import React from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';
import { formatDate } from '../../utils';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
} from '@mui/material';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Empty } from './Empty';
import { useDispatch } from 'react-redux';
import {
  revertTransactions,
  setLoadingState,
} from '../../../../Redux/store/drivers/driversListSlice';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import { useDriverTranslation } from '../../i18n';
import { appDirection } from '../../../../shared_utils';
import { useTheme } from '@mui/styles';
import { toast } from 'react-toastify';

//dialog
const ReteveDialog = ({
  transaction,
  open,
  setOpen,
  setLoading,
  isRefreshing,
  setIsRefreshing,
}) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };
  const handleRevert = async () => {
    try {
      await dispatch(revertTransactions(transaction.id));
      dispatch(setLoadingState(true));
      setIsRefreshing(isRefreshing + 1);
      dispatch(
        setAlert({
          type: 'info',
          message: `revert ${transaction.amount}`,
          isOpen: true,
        })
      );

      setOpen(false);
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    }
  };
  const t = useDriverTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      fullWidth={'sm'}
      maxWidth={'sm'}
    >
      <DialogTitle id='form-dialog-title'>{t('msg')}</DialogTitle>
      <DialogContent>
        <div className='text-md text-gray-500'>
          {t('transaction_ID')} :
          <span className='ml-2 text-gray-800 font-mono font-bold'>
            {transaction.id}
          </span>
        </div>
        <div className='text-md text-gray-500'>
          {t('amount')} :
          <span
            className={
              transaction.amount < 0
                ? 'ml-2 font-mono font-bold text-red-500'
                : 'ml-2 font-mono font-bold text-green-700'
            }
          >
            {transaction.amount}
          </span>
        </div>
        <div className='text-md text-gray-500'>
          {t('role')}:{' '}
          <span className='ml-2 text-gray-800 font-mono font-extralight'>
            {transaction.reference_type}
          </span>
        </div>

        <div className='text-md text-gray-500'>
          {t('narration')} :
          <p className='ml-2 text-gray-800 font-mono font-thin text-center text-xs overflow-hidden py-3 bg-gray-200 rounded-xl'>
            {transaction.narration}
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          lg={12}
          md={12}
          className='flex p-2 justify-center space-x-2  '
          spacing={1}
        >
          <Grid item>
            <Button color='error' variant='outlined' onClick={handleRevert}>
              {t('revert')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant='outlined' onClick={handleClose}>
              {t('cancel')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

function CustomPagination(loading) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const t = useDriverTranslation();
  const theme = useTheme();

  return (
    <Grid
      container
      className=' flex justify-center relative space-x-4 py-4 w-full'
      spacing={1}
    >
      <Grid item>
        <Button
          disabled={page === 0 || loading ? true : false}
          variant='contained'
          color='primary'
          onClick={() => apiRef.current.setPage(page - 1)}
        >
          {t('previous')}
        </Button>
      </Grid>

      <Grid item>
        <Button
          disabled={loading}
          variant='contained'
          color='primary'
          onClick={() => apiRef.current.setPage(page + 1)}
        >
          {t('next')}
        </Button>
      </Grid>
      <Grid item>
        {loading ? (
          <CircularProgress
            className={
              theme.direction === 'ltr' ? 'absolute right-8' : 'absolute left-8'
            }
          />
        ) : (
          <span
            className={
              '' + theme.direction === 'ltr'
                ? 'p-2 px-4 rounded-full bg-gray-300  font-bold absolute right-8'
                : 'p-2 px-4 rounded-full bg-gray-300  font-bold absolute left-8'
            }
          >
            {page + 1}
          </span>
        )}
      </Grid>
    </Grid>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />

      <GridToolbarExport
        csvOptions={{ fields: ['reference', 'amount', 'narration', 'created'] }}
      />
    </GridToolbarContainer>
  );
}

//the main component
const BalanceTable = ({
  data,
  setPage,
  loading,
  page,
  setLoading,
  setIsRefreshing,
  isRefreshing,
}) => {
  //dialog
  const [open, setOpen] = React.useState(false);
  //table data
  const transactions = data;

  const [selectedTransaction, setSelectedTransaction] = React.useState({});

  const handleClick = (params) => {
    setSelectedTransaction(params);
    setOpen(true);
  };
  const t = useDriverTranslation();

  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: ' bg-gray-100 ',
      cellClassName: 'text-xs font-bold text-gray-600',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'reference',
      headerName: t('reference_type'),
      headerClassName: ' bg-gray-100 ',
      cellClassName: 'text-xs',
      flex: 3,
      disableColumnMenu: true,
    },
    {
      field: 'amount',
      headerName: t('amount'),
      headerClassName: ' bg-gray-100  ',
      cellClassName: (params) =>
        params.value > 0
          ? 'text-bold text-green-700 text-center text-xs'
          : 'text-bold text-red-700 text-center text-xs',
      disableColumnMenu: true,
    },
    {
      field: 'narration',
      headerName: t('narration'),
      headerClassName: ' bg-gray-100 ',
      cellClassName: 'text-xs',
      disableColumnMenu: true,
      flex: 3,
    },
    {
      field: 'created',
      headerName: t('created_at'),
      headerClassName: ' bg-gray-100 ',
      cellClassName: 'text-xs',
      type: 'date',
      flex: 2,
      disableColumnMenu: true,
    },
    {
      field: 'action',
      headerName: t('revert'),
      headerClassName: ' bg-gray-100 ',
      // flex: 1,
      width: 70,
      renderCell: (params) => (
        <Button
          color='error'
          size='small'
          // style={{ marginLeft: 16 }}
          onClick={() => handleClick(params.value)}
        >
          <SettingsBackupRestoreIcon />
        </Button>
      ),
    },
  ];

  const rowsData = transactions.map((transaction) => {
    return {
      id: transaction.id,
      reference: transaction.reference_type,
      amount: transaction.amount,
      narration: transaction.narration,
      created: formatDate(transaction.created_at),
      action: transaction,
    };
  });

  return (
    <div className='flex h-auto ' dir={appDirection}>
      <div className=' flex-grow'>
        <DataGrid
          className='bg-white'
          autoHeight
          density='compact'
          rowHeight={60}
          loading={loading}
          rows={rowsData}
          columns={cols}
          pageSize={20}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => setPage(newPage)}
          components={{
            // Toolbar: CustomToolbar,
            Pagination: () => CustomPagination(loading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: LinearProgress,
          }}
        />
      </div>
      <ReteveDialog
        open={open}
        setOpen={setOpen}
        isRefreshing={isRefreshing}
        transaction={selectedTransaction}
        setLoading={setLoading}
        setIsRefreshing={setIsRefreshing}
      />
    </div>
  );
};

export default BalanceTable;
