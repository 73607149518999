import http from '../../../services/APIs/dispatch_api';

export const dispatchesApi = {
  fetchAll: async ({ page, pageSize, filters }) => {
    try {
      const response = await http.get(
        `/dashboard/dispatches?page_number=${page}&page_size=${pageSize}${
          filters ? `&${filters}` : ''
        }`
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  fetchDriverDispatches: async ({ driverId, page, pageSize }) => {
    try {
      const queryString = `page_number=${page}&page_size=${pageSize}&driver_id=${driverId}`;
      const response = await http.get(`/dashboard/dispatches?${queryString}`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  fetchDispatchById: async ({ dispatchId }) => {
    try {
      const response = await http.get(`/dashboard/dispatches/${dispatchId}`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  fetchAutoDispatch: async ({ dispatchId }) => {
    try {
      const response = await http.get(
        `/dispatch_drivers?dispatch_id=${dispatchId}&page_size=1000&page_index=0`
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  fetchDispatchTransaction: async ({ dispatchId }) => {
    try {
      const response = await http.get(`/dashboard/dispatch_transactions?dispatch_id=${dispatchId}`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  updateStatus: async ({ dispatchId, driverId, statusId }) => {
    try {
      const response = await http.put('/dashboard/dispatches/', {
        dispatch_id: dispatchId,
        driver_id: driverId,
        status_id: statusId,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  assignDispatch: async ({ dispatchId, driverId }) => {
    try {
      const response = await http.post('/dashboard/assign_dispatch/', {
        dispatch_id: dispatchId,
        driver_id: driverId,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

 
}