import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { formatDate } from '../../../drivers/utils';
import { useDispatchTranslation } from '../../i18n';
import StatusProgress from './statusProgress';

const DispatchDriversCard = ({ dispatch, handleClickOpen }) => {
  const t = useDispatchTranslation();

  const handleAction = useCallback((id) => {
    switch (id) {
      case 0:
        return t('accepted');

      case 1:
        return t('rejected');

      case 2:
        return t('timeout');

      case 3:
        return t('canceled_client');

      case 4:
        return t('pending');

      default:
        return '.....';
    }
  }, []);

  // useEffect(()=>{
  //   handleStatus()
  // },[])

  // console.log(handleStatus());

  const navigate = useNavigate();
  return (
    <Grid
      container
      lg={12}
      spacing={1}
      md={12}
      justifyContent='center'
      className='bg-white rounded-2xl shadow-xl border-2 border-black '
    >
      <Grid item lg={12} md={12}>
        {/* progress */}
        <Grid item md={12} lg={12} className='flex justify-center p-2'>
          <Grid item lg={8} md={8}>
            <StatusProgress dispatch={dispatch} />
          </Grid>
        </Grid>
      </Grid>
      {/* info  */}
      <Grid item lg={6} md={6} className='p-4'>
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2}>
            <span className=' text-xs font-semibold '>{t('driver_id')}:</span>
          </Grid>
          <Grid
            item
            lg={9}
            md={9}
            sm={9}
            className='p-1 bg-gray-100 text-center rounded-md  hover:text-blue-400 hover:cursor-pointer hover:bg-gray-300'
            onClick={() => navigate(`/drivers/${dispatch.driver_id}/identity`)}
          >
            <span className='text-sm font-serif w-full text-blue-600 '>
              {dispatch.driver_id || '....'}
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} md={6} className='p-4'>
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2}>
            <span className=' text-xs font-semibold '>{t('distance')} :</span>
          </Grid>
          <Grid
            item
            lg={9}
            md={9}
            sm={9}
            className='p-1 bg-gray-100 text-center rounded-md '
          >
            <span className='text-sm font-serif w-full'>
              {dispatch.distance_to_client_km || '0'} {t('KM')}
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} md={6} className='p-4'>
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2}>
            <span className=' text-xs font-semibold '>{t('location')}:</span>
          </Grid>
          <Grid
            item
            lg={9}
            md={9}
            sm={9}
            className='p-1 bg-gray-100 text-center rounded-md hover:text-blue-400 hover:cursor-pointer hover:bg-gray-300'
            onClick={handleClickOpen}
          >
            <span className='text-sm font-serif w-full text-blue-600 '>
              {t('view')}
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} md={6} className='p-4'>
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2}>
            <span className=' text-xs font-semibold '>{t('action')}:</span>
          </Grid>
          <Grid
            item
            lg={9}
            md={9}
            sm={9}
            className='p-1 bg-gray-100 text-center rounded-md '
          >
            <span className='text-sm font-serif w-full'>
              {dispatch.accepted !== 0 && t('accepted')}
              {dispatch.rejected !== 0 && t('rejected')}
              {dispatch.timeout !== 0 && t('timeout')}
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} md={6} className='p-4'>
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2}>
            <span className=' text-xs font-semibold '>{t('created_at')}:</span>
          </Grid>
          <Grid
            item
            lg={9}
            md={9}
            sm={9}
            className='p-1 bg-gray-100 text-center rounded-md '
          >
            <span className='text-sm font-serif w-full'>
              {formatDate(dispatch.created_at) || '....'}
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} md={6} className='p-4'>
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2}>
            <span className=' text-xs font-semibold '>{t('updated_at')}:</span>
          </Grid>
          <Grid
            item
            lg={9}
            md={9}
            sm={9}
            className='p-1 bg-gray-100 text-center rounded-md '
          >
            <span className='text-sm font-serif w-full'>
              {formatDate(dispatch.updated_at) || '....'}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DispatchDriversCard;
