import { Grid } from '@material-ui/core';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../../Redux/store/app/appSlice';
import { getPlacseList } from '../../../Redux/store/places/placesListSlice';
import PageHeading from '../../../components/common/PageHeading';
import PlacesList from '../components/PlacesList';

import PlacesFilters from '../components/common/PlacesFilters';
import { usePlacesTranslation } from '../i18n';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const PlacesListPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(0);
  const [payload, setPayload] = useState({
    place_name: '',
    is_deleted: '',
  });

  const [searchParams, setSearchParams] = useSearchParams(
    createSearchParams({
      page_number: 0,
      page_size: 10,
      ...payload,
    })
  );
  const urlSearchParams = useMemo(
    () => ({
      page_number: searchParams.get('page_number'),
      page_size: searchParams.get('page_size'),
      place_name: searchParams.get('place_name'),
      is_deleted: searchParams.get('is_deleted'),
    }),
    [searchParams]
  );

  const handleApply = () => {
    setSearchParams({
      ...urlSearchParams,
      ...payload,
    });
    setIsRefreshing((prev) => prev + 1);
  };

  const setPage = (newPage) => {
    setSearchParams({
      ...urlSearchParams,
      page_number: newPage,
    });
    setIsRefreshing((prev) => prev + 1);
  };

  const { places } = useSelector((state) => state.places).places;

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(
        getPlacseList({
          page_number: searchParams.get('page_number'),
          page_size: searchParams.get('page_size'),
          place_name: searchParams.get('place_name'),
          is_deleted: searchParams.get('is_deleted'),
        })
      );
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  useLayoutEffect(() => {
    fetchData();
  }, [isRefreshing]);

  const t = usePlacesTranslation();

  return (
    <Grid container justify='center' spacing={2}>
      <Grid item lg={12} md={12}>
        <PageHeading
          heading1={t('places')}
          heading2={t('places_list')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>
      <Grid item lg={12} md={12} xs={12}>
        <PlacesFilters
          handleApply={handleApply}
          payload={payload}
          setPayload={setPayload}
          setIsRefreshing={setIsRefreshing}
        />
        <PlacesList
          data={places}
          page={urlSearchParams.page_number}
          setPage={setPage}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      </Grid>
    </Grid>
  );
};

export default PlacesListPage;
