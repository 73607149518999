import { Button, TextField } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  createEventProviders,
  updateEventProvider,
} from '../../../core/features/events-providers/eventsProvidersSlice';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { Alert } from '@mui/material';
import { toast } from 'react-toastify';
import { Close } from '@material-ui/icons';
import { useEventsTranslation } from '../../../services/i18n';

export default function CreateProviderForm({
  full_name_ar,
  full_name_en,
  mobile,
  email,
  setIsRefreshing,
  setOpenModal,
  update,
  provider_id,
}) {
  const [data, setData] = useState({
    full_name_ar: full_name_ar || '',
    full_name_en: full_name_en || '',
    mobile: mobile || '+249',
    email: email || '',
  });
  const [logo, setLogo] = useState(null);
  const [alert, setAlert] = useState(false);

  const dispatch = useDispatch();
  const [isloading, setIsloading] = useState(false);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setIsloading(true);
    try {
      const formData = new FormData();
      for (let key in data) {
        if (!data[key] && key !== 'email') return setAlert(true);
        formData.append(key, data[key]);
      }
      if (logo) formData.append('provider_logo', logo);
      if (update) {
        await dispatch(updateEventProvider({ provider_id, formData }));
      } else {
        await dispatch(createEventProviders(formData));
      }
      setOpenModal(false);
      setIsRefreshing((prev) => prev + 1);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsloading(false);
    }
  });

  const t = useEventsTranslation();

  if (isloading) {
    return <LoadingIndicator />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='flex flex-col bg-gray-100 rounded-lg p-3 w-[50%] gap-2 absolute left-[50%] translate-x-[-50%]'>
        <div
          className='flex items-start absolute'
          onClick={() => setOpenModal(false)}
        >
          <Close className='bg-gray-100 cursor-pointer' />
        </div>
        {alert && (
          <Alert severity='error'>{t('please_fill_all_the_places')}</Alert>
        )}
        <div className='text-2xl my-2 text-center font-bold'>
          {update ? t('edit_event_provider') : t('add_new_event_provider')}
        </div>

        <TextField
          onChange={(e) => setData({ ...data, full_name_ar: e.target.value })}
          label={t('full_name_ar')}
          variant='outlined'
          value={data.full_name_ar}
          required
        />
        <TextField
          onChange={(e) => setData({ ...data, full_name_en: e.target.value })}
          label={t('full_name_en')}
          variant='outlined'
          value={data.full_name_en}
          required
        />
        <TextField
          onChange={(e) => setData({ ...data, mobile: e.target.value })}
          required
          variant='outlined'
          dir='ltr'
          placeholder='+249xxxxxxxxx'
          value={data.mobile}
          inputProps={{ maxLength: 13, minLength: 13 }}
        />
        <TextField
          onChange={(e) => setData({ ...data, email: e.target.value })}
          label={t('email')}
          variant='outlined'
          type='email'
          value={data.email}
        />
        <div className='flex justify-between items-center'>
          <TextField
            type='file'
            lable={t('provider_logo')}
            variant='outlined'
            onChange={(e) => setLogo(e.target.files[0])}
            className='w-full'
          />
        </div>
        <div className='w-full flex justify-center'>
          <div className='w-[20%]'>
            <Button type='submit' variant='contained' fullWidth color='primary'>
              {update ? t('edit') : t('add_new')}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
