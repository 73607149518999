import moment from 'moment';
import http from '../http';

const getMonthlyRate = async () => {
  const baseURL = process.env.REACT_APP_DISPATCH_API;
  const currentMonth = {
    from: moment().startOf('month').utc(),
    to: moment().endOf('month').utc(),
  };
  try {
    const response = await http.get(
      `${baseURL}/dashboard/acceptance_rate/weakly_acceptance_rate?from=${currentMonth.from}&to=${currentMonth.to}`
    );
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

export default getMonthlyRate;
