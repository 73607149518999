import { createSlice } from '@reduxjs/toolkit';
import { placesApi } from './api';

const initialState = {
  places: [],

  amenitiesList: [{ amenity: '' }],
  reports: [
    // {  place:{
    // }
    //   }
  ],
  groups: [],
  groupDetails: {},
  groupPlaces: [],
  placeDetails: {},
  commentInfo: {},
  IsRefreshingPlace: 0,
  images: [],
  reviewsImages: [],
  reviews: [],
  replies: [],
};

const placesListSlice = createSlice({
  name: 'places',
  initialState,
  reducers: {
    setRefreshingPlace: (state, action) => {
      state.IsRefreshingPlace = action.payload;
    },
    setPlaces: (state, action) => {
      state.places = action.payload;
    },
    setPlaceDetails: (state, action) => {
      state.placeDetails = action.payload;
    },
    setDetails: (state, action) => {
      state.placeDetails = action.payload;
    },
    setCommentInfo: (state, action) => {
      state.commentInfo = action.payload;
    },

    setAmenitiesList: (state, action) => {
      state.amenitiesList = action.payload;
    },

    setAmenities: (state, action) => {
      state.amenitiesList = action.payload;
    },

    setGroups: (state, action) => {
      state.groups = action.payload;
    },
    setGroupDetails: (state, action) => {
      state.groupDetails = action.payload;
    },
    setGroupPlaces: (state, action) => {
      state.groupPlaces = action.payload;
    },
    setImages: (state, action) => {
      state.images = action.payload;
    },
    setReviewImages: (state, action) => {
      state.reviewsImages = action.payload;
    },
    setPlaceReviews: (state, action) => {
      state.reviews = action.payload;
    },
    getReports: (state, action) => {
      state.reports = action.payload;
    },
    setReplies: (state, action) => {
      state.replies = action.payload;
    },
  },
});

export const {
  setRefreshingPlace,
  setPlaces,
  setPlaceDetails,
  setGroups,
  setGroupDetails,
  setGroupPlaces,
  setAmenities,
  setDetails,
  setCommentInfo,
  setAmenitiesList,
  setImages,
  setReviewImages,
  setPlaceReviews,
  getReports,
  setReplies,
} = placesListSlice.actions;

export const getPlacseList = (payload) => async (dispatch) => {
  try {
    const placseList = await placesApi.getPlaces(payload);
    dispatch(setPlaces(placseList));
  } catch (err) {
    throw err;
  }
};

export const getPlacsDetails = (payload) => async (dispatch) => {
  try {
    const placse = await placesApi.getPlaceInfo(payload);
    dispatch(setPlaceDetails(placse));
  } catch (err) {
    throw err;
  }
};

export const getPlacesImage = (payload) => async (dispatch) => {
  try {
    const images = await placesApi.getPlaceImage(payload);
    dispatch(setImages(images));
  } catch (err) {
    throw err;
  }
};

export const removeReviewsImage = (payload) => async (dispatch) => {
  try {
    const images = await placesApi.removeReviewsImage(payload);
    dispatch(setReviewImages(images));
  } catch (err) {
    throw err;
  }
};

export const updatePlaceDetails = (payload) => async (dispatch) => {
  try {
    await placesApi.updatePlaceInfo(payload);
  } catch (err) {
    throw err;
  }
};
export const getGroupList = (payload) => async (dispatch) => {
  try {
    const groups = await placesApi.getGroups(payload);
    dispatch(setGroups(groups));
  } catch (err) {
    throw err;
  }
};

export const getGroupDetails = (payload) => async (dispatch) => {
  try {
    const Details = await placesApi.getGroupInfo(payload);
    dispatch(setGroupDetails(Details));
  } catch (err) {
    throw err;
  }
};

export const getGroupPlaces = (payload) => async (dispatch) => {
  try {
    const places = await placesApi.getGroupPlaces(payload);
    dispatch(setGroupPlaces(places));
  } catch (err) {
    throw err;
  }
};

export const updateGroupDetails = (payload) => async (dispatch) => {
  try {
    await placesApi.updateGroupInfo(payload);
  } catch (err) {
    throw err;
  }
};
export const deleteGroup = (payload) => async (dispatch) => {
  try {
    await placesApi.deleteGroup(payload);
  } catch (err) {
    throw err;
  }
};
export const deletePlace = (payload) => async (dispatch) => {
  try {
    await placesApi.deletePlace(payload);
  } catch (err) {
    throw err;
  }
};

export const deleteGroupPlace = (payload) => async (dispatch) => {
  try {
    await placesApi.deleteGroupPlaces(payload);
  } catch (err) {
    throw err;
  }
};

export const getAmenitiesList = () => async (dispatch) => {
  try {
    const amenities = await placesApi.getAmenities();
    dispatch(setAmenities(amenities));
  } catch (err) {
    throw err;
  }
};

export const createNewPlace = (payload) => async (dispatch) => {
  try {
    await placesApi.createPlace(payload);
  } catch (err) {
    throw err;
  }
};

export const getComments = (payload) => async (dispatch) => {
  try {
    const places = await placesApi.getCommentInfo(payload);
    await dispatch(setCommentInfo(places));
  } catch (err) {
    throw err;
  }
};

export const getPlacesReviews = (payload) => async (dispatch) => {
  try {
    const places = await placesApi.fetchReviewsList(payload);
    await dispatch(setPlaceReviews(places));
  } catch (err) {
    throw err;
  }
};

export const getCommentReplies = (payload) => async (dispatch) => {
  try {
    const res = await placesApi.fetchCommentReplies(payload);
    await dispatch(setReplies(res));
  } catch (err) {
    throw err;
  }
};

//////////////////////////
export const getAmenities = (payload) => async (dispatch) => {
  try {
    const places = await placesApi.getAmenitiesInfo(payload);
    dispatch(setAmenitiesList(places));
  } catch (err) {
    throw err;
  }
};

export const putCommentInfo = (options) => async (dispatch) => {
  try {
    // const places =
    await placesApi.putComment(options);
    // dispatch(setCommentInfo(places));
  } catch (err) {
    throw err;
  }
};

export const deleteComment = (payload) => async (dispatch) => {
  try {
    await placesApi.setComment(payload);
    // dispatch(setCommentInfo(places));
  } catch (err) {
    throw err;
  }
};

export const createNewGroup = (payload) => async (dispatch) => {
  try {
    await placesApi.createGroup(payload);
  } catch (err) {
    throw err;
  }
};

export const deleteImage = (payload) => async (dispatch) => {
  try {
    await placesApi.deletImage(payload);
  } catch (err) {
    throw err;
  }
};

export const AddImage = (options) => async (dispatch) => {
  try {
    await placesApi.updateImages(options);
  } catch (err) {
    throw err;
  }
};

export const addToGroup = (payload) => async (dispatch) => {
  try {
    await placesApi.addPlaceToGroup(payload);
  } catch (err) {
    throw err;
  }
};

export const fetchPlacesReports = (payload) => async (dispatch) => {
  try {
    const response = await placesApi.getPlaceReports(payload);
    dispatch(getReports(response));
  } catch (err) {
    throw err;
  }
};

export const approveReport = (payload) => async (dispatch) => {
  try {
    await placesApi.setReportApprove(payload);
  } catch (err) {
    throw err;
  }
};

export const rejectReport = (payload) => async (dispatch) => {
  try {
    await placesApi.setReportReject(payload);
  } catch (err) {
    throw err;
  }
};

export const hidePlace = (payload) => async () => {
  // await placesApi.hidePlace(payload);
  try {
    await placesApi.hidePlace(payload);
  } catch (err) {
    throw err;
  }
};

export const showPlace = (payload) => async () => {
  try {
    await placesApi.showPlace(payload);
  } catch (err) {
    throw err;
  }
};
export default placesListSlice.reducer;
