import React from "react";
import { Provider } from "react-redux";
import eventsStore from "../../../core/app/store";
import ProviderTabs from "../components/provider-tabs";

export default function ProviderDetailsPage() {
  return (
    <Provider store={eventsStore}>
      <ProviderTabs />
    </Provider>
  );
}
