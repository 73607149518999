import React, { useCallback, createRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useSelector } from 'react-redux';

import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
import { usePendingTranslation } from '../i18n';
import { useTheme } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    margin: '0px 0px 10px 0px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function ListFilter({
  setIsRefreshing,
  isRefreshing,
  payload,
  setPayload,
  onApply,
}) {
  const { carTypes } = useSelector(
    (state) => state.pendingDrivers
  ).pendingDrivers;

  const t = usePendingTranslation();
  const theme = useTheme();
  const inputRef = createRef();
  useEffect(() => {
    if (inputRef) {
      inputRef.current.dir = 'auto';
    }
  }, []);

  const handleSubmit = useCallback((e) => {
    setIsRefreshing(isRefreshing + 1);
  });

  return (
    <Grid
      container
      lg={12}
      md={12}
      justifyContent
      className=' flex justify-end p-1 '
    >
      <Grid container lg={11} md={11} className='' spacing={2}>
        <Grid item lg={2} md={2} sm={2} xs={2} sx={{ mt: -1 }}>
          <TextField
            InputLabelProps={{ shrink: true }}
            className='bg-white'
            fullWidth
            select
            id='outlined-select-currency'
            label={t('car_type')}
            value={payload.carTypeId}
            onChange={(e) =>
              setPayload({ ...payload, carTypeId: e.target.value })
            }
            margin='dense'
            variant='outlined'
            size='small'
          >
            <MenuItem value={''}>All</MenuItem>
            {carTypes.map((cartype) => (
              <MenuItem value={cartype.id} key={cartype.id}>
                {cartype.car_name_en}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={2}>
          <TextField
            InputLabelProps={{ shrink: true }}
            inputRef={inputRef}
            className='bg-white'
            variant='outlined'
            value={payload.mobile}
            onChange={(e) => setPayload({ ...payload, mobile: e.target.value })}
            id='driver_phone'
            label={t('phone')}
            type='text'
            size='small'
            fullWidth
            InputProps={
              theme.direction == 'ltr'
                ? {
                    startAdornment: (
                      <InputAdornment position='end'>+249</InputAdornment>
                    ),
                  }
                : {
                    endAdornment: (
                      <InputAdornment position='start'>249+</InputAdornment>
                    ),
                  }
            }
          />
        </Grid>
      </Grid>

      <Grid
        item
        lg={1}
        md={1}
        sm={1}
        xs={1}
        className='flex justify-center h-full '
      >
        <Button
          variant='contained'
          className='h-full p-8'
          color='primary'
          type='submit'
          onClick={onApply}
        >
          {t('apply')}
        </Button>
      </Grid>
    </Grid>
  );
}
