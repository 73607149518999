import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Orders from './tabs/Orders';

import { useSelector } from 'react-redux';
import Statistics from './tabs/Statistics';
import Balance from './tabs/Balance';
import UserDetails from './tabs/UserDetails';
import Tickets from './tabs/Tickets';
import { useUsersTranslation } from '../i18n';

//tabs config
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

//main component
const UserDetailsTabs = ({ isRefreshing, setIsRefreshing }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const t = useUsersTranslation();
  console.log('done');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Grid container lg={12} md={12} sm={12} className='bg-gray-100 '>
      <Grid item lg={12} md={12} sm={12}>
        <AppBar position='static' color='default' className=''>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            // variant='scrollable'
            // scrollButtons='auto'
            aria-label='full width tabs example'
            className='px-4'
            centered
          >
            <Tab label={t('details')} {...a11yProps(0)} />
            <Tab label={t('orders')} {...a11yProps(1)} />
            <Tab label={t('statistics')} {...a11yProps(2)} />
            <Tab label={t('balance')} {...a11yProps(3)} />
            <Tab label={t('tickets')} {...a11yProps(4)} />
          </Tabs>
        </AppBar>
      </Grid>

      <Grid item lg={12} md={12} xs={12}>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel
            value={value}
            index={0}
            dir={theme.direction}
            className=' h-auto min-h-[90vh]'
          >
            <Grid container lg={12} md={12}>
              {/* <Orders /> */}
              <UserDetails
                isRefreshing={isRefreshing}
                setIsRefreshing={setIsRefreshing}
              />
            </Grid>
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            dir={theme.direction}
            className=' h-auto min-h-[90vh]'
          >
            <Grid container lg={12} md={12}>
              <Orders />
            </Grid>
          </TabPanel>
          <TabPanel
            value={value}
            index={2}
            dir={theme.direction}
            className=' h-auto min-h-[90vh]'
          >
            <Statistics dir={theme.direction} />
          </TabPanel>
          <TabPanel
            value={value}
            index={3}
            dir={theme.direction}
            className=' h-auto min-h-[90vh]'
          >
            <Grid container lg={12} md={12}>
              <Balance />
            </Grid>
          </TabPanel>
          <TabPanel
            value={value}
            index={4}
            dir={theme.direction}
            className=' h-auto min-h-[90vh]'
          >
            <Tickets />
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>

    // <Map />
  );
};

export default UserDetailsTabs;
