import { createSlice } from '@reduxjs/toolkit';
import { ticketsApi } from './api';

const initialState = {
  arrayOfTickets: [],
  ticketDetails: {},
  ticketTransactions: [],
  ticketMessages: [],
  supportUsersList: [],
  categoriesList: [],
  notes: [],
};

const ticketsListSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    fetchTicketsSuccess: (state, action) => {
      state.arrayOfTickets = action.payload;
    },
    fetchTicketDetailsSuccess: (state, action) => {
      state.ticketDetails = action.payload;
    },
    fetchTicketTransactionsSuccess: (state, action) => {
      state.ticketTransactions = action.payload;
    },
    fetchTicketMessagesSuccess: (state, action) => {
      state.ticketMessages = action.payload;
    },
    fetchTicketSupportUsersListSuccess: (state, action) => {
      state.supportUsersList = action.payload;
    },
    fetchTicketcategoriesSuccess: (state, action) => {
      state.categoriesList = action.payload;
    },
    fetchNotesSuccess: (state, actions) => {
      state.notes = actions.payload;
    },
    setError: (state, actions) => {
      state.error = actions.payload;
    },
  },
});

export const {
  fetchTicketsSuccess,
  fetchTicketDetailsSuccess,
  fetchTicketTransactionsSuccess,
  fetchTicketMessagesSuccess,
  fetchTicketSupportUsersListSuccess,
  fetchTicketcategoriesSuccess,
  fetchNotesSuccess,
  setError,
} = ticketsListSlice.actions;

export const fetchUsersTickets = (payload) => async (dispatch) => {
  try {
    const ticketsList = await ticketsApi.fetchUsersTicketsList(payload);
    dispatch(fetchTicketsSuccess(ticketsList));
  } catch (err) {
    dispatch(fetchTicketsSuccess([]));
    throw err;
  }
};
export const fetchDriversTickets = (payload) => async (dispatch) => {
  try {
    const ticketsList = await ticketsApi.fetchDriversTicketsList(payload);
    dispatch(fetchTicketsSuccess(ticketsList));
  } catch (err) {
    dispatch(fetchTicketsSuccess([]));
    throw err;
  }
};

export const fetchTicketDetails = (payload) => async (dispatch) => {
  try {
    const ticketDetails = await ticketsApi.fetchTicket(payload);
    dispatch(fetchTicketDetailsSuccess(ticketDetails));
  } catch (err) {
    dispatch(fetchTicketDetailsSuccess({}));
    throw err;
  }
};

export const fetchTicketTransactions = (payload) => async (dispatch) => {
  try {
    const transactions = await ticketsApi.fetchTicketTransactions(payload);
    dispatch(fetchTicketTransactionsSuccess(transactions));
  } catch (err) {
    dispatch(fetchTicketTransactionsSuccess([]));
    throw err;
  }
};

export const fetchTicketMessages = (payload) => async (dispatch) => {
  try {
    const messages = await ticketsApi.fetchTicketMessages(payload);
    dispatch(fetchTicketMessagesSuccess(messages));
  } catch (err) {
    dispatch(fetchTicketMessagesSuccess([]));
    throw err;
  }
};

export const fetchTicketSupportUsersList = () => async (dispatch) => {
  try {
    const messages = await ticketsApi.fetchSupportUsers();
    dispatch(fetchTicketSupportUsersListSuccess(messages));
  } catch (err) {
    dispatch(fetchTicketSupportUsersListSuccess([]));
    throw err;
  }
};

export const assignSupportUser = (payload) => async (dispatch) => {
  try {
    await ticketsApi.assignSupportUser(payload);
  } catch (err) {
    throw err;
  }
};

export const upadateTicketStatus = (payload) => async (dispatch) => {
  try {
    await ticketsApi.changeTicketStatus(payload);
  } catch (err) {
    throw err;
  }
};

export const fetchUsersTicketcategories = () => async (dispatch) => {
  try {
    console.log('users');
    const categories = await ticketsApi.fetchUsersTicketCategoriesCall();
    dispatch(fetchTicketcategoriesSuccess(categories));
  } catch (err) {
    throw err;
  }
};

export const fetchDriversTicketcategories = () => async (dispatch) => {
  try {
    const categories = await ticketsApi.fetchDriversTicketCategoriesCall();
    dispatch(fetchTicketcategoriesSuccess(categories));
  } catch (err) {
    throw err;
  }
};

export const createTicket = (payload) => async (dispatch) => {
  try {
    await ticketsApi.createTicket(payload);
  } catch (err) {
    throw err;
  }
};

export const updateTicket = (payload) => async (dispatch) => {
  try {
    await ticketsApi.updateTicket(payload);
  } catch (err) {
    throw err;
  }
};

export const sendTicketMessage = (payload) => async (dispatch) => {
  try {
    await ticketsApi.sendMessage(payload);
  } catch (err) {
    throw err;
  }
};

export const fetchTicketNotes = (payload) => async (dispatch) => {
  try {
    const response = await ticketsApi.fetchTicketNotes(payload);
    dispatch(fetchNotesSuccess(response));
  } catch (err) {
    throw err;
  }
};

export const CreateTicketNotes = (payload) => async (dispatch) => {
  try {
    console.log(payload);
    await ticketsApi.CreateTicketNotes(payload);
  } catch (err) {
    throw err;
  }
};

export default ticketsListSlice.reducer;
