import React, { useCallback, useEffect, useMemo, useState } from 'react';
import default_image from '../../../../assets/images/defult-pic.png';

import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
  AttachmentButton,
  InputToolbox,
  SendButton,
} from '@chatscope/chat-ui-kit-react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from '../../utils';
import moment from 'moment';
import { useFormik } from 'formik';
import { IconButton } from '@mui/material';
import { AttachFile, Send } from '@mui/icons-material';
import {
  sendTicketMessage,
  setError,
} from '../../../../Redux/store/tickets/ticketsListSlice';

import { appDirection } from '../../../../shared_utils';
import { useTicketTranslation } from '../../i18n';
import * as yup from 'yup';
import { toast } from 'react-toastify';

const Chat = ({ isRefreshing, setIsRefreshing }) => {
  const { ticketMessages, ticketDetails } = useSelector(
    (state) => state.tickets
  ).ticketsList;
  const dispatch = useDispatch();

  const [message, setMessage] = React.useState('');

  const messages = [...ticketMessages].reverse();

  const handleSystemMessagesDispalay = (message) => {
    const msg = message.split('*');
    return msg.map((msg) => <p>{msg}</p>);
  };

  const validationSchema = yup.object({
    message: yup.string().min(2, 'Too Short!').required('Required'),
  });

  const handleSendingMessages = useCallback(async (payload) => {
    try {
      await dispatch(sendTicketMessage(payload));
    } catch (err) {
      toast.error(err.data.message);

      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsRefreshing(isRefreshing + 1);
    }
  });

  //input config
  const init = {
    ticket_id: ticketDetails.ticket_id,
    message: '',
  };

  const formik = useFormik({
    initialValues: init,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleSendingMessages(values);
      resetForm();
    },
  });

  const t = useTicketTranslation();

  const handleMessageType = useCallback((message) => {
    /***classify messages by check who is the sender ***/
    if (message.created_by_id) {
      if (message.created_by_id === ticketDetails.user_id) {
        return (
          <Message
            model={{
              message: message.message,
              sentTime: message.created_at,
              sender: ticketDetails.user.full_name,
              direction: 'incoming',
              position: 'single',
            }}
          >
            <Message.Footer
              sender={ticketDetails.user.full_name}
              sentTime={moment(message.created_at).format('DD/MM/YYYY')}
            />
            <Avatar src={default_image} />
          </Message>
        );
      } else {
        return (
          <Message
            model={{
              message: message.message,
              sentTime: message.created_at,
              direction: 'outgoing',
              position: 'single',
            }}
          >
            <Message.Footer
              sender='Support'
              sentTime={moment(message.created_at).format('DD/MM/YYYY ')}
            />
          </Message>
        );
      }
    } else {
      return (
        <div className='w-full text-black flex justify-center text-center p-4'>
          <span className='bg-yellow-200 p-3 w-2/4 text-center rounded-md shadow-lg'>
            {handleSystemMessagesDispalay(message.message)}
            <p className='text-gray-600 text-xs pt-2'>
              {format(message.created_at)}
            </p>
          </span>
        </div>
      );
    }
  });

  return (
    <div style={{ position: 'relative', height: '70vh' }}>
      <MainContainer>
        <ChatContainer>
          <MessageList>
            {messages.map((message) => handleMessageType(message))}
          </MessageList>
        </ChatContainer>
      </MainContainer>
      <form onSubmit={formik.handleSubmit} className='flex justify-center mt-1'>
        <input
          dir={appDirection}
          type='text'
          name='message'
          id='message'
          className='py-2 px-8 w-8/12 mr-2  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500'
          placeholder={t('text_msg')}
          value={formik.values.message}
          onChange={formik.handleChange}
        />
        <IconButton>
          <AttachFile color='primary' />
        </IconButton>
        <IconButton type='submit'>
          <Send color='primary' />
        </IconButton>
      </form>
    </div>
  );
};

export default Chat;
