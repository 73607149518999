import { Route } from 'react-router-dom';
import GenerateOtpsPage from './pages/generate-otps-page';
import UserDetailsPage from './pages/user-details-page';
import UsersListPage from './pages/users-list-pages';

export function UsersRoutes() {
  return [
    <Route path='/users' key={11}>
      <Route path='/users/' element={<UsersListPage />} key={'users_list'} />
      <Route path='/users/:id/' element={<UserDetailsPage />} key={'users_details'} />
      <Route path='/users/otps-generate/' element={<GenerateOtpsPage />} key={'users_otps'} />
    </Route>,
  ];
}
