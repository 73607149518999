import { Route } from 'react-router';
import HeatMapPage from './pages/heatmap-page';

export function DemandSupplyRoutes() {
  return (
    <Route path='/demand_supply' key={'demand_supply'}>
      <Route path='/demand_supply/' element={<HeatMapPage />} key={'heatmap_page'} />
    </Route>
  );
}
