const users_en = {
  users: 'Users',
  users_list: 'Users List ',
  user_info: 'User Info ',
  actions: 'Actions',
  phone: ' Phone',
  user_details: 'User Details ',

  full_name: 'Full name',
  email: 'Email',
  created_at: 'Created at ',
  updated_at: 'Updated at',
  create_ticket: 'Create ticket',
  parent_category: 'Parent category ',
  child_category: ' Child category',
  priority: 'Priority',
  description: 'Description',

  note: 'Note',
  new_ticket: ' New ticket',
  submit: 'Submit',
  new_otp: 'New Otp',
  order_id: ' Driver id',
  from: 'From',
  to: 'To',
  car_type: 'Car type ',
  all: 'الكل',
  reference_type: 'Reference type',

  amount: 'Amount',
  narration: 'Narration',
  total_balance: ' Total balance',
  transactions: 'Transaction',
  add_balance: ' Add Balance',
  deduct_balance: 'Deduct Balance ',
  status: 'Status',
  new: 'New',
  under_process: ' Under process',
  closed: 'Closed',

  user_info: 'User info',
  car_type: 'Car type',
  generate_otp: 'Generate OTP',
  service: 'Service',
  lemon_client: 'lemon client',
  lemon_driver: ' Lemon driver',
  taxi_support: ' Taxi support',
  food_support: ' Food support',
  lemon_store: 'Lemon store',
  lemon_events_portal: 'LemonEvents (Provider)',
  mobile: 'Mobile',
  msg_invalid: 'invalid mobile number',
  msg_add: 'add to balance successfully',
  msg_deduct: 'Deduct from balance successfully',
};
export default users_en;
