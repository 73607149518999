import React from 'react';
import { Button, Grid } from '@mui/material';

import TextField from '@mui/material/TextField';

import { useFormik } from 'formik';
import { useDriverTranslation } from '../../i18n';

const Form = (props) => {
  const driver = props.driver;
  const handleClose = props.close;
  const addNote = props.addNote;

  //formik
  const formik = useFormik({
    initialValues: {
      note: '',
      supervisor_name: JSON.parse(localStorage.supportUser), // TODO: the Supervisor in the user logined in
      driver_id: driver.driver_id,
    },

    onSubmit: (values) => {
      console.log('hi');
    },
  });

  const handleSubmit = (values) => {
    addNote(values);
    handleClose();
  };
  const t = useDriverTranslation();

  return (
    <>
      {/* general info */}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(formik.values);
        }}
      >
        <Grid
          container
          lg={12}
          md={12}
          spacing={2}
          style={{ padding: '20px 10px' }}
        >
          {/* body  */}

          <Grid item lg={12} md={12}>
            <TextField
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              margin='dense'
              id='note'
              name='note'
              label={t('note')}
              fullWidth
              value={formik.values.note}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid
            container
            lg={12}
            md={12}
            className='flex p-2 justify-center space-x-2  '
            spacing={1}
          >
            <Grid item>
              <Button variant='outlined' color='primary' type='submit'>
                {t('submit')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant='outlined' color='inherit' onClick={handleClose}>
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default Form;
