import http from '../../../services/APIs/places_api';
import http2 from '../../../services/APIs/places_api_2';

export const placesApi = {
  getPlaces: async (payload) => {
    let url;
    if (payload.page_number || payload.page_number === 0) {
      url = `/dashboard/places?page_number=${payload.page_number}&page_size=${payload.page_size}&search=${payload.place_name}&is_deleted=${payload.is_deleted}`;
    } else {
      url =
        `/dashboard/places/get_nearby?latitude=${payload.lat}&longitude=${payload.lng}&is_deleted=false`.concat(
          payload.amenityId ? `&amenity_id=${payload.amenityId}` : ''
        );
    }
    try {
      const response = await http.get(url);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  getPlaceInfo: async ({ id }) => {
    try {
      const response = await http.get(`/dashboard/places/get_details/${id}`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  getPlaceImage: async ({ id }) => {
    try {
      const response = await http.get(`/dashboard/places_images/${id}`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  updatePlaceInfo: async (payload) => {
    try {
      await http.put(`/dashboard/places/${payload.id}`, payload.payload);
    } catch (error) {
      throw error.response;
    }
  },

  getCommentInfo: async (payload) => {
    try {
      const response = await http.get(
        `/place_comments?place_id=${payload.id}&page_number=${payload.page_number}&page_size=20`
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  fetchReviewsList: async (payload) => {
    try {
      const response = await http.get(
        `dashboard/place_comments?page_size=${payload.pageSize}&page_number=${payload.page}&is_deleted=${payload.isDeleted}&mobile_number=${payload.mobile}&place_id=${payload.id}`
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  fetchCommentReplies: async (payload) => {
    try {
      const response = await http.get(
        `dashboard/place_comments/${payload.id}?page_size=${payload.pageSize}&page_number=${payload.page}&is_deleted=${payload.isDeleted}`
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  getAmenities: async () => {
    try {
      const response = await http.get(`/dashboard/places_Amenities/`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  getAmenitiesInfo: async () => {
    try {
      const response = await http.get(`/dashboard/places_Amenities/`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  getGroups: async ({ isDeleted, type, page }) => {
    let url = `/dashboard/groups?page_size=10&page_number=${page || 0}`;
    if (isDeleted !== '') {
      url += `&is_deleted=${isDeleted}`;
    }
    try {
      const response = await http.get(url);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  createPlace: async (payload) => {
    try {
      const response = await http2.post(`/dashboard/places`, payload);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  getGroupInfo: async ({ group_id }) => {
    try {
      const response = await http.get(`/dashboard/groups/${group_id}`);

      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  getGroupPlaces: async ({ group_id, page, pageSize }) => {
    try {
      const response = await http.get(
        `/dashboard/group_places?group_id=${group_id}&page_size=${pageSize}&page_number=${page}`
        // /dashboard/group_places?group_id=3&page_size=2&page_number=0
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  updateGroupInfo: async (payload) => {
    try {
      await http.put(`/dashboard/groups/${payload.group_id}`, payload);
    } catch (error) {
      throw error.response;
    }
  },

  deleteGroup: async (id) => {
    try {
      await http.delete(`/dashboard/groups/${id}`);
    } catch (error) {
      throw error.response;
    }
  },

  deletePlace: async (id) => {
    try {
      await http.delete(`/dashboard/places/${id}`);
    } catch (error) {
      throw error.response;
    }
  },

  deleteGroupPlaces: async (payload) => {
    try {
      await http.delete(`/dashboard/group_places/${payload.place_id}`);
    } catch (error) {
      throw error.response;
    }
  },
  putComment: async ({ id, payload }) => {
    try {
      const response = await http.put(`/place_comments/${id}`, payload);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  setComment: async ({ id }) => {
    try {
      const response = await http.delete(`/dashboard/place_comments/${id}`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  deletImage: async ({ id, imagesIds }) => {
    try {
      // const response =
      await http.delete(`/dashboard/places_images/${id}`, {
        data: { imagesIds: imagesIds },
      });
      // return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  removeReviewsImage: async ({ id, imagesIds }) => {
    try {
      await http.delete(`/dashboard/comments_images/${id}`, {
        data: { image_id: imagesIds },
      });
      // return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  updateImages: async ({ id, payload }) => {
    try {
      const response = await http2.put(
        `/dashboard/places_images/${id}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  createGroup: async (payload) => {
    try {
      const response = await http.post(`/dashboard/groups`, payload);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  addPlaceToGroup: async (payload) => {
    try {
      const response = await http.post(`/dashboard/group_places`, payload);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  getPlaceReports: async (payload) => {
    try {
      const response = await http.get(
        `/dashboard/reports?page_number=${payload.page}&page_size=${payload.pageSize}&status_id=${payload.status_id}`
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  setReportApprove: async (payload) => {
    try {
      const response = await http.put(
        `/dashboard/reports/approve/${payload.id}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  setReportReject: async (payload) => {
    try {
      const response = await http.put(
        `/dashboard/reports/reject/${payload.id}`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  hidePlace: async (payload) => {
    try {
      const response = await http.put(`/dashboard/places/hide/${payload.id}`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  showPlace: async (payload) => {
    try {
      const response = await http.put(`/dashboard/places/show/${payload.id}`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
};
