import { Route } from 'react-router-dom';
import AcceptanceRatePage from './pages/acceptance_rate_page';

export function AppRoutes() {
  return [
    <Route path='/data' key={77}>
      <Route path='/data/acceptance_rate' element={<AcceptanceRatePage />} key={'all-drivers'} />
    </Route>,
  ];
}
