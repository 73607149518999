import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

import {
  ButtonGroup,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';

import { Empty } from '../../drivers/components/common/Empty';
import { useDispatch, useSelector } from 'react-redux';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import { Link, useNavigate } from 'react-router-dom';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {
  cancelOrder,
  reOrder,
} from '../../../Redux/store/orders/ordersListSlice';

import {
  AttachMoney,
  Cancel,
  Refresh,
  Room,
  Visibility,
} from '@mui/icons-material';

import { formatDate } from '../../drivers/utils';

import { GridCloseIcon } from '@mui/x-data-grid-pro';
import Map from '../../tracking/components/common/orders_list_tracking/Map';
import ConfirmationDialog from './common/ConfirmationDialog';
import { useOrdersTranslation } from '../i18n';
import { toast } from 'react-toastify';
import CustomPagination from '../../../components/new-components/CustomPagination';
import DeleteModal from '../../../components/new-components/DeleteModal';

function CustomToolbar() {
  return <></>;
}

//mapdialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const MapDialog = (props) => {
  const {
    onClose,
    selectedValue,
    open,
    driver_id,
    pickup_lang,
    pickup_lat,
    dropoff_lat,
    dropoff_lang,
    route_ref,
  } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const mapProps = {
    driver_id: driver_id,
    pickup_lang: pickup_lang,
    pickup_lat: pickup_lat,
    dropoff_lat: dropoff_lat,
    dropoff_lang: dropoff_lang,
    route_ref: route_ref,
  };

  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <appBar className='relative bg-blue-900 text-white'>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <GridCloseIcon />
          </IconButton>
        </Toolbar>
      </appBar>
      <Map {...mapProps} />
    </Dialog>
  );
};

const OrdersList = ({
  data,
  setPage,
  page,
  isLoading,
  setIsRefreshing,
  isRefreshing,
  type,
}) => {
  const t = useOrdersTranslation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleCancel = async (id) => {
    try {
      await dispatch(cancelOrder(id));
      setIsRefreshing(isRefreshing + 1);
      toast.info(t('cancle') + ' ' + id);
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    }
  };

  //mapdialog
  const [open, setOpen] = React.useState(false);
  const handleClose = (value) => {
    setOpen(false);
  };
  const [mapProps, setMapProps] = React.useState({});
  const handleClickOpen = (
    driver_id,
    pick_up_latitude,
    pick_up_longitude,
    drop_off_latitude,
    drop_off_longitude,
    route_ref,
    dispatch_id
  ) => {
    setOpen(true);
    setMapProps({
      driver_id: driver_id,
      pickup_lang: pick_up_longitude,
      pickup_lat: pick_up_latitude,
      dropoff_lat: drop_off_latitude,
      dropoff_lang: drop_off_longitude,
      route_ref: route_ref,
      dispatch_id: dispatch_id,
    });
  };

  // confirmation dailog
  const [isOpen, setIsOpen] = React.useState(false);
  // reorder dialog
  const [reorderOpen, setReorderOpen] = React.useState(false);

  const [orderId, setOrderId] = React.useState(0);
  const handleConfiram = (id) => {
    setOrderId(id);
    setIsOpen(true);
  };

  const handleReorderAction = async (order) => {
    let payload = {
      car_type_id: order.car_type_id,
      driver_note: order.driver_note,
      drop_off_latitude: order.drop_off_latitude,
      drop_off_longitude: order.drop_off_longitude,
      mobile_number: order.user.mobile,
      order_type: order.order_type,
      pick_up_latitude: order.pick_up_latitude,
      pick_up_longitude: order.pick_up_longitude,
    };

    try {
      await dispatch(reOrder(payload));
      setIsRefreshing(isRefreshing + 1);
      toast.success(t('reorder_successfully'));
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    }
  };

  const handleReorderBtnVisibility = (id) => {
    switch (id) {
      case 3:
      case 4:
      case 9:
      case 14:
      case 15:
      case 16:
      case 17:
      case 18:
        return true;

      case 10:
        return false;
      default:
        return false;
    }
  };

  const handleCancelOrderShowBtn = (id) => {
    const listOfIDs = { 1: true, 8: true, 6: true, 5: true, 2: true };
    return id in listOfIDs;
  };

  const cols = [
    {
      field: 'clientInfo',
      headerName: t('client'),
      headerClassName: ' bg-gray-100 text-black fond-extrabold ',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs p-1 text-[#1769aa] hover:cursor-pointer'
          // onClick={() => navigate(`/users/${params.value.user_id}`)}
        >
          <Grid item lg={8} md={8}>
            <Link to={`/users/${params.value.user_id}`}>
              <Grid item lg={12} md={12} xs={12}>
                <AccountCircleIcon fontSize='small' className='text-gray-500' />{' '}
                {params.value.user.full_name}
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <PhoneAndroidIcon fontSize='small' className='text-gray-500' />{' '}
                {params.value.user.mobile}
              </Grid>
            </Link>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'driverInfo',
      headerName: t('driver'),
      headerClassName: ' bg-gray-100 font-bold ',

      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className={
            !params.value.driver_name
              ? ''
              : type == 'DriverDetails'
              ? 'text-xs p-1 '
              : 'text-xs p-1 text-[#1769aa] cursor-pointer'
          }
          // onClick={(e) => {
          //   if (params.value.driver_name) {
          //     navigate(`/drivers/${params.value.driver_id}/Normal`);
          //   }
          // }}
        >
          <Grid item lg={8} md={8}>
            <Link to={`/drivers/${params.value.driver_id}/Normal`}>
              <Grid item lg={12} md={12} xs={12}>
                <AccountCircleIcon fontSize='small' className='text-gray-500' />{' '}
                {params.value.driver_name || '---'}
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <PhoneAndroidIcon fontSize='small' className='text-gray-500' />{' '}
                {params.value.driver_mobile || '---'}
              </Grid>
            </Link>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'status',
      headerName: t('status'),
      headerClassName: ' bg-gray-100 font-bold',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid container lg={12} md={12} xs={12}>
          <Grid item lg={12} md={12} xs={12}>
            <Grid
              item
              lg={2}
              md={2}
              className={
                params.value.status_id === 3 ||
                params.value.status_id === 4 ||
                params.value.status_id === 9 ||
                params.value.status_id === 13 ||
                params.value.status_id === 14 ||
                params.value.status_id === 15 ||
                params.value.status_id === 16 ||
                params.value.status_id === 17 ||
                params.value.status_id === 18
                  ? 'text-xs text-red-700 '
                  : params.value.status_id === 2 ||
                    params.value.status_id === 5 ||
                    params.value.status_id === 6
                  ? 'text-xs text-blue-700 '
                  : params.value.status_id === 7
                  ? 'text-xs text-green-700 '
                  : params.value.status_id === 1 || params.value.status_id === 8
                  ? 'text-xs text-orange-500 '
                  : null
              }
            >
              {params.value.status.status_name}
            </Grid>
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'Distance',
      headerName: t('distance'),
      headerClassName: ' bg-gray-100 font-bold',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid container lg={12} md={12} className='text-xs'>
          <Grid item lg={12} md={12} className='pt-1'>
            <span className='p-2 text-gray-700'>
              {params.value.distance_km}
              <span className='font-bold text-xs'> {t('KM')}</span>{' '}
            </span>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'CarType',
      headerName: t('car_type'),
      headerClassName: ' bg-gray-100 font-bold',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid container lg={12} md={12} className='text-xs'>
          <Grid item lg={12} md={12}>
            <span className=' text-xs text-gray-700 font-mono font-semibold w-full text-center'>
              {params.value.car_type.car_name}
            </span>
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'paidInfo',
      headerName: t('paid_info'),
      headerClassName: ' bg-gray-100 font-bold',
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid
          container
          justifyContent='center'
          lg={12}
          md={12}
          xs={12}
          className='text-xs'
        >
          <Grid item lg={12} md={12} xs={12} className=' rounded-xl '>
            <AttachMoney fontSize='small' />
            {params.value.price}{' '}
            <Typography
              variant='caption'
              className=' font-extrabold text-gray-700'
            >
              {t('SDG')}
            </Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'dates',
      headerName: t('dates'),
      headerClassName: ' bg-gray-100 font-bold',
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid container lg={12} md={12} className='text-xs'>
          <Grid item lg={12} md={12} className='p-2 text-gray-700 text-center'>
            {formatDate(params.value.created_at)}
          </Grid>
          <Grid item lg={12} md={12} className='p-2 text-gray-700 text-center'>
            {formatDate(params.value.updated_at)}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'actions',
      headerName: t('actions'),
      headerClassName: ' bg-gray-100 font-bold',
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid container lg={12} md={12} justifyContent='center'>
          <ButtonGroup
            color='primary'
            aria-label='outlined primary button group'
          >
            {/* details  */}
            <Link to={`/orders/${params.value.order_id}`}>
              <Visibility
                color='inherit'
                className=' hover:bg-blue-200 hover:cursor-pointer'
                // onClick={() => {
                //   navigate(`/orders/${params.value.order_id}`);
                // }}
              />
            </Link>
            {/* map */}
            <Room
              color='primary'
              className=' hover:bg-blue-200 hover:cursor-pointer'
              onClick={() => {
                handleClickOpen(
                  params.value.driver_id,
                  params.value.pick_up_latitude,
                  params.value.pick_up_longitude,
                  params.value.drop_off_latitude,
                  params.value.drop_off_longitude,
                  params.value.route_ref,
                  params.value.dispatch_id
                );
              }}
            />
            {/* reorder */}
            {handleReorderBtnVisibility(params.value.status.status_id) && (
              <Refresh
                color='warning'
                className=' hover:bg-blue-200 hover:cursor-pointer'
                onClick={() => {
                  setReorderOpen(params.value);
                }}
              />
            )}
            {/* {params.value.status.status_id !== 7 &&  */}
            {handleCancelOrderShowBtn(params.value.status.status_id) && (
              <Cancel
                color='error'
                className=' hover:bg-red-200 hover:cursor-pointer'
                onClick={() => handleConfiram(params.value.order_id)}
              />
            )}
          </ButtonGroup>
        </Grid>
      ),
    },
  ];
  const rowsData = data.map((data) => {
    return {
      id: data.order_id,
      status: data,
      CarType: data,
      clientInfo: data,
      driverInfo: data,
      Distance: data,
      paidInfo: data,
      dates: data,
      actions: data,
    };
  });
  return (
    <div className='flex h-auto'>
      <MapDialog open={open} onClose={handleClose} {...mapProps} />
      <ConfirmationDialog
        open={isOpen}
        setOpen={setIsOpen}
        handleCancel={handleCancel}
        id={orderId}
      />
      <DeleteModal
        openModal={reorderOpen !== false}
        setOpenModal={setReorderOpen}
        onConfirm={() => handleReorderAction(reorderOpen)}
        message={t('are_you_sure_do_you_want_to_reorder')}
      />

      <div className=' flex-grow'>
        <DataGrid
          className='bg-white'
          disableColumnFilter
          autoHeight
          rowHeight={60}
          page={Number(page)}
          loading={isLoading}
          rows={rowsData}
          columns={cols}
          pageSize={1}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => setPage(newPage)}
          CellStyle='{StaticResource DataGridBorder}'
          components={{
            Toolbar: CustomToolbar,
            Pagination: () => CustomPagination(isLoading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: LoadingIndicator,
          }}
        />
      </div>
    </div>
  );
};

export default OrdersList;
