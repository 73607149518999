import { Button, Grid, Stack } from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageHeading from '../../../components/common/PageHeading';
import {
  getAmenitiesList,
  getPlacseList,
} from '../../../Redux/store/places/placesListSlice';
import { TurnSlightRight } from '@mui/icons-material';
import { usePlacesTranslation } from '../i18n';
import { toast } from 'react-toastify';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import * as L from 'leaflet';
import { Link, useNavigate } from 'react-router-dom';
import LoadingIndicator from '../../../components/common/LoadingIndicator';

const icons = [
  new L.Icon({
    iconUrl: require('../../../assets/images/mapIcons/ic_pin_cafe.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  }),
  new L.Icon({
    iconUrl: require('../../../assets/images/mapIcons/ic_pin_restaurant.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  }),
  new L.Icon({
    iconUrl: require('../../../assets/images/mapIcons/ic_pin_juice.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  }),
  new L.Icon({
    iconUrl: require('../../../assets/images/mapIcons/ic_pin_store.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  }),
];

const PlacesMapPage = () => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [coordinates, setCoordinates] = useState({
    lat: 15.5007,
    lng: 32.5599,
  });

  const [loading, setLoading] = useState(false);
  const [amenityId, setAmenityId] = useState();
  const { places, amenitiesList } = useSelector((state) => state.places).places;

  const t = usePlacesTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const fetchAmenitiesList = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(getAmenitiesList());
    } catch (err) {
      toast.error(err.data.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAmenitiesList();
  }, [isRefreshing]);

  const fetchData = useCallback(async () => {
    try {
      await dispatch(
        getPlacseList({
          lat: coordinates.lat,
          lng: coordinates.lng,
          amenityId: amenityId,
        })
      );
    } catch (err) {
      toast.error(err.data.message);
    }
  });

  useLayoutEffect(() => {
    fetchData();
  }, [isRefreshing, coordinates, amenityId]);

  const MapMarker = () => {
    const map = useMap();
    const [pos, setPos] = useState(map.getCenter());

    const onMove = useCallback(() => {
      setPos(map.getCenter());
    }, [map]);

    const onStop = useCallback(() => {
      setCoordinates(map.getCenter());
    }, [map]);

    useEffect(() => {
      map.on('move', onMove);
      map.on('moveend', onStop);
      return () => {
        map.off('move', onMove);
        map.off('moveend', onStop);
      };
    }, [map, onMove, onStop]);

    return <Marker position={pos} />;
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Grid container justify='center' className=''>
      <Grid item lg={12} md={12} className='flex relative '>
        <PageHeading
          heading1={t('places')}
          heading2={t('places_list')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>
      <div className='w-full flex items-center justify-center'>
        <div className='m-2 w-full h-[80vh] relative'>
          <div
            dir='rtl'
            className='z-[10000] right-0 w-[50%] absolute top-2 flex items-center'
          >
            <Stack direction='row' spacing={1}>
              {amenitiesList.map((amenity) => (
                <Button
                  key={amenity.amenity_id}
                  variant='contained'
                  color={
                    amenity.amenity_id === amenityId ? 'primary' : 'inherit'
                  }
                  onClick={() =>
                    setAmenityId((perv) =>
                      perv === amenity.amenity_id
                        ? undefined
                        : amenity.amenity_id
                    )
                  }
                >
                  {amenity.name}
                </Button>
              ))}
            </Stack>
          </div>
          <MapContainer
            center={{ lat: 15.600519114585818, lng: 32.5761194225197 }}
            zoom={13}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            <MapMarker />
            {places.map((place) => (
              <Marker
                icon={icons[place.amenity_id - 1]}
                position={{ lat: place.latitude, lng: place.longitude }}
              >
                <Popup closeButton={false}>
                  <div className='m-2 text-lg'>{place.name}</div>
                  <Button
                    onClick={() => navigate(`/places/${place.place_id}`)}
                    variant='contained'
                    endIcon={<TurnSlightRight />}
                  >
                    {t('place_page')}
                  </Button>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>
      </div>
    </Grid>
  );
};

export default PlacesMapPage;
