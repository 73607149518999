const orders_ar = {
  orders: 'الطلبات',
  oredrs_list: 'قائمة الطلبات',
  orders_details: 'تفاصيل الطلب',
  filters: 'تصفية النتائج',
  status: 'الحالة',
  all: 'الكل',
  car_type: 'نوع السيارة',
  apply: 'تطبيق',
  user_mobile: 'هاتف المستخدم',
  driver_mobile: 'هاتف السائق',
  search_with: 'البحث باستخدام',
  client: 'العميل',
  driver: 'السائق',
  distance: 'المسافة',
  km: 'كم',
  paid_info: 'بيانات الدفع',
  SDG: 'جنيه',
  dates: 'التواريخ',
  actions: 'العمليات',
  cancle: 'الغاء',
  AreyousureyouwanttocanceltheOrder: 'هل انت متاكد انك تريد الغاء الطلب؟',
  confirm: 'تاكيد',
  details: 'التفاصيل',
  transactions: 'العمليات',
  tracking: 'التتبع',
  Dispatch_ID: 'رقم الديسباتش',
  Dispatch_details: 'تفاصيل الديسباتش',
  Driver_ID: 'معرف السائق',
  Creation_at: 'تاريخ الانشاء',
  Updated_at: 'اخر تحديث',
  Car_Type: 'نوع السيارة',
  Peak_Rate: 'معدل الذروة',
  Trip_details: 'تفاصيل الرحلة',
  From: 'من',
  To: 'إلى',
  Price: 'السعر',
  Open_trip_Price: 'سعر الرحلة المفتوحة',
  Distance_details: 'تفاصيل المسافة',
  Distance: 'المسافة',
  Duration: 'المدة',
  Open_Trip_Distance: 'مسافة الرحلة المفتوحة',
  Open_Trip_Duration: 'مدة الرحلة المفتوحة',
  Client_details: 'تفاصيل العميل',
  Client: 'العميل',
  Client_Mobile: 'موبايل العميل',
  Order_Mobile: 'موبايل الطلب',
  Client_Note: 'ملاحظات العميل',
  create_ticket: 'تذكرة جديدة',
  Order_Transactins: 'عمليات الطلب',
  Created_By: 'تم الانشاء بواسطة',
  Supervisor: 'مشرف',
  Admin: 'مسؤول',
  System: 'نظام',
  Driver: 'سائق',
  Driver_Name: 'اسم السائق',
  Paid_cash: 'المدفوع نقدا',
  Paid_balance: 'الرصيد المدفوع',
  Paid_promo: 'البرومو المدفوع',
  statistics: 'الإحصائيات',
  Total: 'الكلي',
  create_order: 'إنشاء طلب للعميل',
  select_car: 'حدد فئة المركبة',
  open_trip: 'مشوار مفتوح',
  dropoff_location: 'حدد نقطة النهاية',
  pickup_location: 'حدد نقطة الإنطلاق',
  route_details: 'تفاصيل المسار',
  Customer_phone: 'رقم هاتف العميل',
  lemon_go: 'ليمون GO ',
  lemon_one: 'ليمون ONE',
  lemon_star: 'ليمون STAR ',
  lemon_xl: 'ليمون XL ',
  lemon_box: 'ليمون BOX',
  yalla: 'يلا',
  cancel: 'إلغاء',
  note: 'ملاحظة للسائق',
  client_mobile: ' رقم هاتف العميل',
  search: 'بحث',
  estimated_price: 'سعر تقديري',
  estimated_distance: 'مسافة مقدرة',
  SDG: 'جنيه',
  KM: 'كلم',
  msg_created: 'تم إنشاء الطلب بنجاح',
  reset: 'إعادة',
  pickup: 'نقطة الإنطلاق',
  dropoff: 'نقطة النهاية',
  no_place: 'لا يوجد مكان',
  orders_list: 'قائمة الطلبات',
  reorder_successfully: 'تم اعادة الطلب بنجاح',
  are_you_sure_do_you_want_to_reorder: 'هل انت متاكد انك نريد اعادة الطلب',
  pending: 'قيد الانتظار',
  accepted: 'مقبول',
  timed_out: 'انتهى وقته',
  canceled_by_admin: 'ملغي بواسطة المشرف',
  canceled: 'ملغي',
  canceled_by_user: 'ملغي بواسطة المستخدم',
  arrived: 'وصل',
  picked: 'تم اخذه',
  completed: 'منتهي',
  pending_matching: 'يتم البحث ومعلق',
  matching_timeout: 'لم يتم العثور على راكب',
  not_show: 'لم يظهر العميل',
  canceled_by_driver: ' ملغي بواسطة السائق',
  canceled_by_system: 'ملغي بواسطة النظام',
  canceled_by_user_after_accepted: 'تم الغاؤه بواسطة المستخدم بعد القبول',
  total: 'إجمالي',
};

export default orders_ar;
