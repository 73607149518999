export const validateMobile = (mobileNumber) => {
  let mobile = mobileNumber;
  if (mobile[0] === '0') {
    mobile = mobile.substring(1);
  }
  return `+249${mobile}`;
};

export const handleTokenStore = (token) => {
  localStorage.setItem('token', JSON.stringify(token));
};

export const handleStateStore = (authState) => {
  localStorage.setItem('authState', JSON.stringify(authState));
};

export const handleUserStore = (user) => {
  localStorage.setItem('supportUser', JSON.stringify(user));
};
