import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React from 'react';
import { usePlacesTranslation } from '../../i18n';

export default function PlacesFilters({ payload, setPayload, handleApply }) {
  const t = usePlacesTranslation();

  return (
    <Grid
      container
      justifyContent={'space-between'}
      className='bg-gray-100 p-2 my-2'
    >
      {/*  */}
      <Grid item className='flex items-center justify-start' lg={10}>
        <Grid item lg={2} marginX={1}>
          <TextField
            size='small'
            label={t('place_name')}
            onChange={(e) => {
              setPayload({
                ...payload,
                place_name: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item lg={2} marginX={1}>
          <FormControl size='small' fullWidth>
            <InputLabel id='demo-simple-select-label'>{t('status')}</InputLabel>
            <Select
              sx={{ height: 40 }}
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={payload.is_deleted}
              label={t('status')}
              onChange={(e) => {
                setPayload({
                  ...payload,
                  is_deleted: e.target.value,
                });
              }}
            >
              <MenuItem value={''}>{t('all')}</MenuItem>
              <MenuItem value={true}>{t('deleted')}</MenuItem>
              <MenuItem value={false}>{t('not_deleted')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {/*  */}
      <Button variant='contained' onClick={handleApply} color='primary'>
        {t('apply')}
      </Button>
    </Grid>
  );
}
