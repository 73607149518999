import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../Redux/store/app/appSlice';
import {
  fetchCarTypes,
  fetchDrivers,
} from '../../../Redux/store/pending-drivers/pendingDriversListSlice';
import DriversTable from '../components/DriversList';
import PageHeading from '../../../components/common/PageHeading';
import ListFilter from '../components/common/ListFilter';
import { usePendingTranslation } from '../components/i18n';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const DriversListPage = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefreshing, setIsRefreshing] = React.useState(0);
  const dispatch = useDispatch();
  const t = usePendingTranslation();

  const [payload, setPayload] = useState({
    mobile: '',
    carTypeId: '',
  });

  const [searchParams, setSearchParmas] = useSearchParams(
    createSearchParams({
      page: 0,
      pageSize: 10,
      mobile: '',
      carTypeId: '',
    })
  );

  const currnetSearchParams = useMemo(() => ({
    page: searchParams.get('page'),
    pageSize: searchParams.get('pageSize'),
    mobile: searchParams.get('mobile'),
    carTypeId: searchParams.get('carTypeId'),
  }));

  const setPage = (newPage) => {
    setSearchParmas({
      ...currnetSearchParams,
      page: newPage,
    });
    setIsRefreshing((prev) => prev + 1);
  };

  const fetchEverything = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        dispatch(fetchCarTypes()),
        dispatch(fetchDrivers(currnetSearchParams)),
      ]);
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  const onApply = () => {
    setSearchParmas({
      ...currnetSearchParams,
      ...payload,
      page: 0,
    });
    setIsRefreshing((prev) => prev + 1);
  };

  useEffect(() => {
    fetchEverything();
  }, [isRefreshing, dispatch]);

  return (
    <Grid container justifyContent='center' className='mx-1' spacing={2}>
      <Grid item lg={12} md={12}>
        <PageHeading
          heading1={t('pending_drivers')}
          heading2={t('drivers_list')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>

      <Grid item lg={12} md={12} className=' bg-gray-100 '>
        <ListFilter
          onApply={onApply}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          payload={payload}
          setPayload={setPayload}
        />
      </Grid>
      <Grid item lg={12} md={12}>
        <DriversTable
          setPage={setPage}
          page={Number(currnetSearchParams.page)}
          loading={isLoading}
        />
      </Grid>
    </Grid>
  );
};

export default DriversListPage;
