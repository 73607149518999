import React, { useState } from 'react';
import Map from '../Map';
import { Grid, Button } from '@material-ui/core';
import TripsList from '../component/TripsList';
import PageHeading from '../../../components/common/PageHeading';
import LoadingIndicator from '../../../components/common/LoadingIndicator';

function LemonSharePage(props) {
  // const [isRefreshing, setIsRefreshing] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) {
    return <LoadingIndicator />;
  }
  return (
    <Grid container lg={12} md={12} className=' relative bg-gray-100'>
      <Grid item lg={12} md={12}>
        {/* <PageHeading
          // heading1={'Shared Trip'}
          // heading2={'Cars list'}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          // backBtn
        /> */}
      </Grid>
      <Grid item lg={12} md={12} className='relative z-0'>
        <Map isLoading={isLoading} setIsLoading={setIsLoading} />
      </Grid>
    </Grid>
  );
}

export default LemonSharePage;
