import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import {
  creditUserBalance,
  debitUserBalance,
  fetchUserBalance,
  fetchUserTransactions,
  setError,
} from '../../../../Redux/store/users/usersListSlice';
import { appDirection } from '../../../../shared_utils';
import { useUsersTranslation } from '../../i18n';
import TransactionsList from '../common/TransactionsList';
import UpdateBalanceForm from '../common/UpdateBalanceForm';

const Balance = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(0);
  const [loadingCount, setLoadingCount] = useState(0);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { transactions, balance } = useSelector(
    (state) => state.users
  ).usersList;

  const [page, setPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [updateType, setUpdateType] = useState('');

  const creditOptions = [
    'CLIENT_MANUAL',
    'CLIENT_REMAINDER_BALANCE',
    'CLIENT_GIFT_BALANCE',
    'CLIENT_TRIP_COMPENSATION_BALANCE',

    'CLIENT_LOYALTY_CREDIT_BALANCE',
    'CLIENT_NEW_USER_CREDIT_BALANCE',

    'CLIENT_LEMON_SHARE_REFERRER_REWARD_BALANCE',
    'CLIENT_LEMON_SHARE_REFEREE_REWARD_BALANCE',

    'CLIENT_PAYCHAT_DEPOSIT_BALANCE',
    'CLIENT_SYBER_DEPOSIT_BALANCE',
    'CLIENT_MBOK_DEPOSIT_BALANCE',
    'CLIENT_FAWRY_DEPOSIT_BALANCE',
  ];
  const debitOptions = ['CLIENT_FINE', 'CLIENT_ORDER_PAYMENT'];

  const handleCreditBalance = async (payload) => {
    try {
      await dispatch(
        creditUserBalance({
          user_id: id,
          ...payload,
        })
      );
      setIsRefreshing(isRefreshing + 1);
      setLoadingCount(0);

      toast.success(t('msg_add'));
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    }
  };

  const handleDebitBalance = async (payload) => {
    try {
      await dispatch(debitUserBalance({ user_id: id, ...payload }));
      setIsRefreshing(isRefreshing + 1);
      setLoadingCount(0);

      toast.success(t('msg_deduct'));
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    }
  };

  const fetchAllData = useCallback(async () => {
    try {
      setIsLoading(true); //FIXME: the state don't change to ture?????
      console.log(isLoading);
      await new Promise.all([
        dispatch(fetchUserBalance(id)),
        dispatch(
          fetchUserTransactions({
            user_id: id,
            page_size: 20,
            page_number: page,
          })
        ),
      ]);
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    } finally {
      console.log(isLoading);
      setIsLoading(false);
      setLoadingCount(1);
      console.log(isLoading);
    }
  });
  const t = useUsersTranslation();
  useEffect(() => {
    setIsLoading(true);
    fetchAllData();
  }, [isRefreshing, page]);

  if (isLoading && loadingCount === 0) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-[70vh] place-content-center'>
          <div class='flex items-center gap-2 text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            loading...
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <Grid container lg={12} md={12} justifyContent='center' dir={appDirection}>
      {/* header */}
      <Grid item lg={6} md={6} xs={6}>
        <Card className='w-full flex justify-center mb-4'>
          <CardContent className=''>
            <span className=' font-sans text-lg font-semibold p-2 text-gray-500'>
              {t('total_balance')}:
            </span>
            <span
              className={
                balance > 0
                  ? 'font-black text-lg text-green-700 p-2 mr-4  rounded-xl'
                  : 'font-black text-lg text-red-700 p-2 mr-4  rounded-xl'
              }
            >
              {balance} {t('SDG')}
            </span>

            {/* btn's */}

            <AddCircle
              color='primary'
              onClick={() => {
                setUpdateType('add');
                setIsOpen(true);
              }}
              className='cursor-pointer'
            />
            <RemoveCircle
              color='secondary'
              onClick={() => {
                setUpdateType('deduct');
                setIsOpen(true);
              }}
              className='cursor-pointer'
            />

            <UpdateBalanceForm
              open={isOpen}
              setOpen={setIsOpen}
              updateBalnce={
                updateType === 'add' ? handleCreditBalance : handleDebitBalance
              }
              title={
                updateType === 'add' ? t('add_balance') : t('deduct_balance')
              }
              options={updateType === 'add' ? creditOptions : debitOptions}
            />
          </CardContent>
        </Card>
      </Grid>
      {/* body  */}
      <Grid container lg={12} md={12} xs={10}>
        <Grid item lg={12} md={12} xs={9}>
          <TransactionsList
            data={transactions}
            setPage={setPage}
            loading={isLoading}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Balance;
