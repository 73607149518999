import React, { useCallback, useEffect } from 'react';
import { fetchOrders } from '../../../../Redux/store/orders/ordersListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import OrdersList from '../../../orders/components/OrdersList';

import MobileFilter from '../common/MobileFilter';
import { toast } from 'react-toastify';

const Orders = ({ driver, driverId, type }) => {
  const { orders, listPage } = useSelector((state) => state.orders).ordersList;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefreshing, setIsRefreshing] = React.useState(0);
  const [pageNum, setPageNum] = React.useState(listPage);

  const [payload, setPayload] = React.useState({
    driver_mobile: driver.mobile_number,
    user_mobile: '',
  });

  const fetchOrdersData = useCallback(async () => {
    try {
      setIsLoading(true);

      await dispatch(
        fetchOrders({
          page_number: pageNum,
          page_size: 10,
          ...payload,
        })
      );
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    fetchOrdersData();
  }, [isRefreshing, pageNum, payload]);

  return (
    <Grid container lg={12} md={12} spacing={2} justifyContent='center'>
      <Grid item lg={12} md={12}>
        <MobileFilter
          payload={payload}
          setPayload={setPayload}
          driver={driver}
          setIsRefreshing={setIsRefreshing}
          isRefreshing={isRefreshing}
        />
      </Grid>
      <Grid item lg={12} md={12}>
        <OrdersList
          data={orders}
          isLoading={isLoading}
          setPage={setPageNum}
          page={pageNum}
          setIsRefreshing={setIsRefreshing}
          isRefreshing={isRefreshing}
          type={type}
        />
      </Grid>
    </Grid>
  );
};

export default Orders;
