import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Checkbox,
  FormControlLabel,
  InputAdornment,
} from '@mui/material';
import { Divider, List, ListItem, ListItemText } from '@mui/material';
import { LocalTaxi, Search } from '@mui/icons-material';
import React, { useCallback, useState, useEffect, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import {
  fetchUserByMobile,
  getPlacesOptions,
} from '../../../../Redux/store/orders/ordersListSlice';
import { useOrdersTranslation } from '../../i18n';
import { validateMobileForQueryParams } from '../../utils';
import { validationSchema } from '../../validations/validation';
import NewOrderDetails from './NewOrderDetails';

import { useTheme } from '@mui/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const CreateOrderForm = (props) => {
  const {
    carTypeId,
    setCarTypeId,
    mobile,
    setMobile,
    tripController,
    stage,
    isLoading,
    noteToDriver,
    setNoteToDriver,
    setCoordinates,
    coordinates,
  } = props;

  const url = process.env.PORTAL_URL;

  const t = useOrdersTranslation();

  const handleReset = async (e) => {
    setSearchKeyword('');
    await setOpen(false);
    tripController('reset pickup');
  };

  const theme = useTheme();
  const inputRef = createRef();
  useEffect(() => {
    if (inputRef) {
      inputRef.current.dir = 'auto';
    }
  }, []);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [showUserName, setShowUserName] = useState(false);
  const { user } = useSelector((state) => state.orders).ordersList;
  const [open, setOpen] = useState(false);

  const handleChecking = () => {
    setOpen(!open);
  };

  const getOptions = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(getPlacesOptions(searchKeyword));
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
    } finally {
      setLoading(false);
      setShowOptions(true);
    }
  });

  const getUser = useCallback(async (mobile) => {
    try {
      setLoading(true);
      const payload = validateMobileForQueryParams(mobile);
      await dispatch(fetchUserByMobile(payload));
      setShowUserName(true);
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setLoading(false);
    }
  });
  const handleChange = useCallback(async (e) => {
    setShowUserName(false);
    const formData = {
      mobile: e.target.value,
    };
    setMobile(formData.mobile);
    const isValid = await validationSchema.isValid(formData);

    if (isValid) {
      getUser(formData.mobile);
    }
  });

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    getOptions();
  });

  const handlePickup = (e) => {
    tripController('pickup');
    setShowOptions(false);
    setSearchKeyword('');
  };
  const handleDropoff = (e) => {
    setShowOptions(false);
    tripController('dropoff');
    setSearchKeyword('');
  };

  const { options } = useSelector((state) => state.orders).ordersList;

  const ITEM_HEIGHT = 48;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const opens = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectingLocation = ({ lat, lng, name }) => {
    const newCoordinates = { lat: lat, lng: lng };
    setSearchKeyword(name);
    if (stage === 'pickup') {
      setCoordinates({
        ...coordinates,
        pickup: newCoordinates,
        center: newCoordinates,
      });
    } else if (stage === 'check') {
      setCoordinates({
        ...coordinates,
        dropoff: newCoordinates,
        center: newCoordinates,
      });
    }
  };

  return (
    <Grid container lg={12} md={12} justifyContent='center'>
      {loading && (
        <Grid item lg={12} md={12}>
          <LinearProgress />
        </Grid>
      )}
      <List
        sx={{
          width: '100%',
          fontWeight: 'bold',
        }}
      >
        <ListItem>
          <ListItemText primary={t('create_order')} />
        </ListItem>
        <Divider component='li' />
      </List>

      <Grid item lg={10} md={10} className=' '>
        <form onSubmit={handleSubmit}>
          <Grid className='w-full relative pt-2 '>
            <TextField
              disabled={stage === 'checked' ? true : false}
              variant='outlined'
              id='car_model_year'
              label={t('search')}
              InputLabelProps={{ shrink: true }}
              type='tel'
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleClick}>
                      <Search
                        aria-label='more'
                        id='long-button'
                        aria-controls={opens ? 'long-menu' : undefined}
                        aria-expanded={opens ? 'true' : undefined}
                        aria-haspopup='true'
                        onClick={(e) => getOptions()}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item lg={2} md={2}>
            <Menu
              id='long-menu'
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={opens}
              onClose={handleClose}
              PaperProps={{
                style: {
                  // direction: 'rtl',
                  maxHeight: ITEM_HEIGHT * 6,
                  width: '35ch',
                },
              }}
              transformOrigin={
                theme.direction == 'ltr'
                  ? { horizontal: 'right', vertical: 'top' }
                  : { horizontal: 'left', vertical: 'top' }
              }
              anchorOrigin={
                theme.direction == 'ltr'
                  ? { horizontal: 'right', vertical: 'bottom' }
                  : { horizontal: 'left', vertical: 'bottom' }
              }
            >
              {options.length == '' ? (
                <Grid className='flex justify-center'>{t('no_place')}</Grid>
              ) : (
                options.map((option) => (
                  <MenuItem
                    value={option}
                    key={option.name_en}
                    onClick={(e) =>
                      handleSelectingLocation({
                        lng: option.longitude,
                        lat: option.latitude,
                        name: option.display_name,
                      })
                    }
                  >
                    {option.name_en || option.name_ar}
                  </MenuItem>
                ))
              )}
            </Menu>
            {/* </Grid> */}
          </Grid>
          <Grid item className='pt-4'>
            <TextField
              variant='outlined'
              // margin='dense'
              id='car_model_year'
              label={t('client_mobile')}
              type='tel'
              value={mobile}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputRef={inputRef}
            />
          </Grid>

          {/* route_details */}
          <Grid container lg={10} md={10} className='py-4 font-bold'>
            {t('route_details')}
          </Grid>
          <Grid>
            <Grid container lg={12} md={12} className='text-sm'>
              {t('pickup_location')}
            </Grid>

            <Grid item lg={12} md={12} className='py-2 flex justify-evenly'>
              {stage === 'pickup' ? (
                <Button variant='outlined' fullWidth onClick={handlePickup}>
                  {t('pickup')}
                </Button>
              ) : (
                <Button
                  variant='outlined'
                  color='error'
                  // size='small'
                  fullWidth
                  onClick={handleReset}
                >
                  {t('reset')}
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid>
            <Grid container lg={12} md={12} className='text-sm'>
              {t('dropoff_location')}
            </Grid>

            <Grid item lg={12} md={12} className='py-2'>
              <Button
                disabled={stage === 'check' ? false : true}
                variant='outlined'
                // color='primary'
                // size='small'
                fullWidth
                onClick={handleDropoff}
              >
                {t('dropoff')}
              </Button>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} xs={12} className=''>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={(e) => tripController('open trip')}
                  disabled={stage === 'check' ? false : true}
                  // checked={(e) => tripController('open trip')}
                  onChange={handleChecking}
                  checked={open}
                  name='checkedB'
                  color='primary'
                />
              }
              label={t('open_trip')}
            />
          </Grid>
        </form>
      </Grid>
      {/* car types */}

      {stage === 'pickup' ||
      stage === 'check' ||
      stage === 'check with dropoff' ? (
        <Grid
          container
          lg={12}
          md={12}
          spacing={1}
          className='flex justify-center '
        >
          <Grid container lg={10} md={10} className='py-4 px-2 font-bold'>
            {t('select_car')}
          </Grid>
          <Grid item lg={5} md={5}>
            <Button
              color={carTypeId === 1 ? 'primary' : 'dark'}
              variant={carTypeId === 1 ? 'contained' : 'outlined'}
              className=' w-full'
              size='small'
              // sx={{ textSizeAdjust:'' }}
              onClick={() => setCarTypeId(1)}
              // className='h-full'
            >
              <LocalTaxi fontSize='large' />

              {t('lemon_go')}
            </Button>
          </Grid>

          <Grid item lg={5} md={5}>
            <Button
              color={carTypeId === 5 ? 'primary' : 'dark'}
              variant={carTypeId === 5 ? 'contained' : 'outlined'}
              className='w-full'
              size='small'
              onClick={() => setCarTypeId(5)}
            >
              <LocalTaxi fontSize='large' />
              {t('lemon_one')}
            </Button>
          </Grid>
          <Grid item lg={5} md={5}>
            <Button
              color={carTypeId === 2 ? 'primary' : 'dark'}
              variant={carTypeId === 2 ? 'contained' : 'outlined'}
              className='w-full '
              size='small'
              onClick={() => setCarTypeId(2)}
            >
              <LocalTaxi fontSize='large' />
              {t('lemon_star')}
            </Button>
          </Grid>
          <Grid item lg={5} md={5}>
            <Button
              color={carTypeId === 3 ? 'primary' : 'dark'}
              variant={carTypeId === 3 ? 'contained' : 'outlined'}
              className='w-full'
              size='small'
              onClick={() => setCarTypeId(3)}
            >
              <LocalTaxi fontSize='large' />
              {t('lemon_xl')}
            </Button>
          </Grid>
          <Grid item lg={5} md={5}>
            <Button
              color={carTypeId === 4 ? 'primary' : 'dark'}
              variant={carTypeId === 4 ? 'contained' : 'outlined'}
              className='w-full'
              size='small'
              onClick={() => setCarTypeId(4)}
            >
              <LocalTaxi fontSize='large' />
              {t('lemon_box')}
            </Button>
          </Grid>
        </Grid>
      ) : null}
      {isLoading && stage !== 'start trip' ? (
        <Grid container lg={12} md={12} justifyContent='center'>
          <div class='grid min-h-screen place-content-center'>
            <div class='flex items-center  text-xl text-gray-600'>
              <span class='h-6 w-6 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
              checking ...
            </div>
          </div>
        </Grid>
      ) : null}
      {!isLoading ? (
        stage === 'checked' ||
        stage === 'open trip' ||
        stage === 'start trip' ? (
          <Grid
            item
            className=' w-5/6 p-2 absolute bottom-4 shadow-lg shadow-gray-300'
          >
            <Grid item lg={12} md={12} className='pb-1'>
              {stage === 'open trip' ? null : <NewOrderDetails stage={stage} />}
            </Grid>
            <Grid item lg={12} md={12} className='pb-1'>
              <TextField
                variant='outlined'
                // margin='dense'
                id='note'
                label={t('note')}
                multiline
                // maxRows={2}
                value={noteToDriver}
                onChange={(e) => setNoteToDriver(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid
              container
              lg={12}
              md={12}
              spacing={1}
              className='p-2 flex justify-center'
            >
              <Grid item lg={8} md={8}>
                <Button
                  disabled={isLoading ? true : false}
                  color='primary'
                  variant='contained'
                  fullWidth
                  onClick={(e) => tripController('start')}
                >
                  {t('yalla')}
                </Button>
              </Grid>
              <Grid item lg={4} md={4}>
                <Button
                  disabled={isLoading ? true : false}
                  fullWidth
                  color='inherit'
                  variant='contained'
                  onClick={(e) => tripController('done')}
                >
                  {t('cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : null
      ) : null}
    </Grid>
  );
};

export default CreateOrderForm;
