import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { Avatar, Chip, fabClasses, Grid } from '@mui/material';
import DoneIcon from '@material-ui/icons/Done';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import { use } from 'i18next';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ArrowDropDown, ArrowDropDownCircle, FilterListRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    margin: '0px 0px 10px 0px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function ListFiler({ setIsRefreshing, isRefreshing, statusId, setStatusId }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Grid container lg={12} md={12} justifyContent className='p-1 bg-gray-200 rounded-t-xl'>
      <Grid item lg={12} md={12} className='text-sm flex justify-center'>
        <div className='my-2 flex sm:flex-row flex-col'>
          <div className='flex flex-row mb-1 sm:mb-0 shadow-lg'>
            {/* status menu  */}
            <div className='relative'>
              <select
                className=' h-full rounded-r border-t sm:rounded-r-none sm:border-r-0 border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500'
                value={statusId}
                onChange={(e) => setStatusId(e.target.value)}>
                <option selected disabled>
                  Status
                </option>
                <option value={0}>New</option>
                <option value={1}>Under Process</option>
                <option value={2}>Closed</option>
              </select>
              <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                <svg
                  className='fill-current h-4 w-4'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'>
                  <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                </svg>
              </div>
            </div>
          </div>

          {/* <div className=' pl-4 pt-1'>
            <Button
              color='primary'
              variant='contained'
              size='small'
              className='ml-1'
              onClick={() => setIsRefreshing(isRefreshing + 1)}>
              <RefreshIcon fontSize='small' />
            </Button>
          </div> */}
        </div>
      </Grid>
    </Grid>
  );
}
