// This dictionary contains 291 untranslated or inherited localization strings.
// These strings are commented out. Uncomment and edit them if you want to add your translations.
import { editorLocalization } from "survey-creator-core";

var persianStrings = {
  // survey templates
  survey: {
    edit: "ویرایش",
    externalHelpLink: "نگاه کنید و ساختن نظرسنجی را آموزش ببینید",
    externalHelpLinkUrl: "https://www.youtube.com/channel/UCH2ru9okIcooNZfpIbyq4qQ?view_as=subscriber",
    dropQuestion: "لطفا از جعبه ابزار سوالی در اینجا قرار دهید",
    addLogicItem: "برای شخصی سازی نظرسنجی خود، قانونی را ایجاد کنید",
    copy: "کپی",
    duplicate: "کپی کردن",
    addToToolbox: "افزودن به جعبه ابزار",
    deletePanel: "حذف پنل",
    deleteQuestion: "حذف سوال",
    convertTo: "تبدیل به",
    drag: "المان را بکشید"
  },
  // Question types
  qt: {
    default: "پیش فرض",
    checkbox: "چند انتخابی",
    comment: "نظر",
    imagepicker: "انتخاب عکس",
    ranking: "رتبه بندی(رنک)",
    image: "عکس",
    dropdown: "لیست انتخابی",
    // tagbox: "Tag Box",
    file: "فایل",
    html: "Html",
    matrix: "ماتریس (تک انتخابی)",
    matrixdropdown: "ماتریس (چند انتخابی)",
    matrixdynamic: "ماتریس (سطرهای داینامیک)",
    multipletext: "متن چند خطی",
    panel: "پنل",
    paneldynamic: "پنل (پنل های داینامیک)",
    radiogroup: "تک انتخابی",
    rating: "رتبه بندی",
    text: "متن تک خطی",
    boolean: "صحیح و غلط",
    expression: "توصیفی",
    signaturepad: "قسمت امضا",
    buttongroup: "گروه دکمه"
  },
  // Strings in SurveyJS Creator
  ed: {
    defaultLocale: "پیش فرض ({0})",
    survey: "نظرسنجی",
    settings: "تنظیمات نظرسنجی",
    settingsTooltip: "باز کردن تنظیمات نظرسنجی",
    // surveySettings: "Survey Settings",
    // surveySettingsTooltip: "Open survey settings",
    showPanel: "نمایش پنل",
    hidePanel: "پنهان سازی پنل",
    prevSelected: "قبلی",
    nextSelected: "بعدی",
    surveyTypeName: "نظرسنجی",
    pageTypeName: "صفحه",
    panelTypeName: "پنل",
    questionTypeName: "سوال",
    columnTypeName: "ستون",
    addNewPage: "درج صفحه جدید",
    moveRight: "اسکرول به راست",
    moveLeft: "اسکرول به چپ",
    deletePage: "حذف صفحه",
    editPage: "ویرایش صفحه",
    edit: "ویرایش",
    newPageName: "صفحه",
    newQuestionName: "سوال",
    newPanelName: "پنل",
    newTextItemName: "متن",
    testSurvey: "آزمایش نظرسنجی",
    defaultV2Theme: "پیش فرض",
    modernTheme: "مدرن",
    defaultTheme: "پیش فرض (legacy)",
    testSurveyAgain: "آزمایش دوباره نظرسنجی",
    testSurveyWidth: "عرض نظرسنجی: ",
    navigateToMsg: "شما باید به موارد زیر بروید:",
    logic: "منطق نظرسنجی",
    embedSurvey: "نظرسنجی توکار",
    translation: "ترجمه",
    saveSurvey: "ذخیره نظرسنجی",
    saveSurveyTooltip: "ذخیره نظرسنجی",
    designer: "طراح نظرسنجی",
    jsonEditor: "ویرایشگر JSON",
    jsonHideErrors: "پنهان کردن خطاها",
    jsonShowErrors: "نمایش خطاها",
    undo: "بازگردانی",
    redo: "بازانجام",
    undoTooltip: "بازگرداندن آخرین تغییر",
    redoTooltip: "انجام دادن تغییر",
    showMoreChoices: "نمایش بیشتر",
    showLessChoices: "نمایش کمتر",
    copy: "کپی",
    cut: "برداشتن",
    paste: "چسباندن",
    copyTooltip: "کپی متن انتخاب شده در کلیپ بورد",
    cutTooltip: "متن انتخابی را از کلیپ بورد برش دهید",
    pasteTooltip: "الصاق از کلیپ بورد",
    options: "انتخاب ها",
    generateValidJSON: "تولید کد معتبر JSON",
    generateReadableJSON: "تولید کد خوانا JSON",
    toolbox: "جعبه ابزار",
    "property-grid": "ویژگی ها",
    propertyGridFilteredTextPlaceholder: "جستجو...",
    toolboxGeneralCategory: "عمومی",
    // toolboxChoiceCategory: "Choice Questions",
    // toolboxTextCategory: "Text Input Questions",
    // toolboxContainersCategory: "Containers",
    // toolboxMatrixCategory: "Matrix Questions",
    // toolboxMiscCategory: "Misc",
    correctJSON: "لطفا JSON را تصحیح کنید.",
    surveyResults: "نتایج نظرسنجی: ",
    surveyResultsTable: "به صورت جدول",
    surveyResultsJson: "به صورت JSON",
    resultsTitle: "عنوان سوال",
    resultsName: "نام سوال",
    resultsValue: "محتوای پاسخ",
    resultsDisplayValue: "محتوای نمایشی",
    modified: "تغییر داده شده",
    saving: "در حال ذخیره",
    saved: "ذخیره شد",
    propertyEditorError: "ارور:",
    saveError: "خطا! محتوای ویرایشگر ذخیره نشده است.",
    translationPropertyGridTitle: "تنظیمات زبان",
    translationLanguages: "زبان",
    translationAddLanguage: "زبانی را برای ترجمه انتخاب کنید",
    translationShowAllStrings: "نمایش تمام عبارات",
    translationShowUsedStringsOnly: "فقط از عبارات استفاده کنید",
    translationShowAllPages: "نمایش تمام صفحات",
    translationNoStrings: "هیچ عبارتی برای ترجمه نیست، لطفا فیلتر را عوض کنید.",
    translationExportToSCVButton: "برون بری به CSV",
    translationImportFromSCVButton: "برون ریزی از CSV",
    translationMergeLocaleWithDefault: "ادغام {0} با زبان پیش فرض",
    translationPlaceHolder: "در حال ترجمه...",
    bold: "پررنگ",
    italic: "مورب",
    underline: "پایین خط",
    addNewQuestion: "اضافه کردن سوال",
    selectPage: "انتخاب صفحه ...",
    htmlPlaceHolder: "محتوای html اینجا خواهد بود",
    panelPlaceHolder: "سوالی را از داخل جعبه ابزار در اینجا قرار دهید",
    surveyPlaceHolder: "نظرسنجی خالی است. یک آیتم را از جعبه ابزار انتخاب یا روی دکمه ی زیر کلیک کنید",
    addNewTypeQuestion: "اضافه {0}", //{0} is localizable question type
    chooseLogoPlaceholder: "[لوگو]",
    // auto: "auto",
    lg: {
      addNewItem: "اضافه کردن آیتم جدید",
      empty_tab: "یک آیتم را برای سفارشی کردن جریان نظرسنجی انتخاب کنید",
      page_visibilityName: "قابلیت رویت صفحه ",
      page_enableName: "فعال/غیرفعال کردن صفحه",
      panel_visibilityName: "قابلیت رویت پنل",
      panel_enableName: "فعال/غیرفعال کردن پنل ",
      question_visibilityName: " قابلیت رویت سوال",
      question_enableName: "فعال/غیرفعال کردن سوال ",
      question_requireName: "سوال اختیاری موردنیاز",
      column_visibilityName: "نمایش/پنهان کردن ستون",
      column_enableName: "فعال/غیرفعال کردن ستون",
      column_requireName: "ستون مورد نیاز است",
      trigger_completeName: "تکمیل نظرسنجی",
      trigger_setvalueName: "تنظیم عنوان سوال",
      trigger_copyvalueName: "کپی مقدار سوال",
      trigger_skipName: "رد کردن سوال ",
      trigger_runExpressionName: "اجرای متن شفارشی مورد نظر",
      completedHtmlOnConditionName: "متن سفارشی صفحه 'تشکر' ",
      page_visibilityDescription: "زمانی که عبارت منطقی مقدار صحیح برمی گرداند صفحه را قابل مشاهده کنید، در غیراینصورت آن را غیرقابل مشاهده نگه دارید.",
      panel_visibilityDescription: "اگر عبارت منطقی مقدار صحیح بر میگرداند، پنل را فعال کنید، در غیر اینصورت آن را غیرقابل مشاهده نگه دارید.",
      panel_enableDescription: "یک پنل ایجاد کنید و همه المان های داخل آن را در صورتی که عبارت منطقی مقدار صحیح بر می گرداند را فعال کنید، در غیر اینصورت آن ها را غیرفعال نگه دارید.",
      question_visibilityDescription: "زمانی که عبارت منطقی مقدار صحیح را برمی گرداند، سوال را قابل مشاهده کنید، در غیر اینصورت آن را غیرمشاهده نگه دارید.",
      question_enableDescription: "زمانی که عبارت منطقی مقدار صحیح برمی گرداند، سوال را فعال کنید، در غیر اینصورت آن را غیرفعال نگه دارید.",
      question_requireDescription: "زمانی که عبارت منطقی مقدار صحیح را بر می گرداند، سوال اجبار ی می شود.",
      trigger_completeDescription: "زمانی که عبارت منطقی مقدار صحیح را بر می گرداند، و بررسی تکمیل می شود،کاربر نهایی صفحه 'تشکر' را مشاهده می کند.",
      trigger_setvalueDescription: "زمانی که مقدار سوالی که در عبارت منطقی استفاده شده است تغییر می یابد و عبارت منطقی مقدار صحیح را بر می گرداند، آنگاه مقدار برای سوال انتخاب شده تنظیم می شود.",
      trigger_copyvalueDescription: "زمانی که مقدارهای سوال که در عبارت منطقی استفاده شده اند، تغییر می یابد و عبارت منطقی مقدار صحیح را بر می گرداند، سپس مقدار یکی از سوال های انتخاب شده در سوال دیگر انتخاب شده کپی می شود.",
      trigger_skipDescription: "زمانی که عبارت منطقی صحیح برمی گرداند، سپس نظرسنجی به سوال انتخابی می رود.",
      trigger_runExpressionDescription: "اگر عبارت منطقی مقدار صحیح برگرداند، آنگاه عبارت سفارشی اجرا می شود. شما ممکن است به طور اختیاری این نتیجه را به سوال انتخاب شده تبدیل کنید.",
      completedHtmlOnConditionDescription: " اگر عبارت منطقی مقدار صحیح برگرداند متن صفحه 'تشکر' به متن داده شده تغییر می یابد.",
      itemExpressionText: "زمانی که عبارت {0} مقدار صحیح بر می گرداند", //{0} - the expression
      itemEmptyExpressionText: "قانون جدید",
      page_visibilityText: "صفحه {0} را قابل مشاهده کنید.", //{0} page name
      panel_visibilityText: "پنل {0} را قابل مشاهده کنید.", //{0} panel name
      panel_enableText: "فعال کردن پنل {0}", //{0} panel name
      question_visibilityText: "سوال {0} را قابل مشاهده کنید.", //{0} question name
      question_enableText: "فعال کردن سوال {0}", //{0} question name
      question_requireText: "ایجاد سوال {0} اجباری", //{0} question name
      column_visibilityText: "ستون {0} سوال {1} را قابل مشاهده کنید", //{0} column name, {1} question name
      column_enableText: "ستون {0} سوال {1} را فعال کنید", //{0} column name, {1} question name
      column_requireText: "ستون {0} سوال {1} را الزامی کنید", //{0} column name, {1} question name
      trigger_completeText: "بررسی نظرسنجی تمام شد",
      trigger_setvalueText: "در سوال {0} مقدار {1} را قرار دهید", //{0} question name, {1} setValue
      trigger_copyvalueText: "مقدار سوال {0} را از سوال {1} کپی کنید.", //{0} and {1} question names
      trigger_skipText: "رد کردن نظرسنجی برای سوال {0}", //{0} question name
      trigger_runExpressionText1: "اجرای عبارت '{0}' ", //{0} the expression
      trigger_runExpressionText2: "و نتیجه آن را داخل سوال {0} ذخیره کنید", //{0} question name
      completedHtmlOnConditionText: "نمایش متن سفارشی برای صفحه 'تشکر'",
      showAllQuestions: "تمام سوالات",
      showAllActionTypes: "همه انواع اقدام",
      conditions: "شرط(ها)",
      actions: "عملکرد(ها)",
      expressionEditorTitle: "عبارت منطقی را تنظیم کنید",
      actionsEditorTitle: "تعریف کردن عملکرد(ها)",
      deleteAction: "حذف عملکرد",
      addNewAction: "اضافه کردن سوال (های) جدید",
      selectedActionCaption: "یک عملکرد را برای اضافه کردن، انتخاب کنید",
      expressionInvalid: "عبارت منطقی خالی یا نادرست است. لطفا آن را تضحیح کنید",
      noActionError: "لطفا حداقل یک عملکرد را اضافه نمایید",
      actionInvalid: "لطفا مشکلات مربوط به عملکرد(های) خود را برطرف نمایید.",
      expressionSetup: "تنظیمات عبارت ها",
      actionsSetup: "تنظیمات عملکردها"
    }
  },
  // Property Editors
  pe: {
    apply: "اعمال",
    ok: "تایید",
    save: "ذخیره",
    // clear: "Clear",
    saveTooltip: "ذخیره",
    cancel: "لغو",
    // set: "Set",
    reset: "بازنشانی",
    // change: "Change",
    refresh: "تازه سازی",
    close: "بستن",
    delete: "حذف",
    add: "اضافه کردن",
    addNew: "افزودن",
    addItem: "برای اضافه کردن یک آیتم کلیک کنید...",
    // removeItem: "Click to remove the item...",
    // dragItem: "Drag the item",
    addOther: "سایر",
    addSelectAll: "انتخاب همه",
    addNone: "هیچکدام",
    removeAll: "حذف همه",
    edit: "ویرایش",
    back: "بازگشت بدون ذخیره",
    backTooltip: "بازگشت بدون ذخیره",
    saveAndBack: "ذخیره و بازگشت",
    saveAndBackTooltip: "ذخیره و بازگشت",
    // doneEditing: "Done",
    editChoices: "ویرایش انتخاب ها",
    showChoices: "نمایش انتخاب ها",
    move: "جابجایی",
    empty: "<خالی>",
    emptyValue: "مقدار خالی است",
    fastEntry: "تکمیل سریع",
    // fastEntryNonUniqueError: "Value '{0}' is not unique",
    // fastEntryChoicesCountError: "Please limit the number of items from {0} to {1}",
    // fastEntryPlaceholder: "You can set data in the following format:\nvalue1|text\nvalue2",
    formEntry: "تکمیل فرم",
    testService: "بررسی سرویس",
    itemSelectorEmpty: "لطفا یک المان را انتخاب کنید ",
    conditionActionEmpty: "لطفا عملکرد را انتخاب کنید",
    conditionSelectQuestion: "انتخاب سوال...",
    conditionSelectPage: "انتخاب صفحه...",
    conditionSelectPanel: "انتخاب پنل...",
    conditionValueQuestionTitle: "لطفا مقدار را انتخاب / وارد کنید",
    expressionHelp: "لطفا یک عبارت توصیفی را وارد کنید. شما ممکن است از کروشه برای دسترسی به مقدار سوالات استفاده کنید. برای مثال: {question1} = 'value1' or ({question2} = 3 and {question3} < 5)",
    aceEditorHelp: "برای مشاهده نکات تکمیلی ctrl+space را بفشارید",
    aceEditorRowTitle: "سطر فعلی",
    aceEditorPanelTitle: "پنل فعلی",
    showMore: "برای اطلاعات بیشتر لطفا سند راهنما را مطالعه کنید",
    assistantTitle: "سوالات موجود:",
    cellsEmptyRowsColumns: "حداقل باید یک سطر یا ستون وجود داشته باشد.",
    // showPreviewBeforeComplete: "Preview answers before submitting the survey",
    propertyIsEmpty: "لطفا یک مقدار وارد کنید",
    propertyIsNoUnique: "لطفاً یک مقدار منحصر به فرد وارد کنید",
    propertyNameIsNotUnique: "لطفاً یک نام منحصر به فرد وارد کنید",
    listIsEmpty: "یک آیتم جدید اضافه کنید",
    // "listIsEmpty@choices": "No choices have been added yet",
    // "addNew@choices": "Add a choice",
    expressionIsEmpty: "عبارت خالی است",
    value: "مقدار",
    text: "متن",
    rowid: "شناسه ی سطر",
    imageLink: "لینک تصویر",
    columnEdit: "ویرایش ستون: {0}",
    itemEdit: "ویرایش آیتم: {0}",
    url: "آدرس وب سایت(url)",
    path: "آدرس",
    valueName: "نام مقدار",
    choicesbyurl: {
      // valueName: "Get values from the following JSON field"
    },
    titleName: "نام عنوان",
    // imageLinkName: "Get image URLs from the following JSON field",
    // allowEmptyResponse: "Allow empty response",
    titlePlaceholder: "عنوان را اینجا وارد کنید",
    surveyTitlePlaceholder: "عنوان نظرسنجی را اینجا وارد کنید",
    pageTitlePlaceholder: "عنوان صفحه را اینجا وارد کنید",
    descriptionPlaceholder: "توضیحات را وارد کنید",
    surveyDescriptionPlaceholder: "توضیحات نظرسنجی را وارد کنید",
    pageDescriptionPlaceholder: "توضیحات صفحه را وارد کنید",
    showOtherItem: "دارای آیتم دیگر",
    otherText: "سایر آیتم های متن",
    showNoneItem: "هیچ آیتمی ندارد",
    noneText: "هیچ آیتم متنی وجود ندارد",
    showSelectAllItem: "همه موارد انتخاب شده است",
    selectAllText: "انتخاب همه آیتم های متن",
    choicesMin: "حداقل مقدار برای ایجاد خودکار آیتم ها",
    choicesMax: "حداکثر مقدار برای ایجاد خود آیتم ها",
    choicesStep: "تفاوت بین موارد تولید شده خودکار",
    name: "نام",
    title: "عنوان",
    cellType: "نوع سلول",
    colCount: "تعداد ستون",
    choicesOrder: "ترتیب گزینه را انتخاب کنید",
    visible: "نمایش داده شود؟",
    isRequired: "ضروری است؟",
    isAllRowRequired: "نیاز به پاسخ برای همه سطرها",
    requiredErrorText: "متن خطای موردنیاز",
    startWithNewLine: "با سطر جدید شروع شود؟",
    rows: "تعداد سطر",
    cols: "تعداد ستون",
    placeholder: "نگهدارنده متن",
    showPreview: "پیش نمایش تصویر نشان داده شود؟",
    storeDataAsText: "ذخیره کردن محتوای فایل در JSON به عنوان متن",
    maxSize: "حداکثر سایز به بایت",
    imageHeight: "ارتفاع تصویر",
    imageWidth: "عرض تصویر",
    rowCount: "تعداد سطر",
    columnLayout: "قالب ستون ها",
    addRowLocation: "اضافه کردن موقعیت دکمه سطری",
    addRowText: "متن دکمه درج سطر",
    removeRowText: "متن دکمه حذف سطر",
    rateMin: "حداقل میزان",
    rateMax: "حداکثر میزان",
    rateStep: "میزان مراحل",
    minRateDescription: "توضیح حداقل امتیاز",
    maxRateDescription: "توضیح حداکثر امتیاز",
    inputType: "نوع ورودی",
    optionsCaption: "نوشته انتخاب ها",
    defaultValue: "مقدار پیش فرض",
    cellsDefaultRow: "متن پیش فرض سلول ها",
    surveyEditorTitle: "ویرایش نظرسنجی",
    qEditorTitle: "ویرایش سوال: {0}",
    maxLength: "ماکزیمم طول",
    buildExpression: "ساختن",
    editExpression: "ویرایش",
    // and: "and",
    // or: "or",
    remove: "حذف",
    addCondition: "اضافه کردن شرط",
    // emptyLogicPopupMessage: "Select a question to start configuring conditions.",
    // if: "If",
    // then: "then",
    // setToName: "Target question",
    // fromName: "Question to copy answer from",
    // gotoName: "Question to skip to",
    // ruleIsNotSet: "Rule is incorrect",
    // includeIntoResult: "Include into survey results",
    showTitle: "نمایش/پنهان کردن عنوان",
    expandCollapseTitle: "نمایش/پنهان کردن عنوان",
    locale: "زبان پیش فرض",
    simulator: "انتخاب دستگاه",
    landscapeOrientation: "افقی",
    // portraitOrientation: "Switch to portrait orientation",
    mode: "حالت (ویرایش/خواندن)",
    clearInvisibleValues: "پاکسازی مقادیر پنهان",
    cookieName: "نام کوکی (به منظور جلوگیری از اجرای دوباره نظرسنجی)",
    sendResultOnPageNext: "ارسال نتایج نظرسنجی در صفحه بعدی",
    storeOthersAsComment: "ذخیره مقدار 'سایر' در فیلد جداگانه",
    showPageTitles: "نمایش عنوان صفحات",
    showPageNumbers: "نمایش شماره صفحات",
    pagePrevText: "متن دکمه صفحه قبلی",
    pageNextText: "متن دکمه صفحه بعدی",
    completeText: "متن دکمه تکمیل نظرسنجی",
    previewText: "پیش نمایش متن دکمه",
    editText: "ویرایش متن دکمه",
    startSurveyText: "متن دکمه شروع نظرسنجی",
    showNavigationButtons: "نمایش دکمه های ناوبری (ناوبری پیش فرض)",
    showPrevButton: "نمایش دکمه قبلی (کاربر ممکن است به صفحه قبل برگردد)",
    firstPageIsStarted: "صفحه اول در نظرسنجی نقطه آغازین آن است.",
    showCompletedPage: "نمایش صفحه اتمام نظرسنجی در پایان (completedHtml)",
    goNextPageAutomatic: "با پاسخگویی به تمام سوالات، به صورت اتوماتیک به صفحه بعد برود",
    showProgressBar: "نمایش نشانگر پیشرفت",
    questionTitleLocation: "محل عنوان سوال",
    requiredText: "سوالات نشان دار اجباری هستند",
    questionStartIndex: "نمایه شروع سوالات (۱،۲ یا a و b)",
    showQuestionNumbers: "نمایش شماره های سوالات",
    questionTitleTemplate: "قالب عنوان سوال، به صورت پیش فرض: '{no}. {require} {title}'",
    questionErrorLocation: "محل خطای سوال",
    focusFirstQuestionAutomatic: "تمرکز بر روی اولین سوال با تغییر صفحه",
    questionsOrder: "ترتیب المان ها در صفحه",
    maxTimeToFinish: "نهایت زمان برای اتمام نظرسنجی",
    maxTimeToFinishPage: "نهایت زمان برای اتمام این صفحه نظرسنجی",
    page: {
      // maxTimeToFinish: "Time limit to finish the page (in seconds)"
    },
    question: {
      // page: "Parent page"
    },
    showTimerPanel: "نمایش پنل زمان سنج",
    showTimerPanelMode: "نمایش حالت پنل زمان سنج",
    renderMode: "حالت رندر",
    allowAddPanel: "اجازه افزودن پنل",
    allowRemovePanel: "اجازه حذف پنل",
    // noEntriesText: "Empty entries text",
    panelAddText: "متن افزودن پنل",
    panelRemoveText: "متن حذف پنل",
    isSinglePage: "نمایش تمام المان ها در یک صفحه",
    html: "Html",
    expression: "عبارت",
    // setValue: "Answer",
    // dataFormat: "Image format",
    // allowAddRows: "Allow adding rows",
    // allowRemoveRows: "Allow removing rows",
    // allowRowsDragAndDrop: "Allow row drag and drop",
    // responsiveImageSizeHelp: "Does not apply if you specify the exact image width or height.",
    // minImageWidth: "Minimum image width",
    // maxImageWidth: "Maximum image width",
    // minImageHeight: "Minimum image height",
    // maxImageHeight: "Maximum image height",
    minValue: "حداقل مقدار",
    maxValue: "حداکثر مقدار",
    minLength: "حداقل طول",
    allowDigits: "امکان ورود اعداد",
    minCount: "حداقل تعداد ",
    maxCount: "حداکثر مقدار",
    regex: "فرمت صحیح عبارت ",
    surveyvalidator: {
      // text: "Error message",
      // expression: "Validation expression"
    },
    totalText: "متن کامل",
    totalType: "نوع کامل",
    totalExpression: "عبارت کامل",
    totalDisplayStyle: "سبک نمایش کل",
    totalCurrency: "مجموع ارز",
    totalFormat: "مجموع فرمت",
    // logo: "Logo (URL or base64-encoded string)",
    // questionsOnPageMode: "Survey structure",
    // maxTextLength: "Maximum answer length (in characters)",
    // maxOthersLength: "Maximum comment length (in characters)",
    // autoGrowComment: "Auto-expand comment area if necessary",
    // textUpdateMode: "Update text question value",
    // focusOnFirstError: "Set focus on the first invalid answer",
    // checkErrorsMode: "Run validation",
    // navigateToUrl: "Navigate to URL",
    // navigateToUrlOnCondition: "Dynamic URL",
    // completedBeforeHtml: "Markup to show if the user already filled out this survey",
    // completedHtml: "Survey Complete page markup",
    // completedHtmlOnCondition: "Dynamic Survey Complete page markup",
    // loadingHtml: "Markup to show while survey model is loading",
    // commentText: "Comment area text",
    // autocomplete: "Autocomplete type",
    // labelTrue: "\"True\" label",
    // labelFalse: "\"False\" label",
    // allowClear: "Show the Clear button",
    // displayStyle: "Value display style",
    // format: "Formatted string",
    // maximumFractionDigits: "Maximum fractional digits",
    // minimumFractionDigits: "Minimum fractional digits",
    // useGrouping: "Display grouping separators",
    // allowMultiple: "Allow multiple files",
    // allowImagesPreview: "Preview images",
    // acceptedTypes: "Accepted file types",
    // waitForUpload: "Wait for the upload to complete",
    // needConfirmRemoveFile: "Confirm file deletion",
    // detailPanelMode: "Detail panel location",
    // minRowCount: "Minimum row count",
    // maxRowCount: "Maximum row count",
    // confirmDelete: "Confirm row deletion",
    // confirmDeleteText: "Confirmation message",
    paneldynamic: {
      // confirmDelete: "Confirm panel deletion"
    },
    // panelCount: "Initial panel count",
    // minPanelCount: "Minimum panel count",
    // maxPanelCount: "Maximum panel count",
    // panelsState: "Inner panel expand state",
    // templateDescription: "Description template",
    // templateTitle: "Title template",
    // panelPrevText: "Previous Panel button tooltip",
    // panelNextText: "Next Panel button tooltip",
    // showRangeInProgress: "Show progress bar",
    // templateTitleLocation: "Question title location",
    // panelRemoveButtonLocation: "Remove Panel button location",
    // hideIfRowsEmpty: "Hide the question if there are no rows",
    // hideColumnsIfEmpty: "Hide columns if there are no rows",
    // rateValues: "Custom rate values",
    // hideIfChoicesEmpty: "Hide the question if it contains no choices",
    // hideNumber: "Hide question number",
    // minWidth: "Minimum width (in CSS-accepted values)",
    // maxWidth: "Maximum width (in CSS-accepted values)",
    // width: "Width (in CSS-accepted values)",
    // showHeader: "Show column headers",
    // horizontalScroll: "Show horizontal scrollbar",
    // columnMinWidth: "Minimum column width (in CSS-accepted values)",
    // rowTitleWidth: "Row header width (in CSS-accepted values)",
    // valueTrue: "\"True\" value",
    // valueFalse: "\"False\" value",
    // minErrorText: "\"Value is below minimum\" error message",
    // maxErrorText: "\"Value exceeds maximum\" error message",
    // otherErrorText: "\"Empty comment\" error message",
    // keyDuplicationError: "\"Non-unique key value\" error message",
    // maxSelectedChoices: "Maximum selected choices",
    // showClearButton: "Show the Clear button",
    // showNumber: "Show panel number",
    // logoWidth: "Logo width (in CSS-accepted values)",
    // logoHeight: "Logo height (in CSS-accepted values)",
    // readOnly: "Read-only",
    // enableIf: "Editable if",
    // emptyRowsText: "\"No rows\" message",
    // size: "Input size (in characters)",
    // separateSpecialChoices: "Separate special choices (None, Other, Select All)",
    // choicesFromQuestion: "Copy choices from the following question",
    // choicesFromQuestionMode: "Which choices to copy?",
    // showCommentArea: "Show the comment area",
    // commentPlaceholder: "Comment area placeholder",
    // displayRateDescriptionsAsExtremeItems: "Display rate descriptions as extreme values",
    // rowsOrder: "Row order",
    // columnsLayout: "Column layout",
    // columnColCount: "Nested column count",
    // state: "Panel expand state",
    // correctAnswer: "Correct Answer",
    // defaultPanelValue: "Default Values",
    // cells: "Cell Texts",
    // keyName: "Key column",
    itemvalue: {
      // text: "Alt text"
    },
    logoPosition: "محل قرارگیری لوگو",
    addLogo: "اضافه کردن لوگو...",
    changeLogo: "تغییر لوگو...",
    logoPositions: {
      none: "حذف لوگو",
      left: "چپ",
      right: "راست",
      top: "بخش بالا",
      bottom: "بخش پایین"
    },
    tabs: {
      general: "عمومی",
      fileOptions: "انتخاب ها",
      html: "ویرایشگر HTML",
      columns: "ستون ها",
      rows: "سطرها",
      choices: "انتخاب ها",
      items: "آیتم ها",
      visibleIf: "نمایش در صورت",
      enableIf: "فعال اگر",
      requiredIf: "مورد نیاز ااگر",
      rateValues: "مقادیر رتبه بندی",
      choicesByUrl: "انتخاب ها از وب",
      matrixChoices: "انتخاب های پیشفرض",
      multipleTextItems: "فیلدهای متنی",
      numbering: "شماره گذاری",
      validators: "اعتبارسنجی ها",
      navigation: "ناوبری",
      question: "سوال",
      pages: "صفحات",
      timer: "زمان سنج/کوئیز",
      calculatedValues: "مقادیر محاسبه شد",
      triggers: "اجرا کننده",
      templateTitle: "عنوان قالب",
      totals: "جمع کل",
      logic: "منطق",
      layout: "چیدمان",
      data: "اطلاعات",
      validation: "اعتبارسنجی",
      cells: "سلول ها",
      showOnCompleted: "نمایش بدهید تکمیل شده",
      logo: "لوگو در عنوان نظرسنجی",
      slider: "اسلایدر",
      // expression: "Expression",
      others: "باقی موارد"
    },
    editProperty: "ویرایش خصوصیت '{0}'",
    items: "[ آیتم ها: {0} ]",
    // choicesVisibleIf: "Choices are visible if",
    // choicesEnableIf: "Choices are selectable if",
    // columnsEnableIf: "Columns are visible if",
    // rowsEnableIf: "Rows are visible if",
    // indent: "Add indents",
    panel: {
      // indent: "Add outer indents"
    },
    // innerIndent: "Add inner indents",
    // defaultValueFromLastRow: "Take default values from the last row",
    // defaultValueFromLastPanel: "Take default values from the last panel",
    enterNewValue: "لطفا یک مقدار وارد کنید",
    noquestions: "سوالی در پرسشنامه درج نشده",
    createtrigger: "اجرا کننده ای بسازید",
    titleKeyboardAdornerTip: "برای ویرایش دکمه ی enter را فشار دهید.",
    keyboardAdornerTip: "برای ویرایش دکمه ی enter را فشار دهید, برای حذف دکمه ی delete را فشار دهید, برای حرکت دادن یک آیتم، دکمه ی  alt + arrow down /  alt + arrow up فشار دهید",
    triggerOn: "در ",
    triggerMakePagesVisible: "صفحات را قابل نمایش کن:",
    triggerMakeQuestionsVisible: "سوالات را قابل نمایش کن:",
    triggerCompleteText: "پرسشنامه را تکمیل کن اگر موفق بود.",
    triggerNotSet: "اجرا کننده تنظیم نشده.",
    triggerRunIf: "اجرا در صورت",
    triggerSetToName: "تعییر مقدار از: ",
    triggerFromName: "کپی مقدار از: ",
    triggerRunExpression: "اجرای این عبارت",
    triggerSetValue: "به: ",
    triggerGotoName: "به سوال بروید:",
    triggerIsVariable: "عدم درج متغییر در نتایج پرسشنامه",
    triggerRunExpressionEmpty: "لطفا عبارت معتبری وارد نمایید",
    // emptyExpressionPlaceHolder: "Type expression here...",
    noFile: "هیچ فایلی انتخاب نشده است",
    // clearIfInvisible: "Clear the value if the question becomes hidden",
    // valuePropertyName: "Value property name",
    // searchEnabled: "Enable search",
    // hideSelectedItems: "Hide selected items",
    // signatureWidth: "Signature width",
    // signatureHeight: "Signature height",
    // verticalAlign: "Vertical alignment",
    // alternateRows: "Alternate rows",
    // columnsVisibleIf: "Columns are visible if",
    // rowsVisibleIf: "Rows are visible if",
    // otherPlaceholder: "Comment area placeholder"
  },
  // Property values
  pv: {
    // true: "true",
    // false: "false",
    inherit: "ارث بری",
    show: "آشکار",
    hide: "پنهان",
    default: "پیش فرض",
    initial: "اولیه",
    random: "تصادفی",
    collapsed: "جمع شده",
    expanded: "باز شده",
    none: "هیچ کدام",
    asc: "صعودی",
    desc: "نزولی",
    indeterminate: "نامشخص",
    // decimal: "decimal",
    // currency: "currency",
    // percent: "percent",
    firstExpanded: "گسترش یافته",
    off: "خاموش",
    // onpanel: "Start on each panel",
    onPanel: "روی پنل",
    onSurvey: "روی نظرسنجی",
    list: "لیست",
    progressTop: "نوار پیشرفت  بالا",
    progressBottom: "نوار پیشرفت پایین",
    progressTopBottom: "نوار پیشرفت در بالا و پایین",
    horizontal: "افقی",
    vertical: "عمودی",
    top: "بالا",
    bottom: "پایین",
    topBottom: "بالا و پایین",
    both: "هر دو",
    left: "چپ",
    // right: "Right",
    // color: "color",
    // date: "date",
    // datetime: "datetime",
    // "datetime-local": "datetime-local",
    // email: "email",
    // month: "month",
    // number: "number",
    // password: "password",
    // range: "range",
    // tel: "tel",
    // text: "text",
    // time: "time",
    // url: "url",
    // week: "week",
    hidden: "مخفی",
    on: "روشن",
    onPage: "روی صفحه",
    edit: "ویرایش",
    display: "نمایش",
    onComplete: "به محض تمام شدن",
    onHidden: "به محض مخفی شدن",
    // onHiddenContainer: "When the question or its panel/page becomes hidden",
    clearInvisibleValues: {
      // none: "Never"
    },
    all: "تمام",
    page: "صفحه",
    survey: "نظرسنجی",
    onNextPage: "به محض صفحه بعدی",
    onValueChanged: "به محض تغییر مقدار",
    // onValueChanging: "Before an answer is changed",
    standard: "استاندارد",
    singlePage: "تک صفحه ای",
    questionPerPage: "سوال در هر صفحه ی مجزا",
    noPreview: "پیش نمایش ندارد",
    showAllQuestions: "فعال سازی پیش نمایش با تمام سوالات",
    showAnsweredQuestions: "فعال سازی پیش نمایش به سوالات پاسخ داده شده",
    // pages: "Completed pages",
    // questions: "Answered questions",
    // requiredQuestions: "Answered required questions",
    // correctQuestions: "Valid answers",
    // buttons: "Completed pages (button UI)",
    // underInput: "Under the input",
    // underTitle: "Under the title",
    // onBlur: "On blur",
    // onTyping: "While typing",
    // underRow: "Under the row",
    // underRowSingle: "Under the row, only one panel is visible",
    showNavigationButtons: {
      // none: "Hidden"
    },
    showProgressBar: {
      // off: "Hidden"
    },
    showTimerPanel: {
      // none: "Hidden"
    },
    showTimerPanelMode: {
      // all: "Both"
    },
    detailPanelMode: {
      // none: "Hidden"
    },
    addRowLocation: {
      // default: "Depends on matrix layout"
    },
    panelsState: {
      // default: "Users cannot expand or collapse panels",
      // collapsed: "All panels are collapsed",
      // expanded: "All panels are expanded"
    },
    widthMode: {
      // auto: "Auto",
      // static: "Static",
      // responsive: "Responsive"
    },
    imageFit: {
      // none: "None",
      // contain: "Contain",
      // cover: "Cover",
      // fill: "Fill"
    },
    contentMode: {
      // auto: "Auto",
      // image: "Image",
      // video: "Video",
      // youtube: "YouTube"
    },
    displayMode: {
      // auto: "Auto",
      // buttons: "Buttons",
      // dropdown: "Dropdown"
    }
  },
  // Operators
  op: {
    empty: "خالی باشد",
    notempty: "خالی نباشد",
    equal: "مساوی باشد",
    notequal: "مساوی نباشد",
    contains: "شامل",
    notcontains: "شامل نباشد",
    anyof: "هرکدام",
    allof: "همه",
    greater: "بزرگتر",
    less: "کوچکتر",
    greaterorequal: "بزرگتر یا مساوی",
    lessorequal: "کوچکتر یا مساوی",
    // and: "and",
    // or: "or"
  },
  // Embed window
  ew: {
    angular: "استفاده از نسخه Angular",
    jquery: "استفاده از نسخه jQuery",
    knockout: "استفاده از نسخه ناک اوت",
    react: "استفاده از نسخه React",
    vue: "استفاده از نسخه Vue",
    bootstrap: "برای فریم ورک بوتسترپ",
    modern: "تم مدرن",
    default: "تم پیش فرض",
    orange: "تم نارنجی",
    darkblue: "تم آبی تیره",
    darkrose: "تم صورتی",
    stone: "تم سنگی",
    winter: "تم زمستانی",
    winterstone: "تم زمستانی و سنگی",
    showOnPage: "نمایش نظرسنجی در یک صفحه",
    showInWindow: "نمایش نظرسنجی در یک پنجره",
    loadFromServer: "بارگزاری JSON از سرور",
    titleScript: "اسکریپت و شیوه نمایش",
    titleHtml: "HTML",
    titleJavaScript: "جاوااسکریپت"
  },
  // Preview (Survey)
  ts: {
    selectPage: "صفحه ای را برای آزمایش انتخاب کنید:",
    showInvisibleElements: "نمایش المان های مخفی",
    // hideInvisibleElements: "Hide invisible elements"
  },
  validators: {
    answercountvalidator: "تعداد پاسخ",
    emailvalidator: "ایمیل",
    expressionvalidator: "عبارت",
    numericvalidator: "عدد",
    regexvalidator: "regex",
    textvalidator: "متن"
  },
  triggers: {
    completetrigger: "تکمیل نظرسنجی",
    setvaluetrigger: "تنظیم مقدار",
    copyvaluetrigger: "کپی مقدار",
    skiptrigger: "رد کردن سوال ",
    runexpressiontrigger: "اجرای عبارت",
    visibletrigger: "تغییر وضعیت دیده شدن"
  },
  pehelp: {
    // cookieName: "Cookies prevent users from filling out the same survey twice.",
    // format: "Use {0} as a placeholder for the actual value.",
    // totalText: "Visible only when at least one column has Total type or Total expression.",
    // acceptedTypes: "Refer to the [accept](https://www.w3schools.com/tags/att_input_accept.asp) attribute description for more information.",
    // columnColCount: "Applicable only to Radiogroup and Checkbox cell types.",
    // autocomplete: "Refer to the [autocomplete](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete) attribute description for more information.",
    // valueName: "If you do not set this property, the answer will be stored in a field specified by the Name property.",
    choicesbyurl: {
      // valueName: " "
    },
    // keyName: "If the specified column contains identical values, the survey throws the \"Non-unique key value\" error."
  },
  // Properties
  p: {
    title: {
      name: "عنوان",
      title: "اگر خالی باشد مانند نام درج می شود"
    },
    // multiSelect: "Allow multiple selection",
    // showLabel: "Show image captions",
    // value: "Value",
    visibleIf: "آشکار اگر",
    // attachOriginalItems: "attachOriginalItems",
    // useDisplayValuesInDynamicTexts: "useDisplayValuesInDynamicTexts",
    titleLocation: "محل قرارگیری عنوان",
    description: "توضیحات",
    // descriptionLocation: "descriptionLocation",
    // defaultValueExpression: "defaultValueExpression",
    requiredIf: "اجبار اگر؟",
    validators: "اعتبارسنج ها",
    // bindings: "bindings",
    // renderAs: "renderAs",
    currency: "ارز",
    // cellHint: "cellHint",
    // isUnique: "isUnique",
    // showInMultipleColumns: "showInMultipleColumns",
    // totalMaximumFractionDigits: "totalMaximumFractionDigits",
    // totalMinimumFractionDigits: "totalMinimumFractionDigits",
    columns: "ستون ها",
    // detailElements: "detailElements",
    choices: "گزینه ها",
    // allowAdaptiveActions: "allowAdaptiveActions",
    defaultRowValue: "مقدار پیش فرض سطر",
    // detailPanelShowOnAdding: "detailPanelShowOnAdding",
    // logoFit: "logoFit",
    // pages: "pages",
    // questions: "questions",
    triggers: "فعال سازی",
    // calculatedValues: "calculatedValues",
    // surveyId: "surveyId",
    // surveyPostId: "surveyPostId",
    // surveyShowDataSaving: "surveyShowDataSaving",
    // questionDescriptionLocation: "questionDescriptionLocation",
    // progressBarType: "progressBarType",
    // questionTitlePattern: "questionTitlePattern",
    // widthMode: "widthMode",
    // showBrandInfo: "showBrandInfo",
    choicesByUrl: "گزینه ها با لینک",
    // choicesLazyLoadEnabled: "choicesLazyLoadEnabled",
    // choicesLazyLoadPageSize: "choicesLazyLoadPageSize",
    // inputFieldComponent: "inputFieldComponent",
    // itemComponent: "itemComponent",
    // min: "min",
    // max: "max",
    // minValueExpression: "minValueExpression",
    // maxValueExpression: "maxValueExpression",
    // step: "step",
    // dataList: "dataList",
    itemSize: "ابعاد مورد",
    // elements: "elements",
    // content: "content",
    navigationButtonsVisibility: "پدیداری دکمه های ناوبری",
    // navigationTitle: "navigationTitle",
    // navigationDescription: "navigationDescription",
    // closeOnSelect: "closeOnSelect",
    // longTap: "longTap",
    // autoGrow: "autoGrow",
    // acceptCarriageReturn: "acceptCarriageReturn",
    // displayMode: "displayMode",
    label: "برچسب",
    // contentMode: "contentMode",
    // imageFit: "imageFit",
    // altText: "altText",
    // height: "height",
    // penColor: "penColor",
    // backgroundColor: "backgroundColor",
    // templateElements: "templateElements",
    // operator: "operator",
    // isVariable: "isVariable",
    // runExpression: "runExpression",
    // showCaption: "showCaption",
    // iconName: "iconName",
    // iconSize: "iconSize"
  }
};

editorLocalization.locales["fa"] = persianStrings;
