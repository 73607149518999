import { Grid } from '@mui/material';
import moment from 'moment';
import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchRateData } from '../../../core/features/acceptance-rate/acceptanceRateSlice';
import DayChart from './DayChart';
import WeekChart from './WeekChart';
import MonthChart from './MonthChart';
import { useRateTranslation } from './i18n';

const Container = () => {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(fetchRateData());
  }, []);

  const t = useRateTranslation();
  return (
    <Grid
      container
      lg={12}
      md={12}
      sm={12}
      className='h-full'
      justifyContent='center'
      spacing={4}
    >
      <Grid item lg={12} md={12} sm={12} className='max-h-2/4   '>
        {/* <div className=''> */}
        <Grid item lg={12} md={12}>
          <span className='mx-2 font-serif p-1 bg-slate-400 text-white '>
            {t('day')}
          </span>
          <span className='px-2 font-serif text-sm text-gray-600'>
            {moment().format('DD.MM.YYYY')}
          </span>
        </Grid>
        <Grid item lg={12} md={12}>
          <DayChart />
        </Grid>
        {/* </div> */}
      </Grid>
      <Grid item lg={6} md={6} sm={6} className='max-h-1/2  p-1 '>
        <div className=''>
          <Grid item lg={12} md={12}>
            <span className='mx-2 font-serif p-1 bg-slate-400 text-white'>
              {t('week')}
            </span>
            <span className='px-2 font-serif text-sm text-gray-600'>
              {' '}
              {`${moment().startOf('week').format('DD/MM')} - ${moment()
                .endOf('week')
                .format('DD/MM')}`}
            </span>
          </Grid>
          <Grid item lg={12} md={12}>
            <WeekChart />
          </Grid>
        </div>
      </Grid>
      <Grid item lg={6} md={6} sm={6} className='max-h-1/2  p-1 '>
        <div className=''>
          <Grid item lg={12} md={12}>
            <span className='mx-2 font-serif p-1 bg-slate-400 text-white'>
              {t('month')}
            </span>
            <span className='px-2 font-serif text-sm text-gray-600'>
              {' '}
              {moment().format('MM/YYYY')}
            </span>
          </Grid>
          <Grid item lg={12} md={12}>
            <MonthChart />
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default Container;
