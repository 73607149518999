import React from 'react';
import {
  DataGrid,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';

import { Button, CircularProgress, Grid } from '@mui/material';
import { Empty } from '../../drivers/components/common/Empty';
import { Avatar } from '@mui/material';
import default_image from '../../../assets/images/defult-pic.png';
import { makeStyles, useTheme } from '@mui/styles';
import { Visibility } from '@mui/icons-material';

import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { useUsersTranslation } from '../i18n';
import { useTranslation } from 'react-i18next';
import { appDirection } from '../../../shared_utils';
import { Link } from 'react-router-dom';

// avatar style
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

function CustomPagination(loading) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid
      container
      className=' flex justify-center relative space-x-4 py-4 w-full'
      spacing={1}
    >
      <Grid item>
        <Button
          disabled={page === 0 || loading ? true : false}
          variant='contained'
          color='primary'
          onClick={() => apiRef.current.setPage(page - 1)}
        >
          {t('previous')}
        </Button>
      </Grid>

      <Grid item>
        <Button
          disabled={loading}
          variant='contained'
          color='primary'
          onClick={() => apiRef.current.setPage(page + 1)}
        >
          {t('next')}
        </Button>
      </Grid>
      <Grid item>
        {loading ? (
          <CircularProgress
            className={
              theme.direction === 'ltr' ? 'absolute right-8' : 'absolute left-8'
            }
          />
        ) : (
          <span
            className={
              '' + theme.direction === 'ltr'
                ? 'p-2 px-4 rounded-full bg-gray-300  font-bold absolute right-8'
                : 'p-2 px-4 rounded-full bg-gray-300  font-bold absolute left-8'
            }
          >
            {page + 1}
          </span>
        )}
      </Grid>
    </Grid>
  );
}

const UsersList = ({ data, setPage, page, isLoading }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useUsersTranslation();
  const cols = [
    {
      field: 'userInfo',
      headerName: t('user_info'),
      headerClassName: ' bg-gray-100 font-bold',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs pl-2'
        >
          <Grid item lg={1} md={1}>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className='flex justify-center  cursor-pointer'
            >
              <Avatar
                src={params.value.thumbnail_url || default_image}
                className={classes.small}
              />
            </Grid>
          </Grid>
          <Grid item lg={8} md={8} sx={{ color: '#1769aa' }}>
            <Link to={`/users/${params.value.user_id}`}>
              <Grid item lg={12} md={12} xs={12}>
                {params.value.full_name}
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                {params.value.mobile}
              </Grid>
            </Link>
          </Grid>
        </Grid>
      ),
    },
    // {
    //   field: 'action',
    //   headerName: t('actions'),
    //   headerClassName: ' bg-gray-100 font-bold',

    //   disableColumnMenu: true,
    //   flex: 0.5,
    //   renderCell: (params) => (
    //     <Grid container lg={12} md={12} justifyContent='center'>
    //       <Visibility
    //         onClick={() => {
    //           navigate(`/users/${params.value.user_id}`);
    //         }}
    //         className=' hover:bg-blue-200 hover:cursor-pointer'
    //         color='inherit'
    //       />
    //     </Grid>
    //   ),
    // },
  ];

  const rowsData = data.map((data) => {
    return {
      id: data.user_id,
      userInfo: data,
      action: data,
    };
  });

  return (
    <div className='flex h-auto justify-center' dir={appDirection}>
      <Grid lg={12} md={12} className='flex-grow'>
        <DataGrid
          disableColumnFilter
          autoHeight
          density='standard'
          rowHeight={60}
          loading={isLoading}
          rows={rowsData}
          columns={cols}
          page={page}
          pageSize={1}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => setPage(newPage)}
          components={{
            Toolbar: null,
            Pagination: () => CustomPagination(isLoading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: LoadingIndicator,
          }}
        />
      </Grid>
    </div>
  );
};

export default UsersList;
