import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeading from '../../../../components/common/PageHeading';
import Details from '../Details';

export default function TrackingDrivers() {
  const { t } = useTranslation();
  const [isRefreshing, setisRefreshing] = useState(0);
  const [payload, setPayload] = useState({
    car_type_id: 1,
    latitude: 15.5888852,
    longitude: 32.5243864,
    radius: '3',
  });

  return (
    <Grid>
      <PageHeading
        backBtn
        heading1={t('Dispatch')}
        heading2={t('Tracking')}
        isRefreshing={isRefreshing}
        setIsRefreshing={setisRefreshing}
      />
      <Details
        payload={payload}
        setPayload={setPayload}
        isRefreshing={isRefreshing}
        setIsRefreshing={setisRefreshing}
      />
    </Grid>
  );
}
