import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Chart from './common/Chart';

const WeekChart = () => {
  const dailyRateData = useSelector((state) => state.acceptanceRates.dailyRateData);

  return <Chart data={dailyRateData} type={'daily'} />;
};

export default WeekChart;
