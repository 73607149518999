import {
  Fab,
  Grid,
  makeStyles,
  Paper,
  Tooltip,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNote,
  fetchNotes,
  setError,
} from '../../../../Redux/store/drivers/driversListSlice';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { formatDate } from '../../utils';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';
import { Empty } from '../common/Empty';
import EditDialog from '../common/FormDialog';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import { useDriverTranslation } from '../../i18n';
import { appDirection } from '../../../../shared_utils';
import { toast } from 'react-toastify';

// TODO: check if there pagination or note
// 		 add the add functionlity and form

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* <GridToolbarColumnsButton /> */}

      <GridToolbarExport
        csvOptions={{ fields: ['reference', 'amount', 'narration', 'created'] }}
      />
    </GridToolbarContainer>
  );
}

//main component

const Notes = ({ driver, driverId }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { notes } = useSelector((state) => state.drivers).driversList;
  // dialog settings
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const t = useDriverTranslation();
  const [isRefreshing, setIsRefreshing] = React.useState(0);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  // FIXME: loading state
  useEffect(() => {
    async function fetchEverything() {
      try {
        setIsLoading(true);
        await Promise.all([dispatch(fetchNotes(driverId))]);
      } catch (err) {
        if (err.status === 401) {
          localStorage.removeItem('token');
          window.location.reload();
        }
        toast.error(err.data.message);
      } finally {
        setIsLoading(false);
      }
    }

    fetchEverything();
  }, [dispatch, isRefreshing]);

  const cols = [
    {
      field: 'Note',
      headerName: t('note'),
      headerClassName: ' bg-gray-100',

      flex: 3,
      disableColumnMenu: true,
    },
    {
      field: 'supervisor_name',
      headerName: t('supervisor_name'),
      headerClassName: ' bg-gray-100',

      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'created',
      headerName: t('created_at'),
      headerClassName: ' bg-gray-100',

      disableColumnMenu: true,
      flex: 1,
    },
  ];

  const rowsData = notes.map((note) => {
    return {
      id: note.id,
      Note: note.note,
      supervisor_name: note.supervisor_name,
      created: formatDate(note.created_at),
    };
  });

  const handleAdd = async (note) => {
    try {
      setIsLoading(true);

      await dispatch(addNote(note));
      setIsRefreshing(isRefreshing + 1);

      toast.success(t('msg_note'));
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-screen place-content-center'>
          <div class='flex items-center gap-2 text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            loading...
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <Grid
      container
      lg={12}
      md={12}
      spacing={2}
      justifyContent='center'
      dir={appDirection}
    >
      <Grid item lg={12} md={12} className='flex justify-end'>
        <EditDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          driver={driver}
          carTypes={null}
          action={handleAdd}
          type='Note'
          title='Supervisor Note Form'
        />
      </Grid>
      <Grid item lg={12} md={12}>
        <div className='flex h-auto '>
          <div className=' flex-grow'>
            <DataGrid
              className='bg-white'
              disableColumnFilter
              autoHeight
              density='compact'
              rowHeight={60}
              loading={isLoading}
              rows={rowsData}
              columns={cols}
              hideFooterPagination={true}
              pageSize={20}
              rowCount={100}
              CellStyle='{StaticResource DataGridBorder}'
              paginationMode='server'
              components={{
                // Toolbar: CustomToolbar,
                NoRowsOverlay: Empty,
                NoResultsOverlay: Empty,
                LoadingOverlay: LoadingIndicator,
              }}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Notes;
