import { Grid } from '@material-ui/core';
import { useState } from 'react';
import Map from './dmand_supply_Heatmap/Map';
import Timeline from './Timeline';

const HeatMapContainer = ({ dateRange, setDateRange }) => {
  const [selectedTime, setSelectedTime] = useState(dateRange.from);
  return (
    <Grid container lg={12} md={12} className='relative'>
      <Map selectedTime={selectedTime} />
      <Timeline setSelectedTime={setSelectedTime} selectedTime={selectedTime} />
    </Grid>
  );
};

export default HeatMapContainer;
