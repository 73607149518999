const identity_ar = {
  identity: 'وثائق',
  users: 'مستخدمين',
  apply: 'تطبيق',
  deleted: 'محذوف',
  not_deleted: 'غير محذوف',
  all: 'الكل',
  blocked: 'محظور',
  unblocked: 'غير محظور',
  block: 'حظر',
  warning: 'تحذير',
  not_blocked: 'غير محظور',
  status: 'الحالة',
  mobile: 'رقم الهاتف',
  actions: 'العمليات',
  unblock: 'إلغاء الحظر',
  block: 'حظر',
  name: 'الإسم',
  full_name: 'الإسم',
  unblock_message: 'هل تريد إلغاء حظر المستخدم',
  block_message: 'هل تريد حظر المستخدم',
  delete_message: 'هل تريد حذف المستخدم',
  cancel: 'إلغاء',
  gender: 'النوع',
  email: 'إيميل',
  block_reason: 'سبب الحظر',
  deleted_at: 'تاريخ الحذف',
  details: 'التفاصيل',
  reason: 'السبب',
  deletion_status: 'حالة الحذف',
  block_status: 'حالة الحظر',
  previous: 'السائق',
  next: 'التالي',
};
export default identity_ar;
