import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';

import { Button, Grid } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Empty } from '../../../drivers/components/common/Empty';

import { LocalTaxi, SettingsEthernet } from '@mui/icons-material';

import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { fetchCarDetailsSuccess } from '../../../../Redux/store/cars/carsListSlice';
import { FormatDate } from '../../../drivers/utils';
import { useCarsTranslation } from '../../../../dispatch/views/cartypes/i18n';

function CustomPagination(loading) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Grid className=' flex justify-center relative space-x-4 py-4 w-full'></Grid>
  );
}

const CarsList = ({ data, setPage, isLoading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { carTypes } = useSelector((state) => state.cars).carsList;
  const [myArray, setMyArry] = useState([]);

  const t = useCarsTranslation();

  const cols = [
    {
      field: 'carType',
      headerName: t('car_type'),
      headerClassName: ' bg-gray-100   w-full',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs p-1 '
        >
          <Grid item lg={8} md={8}>
            <Grid item lg={12} md={12} xs={12}>
              {params.value.car_name_en}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'standardRide',
      headerName: t('standard_ride'),
      headerClassName: ' bg-gray-100 ',
      disableColumnMenu: true,
      flex: 1,
      width: 130,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs p-1 '
        >
          <Grid item lg={8} md={8}>
            <Grid item lg={12} md={12} xs={12}>
              <AttachMoneyIcon fontSize='small' /> {params.value.price_km}
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <SettingsEthernet rotate='right' fontSize='small' />
              {params.value.minimum_fare}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'shortRide',
      headerName: t('short_ride'),
      headerClassName: ' bg-gray-100 ',
      disableColumnMenu: true,
      flex: 1,
      width: 130,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs p-1 '
        >
          <Grid item lg={8} md={8}>
            <Grid item lg={12} md={12} xs={12}>
              <AttachMoneyIcon fontSize='small' />
              {params.value.short_distance_price_per_km}
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <SettingsEthernet fontSize='small' />
              {params.value.short_distance_km}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'midRide',
      headerName: t('mid_ride'),
      headerClassName: ' bg-gray-100 ',
      disableColumnMenu: true,
      flex: 1,
      width: 130,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs p-1 '
        >
          <Grid item lg={8} md={8}>
            <Grid item lg={12} md={12} xs={12}>
              <AttachMoneyIcon fontSize='small' />
              {params.value.mid_distance_price_per_km}
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <SettingsEthernet fontSize='small' />
              {params.value.mid_distance_km}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'openRidekm',
      headerName: t('open_km'),
      headerClassName: ' bg-gray-100 ',
      disableColumnMenu: true,
      flex: 1,
      width: 130,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs p-1 '
        >
          <Grid item lg={8} md={8}>
            <Grid item lg={12} md={12} xs={12}>
              <AttachMoneyIcon fontSize='small' />{' '}
              {params.value.open_trip_minimum_fare}
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <AttachMoneyIcon fontSize='small' />{' '}
              {params.value.open_trip_price_km}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'openRidemin',
      headerName: t('open_min'),
      headerClassName: ' bg-gray-100 ',
      flex: 1,
      disableColumnMenu: true,
      width: 130,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs p-1 '
        >
          <Grid item lg={8} md={8}>
            <Grid item lg={12} md={12} xs={12}>
              <AttachMoneyIcon fontSize='small' />{' '}
              {params.value.open_trip_minute_price}
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <AttachMoneyIcon fontSize='small' />{' '}
              {params.value.open_trip_backup_minute_price}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'TypesOfCarsAllowed',
      headerName: t('cars_allowed'),
      headerClassName: ' bg-gray-100 ',
      flex: 1,
      disableColumnMenu: true,
      width: 130,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs p-1 '
        >
          <Grid item lg={8} md={8}>
            {handleAllowedCars(params.value.allowed_car_type_ids).map(
              (item) => {
                return (
                  <div>
                    <LocalTaxi fontSize='small' />
                    {item}
                  </div>
                );
              }
            )}
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'action',
      headerName: t('view'),
      headerClassName: ' bg-gray-100   w-full',
      disableColumnMenu: true,
      flex: 0.5,
      renderCell: (params) => (
        <Button
          color='primary'
          onClick={() => {
            dispatch(fetchCarDetailsSuccess(params.value));
            navigate(`/cars/${params.value.id}`);
          }}
        >
          <VisibilityOutlinedIcon fontSize='small' />
        </Button>
      ),
    },
  ];

  const handleAllowedCars = (value) => {
    const carIds = value.map((item) => {
      return carTypes.find((id) => id.id === item).car_name;
    });

    if (carIds.length === 0) {
      return ['...'];
    }
    return carIds;
  };

  const rowsData = data.map((car) => {
    return {
      carType: car,
      TypesOfCarsAllowed: car,
      action: car,
      CreatedAt: car,
      id: car.id,
      midRide: car,
      openRidekm: car,
      openRidemin: car,
      shortRide: car,
      standardRide: car,
    };
  });

  return (
    <div className='flex h-auto '>
      <div className=' flex-grow'>
        <DataGrid
          disableColumnFilter
          autoHeight
          density='standard'
          rowHeight={100}
          loading={isLoading}
          rows={rowsData}
          columns={cols}
          pageSize={1}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => setPage(newPage)}
          components={{
            Toolbar: null,
            Pagination: () => CustomPagination(isLoading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: LoadingIndicator,
          }}
        />
      </div>
    </div>
  );
};

export default CarsList;
