import {
  Add,
  Delete,
  Edit,
  HideSource,
  Info,
  Visibility,
} from '@mui/icons-material';
import { Box, Button, Dialog, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingIndicator from '../../../../../components/common/LoadingIndicator';
import CustomPagination from '../../../../../components/new-components/CustomPagination';
import { Empty } from '../../../../../components/new-components/Empty';
import api from '../../../../services/API/eventTicketTypes';
import { useEventsTranslation } from '../../../../services/i18n';
import { DataGrid } from '@mui/x-data-grid';
import DialogForm from './components/DialogForm';
import DeleteModal from '../../../../../components/new-components/DeleteModal';

export default function EventTickets({
  isRefreshing,
  providerID,
  setIsRefreshing,
}) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [openFormDialog, setOpenFromDialog] = useState(false);
  const [updatedData, setUpdatedData] = useState();
  const [deletedID, setDeletedID] = useState();
  const [enabledID, setEnabledID] = useState();

  const { id } = useParams();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await api.getTicketTypes({
        page_number: page,
        page_size: 20,
        event_id: id,
        provider_id: providerID,
      });
      setData(data);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isRefreshing, page]);

  const t = useEventsTranslation();

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await api.deleteTicketType(deletedID);
      toast.success(t('deleted_successfully'));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
      setIsRefreshing((prev) => prev + 1);
    }
  };

  const handleEnable = async () => {
    setIsLoading(true);
    try {
      if (enabledID.isEnabled) {
        await api.disableTicketType(enabledID.value);
      } else {
        await api.enableTicketType(enabledID.value);
      }
      toast.success(t('done_successfully'));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
      setIsRefreshing((prev) => prev + 1);
    }
  };

  const cols = [
    {
      field: 'ticket_type_name',
      headerName: t('ticket_name'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: 'ticket_price',
      headerName: t('ticket_price'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: 'tickets_qty',
      headerName: t('tickets_qty'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: 'remaining_tickets_qty',
      headerName: t('remaining_tickets_qty'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: 'status',
      headerName: t('status'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <div className='text-center'>
          {params.value.enabled ? (
            <p className='text-green-400'>{t('enabled')}</p>
          ) : (
            <p className='text-red-400'>{t('disabled')}</p>
          )}
        </div>
      ),
    },
    {
      field: 'btn',
      headerName: t('actions'),
      headerClassName: 'bg-gray-100 text-center w-full h-full',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <div className='flex items-center ml-2 w-full gap-2'>
          {/* <Link to={'/events/providers/'}>
            <Info color='primary' />
          </Link> */}
          <button
            onClick={() => {
              setUpdatedData({
                ticket_type_name_ar: params.value.ticket_type_name_ar,
                ticket_type_name_en: params.value.ticket_type_name_en,
                ticket_price: params.value.ticket_price,
                tickets_qty: params.value.tickets_qty,
                id: params.value.ticket_type_id,
              });
              setOpenFromDialog(true);
            }}
          >
            <Edit className='text-gray-800' />
          </button>
          <button
            onClick={() => {
              setDeletedID(params.value.ticket_type_id);
            }}
          >
            <Delete color='error' />
          </button>
          <button
            onClick={() => {
              setEnabledID({
                isEnabled: params.value.enabled,
                value: params.value.ticket_type_id,
              });
            }}
          >
            {params.value.enabled ? (
              <HideSource color='warning' />
            ) : (
              <Visibility color='success' />
            )}
          </button>
        </div>
      ),
    },
  ];

  const rows = data.map((item) => ({
    ...item,
    id: item.ticket_type_id,
    status: item,
    btn: item,
  }));

  return (
    <Grid container>
      <Grid mb={2}>
        <Button
          color='primary'
          variant='contained'
          onClick={() => setOpenFromDialog(true)}
          endIcon={<Add />}
        >
          {t('add')}
        </Button>
      </Grid>
      <Box width={'100%'}>
        <DataGrid
          className='border-2'
          disableColumnFilter
          autoHeight
          rowHeight={50}
          page={page}
          loading={isLoading}
          rows={rows}
          columns={cols}
          pageSize={10}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          CellStyle='{StaticResource DataGridBorder}'
          components={{
            // Toolbar: () => <></>,
            Pagination: () => CustomPagination(isLoading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: LoadingIndicator,
          }}
        />
      </Box>
      <DialogForm
        open={openFormDialog}
        close={() => {
          setOpenFromDialog(false);
          setUpdatedData(null);
        }}
        setIsRefreshing={setIsRefreshing}
        update={updatedData}
      />
      <DeleteModal
        message={t('are_you_sure_you_want_to_delete')}
        onConfirm={() => handleDelete()}
        openModal={deletedID ? true : false}
        setOpenModal={setDeletedID}
      />
      <DeleteModal
        message={enabledID?.isEnabled ? t('disable_msg') : t('enable_msg')}
        onConfirm={() => handleEnable()}
        openModal={enabledID ? true : false}
        setOpenModal={setEnabledID}
      />
    </Grid>
  );
}
