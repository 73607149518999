import React from 'react';

import { useDispatch } from 'react-redux';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import { deletePlace } from '../../../../Redux/store/places/placesListSlice';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { usePlacesTranslation } from '../../i18n';

export default function DeletePlaceDialog({ placeId }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeletingPlace = async () => {
    try {
      await dispatch(deletePlace(placeId));
      dispatch(
        setAlert({
          type: 'info',
          message: `place ${placeId} deleted successfully`,
          isOpen: true,
        })
      );
      navigate('/places/');
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      handleClose();
    }
  };

  const t = usePlacesTranslation();

  return (
    <>
      <Button variant='outlined' color='error' onClick={handleClickOpen}>
        {t('delete_place')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle color='error' id='alert-dialog-title'>
          {t('delete')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {t('dialog_delete_msg')} {placeId} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='inherit'>
            {t('cancel')}
          </Button>
          <Button onClick={handleDeletingPlace} color='error' autoFocus>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
