import React, { useState, useEffect } from 'react';
import { Dialog } from '@mui/material';
import { DialogActions } from '@mui/material';
import { DialogContent } from '@mui/material';
import { DialogContentText } from '@mui/material';
import { DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  blockUser,
  unblockUser,
  clearErrors,
} from '../../../core/features/userIdentitySlice';
import { makeStyles } from '@mui/styles';

import { useCallback } from 'react';
import { Grid, Button, ButtonGroup } from '@mui/material';
import { Block } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { TextField } from '@mui/material';
import { useIdentityTranslation } from '../../i18n';

const useStyles = makeStyles((theme) => ({
  // cancel: {
  //   backgroundColor: '#575757',
  //   color: 'white',
  //   border: '2px',
  //   paddingLeft: '8px',
  //   paddingRight: '8px',
  //   '&:hover': {
  //     backgroundColor: '#383838',
  //     color: 'white',
  //     boxShadow: 'none',
  //   },
  // },

  text: {
    fontSize: '18px',
    color: '#151515',
  },
}));

export default function DisplayDialog({
  id,
  blocked,
  isRefreshing,
  setIsRefreshing,
}) {
  const [open, setOpen] = React.useState(false);
  const [reason, setReason] = useState({
    block_reason: null,
  });

  const classes = useStyles();
  const t = useIdentityTranslation();
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { error } = useSelector((state) => {
    return state.userIdentitySlice;
  });

  const handleVisibilty = useCallback(async () => {
    try {
      if (blocked) {
        await dispatch(unblockUser({ id }));
      } else {
        await dispatch(blockUser({ id, reason }));
      }
    } finally {
      setIsRefreshing(isRefreshing + 1);
      setOpen(false);
    }
  });

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
    dispatch(clearErrors());
  }, [dispatch]);

  return (
    <div>
      <ButtonGroup onClick={handleClickOpen}>
        <Button variant='contained' color='primary' onClick={handleClickOpen}>
          {blocked ? t('unblock') : t('block')}
        </Button>
      </ButtonGroup>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle id='alert-dialog-title' style={{ fontWeight: 'bold' }}>
          {t('warning')}
        </DialogTitle>{' '}
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            className={classes.text}
          >
            {blocked ? (
              <Grid item className='pb-2' lg={12} md={12}>
                {t('unblock_message')}
              </Grid>
            ) : (
              <Grid item className='' lg={12} md={12}>
                <Grid item className='pb-2'>
                  {t('block_message')}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={(e) =>
                      setReason({ ...reason, block_reason: e.target.value })
                    }
                    label={t('reason')}
                    value={reason.block_reason}
                    variant='outlined'
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            lg={12}
            md={12}
            className='flex p-2 justify-center space-x-2  '
            spacing={1}
          >
            <Grid item>
              <Button
                onClick={handleVisibilty}
                variant='outlined'
                disabled={!blocked && reason.block_reason == null}
                color='error'
              >
                {blocked ? t('unblock') : t('block')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant='outlined' onClick={handleClose} color='inherit'>
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
