import { Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchDailyRateData,
  fetchMonthlyRateData,
  fetchWeeklyRateData,
  setAlert,
} from '../../../Redux/store/app/appSlice';
import DayChart from '../components/DayChart';
import MonthChart from '../components/MonthChart';
import WeekChart from '../components/WeekChart';

const AcceptanceRatePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(0);

  const [dates, setDates] = useState({
    currentDay: {
      from: moment().startOf('day').utc(),
      to: moment().endOf('day').utc(),
    },
    currentWeek: {
      from: moment().startOf('week').utc(),
      to: moment().endOf('week').utc(),
    },
    currentMonth: {
      from: moment().startOf('month').utc(),
      to: moment().endOf('month').utc(),
    },
  });

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    try {
      await Promise.all([
        dispatch(fetchDailyRateData(dates.currentDay)),
        dispatch(fetchWeeklyRateData(dates.currentWeek)),
        dispatch(fetchMonthlyRateData(dates.currentMonth)),
      ]);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    }
  });

  useLayoutEffect(() => {
    fetchData();
  }, [isRefreshing]);

  return (
    <Grid container lg={12} md={12} sm={12} className='h-full' justifyContent='center'>
      <Grid item lg={12} md={12} sm={12} className='max-h-2/4  border-2 border-gray-400 '>
        {/* <div className='border-2 border-gray-400'> */}
        <Grid item lg={12} md={12}>
          <span className='mx-2 font-serif p-1 bg-slate-600 text-white '>Day</span>
          <span className='px-2 font-serif text-sm text-gray-600'>
            {moment().format('DD.MM.YYYY')}
          </span>
        </Grid>
        <Grid item lg={12} md={12}>
          <DayChart />
        </Grid>
        {/* </div> */}
      </Grid>
      <Grid item lg={6} md={6} sm={6} className='max-h-1/2  p-1 '>
        <div className='border-2 border-gray-400'>
          <Grid item lg={12} md={12}>
            <span className='mx-2 font-serif p-1 bg-slate-600 text-white'>week</span>
            <span className='px-2 font-serif text-sm text-gray-600'>
              {' '}
              {`${moment().startOf('week').format('DD/MM')} - ${moment()
                .endOf('week')
                .format('DD/MM')}`}
            </span>
          </Grid>
          <Grid item lg={12} md={12}>
            <WeekChart />
          </Grid>
        </div>
      </Grid>
      <Grid item lg={6} md={6} sm={6} className='max-h-1/2  p-1 '>
        <div className='border-2 border-gray-400'>
          <Grid item lg={12} md={12}>
            <span className='mx-2 font-serif p-1 bg-slate-600 text-white'>month</span>
            <span className='px-2 font-serif text-sm text-gray-600'>
              {' '}
              {moment().format('MM/YYYY')}
            </span>
          </Grid>
          <Grid item lg={12} md={12}>
            <MonthChart />
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default AcceptanceRatePage;
