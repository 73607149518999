import http from "../http";

export const deleteEventProvider = async (id) => {
  try {
    await http.delete(`/dashboard/events_providers/${id}`);
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
};
