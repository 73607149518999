import { createEventProvider } from "./createEventProvider";
import { deleteEventProvider } from "./deleteEventProvider";
import { getAllEventsProviders } from "./getAllEventsProviders";
import { getProviderById } from "./getProviderById";
import { updateEventProvider } from "./updateEventProvider";

const api = {
  createEventProvider,
  getAllEventsProviders,
  getProviderById,
  updateEventProvider,
  deleteEventProvider,
};

export default api;
