import React, { useCallback, useEffect } from 'react';

import { Grid, IconButton, InputBase, Paper } from '@mui/material';

import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import {
  fetchCarTypes,
  fetchFeesGroups,
  setError,
} from '../../../../Redux/store/drivers/driversListSlice';
import { setListPayload } from '../../../../Redux/store/orders/ordersListSlice';

import { FilterListRounded } from '@mui/icons-material';
import SearchTypeDialog from './SearchTypeDialog';
import { useOrdersTranslation } from '../../i18n';
import storage from '../../../../storage';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    margin: '0px 0px 10px 0px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function ListFiler({ setIsRefreshing, isRefreshing }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { carTypes } = useSelector((state) => state.drivers).driversList;
  const { listPayload } = useSelector((state) => state.orders).ordersList;
  const [payload, setPayload] = React.useState(listPayload);
  const [number, setNumber] = React.useState('');

  useEffect(() => {
    async function fetchEverything() {
      try {
        return await Promise.all([
          dispatch(fetchCarTypes()),
          dispatch(fetchFeesGroups()),
        ]);
      } catch (error) {
        setError(error);
        return error;
      }
    }
    fetchEverything();
  }, [dispatch]);

  const t = useOrdersTranslation();

  //dialog
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(t('user_mobile'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
    setNumber('');
  };

  //////

  const handleStatusChange = useCallback((e) => {
    setPayload({ ...payload, status_id: parseInt(e.target.value) });
  });

  const handleCartypeChange = useCallback((e) => {
    setPayload({ ...payload, car_type_id: parseInt(e.target.value) });
  });

  const handleMobileChange = useCallback((e) => {
    setNumber(e.target.value);
    if (selectedValue === t('driver_mobile')) {
      setPayload({
        ...payload,
        driver_mobile: e.target.value,
        user_mobile: undefined,
      });
    } else {
      setPayload({
        ...payload,
        user_mobile: e.target.value,
        driver_mobile: undefined,
      });
    }
  });

  const handleApplying = useCallback(async (e) => {
    await dispatch(setListPayload(payload));
    setIsRefreshing(isRefreshing + 1);
  });

  //formik

  const init = {
    cartype: 0,
  };

  const formik = useFormik({
    initialValues: init,
  });

  return (
    <Grid container lg={12} md={12} className='p-1 bg-gray-100 my-2'>
      <Grid container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item lg={10} md={10} className='text-xs flex justify-start'>
          <div className='my-2 mr-2 gap-3 flex  sm:flex-row flex-col'>
            <div className='flex flex-row mb-1 sm:mb-0 shadow-lg'>
              {/* status menu  */}
              <div className='relative'>
                <select
                  className='uppercase h-full rounded-r border-t sm:rounded-r-none focus:outline-none  border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight border-l border-r focus:bg-white focus:border-gray-500'
                  value={payload.status_id}
                  onChange={handleStatusChange}
                >
                  <option selected disabled>
                    {t('status')}
                  </option>
                  <option value={'null'}>{t('all')}</option>
                  <option value={1}>{t('pending')}</option>
                  <option value={2}>{t('accepted')}</option>
                  <option value={3}>{t('timed_out')}</option>
                  <option value={4}>{t('canceled')}</option>
                  <option value={5}>{t('arrived')}</option>
                  <option value={6}>{t('picked')}</option>
                  <option value={7}>{t('completed')}</option>
                  <option value={8}>{t('pending_matching')}</option>
                  <option value={9}>{t('matching_timeout')}</option>
                  <option value={13}>{t('not_show')}</option>
                  <option value={14}>{t('canceled_by_admin')}</option>
                  <option value={15}>{t('canceled_by_driver')}</option>
                  <option value={16}>{t('canceled_by_user')}</option>
                  <option value={17}>{t('canceled_by_system')}</option>
                  <option value={18}>
                    {t('canceled_by_user_after_accepted')}
                  </option>
                </select>
                <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                  <svg
                    className='fill-current h-4 w-4'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                  >
                    <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                  </svg>
                </div>
              </div>
            </div>

            {/* carType menu  */}
            <div className='relative'>
              <select
                className='h-full border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                onChange={handleCartypeChange}
                value={payload.car_type_id}
              >
                {/* TODO: config it to become multi select */}
                <option value={null} disabled selected>
                  {t('car_type')}
                </option>
                <option value={null}>{t('all')}</option>
                {carTypes.map((cartype) => (
                  <option value={cartype.id} key={cartype.id}>
                    {storage.lang.getLang() === 'en'
                      ? cartype.car_name_en
                      : cartype.car_name_ar}
                  </option>
                ))}
              </select>
              <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                <svg
                  className='fill-current h-4 w-4'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                >
                  <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                </svg>
              </div>
            </div>

            {/* search field */}
            <div className='block relative'>
              <Paper
                component='form'
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                sx={{ borderRadius: 0 }}
                className='appearance-none rounded-none sm:rounded-l-none border border-gray-400 border-b block  w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none'
              >
                <InputBase
                  className={classes.input}
                  id='mobile'
                  name='mobile'
                  dir='ltr'
                  placeholder={`${selectedValue}...`}
                  inputProps={{ 'aria-label': 'search google maps' }}
                  value={number}
                  onChange={handleMobileChange}
                />
                <IconButton className={classes.iconButton} aria-label='search'>
                  <FilterListRounded
                    color='primary'
                    onClick={handleClickOpen}
                  />
                  <SearchTypeDialog
                    selectedValue={selectedValue}
                    open={open}
                    onClose={handleClose}
                  />
                </IconButton>
                {/* </form> */}
              </Paper>
            </div>
          </div>
        </Grid>
        <div className='relative h-full pl-4 py-2'>
          <Button
            color='primary'
            variant='contained'
            size='small'
            className='h-full'
            onClick={handleApplying}
          >
            {t('apply')}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}
