import React from 'react';
import { Provider } from 'react-redux';
import dispatchStore from '../../../core/app/store';
import CarTypeDetails from '../components/CarTypeDetails';

const carDetailsPage = () => {
  return (
    <Provider store={dispatchStore}>
      <CarTypeDetails />
    </Provider>
  );
};

export default carDetailsPage;
