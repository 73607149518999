import {
  Close,
  Done,
  Person,
  Phone,
  QrCode2,
  Visibility,
} from '@mui/icons-material';
import { Box, Button, LinearProgress } from '@mui/material';
import moment from 'moment';
import React from 'react';
import CustomPagination from '../../../components/new-components/CustomPagination';
import { Empty } from '../../../components/new-components/Empty';
import { DataGrid } from '@mui/x-data-grid';
import { useEventsTranslation } from '../../services/i18n';
import { Link } from 'react-router-dom';

export default function TicketsTable({ data, isLoading, page, setPage }) {
  const t = useEventsTranslation();

  const cols = [
    {
      field: 'ticketNumber',
      headerName: t('ticket_number'),
      headerClassName: ' bg-gray-100 text-center w-full',
      flex: 0.8,
    },
    {
      field: 'eventID',
      headerName: t('event_id'),
      headerClassName: ' bg-gray-100 text-center w-full',
      flex: 1.2,
      renderCell: (params) => (
        <Link to={'/events/' + params.value.event_id}>
          <Box className='text-center text-blue-400 cursor-pointer'>
            {params.value.event_id}
          </Box>
        </Link>
      ),
    },
    {
      field: 'user',
      headerName: t('user_info'),
      headerClassName: ' bg-gray-100 text-center w-full',
      flex: 1.2,
      renderCell: (params) =>
        params.value ? (
          <Link to={'/users/' + params.value.user_id}>
            <div className='text-blue-400'>
              <div className='flex items-center gap-1'>
                <Person color='disabled' />
                <p>{params.value?.full_name}</p>
              </div>
              <div className='flex items-center gap-1'>
                <Phone color='disabled' />
                <p>{params.value?.mobile}</p>
              </div>
            </div>
          </Link>
        ) : (
          '...'
        ),
    },
    {
      field: 'ticketType',
      headerName: t('ticket_type'),
      headerClassName: ' bg-gray-100 text-center w-full',
      flex: 0.8,
    },

    {
      field: 'status',
      headerName: t('status'),
      headerClassName: ' bg-gray-100 text-center w-full',
      flex: 0.5,
    },
    {
      field: 'bookingTime',
      headerName: t('booking_time'),
      headerClassName: ' bg-gray-100 text-center w-full',
      flex: 1.2,
      renderCell: (params) => <div dir='ltr'>{params.value}</div>,
    },
    {
      field: 'createdAt',
      headerName: t('created_at'),
      headerClassName: ' bg-gray-100 text-center w-full',
      flex: 1.2,
      renderCell: (params) => <div dir='ltr'>{params.value}</div>,
    },
    {
      field: 'viewQr',
      headerName: t('view_qr'),
      headerClassName: ' bg-gray-100 text-center w-full',
      flex: 0.5,
      renderCell: (params) => (
        <Box className='flex items-center justify-center'>
          <Button
            color='success'
            onClick={() => window.open(params.value, '_blank')?.focus()}
          >
            <QrCode2 />
          </Button>
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: t('purchased_from_app'),
      headerClassName: 'bg-gray-100 text-center w-full',
      cellClassName: 'text-gray-600',
      flex: 0.6,
      renderCell: (params) => (
        // <Link to={'/events/tickets/' + params.value.ticket_id}>
        <Box className='flex items-center justify-center w-full'>
          {params.value.purchased_from_app ? (
            <Done color='success' />
          ) : (
            <Close color='error' />
          )}
        </Box>
        // </Link>
      ),
    },
  ];
  const rows = data?.map((data) => {
    return {
      id: data.ticket_id,
      ticketNumber: data.ticket_number,
      eventID: data,
      user: data.user,
      ticketType: data.ticket_type.ticket_type_name,
      status: data.ticket_status?.status_name,
      bookingTime: data.booking_time
        ? moment(data.booking_time).format('DD/MM/YYYY, h:mm:ss a')
        : null,
      createdAt: moment(data.created_at).format('DD/MM/YYYY, h:mm:ss a'),
      viewQr: data.qr_url,
      actions: data,
    };
  });

  return (
    <Box>
      <DataGrid
        className='border-2'
        disableColumnFilter
        autoHeight
        rowHeight={60}
        page={page}
        loading={isLoading}
        rows={rows}
        columns={cols}
        pageSize={1}
        rowCount={1000}
        paginationMode='server'
        onPageChange={(newPage) => setPage(newPage)}
        CellStyle='{StaticResource DataGridBorder}'
        components={{
          Toolbar: () => <></>,
          Pagination: () => CustomPagination(isLoading),
          NoRowsOverlay: Empty,
          NoResultsOverlay: Empty,
          LoadingOverlay: LinearProgress,
        }}
      />
    </Box>
  );
}
