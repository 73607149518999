import img from '../../assets/images/favicon.ico';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import storage from '../../storage';
import { toast } from 'react-toastify';
import DeleteModal from '../../components/new-components/DeleteModal';

export default function PrimarySearchAppBar({
  setIsAuthenticated,
  isAuthenticated,
}) {
  const handleChangeLang = () => {
    const lang = storage.lang.getLang();
    storage.lang.setLang(lang === 'en' ? 'ar' : 'en');
    window.location.reload();
  };

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handlelogout = () => {
    navigate('/');
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    toast.info(t('signed out successfully'));
  };

  const { t } = useTranslation();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <DeleteModal
        openModal={open}
        setOpenModal={setOpen}
        message={t('are you sure do you want to logout')}
        onConfirm={() => handlelogout()}
      />
      <AppBar
        position='static'
        sx={{
          boxShadow: '0 0 0 0',
        }}
        className='border-b-[0.5px] border-gray-300'
        color={'default'}
      >
        <Toolbar>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='menu'
            sx={{ mx: 2 }}
          >
            <img className='h-12 w-12' src={img} alt='' />
          </IconButton>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
            Admin Portal
          </Typography>
          <Button onClick={handleChangeLang} color='inherit'>
            {storage.lang.getLang() === 'en' ? 'العربية' : 'English'}
          </Button>{' '}
          {isAuthenticated && (
            <Button
              onClick={() => setOpen(true)}
              color='inherit'
              variant='contained'
            >
              {t('Logout')}
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
