import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  forwardRef,
} from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  Circle,
  CircleMarker,
  useMapEvents,
  useMap,
  useMapEvent,
  Tooltip,
} from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import './Map.css';
import L from 'leaflet';
import polyline from '@mapbox/polyline';
import http1 from '../../../../services/APIs/dispatch_api';
import { getTripActualRoute } from '../../../../Redux/store/app/appSlice';
import { Grid } from '@mui/material';
import 'leaflet-rotatedmarker';
import { useTranslation } from 'react-i18next';

const Map = ({ payload, setPayload, driversLocation, setIsLocated }) => {
  function MyComponent() {
    const distance = 1000;
    const map = useMapEvents({
      dragend: () => {
        // if (!map) return;
        const position = map.getCenter();

        setPayload(
          {
            ...payload,
            latitude: position.lat,
            longitude: position.lng,
          },
          map.getZoom()
        );
        setIsLocated(true);
      },
      zoomend: () => {
        // if (!map) return;
        const position = map.getCenter();
        setPayload(
          {
            ...payload,
            latitude: position.lat,
            longitude: position.lng,
          },
          map.getZoom()
        );
        setIsLocated(true);
      },
    });
    return (
      <Circle
        center={[payload.latitude, payload.longitude]}
        pathOptions={fillBlueOptions}
        radius={payload.radius * distance}
      />
    );
  }

  const { t } = useTranslation();

  const fillBlueOptions = { color: '#2196f3' };

  const carIconGreen = new L.Icon({
    iconUrl: require('../../../../assets/svg/car.png'),
    iconSize: [20, 35],
    iconAnchor: [17, 0],
    shadowSize: [41, 41],
  });
  const carIconRed = new L.Icon({
    iconUrl: require('../../../../assets/svg/ic_car_red.png'),
    iconSize: [20, 35],
    iconAnchor: [17, 0],
    shadowSize: [41, 41],
  });

  const RotatedMarker = forwardRef(({ children, ...props }, forwardRef) => {
    const markerRef = useRef();

    const { rotationAngle, rotationOrigin } = props;
    useEffect(() => {
      const marker = markerRef.current;
      if (marker) {
        marker.setRotationAngle(rotationAngle);
        marker.setRotationOrigin(rotationOrigin);
      }
    }, [rotationAngle, rotationOrigin]);

    return (
      <Marker
        ref={(ref) => {
          markerRef.current = ref;
          if (forwardRef) {
            forwardRef.current = ref;
          }
        }}
        {...props}
      >
        {children}
      </Marker>
    );
  });

  return (
    <MapContainer
      center={[15.5888852, 32.5243864]}
      zoom={12}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      <MyComponent />

      {driversLocation.length !== 0 &&
        driversLocation.map((item) => {
          return (
            <RotatedMarker
              rotationAngle={item.location.bearing}
              rotationOrigin='center'
              icon={item.available ? carIconGreen : carIconRed}
              position={[item.location.latitude, item.location.longitude]}
            >
              {item.car_type_id == 12 || item.car_type_id == 9 ? (
                <Tooltip direction='top' permanent>
                  {t('Orders')} : {item.active_dispatches.length}
                </Tooltip>
              ) : (
                ''
              )}
              <Popup closeButton={false}>
                <Grid container justifyContent='center'>
                  <Grid item> {item.full_name} </Grid>
                  <Grid item className='pt-1 text-[#1769aa]'>
                    {item.mobile_number}
                  </Grid>
                </Grid>
              </Popup>
            </RotatedMarker>
          );
        })}
    </MapContainer>
  );
};

export default Map;
