import { Provider } from 'react-redux';
import dispatchStore from '../../../core/app/store';
import TicketsList from '../components/TicketsList';

const TicketsListPage = () => {
  return (
    <Provider store={dispatchStore}>
      <TicketsList />
    </Provider>
  );
};

export default TicketsListPage;
