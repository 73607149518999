import React from 'react';
import { Grid, Avatar, CircularProgress, ButtonGroup } from '@mui/material';
import default_image from '../../../../assets/images/defult-pic.png';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
} from '@mui/x-data-grid';
import { useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';
import { Empty } from '../../../../components/new-components/Empty';
import { formatDate } from '../../utils';
import { Visibility, CalendarToday } from '@mui/icons-material';
import DisplayDialog from './DisplayDialog';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/styles';

import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import DeleteDialog from './DeleteDialog';
import { Button } from '@mui/material';
import { useIdentityTranslation } from '../../i18n';
import { appDirection } from '../../../../shared_utils';

function CustomPagination(loading) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const t = useIdentityTranslation();
  const theme = useTheme();
  return (
    <Grid
      container
      className=' flex justify-center relative space-x-4 py-4 w-full'
      spacing={1}
    >
      <Grid item>
        <Button
          disabled={page === 0 || loading ? true : false}
          variant='contained'
          color='primary'
          onClick={() => apiRef.current.setPage(page - 1)}
        >
          {t('previous')}
        </Button>
      </Grid>

      <Grid item>
        <Button
          disabled={loading}
          variant='contained'
          color='primary'
          onClick={() => apiRef.current.setPage(page + 1)}
        >
          {t('next')}
        </Button>
      </Grid>
      <Grid item>
        {loading ? (
          <CircularProgress
            className={
              theme.direction === 'ltr' ? 'absolute right-8' : 'absolute left-8'
            }
          />
        ) : (
          <span
            className={
              theme.direction === 'ltr'
                ? 'p-2 px-4 rounded-full bg-gray-300  font-bold absolute right-8'
                : 'p-2 px-4 rounded-full bg-gray-300  font-bold absolute left-8'
            }
          >
            {page + 1}
          </span>
        )}
      </Grid>
    </Grid>
  );
}

export default function UsersList({
  isRefreshing,
  setIsRefreshing,
  page,
  setPage,
}) {
  const navigate = useNavigate();

  const { usersData, isLoading } = useSelector((state) => {
    return state.userIdentitySlice;
  });
  const t = useIdentityTranslation();
  const cols = [
    {
      field: 'name',
      headerName: t('name'),
      headerClassName: ' bg-gray-100  text-center w-full',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <Grid container lg={12} md={12} xs={12} spacing={2} className='pl-2  '>
          <Grid item lg={2} md={2}>
            <Grid item lg={12} md={12} xs={12} className='px-2'>
              <Avatar src={params.value.url || default_image} />
            </Grid>
          </Grid>
          <Grid item lg={8} md={8} sx={{ color: '#1769aa' }}>
            <Link to={`/identity/users/${params.value.user_id}`}>
              <Grid item lg={12} md={12} xs={12}>
                {params.value.full_name == null
                  ? 'User'
                  : params.value.full_name}
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                {params.value.deleted ? '' : params.value.mobile}
              </Grid>
            </Link>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'id',
      headerName: t('ID'),
      headerClassName: ' bg-gray-100  text-center w-full',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs p-4 m-4'
        >
          {params.value}
        </Grid>
      ),
    },
    {
      field: 'deleted',
      headerName: t('deletion_status'),
      headerClassName: ' bg-gray-100  text-center w-full',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs pl-4 '
        >
          <Grid item lg={12} md={12} xs={12} className=''>
            {params.value ? (
              <Grid className='text-red-600'>{t('deleted')}</Grid>
            ) : (
              <Grid className='text-green-600'>{t('not_deleted')}</Grid>
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'blocked',
      headerName: t('block_status'),
      headerClassName: ' bg-gray-100  text-center w-full',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs pl-4 '
        >
          <Grid item lg={12} md={12} xs={12} className=''>
            {params.value ? (
              <Grid className='text-red-600'>{t('blocked')}</Grid>
            ) : (
              <Grid className='text-green-600'>{t('unblocked')}</Grid>
            )}
          </Grid>
        </Grid>
      ),
    },
    // {
    //   field: 'Date',
    //   headerName: 'Date',
    //   headerClassName: ' bg-gray-100  text-center w-full',
    //   disableColumnMenu: true,
    //   flex: 1.5,
    //   renderCell: (params) => (
    //     <Grid lg={12} md={12} xs={12} spacing={2} className='text-xs pl-4 '>
    //       <CalendarToday fontSize='small' />
    //       <span className='text-gray-600 leading-4 pl-2'>
    //         {params.value ? formatDate(params.value) : ''}
    //       </span>
    //     </Grid>
    //   ),
    // },
    // {
    //   field: 'action',
    //   headerName: t('actions'),
    //   headerClassName: ' bg-gray-100  text-center w-full',
    //   disableColumnMenu: true,
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Grid
    //       lg={12}
    //       md={12}
    //       xs={12}
    //       spacing={2}
    //       className='flex justify-center '
    //     >
    //       <ButtonGroup
    //         // color='primary'
    //         aria-label='outlined primary button group'
    //       >
    //         <Link to={`/identity/users/${params.value.user_id}`}>
    //           <Visibility
    //             fontSize='small'
    //             className=' hover:bg-blue-200 hover:cursor-pointer'
    //             color='inherit'
    //           />
    //         </Link>
    //         {/* <DisplayDialog
    //           id={params.value.user_id}
    //           blocked={params.value.blocked}
    //           isRefreshing={isRefreshing}
    //           setIsRefreshing={setIsRefreshing}
    //         /> */}
    //         {/* {!params.value.deleted && (
    //           <DeleteDialog
    //             id={params.value.user_id}
    //             isRefreshing={isRefreshing}
    //             setIsRefreshing={setIsRefreshing}
    //           />
    //         )} */}
    //       </ButtonGroup>
    //     </Grid>
    //   ),
    // },
  ];

  const rowsData = usersData.map((item) => {
    return {
      id: item.user_id,
      name: item,
      deleted: item.deleted,
      blocked: item.blocked,
      Date: item.deleted_at,
      action: item,
    };
  });

  return (
    <div className='flex h-auto' dir={appDirection}>
      <div className=' flex-grow'>
        <DataGrid
          className='border-2'
          disableColumnFilter
          autoHeight
          rowHeight={60}
          isLoading={isLoading}
          rows={rowsData}
          columns={cols}
          page={page}
          pageSize={1}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => setPage(newPage)}
          components={{
            // Toolbar: CustomToolbar,
            Pagination: () => CustomPagination(isLoading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: LoadingIndicator,
          }}
        />
      </div>
    </div>
  );
}
