import { Button, Grid, Tooltip } from '@mui/material';
import { Refresh } from '@material-ui/icons';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import {
  fetchUsersTicketcategories,
  setError,
} from '../../../../Redux/store/tickets/ticketsListSlice';

import { formatDate } from '../../../drivers/utils';
import CreateTicketDialog from '../../../tickets/components/common/CreateTicketDialog';
import { useOrdersTranslation } from '../../i18n';
import storage from '../../../../storage';
import { toast } from 'react-toastify';

const OrderDetails = ({ setIsRefreshing, isRefreshing }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { order } = useSelector((state) => state.orders).ordersList;
  const { carTypes } = useSelector((state) => state.drivers).driversList;
  const [isLoading, setIsLoading] = React.useState(false);
  //create ticket  dialog
  const [isOpen, setIsOpen] = React.useState(false);

  const t = useOrdersTranslation();

  const dialogProps = {
    setIsOpen: setIsOpen,
    isOpen: isOpen,
    userId: order.user_id,
    client_id: 'LEMON_APP_ID',
    service_id: 'lemon_taxi',
    referenceId: order.order_id,
    isRefreshing: isRefreshing,
    setIsRefreshing: setIsRefreshing,
  };

  const getCatogries = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(fetchUsersTicketcategories());
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    getCatogries();
  }, []);

  const handleStatus = useCallback((status) => {
    switch (status.status_id) {
      case 1:
        return (
          <p className=' text-orange-500  font-serif font-black text-2xl'>
            {status.status_name}
          </p>
        );

      case 2:
        return (
          <p className=' text-blue-600  font-serif font-black text-2xl'>
            {status.status_name}
          </p>
        );
      case 3:
        return (
          <p className=' text-red-600  font-serif font-black text-2xl'>
            {status.status_name}
          </p>
        );
      case 4:
        return (
          <p className=' text-red-600  font-serif font-black text-2xl'>
            {status.status_name}
          </p>
        );
      case 5:
        return (
          <p className=' text-green-600  font-serif font-black text-2xl'>
            {status.status_name}
          </p>
        );
      case 6:
        return (
          <p className=' text-blue-600  font-serif font-black text-2xl'>
            {status.status_name}
          </p>
        );
      case 7:
        return (
          <p className=' text-green-800  font-serif font-black text-2xl'>
            {status.status_name}
          </p>
        );
      case 8:
        return (
          <p className=' text-red-600  font-serif font-black text-2xl'>
            {status.status_name}
          </p>
        );
      case 9:
        return (
          <p className=' text-red-600  font-serif font-black text-2xl'>
            {status.status_name}
          </p>
        );
      case 13:
        return (
          <p className=' text-red-600  font-serif font-black text-2xl'>
            {status.status_name}
          </p>
        );
      case 14:
        return (
          <p className=' text-red-600  font-serif font-black text-2xl'>
            {status.status_name}
          </p>
        );
      case 15:
        return (
          <p className=' text-red-600  font-serif font-black text-2xl'>
            {status.status_name}
          </p>
        );
      case 16:
        return (
          <p className=' text-red-600  font-serif font-black text-2xl'>
            {status.status_name}
          </p>
        );
      case 17:
        return (
          <p className=' text-red-600  font-serif font-black text-2xl'>
            {status.status_name}
          </p>
        );
      case 18:
        return (
          <p className=' text-red-600  font-serif font-black text-2xl'>
            {status.status_name}
          </p>
        );

      default:
        return (
          <p className=' text-gray-600  font-serif font-black text-2xl'>
            {'Status unknown'}
          </p>
        );
    }
  }, []);

  const handleCarTypesNames = (id) => {
    let carType = carTypes.map((type) => {
      if (type.id === id) {
        return type.car_name_en;
      }
    });
    return carType;
  };
  if (isLoading) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-screen place-content-center'>
          <div class='flex items-center  text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            loading...
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <div dir={storage.lang.getDirection()}>
      <Grid
        container
        lg={12}
        md={12}
        xs={12}
        justifyContent='center'
        className='relative h-full'
      >
        {/* back btn */}

        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          className=' border-2  pt-6  pb-6 text-center bg-gradient-to-br from-gray-200 to-gray-100  rounded-xl w-[24vw] '
        >
          {/* header  */}
          <Grid item lg={12} md={12} className='pb-4'>
            {/* status */}
            <div className='mb-4'>{handleStatus(order.status)}</div>
            <span className='text-xs font-bold font-serif w-full bg-gray-600 p-1 px-4 text-white rounded-2xl'>
              {order.order_id || '....'}
            </span>
            <br />
          </Grid>
          {/* body  */}
          {/* item  */}
          <Grid item lg={12} md={12} className='flex flex-wrap justify-evenly'>
            {/* table 1 */}
            <Grid
              m={1}
              item
              lg={5}
              md={6}
              className={'p-2 bg-gray-100 rounded-lg'}
            >
              <p className='text-center font-bold text-lg'>
                {t('Dispatch_details')}
              </p>
              <Grid item lg={12} m={1} className='w-full flex items-center'>
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>
                    {t('Dispatch_ID')}:
                  </span>
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white '>
                  <Link to={`/dispatches/${order.dispatch_id}`}>
                    <span className='text-sm font-serif text-[#2EB6EC] underline w-full '>
                      {order.dispatch_id || '....'}
                    </span>
                  </Link>
                </Grid>
              </Grid>
              <Grid m={1} item lg={12} className='w-full flex items-center'>
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>
                    {t('Driver_Name')}:
                  </span>
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <Link to={`/drivers/${order.driver_id}`}>
                    <span className='text-sm text-[#2EB6EC] underline font-serif w-full '>
                      {order.driver_name || '....'}
                    </span>
                  </Link>
                </Grid>
              </Grid>
              <Grid
                m={1}
                item
                lg={12}
                className='w-full my-1 flex items-center'
              >
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>
                    {t('Creation_at')}:
                  </span>
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <span className='text-sm font-serif w-full'>
                    {formatDate(order.created_at) || '....'}
                  </span>
                </Grid>
              </Grid>
              <Grid m={1} item lg={12} className='w-full flex items-center'>
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>
                    {t('Updated_at')}:
                  </span>
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <span className='text-sm font-serif w-full'>
                    {formatDate(order.updated_at) || '....'}
                  </span>
                </Grid>
              </Grid>
              <Grid m={1} item lg={12} className='w-full flex items-center'>
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>
                    {t('Car_Type')}:
                  </span>
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <span className='text-sm font-serif w-full'>
                    {handleCarTypesNames(order.car_type_id)}
                  </span>
                </Grid>
              </Grid>
              <Grid m={1} item lg={12} className='w-full flex items-center'>
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>
                    {t('Peak_Rate')}:
                  </span>
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <span className='text-sm font-serif w-full'>
                    {order.peak_rate || '....'}
                  </span>
                </Grid>
              </Grid>
            </Grid>

            {/* table 2 */}
            <Grid
              m={1}
              item
              lg={5}
              md={6}
              className={'p-2 bg-gray-100 rounded-lg'}
            >
              <p className='text-center font-bold text-lg'>
                {t('Trip_details')}
              </p>
              <Grid
                m={1}
                item
                lg={12}
                className='w-full my-1 flex items-center'
              >
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>{t('From')}:</span>
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <span className='text-xs font-serif w-full '>
                    {order.from_address || '....'}
                  </span>
                </Grid>
              </Grid>
              <Grid
                m={1}
                item
                lg={12}
                className='w-full my-1 flex items-center'
              >
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>{t('To')}:</span>
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <span className='text-xs font-serif w-full '>
                    {order.to_address || '....'}
                  </span>
                </Grid>
              </Grid>
              <Grid
                m={1}
                item
                lg={12}
                className='w-full my-1 flex items-center'
              >
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>{t('Price')}:</span>
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <span className='text-sm font-serif w-full'>
                    {order.price || '0'} {t('SDG')}
                  </span>
                </Grid>
              </Grid>
              <Grid m={1} item lg={12} className='w-full flex items-center'>
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>
                    {t('Open_trip_Price')}:
                  </span>{' '}
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <span className='text-sm font-serif w-full'>
                    {order.open_trip_price || '0'} {t('SDG')}
                  </span>
                </Grid>
              </Grid>
              <Grid m={1} item lg={12} className='w-full flex items-center'>
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>
                    {t('Paid_cash')}:
                  </span>{' '}
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <span className='text-sm font-serif w-full'>
                    {order.paid_cash || '0'} {t('SDG')}
                  </span>
                </Grid>
              </Grid>
              <Grid m={1} item lg={12} className='w-full flex items-center'>
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>
                    {t('Paid_balance')}:
                  </span>{' '}
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <span className='text-sm font-serif w-full'>
                    {order.paid_balance || '0'} {t('SDG')}
                  </span>
                </Grid>
              </Grid>
              <Grid m={1} item lg={12} className='w-full flex items-center'>
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>
                    {t('Paid_promo')}:
                  </span>{' '}
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <span className='text-sm font-serif w-full'>
                    {order.paid_promo || '0'} {t('SDG')}
                  </span>
                </Grid>
              </Grid>
            </Grid>

            {/* table 3 */}
            <Grid
              m={1}
              item
              lg={5}
              md={6}
              className={'p-2 bg-gray-100 rounded-lg'}
            >
              <p className='text-center font-bold text-lg'>
                {t('Distance_details')}
              </p>
              <Grid
                m={1}
                item
                lg={12}
                className='w-full my-1 flex items-center'
              >
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>
                    {t('Distance')}:
                  </span>
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <span className='text-sm font-serif w-full'>
                    {order.distance_km || '0'} {t('km')}
                  </span>
                </Grid>
              </Grid>
              <Grid
                m={1}
                item
                lg={12}
                className='w-full my-1 flex items-center'
              >
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>
                    {t('Open_Trip_Distance')}:
                  </span>{' '}
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <span className='text-sm font-serif w-full'>
                    {order.open_trip_distance || '0'} {t('km')}
                  </span>
                </Grid>
              </Grid>
              <Grid
                m={1}
                item
                lg={12}
                className='w-full my-1 flex items-center'
              >
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>
                    {t('Duration')}:
                  </span>
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <span className='text-sm font-serif w-full'>
                    {order.estimated_duration || '0'}
                  </span>
                </Grid>
              </Grid>
              <Grid m={1} item lg={12} className='w-full flex items-center'>
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>
                    {t('Open_Trip_Duration')}:
                  </span>{' '}
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <span className='text-sm font-serif w-full'>
                    {order.open_trip_duration || '0'}
                  </span>
                </Grid>
              </Grid>
            </Grid>

            {/* table 4 */}
            <Grid
              m={1}
              item
              lg={5}
              md={6}
              className={'p-2 bg-gray-100 rounded-lg'}
            >
              <p className='text-center font-bold text-lg'>
                {t('Client_details')}
              </p>
              <Grid
                m={1}
                item
                lg={12}
                className='w-full my-1 flex items-center'
              >
                <Grid item lg={3} md={2}>
                  <span className=' text-sm  font-semibold  '>
                    {t('Client')}:
                  </span>
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <Link to={`/users/${order.user_id}`}>
                    <span className='text-sm font-serif text-[#2EB6EC] underline w-full'>
                      {order.user.full_name || '....'}
                    </span>
                  </Link>
                </Grid>
              </Grid>
              <Grid
                m={1}
                item
                lg={12}
                className='w-full my-1 flex items-center'
              >
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>
                    {t('Client_Mobile')}:
                  </span>{' '}
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <span className='text-sm font-serif w-full'>
                    {order.user.mobile || '....'}
                  </span>
                </Grid>
              </Grid>
              <Grid
                m={1}
                item
                lg={12}
                className='w-full my-1 flex items-center'
              >
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>
                    {t('Order_Mobile')}:
                  </span>
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <span className='text-sm font-serif w-full'>
                    {order.order_mobile_number || '....'}
                  </span>
                </Grid>
              </Grid>
              <Grid m={1} item lg={12} className='w-full flex items-center'>
                <Grid item lg={3} md={2}>
                  <span className=' text-sm font-semibold '>
                    {t('Client_Note')}:
                  </span>
                </Grid>
                <Grid item lg={8} md={7} sm={7} className='bg-white'>
                  <span className='text-sm font-serif w-full'>
                    {order.driver_note || '....'}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* manual btn  */}
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            className='flex justify-center py-4'
          >
            <Button
              variant='contained'
              color='primary'
              onClick={() => setIsOpen(true)}
            >
              {t('create_ticket')}
            </Button>
            <CreateTicketDialog {...dialogProps} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderDetails;
