import React from 'react';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { Divider, Grid, ListSubheader } from '@mui/material';
import { useDriverTranslation } from '../../i18n';
import { appDirection } from '../../../../shared_utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginLeft: 25,
    maxWidth: 660,
    backgroundColor: theme.palette.background.paper,
  },
}));

const PerformanceTabList = ({ performance }) => {
  const classes = useStyles();
  const t = useDriverTranslation();

  return (
    <Grid container lg={12} md={12} justifyContent='center' dir={appDirection}>
      <List className={classes.root}>
        <ListItem>
          <ListItemText
            primary={t('completed_dispatches')}
            secondary={
              <Grid container lg={12} md={12} justifyContent='space-between'>
                <Grid item md={2} lg={2}>
                  <span className='text-lg '>× </span>
                  {performance.completed_dispatches_count}
                </Grid>
                <Grid item lg={3} md={3} className='text-xs text-green-600'>
                  {performance.completed_dispatches_total_amount} {t('SDG')}
                </Grid>
              </Grid>
            }
          />
        </ListItem>
        <Divider variant='middle' />
        <ListItem>
          <ListItemText
            primary={t('canceled_dispatches')}
            secondary={
              <Grid container lg={12} md={12} justifyContent='space-between'>
                <Grid item md={2} lg={2}>
                  <span className='text-lg '>× </span>
                  {performance.canceled_dispatches_count}
                </Grid>
                <Grid item lg={3} md={3} className='text-xs text-red-600'>
                  {performance.canceled_dispatches_total_amount} {t('SDG')}
                </Grid>
              </Grid>
            }
          />
        </ListItem>
        <Divider variant='middle' />
        <ListItem>
          <ListItemText
            primary={t('ignored_dispatches')}
            secondary={
              <Grid container lg={12} md={12} justifyContent='space-between'>
                <Grid item md={2} lg={2}>
                  <span className='text-lg '>× </span>{' '}
                  {performance.ignored_dispatch_count}
                </Grid>
              </Grid>
            }
          />
        </ListItem>
        <Divider variant='middle' />
        <ListItem>
          <ListItemText
            primary={t('bonus')}
            secondary={
              <Grid container lg={12} md={12} justifyContent='space-between'>
                <Grid item md={2} lg={2}>
                  <span className='text-lg '>× </span>
                  {performance.bonuses_count}
                </Grid>
                <Grid item lg={3} md={3} className='text-xs text-green-600'>
                  {performance.bonuses_total_amount} {t('SDG')}
                </Grid>
              </Grid>
            }
          />
        </ListItem>
        <Divider variant='middle' />
        <ListItem>
          <ListItemText
            primary={t('guarantees')}
            secondary={
              <Grid container lg={12} md={12} justifyContent='space-between'>
                <Grid item md={2} lg={2}>
                  <span className='text-lg '>× </span>
                  {performance.guaranteed_count}
                </Grid>
                <Grid item lg={3} md={3} className='text-xs text-blue-600'>
                  {performance.guaranteed_total_amount} {t('SDG')}
                </Grid>
              </Grid>
            }
          />
        </ListItem>
        <Divider variant='middle' />
        <ListItem>
          <ListItemText
            primary={
              <Grid container lg={12} md={12} justifyContent='space-between'>
                <Grid item md={2} lg={2}>
                  {t('total')}
                </Grid>
                <Grid item lg={3} md={3} className='text-xs text-green-600'>
                  {performance.total_earnings} {t('SDG')}
                </Grid>
              </Grid>
            }
          />
        </ListItem>
      </List>
    </Grid>
  );
};

export default PerformanceTabList;
