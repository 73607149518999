import http from '../../../services/APIs/taxi_api';
import http_api from '../../../services/APIs/tickets_api';
import http2 from '../../../services/APIs/places_api';

export const ordersApi = {
  fetchOrders: async (paylaod) => {
    try {
      const response = await http.post('/dashboard/orders', paylaod);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },

  fetchOrderById: async (id) => {
    try {
      const response = await http.get(`/dashboard/orders/${id}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },

  cancelOrder: async (id) => {
    try {
      const response = await http.put(`/dashboard/orders/cancel/${id}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },

  reOrder: async (paylaod) => {
    try {
      const response = await http.post('/agents/orders', paylaod);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },

  checkOrder: async (paylaod) => {
    try {
      const response = await http.post('/agents/check_order', paylaod);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },

  createOrder: async (paylaod) => {
    try {
      const response = await http.post('/agents/orders', paylaod);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },

  getPlaces: async (keyword) => {
    try {
      const response = await http2.get(`/dashboard/places?search=${keyword}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },
  fetchOrdersStatistics: async (payload) => {
    try {
      const response = await http.get(
        `/dashboard/stats/orders?from=${payload.from}&to=${payload.to}${
          payload.car_type_id ? `&car_type_id=${payload.car_type_id}` : ''
        }`
      );
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },

  getUserByMobile: async (mobile) => {
    try {
      const response = await http_api.get(`/users?mobile=${mobile}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },
};
