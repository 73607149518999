import { Button, Grid } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageHeading from '../../../components/common/PageHeading';
import { getGroupList } from '../../../Redux/store/places/placesListSlice';
import DeleteFilter from '../components/common/DeleteFilter';
import GroupsList from '../components/GroupsList';
import { usePlacesTranslation } from '../i18n';

const GroupsListPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(0);
  const [payload, setPayload] = useState({
    isDeleted: '',
  });
  const [searchParams, setSearchParams] = useSearchParams(
    createSearchParams({
      page: 0,
      ...payload,
    })
  );

  const currnetSearchParams = useMemo(
    () => ({
      isDeleted: searchParams.get('isDeleted'),
      page: searchParams.get('page'),
    }),
    [searchParams, payload]
  );

  const setPage = (newPage) => {
    setSearchParams({
      ...currnetSearchParams,
      page: newPage,
    });
  };

  const dispatch = useDispatch();
  const { groups } = useSelector((state) => state.places).places;

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      setSearchParams(currnetSearchParams);
      await dispatch(getGroupList({ type: null, ...currnetSearchParams }));
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    fetchData();
  }, [isRefreshing, searchParams]);

  const t = usePlacesTranslation();

  return (
    <Grid container justify='center'>
      <Grid item lg={12} md={12}>
        <PageHeading
          heading1={t('places')}
          heading2={t('groups_list')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>
      <Grid
        container
        lg={12}
        md={12}
        justifyContent='space-between'
        className='p-1 bg-gray-100 my-2 px-2'
      >
        <Grid item>
          <DeleteFilter
            payload={payload}
            setPayload={setPayload}
            isRefreshing={isRefreshing}
            setIsRefreshing={setIsRefreshing}
          />
        </Grid>
        <Button
          variant='contained'
          onClick={() => {
            setSearchParams({
              ...currnetSearchParams,
              ...payload,
            });
            setIsRefreshing((prev) => prev + 1);
          }}
          color='primary'
        >
          {t('apply')}
        </Button>
      </Grid>
      <Grid item lg={12} md={12} xs={12}>
        <GroupsList
          data={groups}
          page={Number(currnetSearchParams.page)}
          setPage={setPage}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
};

export default GroupsListPage;
