import http from '../http';

const getSupportUsers = async () => {
  const baseURL = process.env.REACT_APP_TICKETS_API;
  try {
    const supportUsersList = await http.get(`${baseURL}/users/support_users`);
    return supportUsersList.data;
  } catch (err) {
    throw err.response;
  }
};

export default getSupportUsers;
