import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { format } from '../../utils';
import { Link, useNavigate } from 'react-router-dom';
import { useTicketTranslation } from '../../i18n';
import { DataGrid } from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import { Empty } from '../../../drivers/components/common/Empty';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { AccountCircle } from '@mui/icons-material';

export default function TransactionsList() {
  const { ticketTransactions } = useSelector(
    (state) => state.tickets
  ).ticketsList;
  const rows = ticketTransactions;

  const navigate = useNavigate();
  const t = useTicketTranslation();
  const handleActionId = useCallback((id) => {
    switch (id) {
      case 0:
        return t('created');

      case 1:
        return t('under_process');

      case 2:
        return t('closed');

      case 3:
        return t('assigned');
      case 4:
        return t('reopened');

      default:
        return '.....';
    }
  }, []);

  const cols = [
    {
      field: 'action',
      headerName: t('action'),
      headerClassName: ' bg-gray-100  font-bold  ',

      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => <Grid>{handleActionId(params.value)}</Grid>,
    },
    {
      field: 'note',
      headerName: t('note'),
      headerClassName: ' bg-gray-100 font-bold ',

      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => <Grid>{params.value}</Grid>,
    },
    {
      field: 'date',
      headerName: t('date'),
      headerClassName: ' bg-gray-100 font-bold ',

      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => <Grid>{format(params.value)}</Grid>,
    },
    {
      field: 'created_by',
      headerName: t('created_by'),
      headerClassName: 'bg-gray-100 ',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className=' text-[#1769aa]  hover:cursor-pointer h-full flex items-center px-2   '
          // onClick={() => navigate(`/users/${params.value.action_by_id}`)}
        >
          <Link to={`/users/${params.value.action_by_id}`}>
            <Grid item lg={12} md={12} xs={12}>
              <AccountCircle fontSize='small' className='  text-[#1769aa]' />
              {params.value.action_by ? params.value.action_by.full_name : ''}
            </Grid>
          </Link>
        </Grid>
      ),
    },
  ];

  const rowsData = rows.map((row) => {
    return {
      id: row.id,
      action: row.action_id,
      note: row.note,
      date: row.created_at,
      created_by: row,
    };
  });

  return (
    <>
      <div className=' flex-grow '>
        <DataGrid
          className='bg-white '
          disableColumnFilter
          autoHeight
          density='compact'
          rows={rowsData}
          columns={cols}
          pageSize={1}
          rowCount={100}
          paginationMode='server'
          hideFooterPagination={true}
          CellStyle='{StaticResource DataGridBorder}'
          components={{
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: LoadingIndicator,
          }}
        />
      </div>
    </>
  );
}
