import React, { useState, useCallback } from 'react';
import { Formik, Field, Form, ErrorMessage, useFormik } from 'formik';
import { TextField } from '@mui/material';
import * as Yup from 'yup';
import { makeStyles } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import {
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  FormGroup,
  QueryBuilderSharp,
  InputAdornment,
} from '@mui/material';
import PageHeading from '../../../components/common/PageHeading';
import {
  Card,
  CardContent,
  CardActions,
  Chip,
  Select,
  FormControl,
  useTheme,
  MenuItem,
  Input,
  InputLabel,
} from '@mui/material';
import { updateCarDetails } from '../../../Redux/store/cars/carsListSlice';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../../Redux/store/app/appSlice';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { useCarsTranslation } from '../../../dispatch/views/cartypes/i18n';
function CarDetails() {
  const dispatch = useDispatch();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const t = useCarsTranslation();

  const [isRefreshing, setIsRefreshing] = useState(0);
  const [isloading, setIsloading] = useState(false);

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }));

  // function getStyles(type, carName, theme) {
  //   return {
  //     fontWeight:
  //       carName.indexOf(type) === -1
  //         ? theme.typography.fontWeightRegular
  //         : theme.typography.fontWeightMedium,
  //   };
  // }

  const { carTypes, carDetails } = useSelector((state) => state.cars).carsList;
  // console.log(carDetails);

  const [options, setOptions] = useState({
    // const options = {
    // all the cartype info
    id: carDetails.id,
    payload: carDetails,
  });
  const updateCarType = useCallback(async () => {
    try {
      // **update component state**
      setIsloading(true);
      await dispatch(updateCarDetails(options));
      dispatch(
        setAlert({
          type: 'success',
          message: 'carType updated successfully',
          isOpen: true,
        })
      );
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsloading(false);
    }
  });
  // const onSubmit = (e) => {
  //   console.log("Form data", e.values);
  //   updateCarType();
  // };

  const classes = useStyles();
  const theme = useTheme();
  // const [carName, setcarName] = React.useState([]);
  // const handleChange = (event) => {
  //   setOptions(event.target.value);
  // };

  // const initialValues = options;

  const validationSchema = Yup.object({
    Name: Yup.string().required('Required'),
    Description: Yup.string().required('Required'),
    minimum: Yup.string().required('Required'),
    pricePerKm: Yup.string().required('Required'),
    checkOption: Yup.array().required('Required'),
  });

  const formik = useFormik({
    initialValues: carDetails,

    validationSchema: validationSchema,
  });
  const handleSubmit = (values) => {
    console.log(values);
    setOptions({ ...options, payload: values });
    updateCarType();
  };
  // const test = (id) => {
  //   // console.table(carTypes.find((car) => car.id === id));
  //   console.log(id);
  // };
  if (isloading) {
    return <LoadingIndicator />;
  }

  return (
    <Grid container lg={12} md={12}>
      <Grid item lg={12} md={12} className='pb-4'>
        <PageHeading
          previousUrl={'cars'}
          heading1={t('cars')}
          heading2={t('cars_list')}
          heading3={t('car_details')}
          backBtn
        />
      </Grid>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(formik.values);
        }}
        className='p-4'
      >
        <Grid
          container
          lg={12}
          md={12}
          spacing={2}
          justifyContent='center'
          alignItems='center'
        >
          <Card className='shadow-2xl border-l-2 border-gray-2 '>
            <CardContent className=''>
              <Grid
                container
                lg={12}
                md={12}
                spacing={2}
                className='flex justify-center  rounded'
              >
                <Grid item lg={10} md={10}>
                  <span className='text-lg font-semibold text-slate-600 '>
                    <h1>{'vehical'}</h1>
                  </span>
                </Grid>

                <Grid item lg={10} md={10}>
                  <TextField
                    fullWidth
                    label={t('name')}
                    name='car_name'
                    id='car_name'
                    type='text'
                    variant='outlined'
                    margin='dense'
                    onChange={formik.handleChange}
                    value={formik.values.car_name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'></InputAdornment>
                      ),
                    }}
                  />
                  {/* <ErrorMessage name="Name" /> */}
                </Grid>

                <Grid item lg={10} md={10}>
                  <TextField
                    fullWidth
                    label={t('description')}
                    name='description'
                    id='description'
                    variant='outlined'
                    // margin='dense'
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    type='text'
                  />
                  {/* <ErrorMessage name="Description" /> */}
                </Grid>

                <Grid item lg={5} md={5}>
                  <span className='text-base font-semibold text-slate-600'>
                    <h1>{t('short')}</h1>
                  </span>
                  <TextField
                    label={t('minimum')}
                    name='short_distance_km'
                    id='short_distance_km'
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    type='text'
                    onChange={formik.handleChange}
                    value={formik.values.short_distance_km}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                  />
                  {/* <ErrorMessage name="Minimum" /> */}

                  <TextField
                    // control='input'
                    label={t('pricekm')}
                    name='short_distance_price_per_km'
                    id='short_distance_price_per_km'
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    type='integer'
                    onChange={formik.handleChange}
                    value={formik.values.short_distance_price_per_km}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                  />
                  {/* <ErrorMessage name="pricePerKm" /> */}
                </Grid>

                <Grid item lg={5} md={5}>
                  <span className='text-base font-semibold text-slate-600'>
                    <h1>{'average'}</h1>
                  </span>
                  <TextField
                    label={t('minimum')}
                    name='mid_distance_km'
                    id='mid_distance_km'
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    type='text'
                    onChange={formik.handleChange}
                    value={formik.values.mid_distance_km}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                  />
                  {/* <ErrorMessage name="Minimum" /> */}

                  <TextField
                    // control='input'
                    label={t('pricekm')}
                    name='mid_distance_price_per_km'
                    id='mid_distance_price_per_km'
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    type='integer'
                    onChange={formik.handleChange}
                    value={formik.values.mid_distance_price_per_km}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                  />
                  {/* <ErrorMessage name="pricePerKm" /> */}
                </Grid>
                <Grid item lg={10} md={10}>
                  <span className='text-base font-semibold text-slate-600'>
                    <h1>{'open'}</h1>
                  </span>
                </Grid>
                <Grid item lg={5} md={5}>
                  <TextField
                    label={t('minimum')}
                    name='open_trip_price_km'
                    id='open_trip_price_km'
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    type='text'
                    onChange={formik.handleChange}
                    value={formik.values.open_trip_price_km}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                  />
                  {/* <ErrorMessage name="Minimum" /> */}

                  <TextField
                    // control='input'
                    label={t('pricekm')}
                    name='open_trip_minimum_fare'
                    id='open_trip_minimum_fare'
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    type='integer'
                    onChange={formik.handleChange}
                    value={formik.values.open_trip_minimum_fare}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                  />
                  {/* <ErrorMessage name="pricePerKm" /> */}
                </Grid>

                <Grid item lg={5} md={5}>
                  <span className='text-base font-semibold text-slate-600'>
                    {/* <h1>Price of Open ride</h1> */}
                  </span>
                  <TextField
                    label={t('minimum')}
                    name='open_trip_minute_price'
                    id='open_trip_minute_price'
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    type='text'
                    onChange={formik.handleChange}
                    value={formik.values.open_trip_minute_price}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                  />
                  {/* <ErrorMessage name="Minimum" /> */}

                  <TextField
                    // control='input'
                    label={t('pricekm')}
                    name='open_trip_backup_minute_price'
                    id='open_trip_backup_minute_price'
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    type='Text'
                    onChange={formik.handleChange}
                    value={formik.values.open_trip_backup_minute_price}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                  />
                  {/* <ErrorMessage name="pricePerKm" /> */}
                </Grid>

                <Grid item lg={10} md={10}>
                  <span className='text-base font-semibold text-slate-600'>
                    <h1>{'cars_allowed'}</h1>
                  </span>
                </Grid>

                <Grid item lg={10} md={10}>
                  <FormControl className='w-full'>
                    <InputLabel id='demo-mutiple-name-label'>
                      {'car_types'}
                    </InputLabel>
                    <Select
                      labelId='demo-mutiple-name-label'
                      id='allowed_car_type_ids'
                      name='allowed_car_type_ids'
                      multiple
                      value={formik.values.allowed_car_type_ids}
                      onChange={formik.handleChange}
                      input={<Input />}
                      MenuProps={MenuProps}
                    >
                      {carTypes.map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                          {type.car_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item lg={10} md={10} className='flex justify-center'>
                  {/* <CardActions> */}
                  <Button
                    // loading={isSubmitting}
                    color='primary'
                    variant='contained'
                    type='submit'
                    // onClick={submitForm}
                    // onSubmit={handleSubmit}
                    // onClick={() => dispatch(fetchCarDetails({car_id}))}
                  >
                    {'update'}
                  </Button>
                  {/* </CardActions> */}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </form>
      {/* </> */}
      {/* )} */}
      {/* </Formik> */}
    </Grid>
  );
}
export default CarDetails;
