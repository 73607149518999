import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/API/index';

const initialState = {
  usersData: [],
  details: [],
  isLoading: false,
  error: null,
};

export const fetchUsersList = createAsyncThunk(
  'user_identity/fetchUsersList',
  async (payload) => {
    return await api.getUsers(payload);
  }
);

export const fetchUserDetails = createAsyncThunk(
  'user_identity/fetchUserDetails',
  async (payload) => {
    return await api.getDetails(payload);
  }
);

export const blockUser = createAsyncThunk(
  'user_identity/blockUser',
  async (payload) => {
    return await api.getUserBlocked(payload);
  }
);

export const unblockUser = createAsyncThunk(
  'user_identity/unblockUser',
  async (payload) => {
    return await api.getUserUnblocked(payload);
  }
);

export const deleteUser = createAsyncThunk(
  'user_identity/deleteUser',
  async (payload) => {
    return await api.deleteUserById(payload);
  }
);

const userIdentitySlice = createSlice({
  name: 'user_identity',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // fetch users list
    builder.addCase(fetchUsersList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUsersList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.usersData = action.payload;
    });
    builder.addCase(fetchUsersList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    // fetch user details
    builder.addCase(fetchUserDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.details = action.payload;
    });

    builder.addCase(fetchUserDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    //block user
    builder.addCase(blockUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(blockUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.details = action.payload;
    });

    builder.addCase(blockUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    // unblock user
    builder.addCase(unblockUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(unblockUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.details = action.payload;
    });

    builder.addCase(unblockUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    // delete user
    builder.addCase(deleteUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.details = action.payload;
    });

    builder.addCase(deleteUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});
export const { clearErrors } = userIdentitySlice.actions;

export default userIdentitySlice.reducer;
