import React from 'react';
import Button from '@mui/material/Button';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import { Fab, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PersonIcon from '@material-ui/icons/Person';
import DialogTitle from '@mui/material/DialogTitle';
import DriverForm from './DriverInfoForm';
import Form from './AddNoteForm';
import { IconButton, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import CloseIcon from '@material-ui/icons/Close';
import { appDirection } from '../../../../shared_utils';
import { useDriverTranslation } from '../../i18n';
import { Add, AddIcCallTwoTone } from '@mui/icons-material';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitlee = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function EditDialog({
  driver,
  carTypes,
  driverId,
  isRefreshing,
  setIsRefreshing,
  action,
  type,
  title,
}) {
  const driverI = driver;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const t = useDriverTranslation();

  return (
    <div dir={appDirection}>
      {type == 'DriverInfo' ? (
        <Button
          variant='contained'
          color='primary'
          aria-label='outlined primary button group'
          onClick={handleClickOpen}
        >
          {t('update_info')}
        </Button>
      ) : (
        <Button
          variant='contained'
          color='primary'
          aria-label='outlined primary button group'
          onClick={handleClickOpen}
        >
          <Add fontSize='small' />
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth={'sm'}
        maxWidth={'sm'}
      >
        {type === 'DriverInfo' ? (
          <DialogTitle id='form-dialog-title' onClose={handleClose}>
            {t('update_info')}
          </DialogTitle>
        ) : (
          <DialogTitle id='form-dialog-title' onClose={handleClose}>
            {t('add_note')}
          </DialogTitle>
        )}

        <DialogContent>
          {type === 'DriverInfo' ? (
            <DriverForm
              driver={driverI}
              carTypes={carTypes}
              close={handleClose}
              updateDriverInfo={action}
            />
          ) : (
            <Form
              driver={driverI}
              carTypes={carTypes}
              close={handleClose}
              addNote={action}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
