import React, { useCallback } from 'react';
import { DialogTitle, MenuItem } from '@mui/material';
import { Dialog } from '@mui/material';

import { useDispatch } from 'react-redux';
import { upadateTicketStatus } from '../../../../Redux/store/tickets/ticketsListSlice';
import { useFormik } from 'formik';
import {
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { useTicketTranslation } from '../../i18n';
import { toast } from 'react-toastify';

const UpdateStatusDialog = (props) => {
  const { setIsOpen, isOpen, ticketId, isRefreshing, setIsRefreshing } = props;

  const dispatch = useDispatch();
  const t = useTicketTranslation();

  const handleUpdateStatus = useCallback(async (payload) => {
    try {
      await dispatch(upadateTicketStatus({ id: ticketId, ...payload }));

      toast.success(t('msg_note'));
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }

      toast.error(err.data.message);
    } finally {
      setIsRefreshing(isRefreshing + 1);
    }
  });

  const init = {
    type: '',
    notify_user: false,
    note: '',
  };

  const formik = useFormik({
    initialValues: init,
    onSubmit: (values, { resetForm }) => {
      // console.log(values);
      handleUpdateStatus(values);
      resetForm();
      setIsOpen(false);
    },
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={isOpen}
      fullWidth
    >
      <DialogTitle id='simple-dialog-title'>{t('update_status')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container lg={12} md={12} spacing={2} className=' pt-4'>
            <Grid item lg={12} md={12}>
              <TextField
                required
                fullWidth
                select
                id='type'
                name='type'
                label={t('status')}
                onChange={formik.handleChange}
                value={formik.values.type}
                variant='outlined'
                InputLabelProps={{ shrink: true }}
              >
                <MenuItem value='new' className='px-4'>
                  {t('new')}
                </MenuItem>
                <MenuItem value='under_process' className='px-4'>
                  {t('under_process')}
                </MenuItem>
                <MenuItem value='closed' className='px-4'>
                  {t('closed')}
                </MenuItem>
              </TextField>
            </Grid>

            <FormControlLabel
              control={<Checkbox checked={formik.values.notify_user} />}
              label={t('notify_user')}
              name='notify_user'
              className='px-8'
              onChange={formik.handleChange}
            />

            <Grid item lg={12} md={12}>
              <TextField
                fullWidth
                id='note'
                name='note'
                label={t('note')}
                onChange={formik.handleChange}
                value={formik.values.note}
                variant='outlined'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid
              container
              lg={12}
              md={12}
              className='flex p-2 justify-center space-x-2  '
              spacing={1}
            >
              <Grid item>
                <Button variant='outlined' color='primary' type='submit'>
                  {t('edit')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='outlined'
                  color='inherit'
                  onClick={handleClose}
                >
                  {t('cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateStatusDialog;
