import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Delete } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { useCallback } from 'react';
import { ButtonGroup } from '@material-ui/core';
import {
  deleteUser,
  clearErrors,
} from '../../../core/features/userIdentitySlice';
import { toast } from 'react-toastify';
import { useIdentityTranslation } from '../../i18n';

const useStyles = makeStyles((theme) => ({
  cancel: {
    backgroundColor: '#575757',
    color: 'white',
    border: '2px',
    paddingLeft: '8px',
    paddingRight: '8px',
    '&:hover': {
      backgroundColor: '#383838',
      color: 'white',
      boxShadow: 'none',
    },
  },

  text: {
    fontSize: '18px',
    color: '#151515',
  },
  delete: {
    // '&:active': {},
    backgroundColor: '#FF392E',
    color: 'white',
    border: '2px',
    paddingLeft: '8px',
    paddingRight: '8px',
    '&:hover': {
      backgroundColor: '#9A0000',
      color: 'white',
      boxShadow: 'none',
    },
  },
  dialog: {},
}));

export default function DeleteDialog({
  id,

  isRefreshing,
  setIsRefreshing,
}) {
  const [open, setOpen] = React.useState(false);
  // const [remove, setRemove] = React.useState(false);
  const classes = useStyles();
  const t = useIdentityTranslation();
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { error } = useSelector((state) => {
    return state.userIdentitySlice;
  });

  const handleDelete = useCallback(async () => {
    try {
      await dispatch(deleteUser({ id }));
      setOpen(false);
    } finally {
      setIsRefreshing(isRefreshing + 1);
    }
  });

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
    dispatch(clearErrors());
  }, [dispatch]);

  return (
    <div>
      <ButtonGroup
        aria-label='outlined primary button group'
        onClick={handleClickOpen}
      >
        <Delete
          fontSize='small'
          color='primary'
          className=' hover:bg-blue-200 hover:cursor-pointer'
        />
      </ButtonGroup>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle id='alert-dialog-title' style={{ fontWeight: 'bold' }}>
          {t('warning')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            className={classes.text}
          >
            {t('delete_message')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='outlined' color='inherit'>
            {'cancel'}
          </Button>
          <Button
            onClick={handleDelete}
            // autoFocus
            variant='outlined'
            color='error'

            // className={classes.delete}
          >
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
