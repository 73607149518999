import { Route } from 'react-router-dom';
import OrdersListPage from './pages/orders-list-page';
import OrderDetailsPage from './pages/order-details-page';
import CreateOrderPage from './pages/create-order-page';
import OrdersStatisticsPage from './pages/orders-statistics-page';

export function OrdersRoutes() {
  return [
    <Route path='/orders' key={44}>
      <Route path='/orders/' element={<OrdersListPage />} key={'orders_list'} />
      <Route path='/orders/:id/' element={<OrderDetailsPage />} key={'order-info'} />
      <Route path='/orders/create-order/' element={<CreateOrderPage />} key={'create-orders'} />
      <Route path='/orders/statistics/' element={<OrdersStatisticsPage />} key={'create-orders'} />
    </Route>,
  ];
}
