import React, { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useCarsTranslation } from '../i18n';
import { updateCartype } from '../../../core/features/cartype-detail/cartypeDetailSlce';

const CarTypeDetails = ({
  carTypeDetails,
  setType,
  setIsRefreshing,
  isRefreshing,
}) => {
  const dispatch = useDispatch();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const t = useCarsTranslation();

  const { isLoading } = useSelector((state) => state.carTypeDetails);
  const { carTypes } = useSelector((state) => {
    console.log(state);
    return state.carTypesList;
  });

  const [options, setOptions] = useState({
    id: carTypeDetails.id,
    payload: carTypeDetails,
  });

  const validationSchema = Yup.object({
    Name: Yup.string().required('Required'),
    Description: Yup.string().required('Required'),
    minimum: Yup.string().required('Required'),
    pricePerKm: Yup.string().required('Required'),
    checkOption: Yup.array().required('Required'),
  });

  const formik = useFormik({
    initialValues: carTypeDetails,
    validationSchema: validationSchema,
  });
  const handleSubmit = async (values) => {
    await setOptions({ ...options, payload: values });

    await dispatch(updateCartype({ id: values.id, payload: values }));
    setType('table');
    setIsRefreshing(isRefreshing + 1);
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Grid container lg={12} md={12}>
      <form
        onSubmit={(e) => {
          handleSubmit(formik.values);
        }}
        className='p-4'
      >
        <Grid
          container
          lg={12}
          md={12}
          spacing={2}
          justifyContent='center'
          alignItems='center'
        >
          <Card className='shadow-2xl border-l-2 border-gray-2 '>
            <CardContent className=''>
              <Grid
                container
                lg={12}
                md={12}
                spacing={2}
                className='flex justify-center  rounded'
              >
                <Grid item lg={10} md={10}>
                  <span className='text-lg font-semibold text-slate-600 '>
                    <h1>{t('vehical')}</h1>
                  </span>
                </Grid>

                <Grid item lg={10} md={10}>
                  <TextField
                    fullWidth
                    label={t('name')}
                    name='car_name'
                    id='car_name'
                    type='text'
                    variant='outlined'
                    margin='dense'
                    onChange={formik.handleChange}
                    value={formik.values.car_name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'></InputAdornment>
                      ),
                    }}
                  />
                  {/* <ErrorMessage name="Name" /> */}
                </Grid>

                <Grid item lg={10} md={10}>
                  <TextField
                    fullWidth
                    label={t('description')}
                    name='description'
                    id='description'
                    variant='outlined'
                    // margin='dense'
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    type='text'
                  />
                  {/* <ErrorMessage name="Description" /> */}
                </Grid>

                <Grid item lg={5} md={5}>
                  <span className='text-base font-semibold text-slate-600'>
                    <h1>{t('short')}</h1>
                  </span>
                  <TextField
                    label={t('minimum')}
                    name='short_distance_km'
                    id='short_distance_km'
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    type='text'
                    onChange={formik.handleChange}
                    value={formik.values.short_distance_km}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                  />
                  {/* <ErrorMessage name="Minimum" /> */}

                  <TextField
                    // control='input'
                    label={t('pricekm')}
                    name='short_distance_price_per_km'
                    id='short_distance_price_per_km'
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    type='integer'
                    onChange={formik.handleChange}
                    value={formik.values.short_distance_price_per_km}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                  />
                  {/* <ErrorMessage name="pricePerKm" /> */}
                </Grid>

                <Grid item lg={5} md={5}>
                  <span className='text-base font-semibold text-slate-600'>
                    <h1>{t('average')}</h1>
                  </span>
                  <TextField
                    label={t('minimum')}
                    name='mid_distance_km'
                    id='mid_distance_km'
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    type='text'
                    onChange={formik.handleChange}
                    value={formik.values.mid_distance_km}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                  />
                  {/* <ErrorMessage name="Minimum" /> */}

                  <TextField
                    // control='input'
                    label={t('pricekm')}
                    name='mid_distance_price_per_km'
                    id='mid_distance_price_per_km'
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    type='integer'
                    onChange={formik.handleChange}
                    value={formik.values.mid_distance_price_per_km}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                  />
                  {/* <ErrorMessage name="pricePerKm" /> */}
                </Grid>
                <Grid item lg={10} md={10}>
                  <span className='text-base font-semibold text-slate-600'>
                    <h1>{t('open')}</h1>
                  </span>
                </Grid>
                <Grid item lg={5} md={5}>
                  <TextField
                    label={t('pricekm')}
                    name='open_trip_price_km'
                    id='open_trip_price_km'
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    type='text'
                    onChange={formik.handleChange}
                    value={formik.values.open_trip_price_km}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                  />
                  {/* <ErrorMessage name="Minimum" /> */}

                  <TextField
                    // control='input'
                    label={t('minimum')}
                    name='open_trip_minimum_fare'
                    id='open_trip_minimum_fare'
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    type='integer'
                    onChange={formik.handleChange}
                    value={formik.values.open_trip_minimum_fare}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                  />
                  {/* <ErrorMessage name="pricePerKm" /> */}
                </Grid>

                <Grid item lg={5} md={5}>
                  <span className='text-base font-semibold text-slate-600'>
                    {/* <h1>Price of Open ride</h1> */}
                  </span>
                  <TextField
                    label={t('minprice')}
                    name='open_trip_minute_price'
                    id='open_trip_minute_price'
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    type='text'
                    onChange={formik.handleChange}
                    value={formik.values.open_trip_minute_price}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                  />
                  {/* <ErrorMessage name="Minimum" /> */}

                  <TextField
                    // control='input'
                    label={t('backup')}
                    name='open_trip_backup_minute_price'
                    id='open_trip_backup_minute_price'
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    type='Text'
                    onChange={formik.handleChange}
                    value={formik.values.open_trip_backup_minute_price}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      ),
                    }}
                  />
                  {/* <ErrorMessage name="pricePerKm" /> */}
                </Grid>

                <Grid item lg={10} md={10}>
                  <span className='text-base font-semibold text-slate-600'>
                    <h1>{t('cars_allowed')}</h1>
                  </span>
                </Grid>

                <Grid item lg={10} md={10}>
                  <FormControl className='w-full'>
                    <InputLabel id='demo-mutiple-name-label'>
                      {t('car_types')}
                    </InputLabel>
                    <Select
                      labelId='demo-mutiple-name-label'
                      id='allowed_car_type_ids'
                      name='allowed_car_type_ids'
                      multiple
                      value={formik.values.allowed_car_type_ids}
                      onChange={formik.handleChange}
                      input={<Input />}
                      MenuProps={MenuProps}
                    >
                      {carTypes.map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                          {type.car_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item lg={10} md={10} className='flex justify-center'>
                  {/* <CardActions> */}

                  <Button color='primary' variant='contained' type='submit'>
                    {t('update')}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </form>
    </Grid>
  );
};
export default CarTypeDetails;
