import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import { Done } from '@mui/icons-material';
import { Box, Dialog, IconButton, Slide, Toolbar } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid-pro';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function Map({ data, setData, openMap, setOpenMap }) {
  const [pos, setPos] = useState([
    data.event_location_lat,
    data.event_location_lng,
  ]);

  const MarkerLocated = () => {
    useMapEvents({
      click: (e) => {
        setPos([e.latlng.lat, e.latlng.lng]);
      },
    });
    if (!pos[0]) return null;
    return <Marker position={pos} />;
  };

  useEffect(() => {
    setPos([data.event_location_lat, data.event_location_lng]);
  }, [data]);

  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      onClose={() => setOpenMap(false)}
      aria-labelledby='simple-dialog-title'
      open={openMap}
    >
      <Box className=' bg-blue-900 text-white'>
        <Toolbar sx={{ display: 'flex', gap: 1 }}>
          <IconButton
            edge='start'
            color='inherit'
            onClick={() => {
              setOpenMap(false);
              if (data.event_location_lat) {
                setPos([data.event_location_lat, data.event_location_lng]);
              }
            }}
            aria-label='close'
          >
            <GridCloseIcon />
          </IconButton>
          <IconButton
            edge='start'
            color='inherit'
            onClick={() => {
              setOpenMap(false);
              setData({
                ...data,
                event_location_lat: pos[0],
                event_location_lng: pos[1],
              });
            }}
            aria-label='close'
          >
            <Done />
          </IconButton>
        </Toolbar>
      </Box>

      <div className='w-full flex flex-col justify-center overflow-y-hidden'>
        <MapContainer
          center={
            pos[0] && pos[1]
              ? { lat: pos[0], lng: pos[1] }
              : { lat: 15.600519114585818, lng: 32.5761194225197 }
          }
          zoom={13}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          <MarkerLocated />
        </MapContainer>
      </div>
    </Dialog>
  );
}
