import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Grid, Paper } from '@mui/material';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import DescriptionIcon from '@mui/icons-material/Description';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AirlineSeatReclineExtraRoundedIcon from '@mui/icons-material/AirlineSeatReclineExtraRounded';

const useStyles = makeStyles((theme) => ({
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));
function DriverCarInfo(props) {
  const { t } = useTranslation('drivers');
  const classes = useStyles();
  const { driver, carTypes, updateDriver } = props;
  const [readOnly, setReadOnly] = React.useState(true);
  const [stateDriver, setStateDriver] = React.useState(driver);

  const handleSelectAndSwitchChange = (event) => {
    const { value } = event.target;
    setStateDriver({ ...stateDriver, car_type_id: value });
  };

  const handleTextInputChange = (prop, value) => {
    setStateDriver({ ...stateDriver, [prop]: value });
  };

  const updateInfo = () => {
    const {
      car_plate: carPlate,
      car_color: carColor,
      car_desc: carDesc,
      car_model_year: carModelYear,
      car_type_id: carTypeId,
    } = stateDriver;

    updateDriver({ carPlate, carColor, carDesc, carModelYear, carTypeId });
  };

  return (
    <Grid container lg={12} spacing={4} justifyContent='center'>
      {/* header  */}
      <Grid item lg={12} md={12} xs={12}>
        <h1 className='  text-left text-4xl font-extrabold font-serif text-gray-800 capitalize'>
          car information
        </h1>
      </Grid>
      {/* body  */}
      <Grid item lg={4} md={4} sm={6} xs={9}>
        <Paper elevation={6} className=' p-2 '>
          <span className=''>
            <LocalTaxiIcon />
            <h1 className='w-full pt-3 text-center uppercase text-xl font-mono font-semibold text-gray-500'>
              Plate Number
            </h1>
          </span>
          <p className='w-full p-5 text-center font-mono font-black text-xl'>
            {stateDriver.car_plate}
          </p>
        </Paper>
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={9}>
        <Paper elevation={6} className='w-full p-2 '>
          <span className=''>
            <ColorLensIcon />
            <h1 className='w-full pt-3 text-center uppercase text-xl font-mono font-semibold text-gray-500'>
              color
            </h1>
          </span>
          <p className='w-full p-5 text-center font-mono font-black text-xl'>
            {stateDriver.car_color}
          </p>
        </Paper>
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={9}>
        <Paper elevation={6} className='w-full p-2 '>
          <span className=''>
            <DescriptionIcon />
            <h1 className='w-full pt-3 text-center uppercase text-xl font-mono font-semibold text-gray-500'>
              Description
            </h1>
          </span>
          <p className='w-full p-5 text-center font-mono font-black text-xl'>
            {stateDriver.car_desc}
          </p>
        </Paper>
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={9}>
        <Paper elevation={6} className='w-full p-2 '>
          <CalendarTodayIcon />
          <span className=''>
            <h1 className='w-full pt-3 text-center uppercase text-xl font-mono font-semibold text-gray-500'>
              Manufacture Date
            </h1>
          </span>
          <p className='w-full p-5 text-center font-mono font-black text-4xl'>
            {stateDriver.car_model_year}
          </p>
        </Paper>
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={9}>
        <Paper elevation={6} className='w-full p-2 '>
          <AirlineSeatReclineExtraRoundedIcon />
          <span className=''>
            <h1 className='w-full pt-3 text-center uppercase text-xl font-mono font-semibold  text-gray-500'>
              Number Of Seats
            </h1>
          </span>
          <p className='w-full p-5 text-center font-mono font-black text-4xl'>
            {stateDriver.car_seats_count}
          </p>
        </Paper>
      </Grid>
      {/* <Grid item lg={4} md={4} sm={6} xs={9}>
				<Paper elevation={6} className='w-full p-2 '>
					<span className='p-2'>
						<h1 className='w-full text-center uppercase text-xl font-mono font-semibold text-gray-500'>
							Type
						</h1>
					</span>
					<p className='w-full p-5 text-center font-mono font-black text-xl'></p>
				</Paper>
			</Grid> */}
    </Grid>
  );
}
export default DriverCarInfo;
