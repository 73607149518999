import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@mui/styles';
import { useSelector } from 'react-redux';
import DriverFiles from './tabs/DriverFiles';
import DriverMainDetails from './tabs/DriverMainDetails';
import { usePendingTranslation } from './i18n';
import { Grid, Box, AppBar, Tabs, Tab, Typography } from '@mui/material';

//tabs config
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

//main component
const DriverDetailsTabs = ({ isRefreshing, setIsRefreshing }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { driver } = useSelector(
    (state) => state.pendingDrivers
  ).pendingDrivers;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const driverI = driver;

  const t = usePendingTranslation();

  // console.log(driverI);

  return (
    <Grid container lg={12} md={12} className='bg-gray-100 '>
      <Grid item lg={12} md={12}>
        <AppBar position='static' color='default' className=''>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            // variant='scrollable'
            // scrollButtons='auto'
            aria-label='full width tabs example'
            className='px-4'
            centered
          >
            <Tab label={t('details')} {...a11yProps(0)} />

            <Tab label={t('files')} {...a11yProps(1)} />
          </Tabs>
        </AppBar>
      </Grid>

      <Grid item lg={12} md={12} xs={12}>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel
            value={value}
            index={0}
            dir={theme.direction}
            className=' h-full min-h-[90vh]'
          >
            <DriverMainDetails
              isRefreshing={isRefreshing}
              setIsRefreshing={setIsRefreshing}
            />
          </TabPanel>

          <TabPanel
            value={value}
            index={1}
            dir={theme.direction}
            className=' h-full min-h-[90vh]'
          >
            <DriverFiles driverId={driver.driver_id} />
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
    // </Paper>
  );
};

export default DriverDetailsTabs;
