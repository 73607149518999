import React, { useCallback, useState } from 'react';
import { useDispatchTranslation } from '../../modules/dispatches/i18n';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../modules/drivers/utils';
import ConfirmationDialog from '../../modules/dispatches/components/common/ConfirmationDialog';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { updateStatus } from '../../Redux/store/dispatches/dispatchesListSlice';

export default function GlobalDetailsTap({ data, setIsRefreshing }) {
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState('');
  const [id, setId] = useState(0);
  const t = useDispatchTranslation();

  const navigate = useNavigate();

  const dispatchI = useDispatch();

  const handleUpdateStatus = useCallback(async (id) => {
    switch (id) {
      case 1:
        try {
          await dispatchI(
            updateStatus({
              dispatchId: data.dispatch_id,
              driverId: data.driver_id,
              statusId: 5,
            })
          );
          setIsRefreshing(1);
        } catch (err) {
          if (err.status === 401) {
            localStorage.removeItem('token');
            window.location.reload();
          }
          toast.error(err.data.message);
        }

        break;
      case 2:
        try {
          await dispatchI(
            updateStatus({
              dispatchId: data.dispatch_id,
              driverId: data.driver_id,
              statusId: 6,
            })
          );
          setIsRefreshing(2);
        } catch (err) {
          if (err.status === 401) {
            localStorage.removeItem('token');
            window.location.reload();
          }
          toast.error(err.data.message);
        }

        break;
      case 3:
        try {
          await dispatchI(
            updateStatus({
              dispatchId: data.dispatch_id,
              driverId: data.driver_id,
              statusId: 7,
            })
          );
          setIsRefreshing(3);
        } catch (err) {
          if (err.status === 401) {
            localStorage.removeItem('token');
            window.location.reload();
          }
          toast.error(err.data.message);
        }

        break;

      default:
        break;
    }
  });

  const handleStatus = useCallback((status_id) => {
    switch (status_id) {
      case 1:
        return (
          <p className=' text-orange-500  font-serif font-black text-2xl'>
            {t('pending')}
          </p>
        );

      case 2:
        return (
          <p className=' text-blue-600  font-serif font-black text-2xl'>
            {t('accepted')}
          </p>
        );
      case 3:
        return (
          <p className=' text-red-600  font-serif font-black text-2xl'>
            {t('timeout')}
          </p>
        );
      case 4:
        return (
          <p className=' text-red-600  font-serif font-black text-2xl'>
            {t('canceled')}
          </p>
        );
      case 5:
        return (
          <p className=' text-green-600  font-serif font-black text-2xl'>
            {t('arrived')}
          </p>
        );
      case 6:
        return (
          <p className=' text-blue-600  font-serif font-black text-2xl'>
            {t('picked')}
          </p>
        );
      case 7:
        return (
          <p className=' text-green-800  font-serif font-black text-2xl'>
            {t('completed')}
          </p>
        );
      case 8:
        return (
          <p className=' text-red-600  font-serif font-black text-2xl'>
            {t('canceled_client')}
          </p>
        );
      case 9:
        return (
          <p className=' text-red-600  font-serif font-black text-2xl'>
            {t('canceled_driver')}
          </p>
        );
      case 10:
        return (
          <p className=' text-red-600  font-serif font-black text-2xl'>
            {t('canceled_support')}
          </p>
        );
      case 11:
        return (
          <p className=' text-gray-600  font-serif font-black text-2xl'>
            {t('on_going')}
          </p>
        );
      case 12:
        return (
          <p className=' text-red-600  font-serif font-black text-2xl'>
            {t('not_show')}
          </p>
        );

      default:
        return (
          <p className=' text-gray-600  font-serif font-black text-2xl'>
            {t('status_unknown')}
          </p>
        );
    }
  }, []);

  return (
    <Grid
      container
      lg={12}
      md={12}
      xs={12}
      justifyContent='center'
      className='relative h-full'
    >
      {/* dialog  */}
      <ConfirmationDialog
        open={isOpen}
        setOpen={setIsOpen}
        handleUpdateStatus={handleUpdateStatus}
        id={id}
        status={status}
      />

      <Grid item lg={9} md={9} xs={9} className=' text-center  '>
        {/* header  */}
        <Grid item lg={12} md={12} className='pb-4'>
          {/* status */}
          {handleStatus(data.status_id)}
          {/* {handleStatus(12)} */}
        </Grid>
        <Grid item lg={12} md={12} className='pb-4'>
          <span className='text-sm font-bold font-serif w-full bg-gray-300 p-1 px-4 text-white rounded-2xl'>
            {data.dispatch_id || '....'}
          </span>
          <br />
        </Grid>

        {/* body  */}
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2} className=' flex justify-start'>
            <span className=' text-sm font-semibold '>{t('driver_id')}:</span>
          </Grid>
          <Grid
            item
            lg={8}
            md={8}
            sm={8}
            className={
              data.driver_id
                ? ' p-1 bg-white text-[#1769aa] cursor-pointer'
                : 'p-1 bg-white '
            }
            onClick={() =>
              data.driver_id ? navigate(`/drivers/${data.driver_id}/id`) : null
            }
          >
            <span className='text-sm font-serif w-full '>
              {data.driver_id || '....'}
            </span>
          </Grid>
        </Grid>

        {/* item  */}
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2} className='flex justify-start'>
            <span className=' text-sm font-semibold '>{t('from')}:</span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className='text-sm font-serif w-full '>
              {data.from_address || '....'}
            </span>
          </Grid>
        </Grid>

        {/* item  */}
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2} className='flex justify-start'>
            <span className=' text-sm font-semibold '>{t('to')}:</span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className='text-sm font-serif w-full '>
              {data.to_address || '....'}
            </span>
          </Grid>
        </Grid>

        {/* item  */}
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2} className='flex justify-start'>
            <span className=' text-sm font-semibold '>{t('price')}:</span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className='text-sm font-serif w-full'>
              {data.total_price || '0'} {t('SDG')}
            </span>
          </Grid>
        </Grid>
        {/* item  */}
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2} className='flex justify-start'>
            <span className=' text-sm font-semibold '>
              {t('delivery_price')}:
            </span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className='text-sm font-serif w-full'>
              {data.delivery_price || '0'} {t('SDG')}
            </span>
          </Grid>
        </Grid>
        {/* item  */}
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2} className='flex justify-start'>
            {' '}
            <span className=' text-sm font-semibold '>{t('paid_cash')}:</span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className='text-sm font-serif w-full'>
              {data.paid_cash || '0'} {t('SDG')}
            </span>
          </Grid>
        </Grid>
        {/* item  */}
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2} className='flex justify-start'>
            <span className=' text-sm font-semibold '>
              {t('paid_balance')}:
            </span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className='text-sm font-serif w-full'>
              {data.paid_balance || '0'} {t('SDG')}
            </span>
          </Grid>
        </Grid>
        {/* item  */}
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2} className='flex justify-start'>
            <span className=' text-sm font-semibold '>{t('order_price')}:</span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className='text-sm font-serif w-full'>
              {data.order_price || '0'} {t('SDG')}
            </span>
          </Grid>
        </Grid>
        {/* item  */}
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2} className='flex justify-start'>
            <span className=' text-sm font-semibold '>{t('distance')}:</span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className='text-sm font-serif w-full'>
              {data.distance_km || '0'} {t('KM')}
            </span>
          </Grid>
        </Grid>
        {/* item  */}
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2} className='flex justify-start'>
            <span className=' text-sm font-semibold '>{t('peak_rate')}:</span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className='text-sm font-serif w-full'>
              {data.peak_rate || '....'}
            </span>
          </Grid>
        </Grid>
        {/* item  */}
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2} className='flex justify-start'>
            <span className=' text-sm font-semibold '>{t('client')}:</span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className='text-sm font-serif w-full'>
              {data.client_name || '....'}
            </span>
          </Grid>
        </Grid>
        {/* item  */}
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2} className='flex justify-start'>
            <span className=' text-sm font-semibold '>
              {t('client_mobile')}:
            </span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className='text-sm font-serif w-full'>
              {data.client_mobile || '....'}
            </span>
          </Grid>
        </Grid>

        {/* item  */}
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2} className='flex justify-start'>
            <span className=' text-sm font-semibold '>{t('driver_note')}:</span>
          </Grid>
          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className='text-sm font-serif w-full'>
              {data.driver_note || '....'}
            </span>
          </Grid>
        </Grid>
        {/* item  */}
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2} className='flex justify-start'>
            {' '}
            <span className=' text-sm font-semibold '>
              {t('starting_date')}:
            </span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className='text-sm font-serif w-full'>
              {formatDate(data.open_trip_start_time) || '....'}
            </span>
          </Grid>
        </Grid>
        {/* item  */}
        <Grid item lg={12} md={12} className='p-2 flex justify-evenly'>
          <Grid item lg={2} md={2} className='flex justify-start'>
            <span className=' text-sm font-semibold '>{t('ending_date')}:</span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className='text-sm font-serif w-full'>
              {formatDate(data.open_trip_end_time) || '....'}
            </span>
          </Grid>
        </Grid>
        {/* btn's */}
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          className='flex justify-center pt-5 pb-4 '
          spacing={2}
        >
          <Grid item>
            <Button
              disabled={data.status_id === 5 ? true : false}
              variant='contained'
              onClick={() => {
                setId(1);
                setIsOpen(true);
                setStatus(t('arrived'));
              }}
            >
              {t('arrived')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color='primary'
              variant='contained'
              disabled={data.status_id === 6 ? true : false}
              onClick={() => {
                setId(2);
                setIsOpen(true);
                setStatus(t('picked'));
              }}
            >
              {t('picked')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color='primary'
              variant='contained'
              disabled={data.status_id === 7 ? true : false}
              onClick={() => {
                setId(3);
                setIsOpen(true);
                setStatus(t('completed'));
              }}
            >
              {t('completed')}
            </Button>
          </Grid>
          {/* </ButtonGroup> */}
        </Grid>
      </Grid>
    </Grid>
  );
}
