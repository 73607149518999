import React, { createRef, useEffect } from 'react';

import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
import { useTicketTranslation } from '../../i18n';

export default function Filters({
  payload,
  setPayload,
  setIsRefrashing,
  isRefrashing,
  onApply,
}) {
  const t = useTicketTranslation();

  return (
    //
    <Grid container lg={12} md={12} className=' flex justify-end p-1'>
      <Grid container lg={11} md={11} className='' spacing={2}>
        <Grid item lg={2} md={2} sm={2} xs={2} sx={{ mt: -1 }}>
          <TextField
            className='bg-white'
            fullWidth
            select
            id='outlined-select-currency'
            InputLabelProps={{ shrink: true }}
            label={t('status')}
            value={payload.status_id}
            onChange={(e) =>
              setPayload({ ...payload, status_id: e.target.value })
            }
            margin='dense'
            variant='outlined'
            size='small'
          >
            <MenuItem value={0}>{t('new')}</MenuItem>
            <MenuItem value={1}>{t('under_process')}</MenuItem>
            <MenuItem value={2}>{t('closed')}</MenuItem>
          </TextField>
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={2}>
          <TextField
            className='bg-white'
            variant='outlined'
            value={payload.ticket_id}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setPayload({ ...payload, ticket_id: e.target.value })
            }
            id='ticket_id'
            label={t('ticket_id')}
            type='text'
            size='small'
            fullWidth
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={2}>
          <TextField
            className='bg-white'
            variant='outlined'
            value={payload.mobile}
            onChange={(e) => setPayload({ ...payload, mobile: e.target.value })}
            id='driver_phone'
            label={t('phone')}
            type='text'
            size='small'
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>+249</InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={2}>
          <TextField
            className='bg-white'
            variant='outlined'
            value={payload.from}
            onChange={(e) => setPayload({ ...payload, from: e.target.value })}
            id='from'
            label={t('from')}
            type='date'
            size='small'
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={2}>
          <TextField
            className='bg-white'
            variant='outlined'
            value={payload.to}
            onChange={(e) => setPayload({ ...payload, to: e.target.value })}
            id='to'
            label={t('to')}
            type='date'
            size='small'
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>

      <Grid
        item
        lg={1}
        md={1}
        sm={1}
        xs={1}
        className='flex justify-center h-full '
      >
        <Button
          variant='contained'
          className='h-full p-8'
          color='primary'
          type='submit'
          onClick={onApply}
        >
          {t('apply')}
        </Button>
      </Grid>
    </Grid>
  );
}
