import { Grid } from '@mui/material';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useDriverTranslation } from '../../i18n';

const Status = ({ driver }) => {
  const t = useDriverTranslation();
  return (
    <Grid container lg={12} md={12} justifyContent='center'>
      <Grid item lg={3} md={3} className='p-2 '>
        <Grid
          item
          lg={12}
          md={12}
          className=' text-center text-gray-600 font-serif font-semibold text-xs'
        >
          {t('online')}
        </Grid>
        <Grid item lg={12} md={12} className='flex justify-center'>
          {driver.online ? (
            <CheckCircleIcon fontSize='small' className='text-green-600' />
          ) : (
            <CancelIcon fontSize='small' className='text-gray-300' />
          )}
        </Grid>
      </Grid>
      <Grid item lg={3} md={3} className='p-2 '>
        <Grid
          item
          lg={12}
          md={12}
          className=' text-center text-gray-600 font-serif font-semibold text-xs'
        >
          {t('active')}
        </Grid>
        <Grid item lg={12} md={12} className='flex justify-center'>
          {driver.active ? (
            <CheckCircleIcon fontSize='small' className='text-green-600' />
          ) : (
            <CancelIcon fontSize='small' className='text-gray-300' />
          )}
        </Grid>
      </Grid>
      <Grid item lg={3} md={3} className='p-2 '>
        <Grid
          item
          lg={12}
          md={12}
          className=' text-center text-gray-600 font-serif font-semibold text-xs'
        >
          {t('available')}
        </Grid>
        <Grid item lg={12} md={12} className='flex justify-center'>
          {driver.available ? (
            <CheckCircleIcon fontSize='small' className='text-green-600' />
          ) : (
            <CancelIcon fontSize='small' className='text-gray-300' />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Status;
