import { CloudUpload } from '@mui/icons-material';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { usePlacesTranslation } from '../../i18n/index';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

export default function UploadBtnOne({
  onChange,
  value,
  name,
  title,
  setValue,
}) {
  const classes = useStyles();

  const t = usePlacesTranslation();

  return (
    <div className={classes.root}>
      <input
        accept='image/*'
        className={classes.input}
        id={name}
        multiple
        onChange={(e) => setValue(e.currentTarget.files)}
        // value={value}
        type='file'
      />
      <label htmlFor={name}>
        <Button
          fullWidth
          size='small'
          variant='contained'
          color='primary'
          component='span'
          startIcon={<CloudUpload />}
        >
          {t('add_new_image')}
        </Button>
      </label>
    </div>
  );
}
