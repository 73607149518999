import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Chart from './common/Chart';

const MonthChart = () => {
  const { monthlyRateData } = useSelector((state) => state.app).app;
  return <Chart data={monthlyRateData.acceptance_rates} type={'weekly'} />;
};

export default MonthChart;
