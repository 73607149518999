import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const OTPForm = ({ handleLogin, setcode, code }) => {
  const handleChange = (e) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split('-');

    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 4) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
        );

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }

    let newCode = code.split('');
    newCode[Number(fieldIndex) - 1] = value;
    setcode(newCode.join(''));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <div
        dir='ltr'
        id='otp'
        className='flex flex-row justify-center text-center px-2 mt-5'
      >
        <input
          autoFocus
          className='m-2 border-2 border-black h-10 w-10 text-center form-control rounded'
          type='text'
          id='first'
          maxLength='1'
          onChange={handleChange}
          name='ssn-1'
        />
        <input
          className='m-2 border-2 border-black h-10 w-10 text-center form-control rounded'
          type='text'
          id='second'
          maxLength='1'
          onChange={handleChange}
          name='ssn-2'
        />
        <input
          className='m-2 border-2 border-black h-10 w-10 text-center form-control rounded'
          type='text'
          id='third'
          maxLength='1'
          onChange={handleChange}
          name='ssn-3'
        />
        <input
          className='m-2 border-2 border-black h-10 w-10 text-center form-control rounded'
          type='text'
          id='fourth'
          maxLength='1'
          onChange={handleChange}
          name='ssn-4'
        />
      </div>
      <div className='w-full flex justify-center pt-4'>
        <button
          type='submit'
          className='inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out'
        >
          {t('Verify')}
        </button>
      </div>
    </form>
  );
};

export default OTPForm;
