import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import AcceptanceRate from './acceptance-rate/Routes';
import CarTypesRoutes from './cartypes/Routes';
import DispatchingTimesPage from './dispatching_times/DispatchingTimesPage';
import { ticketsRoutes } from './drivers-tickets/Routes';
import { SupplyDemandRoutes } from './supply-demand/Routes';
import TrackingDrivers from './tracking_drivers/pages/tracking-page';

const Dispatch = () => {
  return (
    <Route path='/dispatch' key={'R2'}>
      {AcceptanceRate()}
      {CarTypesRoutes()}
      {SupplyDemandRoutes()}
      {ticketsRoutes()}
      {/* new file structre here */}
      <Route
        path='/dispatch/system_config'
        element={<DispatchingTimesPage />}
      />
      <Route
        path='/dispatch/tracking/'
        element={<TrackingDrivers />}
        key={'track'}
      />
    </Route>
  );
};

export default Dispatch;
