const events_ar = {
  name: 'الاسم',
  mobile: 'رقم الجوال',
  email: 'البريد الإلكتروني',
  actions: 'العمليات',
  delete_provider_msg: 'هل انت متاكد انك تريد حذف مزود الفعالية',
  events: 'الفعاليات',
  events_providers: 'مزودين الفعاليات',
  add: 'إضافة',
  providers: 'مزودين الفعاليات',
  about_provider: 'تفاصيل مزود الفعالية',
  provider_events: 'فعاليات المزود',
  provider_employees: 'موظفين المزود',
  role: 'المنصب',
  employee: 'موظف',
  manager: 'مدير',
  delete_employee_msg: 'هل انت متاكد انك تريد حذف الموظف',
  add_new: 'اضافة',
  date: 'التاريخ',
  description: 'الوصف',
  actions: 'العمليات',
  add_new_employee: 'إضافة موظف جديد',
  name_is_required: 'الإسم مطلوب',
  mobile_number_is_not_vaild: 'رقم الهاتف غير صالح',
  cancle: 'إلغاء',
  please_fill_all_the_places: 'الرجاء إكمال جميع الحقول',
  edit_event: 'تعديل الفعالية',
  add_new_event: 'إضافة فعالية جديدة',
  event_name_ar: 'إسم الفعالية بالعربية',
  event_name_en: 'إسم الفعالية بالإنجليزية',
  event_address_ar: 'عنوان الفعالية بالعربية',
  event_address_en: 'عنوان الفعالية بالإنجليزية',
  event_desc_en: 'تفاصيل الفعالية بالإنجليزية',
  event_desc_ar: 'تفاصيل الفعالية بالعربية',
  event_start_date: 'تاريخ بدء الفعالية',
  event_end_date: 'تاريخ انتهاء الفعالية',
  start_booking_at: 'تاريخ بدء الحجز',
  end_booking_at: 'تاريخ انتهاء الحجز',
  select_images: 'اختيار الصور',
  edit_location: 'تعديل الموقع',
  select_location: 'اختيار الموقع',
  edit_event_provider: 'تعديل مزود الفعالية',
  add_new_event_provider: 'اضافة مزود فعالية جديد',
  full_name_ar: 'الاسم الكامل بالعربي',
  full_name_en: 'الاسم الكامل بالانجليزي',
  provider_logo: 'شعار مزود الفعالية',
  address: 'العنوان',
  all: 'الكل',
  deleted: 'محذوف',
  not_deleted: 'غير محذوف',
  status: 'الحالة',
  apply: 'تطبيق',
  edit: 'تعديل',
  delete_event_msg: 'هل انت متاكد انك تريد حذف الفعالية',
  all_tickets_qty: 'عدد التذاكر الكلي',
  max_tickets_qty_per_user: 'اقصى عدد تذاكر للفرد',
  enable_survey: 'تفعيل الاستطلاع',
  survey: 'الاستطلاع',
  form: 'النموذج',
  add_event: 'إضافة فعالية',
  choose_provider_to_continue: 'اختار مزود فعالية للتقدم',
  choose: 'اختيار',
  sold_tickets_qty: 'عدد التذاكر التي تم بيعها',
  remaining_tickets_qty: 'عدد التذاكر المتبقية',
  new: 'جديد',
  canceled: 'ملغي',
  hidden: 'مخفي',
  start_booking: 'تم بدء الحجز',
  end_booking: 'تم انتهاء الحجز',
  event_details: 'تفاصيل الفعالية',
  show_location: 'إظهار الموقع',
  about_event: 'تفاصيل الفعالية',
  event_provider: 'مزود الفعالية',
  event_address: 'عنوان الفعالية',
  require_booking_time: 'تحديد زمن الحجز',
  event_note_ar: 'إضافة ملاحظة بالعربية',
  event_note_en: 'إضافة ملاحظة بالإنجليزية',
  event_updated_successfuly: 'تم تحديث الفعالية بنجاح',
  event_created_successfuly: 'تم إنشاء الفعالية بنجاح',
  min_tickets_qty_per_booking: 'ادنى عدد تذاكر للحجز',
  lemon_percentage: 'نسبة ليمون المئوية',
  event_images: 'صور الفعالية',
  event_note: 'ملاحظة الفعالية',
  latitude: 'خط العرض',
  longitude: 'خط الطول',
  ticket_name: 'نوع التذكرة',
  ticket_price: 'سعر التذكرة',
  tickets_qty: 'عدد التذاكر',
  cancel: 'إلغاء',
  enabled: 'مفعل',
  disabled: 'غير مفعل',
  update_ticket_type: 'تحديث نوع التذكرة',
  create_new_ticket_type: 'انشاء نوع تذكرة جديد',
  ticket_type_name_en: 'اسم نوع التذكرة بالعربية',
  ticket_type_name_ar: 'اسم نوع التذكرة بالانجليزية',
  ticket_price: 'سعر التذكرة',
  tickets_qty: 'عدد التذاكر',
  are_you_sure_you_want_to_delete: 'هل انت متاكد انك تريد الحذف',
  disable_msg: 'هل انت متاكد انك تريد الغاء تفعيل التذكرة',
  enable_msg: 'هل انت متاكد انك تريد تفعيل التذكرة',
  deleted_successfully: 'تم الحذف بنجاح',
  done_successfully: 'تم بنجاح',
  event_tickets: 'تذاكر الفعالية',
  updated_successfully: 'تم التحديث بنجاح',
  update: 'تعديل',
  created_successfully: 'تم الانشاء بنجاح',
  show_images: 'إظهار الصور',
  start_booking_msg: 'هل انت متاكد انك تريد بدء الحجز لهذه الفعالية؟',
  cancel_event_msg: 'هل انت متاكد انك تريد إلغاء هذه الفعالية؟',
  hide_event_msg: 'هل انت متاكد انك تريد إخفاء هذه الفعالية؟',
  hide: 'اخفاء',
  starting_booking: 'بدء الحجز',
  ending_booking: 'انهاء الحجز',
  end_booking_msg: 'هل انت متاكد انك تريد انهاء الحجز لهذه الفعالية؟',
  events_list: 'قائمة الفعاليات',
  event_status: 'حالة الفعالية',
  ticket_number: 'رقم التذكرة',
  user_name: 'اسم المستخدم',
  user_mobile: 'رقم هاتف المستخدم',
  ticket_type: 'نوع التذكرة',
  booking_time: 'زمن الحجز',
  created_at: 'تاريخ الانشاء',
  view_qr: 'عرض الQR',
  confirmd: 'مؤكد',
  consumed: 'مستخدم',
  events_tickets: 'تذاكر الفعاليات',
  from: 'من',
  to: 'الى',
  event_id: 'رقم الفعالية',
  purchased_from_app: 'تم من التطبيق',
  event_tickets_types: 'انواع تذاكر الفعالية',
  add_event_to_place: 'إضافة الفعالية الى مكان',
  choose_event_to_continue: 'قم بإختيار فعالية لتتم اضافتها الى المكان',
  event_date: 'تاريخ الفعالية',
  start_at: 'يبدأ في',
  end_at: 'ينتهي في',
  booking_date: 'تاريخ الحجز',
  user_info: 'بيانات المستخدم',
};

export default events_ar;
