import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Slide,
  Toolbar,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingIndicator from '../../../../../components/common/LoadingIndicator';
import { useEventsTranslation } from '../../../../services/i18n';
import api from '../../../../services/API/events-dashboard';
import { toast } from 'react-toastify';
import {
  AirplaneTicket,
  CalendarToday,
  Close,
  ConfirmationNumber,
  Description,
  FmdGood,
  Image,
  LocationOn,
  MapOutlined,
  Note,
  Percent,
  Person,
  PresentToAll,
} from '@mui/icons-material';
import moment from 'moment';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import DeleteModal from '../../../../../components/new-components/DeleteModal';
import { eventsStatuses } from '../../../utils';

const statusTypes = {
  1: 'start_booking_msg',
  2: 'cancel_event_msg',
  3: 'hide_event_msg',
  4: 'end_booking_msg',
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function EvnetDetails({ data, setIsRefreshing }) {
  const t = useEventsTranslation();

  const { id } = useParams();

  const [openMap, setOpenMap] = useState(false);
  const [openImagesDialog, setOpenImagesDialog] = useState(false);
  const [type, setType] = useState(0);
  const [statusModalOpen, setStatusModalOpen] = useState(false);

  const handleChangeStatus = async () => {
    if (!type in statusTypes) return;
    try {
      if (type === 1) await api.startBookingEvent(id);
      else if (type === 2) await api.cancelEvent(id);
      else if (type === 3) await api.hideEvent(id);
      else if (type === 4) await api.endBookingEvent(id);
      toast.success(t('done_successfully'));
      setIsRefreshing((prev) => prev + 1);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Grid>
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          className=' border-2 p-1  bg-gray-100 w-full'
        >
          {/* <DriverTreeView /> */}
          <Grid item lg={12} className='pb-10 pt-5 relative'>
            {/* profile img  */}
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className='flex justify-center  cursor-pointer'
            >
              <Avatar
                src={data.event_provider?.provider_logo}
                sx={{ width: 100, height: 100 }}
              />
            </Grid>

            <div className='absolute left-10 flex gap-3 items-center'>
              <Button
                variant='outlined'
                onClick={() => {
                  setType(1);
                  setStatusModalOpen(true);
                }}
                color='success'
              >
                {t('starting_booking')}
              </Button>
              <Button
                variant='outlined'
                onClick={() => {
                  setType(2);
                  setStatusModalOpen(true);
                }}
                color='error'
              >
                {t('cancel')}
              </Button>
              <Button
                variant='outlined'
                onClick={() => {
                  setType(3);
                  setStatusModalOpen(true);
                }}
                color='warning'
              >
                {t('hide')}
              </Button>
              <Button
                variant='outlined'
                onClick={() => {
                  setType(4);
                  setStatusModalOpen(true);
                }}
                color='info'
              >
                {t('ending_booking')}
              </Button>
            </div>

            <DeleteModal
              message={t(statusTypes[type])}
              onConfirm={() => handleChangeStatus()}
              openModal={statusModalOpen}
              setOpenModal={setStatusModalOpen}
            />

            {/* header */}
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              marginBottom={3}
              textAlign='center'
            >
              <p className='font-serif font-extrabold text-gray-700 text-2xl'>
                {data.event_name}
              </p>
              <div className='mt-5 gap-2 flex items-center justify-center'>
                <Chip
                  sx={{
                    fontSize: '20px',
                    p: 1,
                    pb: 2,
                  }}
                  color={eventsStatuses[data.event_status_id - 1].color}
                  variant='outlined'
                  label={t(eventsStatuses[data.event_status_id - 1].name)}
                />
              </div>
            </Grid>
            <Divider variant='middle' />
            {/* body */}
            <Grid
              container
              lg={12}
              md={12}
              paddingX={4}
              flexDirection='row'
              spacing={2}
            >
              {/* leftside */}
              <Grid item lg={4} md={4}>
                <List sx={{ widht: '100%' }}>
                  {/* item 1 */}
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Person className='text-black w-2' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t('event_provider')}
                      secondary={data.event_provider?.full_name || '...'}
                    />
                  </ListItem>
                  <Divider variant='inset' component='li' />
                  {/* item 1 */}
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Description className='text-black w-2' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t('about_event')}
                      secondary={data.event_desc || '...'}
                    />
                  </ListItem>
                  <Divider variant='inset' component='li' />
                  {/* note */}
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Note className='text-black w-2' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t('event_note')}
                      secondary={data.event_note || '...'}
                    />
                  </ListItem>
                  <Divider variant='inset' component='li' />
                  {/* item 2 */}
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <FmdGood className='text-black' />
                      </Avatar>
                    </ListItemAvatar>
                    <div className='flex truncate justify-between items-center w-full'>
                      <div className='truncate w-full'>
                        <ListItemText
                          primary={t('event_address')}
                          secondary={data.event_address || '...'}
                        />
                      </div>
                      <div className='p-1'>
                        <Button
                          color='info'
                          variant='outlined'
                          endIcon={<LocationOn />}
                          onClick={() => setOpenMap(true)}
                        >
                          {t('show_location')}
                        </Button>
                      </div>
                    </div>
                  </ListItem>
                  <Divider variant='inset' component='li' />
                </List>
              </Grid>
              {/* rigthside */}
              <Grid item lg={4} md={4}>
                <List>
                  {/* item 1 */}
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <ConfirmationNumber className='text-black w-2' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t('all_tickets_qty')}
                      secondary={data.all_tickets_qty}
                    />
                  </ListItem>
                  <Divider variant='inset' component='li' />
                  {/* item 2 */}
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <ConfirmationNumber className='text-black' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t('sold_tickets_qty')}
                      secondary={
                        data.all_tickets_qty
                          ? data.all_tickets_qty - data.remaining_tickets_qty
                          : '...'
                      }
                    />
                  </ListItem>
                  <Divider variant='inset' component='li' />
                  {/* item 3 */}
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <ConfirmationNumber className='text-black' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t('remaining_tickets_qty')}
                      secondary={data.remaining_tickets_qty}
                    />
                  </ListItem>
                  <Divider variant='inset' component='li' />
                  {/*  */}
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <ConfirmationNumber className='text-black' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t('max_tickets_qty_per_user')}
                      secondary={data.max_tickets_qty_per_user}
                    />
                  </ListItem>
                  <Divider variant='inset' component='li' />
                  {/*  */}
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <ConfirmationNumber className='text-black' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t('min_tickets_qty_per_booking')}
                      secondary={data.min_tickets_qty_per_booking}
                    />
                  </ListItem>
                  <Divider variant='inset' component='li' />
                  {/* booking */}
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <AirplaneTicket className='text-black' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t('require_booking_time')}
                      secondary={data.require_booking_time ? t('yes') : t('no')}
                    />
                  </ListItem>
                  <Divider variant='inset' component='li' />
                  {/* images */}
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Image className='text-black' />
                      </Avatar>
                    </ListItemAvatar>
                    <div className='flex truncate justify-between items-center w-full'>
                      <div className='truncate w-full'>
                        <ListItemText
                          primary={t('event_images')}
                          secondary={
                            (data.event_images?.length || 0) + ' ' + t('images')
                          }
                        />
                      </div>
                      <div className='p-1'>
                        <Button
                          color='info'
                          variant='outlined'
                          endIcon={<Image />}
                          onClick={() => setOpenImagesDialog(true)}
                        >
                          {t('show_images')}
                        </Button>
                      </div>
                    </div>
                  </ListItem>
                  <Divider variant='inset' component='li' />
                </List>
              </Grid>
              {/* side3 */}
              <Grid item lg={4} md={4}>
                <List sx={{ widht: '100%' }}>
                  {/* item 3 */}
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Percent className='text-black' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t('lemon_percentage')}
                      secondary={data.lemon_percentage || '...'}
                    />
                  </ListItem>
                  <Divider variant='inset' component='li' />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <CalendarToday className='text-black' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t('event_start_date')}
                      secondary={
                        moment(data.event_start_date).format(
                          'DD/MM/YYYY, h:mm:ss a'
                        ) || '...'
                      }
                    />
                  </ListItem>
                  <Divider variant='inset' component='li' />
                  {/* item 4 */}
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <CalendarToday className='text-black' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t('event_end_date')}
                      secondary={
                        moment(data.event_end_date).format(
                          'DD/MM/YYYY, h:mm:ss a'
                        ) || '...'
                      }
                    />
                  </ListItem>
                  <Divider variant='inset' component='li' />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <CalendarToday className='text-black' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t('start_booking_at')}
                      secondary={
                        moment(data.start_booking_at).format(
                          'DD/MM/YYYY, h:mm:ss a'
                        ) || '...'
                      } //FIXME:
                    />
                  </ListItem>
                  <Divider variant='inset' component='li' />
                  {/* item  */}
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <CalendarToday className='text-black' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t('end_booking_at')}
                      secondary={
                        moment(data.end_booking_at).format(
                          'DD/MM/YYYY, h:mm:ss a'
                        ) || '...'
                      }
                    />
                  </ListItem>
                  {/* item 4 */}
                  <Divider variant='inset' component='li' />
                </List>
              </Grid>
            </Grid>
            {/* <Divider variant='middle' /> */}
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        onClose={() => setOpenMap(false)}
        aria-labelledby='simple-dialog-title'
        open={openMap}
      >
        <Box className=' bg-blue-900 text-white'>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => {
                setOpenMap(false);
              }}
              aria-label='close'
            >
              <Close />
            </IconButton>
          </Toolbar>
        </Box>

        <div className='w-full flex flex-col justify-center overflow-y-hidden'>
          <MapContainer
            center={{
              lat: data.event_location_lat,
              lng: data.event_location_lng,
            }}
            zoom={13}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            <Marker
              position={{
                lat: data.event_location_lat,
                lng: data.event_location_lng,
              }}
            />
          </MapContainer>
        </div>
      </Dialog>
      <Dialog
        open={openImagesDialog}
        onClose={() => setOpenImagesDialog(false)}
        TransitionComponent={Transition}
        fullScreen
      >
        <Box className=' bg-blue-900 text-white'>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => {
                setOpenImagesDialog(false);
              }}
              aria-label='close'
            >
              <Close />
            </IconButton>
          </Toolbar>
        </Box>
        <ImageList
          sx={{ width: '100%', padding: 10 }}
          variant='woven'
          cols={3}
          gap={8}
          rowHeight={250}
        >
          {data.event_images?.map((item) => (
            <ImageListItem key={item.url}>
              <img
                src={`${item.url}?w=250&h=250&fit=crop&auto=format`}
                srcSet={`${item.url}?w=250&h=250&fit=crop&auto=format&dpr=2 2x`}
                alt={item.id}
                loading='lazy'
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Dialog>
    </>
  );
}
