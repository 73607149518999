import { configureStore } from "@reduxjs/toolkit";
import eventsProvidersSlice from "../features/events-providers/eventsProvidersSlice";
import eventsSlice from "../features/events/eventsSlice";
import providerEmployeesSlice from "../features/provider-employees/providerEmployeesSlice";

const eventsStore = configureStore({
  reducer: {
    eventsProvidersSlice,
    eventsSlice,
    providerEmployeesSlice,
  },
});

export default eventsStore;
