import http from '../http';

export const createEvent = async (formData) => {
  try {
    const { data } = await http.post('/dashboard/events', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
};
