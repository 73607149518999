const dispatch_en = {
  dispatches: 'Dispatches',
  dispatches_list: 'Dispatches List',
  dispatch_details: 'Dispatch Details',
  //   details: '',
  //   transactions: '',
  //   tracking: '',
  driver: 'Driver',
  driver_id: 'Driver ID',
  dispatch_drivers: 'Dispatch drivers',
  driver: 'Driver',
  complete: 'Complete',
  picked: 'Picked',
  arraived: 'Arraived',
  ending_date: 'Ending date',
  starting_date: 'Starting date',
  driver_note: 'Driver note',
  client_mobile: 'Client mobile',
  client: 'Client',
  peak_rate: 'Peak rate',
  distance: 'Distance',
  order_price: 'Order price',
  // SDG: '',
  paid_balance: 'Paid balance',
  paid_cash: 'Paid cash',
  delivery_price: 'Delivery price',
  price: 'Price',
  driver: 'Driver',
  to: 'To',
  from: 'From',
  pending: 'PENDING',
  accepted: 'ACCEPTED',
  timeout: 'TIMEDOUT',
  canceled: 'CANCELED',
  arraived: 'ARRIVED',
  picked: 'PICKED',
  compeleted_cancel: ' COMPLETED CANCELED BY CLIENT',
  canceled_driver: 'CANCELED BY DRIVER',
  canceled_support: 'CANCELED BY SUPPORT',
  on_going: 'ON GOING',
  not_show: ' NOT SHOW',
  status_unknown: 'Status unknown',
  supervisor: 'Supervisor',
  // admin: '',
  system: 'System',
  status: 'Status',
  created_at: 'Created at',
  created_by: 'Created by',
  status_change: 'Status Change',
  status_msg: 'Change the Dispatch Status to',
  updated_at: 'Updated at ',
  location: 'Location',
  action: 'Action',
  view: 'View',
  eligible: 'Eligible',
  dispatched: 'Dispatched',
  received: 'Received',
  viewed: 'Viewed',
  dispatch_transactins: 'Dispatch Transactions',
  client_info: 'Client info',
  dispatch_status: 'Dispatch status',
  distance: 'Distance',
  paid_info: 'Paid info',
  // SDG: 'جنيه',
  driver_info: 'Driver Info ',
  car_type: ' Car type',

  mobile_number: ' Mobile number',
  dates_info: 'Dates Info ',
};

export default dispatch_en;
