import { createAsyncThunk, createSlice, isFulfilled } from '@reduxjs/toolkit';
import api from '../../../services/API/drivers-tickects';

const initialState = {
  isLoading: false,
  tickets: [],
  page: 0,
  statusId: 0,
  supportUsers: [],
  error: null,
};

export const fetchData = createAsyncThunk(
  'driversTicketsList/fetchData',
  async (args, { getState, dispatch }) => {
    await Promise.all([dispatch(fetchSupportUsers()), dispatch(getTickets())]);
  }
);
export const getTickets = createAsyncThunk(
  'driversTicketsList/getTickets',
  async (args, { getState }) => {
    const state = getState().driversTicketsList;
    const res = await api.getDriversTickets({
      page_size: 10,
      status_id: state.statusId,
      page_number: state.page,
    });
    return res;
  }
);
export const fetchSupportUsers = createAsyncThunk(
  'driversTicketsList/fetchSupportUsers',
  async () => {
    const res = await api.getSupportUsers();
    return res;
  }
);

export const assignSupportUser = createAsyncThunk(
  'driversTicketsList/assignSupportUser',
  async () => {
    await api.assignSupportUser();
  }
);

const driversTicketslListSlice = createSlice({
  name: 'driversTicketsList',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    //fetchData
    builder.addCase(fetchData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    //fetchtickets
    builder.addCase(getTickets.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTickets.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tickets = action.payload;
    });
    builder.addCase(getTickets.rejected, (state, action) => {
      state.isLoading = false;
    });
    //fetchSupportUsers
    builder.addCase(fetchSupportUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSupportUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supportUsers = action.payload;
    });
    builder.addCase(fetchSupportUsers.rejected, (state, action) => {
      state.isLoading = false;
    });
    //assignSupportUser
    builder.addCase(assignSupportUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(assignSupportUser.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(assignSupportUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const { setPage } = driversTicketslListSlice.actions;
export default driversTicketslListSlice.reducer;
