import { Grid } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import PageHeading from '../../../../components/common/PageHeading';
import eventsStore from '../../../core/app/store';
import {
  clearErrors,
  fetchEvents,
  getEventDetails,
} from '../../../core/features/events/eventsSlice';
import { useEventsTranslation } from '../../../services/i18n';
import CreateEventForm from '../components/CreateEventForm';

export default function UpdateEvent() {
  const { isLoading, error, event } = useSelector((state) => state.eventsSlice);
  const [isRefreshing, setIsRefreshing] = useState(0);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEventDetails(id));
  }, [dispatch, isRefreshing]);

  const formData = useMemo(() => {
    const dates = {
      event_start_date: 1,
      event_end_date: 1,
      start_booking_at: 1,
      end_booking_at: 1,
    };
    const newObj = {};
    for (let key in event) {
      if (key in dates) {
        newObj[key] = moment(event[key]).format('YYYY-MM-DDTHH:mm');
      } else {
        newObj[key] = event[key];
      }
    }
    newObj.update = true;
    return newObj;
  }, [event]);

  useEffect(() => {
    if (error !== null) {
      toast.error(error.message);
      dispatch(clearErrors());
    }
  }, [dispatch, error]);

  const t = useEventsTranslation();

  if (isLoading || !formData.event_id) return <LoadingIndicator />;

  return (
    <>
      <PageHeading
        heading1={t('events')}
        heading2={t('edit')}
        backBtn
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />
      <CreateEventForm setIsRefreshing={setIsRefreshing} {...formData} />
    </>
  );
}
