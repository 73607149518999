import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearErrors,
  deleteEventProvider,
  fetchAllEventsProviders,
} from '../../../core/features/events-providers/eventsProvidersSlice';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import PageHeading from '../../../../components/common/PageHeading';
import {
  Avatar,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from '@mui/material';
import CreateProviderForm from './events-provider-create';
import { Add, Delete, Edit, Email, Info, Phone } from '@mui/icons-material';
import {
  createSearchParams,
  Link,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import DeleteModal from '../../../../components/new-components/DeleteModal';
import { useEventsTranslation } from '../../../services/i18n';
import CustomPagination from '../../../../components/new-components/CustomPagination';
import { Empty } from '../../../../components/new-components/Empty';

export default function EventsProvidersList() {
  const { isLoading, events, error } = useSelector((state) => {
    return state.eventsProvidersSlice;
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [seaechParams, setSearchParams] = useSearchParams(
    createSearchParams({
      page_number: 0,
      page_size: 10,
      is_deleted: false,
    })
  );

  const defaultSearchParams = useMemo(
    () => ({
      page_number: seaechParams.get('page_number'),
      page_size: seaechParams.get('page_size'),
      is_deleted: seaechParams.get('is_deleted'),
    }),
    [seaechParams]
  );

  const [openModal, setOpenModal] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(0);
  const [prevFormData, setPrevFormData] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [deletedId, setDeletedId] = useState(0);

  const handleEdit = (provider) => {
    setPrevFormData({ ...provider, update: true });
    setOpenModal(true);
  };

  const setPage = (newPage) => {
    setSearchParams({
      ...defaultSearchParams,
      page_number: newPage,
    });
  };

  useEffect(() => {
    dispatch(fetchAllEventsProviders(defaultSearchParams));
  }, [dispatch, navigate, isRefreshing]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
    dispatch(clearErrors());
  }, [error, dispatch, navigate]);

  const t = useEventsTranslation();

  const cols = [
    {
      field: 'name',
      headerName: t('name'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <div className='flex w-full items-center '>
          <div className='ml-2'>
            <Avatar
              alt={params.value.full_name_en}
              src={params.value.provider_logo}
            />
          </div>
          <div className='flex flex-col ml-3'>
            <p className=''>{params.value.full_name_ar}</p>
            <p className=''>{params.value.full_name_en}</p>
          </div>
        </div>
      ),
    },
    {
      field: 'mobile',
      headerName: t('mobile'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <div className='ml-2 flex items-center'>
          <Phone /> <p dir='ltr'>{params.value.mobile}</p>
        </div>
      ),
    },
    {
      field: 'email',
      headerName: t('email'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <div className='ml-2'>
          <Email /> {params.value.email}
        </div>
      ),
    },
    {
      field: 'btn',
      headerName: t('actions'),
      headerClassName: 'bg-gray-100 text-center w-full h-full',
      disableColumnMenu: true,
      flex: 0.8,
      renderCell: (params) =>
        params.value.is_deleted ? (
          <div className='ml-2 text-lg text-red-500'>{t('deleted')}</div>
        ) : (
          <div className='flex items-center ml-2 w-full gap-2'>
            <Link to={'/events/providers/' + params.value.provider_id}>
              <Info color='primary' />
            </Link>
            <button onClick={() => handleEdit(params.value)}>
              <Edit className='text-gray-800' />
            </button>
            <button
              onClick={() => {
                setConfirmModal(true);
                setDeletedId(params.value.provider_id);
              }}
            >
              <Delete color='error' />
            </button>
          </div>
        ),
    },
  ];

  const rowsData = events.map((provider) => {
    return {
      id: provider.provider_id,
      name: provider,
      email: provider,
      mobile: provider,
      btn: provider,
    };
  });

  const handlePagination = (newPage) => {
    setPage(newPage);
    setIsRefreshing((prev) => prev + 1);
  };

  return (
    <>
      <DeleteModal
        setOpenModal={setConfirmModal}
        openModal={confirmModal}
        onConfirm={() => {
          dispatch(deleteEventProvider(deletedId));
          setIsRefreshing((prev) => prev + 1);
        }}
        // message={'Are you sure do you want to delete this provider'}
        message={t('delete_provider_msg')}
      />
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <CreateProviderForm
          {...prevFormData}
          setOpenModal={setOpenModal}
          setIsRefreshing={setIsRefreshing}
        />
      </Modal>
      <div className='flex w-full justify-between items-center my-2 p-2'>
        <PageHeading
          heading1={t('events')}
          heading2={t('events_providers')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn={true}
        />
        <div className='w-[10%]'>
          <Button
            variant='contained'
            fullWidth
            color='primary'
            onClick={() => {
              setOpenModal(true);
              setPrevFormData({});
            }}
            className='flex items-center'
            endIcon={<Add />}
          >
            {t('add_new')}
          </Button>
        </div>
      </div>
      <Grid
        item
        lg={12}
        marginBottom={2}
        justifyContent='space-between'
        className='bg-gray-100 flex p-2'
      >
        <Grid item lg={10}>
          <Grid width={300} marginX={1}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>
                {t('status')}
              </InputLabel>
              <Select
                sx={{ height: 50 }}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={defaultSearchParams.is_deleted}
                label={t('status')}
                onChange={(e) => {
                  setSearchParams({
                    ...defaultSearchParams,
                    is_deleted: e.target.value,
                  });
                }}
              >
                <MenuItem value={true}>{t('deleted')}</MenuItem>
                <MenuItem value={false}>{t('not_deleted')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button
          variant='contained'
          onClick={() => setIsRefreshing((prev) => prev + 1)}
          color='primary'
        >
          {t('apply')}
        </Button>
      </Grid>
      <div className='flex h-auto'>
        <div className=' flex-grow'>
          <DataGrid
            className='border-2'
            disableColumnFilter
            autoHeight
            rowHeight={60}
            page={Number(defaultSearchParams.page_number)}
            loading={isLoading}
            rows={rowsData}
            columns={cols}
            pageSize={10}
            rowCount={100}
            paginationMode='server'
            onPageChange={(newPage) => handlePagination(newPage)}
            CellStyle='{StaticResource DataGridBorder}'
            components={{
              Toolbar: () => <></>,
              Pagination: () => CustomPagination(isLoading),
              NoRowsOverlay: Empty,
              NoResultsOverlay: Empty,
              LoadingOverlay: LoadingIndicator,
            }}
          />
        </div>
      </div>
    </>
  );
}
