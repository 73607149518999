import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect } from "react";
import { useOrdersTranslation } from "../../i18n";
import TransactionsTable from "../common/TransactionsTable";

const OrderTransactions = ({ transactions }) => {
  const t = useOrdersTranslation();

  return (
    <Grid
      container
      lg={12}
      md={12}
      justifyContent="center"
      spacing={2}
      className="h-full"
    >
      <Grid item lg={12} md={12} className="flex justify-center">
        <h1 className="text-xl text-gray-600">{t("Order_Transactins")}</h1>
      </Grid>
      <Grid item lg={12} md={12}>
        <TransactionsTable transactions={transactions} />
      </Grid>
    </Grid>
  );
};

export default OrderTransactions;
