import React, { useCallback, useEffect, useState } from 'react';
import { Button, makeStyles, Paper } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  createNewTrip,
  removeItemSuccess,
  fetchOrdersSuccess,
} from '../../../Redux/store/shareTrip/shareTripListSlice';
import OrderDialog from './OrderDialog';

const useStyles = makeStyles({
  customWidth: {
    width: '350px',
  },
  MainButton: {
    color: '#E6B400',
    width: '100%',
    border: '2px',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  button: {
    border: '2px',
    color: '#E6B400',
    width: '100%',
  },
  basic: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    float: 'right',
  },
  icon: {
    display: 'inline-block',
    alignSelf: 'flex-end',
  },
});

export default function TripsList({
  selectedTrips,
  setSelectedTrips,
  isLoading,
  setIsLoading,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menu, setMenu] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { trips, pointsList } = useSelector((state) => {
    return state.shareTrip.shareTripList;
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = async (item) => {
    const deletePoint = pointsList.find(
      (obj) => obj.id == item.id && obj.action == item.action
    );
    const listOfPonitsFiltered = pointsList.filter((obj) => obj != deletePoint);
    setMenu(deletePoint);

    await dispatch(removeItemSuccess(listOfPonitsFiltered));
  };
  useEffect(async () => {
    const amendedTrips = selectedTrips.map((item, i) => {
      if (menu.id === i && menu.action === 'pickup')
        return {
          ...item,
          showPickUp: true,
          // pick_up_latitude: menu.lat,
          // pick_up_longitude: menu.long,
        };
      else if (menu.id === i && menu.action === 'dropoff')
        return {
          ...item,
          showDropOff: true,
          // drop_off_latitude: menu.lat,
          // drop_off_longitude: menu.long,
        };
      else return item;
    });
    // setRemovedPonit(amendedTrips);
    setSelectedTrips(amendedTrips);

    // await dispatch(fetchOrdersSuccess(amendedTrips));
  }, [menu]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper>
      <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        className={classes.MainButton}
      >
        Open Menu
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        className={classes.customWidth}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '350px',
          },
        }}
      >
        {pointsList.length !== '0'
          ? pointsList.map((item, i) => {
              return (
                <MenuItem
                  className={classes.basic}
                  key={i}
                  onClick={handleClose}
                >
                  <div>
                    {item.action}_ {item.full_name}
                  </div>
                  <div>
                    <DeleteIcon
                      style={{ color: '#3C99DC' }}
                      onClick={() => handleDelete(item)}
                    />
                  </div>
                </MenuItem>
              );
            })
          : ''}

        <OrderDialog
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          className={classes.button}
        />
      </Menu>
    </Paper>
  );
}
