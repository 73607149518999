import React, { useState } from 'react';
import {
  DataGrid,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';

import { formatDate } from '../../utils';
import { Button, CircularProgress, Grid } from '@mui/material';
import { Rating } from '@mui/material';
import { Empty } from './Empty';
import { Avatar } from '@mui/material';
import default_image from '../../../../assets/images/defult-pic.png';
import { makeStyles } from '@mui/styles';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

import { Link } from 'react-router-dom';

import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { useTheme } from '@mui/styles';

import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { useDriverTranslation } from '../../i18n';

// avatar style
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

function CustomPagination(loading) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  const t = useDriverTranslation();
  const theme = useTheme();
  return (
    <Grid
      container
      className=' flex justify-center relative space-x-4 py-4 w-full'
      spacing={1}
    >
      <Grid item>
        <Button
          disabled={page === 0 || loading ? true : false}
          variant='contained'
          color='primary'
          onClick={() => apiRef.current.setPage(page - 1)}
        >
          {t('previous')}
        </Button>
      </Grid>

      <Grid item>
        <Button
          disabled={loading}
          variant='contained'
          color='primary'
          onClick={() => apiRef.current.setPage(page + 1)}
        >
          {t('next')}
        </Button>
      </Grid>
      <Grid item>
        {loading ? (
          <CircularProgress
            className={
              theme.direction === 'ltr' ? 'absolute right-8' : 'absolute left-8'
            }
          />
        ) : (
          <span
            className={
              theme.direction === 'ltr'
                ? 'p-2 px-4 rounded-full bg-gray-300  font-bold absolute right-8'
                : 'p-2 px-4 rounded-full bg-gray-300  font-bold absolute left-8'
            }
          >
            {page + 1}
          </span>
        )}
      </Grid>
    </Grid>
  );
}

const DriversTable = ({ data, setPage, loading, page, setFiletrs }) => {
  const classes = useStyles();
  const t = useDriverTranslation();

  const cols = [
    {
      field: 'DriverInfo',
      headerName: t('driver_info'),
      headerClassName: ' bg-gray-100   w-full',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs pl-2'
        >
          <Grid item lg={2} md={2}>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className='flex justify-center  cursor-pointer'
            >
              {params.value.online ? (
                <StyledBadge
                  overlap='circular'
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant='dot'
                >
                  <Avatar src={params.value.profile_image || default_image} />
                </StyledBadge>
              ) : (
                <Avatar src={params.value.profile_image || default_image} />
              )}
            </Grid>
          </Grid>
          <Grid item lg={8} md={8} sx={{ color: '#1769aa' }}>
            <Link to={`/drivers/${params.value.identity_id}/identity`}>
              <Grid item lg={12} md={12} xs={12}>
                {params.value.full_name}
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                {params.value.mobile_number}
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Rating size='small' readOnly value={params.value.avg_rating} />
                <span className='text-gray-600 text-xs font-bold align-text-top pl-2'>
                  {params.value.avg_rating} ({params.value.rating_count}/
                  {params.value.completed_order_count})
                </span>
              </Grid>
            </Link>
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'CarInfo',
      headerName: t('car_info'),
      headerClassName: ' bg-gray-100   w-full',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <Grid container lg={12} md={12} xs={12} spacing={2} className='text-xs'>
          <Grid item lg={6} md={6}>
            <Grid item lg={12} md={12} xs={12}>
              <LocalTaxiIcon fontSize='small' />
              <span className='text-gray-600 leading-4 pl-2'>
                {params.value.car_desc || '.....'}
              </span>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <ColorLensIcon fontSize='small' />
              <span className='text-gray-600 leading-4 pl-2'>
                {params.value.car_color || '.....'}
              </span>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <CalendarTodayIcon fontSize='small' />
              <span className='text-gray-600 leading-4 pl-2'>
                {params.value.car_model_year || '.....'}
              </span>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6}>
            <Grid item lg={12} md={12}>
              <p className=''>.</p>
            </Grid>
            {/* just for hold the design */}
            <Grid item lg={12} md={12} xs={12}>
              <div className=' font-extrabold border-2 w-24 border-gray-800 leading-4 p-2  rounded-md'>
                {params.value.car_plate || '.....'}
              </div>
            </Grid>
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'AccountInfo',
      headerName: t('account_info'),
      headerClassName: ' bg-gray-100   w-full',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid container lg={12} md={12} xs={12} className='text-xs'>
          <Grid item lg={12} md={12} xs={12} className='pb-2'>
            <span className='text-gray-600 leading-4 pl-2'>
              {params.value.fees_group.group_name}
            </span>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <span className='text-gray-600 leading-4 pl-2'>
              {params.value.car_type.car_name_en}
            </span>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'Dates',
      headerName: t('dates'),
      headerClassName: ' bg-gray-100   w-full',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid container lg={12} md={12} xs={12} className='text-xs'>
          <Grid item lg={12} md={12} xs={12} className='pb-2'>
            <CalendarTodayIcon fontSize='small' />
            <span className='text-gray-600 leading-4 pl-2'>
              {formatDate(params.value.created_at)}
            </span>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <EventAvailableIcon fontSize='small' />
            <span className='text-gray-600 leading-4 pl-2'>
              {formatDate(params.value.updated_at)}
            </span>
          </Grid>
        </Grid>
      ),
    },
    // {
    //   field: 'Action',
    //   headerName: 'View',
    //   headerClassName: ' bg-gray-100   w-full',

    //   disableColumnMenu: true,
    //   // flex: 1,
    //   renderCell: (params) => (
    //     <Link
    //       to={`/drivers/${params.value.identity_id}/identity`}
    //       className='flex w-full justify-center'
    //     >
    //       <Button color='primary'>
    //         <VisibilityOutlinedIcon fontSize='small' />
    //       </Button>
    //     </Link>
    //   ),
    // },
  ];

  const rowsData = data.map((data) => {
    return {
      id: data.driver_id,
      DriverInfo: data,
      CarInfo: data,
      AccountInfo: data,
      Dates: data,
      Action: data,
    };
  });

  return (
    <div className='flex h-auto '>
      <div className=' flex-grow'>
        <DataGrid
          disableColumnFilter
          autoHeight
          density='standard'
          rowHeight={70}
          loading={loading}
          rows={rowsData}
          columns={cols}
          page={page}
          pageSize={1}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => setPage(newPage)}
          components={{
            Toolbar: null,
            Pagination: () => CustomPagination(loading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: LoadingIndicator,
          }}
        />
      </div>
    </div>
  );
};

export default DriversTable;
