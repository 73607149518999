import moment from "moment";

export const validateMobileForQueryParams = (mobileNumber) => {
  let mobile = mobileNumber;
  if (mobile[0] === "0") {
    mobile = mobile.substring(1);
    return `%2B249${mobile}`;
  } else if (mobile === "") {
    return "";
  }
};

export function format(dateString) {
  if (dateString) {
    // const date = new Date(dateString);
    const date = moment(dateString).format("L, h:mm:ss a");
    return date;
  }
  return null;
}
