import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../../services/API/eventTickets';
import TicketsFilter from '../../../common/TicketsFilter';
import TicketsTable from '../../../common/TicketsTable';
import { filterDate, filterMobile } from '../../../utils';

export default function EventTickets({ setIsRefreshing }) {
  const { id } = useParams();
  const [page, setPage] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [filterPayload, setFilterPayload] = useState({
    from_date: '',
    to_date: '',
    status_id: '',
    mobile: '',
  });

  const [data, setData] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const params = {
        page_number: page,
        page_size: 20,
        event_id: id,
        status_id: filterPayload.status_id,
        mobile: filterMobile(filterPayload.mobile),
        from_date: String(filterDate(filterPayload.from_date)),
        to_date: String(filterDate(filterPayload.to_date)),
      };
      const res = await api.getAllTickets(params);
      setData(res);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onApplyFilters = () => {
    if (page === 0) fetchData();
    setPage(0);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  return (
    <Box>
      <TicketsFilter
        filterPayload={filterPayload}
        setFilterPayload={setFilterPayload}
        onApplyFilters={onApplyFilters}
      />

      <TicketsTable
        data={data}
        page={page}
        setPage={setPage}
        isLoading={isLoading}
      />
    </Box>
  );
}
