import http from '../http';

const getCarTypes = async () => {
  const baseURL = process.env.REACT_APP_DISPATCH_API;
  try {
    const response = await http.get(`${baseURL}/dashboard/car_types`);
    return response.data;
  } catch (err) {
    if (err.response.status == 401) {
      localStorage.removeItem('token');
      window.location.reload();
      throw err.response.statusText;
    } else if (err.response) {
      throw err.response.statusText;
    } else {
      throw err;
    }
  }
};

export default getCarTypes;
