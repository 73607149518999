import { Route } from 'react-router-dom';
import CarsTypesPage from './pages/car-types-page';
import CarDetailsPage from './pages/car-details-page';

export default function CarTypesRoutes() {
  return [
    <Route path='/dispatch/cartypes' key={'R3'}>
      <Route path='/dispatch/cartypes/' element={<CarsTypesPage />} />
      <Route path='/dispatch/cartypes/:id' element={<CarDetailsPage />} />
    </Route>,
  ];
}
