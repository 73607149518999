import { Grid } from '@mui/material';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { formatDate } from '../../../drivers/utils';
import moment from 'moment';
import { useDispatchTranslation } from '../../i18n';

const StatusProgress = ({ dispatch }) => {
  const t = useDispatchTranslation();
  return (
    <Grid container lg={12} md={12} justifyContent='center'>
      <Grid item lg={3} md={3} className='p-2 '>
        <Grid
          item
          lg={12}
          md={12}
          className=' text-center text-gray-600 font-serif font-semibold text-sm'
        >
          {t('eligible')}
        </Grid>
        <Grid item lg={12} md={12} className='flex justify-center'>
          {dispatch.eligible ? (
            <CheckCircleIcon fontSize='small' className='text-green-600' />
          ) : (
            <CancelIcon fontSize='small' className='text-gray-300' />
          )}
        </Grid>
      </Grid>
      <Grid item lg={3} md={3} className='p-2 '>
        <Grid
          item
          lg={12}
          md={12}
          className=' text-center text-gray-600 font-serif font-semibold text-sm'
        >
          {t('dispatched')}
        </Grid>
        <Grid item lg={12} md={12} className='flex justify-center'>
          {dispatch.dispatched ? (
            <CheckCircleIcon fontSize='small' className='text-green-600' />
          ) : (
            <CancelIcon fontSize='small' className='text-gray-300' />
          )}
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          className='text-sm text-gray-600 font-serif flex justify-center text-center'
        >
          {dispatch.dispatched_at &&
            moment(dispatch.dispatched_at).format('hh:mm:ss a')}
        </Grid>
      </Grid>
      <Grid item lg={3} md={3} className='p-2 '>
        <Grid
          item
          lg={12}
          md={12}
          className=' text-center text-gray-600 font-serif font-semibold text-sm'
        >
          {t('received')}
        </Grid>
        <Grid item lg={12} md={12} className='flex justify-center'>
          {dispatch.received ? (
            <CheckCircleIcon fontSize='small' className='text-green-600' />
          ) : (
            <CancelIcon fontSize='small' className='text-gray-300' />
          )}
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          className='text-sm text-gray-600 font-serif flex justify-center text-center'
        >
          {dispatch.received_at &&
            moment(dispatch.received_at).format('hh:mm:ss a')}
        </Grid>
      </Grid>
      <Grid item lg={3} md={3} className='p-2 '>
        <Grid
          item
          lg={12}
          md={12}
          className=' text-center text-gray-600 font-serif font-semibold text-sm'
        >
          {t('viewed')}
        </Grid>
        <Grid item lg={12} md={12} className='flex justify-center'>
          {dispatch.viewed ? (
            <CheckCircleIcon fontSize='small' className='text-green-600' />
          ) : (
            <CancelIcon fontSize='small' className='text-gray-300' />
          )}
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          className='text-sm text-gray-600 font-serif flex justify-center text-center'
        >
          {dispatch.viewed_at &&
            moment(dispatch.viewed_at).format('hh:mm:ss a')}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StatusProgress;
