import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import PageHeading from '../../../../components/common/PageHeading';
import {
  clearErrors,
  fetchAllEventsProviders,
} from '../../../core/features/events-providers/eventsProvidersSlice';
import { useEventsTranslation } from '../../../services/i18n';
import ProvidersTable from './ProvidersTable';

export default function ChooseProvider() {
  const { isLoading, events, error } = useSelector((state) => {
    return state.eventsProvidersSlice;
  });

  const [isRefreshing, setIsRefreshing] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [seaechParams, setSearchParams] = useSearchParams(
    createSearchParams({
      page_number: 0,
      page_size: 10,
      is_deleted: false,
    })
  );

  const defaultSearchParams = useMemo(
    () => ({
      page_number: seaechParams.get('page_number'),
      page_size: seaechParams.get('page_size'),
      is_deleted: seaechParams.get('is_deleted'),
    }),
    [seaechParams]
  );

  useEffect(() => {
    dispatch(fetchAllEventsProviders(defaultSearchParams));
  }, [dispatch, navigate, isRefreshing]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
      dispatch(clearErrors());
    }
  }, [error, dispatch, navigate]);

  const t = useEventsTranslation();

  const rowsData = events.map((provider) => {
    return {
      id: provider.provider_id,
      name: provider,
      email: provider,
      mobile: provider,
      btn: provider,
    };
  });

  return (
    <div className='w-full'>
      <PageHeading
        backBtn
        heading1={t('events')}
        heading2={t('add_event')}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />
      <p className='text-3xl font-bold text-center mt-2 mb-5'>
        {t('choose_provider_to_continue')}
      </p>
      <ProvidersTable
        isLoading={isLoading}
        handlePagination={(newPage) => {
          setSearchParams({
            ...defaultSearchParams,
            page_number: newPage,
          });
          setIsRefreshing((prev) => prev + 1);
        }}
        page={Number(defaultSearchParams.page_number)}
        rowsData={rowsData}
      />
    </div>
  );
}
