import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../../services/API/eventTickets';

export default function TikcetDetailsPage() {
  const [isRefreshing, setIsRefreshing] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState();

  const { id } = useParams();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await api.getTicketTransactions(id);
      setData(res);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isRefreshing]);

  return <div>TikcetDetailsPage</div>;
}
