import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useState } from 'react';

import { formatDate } from '../../../drivers/utils';
import { useOrdersTranslation } from '../../i18n';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function TransactionsTable({ transactions }) {
  const classes = useStyles();

  const rows = transactions;

  const t = useOrdersTranslation();

  const handleCreatedById = useCallback((id) => {
    switch (id) {
      case 0:
        return t('Driver');

      case 1:
        return t('Client');

      case 2:
        return t('Supervisor');

      case 3:
        return t('Admin');

      case 4:
        return t('System');

      default:
        return '.....';
    }
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead className='bg-gray-100'>
          <TableRow>
            <TableCell align='center'>
              <b className=' text-base'>{t('status')}</b>
            </TableCell>
            <TableCell align='center'>
              <b className=' text-base'>{t('Creation_at')}</b>
            </TableCell>
            <TableCell align='center'>
              <b className=' text-base'>{t('Created_By')}</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return (
              <TableRow key={row.id}>
                <TableCell align='center'>{row.status.status_name}</TableCell>
                <TableCell align='center'>
                  {formatDate(row.created_at)}
                </TableCell>
                <TableCell align='center'>
                  {handleCreatedById(row.created_by_role_id)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
