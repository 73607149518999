import React, { useState, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import {
  deleteComment,
  getComments,
} from '../../../../Redux/store/places/placesListSlice';

import CommentDialog from '../common/CommentDialog';
import { useEffect } from 'react';
import ReviewsContainer from '../common/ReviewsContainer';
import { usePlacesTranslation } from '../../i18n';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';

function Reviews() {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(0);

  const [page, setPage] = useState(0);

  const { id } = useParams();

  const dispatch = useDispatch();

  const fetchComments = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(getComments({ id: id, page_number: page }));
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
      setLoadingCount(1);
    }
  });

  useEffect(() => {
    fetchComments();
  }, [isRefreshing, page]);

  const t = usePlacesTranslation();

  if (isLoading && loadingCount === 0) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-[70vh] place-content-center'>
          <div class='flex items-center gap-2 text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            {t('loading')}...
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <Grid Container lg={12} md={12}>
      <ReviewsContainer
        page={page}
        setPage={setPage}
        isLoading={isLoading}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />
    </Grid>
  );
}

export default Reviews;
