const places_ar = {
  places: 'أماكن',
  places_list: 'قائمة الأماكن',
  select_amenity: 'اختيار النوع',
  selected_amenity: 'النوع المختار',
  name_en: 'الاسم بالانجليزي',
  name_ar: 'الاسم بالعربي',
  mobile: 'الموبايل',
  website: 'الموقع الالكتروني',
  address: 'العنوان',
  set_Location_on_a_map: 'تحديد الموقع على الخريطة',
  latitude: 'خط العرض',
  longitude: 'خط الطول',
  place_images: 'صور المكان',
  place_logo: 'شعار المكان',
  create: 'انشاء',
  amenity: 'النوع',
  open: 'افتح',
  upload: 'رفع',
  logo: 'الشعار',
  add_place: 'إضافة مكان',
  place_name: 'اسم المكان',
  status: 'الحالة',
  all: 'الكل',
  deleted: 'محذوف',
  not_deleted: 'غير محذوف',
  apply: 'تطبيق',
  place_info: 'بيانات المكان',
  user: 'المستخدم',
  visibility: 'مرئي',
  dates: 'التواريخ',
  actions: 'العمليات',
  hidden: 'مخفي',
  visible: 'مرئي',
  previous: 'السابق',
  next: 'التالي',
  warning: 'تحذير',
  dialog_show_msg: 'هل انت متاكد انك تريد اظهار المكان؟',
  dialog_hide_msg: 'هل انت متاكد انك تريد اخفاء المكان؟',
  cancel: 'إلغاء',
  confirm: 'تأكيد',
  place_details: 'تفاصيل المكان',
  phone: 'هاتف المكان',
  user_name: 'اسم المستخدم',
  email: 'البريد الالكتروني',
  created_at: 'تاريخ الانشاء',
  updated_at: 'تاريخ التحديث',
  ratings: 'التقييمات',
  total: 'المجموع',
  dialog_delete_msg: 'هل انت متاكد انك تريد حذف المكان',
  delete: 'حذف',
  add_place_to_group: 'اضافة المكان الى مجموعة',
  edit: 'تعديل',
  edit_form: 'تعديل المكان',
  set_location_on_a_map: 'تعديل الموقع على الخريطة',
  submit: 'تأكيد',
  delete_place: 'حذف المكان',
  add_to_group: 'اضافة الى مجموعة',
  add_new_image: 'اضافة صورة جديدة',
  no_images: 'لا توجد صور',
  loading: 'جار التحميل',
  details: 'التفاصيل',
  images: 'الصور',
  reviews: 'التقييمات',
  comment: 'التعليقات',
  likes: 'الاعجابات',
  date: 'التاريخ',
  view: 'عرض',
  delete_message: 'هل انت متاكد انك تريد الحذف',
  replies: 'الردود',
  user_phone: 'هاتف المستخدم',
  place: 'المكان',
  reports: 'التبليغات',
  note: 'ملاحظة',
  message: 'رسالة',
  approve_report_msg: 'هل انت متاكد انك تريد الموافقة على هذا البلاغ؟',
  reject_report_msg: 'هل انت متاكد انك تريد رفض هذا البلاغ؟',
  group_type: 'نوع المجموعة',
  manually_selected: 'الاختيار يدويا',
  automatically_selected: 'الاختيار تلقائيا',
  groups_list: 'قائمة القروبات',
  user_mobile: 'هاتف المستخدم',
  place_order: 'ترتيب المكان',
  group_details: 'تفاصيل المكان',
  group_id: 'رقم المجموعة',
  key: 'الرمز',
  type: 'النوع',
  personal_info: 'البيانات الشخصية',
  edit_group: 'تعديل المجموعة',
  update: 'تحديث',
  delete_group_msg: 'هل انت متاكد انك تريد حذف المجموعة؟',
  place_page: 'صفحة المكان',
  delete_img_msg: 'هل انت متاكد انك تريد حذف الصورة؟',
  place_added_successfuly: 'تم انشاء المجموعة بنجاح',
  add_group: 'اضافة مجموعة',
  places_added_to_group_successfuly: 'تمت اضافة المكان الى المجموعة بنجاح',
  place_created_successfully: 'تم انشاء المكان بنجاح',
  pending: 'قيد الانتظار',
  approved: 'مقبول',
  rejected: 'مرفوض',
  approve: 'تأكيد',
  no_images: 'لا صور',
  delete_place_msg: 'هل انت متاكد انك تريد حذف المكان',
  place_was_successfully_deleted: 'تم حذف المكان بنجاح',
  done_successfully: 'تم بنجاح',
  link_event_to_place: 'ربط فعالية بالمكان',
  event_id: 'رقم الفعالية',
  event_provider: 'مزود الفعالية',
};

export default places_ar;
