import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PageHeading from '../../../../components/common/PageHeading';
import { useEventsTranslation } from '../../../services/i18n';
import {
  createSearchParams,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Grid } from '@mui/material';
import EvnetDetails from '../components/eventDetailsTabs/EventDetails';
import EventTicketsTypes from '../components/eventDetailsTabs/EventTicketsTypes';
import { toast } from 'react-toastify';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import eventsDashboardAPI from '../../../services/API/events-dashboard';
import EventTickets from '../components/eventDetailsTabs/EventTickets';

export default function EvnetDetailsPgae() {
  const [value, setValue] = useSearchParams(
    createSearchParams({
      tabs: '1',
    })
  );

  const { id } = useParams();

  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await eventsDashboardAPI.getEventDetails(id);
      setData(res);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const [isRefreshing, setIsRefreshing] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue({
      tabs: newValue,
    });
  };

  React.useEffect(() => {
    fetchData();
  }, [isRefreshing]);

  const t = useEventsTranslation();

  if (isLoading) return <LoadingIndicator />;

  return (
    <div className='mt-2'>
      <Grid item lg={12} md={12} sm={12}>
        <PageHeading
          heading1={t('events')}
          heading2={t('event_details')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>
      <Box
        sx={{
          width: '100%',
          typography: 'body1',
          marginTop: 3,
        }}
      >
        <TabContext value={value.get('tabs')}>
          <Box
            className='bg-gray-100'
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <TabList
              onChange={handleChange}
              aria-label='lab API tabs example'
              centered
            >
              <Tab label={t('event_details')} value='1' />
              <Tab label={t('event_tickets_types')} value='2' />
              <Tab label={t('event_tickets')} value='3' />
            </TabList>
          </Box>
          <TabPanel value='1'>
            <EvnetDetails data={data} setIsRefreshing={setIsRefreshing} />
          </TabPanel>
          <TabPanel value='2'>
            <EventTicketsTypes
              isRefreshing={isRefreshing}
              providerID={data.provider_id}
              setIsRefreshing={setIsRefreshing}
            />
          </TabPanel>
          <TabPanel value='3'>
            <EventTickets
              isRefreshing={isRefreshing}
              setIsRefreshing={setIsRefreshing}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
