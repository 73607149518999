import { createSlice } from '@reduxjs/toolkit';
import { carsApi } from './api';

const initialState = {
  carTypes: [],
  carDetails: {},
  isLoading: false,
};

const carsListSlice = createSlice({
  name: 'cars',
  initialState,
  reducers: {
    setLoadingState: (state, action) => {
      state.isLoading = action.payload;
    },
    fetchCarTypesSuccess: (state, action) => {
      state.carTypes = action.payload;
    },
    fetchCarDetailsSuccess: (state, action) => {
      state.carDetails = action.payload;
    },
    setError: (state, actions) => {
      state.error = actions.payload.message;
    },
  },
});

export const {
  fetchCarTypesSuccess,
  fetchCarDetailsSuccess,
  setError,
  setLoadingState,
} = carsListSlice.actions;

export const fetchCarTypes = (payload) => async (dispatch) => {
  try {
    // dispatch(setLoadingState(true));
    const carTypes = await carsApi.fetchCarTypesData(payload);
    dispatch(fetchCarTypesSuccess(carTypes));
    // return carTypes;
  } catch (err) {
    throw err;
  }
};

export const updateCarDetails = (options) => async (dispatch) => {
  try {
    dispatch(setLoadingState(true));
    const carInfo = await carsApi.fetchCarDetails(options);
    dispatch(fetchCarDetailsSuccess(carInfo));
    return carInfo;
  } catch (err) {
    throw err;
  }
};

export default carsListSlice.reducer;
