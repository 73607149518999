import {
  Avatar,
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { PhoneAndroid } from '@mui/icons-material';
import React from 'react';
import { useOrdersTranslation } from '../../i18n';

const SearchTypeDialog = (props) => {
  const t = useOrdersTranslation();
  // const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const listData = [t('driver_mobile'), t('user_mobile')];

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <div className='px-8'>
        <DialogTitle id='simple-dialog-title'>{t('search_with')}</DialogTitle>
        <List>
          {listData.map((item) => (
            <ListItem
              button
              onClick={() => handleListItemClick(item)}
              key={item}
            >
              <IconButton className='p-5'>
                <PhoneAndroid color='primary' />
              </IconButton>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </div>
    </Dialog>
  );
};

export default SearchTypeDialog;
