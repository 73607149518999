import React from 'react';

import { useDispatch } from 'react-redux';
import {
  approveReport,
  rejectReport,
} from '../../../../Redux/store/places/placesListSlice';
import { useCallback } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { usePlacesTranslation } from '../../i18n';
import { Close, Done } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  cancel: {
    backgroundColor: '#575757',
    color: 'white',
    border: '2px',
    paddingLeft: '8px',
    paddingRight: '8px',
    '&:hover': {
      backgroundColor: '#383838',
      color: 'white',
      boxShadow: 'none',
    },
  },

  text: {
    fontSize: '18px',
    color: '#151515',
  },
  confirm: {
    // '&:active': {},
    backgroundColor: '#329B24',
    color: 'white',
    border: '2px',
    paddingLeft: '8px',
    paddingRight: '8px',
    '&:hover': {
      backgroundColor: '#008000',
      color: 'white',
      boxShadow: 'none',
    },
  },
  dialog: {},
}));

export default function ApproveDialog({
  id,
  setIsLoading,
  isRefreshing,
  setIsRefreshing,
  type,
}) {
  const [open, setOpen] = React.useState(false);
  const [note, setNote] = React.useState('');

  const [options, setOptions] = React.useState({
    id: id,
    support_note: '',
  });

  const classes = useStyles();

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setNote(e.target.value);
    setOptions({
      ...options,
      support_note: e.target.value,
    });
  };
  const handleApprove = useCallback(async () => {
    try {
      if (type === 'approve') {
        await dispatch(approveReport({ ...options }));
      } else {
        await dispatch(rejectReport({ ...options }));
      }
      setIsLoading(true);
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsRefreshing(isRefreshing + 1);
      setOpen(false);
    }
  });

  const t = usePlacesTranslation();

  return (
    <div>
      <Button onClick={handleClickOpen}>
        {type === 'approve' ? (
          <Done style={{ color: '#008000' }} />
        ) : (
          <Close style={{ color: '#BB0000' }} />
        )}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle
          id='alert-dialog-title'
          color={type === 'approve' ? 'green' : 'error'}
          style={{
            fontWeight: 'bold',
          }}
        >
          {t('approve')}
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            className={classes.text}
          >
            <Grid item className='pb-2' lg={12} md={12}>
              {/* {("Are you sure you want to approve this report !!")} */}

              {type === 'approve'
                ? t('approve_report_msg')
                : t('reject_report_msg')}
            </Grid>
            {/* <NotesForm /> */}
            <Grid item lg={12} md={12}>
              <TextField
                fullWidth
                label={t('note')}
                id='note'
                name='note'
                variant='outlined'
                multiline
                rows={2}
                onChange={handleChange}
                value={note}
              />
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.cancel}>
            {t('cancel')}
          </Button>
          <Button
            onClick={handleApprove}
            variant='contained'
            className={classes.confirm}
            color={type === 'approve' ? 'success' : 'error'}
            autoFocus
          >
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
