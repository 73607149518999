import { configureStore } from '@reduxjs/toolkit';
import acceptanceRateReducer from '../features/acceptance-rate/acceptanceRateSlice';
import carTypesListSlce from '../features/cartypes-list/cartypesListSlce';
import carTypeDetailsSlice from '../features/cartype-detail/cartypeDetailSlce';
import supplyDemandSlice from '../features/supply-demand/supplyDemandSlice';
import driverTicketDetailsSlice from '../features/drivers-ticket-details/driverTicketDetailsSlice';
import driversTicketslListSlice from '../features/drivers-tickets-list/driversTicketslListSlice';

const middlewares = [];

if (process.env.NODE_ENV === 'development') {
  const { logger } = require(`redux-logger`);
  middlewares.push(logger);
}

const dispatchStore = configureStore({
  reducer: {
    acceptanceRates: acceptanceRateReducer,
    carTypesList: carTypesListSlce,
    carTypeDetails: carTypeDetailsSlice,
    supplyDemand: supplyDemandSlice,
    driverTicketsDetails: driverTicketDetailsSlice,
    driversTicketsList: driversTicketslListSlice,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
});

export default dispatchStore;
