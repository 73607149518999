import React, { useCallback, useEffect, useMemo } from 'react';
import {
  fetchOrders,
  setError,
  setListPage,
  setListPageSuccess,
  setListPayload,
} from '../../../Redux/store/orders/ordersListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import OrdersList from '../components/OrdersList';
import ListFiler from '../components/common/ListFilters';
import PageHeading from '../../../components/common/PageHeading';
import { useOrdersTranslation } from '../i18n';
import { toast } from 'react-toastify';
import { createSearchParams, useSearchParams } from 'react-router-dom';

const OrdersListPage = () => {
  const { orders, listPayload, listPage } = useSelector(
    (state) => state.orders
  ).ordersList;

  const t = useOrdersTranslation();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefreshing, setIsRefreshing] = React.useState(0);

  const [searchParams, setSearchParams] = useSearchParams(
    createSearchParams({
      page_number: listPage,
    })
  );

  const currentSearchParams = useMemo(
    () => ({
      page_number: searchParams.get('page_number'),
      ...listPayload,
    }),
    [searchParams, listPayload]
  );

  const setPage = (newPage) => {
    setSearchParams({
      page_number: newPage,
    });
  };

  const fetchOrdersData = useCallback(async () => {
    try {
      setIsLoading(true);
      setSearchParams(currentSearchParams);
      await dispatch(
        fetchOrders({
          page_size: 10,
          ...currentSearchParams,
        })
      );
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    if (isRefreshing === 0) {
      dispatch(setListPayload({}));
      dispatch(setListPage(0));
    }
    fetchOrdersData();
  }, [isRefreshing, searchParams]);

  return (
    <Grid item lg={12} md={12} spacing={2} justifyContent='center'>
      <Grid item lg={12} md={12}>
        <PageHeading
          heading1={t('orders')}
          heading2={t('orders_list')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>
      <Grid item lg={12} md={12}>
        <ListFiler
          setIsRefreshing={setIsRefreshing}
          listPayload={listPayload}
          isRefreshing={isRefreshing}
        />
        <OrdersList
          data={orders}
          isLoading={isLoading}
          page={currentSearchParams.page_number}
          setPage={setPage}
          setIsRefreshing={setIsRefreshing}
          isRefreshing={isRefreshing}
        />
      </Grid>
    </Grid>
  );
};

export default OrdersListPage;
