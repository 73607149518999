import {
  FormControlLabel,
  LinearProgress,
  Switch,
  TextField,
} from '@mui/material';
import { Button, Grid, InputAdornment, MenuItem } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import http from '../../services/API/http';
import { useDispatch, useSelector } from 'react-redux';
import Map from './tracking_map/Map';
import { toast } from 'react-toastify';
import { fetchCarTypes } from '../../../Redux/store/cars/carsListSlice';
import { useTranslation } from 'react-i18next';
import { appDirection } from '../../../shared_utils';

export default function Details({
  payload,
  setPayload,
  isRefreshing,
  setisRefreshing,
}) {
  const [checked, setChecked] = useState(false);
  const [isLocated, setIsLocated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [driversLocation, setDriversLocation] = useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fetchCars = async () => {
    await dispatch(fetchCarTypes());
  };

  const fetchData = async () => {
    const baseURL = process.env.REACT_APP_DISPATCH_API;
    try {
      setIsLoading(true);
      const res = await http.post(
        `${baseURL}/dashboard/nearby_online_drivers`,
        payload
      );
      setDriversLocation(res.data);
      setIsLocated(false);
    } catch (err) {
      toast.error(err.message);
      if (err.response.status == 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchCars();
  }, [isRefreshing]);

  useEffect(() => {
    if (isLocated) {
      fetchData();
    } else if (checked) {
      fetchData();
      const interval = setInterval(async () => {
        fetchData();
      }, 15000);

      return () => clearInterval(interval);
    }
  }, [isLocated, checked]);

  const { carTypes } = useSelector((state) => {
    return state.cars.carsList;
  });

  return (
    <Grid container lg={12} md={12} className='p-1' dir={appDirection}>
      <Grid
        container
        lg={12}
        md={12}
        justifyContent
        className=' flex justify-start p-1 '
      >
        <Grid container lg={10} md={10} className='' spacing={2}>
          <Grid item lg={2} md={2} sm={2} xs={2} sx={{ mt: -1 }}>
            <TextField
              InputLabelProps={{ shrink: true }}
              className='bg-white'
              fullWidth
              onChange={(e) => {
                setPayload({ ...payload, car_type_id: e.target.value });
                setIsLocated(true);
              }}
              label={t('CarTypes')}
              margin='dense'
              variant='outlined'
              size='small'
              value={payload.car_type_id}
              select
            >
              <MenuItem value={null}>{t('All')}</MenuItem>
              {carTypes.length !== 0 &&
                carTypes.map((cartype) => (
                  <MenuItem value={cartype.id} key={cartype.id}>
                    {cartype.car_name_en}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2} sx={{ mt: -1 }}>
            <TextField
              size='small'
              className='bg-white'
              fullWidth
              label={t('Radius')}
              margin='dense'
              variant='outlined'
              value={payload.radius}
              type='number'
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: { min: 2, max: 20 },
              }}
              onChange={(e) => {
                setPayload({ ...payload, radius: e.target.value });
                setIsLocated(true);
              }}
            />
          </Grid>
          <Grid item lg={2} md={2}></Grid>
        </Grid>

        <Grid
          item
          lg={2}
          md={2}
          sm={2}
          xs={2}
          className='flex justify-center h-full '
        >
          <FormControlLabel
            control={
              <Switch checked={checked} onChange={() => setChecked(!checked)} />
            }
            label={t('Auto Search')}
          />
          <Button
            disabled={checked}
            variant='contained'
            className='h-full p-8'
            color='primary'
            onClick={fetchData}
          >
            {t('Search')}
          </Button>
        </Grid>
      </Grid>
      {isLoading && (
        <Grid item lg={12} md={12}>
          <LinearProgress />
        </Grid>
      )}
      <Map
        payload={payload}
        setPayload={setPayload}
        driversLocation={driversLocation}
        setDriversLocation={setDriversLocation}
        setIsLocated={setIsLocated}
      />
    </Grid>
  );
}
