import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Delete } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { removeReviewsImage, setRefreshingPlace } from '../../../../Redux/store/places/placesListSlice';

import { setAlert } from '../../../../Redux/store/app/appSlice';
import { useCallback } from 'react';

export default function PhotosDialog({ id, image_id, isRefreshing, setIsRefreshing }) {
  const [open, setOpen] = React.useState(false);
  const [payload, setPayload] = React.useState({
    imagesIds: [image_id],
  });

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = useCallback(async () => {
    try {
      // setIsLoading(true);
      // console.log(id, image_id);
      await dispatch(removeReviewsImage({ id: id, imagesIds: [image_id] }));
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      handleClose();
      setIsRefreshing(isRefreshing + 1);
    }
  });

  return (
    <div>
      <Button onClick={handleClickOpen} color='secondary' variant='contained'>
        <Delete fontSize='small' />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this image!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <Button onClick={handleDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
