import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { validateMobile } from '../uitls';

const MobileForm = ({ setMobile, checkNumber }) => {
  const handleChange = useCallback((e) => {
    const mobile = validateMobile(e.target.value);
    setMobile(mobile);
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    checkNumber();
  };

  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <div className='mb-6'>
        <input
          autoFocus
          type='tel'
          className='form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
          id='exampleFormControlInput2'
          placeholder='09XX XXX XXX'
          onChange={handleChange}
          maxLength={10}
        />
      </div>
      <div className='flex justify-center'>
        <button
          type='submit'
          className='inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out'
        >
          {t('Sign In')}
        </button>
      </div>
    </form>
  );
};

export default MobileForm;
