import { Button, CircularProgress, Grid } from '@mui/material';
import {
  DataGrid,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

function CustomPagination(loading) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  const { t } = useTranslation();

  return (
    <Grid className=' flex justify-center relative py-4 gap-2 w-full'>
      <Button
        disabled={page === 0 || loading ? true : false}
        variant='contained'
        color='primary'
        className='mx-2'
        onClick={() => apiRef.current.setPage(page - 1)}
      >
        {t('previous')}
      </Button>
      {/* <span className='p-2 rounded-full bg-gray-300 '>Page: {page + 1}</span> */}

      <Button
        disabled={loading}
        variant='contained'
        className='mx-2'
        color='primary'
        onClick={() => apiRef.current.setPage(page + 1)}
      >
        {t('next')}
      </Button>
      {loading ? (
        <CircularProgress className='absolute right-8' />
      ) : (
        <span className='p-2 px-4 rounded-full bg-gray-300 absolute right-8  font-bold'>
          {page + 1}
        </span>
      )}
    </Grid>
  );
}
export default CustomPagination;
