import { Grid, Paper } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDriverTranslation } from '../../i18n';

const TotalStatisticsCard = () => {
  const [total, setTotal] = useState(0);
  const { ordersStatis } = useSelector((state) => state.drivers).driversList;
  const handleTotalCounter = () => {
    let sum = 0;
    ordersStatis.map((data) => {
      sum = sum + data.total;
      setTotal(sum);
    });
  };

  useEffect(() => {
    handleTotalCounter();
  }, []);
  const t = useDriverTranslation();

  return (
    <Grid item lg={3} md={3} sm={6} xs={9}>
      <Paper elevation={6} className=' p-2  '>
        <span className=''>
          <h1 className='w-full pt-3 text-center uppercase text-sm font-serif font-semibold text-gray-500'>
            {t('total')}
          </h1>
        </span>
        <p className='w-full p-5 text-center font-medium text-2xl'>{total}</p>
      </Paper>
    </Grid>
  );
};

export default TotalStatisticsCard;
