import http from '../../../services/APIs/dispatch_api';

export const Api = {
  getDemandSupplyData: async ({ from, to }) => {
    try {
      const response = await http.get(
        `/demand_supply/demand_supply_results?from=${from}&to=${to}`
        // `/demand_supply/demand_supply_results?from=2022-08-11 00:05:00&to=2022-10-29 18:10:00`
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
};
