import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { AlertTitle } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../Redux/store/app/appSlice';

export default function Alerts() {
  const { alert } = useSelector((state) => state.app).app;
  const dispatch = useDispatch();
  const sleep = (time) => {
    return new Promise((resolve) => {
      setTimeout(resolve, time * 1000);
    });
  };

  const handleClose = useCallback(() => {
    dispatch(
      setAlert({
        message: '',
        isOpen: false,
      })
    );
  });
  useEffect(() => {
    if (alert.type) {
      const autoTimeOut = setTimeout(() => {
        handleClose();
      }, 5000);
      return () => clearTimeout(autoTimeOut);
    }
  }, [alert]);

  return (
    <div className='w-1/4 z-50 '>
      <Collapse in={alert.isOpen}>
        <Alert
          severity={alert.type || 'info'}
          variant='filled'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                // setIsOpen(false);
                handleClose();
              }}>
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }>
          <AlertTitle>{alert.type}</AlertTitle>
          {alert.message}
        </Alert>
      </Collapse>
    </div>
  );
}
