import { Route } from 'react-router-dom';
import ReviewsPage from './pages/ReviewsPage';
import AddGroupPage from './pages/add-group-page';

import AddPlacePage from './pages/add-place-page';
import GroupDetailsPage from './pages/group-details-page';
import GroupsListPage from './pages/groups-list-page';
import PlaceDetailsPage from './pages/place-details-page';
import PlacesListPage from './pages/places-list-page';
import PlacesMapPage from './pages/places-map-page';
import ReportsPage from './pages/ReportsPage';
import RepliesPage from './pages/RepliesPage';

export const PlacesRoutes = () => {
  return (
    <Route path='/places' key={'places'}>
      <Route path='/places/' element={<PlacesListPage />} key={'places_list'} />
      <Route
        path='/places/tracking-map/'
        element={<PlacesMapPage />}
        key={'places_map'}
      />

      <Route
        path='/places/add-place/'
        element={<AddPlacePage />}
        key={'add-place'}
      />
      <Route
        path='/places/:id'
        element={<PlaceDetailsPage />}
        key={'place_details'}
      />
      <Route
        path='/places/replies/:id'
        element={<RepliesPage />}
        key={'place_details'}
      />
      <Route
        path='/places/groups/'
        element={<GroupsListPage />}
        key={'groups_list'}
      />
      <Route
        path='/places/groups/:id/'
        element={<GroupDetailsPage />}
        key={'group_details'}
      />
      <Route
        path='/places/add/groups/'
        element={<AddGroupPage />}
        key={'create-group'}
      />
      <Route
        path='/places/reviews/'
        element={<ReviewsPage />}
        key={'places-reviews'}
      />
      <Route
        path='/places/reports/'
        element={<ReportsPage />}
        key={'places-reports'}
      />
    </Route>
  );
};
