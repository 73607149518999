import React, { useState, useEffect, useMemo } from 'react';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPlacesReviews } from '../../../Redux/store/places/placesListSlice';
import CommentsContainer from '../components/common/CommentsContainer';
import PlaceFilter from '../components/common/PlaceFilter';
import { setAlert } from '../../../Redux/store/app/appSlice';
import PageHeading from '../../../components/common/PageHeading';
import { Grid } from '@mui/material';
import { usePlacesTranslation } from '../i18n';
import { makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import { createSearchParams, useSearchParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

function ReviewsPage(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(0);
  const [payload, setPayload] = useState({
    mobile: '',
  });

  const { reviews } = useSelector((state) => state.places).places;

  const [searchParams, setSearchParams] = useSearchParams(
    createSearchParams({
      page_number: 0,
      mobile: '',
    })
  );

  const currentSearchParams = useMemo(
    () => ({
      mobile: searchParams.get('mobile'),
      page_number: searchParams.get('page_number'),
    }),
    [searchParams]
  );

  const setPage = (newPage) => {
    setSearchParams({
      ...currentSearchParams,
      page_number: newPage,
    });
    setIsRefreshing((prev) => prev + 1);
  };

  const onApply = () => {
    setSearchParams({
      ...currentSearchParams,
      ...payload,
      page_number: 0,
    });
    setIsRefreshing((prev) => prev + 1);
  };

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      setSearchParams(currentSearchParams);
      await dispatch(
        getPlacesReviews({
          page: currentSearchParams.page_number,
          pageSize: 10,
          id: '',
          isDeleted: '',
          mobile: currentSearchParams.mobile,
        })
      );
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    fetchData();
  }, [isRefreshing]);

  const t = usePlacesTranslation();

  return (
    <Grid container lg={12} md={12}>
      <PageHeading
        heading2={t('reviews')}
        heading1={t('places')}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />
      <PlaceFilter
        onApply={onApply}
        payload={payload}
        setPayload={setPayload}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />

      <CommentsContainer
        data={reviews}
        setPage={setPage}
        page={Number(currentSearchParams.page_number)}
        isLoading={isLoading}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />
    </Grid>
  );
}

export default ReviewsPage;
