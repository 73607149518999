import { Route } from 'react-router';
import HeatMapPage from './pages/heatmap-page';

export function SupplyDemandRoutes() {
  return (
    <Route path='/dispatch/supply_demand' key={'supply_demand'}>
      <Route path='/dispatch/supply_demand/' element={<HeatMapPage />} key={'heatmap_page'} />
    </Route>
  );
}
