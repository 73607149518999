import { createSlice } from '@reduxjs/toolkit';
import { ordersApi } from './api';

const initialState = {
  orders: [],
  order: {
    order_id: '',
    user_id: '',
    driver_id: null,
    user_note: null,
    driver_note: null,
    promo_code: '',
    driver_name: null,
    driver_mobile: null,
    driver_avatar: null,
    pick_up_longitude: null,
    pick_up_latitude: null,
    drop_off_longitude: null,
    drop_off_latitude: null,
    distance_km: 0,
    estimated_duration: 0,
    price: 0,
    paid_cash: 0,
    paid_balance: 0,
    paid_promo: 0,
    peak_rate: 0,
    status_id: 0,
    car_type_id: 0,
    dispatch_id: '',
    from_address: '',
    to_address: '',
    route_ref: '',
    cancel_reason_txt: null,
    created_by: '',
    created_by_role_id: 0,
    agent_id: null,
    call_id: null,
    order_type: 0,
    open_trip_duration: 0,
    open_trip_distance: 0,
    open_trip_price: 0,
    rated: null,
    rated_at: null,
    matching_gender: null,
    matching_duration_min: null,
    order_schema_version: 0,
    trip_id: null,
    pickup_trip_station_id: null,
    dropoff_trip_station_id: null,
    referral_order_id: null,
    referral_reward_enabled: null,
    route_image_s3_id: null,
    order_mobile_number: null,
    cancel_reason_id: null,
    driver_cancel_reason_id: null,
    app_os: null,
    app_version_code: null,
    device_id: null,
    created_at: '',
    updated_at: '',
    status: {
      status_id: 0,
      status_name: '',
    },
    transactions: [],
    user: {
      user_id: '',
      full_name: '',
      mobile: '',
      email: '',
      facebook_user_id: null,
      facebook_image_url: null,
      facebook_cover_url: null,
      twitter_user_id: null,
      photo_name: null,
      verified: false,
      role_id: 0,
      email_activated: false,
      mobile_activated: false,
      s3_id: '',
      created_at: '',
      updated_at: '',
      url: '',
      thumbnail_url: '',
    },
    rating: null,
    cancel_reason: null,
    driver_cancel_reason: null,
    car_type: {
      id: 0,
      car_name: 'ليمون Go',
      description: '',
      seats: '',
      image_url: '',
      geohashes: [],
    },
  },
  listPayload: {},
  listPage: 0,
  createdOrder: {
    price: 0,
    distance_km: 0,
  },
  options: [],
  ordersStatis: [],
  user: {},
};

const ordersListSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    fetchOrdersSuccess: (state, action) => {
      state.orders = action.payload;
    },
    fetchOrderSuccess: (state, action) => {
      state.order = action.payload;
    },
    checkOrderInfoSuccess: (state, action) => {
      state.createdOrder = action.payload;
    },
    setPlacesOptionsSuccess: (state, action) => {
      state.options = action.payload;
    },
    fetchOrdersStatisSuccess: (state, action) => {
      state.ordersStatis = action.payload;
    },
    fetchUserByMobileSuccess: (state, action) => {
      state.user = action.payload;
    },
    setError: (state, actions) => {
      state.error = actions.payload;
    },
    setListPayloadSuccess: (state, actions) => {
      state.listPayload = actions.payload;
    },
    setListPageSuccess: (state, actions) => {
      state.listPage = actions.payload;
    },
  },
});

export const {
  fetchOrdersSuccess,
  fetchOrderSuccess,
  setError,
  setListPayloadSuccess,
  setListPageSuccess,
  fetchOrdersStatisSuccess,
  checkOrderInfoSuccess,
  setPlacesOptionsSuccess,
  fetchUserByMobileSuccess,
} = ordersListSlice.actions;

export const fetchOrders = (options) => async (dispatch) => {
  try {
    const orders = await ordersApi.fetchOrders(options);
    dispatch(fetchOrdersSuccess(orders));
  } catch (error) {
    dispatch(fetchOrdersSuccess([]));
    throw error;
  }
};

export const fetchOrderById = (options) => async (dispatch) => {
  try {
    const order = await ordersApi.fetchOrderById(options);
    dispatch(fetchOrderSuccess(order));
  } catch (error) {
    dispatch(fetchOrderSuccess({}));
    throw error;
  }
};

export const setListPayload = (options) => async (dispatch) => {
  dispatch(setListPayloadSuccess(options));
};
export const setListPage = (options) => async (dispatch) => {
  dispatch(setListPageSuccess(options));
};

export const reOrder = (payload) => async (dispatch) => {
  try {
    const order = await ordersApi.reOrder(payload);
    return order;
  } catch (error) {
    throw error;
  }
};

export const cancelOrder = (id) => async (dispatch) => {
  try {
    const order = await ordersApi.cancelOrder(id);
    return order;
  } catch (error) {
    throw error;
  }
};

export const checkOrderInfo = (payload) => async (dispatch) => {
  // console.log(payload);
  try {
    const orders = await ordersApi.checkOrder(payload);
    dispatch(
      checkOrderInfoSuccess({
        price: orders.price.toFixed(2),
        distance_km: orders.distance_km.toFixed(2),
      })
    );
  } catch (error) {
    throw error;
  }
};

export const getPlacesOptions = (payload) => async (dispatch) => {
  // console.log(payload);
  try {
    const places = await ordersApi.getPlaces(payload);
    dispatch(setPlacesOptionsSuccess(places));
  } catch (error) {
    throw error;
  }
};

export const createNewOrder = (payload) => async (dispatch) => {
  // console.log(payload);
  try {
    await ordersApi.createOrder(payload);
  } catch (error) {
    throw error;
  }
};

export const fetchOrdersStatis = (payload) => async (dispatch) => {
  try {
    const statistics = await ordersApi.fetchOrdersStatistics(payload);
    dispatch(fetchOrdersStatisSuccess(statistics));
  } catch (err) {
    throw err;
  }
};

export const fetchUserByMobile = (options) => async (dispatch) => {
  try {
    const user = await ordersApi.getUserByMobile(options);
    dispatch(fetchUserByMobileSuccess(user));
  } catch (error) {
    dispatch(fetchUserByMobileSuccess({}));
    throw error;
  }
};

export default ordersListSlice.reducer;
