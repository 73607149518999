import moment from 'moment';

const subtractFactor = 200;

export const generateTimeLineSlots = () => {
  let hours = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 5) {
      hours.push(moment({ hour, minute }).format('HH:mm'));
    }
  }
  return hours;
};

export const sleep = (time) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time * 1000);
  });
};

export const handleGenerateFakeData = (value, dataType) => {
  /*
  generate fake data in the agreed range untill the api update 
  */
  let fakeValue;
  let fakeStanderPo = [
    Math.random() * 2,
    Math.random() * 2,
    Math.random() * 3,
    Math.random() * 4,
    Math.random() * 5,
  ];

  let fakeStanderNa = [
    Math.random() * -2,
    Math.random() * -2,
    Math.random() * -3,
    Math.random() * -4,
    Math.random() * -5,
  ];
  //generating the fake value
  if (value === 0) {
    fakeValue = 1;
  } else if (value > 0 && value <= 20) {
    fakeValue = fakeStanderPo[0] + 1;
  } else if (value > 20 && value <= 40) {
    fakeValue = fakeStanderPo[1] + 1;
  } else if (value > 40 && value <= 60) {
    fakeValue = fakeStanderPo[2] + 1;
  } else if (value > 60 && value <= 80) {
    fakeValue = fakeStanderPo[3] + 1;
  } else if (value > 80 && value <= 100) {
    fakeValue = fakeStanderPo[4] + 1;
  } else if (dataType === 'ratio') {
    if (value < 0 && value <= -20) {
      fakeValue = fakeStanderNa[0] - 1;
    } else if (value < -20 && value >= -40) {
      fakeValue = fakeStanderNa[1] - 1;
    } else if (value < -40 && value >= -60) {
      fakeValue = fakeStanderNa[2] - 1;
    } else if (value < -60 && value >= -80) {
      fakeValue = fakeStanderNa[3] - 1;
    } else if (value < -80 && value >= -100) {
      fakeValue = fakeStanderNa[4] - 1;
    }
  }

  //return the fake value according to the type
  switch (dataType) {
    case 'ratio':
      return handleBubbleColor(fakeValue);

    case 'diff':
      return fakeValue * subtractFactor;

    default:
      console.log({ message: 'invalid dataType: ' + dataType });
  }
};

export const handleBubbleColor = (demandSupplyratio) => {
  /*
  see if the cell supply dgree
  */
  let bubbleColor;
  if (demandSupplyratio > 0) {
    bubbleColor = handleGreenDgree(demandSupplyratio);
  } else if (demandSupplyratio < 0) {
    bubbleColor = handleRedDgree(demandSupplyratio);
  }
  return bubbleColor;
};

// 0 -> 4 from light to dark
export const handleGreenDgree = (value) => {
  const greenDgree = ['#10d16a', '#1ec26b', '#28b86b', '#31af6c', '#3da46d'];
  const overSupplyColor = '#0a8545';
  if (value === 1) {
    return overSupplyColor;
  } else if (value > 1 && value <= 2) {
    return greenDgree[0];
  } else if (value > 2 && value <= 3) {
    return greenDgree[1];
  } else if (value > 3 && value <= 4) {
    return greenDgree[2];
  } else if (value > 4 && value <= 5) {
    return greenDgree[3];
  } else {
    console.log({ message: 'demand Supply ratio out of range' + value });
  }
};
export const handleRedDgree = (value) => {
  const redDgree = ['#fb3737', '#fa1919', '#e60505', '#d20404', '#b90404'];
  const underSupplyColor = '#b81e1e';
  if (value === 1) {
    return underSupplyColor;
  } else if (value < -1 && value >= -2) {
    return redDgree[0];
  } else if (value < -2 && value >= -3) {
    return redDgree[1];
  } else if (value < -3 && value >= -4) {
    return redDgree[2];
  } else if (value < -4 && value >= -5) {
    return redDgree[3];
  } else {
    console.log({ message: 'demand Supply ratio out of range' });
  }
};
