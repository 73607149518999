import React from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';
import { formatDate } from '../../../drivers/utils';
import { Button, CircularProgress, Grid, LinearProgress } from '@mui/material';

import { Empty } from '../../../drivers/components/common/Empty';
import { useUsersTranslation } from '../../i18n';
import { appDirection } from '../../../../shared_utils';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

function CustomPagination(loading) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid
      container
      className=' flex justify-center relative space-x-4 py-4 w-full'
      spacing={1}
    >
      <Grid item>
        <Button
          disabled={page === 0 || loading ? true : false}
          variant='contained'
          color='primary'
          onClick={() => apiRef.current.setPage(page - 1)}
        >
          {t('previous')}
        </Button>
      </Grid>

      <Grid item>
        <Button
          disabled={loading}
          variant='contained'
          color='primary'
          onClick={() => apiRef.current.setPage(page + 1)}
        >
          {t('next')}
        </Button>
      </Grid>
      <Grid item>
        {loading ? (
          <CircularProgress
            className={
              theme.direction === 'ltr' ? 'absolute right-8' : 'absolute left-8'
            }
          />
        ) : (
          <span
            className={
              '' + theme.direction === 'ltr'
                ? 'p-2 px-4 rounded-full bg-gray-300  font-bold absolute right-8'
                : 'p-2 px-4 rounded-full bg-gray-300  font-bold absolute left-8'
            }
          >
            {page + 1}
          </span>
        )}
      </Grid>
    </Grid>
  );
}

function CustomToolbar() {
  return <GridToolbarContainer></GridToolbarContainer>;
}

//the main component
const TransactionsList = ({ data, setPage, loading }) => {
  //table data
  const transactions = data;
  const t = useUsersTranslation();
  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: ' bg-gray-100 ',
      cellClassName: 'text-xs font-bold text-gray-600',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'reference',
      headerName: t('reference_type'),
      headerClassName: ' bg-gray-100 ',
      cellClassName: 'text-xs',
      flex: 3,
      disableColumnMenu: true,
    },
    {
      field: 'amount',
      headerName: t('amount'),
      headerClassName: ' bg-gray-100  ',
      cellClassName: (params) =>
        params.value > 0
          ? 'text-bold text-green-700 text-center text-xs'
          : 'text-bold text-red-700 text-center text-xs',
      disableColumnMenu: true,
    },
    {
      field: 'narration',
      headerName: t('narration'),
      headerClassName: ' bg-gray-100 ',
      cellClassName: 'text-xs',
      disableColumnMenu: true,
      flex: 3,
    },
    {
      field: 'created',
      headerName: t('created_at'),
      headerClassName: ' bg-gray-100 ',
      cellClassName: 'text-xs',
      type: 'date',
      flex: 2,
      disableColumnMenu: true,
    },
  ];

  const rowsData = transactions.map((transaction) => {
    return {
      id: transaction.id,
      reference: 'CLIENT_GIFT_BALANCE',
      amount: transaction.amount,
      narration: transaction.narration,
      created: formatDate(transaction.created_at),
    };
  });

  return (
    <div className='flex h-auto ' dir={appDirection}>
      <div className=' flex-grow'>
        <DataGrid
          className='bg-white'
          disableColumnFilter
          autoHeight
          density='compact'
          rowHeight={60}
          loading={loading}
          rows={rowsData}
          columns={cols}
          pageSize={20}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => setPage(newPage)}
          components={{
            Toolbar: CustomToolbar,
            Pagination: () => CustomPagination(loading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: LinearProgress,
          }}
        />
      </div>
    </div>
  );
};

export default TransactionsList;
