import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function DeleteModal({
  setOpenModal,
  openModal,
  onConfirm,
  message,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' className='text-red-600'>
          {t('Warning')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color='primary'>
            {t('cancel')}
          </Button>
          <Button
            onClick={() => {
              onConfirm();
              setOpenModal(false);
            }}
            color='error'
            autoFocus
          >
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
