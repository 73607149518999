const common_ar = {
  'lemon dispatch': 'ليمون ديسباتش',
  'Acceptance Rate': 'معدل القبول',
  Dispatches: 'الديسباتشيس',
  Drivers: 'السائقين',
  'Pending Drivers': 'السائقين المعلقين',
  'Drivers Tickets': 'تذاكر السائقين',
  CarTypes: 'انواع السيارات',
  'Supply Demand': 'العرض والطلب',
  'Lemon Taxi': 'ليمون تاكسي',
  'New Order': 'طلب جديد',
  'Shared Trips': 'الرحلات المشتركة',
  Orders: 'الطلبات',
  Users: 'المستخدمين',
  'Users Tickets': 'تذاكر المستخدمين',
  'Orders Statistics': 'احصائيات الطلبات',
  "Generate OTP's": 'توليد OTP',
  'Lemon Places': 'ليمون اماكن',
  'Tracking Map': 'خريطة الاماكن',
  'New Place': 'مكان جديد',
  Places: 'اماكن',
  Reviews: 'تقييمات',
  Reports: 'تبليغات',
  'New Group': 'مجموعة جديدة',
  Groups: 'مجموعات',
  'Lemon events': 'ليمون فعاليات',
  'Events Providers': 'مزودين الفعاليات',
  'Lemon food': 'ليمون طعام',
  'Lemon pay': 'ليمون دفع',
  'Lemon identity': 'نظام الهوية',
  next: 'التالي',
  previous: 'السابق',
  Logout: 'تسجيل الخروج',
  'signed out successfully': 'تم تسجيل الخروج بنجاح',
  'signed in successfully': 'تم تسجيل الدخول بنجاح',
  'Verification Code': 'رمز التحقق',
  'Sign In': 'تسجيل الدخول',
  Verify: 'تأكيد',
  Delete: 'حذف',
  cancel: 'إلغاء',
  confirm: 'تأكيد',
  'are you sure do you want to logout': 'هل انت متاكد انك تريد تسجيل الخروج',
  Warning: 'تحذير',
  'Add New Event': 'اضافة فعالية',
  'Created successfully': 'تم الإنشاء بنجاح',
  'Delete successfully': 'تم الحذف بنجاح',
  'Updated successfully': 'تم التحديث بنجاح',
  Dispatch: 'الديسباتش',
  'Dispatching Times': 'اوقات الديسباتش',
  update_msg: 'هل انت متاكد انك تريد التغيير؟',
  dispatching_time: 'اوقات الديسباتش',
  time_value_in_sec: 'التوقيت بالثواني',
  DISPATCH_DELAY: 'زمن انتظار الديسباتش عند كل كابتن',
  DISPATCH_LOCK_DURATION_IN_SEC: 'زمن قفل الكابتن أثناء عملية الديسباتش',
  MANUAL_DISPATCH_DELAY: 'زمن انتظار الديسباتش اليدوي عند كل كابتن',
  MANUAL_DISPATCH_LOCK_DURATION_IN_SEC:
    'زمن قفل الكابتن أثناء عملية الديسباتش اليدوي',
  FOOD_DISPATCH_DELAY: 'زمن انتظار الديسباتش عند كباتن خدمة فوود',
  submit: 'تأكيد',
  'System Config': 'إعدادات النظام',
  LAST_DELAY: 'زمن انتظار عملية الديسباتش لاخر كابتن',
  action: 'إجراء',
  edit: 'تعديل',

  'Tracking drivers': 'تتبع المركبات',
  Search: 'بحث',
  KM: 'كلم',
  Radius: 'نصف القطر',
  All: 'الكل',
  Tracking: 'تتبع',
  'Auto Search': 'بحث تلقائي',

  'Events Tickets': 'تذاكر الفعاليات',
  Events: 'الفعاليات',

};

export default common_ar;
