import React from 'react';

import DriverForm from './DriverInfoForm';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles';
import { appDirection } from '../../../../shared_utils';
import { usePendingTranslation } from '../i18n';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
} from '@mui/material';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export default function EditDialog({
  driver,
  carTypes,
  driverId,
  isRefreshing,
  setIsRefreshing,
}) {
  const driverI = driver;
  const [open, setOpen] = React.useState(false);

  const t = usePendingTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const DialogTitlee = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <DialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant='h6'>{children}</Typography>
        <Grid container className='flex justify-between '>
          <Grid item className='pt-2 '>
            {t('update_info')}
          </Grid>
          <Grid item>
            {onClose ? (
              <IconButton aria-label='close' onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
      </DialogTitle>
    );
  });

  return (
    <div dir={appDirection}>
      <Button
        variant='contained'
        color='primary'
        aria-label='outlined primary button group'
        onClick={handleClickOpen}
      >
        {t('update_info')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth={'sm'}
        maxWidth={'sm'}
      >
        <DialogTitle id='form-dialog-title' onClose={handleClose}>
          {/* {t('add_note')} */}
          {t('update_info')}
        </DialogTitle>

        <DialogContent>
          <DriverForm
            driver={driverI}
            driverId={driverId}
            carTypes={carTypes}
            close={handleClose}
            isRefreshing={isRefreshing}
            setIsRefreshing={setIsRefreshing}

            // updateDriverInfo={action}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
