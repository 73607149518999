export const generateFormData = (data, img) => {
  let form_data = new FormData();
  Object.keys(data).forEach((key) => {
    if (data[key]) {
      if (key === 'images') {
        let images = Array.from(data.images); //needed to access the first array first
        for (let i = 0; i < images.length; i++) {
          form_data.append('images', images[i]);
        }
      } else if (key === 'reference_data') {
        form_data.get(key, data[key]);
      } else {
        form_data.append(key, data[key]);
      }
    }
  });
  console.log('form_data', form_data);
  return form_data;
};

export const sleep = (time) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time * 1000);
  });
};

export const validateMobileForQueryParams = (mobile) => {
  if (mobile[0] === '0') {
    mobile = mobile.substring(1);
  }
  return mobile[0] !== '+' ? `%2B249${mobile}` : mobile.replace('+', '%2B');
};
