import { Grid } from '@mui/material';

import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import PageHeading from '../../../components/common/PageHeading';
import { setAlert } from '../../../Redux/store/app/appSlice';
import {
  fetchOrderById,
  setError,
} from '../../../Redux/store/orders/ordersListSlice';

import OrderDetailsTabs from '../components/OrderDetailsTabs';
import { useOrdersTranslation } from '../i18n';

const OrderDetailsPage = () => {
  const { id } = useParams();
  const match = useMatch('/dispatches/:id');
  const [isRefreshing, setIsRefreshing] = useState(0);
  const [isloading, setIsloading] = useState(false);
  const { order } = useSelector((state) => state.orders).ordersList;
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const t = useOrdersTranslation();

  const fetchOrder = useCallback(async () => {
    try {
      setIsloading(true);
      await dispatch(fetchOrderById(id));
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    } finally {
      setIsloading(false);
    }
  });

  useEffect(() => {
    fetchOrder();
  }, [isRefreshing]);

  if (isloading) {
    return <LoadingIndicator />;
  }

  return (
    <Grid container lg={12} md={12} sm={12} spacing={2} className='h-full'>
      <Grid item lg={12} md={12} className='px-2  pl-2 text-white relative'>
        <PageHeading
          previousUrl={'orders'}
          heading1={t('orders')}
          heading2={t('oredrs_list')}
          heading3={t('orders_details')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} className='h-full'>
        <OrderDetailsTabs
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      </Grid>
    </Grid>
  );
};

export default OrderDetailsPage;
