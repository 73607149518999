import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import moment from 'moment';
import React from 'react';

export function BlockDialog(props) {
  const { onClose, open, close, t } = props;
  const [reason, setReason] = React.useState(null);
  const [note, setNote] = React.useState('');
  const [unblockDate, setUnblockDate] = React.useState(
    moment().format('YYYY-MM-DDThh:mm')
  );
  const [isChecked, setIsChecked] = React.useState(false);

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleBlock = (event) => {
    if (isChecked) {
      onClose({
        block_reason: reason,
        blocked_until: unblockDate,
        supervisor_note: note,
      });
    } else {
      onClose({ block_reason: reason, supervisor_note: note });
    }
  };

  return (
    <Dialog aria-labelledby='dialog-title' open={open}>
      <DialogTitle id='dialog-title'>{t('block_dialog')}</DialogTitle>
      <DialogContent>
        <Grid container justifyContent='center' className='pt-4' spacing={2}>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setReason(e.target.value)}
              label={t('reason')}
              value={reason}
              variant='outlined'
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setNote(e.target.value)}
              label={t('supervisor_note')}
              value={note}
              variant='outlined'
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleChange}
                  name='checkedB'
                  color='primary'
                />
              }
              label={t('temporary_block')}
            />
          </Grid>
          {isChecked && (
            <Grid item xs={12}>
              <TextField
                id='datetime-local'
                label={t('unblock_date')}
                type='datetime-local'
                defaultValue={unblockDate}
                className='w-full'
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setUnblockDate(e.target.value)}
              />
            </Grid>
          )}
          <Grid
            container
            lg={12}
            md={12}
            className='flex justify-center  p-2 '
            spacing={1}
          >
            <Grid item>
              <Button
                onClick={handleBlock}
                disabled={reason === null || reason == '' ? true : false}
                variant='outlined'
                color='error'
                type='submit'
              >
                {t('block')}
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant='outlined'
                color='inherit'
                onClick={() => close()}
              >
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
