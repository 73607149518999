import React, { useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { CreateTicketNotes } from '../../../../Redux/store/tickets/ticketsListSlice';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { useFormik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { DialogTitle } from '@mui/material';

import { appDirection } from '../../../../shared_utils';
import { useTicketTranslation } from '../../i18n';
import { toast } from 'react-toastify';

export default function CreatenoteDialog({
  ticket_id,
  isRefreshing,
  setIsRefreshing,
}) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const t = useTicketTranslation();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateNote = async (values) => {
    try {
      await dispatch(CreateTicketNotes({ ...values }));
      handleClose();
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    } finally {
      setIsRefreshing(isRefreshing + 1);
    }
  };

  const formik = useFormik({
    initialValues: {
      note: '',
      supervisor_name: '',
      ticket_id: ticket_id,
    },
  });

  const handleSubmit = (values) => {
    handleCreateNote(values);
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
  });

  const DialogTitlee = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <DialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant='h6'>{children}</Typography>
        <Grid container className='flex justify-between '>
          <Grid item className='pt-1 '>
            {t('add_note')}
          </Grid>
          <Grid item>
            {onClose ? (
              <IconButton aria-label='close' onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
      </DialogTitle>
    );
  });
  return (
    <div dir={appDirection}>
      <Button
        variant='contained'
        color='primary'
        aria-label='outlined primary button group'
        onClick={handleClickOpen}
      >
        <Add fontSize='small' />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        maxWidth='xs'
      >
        <DialogTitlee id='form-dialog-title' onClose={handleClose}>
          {/* {t('add_note')} */}
        </DialogTitlee>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Grid container lg={12} md={12}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(formik.values);
                }}
                style={{ width: '100%' }}
              >
                <Grid
                  container
                  lg={12}
                  md={12}
                  spacing={2}
                  justifyContent='center'
                  alignItems='center'
                  paddingTop={2}
                >
                  <Grid item lg={12} md={12}>
                    <TextField
                      fullWidth
                      name='note'
                      id='note'
                      label={t('note')}
                      variant='outlined'
                      // InputLabelProps={{ shrink: true }}
                      value={formik.values.note}
                      onChange={formik.handleChange}
                    />
                  </Grid>

                  <Grid item lg={12} md={12}>
                    <TextField
                      fullWidth
                      name='supervisor_name'
                      id='supervisor_name'
                      label={t('supervisor_name')}
                      variant='outlined'
                      // InputLabelProps={{ shrink: true }}
                      value={formik.values.supervisor_name}
                      onChange={formik.handleChange}
                    />
                  </Grid>

                  {/* </Grid> */}
                  <Grid
                    item
                    lg={12}
                    md={12}
                    className='flex justify-center py-5'
                  >
                    <Button variant='outlined' color='primary' type='submit'>
                      {t('create')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
