import { Button, Grid } from '@mui/material';

import { Flag } from '@mui/icons-material';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from '../../utils';
import { useTicketTranslation } from '../../i18n';
import { makeStyles } from '@mui/styles';
import { appDirection } from '../../../../shared_utils';

const useStyles = makeStyles((theme) => ({
  root: {
    '& label': {
      width: '100%',
      textAlign: 'center',
      transformOrigin: 'center',
      '&.Mui-focused': {
        transformOrigin: 'center',
      },
    },
  },
}));
const TicketMainDetails = ({
  type,
  handleOpenAssignDialog,
  handleOpenUpdateStatusDialog,
  handleOpenUpdateDialog,
}) => {
  const classes = useStyles();
  const { ticketDetails } = useSelector((state) => state.tickets).ticketsList;
  const navigate = useNavigate();
  const t = useTicketTranslation();

  const handleStatusId = useCallback((id) => {
    switch (id) {
      case 0:
        return t('new');
      case 1:
        return t('under_process');
      case 2:
        return t('closed');

      default:
        break;
    }
  });

  const handlePriority = useCallback((id) => {
    switch (id) {
      case 1:
        return (
          <span>
            {t('low')} <Flag fontSize='small' className='text-green-600' />
          </span>
        );
      case 2:
        return (
          <span>
            {t('medium')} <Flag fontSize='small' className='text-blue-600' />
          </span>
        );
      case 3:
        return (
          <span>
            {t('high')} <Flag fontSize='small' className='text-red-400' />
          </span>
        );
      case 4:
        return (
          <span>
            {t('critical')} <Flag fontSize='small' className='text-red-700' />
          </span>
        );
      default:
        return <span>.....</span>;
    }
  });

  return (
    <Grid
      container
      lg={12}
      md={12}
      justifyContent='center'
      className='font-serif'
      dir={appDirection}
    >
      {/* header  */}
      <Grid item lg={12} md={12} className='py-6 '>
        <div className='flex justify-center'>
          <span className='p-2 px-6 rounded-2xl font-serif font-semibold bg-gray-300'>
            {handleStatusId(ticketDetails.status_id)}
          </span>
        </div>
        <div className='flex justify-center  p-2'>
          {handlePriority(ticketDetails.priority)}
        </div>
      </Grid>
      {/* body  */}

      <Grid container lg={10} md={10} spacing={2}>
        {/* item  */}
        <Grid item lg={6} md={6} className=''>
          <Grid item lg={12} md={12} className='text-gray-600   font-semibold '>
            {t('ticket_id')}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            className=' text-base text-gray-600 bg-white p-8 rounded-lg shadow-lg'
          >
            {ticketDetails.ticket_id || '......'}
          </Grid>
        </Grid>
        {/* item  */}
        <Grid item lg={6} md={6} className=''>
          <Grid item lg={12} md={12} className='text-gray-600   font-semibold '>
            {t('created_at')}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            className=' text-base text-gray-600 bg-white p-8 rounded-lg shadow-lg'
          >
            {format(ticketDetails.created_at) || '......'}
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} className=''>
          <Grid
            item
            lg={12}
            md={12}
            className='text-gray-600 text-lg  font-semibold '
          >
            {t(type)}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            className={
              ticketDetails.user_id
                ? 'text-base text-gray-600 bg-white p-8 rounded-lg shadow-lg hover:bg-blue-200 hover:cursor-pointer'
                : 'text-base text-gray-600 bg-white p-8 rounded-lg shadow-lg '
            }
            onClick={() =>
              navigate(
                type === 'driver'
                  ? `/drivers/${ticketDetails.user_id}/identity`
                  : `/users/${ticketDetails.user_id}`
              )
            }
          >
            {ticketDetails.user_id ? ticketDetails.user.full_name : '......'}
          </Grid>
        </Grid>
        {/* item  */}
        <Grid item lg={6} md={6} className=''>
          <Grid
            item
            lg={12}
            md={12}
            className='text-gray-600 text-lg  font-semibold '
          >
            {t('order_id')}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            className={
              ticketDetails.reference_id
                ? 'text-base text-gray-600 bg-white p-8 rounded-lg shadow-lg hover:bg-blue-200 hover:cursor-pointer'
                : 'text-base text-gray-600 bg-white p-8 rounded-lg shadow-lg '
            }
            onClick={() =>
              ticketDetails.reference_id &&
              navigate(`/orders/${ticketDetails.reference_id}`)
            }
          >
            {ticketDetails.reference_id || '......'}
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} className=''>
          <Grid
            item
            lg={12}
            md={12}
            className='text-gray-600 text-lg  font-semibold '
          >
            {t('support_user')}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            className='text-base text-gray-600 bg-white p-8 rounded-lg shadow-lg '
          >
            {ticketDetails.support_user_id
              ? ticketDetails.support_user.full_name
              : '......'}
          </Grid>
        </Grid>
        {/* item  */}
        <Grid item lg={6} md={6} className=''>
          <Grid
            item
            lg={12}
            md={12}
            className='text-gray-600 text-lg  font-semibold '
          >
            {t('category')}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            className=' text-base text-gray-600 bg-white px-8 py-6 rounded-lg shadow-lg'
          >
            <p className='font-semibold'>
              {ticketDetails.parent_category_id
                ? ticketDetails.parent_category.category_name
                : '......'}
            </p>
            {ticketDetails.child_category_id
              ? ticketDetails.child_category.category_name
              : '......'}
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} className=''>
          <Grid
            item
            lg={12}
            md={12}
            className='text-gray-600 text-lg  font-semibold '
          >
            {t('description')}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            className=' text-base text-gray-600 bg-white p-8 rounded-lg shadow-lg'
          >
            {ticketDetails.description || '......'}
          </Grid>
        </Grid>
        {/* item  */}
        <Grid item lg={6} md={6} className=''>
          <Grid
            item
            lg={12}
            md={12}
            className='text-gray-600 text-lg  font-semibold '
          >
            {t('support_note')}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            className=' text-base text-gray-600 bg-white p-8 rounded-lg shadow-lg'
          >
            {ticketDetails.note || '......'}
          </Grid>
        </Grid>
        {/* btn's  */}
        <Grid
          container
          lg={12}
          md={12}
          spacing={1}
          className='pt-4 flex justify-center text-white'
        >
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              onClick={() => handleOpenUpdateDialog(ticketDetails)}
            >
              {t('edit')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              onClick={() =>
                handleOpenUpdateStatusDialog(ticketDetails.ticket_id)
              }
            >
              {t('change_status')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              onClick={() => handleOpenAssignDialog(ticketDetails.ticket_id)}
            >
              {t('assign')}
            </Button>
          </Grid>
        </Grid>
        {/* end_of_content */}
      </Grid>
    </Grid>
  );
};

export default TicketMainDetails;
