import { Add, Close } from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { useState } from 'react';
import { usePlacesTranslation } from '../../i18n';
import PhotosDialog from './PhotosDialog';

export default function ReviewImages({
  comment,
  isRefreshing,
  setIsRefreshing,
}) {
  const [open, setOpen] = useState(false);

  const [del, setDel] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const t = usePlacesTranslation();

  const DialogTitlee = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <DialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant='h6'>{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  });
  // if (isLoading) {
  //   return <LoadingIndicator />;
  // }
  return (
    <div>
      <ButtonGroup color='primary' onClick={handleClickOpen}>
        <Add
          fontSize='small'
          color='primary'
          className=' hover:bg-blue-200 hover:cursor-pointer'
        />
      </ButtonGroup>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullHeight
        maxHeight='lg'
      >
        <DialogTitlee
          id='form-dialog-title'
          onClose={handleClose}
        ></DialogTitlee>
        <DialogContent>
          <Grid lg={12} md={12} className=''>
            {comment.place_images.length !== 0 ? (
              <ImageList
                sx={{ width: 600, height: 450 }}
                cols={3}
                rowHeight={164}
              >
                {comment.place_images.map((item) => (
                  <ImageListItem key={item.image_id}>
                    <div className='relative'>
                      <img
                        src={item.image}
                        alt='no_image'
                        // style={{ height: 450, width: 200 }}
                        className='relative z-0 overflow-auto '
                      />
                      <div className='absolute top-0 left-0'>
                        {del && (
                          <PhotosDialog
                            id={item.comment_id}
                            image_id={item.image_id}
                            setIsRefreshing={setIsRefreshing}
                            isRefreshing={isRefreshing}
                          />
                        )}
                      </div>
                    </div>
                  </ImageListItem>
                ))}
              </ImageList>
            ) : (
              <div className='w-full font-bold text-2xlg text-center text-gray-400'>
                {t('no_images')}
              </div>
            )}

            {comment.place_images.length !== 0 && (
              <Grid
                item
                lg={12}
                md={12}
                className='flex justify-center items-end p-2 '
              >
                <Button
                  color='error'
                  variant='contained'
                  type='submit'
                  onClick={(e) => setDel(!del)}
                >
                  {del ? t('cancel') : t('delete')}
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
