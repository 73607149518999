import React from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import AcceptanceRatePage from './pages/acceptance_rate_page';

const LazyAcceptanceRate = React.lazy(() => import('./pages/acceptance_rate_page'));

const Routes = () => {
  return (
    <Route path='/dispatch/acceptance_rate' key={'R1'}>
      <Route
        path='/dispatch/acceptance_rate/'
        // element={<div>test</div>}
        element={
          <React.Suspense fallback='loading ...'>
            <LazyAcceptanceRate />
          </React.Suspense>
        }
        key={'AcceptanceRatePage'}
      />
    </Route>
  );
};

export default Routes;
