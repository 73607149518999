import { Grid } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageHeading from '../../../components/common/PageHeading';
import { setAlert } from '../../../Redux/store/app/appSlice';
import {
  getPlacsDetails,
  getComments,
  getAmenitiesList,
} from '../../../Redux/store/places/placesListSlice';
import PlaceDetails from '../components/PlaceDetails';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { usePlacesTranslation } from '../i18n';
import { toast } from 'react-toastify';

const PlaceDetailsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(0);

  const dispatch = useDispatch();
  const { id } = useParams();

  const { IsRefreshingPlace } = useSelector((state) => state.places).places;

  const t = usePlacesTranslation();

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(getPlacsDetails({ id: id }));
      await dispatch(getComments({ id: id, page_number: 0 }));
      await dispatch(getAmenitiesList());
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    fetchData();
  }, [isRefreshing, IsRefreshingPlace]);

  if (isLoading) {
    return (
      <div>
        <LoadingIndicator />
      </div>
    );
  }
  return (
    <Grid container lg={12} md={12} spacing={2}>
      <Grid item lg={12} md={12} className='px-2  pl-2 text-white relative'>
        <PageHeading
          previousUrl={'places/'}
          heading1={t('places')}
          heading2={t('places_list')}
          heading3={t('place_details')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>

      {/* main view */}
      <Grid item lg={12} md={12} xs={12}>
        <PlaceDetails
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      </Grid>
    </Grid>
  );
};

export default PlaceDetailsPage;
