const car_en = {
  cars: 'Cars',
  cars_list: 'Cars List',
  car_details: 'Cars Details',
  car_type: 'Car type',
  car_types: 'Car types',
  standard_ride: 'Standard ride',
  short_ride: 'Short ride',
  mid_ride: 'Mid ride',
  open_km: 'Open ride/km',
  open_min: 'Open ride/min',
  cars_allowed: 'Types of cars allowed',
  view: 'View',
  pricekm: 'price km',
  average: 'Price of average ride',
  short: 'Price of short ride',
  minimum: 'Minimum fare',
  open: 'Price of Open ride',
  minprice: 'Minute price',
  update: 'Update',
  vehical: 'Vehicle Data Type',
  name: 'Name',
  description: 'Description',
  backup: 'Backup minute price',
  update: 'Edit',
  min_price: 'min-price',
  distance: 'Distance',
  SDG_KM: 'SDG/KM',
  SDG_MIN: 'SDG/MIN',
};
export default car_en;
