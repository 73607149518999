import { createSlice } from '@reduxjs/toolkit';
import { driversApi } from './api';

const initialState = {
  drivers: [],
  error: { response: { status: null } },
  notes: [],
  transactions: [],
  revertTransaction: {},
  performance: {},
  weeklyPerformance: [],
  hourlyPerformance: [],
  files: [],
  balance: 0,
  driver: {
    fees_group: { group_name: null },
  },
  carTypes: [],
  feesGroups: [], //FIXME: find the bug
  isLoading: false,
  ordersStatis: [],
};

const driversListSlice = createSlice({
  name: 'drivers',
  initialState,
  reducers: {
    setLoadingState: (state, action) => {
      state.isLoading = action.payload;
    },
    fetchDriversSuccess: (state, action) => {
      state.drivers = action.payload;
    },
    fetchDriverSuccess: (state, action) => {
      state.driver = action.payload;
    },
    fetchTransactionsSuccess: (state, action) => {
      state.transactions = action.payload;
    },
    fetchBalanceSuccess: (state, action) => {
      state.balance = action.payload;
    },
    fetchOrdersStatisSuccess: (state, action) => {
      state.ordersStatis = action.payload;
    },
    fetchPerformanceSuccess: (state, action) => {
      state.performance = action.payload;
    },
    fetchWeeklyPerformanceSuccess: (state, action) => {
      state.weeklyPerformance = action.payload;
    },
    fetchHourlyPerformanceSuccess: (state, action) => {
      state.hourlyPerformance = action.payload;
    },
    fetchDriverFilesSuccess: (state, action) => {
      state.files = action.payload;
    },
    fetchCarTypesSuccess: (state, action) => {
      state.carTypes = action.payload;
    },
    revertTransactionsSuccess: (state, action) => {
      state.revertTransaction = action.payload;
    },
    fetchDrivernotesSuccess: (state, action) => {
      state.notes = action.payload;
    },
    fetchFeesGroupsSuccess: (state, action) => {
      state.feesGroups = action.payload;
    },
    setError: (state, actions) => {
      state.error = actions.payload;
    },
  },
});

export const {
  fetchDriversSuccess,
  fetchFeesGroupsSuccess,
  fetchDriverSuccess,
  fetchCarTypesSuccess,
  fetchTransactionsSuccess,
  fetchBalanceSuccess,
  fetchPerformanceSuccess,
  fetchOrdersStatisSuccess,
  fetchWeeklyPerformanceSuccess,
  fetchHourlyPerformanceSuccess,
  fetchDriverFilesSuccess,
  revertTransactionsSuccess,
  fetchDrivernotesSuccess,
  setError,
  setLoadingState,
} = driversListSlice.actions;

export const fetchDrivers = (options) => async (dispatch) => {
  try {
    dispatch(setLoadingState(true));
    const drivers = await driversApi.fetchDrivers(options);
    dispatch(fetchDriversSuccess(drivers));
  } catch (error) {
    dispatch(fetchDriversSuccess([]));
    dispatch(setError(error));
    throw error;
  }
};

export const fetchFeesGroups = () => async (dispatch) => {
  try {
    dispatch(setLoadingState(true));
    const feesGroups = await driversApi.fetchFeesGroups();
    dispatch(fetchFeesGroupsSuccess(feesGroups));
  } catch (error) {
    dispatch(fetchDriversSuccess([]));
    dispatch(setError(error));
    throw error;
  }
};

export const fetchDriver = (payload) => async (dispatch) => {
  try {
    dispatch(setLoadingState(true));
    const driver = await driversApi.findDriver(payload);
    dispatch(fetchDriverSuccess(driver));
    return driver;
  } catch (error) {
    dispatch(fetchDriverSuccess({}));
    dispatch(setError(error));
    throw error;
  }
};

export const blockDriver = (payload) => async (dispatch) => {
  try {
    dispatch(setLoadingState(true));
    const driver = await driversApi.block(payload);
    return driver;
  } catch (error) {
    dispatch(setError(error));
    throw error;
  }
};

export const unBlockDriver = (id) => async (dispatch) => {
  try {
    dispatch(setLoadingState(true));
    const driver = await driversApi.unBlock(id);
    return driver;
  } catch (error) {
    dispatch(setError(error));
    throw error;
  }
};

export const fetchCarTypes = (id) => async (dispatch) => {
  try {
    dispatch(setLoadingState(true));
    const carTypes = await driversApi.fetchCarTypes();
    dispatch(fetchCarTypesSuccess(carTypes));
    return carTypes;
  } catch (error) {
    dispatch(setError(error));
    return error;
  }
};
export const fetchOrdersStatis = (payload) => async (dispatch) => {
  try {
    const statistics = await driversApi.fetchOrdersStatistics(payload);
    dispatch(fetchOrdersStatisSuccess(statistics));
  } catch (err) {
    throw err;
  }
};

export const getDriverTransactions = (options) => async (dispatch) => {
  try {
    // dispatch(setLoadingState(true));
    const transactions = await driversApi.getDriverTransactions(options);
    dispatch(fetchTransactionsSuccess(transactions));
    return transactions;
  } catch (error) {
    dispatch(setError(error));
    return error;
  }
};

export const revertTransactions = (id) => async (dispatch) => {
  try {
    dispatch(setLoadingState(true));
    const revertedTransaction = await driversApi.revertTransactions(id);
    dispatch(revertTransactionsSuccess(revertedTransaction));
    return revertedTransaction;
  } catch (error) {
    dispatch(fetchDriverSuccess({}));
    dispatch(setError(error));
    throw error;
  }
};

export const getDriverBalance = (id) => async (dispatch) => {
  try {
    // dispatch(setLoadingState(true));
    const balance = await driversApi.getDriverTotalBalance(id);
    dispatch(fetchBalanceSuccess(balance.balance));
    return balance;
  } catch (error) {
    dispatch(setError(error));
    return error;
  }
};

export const addDriverBalance = (options) => async (dispatch) => {
  try {
    const balance = await driversApi.addDriverBalance(options);
    return balance;
  } catch (error) {
    dispatch(setError(error));
    return error;
  }
};

export const deductDriverBalance = (options) => async (dispatch) => {
  try {
    const balance = await driversApi.deductDriverBalance(options);
    return balance;
  } catch (error) {
    dispatch(setError(error));
    return error;
  }
};

export const fetchDriverPerformance = (options) => async (dispatch) => {
  try {
    const performance = await driversApi.fetchDriverPerformance(options);
    dispatch(fetchPerformanceSuccess(performance));
    return performance;
  } catch (error) {
    dispatch(setError(error));
    return error;
  }
};

export const fetchDriverWeeklyPerformance = (options) => async (dispatch) => {
  try {
    const performance = await driversApi.fetchDriverWeeklyPerformance(options);
    dispatch(fetchWeeklyPerformanceSuccess(performance));
    return performance;
  } catch (error) {
    dispatch(setError(error));
    return error;
  }
};

export const fetchDriverHourlyPerformance = (options) => async (dispatch) => {
  try {
    const performance = await driversApi.fetchDriverHourlyPerformance(options);
    dispatch(fetchHourlyPerformanceSuccess(performance));
    return performance;
  } catch (error) {
    dispatch(setError(error));
    return error;
  }
};

export const fetchDriverFiles = (id) => async (dispatch) => {
  try {
    const files = await driversApi.fetchDriverFiles(id);
    dispatch(fetchDriverFilesSuccess(files));
    return files;
  } catch (error) {
    dispatch(setError(error));
    return error;
  }
};

export const updateDriver = (driverObj) => async (dispatch) => {
  try {
    dispatch(setLoadingState(true));
    const driver = await driversApi.updateDriver(driverObj);
    dispatch(setLoadingState(false));
    return driver;
  } catch (error) {
    dispatch(setError(error));
    return error;
  }
};

// TODO: test the logic

export const fetchNotes = (id) => async (dispatch) => {
  try {
    const notes = await driversApi.fetchNotes(id);
    dispatch(fetchDrivernotesSuccess(notes));
    return notes;
  } catch (error) {
    dispatch(setError(error));
    return error;
  }
};

export const addNote = (options) => async (dispatch) => {
  try {
    const Note = await driversApi.addNote(options);
    return Note;
  } catch (error) {
    dispatch(setError(error));
    return error;
  }
};

export default driversListSlice.reducer;
