import http from '../http';

const getSupplyDemandData = async ({ from, to }) => {
  const baseURL = process.env.REACT_APP_DISPATCH_API;
  try {
    const response = await http.get(
      `${baseURL}/demand_supply/demand_supply_results?from=${from}&to=${to}`
    );
    return response.data;
  } catch (error) {
    throw error.response;
  }
};

export default getSupplyDemandData;
