const pending_driver_ar = {
  pending_drivers: 'السائقين المعلقين',
  drivers_list: 'قائمة السائقين',
  driver_details: 'تفاصيل السائق',
  car_type: 'نوع السيارة',
  phone: 'رقم الهاتف',
  drivers: 'السائقين',
  status: 'الحاله',
  car_info: 'تفاصيل السيارة',
  date: 'التاريخ',
  actions: 'العمليات',
  apply: 'تطبيق',
  driver_id: 'رقم هوية السائق',
  driver_info: 'معلومات السائق',
  mobile_number: 'رقم الهاتف',
  driver_license: 'رخصة السائق',
  national_id: 'الرقم الوطني',
  referral_number: ' رقم الإحالة',
  device_os: 'نظام التشغيل',
  plate_number: 'رقم اللوحة',
  color: 'اللون',
  car_model: 'موديل السيارة',
  car_status: 'الحالة',
  manufacture_date: 'تاريخ التصنيع',
  vehicle_type: 'نوع السيارة',
  update_info: 'تحديث المعلومات',
  send_request: 'تحديث السبب',
  account_info: 'معلومات الحساب',
  reason: 'الأسباب',
  activate: 'تنشيط',
  reject: 'حظر',
  details: 'التفاصيل',
  files: 'الملفات',
  name: 'الإسم',
  submit: 'تطبيق',
  cancel: 'إلغاء',
  update_reason: 'تعديل السبب',
  block_dialog: 'تنشيط السائق',
  activate_driver: 'تنشيط حساب السائق',
  new: 'جديد',
  very_good: 'جيد جدا',
  good: 'جيد',
  no_files: 'لا توجد صور',
  reject_driver: 'حظر حساب السائق',
  avatar_image: 'الصورة الشخصية',
  id_image: 'صورة الهوية',
  car_inside_image: 'صور السيارة من الداخل',
  car_outside_image: 'صور السيارة من الخارج',
  driver_license_image: 'صور الرخصة',
  car_search_certificate_image: 'صور شهادة البحث',
  emergency_transport_license_image: 'صور ترخيص النقل في حالات الطوارئ',
  rejected: 'تم الحظر',
  msg_update: `تم تحديث المعلومات`,
  msg_details: 'تحديث التفاصيل بنجاح',
  msg_activate: 'تم تنشيط بنجاح',
};

export default pending_driver_ar;
