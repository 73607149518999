import React, { useState } from 'react';

import FormDialog from '../common/FormDialog';
import { Formik } from 'formik';
import { formatDate } from '../../../drivers/utils';
import AddPlaceToGroupDialog from '../common/AddPlaceToGroupDialog';
import DeletePlaceDialog from '../common/DeletePlaceDialog';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { usePlacesTranslation } from '../../i18n';
import { Box, Button, ButtonGroup, Grid, Rating } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

function Details({ isLoading, placeDetails }) {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const t = usePlacesTranslation();

  const provider = placeDetails.reference_data || null;

  if (isLoading) {
    return <LoadingIndicator />;
  }
  return (
    <Grid
      container
      lg={12}
      md={12}
      spacing={2}
      justifyContent='center'
      alignItems='center'
    >
      <div className=' p-4 font-serif text-l w-3/4 border-solid border-black '>
        <div className='flex'>
          <div className='text-center border-b-2 w-1/4 border-r-4 border-blue  p-2'>
            {t('name_en')}
          </div>
          <div className='text-center border-b-2 w-1/2  bg-white p-2  flex-1'>
            {placeDetails.name_en || '....'}
          </div>
        </div>
        <div className='flex'>
          <div className='text-center border-b-2 w-1/4 border-r-4 border-blue  p-2'>
            {t('name_ar')}
          </div>
          <div className='text-center border-b-2 w-1/2  bg-white p-2  flex-1'>
            {placeDetails.name_ar || '....'}
          </div>
        </div>
        <div className='flex'>
          <div className='text-center border-b-2 w-1/4 p-2 border-r-4 border-blue'>
            {t('amenity')}
          </div>
          <div className='text-center border-b-2 w-1/2 p-2 bg-white border-solid  flex-1'>
            {placeDetails.amenity ? placeDetails.amenity.name : ''}
          </div>
        </div>
        <div className='flex'>
          <div className='text-center border-b-2 w-1/4 p-2 border-r-4 border-blue'>
            {t('address')}
          </div>
          <div className='text-center border-b-2 w-1/2 p-2 bg-white border-solid  flex-1'>
            {placeDetails.address}
          </div>
        </div>
        <div className='flex'>
          <div className='text-center border-b-2 w-1/4 p-2 border-r-4 border-blue'>
            {t('phone')}
          </div>
          <div className='text-center border-b-2 w-1/2 p-2 bg-white border-solid  flex-1'>
            {placeDetails.phone}
          </div>
        </div>
        <div className='flex'>
          <div className='text-center border-b-2 w-1/4 p-2 border-r-4 border-blue'>
            {t('website')}
          </div>
          <div className='text-center border-b-2 w-1/2 p-2 bg-white border-solid  flex-1'>
            {placeDetails.website}
          </div>
        </div>
        {placeDetails.event_id && (
          <Link to={'/events/' + placeDetails.event_id}>
            <div className='flex'>
              <div className='text-center border-b-2 w-1/4 p-2 border-r-4 border-blue'>
                {t('event_id')}
              </div>
              <div className='text-center border-b-2 w-1/2 p-2 bg-white border-solid text-blue-400 flex-1'>
                {placeDetails.event_id}
              </div>
            </div>
          </Link>
        )}
        {provider && (
          <Link to={'/events/providers/' + provider.provider_id}>
            <div className='flex'>
              <div className='text-center border-b-2 w-1/4 p-2 border-r-4 border-blue'>
                {t('event_provider')}
              </div>
              <div className='text-center border-b-2 w-1/2 p-2 bg-white border-solid text-blue-400 flex-1'>
                {provider.full_name}
              </div>
            </div>
          </Link>
        )}
        <div className='flex'>
          <div className='text-center border-b-2 w-1/4 p-2 border-r-4 border-blue'>
            {t('user_name')}
          </div>
          <div className='text-center border-b-2 w-1/2 p-2 bg-white border-solid  flex-1'>
            {placeDetails.user ? placeDetails.user.full_name : ''}
          </div>
        </div>
        <div className='flex'>
          <div className='text-center border-b-2 w-1/4 p-2 border-r-4 border-blue'>
            {t('user_mobile')}
          </div>
          <div className='text-center border-b-2 w-1/2 p-2 bg-white border-solid  flex-1'>
            {placeDetails.user ? placeDetails.user.mobile : ''}
          </div>
        </div>
        <div className='flex'>
          <div className='text-center border-b-2 w-1/4 p-2 border-r-4 border-blue'>
            {t('email')}
          </div>
          <div className='text-center border-b-2 w-1/2 p-2 bg-white border-solid  flex-1'>
            {placeDetails.user ? placeDetails.user.email : ''}
          </div>
        </div>
        <div className='flex'>
          <div className='text-center border-b-2 w-1/4 p-2 border-r-4 border-blue'>
            {t('created_at')}
          </div>
          <div className='text-center border-b-2 w-1/2 p-2 bg-white border-solid  flex-1'>
            {formatDate(placeDetails.created_at)}
          </div>
        </div>
        <div className='flex'>
          <div className='text-center border-b-2 w-1/4 p-2 border-r-4 border-blue'>
            {t('updated_at')}
          </div>
          <div className='text-center border-b-2 w-1/2 p-2 bg-white border-solid  flex-1'>
            {formatDate(placeDetails.updated_at)}
          </div>
        </div>
      </div>

      <div className='flex flex-wrap rounded-lg bg-white p-4 mb-2 font-serif text-l w-1/4 border-solid border-black'>
        <div className='text-center font-bold ml-2 w-full text-black'>
          <h1>{t('ratings')}</h1>
        </div>
        <div className='w-full mt-2'>
          <div className='flex items-center justify-center'>
            <Rating name='rating 1' value={1} disabled />
            <span>{placeDetails.count_rating_1}</span>
          </div>
          <div className='flex items-center justify-center'>
            <Rating name='rating 2' value={2} disabled />
            <div className=''>{placeDetails.count_rating_2}</div>
          </div>
          <div className='flex items-center justify-center'>
            <Rating name='rating 3' value={3} disabled />
            <div>{placeDetails.count_rating_3}</div>
          </div>
          <div className='flex items-center justify-center'>
            <Rating name='rating 4' value={4} disabled />
            <div className=''>{placeDetails.count_rating_4}</div>
          </div>
          <div className='flex items-center justify-center'>
            <Rating name='rating 5' value={5} disabled />
            <div className=''>{placeDetails.count_rating_5}</div>
          </div>

          <div className='text-center'>
            <h1>{t('total')}</h1>
            <div>{placeDetails.total_rating}</div>
          </div>
        </div>
      </div>

      <ButtonGroup>
        <DeletePlaceDialog placeId={placeDetails.place_id} />
        <AddPlaceToGroupDialog placeId={placeDetails.place_id} />
        <FormDialog open={open} setOpen={setOpen} />
        <>
          <Button
            variant='outlined'
            color='success'
            onClick={() =>
              navigate('/events/add-to-place/' + placeDetails.place_id)
            }
          >
            {t('link_event_to_place')}
          </Button>
        </>
      </ButtonGroup>
    </Grid>
  );
}

export default Details;
