const pending_driver_en = {
  pending_drivers: 'Pending Drivers',
  drivers_list: 'Drivers List',
  car_type: 'Car type',
  phone: ' Phone',
  drivers: 'Drivers',
  status: 'Status',
  car_info: 'Car Info',
  date: 'Date',
  actions: 'Actions',
  driver_id: 'Driver ID',
  driver_info: 'Driver Info ',
  mobile_number: ' Mobile number',
  driver_license: 'Driver license ',
  national_id: 'National ID',
  referral_number: ' Referral number',
  device_os: 'Device OS ',
  plate_number: 'Plate number',
  color: 'Color',
  description: 'Description',
  car_status: 'Car status',
  car_model: 'Car model',
  manufacture_date: 'Manufacture date ',
  vehicle_type: 'Vehicle type ',
  update_info: 'Update Info ',
  account_info: ' Account info',
  driver_details: 'Driver Details',
  name: 'Name',
  very_good: 'very good',
  update_reason: 'Update Reason ',
  block_dialog: 'Block Dialog ',
  activate_driver: 'Activate driver  ',
  no_files: 'No Files',
  reject_driver: ' Reject Driver ',
  avatar_image: 'Avatar image ',
  id_image: 'ID image ',
  car_inside_image: 'Car inside image',
  car_outside_image: 'Car outside image ',
  driver_license_image: 'Driver license image',
  car_search_certificate_image: 'Car search certificate image',
  emergency_transport_license_image: 'Emergency transport license image',
  msg_update: `Update info request sent to the driver`,
  msg_details: 'updated details successfully',
  msg_activate: 'activated successfully',
};

export default pending_driver_en;
