import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../../services/API/cartypes';

const initialState = {
  isLoading: false,
  carTypeDetails: {},
  error: null,
};

export const updateCartype = createAsyncThunk(
  'carTypeDetails/updateCartype',
  async (payload) => {
    return await api.updateCartype(payload);
  }
);
const carTypeDetailsSlice = createSlice({
  name: 'carTypeDetails',
  initialState,
  reducers: {
    setCarTypeDetails: (state, action) => {
      state.carTypeDetails = action.payload;
    },
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCartype.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateCartype.fulfilled, (state, action) => {
      state.isLoading = false;
      state.carTypeDetails = action.payload;
      toast.success('Updated successfully');
    });
    builder.addCase(updateCartype.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      toast.error(state.error.message);
    });
  },
});

export const { setCarTypeDetails } = carTypeDetailsSlice.actions;

export default carTypeDetailsSlice.reducer;
