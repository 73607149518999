import http from '../../../services/APIs/tickets_api';

export const ticketsApi = {
  // req 0
  fetchUsersTicketsList: async (payload) => {
    try {
      const ticketsList = await http.get(
        payload.user_id
          ? `/tickets?service_id=lemon_taxi&client_id=LEMON_APP_ID&user_id=${payload.user_id}&status_id=${payload.status_id}&page_index=${payload.page_number}&page_size=${payload.page_size}`
          : `/tickets?service_id=lemon_taxi&client_id=LEMON_APP_ID&status_id=${
              payload.status_id
            }&page_index=${payload.page_number}&page_size=${
              payload.page_size
            }&ticket_id=${payload.ticket_id}&mobile_number=${
              payload.mobile === '' ? '' : `%2B249${payload.mobile}`
            }&from_date=${payload.from}&to_date=${payload.to}`
      );

      return ticketsList.data;
    } catch (err) {
      throw err.response;
    }
  },
  fetchDriversTicketsList: async (payload) => {
    try {
      const ticketsList = await http.get(
        payload.user_id
          ? `/tickets?service_id=dispatch&client_id=DISPATCH_ID&user_id=${payload.user_id}&status_id=${payload.status_id}&page_index=${payload.page_number}&page_size=${payload.page_size}`
          : `/tickets?service_id=dispatch&client_id=DISPATCH_ID&status_id=${
              payload.status_id
            }&page_index=${payload.page_number}&page_size=${
              payload.page_size
            }&ticket_id=${payload.ticket_id}&mobile_number=${
              payload.mobile === '' ? '' : `%2B249${payload.mobile}`
            }&from_date=${payload.from}&to_date=${payload.to}`
      );

      return ticketsList.data;
    } catch (err) {
      throw err.response;
    }
  },
  // req 2
  fetchTicket: async (ticket_id) => {
    try {
      const ticket = await http.get(`/tickets/${ticket_id}`);
      return ticket.data;
    } catch (err) {
      throw err.response;
    }
  },
  // req 2
  fetchTicketTransactions: async (ticket_id) => {
    try {
      const transactions = await http.get(`/ticket_transactions/${ticket_id}`);
      return transactions.data;
    } catch (err) {
      throw err.response;
    }
  },
  // req 3
  fetchTicketMessages: async (payload) => {
    try {
      const messages = await http.get(
        `/ticket_messages?ticket_id=${payload.ticket_id}&page_size=${payload.page_size}`
      );
      return messages.data;
    } catch (err) {
      throw err.response;
    }
  },
  // req 4
  sendMessage: async (payload) => {
    try {
      await http.post(`/ticket_messages`, payload);
    } catch (err) {
      throw err.response;
    }
  },
  // req 5
  fetchSupportUsers: async () => {
    try {
      const supportUsersList = await http.get(`/users/support_users`);
      return supportUsersList.data;
    } catch (err) {
      throw err.response;
    }
  },
  // req 6
  assignSupportUser: async (payload) => {
    try {
      await http.put(`/tickets/assign_support_user`, {
        ticket_id: payload.id,
        support_user_id: payload.support_user_id,
      });
    } catch (err) {
      throw err.response;
    }
  },
  // req 7

  changeTicketStatus: async (payload) => {
    try {
      await http.put(`/tickets/${payload.type}`, {
        note: payload.note,
        notify_user: payload.notify_user,
        ticket_id: payload.id,
      });
    } catch (err) {
      throw err.response;
    }
  },
  // req 8
  fetchUsersTicketCategoriesCall: async () => {
    try {
      const categoriesList = await http.get(
        `/ticket_categories?service_id=lemon_taxi&client_id=LEMON_APP_ID`
      );
      return categoriesList.data;
    } catch (err) {
      throw err.response;
    }
  },

  fetchDriversTicketCategoriesCall: async () => {
    try {
      const categoriesList = await http.get(
        `/ticket_categories?service_id=dispatch&client_id=DISPATCH_ID`
      );
      return categoriesList.data;
    } catch (err) {
      throw err.response;
    }
  },

  // req 9
  fetchUserByMobile: async (mobile_number) => {
    try {
      const user = await http.get(`/users?mobile=%${mobile_number}`);
      return user.data;
    } catch (err) {
      throw err.response;
    }
  },
  // req 10
  createTicket: async (payload) => {
    try {
      await http.post(`/tickets`, {
        parent_category_id: payload.parent_category_id,
        child_category_id: payload.child_category_id,
        client_id: payload.client_id,
        description: payload.description,
        note: payload.note,
        priority: payload.priority,
        reference_id: payload.reference_id,
        service_id: payload.service_id,
        support_user_id: null,
        user_id: payload.user_id,
      });
    } catch (err) {
      throw err.response;
    }
  },
  // req 11
  updateTicket: async (options) => {
    try {
      await http.put(`/tickets/${options.ticketId}`, options.payload);
    } catch (err) {
      throw err.response;
    }
  },
  fetchTicketNotes: async (ticketId) => {
    try {
      const user = await http.get(`/ticket_notes/${ticketId}`);
      return user.data;
    } catch (err) {
      throw err.response;
    }
  },

  CreateTicketNotes: async (payload) => {
    try {
      const user = await http.post(
        `/ticket_notes/${payload.ticket_id}`,
        payload
      );
      return user.data;
    } catch (err) {
      throw err.response;
    }
  },
};
