import http from "../http";

export const getProviderById = async (id) => {
  try {
    const { data } = await http.get(`/dashboard/events_providers/${id}`);
    return data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
};
