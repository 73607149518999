import { Grid } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import {
  fetchTicketMessages,
  setError,
} from '../../../../Redux/store/tickets/ticketsListSlice';
import Chat from '../common/Chat';

const TicketChat = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadingCount, setLoadingCount] = React.useState(0);
  const [isRefreshing, setIsRefreshing] = React.useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(fetchTicketMessages({ ticket_id: id, page_size: 5000 }));
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
    } finally {
      setIsLoading(false);
      setLoadingCount(loadingCount + 1);
    }
  });

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 5000);

    return () => clearInterval(interval);
  }, [isRefreshing]);

  if (isLoading && loadingCount === 0) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-[60vh] place-content-center'>
          <div class='flex items-center gap-2 text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            loading...
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <Grid container lg={12} md={12} justifyContent='center'>
      <Grid item lg={12} md={12} className=''>
        <Chat isRefreshing={isRefreshing} setIsRefreshing={setIsRefreshing} />
      </Grid>
    </Grid>
  );
};

export default TicketChat;
