import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import {
  addToGroup,
  getGroupList,
} from '../../../../Redux/store/places/placesListSlice';
import { setAlert } from '../../../../Redux/store/app/appSlice';

import { usePlacesTranslation } from '../../i18n';
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from '@mui/material';
import { Business } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { blue } from '@mui/material/colors';
import { toast } from 'react-toastify';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, open } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [order, setOrder] = React.useState(null);
  const dispatch = useDispatch();
  const { groups } = useSelector((state) => state.places).places;

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(getGroupList({ flag: false, type: 1 }));
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  const handleClose = () => {
    onClose(null);
  };

  const handleListItemClick = (group_id) => {
    onClose(group_id, order, setOrder);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const t = usePlacesTranslation();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <DialogTitle id='simple-dialog-title'>
        {t('add_place_to_group')}
      </DialogTitle>
      <DialogContent>
        <TextField
          sx={{ marginTop: 2 }}
          fullWidth
          className='m-2'
          variant='outlined'
          disabled={isLoading}
          label={t('place_order')}
          type='number'
          value={order}
          onChange={(e) => setOrder(e.target.value)}
        />
        {isLoading && !groups ? (
          <CircularProgress />
        ) : (
          <List className='w-96'>
            {groups.map((group) => (
              <ListItem
                button
                onClick={() => handleListItemClick(group.group_id)}
                key={group.group_id}
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <Business />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={group.name} />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function AddPlaceToGroupDialog({ placeId }) {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  //   const [selectedValue, setSelectedValue] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const HandleSetSelectedGroup = async (group_id, order, setOrder) => {
    try {
      setIsLoading(true);
      await dispatch(
        addToGroup({ group_id: group_id, place_id: placeId, order: order })
      );
      setOpen(false);
      setOrder(null);
      toast.success(t('places_added_to_group_successfuly'));
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = (group_id, order, setOrder) => {
    if (group_id) {
      HandleSetSelectedGroup(group_id, order, setOrder);
    } else {
      setOpen(false);
    }
  };

  const t = usePlacesTranslation();

  return (
    <>
      <Button variant='outlined' color='primary' onClick={handleClickOpen}>
        {t('add_to_group')}
      </Button>
      <SimpleDialog open={open} onClose={handleClose} />
    </>
  );
}
