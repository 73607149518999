import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setAlert } from '../../../Redux/store/app/appSlice';
import {
  checkOrderInfo,
  checkOrderInfoSuccess,
  createNewOrder,
  fetchUserByMobileSuccess,
} from '../../../Redux/store/orders/ordersListSlice';
import Map from '../../tracking/components/common/new_order_map/Map';
import CreateOrderForm from '../components/common/CreateOrderForm';
import { useOrdersTranslation } from '../i18n';
import { sleep, validateMobile } from '../utils';

const CreateOrderPage = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [triggerrAction, setTriggerAction] = useState(0);
  const [carTypeId, setCarTypeId] = useState(1);
  const [mobile, setMobile] = useState('');
  const [noteToDriver, setNoteToDriver] = useState('');
  const [coordinates, setCoordinates] = useState({
    center: { lat: 15.5007, lng: 32.5599 },
    pickup: { lat: 15.5007, lng: 32.5599 },
    dropoff: { lat: 15.5007, lng: 32.5599 },
  });
  const [stage, setStage] = useState('pickup');
  const [payload, setPayload] = useState({
    car_type_id: carTypeId,
    mobile_number: validateMobile(mobile),
    pick_up_latitude: null,
    pick_up_longitude: null,
    drop_off_latitude: null,
    drop_off_longitude: null,
  });
  const t = useOrdersTranslation();

  const checkOrder = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(checkOrderInfo(payload));
      // sleep(100);
      setStage('checked');
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
    } finally {
      setIsLoading(false);
    }
  });

  const createOrder = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(createNewOrder(payload));
      tripController('done');

      toast.success(t('msg_created'));
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
    } finally {
      setIsLoading(false);
    }
  });

  const handlePickup = useCallback(() => {
    /*** handle the pickup process ***/
    setStage('check');
    setPayload({
      ...payload,
      pick_up_latitude: coordinates.pickup.lat,
      pick_up_longitude: coordinates.pickup.lng,
    });
  });
  const handleCheck = useCallback(async (typeOfTrip) => {
    /*** handle the pickup process ***/
    setStage(typeOfTrip);
    if (typeOfTrip === 'check with dropoff') {
      // console.log('if test');
      await setPayload({
        ...payload,
        car_type_id: carTypeId,
        order_type: 0,
        mobile_number: validateMobile(mobile),
        drop_off_latitude: coordinates.dropoff.lat,
        drop_off_longitude: coordinates.dropoff.lng,
      });
    } else {
      await setPayload({
        ...payload,
        car_type_id: carTypeId,
        order_type: 1,
        mobile_number: validateMobile(mobile),
        drop_off_latitude: coordinates.pickup.lat,
        drop_off_longitude: coordinates.pickup.lng,
      });
      dispatch(checkOrderInfoSuccess({}));
      setStage('open trip');
    }
    setTriggerAction(triggerrAction + 1);
    // checkOrder();
    // console.log(mobile, carTypeId);
  });
  const handleStartTrip = useCallback(() => {
    /*** handle the pickup process ***/
    setStage('start trip');
    setPayload({
      ...payload,
      mobile_number: validateMobile(mobile),
      driver_note: noteToDriver,
    });
    setTriggerAction(triggerrAction + 1);
  });

  const handleReset = useCallback(() => {
    /*** handle the pickup process ***/
    setStage('pickup');
    dispatch(checkOrderInfoSuccess({}));
    setPayload({
      car_type_id: carTypeId,
      mobile_number: validateMobile(mobile),
      pick_up_latitude: null,
      pick_up_longitude: null,
      drop_off_latitude: null,
      drop_off_longitude: null,
    });
  });

  const handleCancel = useCallback(() => {
    /*** handle the pickup process ***/
    setStage('pickup');
    setMobile('');
    setCarTypeId(1);
    setNoteToDriver('');
    dispatch(checkOrderInfoSuccess({}));
    dispatch(fetchUserByMobileSuccess(null));
    setPayload({
      car_type_id: carTypeId,
      mobile_number: validateMobile(mobile),
      pick_up_latitude: null,
      pick_up_longitude: null,
      drop_off_latitude: null,
      drop_off_longitude: null,
    });
  });

  const tripController = useCallback((stage) => {
    /*** control the trip flow and actions ***/
    switch (stage) {
      case 'pickup':
        handlePickup();
        break;
      case 'dropoff':
        handleCheck('check with dropoff');
        break;
      case 'open trip':
        handleCheck('check with open trip');

        break;
      case 'start':
        handleStartTrip();
        break;
      case 'cancel trip':
        handleCancel();
        break;
      case 'reset pickup':
        handleReset();
        break;
      case 'done':
        handleCancel();
        break;

      default:
        break;
    }
  });

  const formProps = {
    carTypeId: carTypeId,
    setCarTypeId: setCarTypeId,
    mobile: mobile,
    setMobile: setMobile,
    tripController: tripController,
    stage: stage,
    isLoading: isLoading,
    noteToDriver: noteToDriver,
    setNoteToDriver: setNoteToDriver,
    setCoordinates: setCoordinates,
    coordinates: coordinates,
  };

  const mapProps = {
    coordinates: coordinates,
    setCoordinates: setCoordinates,
    stage: stage,
  };

  useEffect(() => {
    if (stage === 'check with dropoff' || stage === 'checked') {
      checkOrder();
    } else if (stage === 'start trip') {
      // console.log(payload);
      createOrder();
    }
  }, [triggerrAction]);

  useEffect(() => {
    const changeData = () => {
      setPayload({
        ...payload,
        car_type_id: carTypeId,
        mobile_number: validateMobile(mobile),
      });
      setTriggerAction(triggerrAction + 1);
    };
    if (stage === 'check with dropoff' || stage === 'checked') {
      changeData();
    }
  }, [mobile, carTypeId]);

  return (
    <Grid
      container
      lg={12}
      md={12}
      className='h-atuo max-h-[100vh] relative bg-gray-100'
    >
      <Grid item lg={12} md={12} className='h-4/4 z-10'>
        <Map {...mapProps} />
      </Grid>
      <div className='p-4 max-w-md rounded-lg bg-gray-50 absolute right-4 top-1 shadow-lg shadow-black z-20 h-[90vh]'>
        <CreateOrderForm {...formProps} />
      </div>
    </Grid>
  );
};

export default CreateOrderPage;
