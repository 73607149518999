import React, { useState, useEffect } from 'react';
import PageHeading from '../../../components/common/PageHeading';
import { Avatar, Grid } from '@mui/material';
import default_image from '../../../assets/images/defult-pic.png';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
  fetchUserDetails,
  clearErrors,
} from '../../core/features/userIdentitySlice';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import DisplayDialog from './common/DisplayDialog';
import { useIdentityTranslation } from '../i18n';
function DetailsList(props) {
  const [isRefreshing, setIsRefreshing] = useState(0);

  const dispatch = useDispatch();
  const { id } = useParams();
  const t = useIdentityTranslation();
  const { details, error, isLoading } = useSelector((state) => {
    return state.userIdentitySlice;
  });

  const fetchData = async () => {
    await dispatch(fetchUserDetails({ id }));
  };

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
    dispatch(clearErrors());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [isRefreshing]);

  if (isLoading) {
    return <LoadingIndicator />;
  }
  return (
    <>
      <Grid>
        <PageHeading
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          heading1={t('identity')}
          heading2={t('users')}
          heading3={t('details')}
          previousUrl={'identity/users'}
          backBtn
        />
      </Grid>
      <Grid
        container
        lg={12}
        md={12}
        xs={12}
        justifyContent='center'
        className='relative  mt-7'
      >
        <Grid
          item
          lg={9}
          md={9}
          xs={9}
          className=' border-2  pt-16  pb-6 text-center bg-gradient-to-br from-gray-200 to-gray-100  rounded-xl w-[24vw] '
        >
          <Grid item lg={12} md={12} className='pb-4  flex justify-center'>
            <Avatar
              src={details.url || default_image}
              sx={{ width: 120, height: 120 }}
            />
          </Grid>

          <Grid item lg={12} md={12} className='p-3 flex justify-evenly'>
            <Grid item lg={2} md={2} className='flex justify-start'>
              <span className=' text-sm font-semibold '>{t('ID')}:</span>
            </Grid>

            <Grid item lg={7} md={7} sm={7} className='p-1 bg-white '>
              <span className='p-1 text-sm  font-serif w-full '>
                {details.user_id}
              </span>
            </Grid>
          </Grid>

          <Grid item lg={12} md={12} className='p-3 flex justify-evenly'>
            <Grid item lg={2} md={2} className='flex justify-start'>
              <span className=' text-sm font-semibold '>{t('full_name')}:</span>
            </Grid>

            <Grid item lg={7} md={7} sm={7} className='p-1 bg-white '>
              <span className='p-1 text-sm font-serif w-full '>
                {details.full_name}
              </span>
            </Grid>
          </Grid>

          <Grid item lg={12} md={12} className='p-3 flex justify-evenly'>
            <Grid item lg={2} md={2} className='flex justify-start'>
              <span className=' text-sm font-semibold '>{t('gender')}:</span>
            </Grid>

            <Grid item lg={7} md={7} sm={7} className='p-1 bg-white '>
              <span className='p-1 text-sm font-serif w-full'>
                {details.gender == 0
                  ? ''
                  : details.gender == 1
                  ? 'Male'
                  : 'Female'}
              </span>
            </Grid>
          </Grid>

          {/* item  */}
          <Grid item lg={12} md={12} className='p-3 flex justify-evenly'>
            <Grid item lg={2} md={2} className='flex justify-start'>
              <span className=' text-sm font-semibold '>{t('mobile')}:</span>
            </Grid>

            <Grid item lg={7} md={7} sm={7} className='p-1 bg-white '>
              <span className='p-1 text-sm  font-serif w-full'>
                {details.mobile}
              </span>
            </Grid>
          </Grid>

          {/* item  */}
          <Grid item lg={12} md={12} className='p-3 flex justify-evenly'>
            <Grid item lg={2} md={2} className='flex justify-start'>
              <span className=' text-sm font-semibold '>{t('email')}:</span>
            </Grid>

            <Grid item lg={7} md={7} sm={7} className='p-1 bg-white '>
              <span className='p-1 text-sm  font-serif w-full'>
                {details.email}
              </span>
            </Grid>
          </Grid>

          <Grid item lg={12} md={12} className='p-3 flex justify-evenly'>
            <Grid item lg={2} md={2} className='flex justify-start'>
              <span className=' text-sm font-semibold '>
                {' '}
                {t('block_reason')} :
              </span>
            </Grid>

            <Grid item lg={7} md={7} sm={7} className='p-1 bg-white '>
              <span className='p-1 text-sm font-serif w-full'>
                {details.block_reason}
              </span>
            </Grid>
          </Grid>

          <Grid item lg={12} md={12} className='p-3 flex justify-evenly'>
            <Grid item lg={2} md={2} className='flex justify-start'>
              <span className=' text-sm font-semibold '>
                {t('deleted_at')} :
              </span>
            </Grid>

            <Grid item lg={7} md={7} sm={7} className='p-1 bg-white '>
              <span className='p-1 text-sm font-serif w-full'>
                {details.deleted_at}
              </span>
            </Grid>
          </Grid>

          <Grid item lg={12} md={12} sm={12} className='px-2 pt-12'>
            {!details.deleted && (
              <DisplayDialog
                id={details.user_id}
                blocked={details.blocked}
                isRefreshing={isRefreshing}
                setIsRefreshing={setIsRefreshing}
              />
            )}
            {/* <Button variant='contained' color='primary'>
              Edit
            </Button> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default DetailsList;
