import React, { useCallback, createRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import { useDriverTranslation } from '../../i18n';
import { appDirection } from '../../../../shared_utils';
import { useTheme } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    margin: '0px 0px 10px 0px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function MobileFilter({
  payload,
  setPayload,
  setIsRefreshing,
  isRefreshing,
}) {
  const classes = useStyles();

  const [mobile, setMobile] = React.useState('');

  const handleSubmit = useCallback(async () => {
    setPayload({
      ...payload,
      user_mobile: mobile,
    });
    setIsRefreshing(isRefreshing + 1);
  });

  const handleClearField = useCallback((e) => {
    setMobile('');
  });

  const handleMobileChange = useCallback((e) => {
    setMobile(e.target.value);
  });
  const t = useDriverTranslation();
  const inputRef = createRef();
  const theme = useTheme();
  useEffect(() => {
    if (inputRef) {
      inputRef.current.dir = 'auto';
    }
  }, []);

  return (
    <Grid container lg={12} md={12} justifyContent className=''>
      <Grid
        container
        lg={12}
        md={12}
        justifyContent
        className='p-1 flex justify-between'
        dir={appDirection}
      >
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <TextField
            inputRef={inputRef}
            className=' bg-white'
            variant='outlined'
            margin='dense'
            InputLabelProps={{ shrink: true }}
            value={mobile}
            onChange={handleMobileChange}
            id='orderId'
            name='order_id'
            label={t('user_mobile')}
            type='text'
            size='small'
            fullWidth
            InputProps={
              theme.direction == 'ltr'
                ? {
                    startAdornment: (
                      <InputAdornment position='end'>+249</InputAdornment>
                    ),
                  }
                : {
                    endAdornment: (
                      <InputAdornment position='start'>249+</InputAdornment>
                    ),
                  }
            }
          />
        </Grid>
        <Grid
          item
          lg={1}
          md={1}
          sm={1}
          xs={1}
          className='flex justify-center h-full '
        >
          <Button
            variant='contained'
            className='h-full p-8'
            color='primary'
            type='submit'
            onClick={handleSubmit}
          >
            {t('apply')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
