import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { connect, useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  fetchDriverFiles,
  setError,
} from '../../../../Redux/store/drivers/driversListSlice';
import default_image from '../../../../assets/images/defult-pic.png';
import ImageDialog from '../common/ImageDialog';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import { ImageList, ImageListItem } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useDriverTranslation } from '../../i18n';
import { appDirection } from '../../../../shared_utils';
import { toast } from 'react-toastify';

// TODO: fix ui

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

function DriverFiles(props) {
  const t = useDriverTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { files } = useSelector((state) => state.drivers).driversList;
  const { driverId } = props;
  const theme = useTheme();

  //Dialog
  const [img, setImg] = useState(default_image);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenImage = (img_url) => {
    setImg(img_url);
    setIsOpen(true);
  };

  const fetchEverything = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all([dispatch(fetchDriverFiles(driverId))]);
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    } finally {
      setIsLoading(false);
    }
  });
  useEffect(() => {
    fetchEverything();
  }, [dispatch]);

  if (isLoading) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-screen place-content-center'>
          <div class='flex items-center  text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            loading...
          </div>
        </div>
      </Grid>
    );
  }

  if (!files || files.length === 0) {
    return (
      <Grid container justify='center'>
        <Typography variant='h6' gutterBottom>
          {t('NO_FILES')}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid container lg={12} md={12} spacing={2} dir={appDirection}>
      {/* dialog */}
      <ImageDialog open={isOpen} setOpen={setIsOpen} image_url={img} />
      <Grid
        item
        lg={6}
        md={6}
        className={theme.direction === 'ltr' ? 'border-l-4 ' : 'border-r-4 '}
      >
        <Grid className='p-4'>
          <h1>{t('avatar_image')}</h1>
        </Grid>

        <ImageList sx={{ width: '100%' }} cols={3}>
          {files.map((file) => {
            if (file.type_id === 1) {
              return (
                <ImageListItem key={file.id}>
                  <img
                    src={file.image_url || default_image}
                    alt={file.file_type.name}
                    // classNameName=' w-36 h-24 hover:cursor-pointer'
                    onClick={() => {
                      handleOpenImage(file.image_url);
                    }}
                  />
                </ImageListItem>
              );
            }
          })}
        </ImageList>
      </Grid>

      <Grid
        item
        lg={6}
        md={6}
        className={theme.direction === 'ltr' ? 'border-l-4 ' : 'border-r-4 '}
      >
        <Grid className='p-4'>
          <h1>{t('id_image')}</h1>
        </Grid>

        <ImageList sx={{ width: '100%' }} cols={3}>
          {files.map((file) => {
            if (file.type_id === 2) {
              return (
                <ImageListItem key={file.id}>
                  <img
                    src={file.image_url || default_image}
                    alt={file.file_type.name}
                    // classNameName=' w-36 h-24 hover:cursor-pointer'
                    onClick={() => {
                      handleOpenImage(file.image_url);
                    }}
                  />
                </ImageListItem>
              );
            }
          })}
        </ImageList>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        className={theme.direction === 'ltr' ? 'border-l-4 ' : 'border-r-4 '}
      >
        <Grid className='p-4'>
          <h1>{t('car_inside_image')}</h1>
        </Grid>
        <ImageList sx={{ width: '100%' }} cols={3}>
          {files.map((file) => {
            if (file.type_id === 3) {
              return (
                <ImageListItem key={file.id}>
                  <img
                    src={file.image_url || default_image}
                    alt={file.file_type.name}
                    // classNameName=' w-36 h-24 hover:cursor-pointer'
                    onClick={() => {
                      handleOpenImage(file.image_url);
                    }}
                  />
                </ImageListItem>
              );
            }
          })}
        </ImageList>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        className={theme.direction === 'ltr' ? 'border-l-4 ' : 'border-r-4 '}
      >
        <Grid className='p-4'>
          <h1>{t('car_outside_image')}</h1>
        </Grid>
        <ImageList sx={{ width: '100%' }} cols={3}>
          {files.map((file) => {
            if (file.type_id === 4) {
              return (
                <ImageListItem key={file.id}>
                  <img
                    src={file.image_url || default_image}
                    alt={file.file_type.name}
                    // classNameName=' w-36 h-24 hover:cursor-pointer'
                    onClick={() => {
                      handleOpenImage(file.image_url);
                    }}
                  />
                </ImageListItem>
              );
            }
          })}
        </ImageList>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        className={theme.direction === 'ltr' ? 'border-l-4 ' : 'border-r-4 '}
      >
        <Grid className='p-4'>
          <h1>{t('driver_license_image')}</h1>
        </Grid>
        <ImageList sx={{ width: '100%' }} cols={3}>
          {files.map((file) => {
            if (file.type_id === 5) {
              return (
                <ImageListItem key={file.id}>
                  <img
                    src={file.image_url || default_image}
                    alt={file.file_type.name}
                    // classNameName=' w-36 h-24 hover:cursor-pointer'
                    onClick={() => {
                      handleOpenImage(file.image_url);
                    }}
                  />
                </ImageListItem>
              );
            }
          })}
        </ImageList>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        className={theme.direction === 'ltr' ? 'border-l-4 ' : 'border-r-4 '}
      >
        <Grid className='p-4'>
          <h1>{t('car_search_certificate_image')}</h1>
        </Grid>
        <ImageList sx={{ width: '100%' }} cols={3}>
          {files.map((file) => {
            if (file.type_id === 6) {
              return (
                <ImageListItem key={file.id}>
                  <img
                    src={file.image_url || default_image}
                    alt={file.file_type.name}
                    // classNameName=' w-36 h-24 hover:cursor-pointer'
                    onClick={() => {
                      handleOpenImage(file.image_url);
                    }}
                  />
                </ImageListItem>
              );
            }
          })}
        </ImageList>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        className={theme.direction === 'ltr' ? 'border-l-4 ' : 'border-r-4 '}
      >
        <Grid className='p-4'>
          <h1>{t('emergency_transport_license_image')}</h1>
        </Grid>
        <ImageList sx={{ width: '100%' }} cols={3}>
          {files.map((file) => {
            if (file.type_id === 7) {
              return (
                <ImageListItem key={file.id}>
                  <img
                    src={file.image_url || default_image}
                    alt={file.file_type.name}
                    // classNameName=' w-36 h-24 hover:cursor-pointer'
                    onClick={() => {
                      handleOpenImage(file.image_url);
                    }}
                  />
                </ImageListItem>
              );
            }
          })}
        </ImageList>
      </Grid>
    </Grid>
  );
}

export default connect(null, {
  fetchDriverFiles,
  setError,
})(DriverFiles);
