const lang = {
  getLang: () => {
    return localStorage.getItem('lang') || 'en';
  },
  setLang: (lang) => {
    localStorage.setItem('lang', lang);
  },
  getDirection: function () {
    return this.getLang() === 'ar' ? 'rtl' : 'ltr';
  },
};

export default lang;
