import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import PageHeading from '../../../components/common/PageHeading';
import ReportsContainer from '../components/common/ReportsContainer';
import { fetchPlacesReports } from '../../../Redux/store/places/placesListSlice';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import StatusFilter from '../components/common/StatusFilter';
import { setAlert } from '../../../Redux/store/app/appSlice';
import { usePlacesTranslation } from '../i18n';
import { toast } from 'react-toastify';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

function ReportsPage(props) {
  const [payload, setPayload] = useState({
    status_id: '',
  });
  const [isRefreshing, setIsRefreshing] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams(
    createSearchParams({
      page: 0,
      pageSize: 10,
      status_id: '',
    })
  );

  const currentSearchParams = useMemo(
    () => ({
      page: searchParams.get('page'),
      pageSize: searchParams.get('pageSize'),
      status_id: searchParams.get('status_id'),
    }),
    [searchParams]
  );

  const setPage = (newPage) => {
    setSearchParams({
      ...currentSearchParams,
      page: newPage,
    });
    setIsRefreshing((prev) => prev + 1);
  };

  const onApply = () => {
    setSearchParams({
      ...currentSearchParams,
      ...payload,
      page: 0,
    });
    setIsRefreshing((prev) => prev + 1);
  };

  const dispatch = useDispatch();

  const { reports } = useSelector((state) => state.places).places;

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(fetchPlacesReports(currentSearchParams));
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  const t = usePlacesTranslation();

  useEffect(() => {
    fetchData();
  }, [isRefreshing]);

  return (
    <Grid container lg={12} md={12}>
      <PageHeading
        heading1={t('places')}
        heading2={t('reports')}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />

      <StatusFilter
        onApply={onApply}
        payload={payload}
        setPayload={setPayload}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />
      <ReportsContainer
        setPage={setPage}
        data={reports}
        page={Number(currentSearchParams.page)}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />
    </Grid>
  );
}

export default ReportsPage;
