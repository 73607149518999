import React, { useCallback, useEffect } from 'react';

import { Button, Grid, IconButton, InputBase, Paper } from '@mui/material';

import { validateMobileForQueryParams } from '../../utils';
import { usePlacesTranslation } from '../../i18n';
import { makeStyles } from '@mui/styles';
import { Clear } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    margin: '0px 0px 10px 0px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
  },
  input: {
    marginX: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function PlaceFilter({
  payload,
  setPayload,
  setIsRefreshing,
  isRefreshing,
  onApply,
}) {
  const classes = useStyles();

  const [mobile, setMobile] = React.useState('');

  const handleSubmit = useCallback(async () => {
    setPayload({
      ...payload,
      mobile: validateMobileForQueryParams(mobile),
    });
    onApply();
  });

  const handleClearField = useCallback((e) => {
    setMobile('');
  });

  const handleMobileChange = useCallback((e) => {
    setMobile(e.target.value);
  });

  const t = usePlacesTranslation();

  return (
    <Grid
      container
      lg={12}
      md={12}
      justifyContent='space-between'
      alignItems={'center'}
      className='px-2 py-1 my-2 bg-gray-100'
    >
      <Grid
        item
        lg={10}
        md={10}
        marginX={2}
        height={50}
        className='text-xs flex'
      >
        <div className='my-2 flex sm:flex-row flex-col'>
          {/* search field */}
          <div className='block relative'>
            <Paper
              component='div'
              className='appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block  w-full bg-white text-sm text-gray-700 focus:outline-none'
            >
              <InputBase
                className='mx-2 h-[40px]'
                id='mobileId'
                name='mobile'
                dir='ltr'
                placeholder={t('user_phone')}
                inputProps={{ 'aria-label': 'search google maps' }}
                value={mobile}
                onChange={handleMobileChange}
              />
              {mobile && (
                <IconButton
                  className={classes.iconButton}
                  aria-label='search'
                  onClick={handleClearField}
                >
                  <Clear className='text-red-800' />
                </IconButton>
              )}
              {/* </form> */}
            </Paper>
          </div>
        </div>
      </Grid>
      <Button
        className='h-[93%]'
        variant='contained'
        onClick={handleSubmit}
        color='primary'
      >
        {t('apply')}
      </Button>
    </Grid>
  );
}
