import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from '@mui/material';
import {
  AttachMoney,
  Edit,
  LocalTaxi,
  SettingsEthernet,
  Visibility,
} from '@mui/icons-material';
import Table from '../../../../../components/new-components/Table';
import { setCarTypeDetails } from '../../../../core/features/cartype-detail/cartypeDetailSlce';
import { useCarsTranslation } from '../../i18n';
import CarTypeDetails from '../CarTypeDetails';
import { toast } from 'react-toastify';

const CarsList = ({ type, setType, setIsRefreshing, isRefreshing }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { carTypes, isLoading } = useSelector((state) => state.carTypesList);

  const handleGoToDetails = async (carTypeDetails) => {
    try {
      await dispatch(setCarTypeDetails(carTypeDetails));
      setType('details');
    } catch (err) {
      console.error(err);
    }
  };

  const t = useCarsTranslation();

  const cols = [
    {
      field: 'carType',
      headerName: t('car_type'),
      headerClassName: ' bg-gray-100   w-full',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-sm font-bold text-gray-700 p-1 '
        >
          <Grid item lg={8} md={8}>
            <Grid item lg={12} md={12} xs={12}>
              {params.value.car_name_en}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'standardRide',
      headerName: t('standard_ride'),
      headerClassName: ' bg-gray-100 ',
      disableColumnMenu: true,
      flex: 1,
      width: 130,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs p-1 '
        >
          <Grid item lg={8} md={8}>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className='text-xs font-extrabold text-gray-700  '
            >
              {params.value.price_km} {t('SDG')}
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className='text-xs  text-gray-500 '
            >
              {t('min_price')}: {params.value.minimum_fare} {t('SDG')}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'shortRide',
      headerName: t('short_ride'),
      headerClassName: ' bg-gray-100 ',
      disableColumnMenu: true,
      flex: 1,
      width: 130,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs p-1 '
        >
          <Grid item lg={8} md={8}>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className='text-xs font-extrabold text-gray-700  '
            >
              {params.value.short_distance_price_per_km} {t('SDG')}
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className='text-xs  text-gray-500 '
            >
              {t('distance')}: {params.value.short_distance_km} {t('KM')}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'midRide',
      headerName: t('mid_ride'),
      headerClassName: ' bg-gray-100 ',
      disableColumnMenu: true,
      flex: 1,
      width: 130,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs p-1 '
        >
          <Grid item lg={8} md={8}>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className='text-xs font-extrabold text-gray-700  '
            >
              {params.value.mid_distance_price_per_km} {t('SDG')}
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className='text-xs  text-gray-500 '
            >
              {t('distance')}: {params.value.mid_distance_km} {t('KM')}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'openRidekm',
      headerName: t('open_km'),
      headerClassName: ' bg-gray-100 ',
      disableColumnMenu: true,
      flex: 1,
      width: 130,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs p-1 '
        >
          <Grid item lg={8} md={8}>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className='text-xs font-extrabold text-gray-700  '
            >
              {params.value.open_trip_price_km} {t('SDG_KM')}
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className='text-xs  text-gray-500 '
            >
              {t('min_price')}: {params.value.open_trip_minimum_fare} {t('SDG')}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'openRidemin',
      headerName: t('open_min'),
      headerClassName: ' bg-gray-100 ',
      flex: 1,
      disableColumnMenu: true,
      width: 130,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs p-1 '
        >
          <Grid item lg={8} md={8}>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className='text-xs font-extrabold text-gray-700  '
            >
              {params.value.open_trip_minute_price} {t('SDG_MIN')}
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className='text-xs  text-gray-500 '
            >
              {t('min_price')}: {params.value.open_trip_backup_minute_price}{' '}
              {t('SDG')}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'TypesOfCarsAllowed',
      headerName: t('cars_allowed'),
      headerClassName: ' bg-gray-100 ',
      flex: 1,
      disableColumnMenu: true,
      width: 130,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          justifyContent='center'
          className='text-xs pt-2 grid grid-cols-4 grid-rows-2 '
        >
          {handleAllowedCars(params.value.allowed_car_type_ids).map((item) => {
            return (
              <Grid className='m-1  '>
                <div
                  className={`${
                    item === ' Share' || item === ' شير'
                      ? 'bg-yellow-100'
                      : item === 'One  ' || item === ' One'
                      ? 'bg-blue-100 '
                      : item === ' Go' || item === 'Go  '
                      ? 'bg-green-100'
                      : item === ' Star' || item === 'Star  '
                      ? 'bg-purple-200'
                      : item === 'XL'
                      ? 'bg-red-200'
                      : item === ' Bus'
                      ? 'bg-gray-300'
                      : item === '-'
                      ? 'text-xl '
                      : item === ' Box' || item === ' بوكس'
                      ? 'bg-fuchsia-200'
                      : item === 'Raksha' || item === 'ركشة'
                      ? 'bg-cyan-100'
                      : 'bg-gray-100'
                  } p-1 rounded-md font-bold`}
                >
                  {item}
                </div>
              </Grid>
            );
          })}
        </Grid>
      ),
    },

    {
      field: 'action',
      headerName: t('view'),
      headerClassName: ' bg-gray-100   w-full',
      disableColumnMenu: true,
      flex: 0.5,
      renderCell: (params) => (
        <Button
          color='primary'
          sx={{
            fontWeight: 'bold',
          }}
          onClick={() => {
            handleGoToDetails(params.value);
          }}
        >
          <Edit fontSize='small' />
        </Button>
      ),
    },
  ];
  const handleAllowedCars = (value) => {
    const carIds = value
      .map((item) => {
        return carTypes
          .find((id) => id.id === item)
          .car_name.replace(t('Lemon'), '');
      })
      .sort();

    if (carIds.length === 0) {
      return ['-'];
    }
    return carIds;
  };

  const rowsData = carTypes.map((carType) => {
    return {
      key: carType.id,
      carType: carType,
      TypesOfCarsAllowed: carType,
      action: carType,
      CreatedAt: carType,
      id: carType.id,
      midRide: carType,
      openRidekm: carType,
      openRidemin: carType,
      shortRide: carType,
      standardRide: carType,
    };
  });

  const tableProps = {
    isLoading,
    pagination: false,
    loaderType: 'page',
    rowsData,
    cols,
  };

  const { carTypeDetails } = useSelector((state) => state.carTypeDetails);

  return (
    <>
      {type == 'table' ? (
        <Table {...tableProps} />
      ) : (
        <CarTypeDetails
          carTypeDetails={carTypeDetails}
          setType={setType}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      )}
    </>
  );
};

export default CarsList;
