import http from 'axios';

export default {
	fetchAll: async ({ page, pageSize }) => {
		try {
			const response = await http.get(`/inbox_messages?page_number=${page}&page_size=${pageSize}`);
			return response.data;
		} catch (error) {
			return error;
		}
	},

	createMessage: async formData => {
		try {
			const response = await http.post('/inbox_messages', formData, {
				headers: { 'Content-Type': 'multipart/form-data' }
			});
			return response.data;
		} catch (error) {
			return error;
		}
	},

	uploadImage: async file => {
		try {
			const response = await http.post('/inbox_messages/image', file, {
				headers: { 'Content-Type': 'multipart/form-data' }
			});
			return response.data;
		} catch (error) {
			return error;
		}
	}
};
