import http from '../http';

const sendMessage = async (payload) => {
  const baseURL = process.env.REACT_APP_TICKETS_API;
  try {
    await http.post(`${baseURL}/ticket_messages`, payload);
  } catch (err) {
    throw err.response;
  }
};

export default sendMessage;
