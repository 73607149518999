import React from 'react';
import { Route } from 'react-router-dom';
import AddEventPage from './addEvent/pages/AddEventPage';
import ChooseProviderPage from './addEvent/pages/ChooseProviderPage';
import UpdateEventPage from './addEvent/pages/UpdateEventPage';
import EventsListPage from './eventsList/EventsListPage';
import EvnetDetailsPgae from './eventsProviders/pages/EvnetDetailsPgae';
import GetProvidersPage from './eventsProviders/pages/get-providers';
import ProviderDetailsPage from './eventsProviders/pages/provider-details';
import EventTicketsPage from './eventsTickets/EventTicketsPage/';
import TikcetDetailsPage from './eventsTickets/ticketDetailsPage';

export default function Events() {
  return (
    <Route path='/events' key={'events'}>
      <Route path='/events/providers/' element={<GetProvidersPage />} />
      <Route path='/events/providers/' element={<GetProvidersPage />} />
      <Route path='/events/providers/:id' element={<ProviderDetailsPage />} />
      <Route path='/events/add/:id' element={<AddEventPage />} />
      <Route path='/events/add/' element={<ChooseProviderPage />} />
      <Route path='/events/update/:id' element={<UpdateEventPage />} />
      <Route path='/events/:id' element={<EvnetDetailsPgae />} />
      <Route path='/events/' element={<EventsListPage />} />
      <Route path='/events/tickets/' element={<EventTicketsPage />} />
      <Route path='/events/tickets/:id' element={<TikcetDetailsPage />} />
      <Route
        path='/events/add-to-place/:place_id'
        element={<EventsListPage />}
      />
    </Route>
  );
}
