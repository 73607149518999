import http from '../http';

const assignSupportUser = async (payload) => {
  const baseURL = process.env.REACT_APP_TICKETS_API;
  try {
    await http.put(`${baseURL}/tickets/assign_support_user`, {
      ticket_id: payload.id,
      support_user_id: payload.support_user_id,
    });
  } catch (err) {
    throw err.response;
  }
};

export default assignSupportUser;
