import React, { useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import {
  fetchDriverDispatches,
  setError,
} from '../../../../Redux/store/dispatches/dispatchesListSlice';

import DispatchesTable from '../common/DispatchesTable';

import { CircularProgress, Grid } from '@material-ui/core';
import { checkAuth } from '../../utils';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import { useDriverTranslation } from '../../i18n';
import { toast } from 'react-toastify';

function DriverDispatches(props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadingCount, setLoadingCount] = React.useState(0);
  const { dispatches } = useSelector(
    (state) => state.dispatches
  ).dispatchesList;
  const { driverId } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const actions = { view: true, baseUrl: '/dispatches' };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    async function fetchEverything() {
      try {
        setIsLoading(true);
        await Promise.all([
          dispatch(
            fetchDriverDispatches({ driverId, page, pageSize: rowsPerPage })
          ),
        ]);
      } catch (err) {
        if (err.status === 401) {
          localStorage.removeItem('token');
          window.location.reload();
        }
        toast.error(err.data.message);
      } finally {
        setIsLoading(false);
        setLoadingCount(1);
      }
    }

    fetchEverything();
  }, [dispatch, page, rowsPerPage]);

  if (isLoading && loadingCount === 0) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-screen place-content-center'>
          <div class='flex items-center gap-2 text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            loading...
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <Grid container lg={12} md={12} xs={10} spacing={3} justifyContent='center'>
      <Grid item lg={12} md={12} xs={12}>
        <DispatchesTable
          data={dispatches}
          driverId={driverId}
          loading={isLoading}
          setPage={setPage}
          key={'m'}
        />
      </Grid>
    </Grid>
  );
}
export default connect(null, { fetchDriverDispatches, setError })(
  DriverDispatches
);
