import { Breadcrumbs, Grid, IconButton } from '@mui/material';
import {
  ArrowBack,
  Backspace,
  East,
  NavigateBefore,
  NavigateNext,
  Refresh,
} from '@mui/icons-material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import GET_CURRENT_LANG from '../../shared_utils';

const PageHeading = ({
  heading1,
  previousUrl,
  heading2,
  heading3,
  heading4,
  heading5,
  backBtn,
  isRefreshing,
  setIsRefreshing,
}) => {
  const navigate = useNavigate();
  return (
    <Grid
      container
      lg={12}
      md={12}
      spacing={2}
      alignItems={'center'}
      className='pr-4 py-1'
    >
      <Grid item>
        {backBtn &&
          (GET_CURRENT_LANG() === 'en' ? (
            <ArrowBack
              color='primary'
              fontSize='medium'
              className='  hover:text-blue-500 hover:cursor-pointer hover:animate-pulse mr-2'
              onClick={() => navigate(-1)}
            />
          ) : (
            <East
              color='primary'
              fontSize='medium'
              className='  hover:text-blue-500 hover:cursor-pointer hover:animate-pulse ml-2'
              onClick={() => navigate(-1)}
            />
          ))}
        {setIsRefreshing && (
          <Refresh
            color='primary'
            fontSize='medium'
            className='  hover:text-blue-500 hover:cursor-pointer hover:animate-pulse '
            onClick={() => setIsRefreshing(isRefreshing + 1)}
          />
        )}
      </Grid>

      <Grid item>
        <Breadcrumbs
          separator={
            GET_CURRENT_LANG() === 'en' ? (
              <NavigateNext fontSize='small' />
            ) : (
              <NavigateBefore fontSize='small' />
            )
          }
          aria-label='breadcrumb'
        >
          <span className='text-xs'>{heading1}</span>
          {heading3 ? (
            <span
              className='text-xs hover:text-sm  hover:text-blue-500 hover:cursor-pointer hover:animate-pulse'
              onClick={() => navigate(`/${previousUrl}`)}
            >
              {heading2}
            </span>
          ) : (
            <span className='text-xs font-bold text-[#1565c0]'>{heading2}</span>
          )}

          {heading3 && (
            <span className='text-xs font-bold text-[#1565c0]'>{heading3}</span>
          )}
          {heading4 && (
            <span className='text-xs font-bold text-[#1565c0] '>
              {heading3}
            </span>
          )}
          {heading5 && (
            <span className='text-xs font-bold text-[#1565c0]'>{heading3}</span>
          )}
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};

export default PageHeading;
