import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { usePlacesTranslation } from '../../i18n';

import PlaceFrom from './placeForm';

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const t = usePlacesTranslation();

  const DialogTitlee = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <DialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant='h6'>{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  });
  return (
    <>
      <Button
        variant='outlined'
        className='mt-4'
        color='warning'
        onClick={handleClickOpen}
      >
        {t('edit')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitlee
          id='form-dialog-title'
          onClose={handleClose}
        ></DialogTitlee>
        <DialogContent>
          <PlaceFrom open={open} setOpen={setOpen} />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}
