import { createSlice } from '@reduxjs/toolkit';
import inboxMessagesApi from './api';

const initialState = {
	inboxMessages: [],
	isLoading: false
};

const inboxMessagesSlice = createSlice({
	name: 'inboxMessages',
	initialState,
	reducers: {
		setLoadingState: (state, action) => {
			state.isLoading = action.payload;
		},
		inboxMessagesSuccess: (state, action) => {
			state.inboxMessages = action.payload;
		},
		setError: (state, actions) => {
			state.error = actions.payload.message;
		}
	}
});

export const { inboxMessagesSuccess, setError, setLoadingState } = inboxMessagesSlice.actions;

export const fetchInboxMessages = options => async dispatch => {
	try {
		dispatch(setLoadingState(true));
		const inboxMessages = await inboxMessagesApi.fetchAll(options);
		dispatch(inboxMessagesSuccess(inboxMessages));
	} catch (error) {
		dispatch(setError(error));
	}
};

export const createMessage = options => async dispatch => {
	try {
		await inboxMessagesApi.createMessage(options);
	} catch (error) {
		dispatch(setError(error));
	}
};

export const uploadMessageImage = file => async dispatch => {
	try {
		await inboxMessagesApi.uploadImage(file);
	} catch (error) {
		dispatch(setError(error));
	}
};

export default inboxMessagesSlice.reducer;
