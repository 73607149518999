/* eslint-disable no-unreachable */
import { Route, Routes } from 'react-router-dom';
import { DispatchesRoutes } from './modules/dispatches/Routes';
import { DriversRoutes } from './modules/drivers/Routes';
import { OrdersRoutes } from './modules/orders/Routes';
import img from './assets/images/soon2.jpg';
import { UsersRoutes } from './modules/users/Routes';
import { ticketsRoutes } from './modules/tickets/Routes';
import { AppRoutes } from './modules/app/Routes';
import { PlacesRoutes } from './modules/places/Routes';
import { CarTypesRoutes } from './modules/car-types/Routes';
import { ShareTripRoutes } from './modules/lemon-share-trip/Routes';

import { DemandSupplyRoutes } from './modules/demand_supply/Routes';
import Dispatch from './dispatch/views/Routes';
import Events from './events/views/Routes';
import Identity from './identity/views/Routes';

export function MainRoutes() {
  return (
    <Routes>
      <Route
        path='/'
        index
        element={
          <div className=' flex-col flex justify-center items-center'>
            <img src={img} alt='...' className='w-2/4 h-2/4' />{' '}
            <p className='text-4xl text-gray-600 font-bold'>Home Page</p>
          </div>
        }
      />
      {DriversRoutes()}
      {DispatchesRoutes()}
      {OrdersRoutes()}
      {UsersRoutes()}
      {ticketsRoutes()}
      {AppRoutes()}
      {PlacesRoutes()}
      {CarTypesRoutes()}
      {ShareTripRoutes()}
      {DemandSupplyRoutes()}
      {/* new routes */}
      {Dispatch()}
      {Events()}
      {Identity()}
    </Routes>
  );
}
