import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import {
  fetchDriverPerformance,
  fetchDriverWeeklyPerformance,
  fetchDriverHourlyPerformance,
  setError,
} from '../../../../Redux/store/drivers/driversListSlice';
import PerformanceCards from '../common/PerformanceCards';
import PerformanceTabControllers from '../common/PerformanceTabControllers';
import PerformanceTabList from '../common/PerformanceTabList';
import moment from 'moment';
import Chart from '../common/Chart';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import { appDirection } from '../../../../shared_utils';
import { toast } from 'react-toastify';

function DriverPerformance(props) {
  const { driverId } = props;
  const now = moment();
  const [type, setType] = React.useState('Daily');
  const [previous, setPrevious] = React.useState(1);
  const [refrashState, setRefrashState] = React.useState(1);
  const [dailypayload, setDailyPayload] = React.useState({
    from: moment().startOf('day').utc().toISOString(),
    to: moment().endOf('day').utc().toISOString(),
  });
  const [weeklypayload, setWeeklyPayload] = React.useState({
    from: moment().startOf('week').utc().toISOString(),
    to: moment().endOf('week').utc().toISOString(),
  });
  const [isloading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { performance, weeklyPerformance, hourlyPerformance } = useSelector(
    (state) => state.drivers
  ).driversList;

  const controllersProps = {
    setType: setType,
    type: type,
    refrashState: refrashState,
    setRefrashState: setRefrashState,
    setDailyPayload: setDailyPayload,
    setWeeklyPayload: setWeeklyPayload,
    dailypayload: dailypayload,
    weeklypayload: weeklypayload,
    previous: previous,
    setPrevious: setPrevious,
  };

  const dailyPreformance = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        dispatch(fetchDriverPerformance({ id: driverId, ...dailypayload })),
        dispatch(
          fetchDriverHourlyPerformance({ id: driverId, ...dailypayload })
        ),
      ]);
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    } finally {
      setIsLoading(false);
    }
  });

  const weeklyPreformance = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        dispatch(fetchDriverPerformance({ id: driverId, ...weeklypayload })),
        dispatch(
          fetchDriverWeeklyPerformance({ id: driverId, ...weeklypayload })
        ),
      ]);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    console.log(moment('2022-06-13').endOf('day').utc().toISOString());

    if (type === 'daily') {
      dailyPreformance();
    } else {
      weeklyPreformance();
    }
  }, [type, refrashState]);

  if (isloading) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-screen place-content-center'>
          <div class='flex items-center gap-2 text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            loading...
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <Grid
      container
      lg={12}
      md={12}
      space={4}
      justifyContent='center'
      // dir={appDirection}
    >
      <Grid item lg={6} md={6}>
        <PerformanceTabControllers {...controllersProps} />
      </Grid>
      <Grid item lg={12} md={12} className='pt-2'>
        <PerformanceCards performance={performance} />
      </Grid>
      <Grid
        container
        lg={12}
        md={12}
        justifyContent='space-between'
        className='pt-4 '
      >
        <Grid item lg={8} md={8} className='bg-white'>
          <Chart
            type={type}
            data={type === 'daily' ? hourlyPerformance : weeklyPerformance}
          />
        </Grid>
        <Grid item lg={3} md={3}>
          <PerformanceTabList performance={performance} />
        </Grid>
      </Grid>
    </Grid>
  );
}
export default connect(null, {
  fetchDriverPerformance,
  fetchDriverWeeklyPerformance,
  fetchDriverHourlyPerformance,
  setError,
})(DriverPerformance);
