import React from 'react';
import { Provider } from 'react-redux';
import dispatchStore from '../../../core/app/store';
import ListContainer from '../components/ListContainer';

const CarsTypesPage = () => {
  return (
    <Provider store={dispatchStore}>
      <ListContainer />
    </Provider>
  );
};

export default CarsTypesPage;
