import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Pane,
  Circle,
  Polyline,
  useMap,
  useMapEvents,
} from 'react-leaflet';
import { useSelector } from 'react-redux';
import './Map.css';
import PinDropIcon from '@material-ui/icons/PinDrop';
import L, { map } from 'leaflet';

const MapEvents = ({ setCoordinates, coordinates }) => {
  useMapEvents({
    click(e) {
      const newCoordinates = e.latlng;
      // console.log(newCoordinates);
      setCoordinates(newCoordinates);
    },
  });
  return false;
};

const Map = ({ coordinates, setCoordinates }) => {
  // const [coordinates, setCoordinates] = useState({
  //     latitude: 15.5007,
  //     lng: 32.5599,
  //   });
  return (
    <MapContainer
      center={[coordinates.lat, coordinates.lng] || []}
      zoom={11}
      scrollWheelZoom={true}
      style={{ height: '100%' }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {/* dispatch location */}
      <Marker
        position={[coordinates.lat, coordinates.lng] || []}
        // icon={car}
      >
        <Popup closeButton={false}>Pickup</Popup>
      </Marker>
      <MapEvents setCoordinates={setCoordinates} coordinates={coordinates} />
    </MapContainer>
  );
};

export default Map;
