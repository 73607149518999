import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles'; //!
import { blue } from '@material-ui/core/colors'; //!
import { useSelector, useDispatch } from 'react-redux';
import {
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { Person } from '@mui/icons-material';
import { assignSupportUser } from '../../../../core/features/drivers-ticket-details/driverTicketDetailsSlice';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

const AssignDialog = (props) => {
  const classes = useStyles();
  const {
    handleCloseAction,
    isOpen,
    ticketId,
    isRefreshing,
    setIsRefreshing,
    handleOpenAssignDialog,
  } = props;
  const { supportUsers } = useSelector((state) => state.driversTicketsList);

  const dispatch = useDispatch();

  const handleAssignSupportUser = async (userId) => {
    await dispatch(assignSupportUser(userId));
    setIsRefreshing(isRefreshing + 1);
  };

  const handleListItemClick = (userId) => {
    handleAssignSupportUser(userId);
    handleCloseAction();
  };

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        size='small'
        onClick={(e) => handleOpenAssignDialog(ticketId)}>
        assign
      </Button>
      <Dialog
        onClose={handleCloseAction}
        aria-labelledby='simple-dialog-title'
        open={isOpen}
        fullWidth>
        <DialogTitle id='simple-dialog-title'>Assign Support User</DialogTitle>
        <List>
          {supportUsers.map((support_user) => (
            <ListItem
              button
              onClick={() => handleListItemClick(support_user.user_id)}
              key={support_user.user_id}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <Person />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={support_user.full_name} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    </>
  );
};

export default AssignDialog;
