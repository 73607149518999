import React, { useState, useCallback } from 'react';

import { Empty } from '../../../drivers/components/common/Empty';
import {
  DataGrid,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import SimpleBackdrop from '../../../../components/common/LoadingIndicator';

import CommentDialog from './CommentDialog';
import DeleteDialog from './DeleteDialog';
import { useEffect } from 'react';
import { formatDate } from '../../../drivers/utils';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import { getCommentReplies } from '../../../../Redux/store/places/placesListSlice';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { usePlacesTranslation } from '../../i18n';
import { Button, CircularProgress, Grid, Rating } from '@mui/material';
import { ThumbUp } from '@mui/icons-material';
import CustomPagination from '../../../../components/new-components/CustomPagination';
import { toast } from 'react-toastify';

function RepliesList({
  isLoading,
  setIsLoading,
  page,
  setPage,
  isRefreshing,
  setIsRefreshing,
}) {
  const [open, setOpen] = useState(false);
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { replies } = useSelector((state) => state.places).places;

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(
        getCommentReplies({
          page: page,
          pageSize: 10,
          isDeleted: '',
          id: id,
        })
      );
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  const t = usePlacesTranslation();

  useEffect(() => {
    fetchData();
  }, [isRefreshing, page]);

  const cols = [
    {
      field: 'user',
      headerName: t('user'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className='font-serif font-semibold pl-2  '
        >
          <Button
            onClick={() => {
              navigate(`/users/${params.value.user_id}`);
            }}
          >
            <Grid
              container
              lg={12}
              md={12}
              justifyContent='center'
              className='flex '
            >
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                className='pr-2 text-sm font-medium block'
              >
                {params.value.full_name}
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                className=' text-sm font-medium'
              >
                {params.value.mobile}
              </Grid>
            </Grid>
          </Button>
        </Grid>
      ),
    },
    {
      field: 'comment',
      headerName: t('comment'),
      headerClassName: ' bg-gray-100 text-center w-full ',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className=' text-sm font-serif font-semibold pl-2'
        >
          <Grid item lg={12} md={12} xs={12} className='truncate'>
            {params.value.comment}
          </Grid>
          <Grid item lg={12} md={12} xs={12} className=''>
            <Rating value={params.value.rating} disabled />
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'likes_count',
      headerName: t('likes'),
      headerClassName: ' bg-gray-100 text-center w-full ',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className=' text-sm font-serif font-semibold pl-2'
        >
          <Grid item lg={12} md={12} xs={12} className=''>
            {/* {params.value.replys} */}
          </Grid>
          <Grid item lg={12} md={12} xs={12} className=''>
            {/* <ThumbUpIcon fontSize="small" /> */}
            <span className='p-2'> {params.value.likes_count}</span>
            <ThumbUp fontSize='small' />
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'status',
      headerName: t('status'),
      headerClassName: ' bg-gray-100 text-center w-full ',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className=' text-xs font-serif font-semibold pl-2'
        >
          <Grid item lg={12} md={12} xs={12} className=''>
            {params.value.is_deleted ? (
              <Grid className='text-red-600'>Deleted</Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'date',
      headerName: t('date'),
      headerClassName: ' bg-gray-100 text-center w-full ',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className=' text-sm font-serif font-semibold pl-2'
        >
          <Grid item lg={12} md={12} xs={12} className=''>
            {formatDate(params.value.created_at)}
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'comment_id',
      headerName: t('view'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 0.5,
      renderCell: (params) => (
        <>
          {/* <VisibilityOutlinedIcon fontSize="small" /> */}
          <CommentDialog
            comment={params.value}
            open={open}
            setOpen={setOpen}
            isRefreshing={isRefreshing}
            setIsRefreshing={setIsRefreshing}
          />

          <DeleteDialog
            open={open}
            setOpen={setOpen}
            id={params.value.comment_id}
            isRefreshing={isRefreshing}
            setIsRefreshing={setIsRefreshing}
          />
        </>
      ),
    },
  ];

  const rowsData = replies.map((item) => {
    return {
      id: item.comment_id,
      user: item.user,
      comment: item,
      comment_id: item,
      date: item,
      likes_count: item,
      status: item,
    };
  });

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className='flex mt-2 h-auto w-full bg-white'>
      <div className=' flex-grow'>
        <DataGrid
          disableColumnFilter
          autoHeight
          density='standard'
          rowHeight={60}
          loading={isLoading}
          rows={rowsData}
          page={page}
          columns={cols}
          pageSize={1}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => setPage(newPage)}
          components={{
            Toolbar: null,
            Pagination: () => CustomPagination(isLoading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: SimpleBackdrop,
          }}
        />
      </div>
    </div>
  );
}

export default RepliesList;
