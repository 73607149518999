import React, { useCallback, useState } from 'react';
import {
  DataGrid,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';

import {
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Empty } from '../../drivers/components/common/Empty';
import { useSelector } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom';

import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { AssignmentInd, PhoneAndroid } from '@mui/icons-material';
import { AccountCircle } from '@mui/icons-material';
import { formatDate } from '../../drivers/utils';
import { Visibility } from '@mui/icons-material';
import { Flag } from '@mui/icons-material';
import AssignDialog from './common/AssignDialog';
import { useTicketTranslation } from '../i18n';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';
import { appDirection } from '../../../shared_utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  action: {
    '&:hover': {
      backgroundColor: '#c7d2fe',
      cursor: 'pointer',
    },
  },
}));

function CustomPagination(loading) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid
      container
      className=' flex justify-center relative space-x-4 py-4 w-full'
      spacing={1}
    >
      <Grid item>
        <Button
          disabled={page === 0 || loading ? true : false}
          variant='contained'
          color='primary'
          onClick={() => apiRef.current.setPage(page - 1)}
        >
          {t('previous')}
        </Button>
      </Grid>

      <Grid item>
        <Button
          disabled={loading}
          variant='contained'
          color='primary'
          onClick={() => apiRef.current.setPage(page + 1)}
        >
          {t('next')}
        </Button>
      </Grid>
      <Grid item>
        {loading ? (
          <CircularProgress
            className={
              theme.direction === 'ltr' ? 'absolute right-8' : 'absolute left-8'
            }
          />
        ) : (
          <span
            className={
              '' + theme.direction === 'ltr'
                ? 'p-2 px-4 rounded-full bg-gray-300  font-bold absolute right-8'
                : 'p-2 px-4 rounded-full bg-gray-300  font-bold absolute left-8'
            }
          >
            {page + 1}
          </span>
        )}
      </Grid>
    </Grid>
  );
}

function CustomToolbar() {
  return <></>;
}

const TicketsList = ({
  type,
  setPage,
  isLoading,
  isRefreshing,
  page,
  setIsRefreshing,
}) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const t = useTicketTranslation();

  //assign dialog configuration
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState(0);
  const handleOpenAssignDialog = useCallback((ticketId) => {
    setSelectedTicketId(ticketId);
    setIsOpen(true);
  });

  const handleCloseAction = useCallback(async () => {
    setIsOpen(false);
  });

  const { arrayOfTickets } = useSelector((state) => state.tickets).ticketsList;

  const handleStatusId = useCallback((id) => {
    switch (id) {
      case 0:
        return <Chip size='small' label={t('new')} />;
      case 1:
        return <Chip size='small' label={t('under_process')} />;
      case 2:
        return <Chip size='small' label={t('closed')} />;

      default:
        break;
    }
  });

  const handlePriority = useCallback((id) => {
    switch (id) {
      case 1:
        return <Flag fontSize='small' className='text-green-600' />;
      case 2:
        return <Flag fontSize='small' className='text-yellow-200' />;
      case 3:
        return <Flag fontSize='small' className='text-orange-400' />;
      case 4:
        return <Flag fontSize='small' className='text-red-700' />;
      default:
        return <span className='text-xs font-serif font-semibold'>.....</span>;
    }
  });

  const cols = [
    {
      field: 'clientInfo',
      headerName: t(type),
      headerClassName: ' bg-gray-100 font-bold ',

      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs p-1 text-[#1769aa]  hover:cursor-pointer'
        >
          <Grid item lg={8} md={8}>
            <Link
              to={
                type === 'drivers'
                  ? `/drivers/${params.value.user_id}/identity`
                  : `/users/${params.value.user_id}`
              }
            >
              <Grid item lg={12} md={12} xs={12}>
                <AccountCircle fontSize='small' className='text-gray-500' />{' '}
                {params.value.user.full_name}
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <PhoneAndroid
                  fontSize='small'
                  className='text-gray-500 truncate ...'
                />{' '}
                {params.value.user.mobile}
              </Grid>
            </Link>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'orderId',
      headerName: t('order_id'),
      headerClassName: ' bg-gray-100 font-bold',
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid container lg={12} md={12} className='text-xs pr-4'>
          <Grid item lg={12} md={12}>
            <span className=' text-xs text-gray-700 font-mono font-semibold w-full text-center'>
              {params.value.reference_id ? params.value.reference_id : '...'}
            </span>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'ticketId',
      headerName: t('ticket_id'),
      headerClassName: ' bg-gray-100 font-bold',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid container lg={12} md={12} className='text-xs pr-4'>
          <Grid item lg={12} md={12}>
            <span className=' text-xs text-gray-700 font-mono font-semibold w-full text-center'>
              {params.value.ticket_id ? params.value.ticket_id : '...'}
            </span>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'category',
      headerName: t('category'),
      headerClassName: ' bg-gray-100 font-bold',
      flex: 2.5,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid container lg={12} md={12} className='text-xs pr-4'>
          <Grid item lg={12} md={12}>
            <p className=' text-xs text-gray-700 font-mono font-semibold w-full '>
              {params.value.parent_category.category_name}
            </p>
          </Grid>
          <Grid item lg={12} md={12} className='pt-1'>
            <p className=' text-gray-700 text-xs truncate ...'>
              {params.value.child_category.category_name}
            </p>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'status',
      headerName: t('status'),
      headerClassName: ' bg-gray-100 font-bold',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          justifyContent='center'
        >
          <Grid item lg={12} md={12} xs={12}>
            {handleStatusId(params.value.status_id)}
            {handlePriority(params.value.priority)}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'support_user',
      headerName: t('support_user'),
      headerClassName: ' bg-gray-100 font-bold ',

      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs p-1'
        >
          <Grid item lg={8} md={8}>
            <Grid item lg={12} md={12} xs={12}>
              <AccountCircle fontSize='small' className='text-gray-500' />{' '}
              {params.value.support_user_id
                ? params.value.support_user.full_name
                : '.....'}
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <PhoneAndroid fontSize='small' className='text-gray-500' />{' '}
              {params.value.support_user_id
                ? params.value.support_user.mobile
                : '.....'}
            </Grid>
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'dates',
      headerName: t('dates'),
      headerClassName: ' bg-gray-100 font-bold',
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid container lg={12} md={12} className='text-xs'>
          <Grid
            item
            lg={12}
            md={12}
            className='p-2 text-gray-700 text-center truncate ...'
          >
            {formatDate(params.value.created_at)}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            className='p-2 text-gray-700 text-center truncate ...'
          >
            {formatDate(params.value.updated_at)}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'actions',
      headerName: t('actions'),
      headerClassName: ' bg-gray-100 font-bold text-center w-full',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid container lg={12} md={12} justifyContent='center'>
          <ButtonGroup>
            <Grid className='p-2 text-gray-700 text-center'>
              <Link to={`/tickets/${type}/${params.value.ticket_id}`}>
                <Visibility
                  fontSize='small'
                  className={classes.action}
                  // onClick={() =>
                  //   navigate(`/tickets/${type}/${params.value.ticket_id}`)
                  // }
                />
              </Link>
            </Grid>
            <Grid className='p-2 text-gray-700 text-center'>
              <AssignmentInd
                className={classes.action}
                variant='contained'
                color='primary'
                onClick={(e) => handleOpenAssignDialog(params.value.ticket_id)}
              />
            </Grid>
          </ButtonGroup>
        </Grid>
      ),
    },
  ];
  const rowsData = arrayOfTickets.map((tictet) => {
    return {
      id: tictet.ticket_id,
      orderId: tictet,
      ticketId: tictet,
      status: tictet,
      clientInfo: tictet,
      support_user: tictet,
      category: tictet,
      dates: tictet,
      actions: tictet,
    };
  });
  return (
    <div className='flex h-auto' dir={appDirection}>
      <div className=' flex-grow'>
        <AssignDialog
          handleCloseAction={handleCloseAction}
          isOpen={isOpen}
          ticketId={selectedTicketId}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
        <DataGrid
          className='bg-white border-2'
          disableColumnFilter
          autoHeight
          rowHeight={60}
          loading={isLoading}
          page={page}
          rows={rowsData}
          columns={cols}
          pageSize={1}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => setPage(newPage)}
          CellStyle='{StaticResource DataGridBorder}'
          components={{
            Toolbar: CustomToolbar,
            Pagination: () => CustomPagination(isLoading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: LoadingIndicator,
          }}
        />
      </div>
    </div>
  );
};

export default TicketsList;
