import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageHeading from '../../../../components/common/PageHeading';
import api from '../../../services/API/eventTickets';
import { useEventsTranslation } from '../../../services/i18n';
import TicketsTable from '../../common/TicketsTable';
import { filterDate, filterMobile } from '../../utils';
import TicketsFilter from '../../common/TicketsFilter';

export default function EventsTicketsPage() {
  const [filterPayload, setFilterPayload] = useState({
    from_date: '',
    to_date: '',
    status_id: '',
    mobile: '',
  });

  const [searchParams, setSearchParams] = useSearchParams(
    createSearchParams({
      page_number: 0,
      page_size: 20,
      ...filterPayload,
    })
  );

  const currentSearchParams = useMemo(
    () => ({
      page_number: searchParams.get('page_number'),
      page_size: searchParams.get('page_size'),
      from_date: searchParams.get('from_date'),
      to_date: searchParams.get('to_date'),
      status_id: searchParams.get('status_id'),
      mobile: searchParams.get('mobile'),
    }),
    [searchParams]
  );

  const setPage = (newPage) => {
    setSearchParams({
      ...currentSearchParams,
      page_number: newPage,
    });
    setIsRefreshing((prev) => prev + 1);
  };

  const onApplyFilters = () => {
    const params = {
      ...currentSearchParams,
      ...filterPayload,
      mobile: filterMobile(filterPayload.mobile),
      from_date: filterDate(filterPayload.from_date),
      to_date: filterDate(filterPayload.to_date),
      page_number: 0,
    };
    setSearchParams(params);
    setIsRefreshing((prev) => prev + 1);
  };

  const [isRefreshing, setIsRefreshing] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await api.getAllTickets(currentSearchParams);
      setData(res);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isRefreshing]);

  const t = useEventsTranslation();

  return (
    <Box>
      <Box className='my-2'>
        <PageHeading
          heading1={t('events')}
          heading2={t('events_tickets')}
          backBtn
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      </Box>

      <TicketsFilter
        filterPayload={filterPayload}
        setFilterPayload={setFilterPayload}
        onApplyFilters={onApplyFilters}
      />

      <TicketsTable
        data={data}
        page={Number(currentSearchParams.page_number)}
        setPage={setPage}
        isLoading={isLoading}
      />
    </Box>
  );
}
