import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { t } from 'i18next';

import api from '../../../services/API/events-dashboard';

const initialState = {
  isLoading: false,
  events: [],
  event: {},
  error: null,
};

export const fetchEvents = createAsyncThunk(
  'events/fetchEvents',
  async (payload) => {
    return await api.getEvents(payload);
  }
);

export const createEvent = createAsyncThunk(
  'events/createEvent',
  async (payload) => {
    return await api.createEvent(payload);
  }
);

export const updateEvent = createAsyncThunk(
  'events/updateEvent',
  async (payload) => {
    return await api.updateEvent(payload.id, payload.formData);
  }
);

export const deleteEvent = createAsyncThunk(
  'events/deleteEvent',
  async (id) => {
    return await api.deleteEvent(id);
  }
);

export const getEventDetails = createAsyncThunk(
  'events/getEventDetails',
  async (id) => await api.getEventDetails(id)
);

const events = createSlice({
  name: 'events',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // fetch
    builder.addCase(fetchEvents.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.events = [];
    });
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      state.isLoading = false;
      state.events = action.payload;
      state.error = null;
    });
    builder.addCase(fetchEvents.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.events = [];
    });
    // create
    builder.addCase(createEvent.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createEvent.fulfilled, (state) => {
      state.isLoading = false;
      toast.success(t('Created successfully'));
      state.error = null;
    });
    builder.addCase(createEvent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    // update
    builder.addCase(updateEvent.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateEvent.fulfilled, (state) => {
      toast.success(t('Updated successfully'));
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(updateEvent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    // delete
    builder.addCase(deleteEvent.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteEvent.fulfilled, (state) => {
      toast.success(t('Delete successfully'));
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(deleteEvent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    // details
    builder.addCase(getEventDetails.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.event = {};
    });
    builder.addCase(getEventDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.event = action.payload;
      state.error = null;
    });
    builder.addCase(getEventDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.event = {};
      state.error = action.error;
    });
  },
});

export const { clearErrors } = events.actions;

export default events.reducer;
