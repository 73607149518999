import http from "../http";

export const updateEventProvider = async (id, formData) => {
  try {
    await http.put(`/dashboard/events_providers/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
};
