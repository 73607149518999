import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Chart from './common/Chart';

const WeekChart = () => {
  const { weeklyRateData } = useSelector((state) => state.app).app;

  return <Chart data={weeklyRateData.acceptance_rates} type={'daily'} />;
};

export default WeekChart;
