import React from 'react';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';
import storage from '../../../../storage';
import 'survey-creator-core/survey-creator-core.i18n';
import { localization } from 'survey-creator-core';

localization.currentLocale = storage.lang.getLang();
const translation = localization.getLocale('ar');
translation.ed.jsonEditor = 'تعديل ملف الJSON';

export default function Survey({ surveyJSON, setSurveyJSON }) {
  const creator = new SurveyCreator({
    isAutoSave: true,
    showLogicTab: true,
    haveCommercialLicense: true,
    showJSONEditorTab: false,
    showLogicTab: false,
    isRTL: storage.lang.getDirection() === 'rtl',
  });

  creator.saveSurveyFunc = (saveNo, callback) => {
    console.log(creator.JSON);
    setSurveyJSON(creator.JSON);
    console.log(saveNo);
  };

  creator.JSON = surveyJSON;

  return <SurveyCreatorComponent creator={creator} />;
}
