import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { formatDate } from '../../../drivers/utils';
import { usePlacesTranslation } from '../../i18n';
import AlertDialog from '../Groups_utilities/ConfirmationDialog';
import Form from '../Groups_utilities/UpdateGroupInfoForm';

const GroupMainDetails = ({ isRefreshing, setIsRefreshing }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen_D, setIsOpen_D] = useState(false);
  const { groupDetails } = useSelector((state) => state.places).places;

  const t = usePlacesTranslation();

  return (
    <Grid
      container
      lg={12}
      md={12}
      justify='center'
      padding={5}
      className='font-serif p-6 shadow-lg shadow-gray-500 bg-white'
    >
      <Form
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />
      <AlertDialog
        isOpen={isOpen_D}
        setIsOpen={setIsOpen_D}
        id={groupDetails.group_id}
      />
      <Grid container lg={12} md={12} spacing={2}>
        {/* item  */}
        <Grid item lg={6} md={6} className=''>
          <Grid
            item
            lg={12}
            md={12}
            className='text-gray-600 text-lg  font-semibold '
          >
            {t('group_id')}:
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            className=' text-base text-gray-600 bg-white p-6 rounded-lg shadow-lg border-2'
          >
            {groupDetails.group_id || '......'}
          </Grid>
        </Grid>

        <Grid item lg={6} md={6} className=''>
          <Grid
            item
            lg={12}
            md={12}
            className='text-gray-600 text-lg  font-semibold '
          >
            {t('name_ar')}:
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            className='text-base text-gray-600 bg-white p-6 rounded-lg shadow-lg border-2'
          >
            {groupDetails.name_ar || '......'}
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} className=''>
          <Grid
            item
            lg={12}
            md={12}
            className='text-gray-600 text-lg  font-semibold '
          >
            {t('name_en')}:
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            className='text-base text-gray-600 bg-white p-6 rounded-lg shadow-lg border-2'
          >
            {groupDetails.name_en || '......'}
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} className=''>
          <Grid
            item
            lg={12}
            md={12}
            className='text-gray-600 text-lg  font-semibold '
          >
            {t('type')}:
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            className=' text-base text-gray-600 bg-white p-6 rounded-lg shadow-lg border-2'
          >
            {groupDetails.group_type === 1
              ? 'Manually Selected'
              : 'Automatically Selected'}
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} className=''>
          <Grid
            item
            lg={12}
            md={12}
            className='text-gray-600 text-lg  font-semibold '
          >
            {t('key')}:
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            className=' text-base text-gray-600 bg-white p-6 rounded-lg shadow-lg border-2'
          >
            {groupDetails.key || '......'}
          </Grid>
        </Grid>
        {/* item  */}
        <Grid item lg={6} md={6} className=''>
          <Grid
            item
            lg={12}
            md={12}
            className='text-gray-600 text-lg  font-semibold '
          >
            {t('created_at')}:
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            className=' text-base text-gray-600 bg-white p-6 rounded-lg shadow-lg border-2'
          >
            {formatDate(groupDetails.created_at) || '......'}
          </Grid>
        </Grid>
        {/* item  */}
        <Grid item lg={6} md={6} className=''>
          <Grid
            item
            lg={12}
            md={12}
            className='text-gray-600 text-lg  font-semibold '
          >
            {t('updated_at')}:
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            className=' text-base text-gray-600 bg-white p-6 rounded-lg shadow-lg border-2'
          >
            {formatDate(groupDetails.updated_at) || '......'}
          </Grid>
        </Grid>

        {/* btn's  */}
        <Grid
          item
          lg={12}
          md={12}
          className='pt-4 flex justify-center gap-3 text-white'
        >
          <Button
            color='primary'
            variant='contained'
            onClick={() => setIsOpen(true)}
          >
            {t('edit_group')}
          </Button>
          {!groupDetails.is_deleted && (
            <Button
              variant='contained'
              color='error'
              onClick={() => setIsOpen_D(true)}
            >
              {t('delete')}
            </Button>
          )}
        </Grid>
        {/* end_of_content */}
      </Grid>
    </Grid>
  );
};

export default GroupMainDetails;
