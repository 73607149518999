import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import ImageDialog from '../common/ImageDialog';
import UploadBtnOne from '../helper_components/Update_one_image';
import {
  AddImage,
  getPlacesImage,
} from '../../../../Redux/store/places/placesListSlice';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import { generateFormData } from '../../utils';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Grid, ImageList, ImageListItem } from '@mui/material';
import { usePlacesTranslation } from '../../i18n';

function PlaceImages() {
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(0);
  const { images, placeDetails } = useSelector((state) => state.places).places;

  const [del, setDel] = useState(false);

  const [options, setOptions] = useState({
    id: placeDetails.place_id,
    payload: null,
  });

  const dispatch = useDispatch();

  const fetchImage = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(getPlacesImage({ id: placeDetails.place_id }));
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  const updateImages = useCallback(async (value) => {
    try {
      let img = generateFormData({ images: value });
      console.log(img);
      await dispatch(AddImage({ id: placeDetails.place_id, payload: img }));
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsRefreshing(isRefreshing + 1);
    }
  });

  const t = usePlacesTranslation();

  useEffect(() => {
    fetchImage();
  }, [isRefreshing]);

  if (isLoading) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-[70vh] place-content-center'>
          <div class='flex items-center gap-2 text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            {t('loading')}...
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <Grid lg={12} md={12} className=''>
      {images.length !== 0 ? (
        <ImageList sx={{ width: '100%', height: 450 }} cols={3} rowHeight={150}>
          {images.map((item) => (
            <ImageListItem key={item.image_id}>
              <div className='relative w-full h-full'>
                <img src={item.image} alt='no_image' className='relative z-0' />
                <div className='absolute top-0 left-0'>
                  {del && (
                    <ImageDialog
                      id={placeDetails.place_id}
                      image_id={item.image_id}
                      setIsRefreshing={setIsRefreshing}
                      isRefreshing={isRefreshing}
                    />
                  )}
                </div>
              </div>
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <div className='w-full font-bold text-2xlg text-center text-gray-400'>
          {t('no_images')}
        </div>
      )}

      <Grid>
        {/* <form onSubmit={formik.handleSubmit}> */}
        <Grid lg={12} md={12} className='flex justify-center pt-24 '>
          <UploadBtnOne
            setValue={updateImages}
            // onChange={null}
            value={options.payload}
            name={'images'}
            title={t('image_place')}
          />
          {/* <Button type='submit'>done</Button> */}
        </Grid>
        {/* </form> */}
      </Grid>
      {images.length !== 0 && (
        <Grid
          item
          lg={12}
          md={12}
          className='flex justify-center items-end p-2 '
        >
          <Button
            color='error'
            variant='contained'
            type='submit'
            onClick={(e) => setDel(!del)}
          >
            {del ? t('cancel') : t('delete')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default PlaceImages;
