import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import { Grid } from '@mui/material';
import { useUsersTranslation } from '../../i18n';
import { TextField, Button } from '@mui/material';
import { appDirection } from '../../../../shared_utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    margin: '0px 0px 10px 0px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function OrderIdFilter({
  payload,
  setPayload,
  setIsRefreshing,
  isRefreshing,
}) {
  const classes = useStyles();
  const t = useUsersTranslation();

  const [order, setOrder] = React.useState('');

  const handleSubmit = useCallback(async () => {
    setPayload({
      ...payload,
      driver_id: order,
    });
    setIsRefreshing(isRefreshing + 1);
  });

  const handleClearField = useCallback((e) => {
    setOrder('');
  });

  const handleOrderChange = useCallback((e) => {
    setOrder(e.target.value);
  });

  return (
    <Grid
      container
      lg={12}
      md={12}
      justifyContent
      className='p-1 flex justify-between'
      dir={appDirection}
    >
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <TextField
          InputLabelProps={{ shrink: true }}
          className=' bg-white'
          variant='outlined'
          value={order}
          onChange={handleOrderChange}
          id='orderId'
          name='order_id'
          label={t('order_id')}
          type='text'
          size='small'
          fullWidth
        />
      </Grid>
      <Grid
        item
        lg={1}
        md={1}
        sm={1}
        xs={1}
        className='flex justify-center h-full '
      >
        <Button
          variant='contained'
          className='h-full p-8'
          color='primary'
          type='submit'
          onClick={handleSubmit}
        >
          {t('apply')}
        </Button>
      </Grid>
    </Grid>
  );
}
