import http from '../http';

const updateTicketDetails = async (options) => {
  const baseURL = process.env.REACT_APP_TICKETS_API;
  try {
    await http.put(`${baseURL}/tickets/${options.ticketId}`, options.payload);
  } catch (err) {
    throw err.response;
  }
};

export default updateTicketDetails;
