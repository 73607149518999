import React, { useState, useCallback } from 'react';

import { Empty } from '../../../drivers/components/common/Empty';
import {
  DataGrid,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';

import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import {
  deleteComment,
  getComments,
} from '../../../../Redux/store/places/placesListSlice';
import SimpleBackdrop from '../../../../components/common/LoadingIndicator';
import ReviewImages from './ReviewImages';
import CommentDialog from '../common/CommentDialog';
import DeleteDialog from '../common/DeleteDialog';
import { useEffect } from 'react';
import { formatDate } from '../../../drivers/utils';
import { usePlacesTranslation } from '../../i18n';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Rating,
} from '@mui/material';
import { ThumbUp } from '@mui/icons-material';
import CustomPagination from '../../../../components/new-components/CustomPagination';

function ReviewsContainer({
  isLoading,
  page,
  setPage,
  isRefreshing,
  setIsRefreshing,
}) {
  const [open, setOpen] = useState(false);
  const [delet, setDelet] = useState(false);
  const [arr, setArr] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { commentInfo } = useSelector((state) => state.places).places;

  useEffect(() => {
    setArr(commentInfo);
  }, [arr]);

  const t = usePlacesTranslation();

  const cols = [
    {
      field: 'user',
      headerName: t('user'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className='font-serif font-semibold pl-2'
        >
          <Button
            onClick={() => {
              navigate(`/users/${params.value.user_id}`);
            }}
          >
            <Grid
              container
              lg={12}
              md={12}
              justifyContent='center'
              className='flex '
            >
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                className='pr-2 text-sm font-medium block'
              >
                {params.value.full_name}
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                className=' text-sm font-medium'
              >
                {params.value.mobile}
              </Grid>
            </Grid>
          </Button>
        </Grid>
      ),
    },
    {
      field: 'comment',
      headerName: t('comment'),
      headerClassName: ' bg-gray-100 text-center w-full ',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className=' text-sm font-serif font-semibold pl-2'
        >
          <Grid item lg={12} md={12} xs={12} className='truncate'>
            {params.value.comment}
          </Grid>
          <Grid item lg={12} md={12} xs={12} className=''>
            <Rating value={params.value.rating} disabled />
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'likes_count',
      headerName: t('likes'),
      headerClassName: ' bg-gray-100 text-center w-full ',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className=' text-sm font-serif font-semibold pl-2'
        >
          <Grid item lg={12} md={12} xs={12} className=''>
            {/* {params.value.replys} */}
          </Grid>
          <Grid item lg={12} md={12} xs={12} className=''>
            {/* <ThumbUpIcon fontSize="small" /> */}
            <span className='p-2'> {params.value.likes_count}</span>
            <ThumbUp fontSize='small' />
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'images',
      headerName: t('images'),
      headerClassName: ' bg-gray-100 text-center w-full ',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className=' text-xs font-serif font-semibold pl-2'
        >
          <Grid item lg={8} md={8} xs={8} className='flex flex-row '>
            {params.value.place_images.length !== 0
              ? params.value.place_images
                  .filter((item, idx) => idx < 2)
                  .map((item) => {
                    return (
                      <Grid lg={8} md={8} className='pl-1 '>
                        <img
                          src={item.image}
                          alt=''
                          width='100%'
                          height='50%'
                        />
                      </Grid>
                    );
                  })
              : 'No Images'}
          </Grid>
          {params.value.place_images.length !== 0 ? (
            <Grid container lg={4} md={4} className='flex  mt-2'>
              <ReviewImages
                comment={params.value}
                open={open}
                setOpen={setOpen}
                isRefreshing={isRefreshing}
                setIsRefreshing={setIsRefreshing}
              />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      ),
    },
    {
      field: 'date',
      headerName: t('date'),
      headerClassName: ' bg-gray-100 text-center w-full ',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className=' text-sm font-serif font-semibold pl-2'
        >
          <Grid item lg={12} md={12} xs={12} className=''>
            {formatDate(params.value.created_at)}
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'comment_id',
      headerName: t('view'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <>
          <Grid container lg={12} md={12} alignItems='center'>
            <Grid item lg={4} md={4}>
              <ButtonGroup>
                <Link to={`/places/replies/${params.value.comment_id}`}>
                  <VisibilityOutlinedIcon
                    color='primary'
                    fontSize='small'
                    className=' hover:bg-blue-200 hover:cursor-pointer'
                  />
                </Link>
              </ButtonGroup>
            </Grid>
            <Grid item lg={4} md={4}>
              <CommentDialog
                comment={params.value}
                open={open}
                setOpen={setOpen}
                isRefreshing={isRefreshing}
                setIsRefreshing={setIsRefreshing}
              />
            </Grid>

            <Grid item lg={4} md={4}>
              <DeleteDialog
                open={open}
                setOpen={setOpen}
                id={params.value.comment_id}
                isRefreshing={isRefreshing}
                setIsRefreshing={setIsRefreshing}
              />
            </Grid>
          </Grid>
        </>
      ),
    },
  ];

  const rowsData = commentInfo.map((item) => {
    return {
      id: item.comment_id,
      user: item.user,
      comment: item,
      comment_id: item,
      date: item,
      likes_count: item,
      images: item,
    };
  });

  return (
    <div className='flex h-auto w-full bg-white'>
      <div className=' flex-grow'>
        <DataGrid
          disableColumnFilter
          autoHeight
          density='standard'
          rowHeight={60}
          loading={isLoading}
          rows={rowsData}
          columns={cols}
          pageSize={1}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => setPage(newPage)}
          components={{
            Toolbar: null,
            Pagination: () => CustomPagination(isLoading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: SimpleBackdrop,
          }}
        />
      </div>
    </div>
  );
}

export default ReviewsContainer;
