import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useOrdersTranslation } from "../../i18n";

export default function AlertDialog({ open, setOpen, handleCancel, id }) {
  //   const [open, setOpen] = React.useState(false);

  const handleCofiramation = () => {
    handleCancel(id);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const t = useOrdersTranslation();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="text-red-600">
          {t("cancle")}{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("AreyousureyouwanttocanceltheOrder")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("cancle")}
          </Button>
          <Button onClick={handleCofiramation} color="secondary" autoFocus>
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
