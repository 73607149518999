import http from '../http';

const createTicket = async (payload) => {
  const baseURL = process.env.REACT_APP_TICKETS_API;
  try {
    await http.post(`${baseURL}/tickets`, {
      parent_category_id: payload.parent_category_id,
      child_category_id: payload.child_category_id,
      client_id: payload.client_id,
      description: payload.description,
      note: payload.note,
      priority: payload.priority,
      reference_id: payload.reference_id,
      service_id: payload.service_id,
      support_user_id: null,
      user_id: payload.user_id,
    });
  } catch (err) {
    throw err.response;
  }
};
export default createTicket;
