import { Box, Button, Checkbox, FormControlLabel, Tab } from '@mui/material';
import { AddLocation } from '@mui/icons-material';
import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Map from './Map';
import { clearErrors } from '../../../core/features/events/eventsSlice';
import { toast } from 'react-toastify';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { useEventsTranslation } from '../../../services/i18n';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Survey from './Survey';
import api from '../../../services/API/events-dashboard';
import moment from 'moment';

export default function CreateEventForm({
  event_name_ar,
  event_name_en,
  event_address_ar,
  event_address_en,
  event_desc_ar,
  event_desc_en,
  event_location_lat,
  event_location_lng,
  event_start_date,
  event_end_date,
  start_booking_at,
  end_booking_at,
  file,
  update,
  event_id,
  all_tickets_qty,
  max_tickets_qty_per_user,
  event_note_ar,
  event_note_en,
  require_booking_time,
  min_tickets_qty_per_booking,
  lemon_percentage,
}) {
  const [data, setData] = useState({
    event_name_ar: event_name_ar || '',
    event_name_en: event_name_en || '',
    event_address_ar: event_address_ar || '',
    event_address_en: event_address_en || '',
    event_desc_ar: event_desc_ar || '',
    event_desc_en: event_desc_en || '',
    event_location_lat: event_location_lat,
    event_location_lng: event_location_lng,
    event_start_date: event_start_date || '',
    event_end_date: event_end_date || '',
    start_booking_at: start_booking_at || '',
    end_booking_at: end_booking_at || '',
    all_tickets_qty: all_tickets_qty || '',
    max_tickets_qty_per_user: max_tickets_qty_per_user || '',
    min_tickets_qty_per_booking: min_tickets_qty_per_booking || '',
    event_note_ar: event_note_ar || '',
    event_note_en: event_note_en || '',
    require_booking_time: require_booking_time || false,
    lemon_percentage: lemon_percentage || '',
  });

  const [files, setFiles] = useState(file || []);
  const [openMap, setOpenMap] = useState(false);

  const [enableSurvey, setEnableSurvey] = useState(false);

  const { isLoading, error } = useSelector((state) => state.eventsSlice);
  const [submitting, setSubmitting] = useState(false);

  const [surveyJSON, setSurveyJSON] = useState({});

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (error) {
      toast.error(error.message);
      dispatch(clearErrors());
    }
  }, [error, dispatch]);

  const t = useEventsTranslation();

  const handleSubmit = async (e) => {
    // console.log(data);
    setSubmitting(true);

    e.preventDefault();
    const formData = new FormData();

    const dates = {
      event_start_date: 1,
      event_end_date: 1,
      start_booking_at: 1,
      end_booking_at: 1,
    };

    for (let key in data) {
      if (key in dates) {
        let date = data[key];
        formData.append(key, moment(date).utc().format('YYYY-MM-DD HH:mm:ss'));
      } else if (key !== 'require_booking_time') {
        if (data[key]) formData.append(key, data[key]);
      }
    }
    formData.append('require_booking_time', data.require_booking_time);
    for (let file of files) {
      formData.append('file', file);
    }
    try {
      if (update) {
        await api.updateEvent(event_id, formData);
        toast.success(t('event_updated_successfuly'));
        navigate(`/events/${event_id}`);
      } else {
        formData.append('provider_id', id);
        const response = await api.createEvent(formData);
        toast.success(t('event_created_successfuly'));
        navigate(`/events/${response.event_id}`);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const [value, setValue] = useState('0');

  if (isLoading || submitting) return <LoadingIndicator />;

  return (
    <>
      <TabContext value={value}>
        <Box
          className='bg-gray-100'
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <TabList
            onChange={(e, newTab) => {
              setValue(newTab);
            }}
            aria-label='lab API tabs example'
            centered
          >
            <Tab label={t('form')} value='0' />
            {enableSurvey && <Tab label={t('survey')} value='1' />}
          </TabList>
        </Box>
        <TabPanel value='0'>
          <form onSubmit={handleSubmit} className='pb-10'>
            <div className='w-full flex items-center justify-center'>
              <div className='flex flex-col rounded-lg px-8 pb-3 pt-3 w-full gap-2 min-h-[90vh] '>
                <div className='flex justify-between text-2xl font-bold mb-3'>
                  {update ? t('edit_event') : t('add_new_event')}
                  <div className='flex flex-col'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={enableSurvey}
                          onChange={(e) => setEnableSurvey(e.target.checked)}
                        />
                      }
                      label={t('enable_survey')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.require_booking_time}
                          onChange={(e) =>
                            setData({
                              ...data,
                              require_booking_time: e.target.checked,
                            })
                          }
                        />
                      }
                      label={t('require_booking_time')}
                    />
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.add_event_to_place}
                          onChange={(e) =>
                            setData({
                              ...data,
                              add_event_to_place: e.target.checked,
                            })
                          }
                        />
                      }
                      label={t('add_event_to_place')}
                    /> */}
                  </div>
                </div>
                <div className='flex justify-between gap-3 items-center'>
                  <TextField
                    onChange={(e) =>
                      setData({ ...data, event_name_ar: e.target.value })
                    }
                    label={t('event_name_ar')}
                    required
                    variant='outlined'
                    value={data.event_name_ar}
                    className='w-full'
                  />
                  <TextField
                    onChange={(e) =>
                      setData({ ...data, event_name_en: e.target.value })
                    }
                    label={t('event_name_en')}
                    variant='outlined'
                    className='w-full'
                    required
                    value={data.event_name_en}
                  />
                </div>
                <div className='flex justify-between gap-3 items-center'>
                  <TextField
                    onChange={(e) =>
                      setData({ ...data, event_address_ar: e.target.value })
                    }
                    label={t('event_address_ar')}
                    variant='outlined'
                    required
                    className='w-full'
                    value={data.event_address_ar}
                  />
                  <TextField
                    onChange={(e) =>
                      setData({ ...data, event_address_en: e.target.value })
                    }
                    label={t('event_address_en')}
                    variant='outlined'
                    className='w-full'
                    required
                    value={data.event_address_en}
                  />
                </div>
                <div className='flex justify-between gap-3 items-center'>
                  <TextField
                    onChange={(e) =>
                      setData({ ...data, event_desc_ar: e.target.value })
                    }
                    label={t('event_desc_ar')}
                    variant='outlined'
                    className='w-full'
                    required
                    multiline
                    rows={2}
                    value={data.event_desc_ar}
                  />
                </div>
                <div className='w-full'>
                  <TextField
                    onChange={(e) =>
                      setData({ ...data, event_desc_en: e.target.value })
                    }
                    label={t('event_desc_en')}
                    variant='outlined'
                    className='w-full'
                    multiline
                    rows={2}
                    required
                    value={data.event_desc_en}
                  />
                </div>
                <div className='w-full'>
                  <TextField
                    onChange={(e) =>
                      setData({ ...data, event_note_ar: e.target.value })
                    }
                    label={t('event_note_ar')}
                    variant='outlined'
                    className='w-full'
                    value={data.event_note_ar}
                    multiline
                    rows={2}
                  />
                </div>
                <div className='w-full'>
                  <TextField
                    onChange={(e) =>
                      setData({ ...data, event_note_en: e.target.value })
                    }
                    label={t('event_note_en')}
                    variant='outlined'
                    className='w-full'
                    value={data.event_note_en}
                    multiline
                    rows={2}
                  />
                </div>
                <div className='flex items-center justify-between gap-2'>
                  <div className='w-full'>
                    <p className=' text-gray-500'>{t('event_start_date')}</p>
                    <TextField
                      onChange={(e) =>
                        setData({ ...data, event_start_date: e.target.value })
                      }
                      type='datetime-local'
                      inputProps={{ step: '1' }}
                      variant='outlined'
                      required
                      className='w-full'
                      value={data.event_start_date}
                    />
                  </div>
                  <div className='w-full'>
                    <p className=' text-gray-500'>{t('event_end_date')}</p>
                    <TextField
                      onChange={(e) =>
                        setData({ ...data, event_end_date: e.target.value })
                      }
                      type='datetime-local'
                      inputProps={{ step: '1' }}
                      variant='outlined'
                      required
                      className='w-full'
                      value={data.event_end_date}
                    />
                  </div>
                </div>
                <div className='flex items-center justify-between gap-2'>
                  <div className='w-full'>
                    <p className=' text-gray-500'>{t('start_booking_at')}</p>
                    <TextField
                      onChange={(e) => {
                        setData({ ...data, start_booking_at: e.target.value });
                      }}
                      type='datetime-local'
                      inputProps={{ step: '1' }}
                      variant='outlined'
                      required
                      className='w-full'
                      value={data.start_booking_at}
                    />
                  </div>
                  <div className='w-full'>
                    <p className=' text-gray-500'>{t('end_booking_at')}</p>
                    <TextField
                      onChange={(e) =>
                        setData({ ...data, end_booking_at: e.target.value })
                      }
                      type='datetime-local'
                      inputProps={{ step: '1' }}
                      variant='outlined'
                      required
                      className='w-full'
                      value={data.end_booking_at}
                    />
                  </div>
                </div>
                <div className='w-full justify-between flex gap-2 items-center'>
                  <div className='w-full'>
                    <div className='flex justify-center w-full'></div>
                    <p className='text-gray-500'>{t('select_images')}</p>
                    <input
                      type={'file'}
                      multiple
                      onChange={(e) => setFiles(e.target.files)}
                      className='p-2 border-gray-400 rounded-md border-[1px] w-full'
                    />
                    <div className='w-full my-3'>
                      <Button
                        variant='contained'
                        color={data.event_location_lat ? 'inherit' : 'primary'}
                        endIcon={<AddLocation />}
                        onClick={() => setOpenMap(true)}
                        fullWidth
                      >
                        {data.event_location_lat
                          ? t('edit_location')
                          : t('select_location')}
                      </Button>
                    </div>
                    <div className='my flex w-full items-center gap-4 justify-between'>
                      <div className='w-full'>
                        <p className='text-gray-600'>{t('latitude')}</p>
                        <TextField
                          fullWidth
                          value={data.event_location_lat}
                          onChange={(e) =>
                            setData({
                              ...data,
                              event_location_lat: e.target.value,
                            })
                          }
                          type='number'
                        />
                      </div>
                      <div className='w-full'>
                        <p className='text-gray-600'>{t('longitude')}</p>
                        <TextField
                          value={data.event_location_lng}
                          fullWidth
                          onChange={(e) =>
                            setData({
                              ...data,
                              event_location_lng: e.target.value,
                            })
                          }
                          type='number'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='w-full flex flex-wrap items-center justify-center gap-2'>
                    <TextField
                      onChange={(e) =>
                        setData({ ...data, all_tickets_qty: e.target.value })
                      }
                      label={t('all_tickets_qty')}
                      type='number'
                      variant='outlined'
                      className='w-full'
                      value={data.all_tickets_qty}
                    />
                    <TextField
                      onChange={(e) =>
                        setData({
                          ...data,
                          max_tickets_qty_per_user: e.target.value,
                        })
                      }
                      label={t('max_tickets_qty_per_user')}
                      variant='outlined'
                      type='number'
                      className='w-full'
                      value={data.max_tickets_qty_per_user}
                    />
                    <TextField
                      onChange={(e) =>
                        setData({
                          ...data,
                          min_tickets_qty_per_booking: e.target.value,
                        })
                      }
                      label={t('min_tickets_qty_per_booking')}
                      variant='outlined'
                      type='number'
                      className='w-full'
                      value={data.min_tickets_qty_per_booking}
                    />
                    <TextField
                      onChange={(e) =>
                        setData({
                          ...data,
                          lemon_percentage: e.target.value,
                        })
                      }
                      label={t('lemon_percentage')}
                      variant='outlined'
                      type='number'
                      className='w-full'
                      value={data.lemon_percentage}
                    />
                  </div>
                </div>

                <Map
                  setOpenMap={setOpenMap}
                  openMap={openMap}
                  data={data}
                  setData={setData}
                />
                <div className='w-full mt-5 mb-3 flex justify-center'>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    className='w-[10%]'
                  >
                    {update ? t('edit') : t('add')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </TabPanel>
        {enableSurvey && (
          <TabPanel value='1'>
            <Survey setSurveyJSON={setSurveyJSON} surveyJSON={surveyJSON} />
          </TabPanel>
        )}
      </TabContext>
    </>
  );
}
