import { Route } from "react-router-dom";
import DispatchesListPage from "./pages/dispatches_list_page";
import DispatchPage from "./pages/dispatch_page";

export function DispatchesRoutes() {
	return (
		<Route path='/dispatches' key={"dispatches"}>
			<Route
				path='/dispatches/'
				element={<DispatchesListPage />}
				key={"dispatches_list_page"}
			/>
			<Route 
				path='/dispatches/:id/' 
				element={<DispatchPage />} 
				key={"dispatch_page"} 
			/>
		</Route>
	);
}
