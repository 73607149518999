import React, { useCallback, useState } from 'react';
import MobileForm from '../components/MobileForm';
import OTPForm from '../components/OTPForm';
import { Grid } from '@material-ui/core';
import http from '../../../services/APIs/auth_api';
import { handleTokenStore, handleUserStore } from '../uitls';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const LoadingIndicator = () => {
  return (
    <Grid container lg={12} md={12} justifyContent='center'>
      <div className='grid  place-content-center'>
        <div className='flex items-center gap-2 text-xl text-gray-600'>
          <span className='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
          loading...
        </div>
      </div>
    </Grid>
  );
};

const LoginPage = ({ setIsAuthenticated }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMoblieVerified, setIsMoblieVerified] = useState(false);
  const [mobile, setMobile] = useState(0);
  const [code, setcode] = useState('');
  const [title, setTitle] = useState('Sign In');

  const { t } = useTranslation();

  const checkNumber = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await http.post('/auth/otp', {
        mobile_number: mobile,
      });
      if (res.data.success) {
        setIsMoblieVerified(true);
        setTitle('Verification Code');
      }
    } catch (err) {
      setMobile(0);
      toast.error(err.response.data.message);
    } finally {
      setIsLoading(false);
    }
  });

  const handleLogin = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await http.post('/auth/mobile_login', {
        code: code,
        mobile_number: mobile,
      });
      if (res.data.user.user_id != null) {
        setIsAuthenticated(true);
        await handleTokenStore(res.data.access_token);
        await handleUserStore(res.data.user.full_name);
        toast.success(t('signed in successfully'));
      }
    } catch (err) {
      toast.error(err.response.data.message);
      setIsMoblieVerified(false);
      setMobile('');
      setcode('');
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <div className='h-[93vh] w-[100vw] bg-gray-100 py-16 px-4'>
      <div className='flex flex-col items-center justify-center'>
        <img
          src={require('../../../assets/images/favicon.ico')}
          className='w-[90px]'
          alt=''
        />
        <p className='text-4xl font-serif font-bold  pt-2 '>Admin Portal</p>
        <div className='bg-white shadow rounded-lg lg:w-1/3  md:w-1/2 w-full py-16 px-10 mt-16'>
          <div className='flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5'>
            <p className='text-center text-lg font-semibold mx-4 mb-0'>
              {t(title)}
            </p>
          </div>
          {isLoading ? (
            <LoadingIndicator />
          ) : isMoblieVerified ? (
            <OTPForm handleLogin={handleLogin} setcode={setcode} code={code} />
          ) : (
            <MobileForm setMobile={setMobile} checkNumber={checkNumber} />
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
