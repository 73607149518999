const car_ar = {
  cars: 'المركبات',
  cars_list: 'قائمة المركبات',
  car_details: 'تفاصيل المركبة',
  car_type: 'نوع المركبة',
  car_types: 'نوع المركبة',
  standard_ride: 'المشوار الأساسي',
  short_ride: 'مشوار قصير',
  mid_ride: 'مشوار متوسط',
  open_km: ' مشوار مفتوح/كلم',
  open_min: ' مشوار مفتوح/دقيقة',
  cars_allowed: 'أنواع السيارات المسموح بها',
  view: 'عرض',
  pricekm: 'سعر/كلم',
  average: 'تسعيرة مشوار متوسط',
  short: 'تسعيرة مشوار قصير',
  minimum: 'الحد الأدنى',
  open: 'تسعيرة مشوار مفتوح',
  update: 'تعديل',
  edit: 'تعديل',
  vehical: 'بيانات المركبة',
  name: 'الإسم',
  description: 'تفاصيل',
  minprice: 'سعر الدقيقة',
  backup: 'سعر الدقيقة بتجاهل المسافة',
  min_price: 'الحد الأدنى',
  SDG: 'جنيه',
  KM: 'كلم',
  distance: 'المسافة',
  SDG_KM: 'جنيه/كلم',
  SDG_MIN: 'جنيه/دقيقة',
  Lemon: 'ليمون',
  share: 'شير ',
};

export default car_ar;
