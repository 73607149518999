import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Chart from './common/Chart';

const MonthChart = () => {
  const weeklyRateData = useSelector((state) => state.acceptanceRates.weeklyRateData);
  return <Chart data={weeklyRateData} type={'weekly'} />;
};

export default MonthChart;
