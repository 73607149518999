import { createSlice } from '@reduxjs/toolkit';
import { Api } from './api';

const initialState = {
  demandSupplyData: [],
};

const demandSupplySlice = createSlice({
  name: 'demandSupply',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.demandSupplyData = action.payload.demand_supply_results;
    },
  },
});

export const { setData } = demandSupplySlice.actions;

export const getHeatMapData = (payload) => async (dispatch) => {
  try {
    const data = await Api.getDemandSupplyData(payload);
    dispatch(setData(data));
  } catch (error) {
    throw error;
  }
};

export default demandSupplySlice.reducer;
