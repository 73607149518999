import http, { handler } from './http';

const api = {
  createTicketType: (data) =>
    handler(http.post('/dashboard/event_ticket_types', data)),
  getTicketTypes: (params) =>
    handler(http.get('/dashboard/event_ticket_types', { params })),
  getTicketTypeDetails: (id) =>
    handler(http.get('/dashboard/event_ticket_types/' + id)),
  updateTicketType: (id, data) =>
    handler(http.put('/dashboard/event_ticket_types/' + id, data)),
  deleteTicketType: (id) =>
    handler(http.delete('/dashboard/event_ticket_types/' + id)),
  disableTicketType: (id) =>
    handler(http.put('/dashboard/event_ticket_types/disable/' + id)),
  enableTicketType: (id) =>
    handler(http.put('/dashboard/event_ticket_types/enable/' + id)),
};

export default api;
