import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOrdersTranslation } from '../../i18n';

const NewOrderDetails = ({ stage }) => {
  const { createdOrder } = useSelector((state) => state.orders).ordersList;
  const t = useOrdersTranslation();

  return (
    <Grid container lg={12} md={12} justifyContent='center' className='text-xs'>
      <Grid item lg={6} md={6} className='p-2 text-center'>
        <Grid
          item
          lg={12}
          md={12}
          className='text-gray-600 text-[0.70rem]text-center py-2'
        >
          {t('estimated_price')}
        </Grid>
        <Grid item lg={12} md={12} className='p-2 text-center bg-gray-300'>
          {createdOrder.price || '...'} {t('SDG')}
        </Grid>
      </Grid>
      <Grid item lg={6} md={6} className='p-2 text-center'>
        <Grid
          item
          lg={12}
          md={12}
          className='text-gray-600 text-[0.70rem] text-center py-2'
        >
          {t('estimated_distance')}
        </Grid>
        <Grid item lg={12} md={12} className='p-2 text-center bg-gray-300'>
          {createdOrder.distance_km || '...'} {t('KM')}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewOrderDetails;
