import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { useUsersTranslation } from '../../i18n';
import { appDirection } from '../../../../shared_utils';

const UpdateBalanceForm = ({ title, updateBalnce, open, setOpen, options }) => {
  const init = {
    amount: null,
    narration: '',
    reference_type: '',
  };

  const formik = useFormik({
    initialValues: {
      amount: 0,
      narration: '',
    },
    onSubmit: (values, { resetForm }) => {
      updateBalnce(values);
      resetForm();
      setOpen(false);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const t = useUsersTranslation();
  return (
    <Grid
      lg={10}
      md={10}
      justifyContent='center'
      spacing={4}
      dir={appDirection}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth={'sm'}
        maxWidth={'sm'}
      >
        <DialogTitle id='dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container lg={12} md={12} spacing={2}>
              <Grid item lg={12} md={12}>
                <TextField
                  fullWidth
                  type='number'
                  id='amount'
                  name='amount'
                  label={t('amount')}
                  onChange={formik.handleChange}
                  value={formik.values.amount}
                  variant='outlined'
                  InputProps={
                    title === 'add_balance'
                      ? { inputProps: { min: 0, max: 5000 } }
                      : { inputProps: { min: 0 } }
                  }
                  required
                />
              </Grid>
              <Grid item lg={12} md={12}>
                <TextField
                  fullWidth
                  required
                  select
                  id='reference_type'
                  name='reference_type'
                  label={t('reference_type')}
                  onChange={formik.handleChange}
                  value={formik.values.reference_type}
                  variant='outlined'
                >
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={12} md={12}>
                <TextField
                  fullWidth
                  id='narration'
                  name='narration'
                  label={t('narration')}
                  onChange={formik.handleChange}
                  value={formik.values.narration}
                  variant='outlined'
                />
              </Grid>
              <Grid
                container
                lg={12}
                md={12}
                className='flex p-2 justify-center  '
                spacing={1}
              >
                <Grid item>
                  <Button variant='outlined' color='primary' type='submit'>
                    {t('update')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='outlined'
                    color='inherit'
                    onClick={handleClose}
                  >
                    {t('cancel')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </Grid>
  );
};

export default UpdateBalanceForm;
