import React, { useEffect, createRef } from 'react';

import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
import { useIdentityTranslation } from '../../i18n';
import { useTheme } from '@mui/styles';

export default function Filters({
  payload,
  setPayload,
  setIsRefreshing,
  isRefreshing,
  setPage,
  setSearchParams,
  currentSearchParams,
}) {
  const theme = useTheme();
  const inputRef = createRef();
  useEffect(() => {
    if (inputRef) {
      inputRef.current.dir = 'auto';
    }
  }, []);
  const t = useIdentityTranslation();
  return (
    <Grid
      container
      lg={12}
      md={12}
      justifyContent
      className=' flex justify-end p-1 '
    >
      <Grid container lg={11} md={11} className='' spacing={2}>
        <Grid item lg={2} md={2} sm={2} xs={2} sx={{ mt: -1 }}>
          <TextField
            inputRef={inputRef}
            className=' bg-white'
            margin='dense'
            variant='outlined'
            value={payload.mobile}
            onChange={(e) => setPayload({ ...payload, mobile: e.target.value })}
            id='mobile'
            label={t('mobile')}
            type='text'
            InputLabelProps={{ shrink: true }}
            size='small'
            // fullWidth
            InputProps={
              theme.direction == 'ltr'
                ? {
                    startAdornment: (
                      <InputAdornment position='end'>+249</InputAdornment>
                    ),
                  }
                : {
                    endAdornment: (
                      <InputAdornment position='start'>249+</InputAdornment>
                    ),
                  }
            }
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={2} sx={{ mt: -1 }}>
          <TextField
            className='bg-white'
            InputLabelProps={{ shrink: true }}
            fullWidth
            select
            id='outlined-select-currency'
            label={t('block_status')}
            value={payload.status}
            onChange={(e) => setPayload({ ...payload, status: e.target.value })}
            margin='dense'
            variant='outlined'
            size='small'
          >
            <MenuItem value={'0'}>{t('all')}</MenuItem>
            <MenuItem value={false}>{t('not_blocked')}</MenuItem>
            <MenuItem value={true}>{t('blocked')}</MenuItem>
          </TextField>
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={2} sx={{ mt: -1 }}>
          <TextField
            className='bg-white'
            InputLabelProps={{ shrink: true }}
            fullWidth
            select
            id='outlined-select-currency'
            label={t('deletion_status')}
            value={payload.deleted}
            onChange={(e) =>
              setPayload({ ...payload, deleted: e.target.value })
            }
            margin='dense'
            variant='outlined'
            size='small'
          >
            <MenuItem value={'0'}>{t('all')}</MenuItem>
            <MenuItem value={false}>{t('not_deleted')}</MenuItem>
            <MenuItem value={true}>{t('deleted')}</MenuItem>
          </TextField>
        </Grid>
      </Grid>

      <Grid
        item
        lg={1}
        md={1}
        sm={1}
        xs={1}
        className='flex justify-center h-full '
      >
        <Button
          variant='contained'
          className='h-full p-8'
          color='primary'
          type='submit'
          onClick={async () => {
            setSearchParams({
              ...currentSearchParams,
              ...payload,
              page: 0,
            });
            setIsRefreshing(isRefreshing + 1);
          }}
        >
          {t('apply')}
        </Button>
      </Grid>
    </Grid>
  );
}
