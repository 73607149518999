import { Grid } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeading from '../../../../components/common/PageHeading';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import {
  fetchTicketDetails,
  fetchUsersTicketcategories,
  setError,
} from '../../../../Redux/store/tickets/ticketsListSlice';
import TicketDetails from '../../components/TicketDetails';
import { useTicketTranslation } from '../../i18n';

const TicketDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefreshing, setIsRefreshing] = React.useState(0);
  const { id } = useParams();
  const t = useTicketTranslation();

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(fetchTicketDetails(id));
      await dispatch(fetchUsersTicketcategories());
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    fetchData();
  }, [isRefreshing]);

  return (
    <Grid
      container
      lg={12}
      md={12}
      spacing={2}
      justifyContent='center'
      className='pt-2 '
    >
      <Grid item lg={12} md={12} className='px-2  pl-2 text-white relative'>
        <PageHeading
          previousUrl={'tickets/users'}
          heading1={t('Tickets')}
          heading2={t('users_tickets_list')}
          heading3={t('tickets_details')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>

      <Grid item lg={12} md={12}>
        <TicketDetails
          type={'user'}
          isLoading={isLoading}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      </Grid>
    </Grid>
  );
};

export default TicketDetailsPage;
