import http from "../http";

export const deleteEvent = async (id) => {
  try {
    await http.delete("/dashboard/events/" + id);
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
};
