import React from 'react';
import { Route } from 'react-router-dom';
import UserDetailsPage from './pages/user-details-page';
import UsersListPage from './pages/user-list-page';

export default function Identity() {
  return (
    <Route path='/identity' key={'identity'}>
      <Route path='/identity/users/' element={<UsersListPage />} />
      <Route path='/identity/users/:id' element={<UserDetailsPage />} />
    </Route>
  );
}
