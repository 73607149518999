import { createSlice } from '@reduxjs/toolkit';
import { appApi } from './api';

const initialState = {
  alert: {
    message: '',
    isOpen: false,
  },
  dailyRateData: {
    acceptance_rates: [],
  },
  weeklyRateData: {
    acceptance_rates: [],
  },
  monthlyRateData: {
    acceptance_rates: [],
  },
  rateDetails: {},
  tripActualRoute: [],
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDailyRateData: (state, actions) => {
      state.dailyRateData = actions.payload;
    },
    setWeeklyRateData: (state, actions) => {
      state.weeklyRateData = actions.payload;
    },
    setMonthlyRateData: (state, actions) => {
      state.monthlyRateData = actions.payload;
    },
    setRateDetails: (state, actions) => {
      state.rateDetails = actions.payload;
    },
    setError: (state, actions) => {
      state.error = actions.payload;
    },
    setAlert: (state, actions) => {
      state.alert = actions.payload;
    },
    setTripActualRoute: (state, actions) => {
      state.tripActualRoute = actions.payload;
    },
  },
});

export const {
  setError,
  setAlert,
  setDailyRateData,
  setWeeklyRateData,
  setMonthlyRateData,
  setRateDetails,
  setTripActualRoute,
} = appSlice.actions;

export const setAlertMessage = (payload) => async (dispatch) => {
  dispatch(setAlert(payload));
};

export const fetchDailyRateData = (payload) => async (dispatch) => {
  try {
    const rateData = await appApi.getDailyRateData(payload);
    dispatch(setDailyRateData(rateData));
  } catch (err) {
    throw err;
  }
};

export const fetchWeeklyRateData = (payload) => async (dispatch) => {
  try {
    const rateData = await appApi.getWeeklyRateData(payload);
    dispatch(setWeeklyRateData(rateData));
  } catch (err) {
    throw err;
  }
};

export const fetchMonthlyRateData = (payload) => async (dispatch) => {
  try {
    const rateData = await appApi.getMonthlyRateData(payload);
    dispatch(setMonthlyRateData(rateData));
  } catch (err) {
    throw err;
  }
};
export const fetchRateDetails = (payload) => async (dispatch) => {
  try {
    const rateData = await appApi.getRateDetails(payload);
    dispatch(setRateDetails(rateData));
  } catch (err) {
    throw err;
  }
};

export const getTripActualRoute = (payload) => async (dispatch) => {
  try {
    const resp = await appApi.fetchActualRoute(payload);
    dispatch(setTripActualRoute(resp));
    return resp;
  } catch (error) {
    dispatch(setError(error));
    throw error;
  }
};

export default appSlice.reducer;
