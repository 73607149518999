import { Grid, TextField, Menu as MenuC, MenuItem } from '@mui/material';
import { Refresh, Menu, ArrowForward, ArrowBack } from '@mui/icons-material';
import moment from 'moment';
import React from 'react';
import { useDriverTranslation } from '../../i18n';
import { useTheme } from '@mui/styles';

const PerformanceTabControllers = ({
  type,
  setType,
  refrashState,
  setRefrashState,
  setDailyPayload,
  setWeeklyPayload,
  weeklypayload,
  dailypayload,
  previous,
  setPrevious,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [next, setNext] = React.useState(1);
  // const [pre, setpre] = React.useState(1);

  const handleNext = async () => {
    if (previous > 1) {
      if (type === 'daily') {
        setDailyPayload({
          from: moment(dailypayload.from).add(1, 'days').utc().toISOString(),
          to: moment(dailypayload.to).add(1, 'days').utc().toISOString(),
        });
      } else {
        setWeeklyPayload({
          from: moment(weeklypayload.from)
            .add(1, 'weeks')
            .format('YYYY-MM-DDThh:mm'),
          to: moment(weeklypayload.to)
            .add(1, 'weeks')
            .format('YYYY-MM-DDThh:mm'),
        });
      }
    } else {
      if (type === 'daily') {
        setDailyPayload({
          from: moment().startOf('day').format('YYYY-MM-DDThh:mm'),
          to: moment().endOf('day').format('YYYY-MM-DDThh:mm'),
        });
      } else {
        setWeeklyPayload({
          from: moment().startOf('week').utc().toISOString(),
          to: moment().endOf('week').utc().toISOString(),
        });
      }
      setPrevious(1);
      setNext(1);
    }
    setPrevious(previous - 1);
    setNext(next + 1);
    setRefrashState(refrashState + 1);

    console.log(previous);
  };

  const handlePrevious = async () => {
    if (type === 'daily') {
      setDailyPayload({
        from: moment(dailypayload.from).subtract(1, 'days').utc().toISOString(),
        to: moment(dailypayload.to).subtract(1, 'days').utc().toISOString(),
      });
    } else {
      setWeeklyPayload({
        from: moment(weeklypayload.from)
          .subtract(1, 'weeks')
          .utc()
          .toISOString(),
        to: moment(weeklypayload.to).subtract(1, 'weeks').utc().toISOString(),
      });
    }
    setPrevious(previous + 1);

    console.log(dailypayload, weeklypayload, previous);
    setRefrashState(refrashState + 1);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTypeChange = (type) => {
    setType(type);
    setPrevious(1);
    setWeeklyPayload({
      from: moment().startOf('week').utc().toISOString(),
      to: moment().endOf('week').utc().toISOString(),
    });
    setDailyPayload({
      from: moment().startOf('day').utc().toISOString(),
      to: moment().endOf('day').utc().toISOString(),
    });
    handleClose();
  };

  const t = useDriverTranslation();
  const theme = useTheme();
  return (
    <Grid
      container
      lg={12}
      md={12}
      justifyContent='space-around'
      spacing={1}
      className='p-2 bg-white'
    >
      {/* line1 */}
      <Grid item lg={2} md={2}>
        <Refresh
          color='primary'
          className=' hover:text-blue-500 hover:cursor-pointer hover:animate-pulse mr-2'
          onClick={() => setRefrashState(refrashState + 1)}
        />
      </Grid>
      <Grid
        item
        lg={8}
        md={8}
        className='text-center text-lg text-gray-700 font-serif font-bold'
      >
        {theme.direction == 'ltr'
          ? type
          : type == 'daily'
          ? 'يوميا'
          : 'إسبوعيا'}
      </Grid>
      <Grid item lg={2} md={2} className='flex justify-end'>
        <Menu
          color='primary'
          className=' hover:text-blue-500 hover:cursor-pointer hover:animate-pulse mr-2'
          onClick={handleClick}
        />
        <MenuC
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleTypeChange('daily')} value='daily'>
            {t('daily')}
          </MenuItem>
          <MenuItem onClick={() => handleTypeChange('Weekly')} value='weekly'>
            {t('weekly')}
          </MenuItem>
        </MenuC>
      </Grid>
      {/* line2 */}
      <Grid item lg={2} md={2}>
        <ArrowBack
          color='primary'
          className=' hover:text-blue-500 hover:cursor-pointer hover:animate-pulse mr-2'
          onClick={handlePrevious}
        />
      </Grid>
      {type === 'weekly' ? (
        <Grid
          item
          lg={8}
          md={8}
          className='text-center text-lg text-gray-700 font-serif font-bold'
        >
          {moment(weeklypayload.from).format('DD/MM')} -{' '}
          {moment(weeklypayload.to).format('DD/MM')}
        </Grid>
      ) : (
        <Grid
          item
          lg={8}
          md={8}
          className='text-center text-lg text-gray-700 font-serif font-bold'
        >
          {moment(dailypayload.from).format('DD/MM')}
        </Grid>
      )}
      <Grid item lg={2} md={2} className='flex justify-end'>
        {previous > 1 && (
          <ArrowForward
            color='primary'
            className=' hover:text-blue-500 hover:cursor-pointer hover:animate-pulse mr-2'
            onClick={handleNext}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PerformanceTabControllers;
