import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { rejectPendingDriver } from '../../../../Redux/store/pending-drivers/pendingDriversListSlice';
import { appDirection } from '../../../../shared_utils';
import { usePendingTranslation } from '../i18n';

export function RejectFormDialog({
  driver,
  driverId,
  isRefreshing,
  setIsRefreshing,
}) {
  const [reason, setReason] = React.useState(null);

  const [open, setOpen] = React.useState(false);

  const t = usePendingTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const handleRjection = async (event) => {
    try {
      await dispatch(
        rejectPendingDriver({
          id: driverId,
          reason: reason,
        })
      );

      toast.error(t('rejected'));
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsRefreshing(isRefreshing + 1);
      handleClose();
    }
  };

  return (
    <div dir={appDirection}>
      <Button
        variant='contained'
        color='primary'
        aria-label='outlined primary button group'
        onClick={handleClickOpen}
        disabled={driver.blocked}
      >
        {t('reject')}
      </Button>
      <Dialog
        fullWidth
        maxWidth='xs'
        aria-labelledby='dialog-title'
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id='dialog-title'>
          {t('reject_driver')} {driverId}
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent='center' className='pt-4' spacing={2}>
            <Grid item xs={12}>
              <TextField
                onChange={(e) => setReason(e.target.value)}
                label={t('reason')}
                value={reason}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid
              container
              lg={12}
              md={12}
              className='flex justify-center  p-2 '
              spacing={1}
            >
              <Grid item>
                <Button
                  variant='outlined'
                  color='error'
                  type='submit'
                  onClick={handleRjection}
                  disabled={reason === null || reason == '' ? true : false}
                >
                  {t('reject')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='outlined'
                  color='inherit'
                  onClick={handleClose}
                >
                  {t('cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
