import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import { deleteGroup } from '../../../../Redux/store/places/placesListSlice';
import { useNavigate } from 'react-router-dom';
import { usePlacesTranslation } from '../../i18n';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export default function AlertDialog({ isOpen, setIsOpen, id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = useCallback(async () => {
    try {
      await dispatch(deleteGroup(id));
      dispatch(
        setAlert({
          type: 'info',
          message: `${id} been deleted successfully`,
          isOpen: true,
        })
      );
      navigate('/places/groups');
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    }
  });

  const t = usePlacesTranslation();

  const handleCofiramation = () => {
    handleDelete();
    setIsOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' className='text-red-600'>
          {t('delete')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {t('delete_group_msg')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {t('cancel')}
          </Button>
          <Button onClick={handleCofiramation} color='error' autoFocus>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
