import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';

import { useSelector } from 'react-redux';
import Map from '../../tracking/components/common/order_details_tracking/Map';
import OrderTransactions from './tabs/OrderTransactions';
import OrderDetails from './tabs/OrderDetails';
import { useOrdersTranslation } from '../i18n';
import { AppBar, Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

//tabs config
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

//main component
const OrderTabs = ({ setIsRefreshing, isRefreshing }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { order } = useSelector((state) => state.orders).ordersList;

  const t = useOrdersTranslation();

  const mapProps = {
    driver_id: order.driver_id,
    pickup_lang: order.pick_up_longitude,
    pickup_lat: order.pick_up_latitude,
    dropoff_lat: order.drop_off_latitude,
    dropoff_lang: order.drop_off_longitude,
    route_ref: order.route_ref,
    dispatch_id: order.dispatch_id,
    status: order.status,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Grid container lg={12} md={12} className='bg-gray-100 '>
      <Grid item lg={12} md={12}>
        <AppBar position='static' color='default' className=''>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            aria-label='full width tabs example'
            className='px-4'
            centered
          >
            <Tab label={t('details')} {...a11yProps(0)} />
            <Tab label={t('transactions')} {...a11yProps(1)} />
            <Tab label={t('tracking')} {...a11yProps(2)} />
          </Tabs>
        </AppBar>
      </Grid>

      <Grid item lg={12} md={12} xs={12}>
        <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
          <TabPanel value={value} index={0} className=' h-auto min-h-[90vh]'>
            <OrderDetails
              isRefreshing={isRefreshing}
              setIsRefreshing={setIsRefreshing}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            dir={theme.direction}
            className=' h-auto min-h-[90vh] bg-white'
          >
            <OrderTransactions transactions={order.transactions} />
          </TabPanel>
          <TabPanel
            value={value}
            index={2}
            dir={theme.direction}
            className=' h-auto min-h-[90vh]'
          >
            <Grid container lg={12} md={12}>
              <Map {...mapProps} />
            </Grid>
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>

    // <Map />
  );
};

export default OrderTabs;
