import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Empty } from '../../../../components/new-components/Empty';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import CustomPagination from '../../../../components/new-components/CustomPagination';
import { useEventsTranslation } from '../../../services/i18n';
import { Avatar, Button, Grid } from '@mui/material';
import { Delete, Edit, Email, Info, Phone } from '@mui/icons-material';
import { Link } from 'react-router-dom';

export default function ProvidersTable({
  isLoading,
  rowsData,
  page,
  handlePagination,
}) {
  const t = useEventsTranslation();

  const cols = [
    {
      field: 'name',
      headerName: t('name'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <div className='flex w-full items-center mx-5 '>
          <div className='ml-2'>
            <Avatar
              alt={params.value.full_name_en}
              src={params.value.provider_logo}
            />
          </div>
          <div className='flex flex-col ml-3'>
            <p className=''>{params.value.full_name_ar}</p>
            <p className=''>{params.value.full_name_en}</p>
          </div>
        </div>
      ),
    },

    {
      field: 'btn',
      headerName: t('actions'),
      headerClassName: 'bg-gray-100 text-center w-full h-full',
      disableColumnMenu: true,
      flex: 0.8,
      renderCell: (params) => (
        <Grid lg={12} justifyContent='center' alignItems={'center'}>
          <Link to={'/events/add/' + params.value.provider_id}>
            <Button variant='contained' color='primary'>
              {t('choose')}
            </Button>
          </Link>
        </Grid>
      ),
    },
  ];
  return (
    <div className='flex h-auto w-full justify-center'>
      <div className='flex w-3/4'>
        <DataGrid
          className='border-2'
          disableColumnFilter
          autoHeight
          rowHeight={60}
          page={page}
          loading={isLoading}
          rows={rowsData}
          columns={cols}
          pageSize={10}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => handlePagination(newPage)}
          CellStyle='{StaticResource DataGridBorder}'
          components={{
            Toolbar: () => <></>,
            Pagination: () => CustomPagination(isLoading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: LoadingIndicator,
          }}
        />
      </div>
    </div>
  );
}
