import http from '../http';

const getTicketMessages = async (payload) => {
  const baseURL = process.env.REACT_APP_TICKETS_API;
  try {
    const messages = await http.get(
      `${baseURL}/ticket_messages?ticket_id=${payload.ticket_id}&page_size=${payload.page_size}`
    );
    return messages.data;
  } catch (err) {
    throw err.response;
  }
};

export default getTicketMessages;
