import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setAlert } from '../../../Redux/store/app/appSlice';
import { getGroupDetails } from '../../../Redux/store/places/placesListSlice';
import PageHeading from '../../../components/common/PageHeading';
import GroupDetails from '../components/GroupDetails';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { usePlacesTranslation } from '../i18n';
import { toast } from 'react-toastify';

const GroupDetailsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(0);
  const dispatch = useDispatch();

  const { id } = useParams();

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(getGroupDetails({ group_id: id }));
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    fetchData();
  }, [isRefreshing]);

  const t = usePlacesTranslation();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Grid container lg={12} md={12} spacing={2}>
      <Grid item lg={12} md={12} className='px-2  pl-2 text-white relative'>
        <PageHeading
          heading1={t('places')}
          heading2={t('group_details')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>

      {/* main view */}
      <Grid item lg={12} md={12} xs={12}>
        <GroupDetails
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      </Grid>
    </Grid>
  );
};

export default GroupDetailsPage;
