import { configureStore } from '@reduxjs/toolkit';
import userIdentitySlice from '../features/userIdentitySlice';

const identityStore = configureStore({
  reducer: {
    userIdentitySlice,
  },
});

export default identityStore;
