import { Route } from 'react-router-dom';
import DrivesList from './pages/drivers_list_page';
import Driver from './pages/driver_page';
import { PendingDriversRoutes } from '../pending_drivers/Routes';

export function DriversRoutes() {
  return [
    <Route path='/drivers' key={55}>
      <Route path='/drivers/' element={<DrivesList />} key={'all-drivers'} />
      <Route path='/drivers/:id/:type' element={<Driver />} key={'driver-info'} />
      {PendingDriversRoutes()}
    </Route>,
  ];
}
