const ticket_en = {
  drivers_tickets_list: 'Drivers Tickets List',
  assign_support_user: 'Assign Support User',
  phone: 'Phone',
  from: 'From',
  to: 'To',
  tickets_details: 'Tickets Details',
  change_status: 'Change status',
  low: 'LOW',
  medium: 'MEDIUM',
  high: 'HIGH',
  critical: 'CRITICAL',
  order_id: ' Order ID',
  ticket_id: 'Ticket ID ',
  support_user: 'Support user',
  support_note: 'Support note',
  under_process: 'Under Process',
  parent_category: 'Parent category',
  child_category: 'Child category',
  priority: 'Priority',
  update_ticket: 'Update Ticket',
  update_status: 'Update Status',
  notify_user: 'notify user',
  add_note: 'Add Note',
  creation_date: 'Creation date',
  created_by: 'Created by',
  created_at: 'Created at',
  drivers: 'Drivers',
  driver: 'Driver',
  description: 'Description',
  category: 'Category',
  status: 'Status',
  dates: 'Dates',
  date: 'Date',
  actions: 'Actions',
  action: 'Action',
  note: 'Note',
  supervisor_name: 'Supervisor name',
  users: 'Users',
  user: 'user',
  text_msg: 'text message ...',
  ticket_transactins: 'Ticket transactions',
  users_tickets_list: 'Users Tickets List',
  updated_successfully: 'Updated successfully',
  msg_note: 'note added successfully',
  created_success: 'Created successfully',
  msg_asign: 'assigned successfully',
  new_ticket: 'New Ticket',
};

export default ticket_en;
