const places_en = {
  places: 'Places',
  places_list: 'Places List',
  select_amenity: 'Select Amenity',
  selected_amenity: 'Selected Amenity',
  name_en: 'Name en',
  name_ar: 'Name ar',
  mobile: 'Mobile',
  website: 'Website',
  address: 'Address',
  set_Location_on_a_map: 'Set Location On A Map',
  latitude: 'Latitude',
  longitude: 'Longitude',
  place_images: 'Place Images',
  place_logo: 'Place Logo',
  create: 'Create',
  amenity: 'Amenity',
  open: 'Open',
  upload: 'Upload',
  logo: 'Logo',
  add_place: 'Add Place',
  place_name: 'Place Name',
  status: 'Status',
  all: 'All',
  deleted: 'Deleted',
  not_deleted: 'Not Deleted',
  apply: 'Apply',
  place_info: 'Place Info',
  user: 'User',
  visibility: 'Visibility',
  dates: 'Dates',
  actions: 'Actions',
  hidden: 'Hidden',
  visible: 'Visible',
  previous: 'Previous',
  next: 'Next',
  warning: 'Warning',
  dialog_show_msg: 'Are you sure do you want to show this place',
  dialog_hide_msg: 'Are you sure do you want to hide this place',
  cancel: 'Cancel',
  confirm: 'Confirm',
  place_details: 'Place Details',
  phone: `Place's Phone`,
  user_name: 'User Name',
  email: 'Email',
  created_at: 'Created At',
  updated_at: 'Updated At',
  ratings: 'Ratings',
  total: 'Total',
  dialog_delete_msg: 'Are you sure do you want to delete this place',
  delete: 'Delete',
  add_place_to_group: 'Add place to group',
  edit: 'Edit',
  edit_form: 'Edit Form',
  set_location_on_a_map: 'Set location on a map',
  submit: 'Submit',
  delete_place: 'Delete Place',
  add_to_group: 'Add to group',
  add_new_image: 'Add new image',
  no_images: 'No Images',
  loading: 'Loading',
  details: 'Details',
  images: 'Images',
  reviews: 'Reviews',
  comment: 'Comment',
  likes: 'Likes',
  date: 'Date',
  view: 'View',
  delete_message: 'Delete Message',
  replies: 'Replies',
  user_phone: 'User Phone',
  place: 'Place',
  reports: 'Reports',
  note: 'Note',
  message: 'Message',
  approve_report_msg: 'Are you sure do you want to approve this report',
  reject_report_msg: 'Are you sure do you want to approve this report',
  group_type: 'Group Type',
  manually_selected: 'Manually Selected',
  automatically_selected: 'Automatically Selected',
  groups_list: 'Groups List',
  user_mobile: 'User Mobile',
  place_order: 'Place Order',
  group_details: 'Group Details',
  group_id: 'Group ID',
  key: 'Key',
  type: 'Type',
  personal_info: 'Personal Info',
  edit_group: 'Edit Group',
  update: 'Update',
  delete_group_msg: 'Are you sure do you want to delete this group',
  place_page: 'Place page',
  delete_img_msg: 'Are you sure do you want to delete this image',
  place_added_successfuly: 'Place added successfuly',
  add_group: 'Add Group',
  places_added_to_group_successfuly: 'Places added to group successfuly',
  place_created_successfully: 'Place created successfully',
  pending: 'Pending',
  approved: 'Approved',
  rejected: 'Rejected',
  approve: 'Approve',
  no_images: 'No Images',
  delete_place_msg: 'Are you sure do you want to delete this place',
  done_successfully: 'Done successfully',
  link_event_to_place: 'Link event to place',
  event_id: 'رقم الفعالية',
  event_provider: 'مزود الفعالية',
};

export default places_en;
