import axios from 'axios';
import GET_CURRENT_LANG from '../../shared_utils';

const auth = 'Bearer ';
const http = axios.create({
  baseURL: process.env.REACT_APP_PLACES_API,
  headers: {
    //
    'Content-Type': 'application/json',
    'x-lemon-client-id': process.env.REACT_APP_CLIENT_ID,
    'x-lemon-client-secret': process.env.REACT_APP_CLIENT_SECRET,
  },
});

http.interceptors.request.use(function (config) {
  let token = JSON.parse(localStorage.getItem('token'));
  config.headers['Authorization'] = auth.concat(token);
  config.headers.lang = GET_CURRENT_LANG();
  return config;
});

export default http;
