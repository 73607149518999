import React, { useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import default_image from '../../../../assets/images/defult-pic.png';
import { Button, Chip, Grid, ListSubheader, Tooltip } from '@mui/material';
import { Rating } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { formatDate } from '../../utils';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import EditDialog from '../common/FormDialog';
import LocalTaxi from '@mui/icons-material/LocalTaxi';
import { BlockDialog } from '../common/BlockDialog';
import ImageDialog from '../common/ImageDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDriversTicketcategories,
  setError,
} from '../../../../Redux/store/tickets/ticketsListSlice';
import CreateTicketDialog from '../../../tickets/components/common/CreateTicketDialog';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import {
  Android,
  Apple,
  Build,
  CalendarToday,
  ColorLens,
  Description,
  Group,
  MonetizationOnRounded,
  Person,
} from '@mui/icons-material';
import Status from '../common/Status';
import { fetchFeesGroups } from '../../../../Redux/store/drivers/driversListSlice';
import { appDirection } from '../../../../shared_utils';
import { useDriverTranslation } from '../../i18n';

// avatar style
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

function DriverMainDetails({
  block,
  unBlock,
  updateDriverInfo,
  isRefreshing,
  setIsRefreshing,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { driver, carTypes } = useSelector(
    (state) => state.drivers
  ).driversList;
  const t = useDriverTranslation();
  //cartype
  const handleType = (carTypeId) => {
    const res = carTypes.map((carType) => {
      if (carType.id === carTypeId) {
        return carType.car_name;
      }
    });
    return res;
  };

  // dialog settings
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [blockDialog, setBlockDialog] = React.useState(false);

  // img dialog settings
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpeningImage = () => {
    if (driver.profile_image) {
      setIsOpen(true);
    }
  };
  const [isLoading, setIsLoading] = React.useState(false);
  // create ticket dialog settings
  const [ticketDialogstatus, setTicketDialogstatus] = React.useState(false);
  const createTicketDialogProps = {
    setIsOpen: setTicketDialogstatus,
    isOpen: ticketDialogstatus,
    userId: driver.identity_id,
    client_id: 'DISPATCH_ID',
    service_id: 'dispatch',
    referenceId: null,
    isRefreshing: isRefreshing,
    setIsRefreshing: setIsRefreshing,
  };

  const getCatogries = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(fetchDriversTicketcategories());
      await dispatch(fetchFeesGroups());
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    getCatogries();
  }, []);

  const navigate = useNavigate();

  const handleCarstatus = (id) => {
    switch (id) {
      case 1:
        return 'New';
      case 2:
        return 'Very Good';
      case 3:
        return 'Good';

      default:
        break;
    }
  };

  const handleUpdateOpen = () => {
    setDialogOpen(true);
  };
  if (isLoading) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-screen place-content-center'>
          <div class='flex items-center  text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            loading...
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <Grid
      container
      lg={12}
      md={12}
      xs={12}
      className='relative'
      dir={appDirection}
    >
      <BlockDialog
        t={t}
        open={blockDialog}
        onClose={(payload) => {
          block(payload);

          setBlockDialog(false);
        }}
        close={() => setBlockDialog(false)}
      />
      <ImageDialog
        open={isOpen}
        setOpen={setIsOpen}
        image_url={driver.profile_image}
      />

      {/* edit btn */}
      {/* <Tooltip
        disableFocusListener
        title='Edit'
        className=' absolute top-4 right-4 cursor-pointer hover:bg-blue-600 rounded-lg'
        onClick={handleUpdateOpen}>
        <EditIcon />
      </Tooltip> */}
      {/* <EditDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        driver={driver}
        carTypes={carTypes}
        action={updateDriverInfo}
        type='DriverInfo'
        title='Edit Driver Info'
      /> */}

      {/* back btn */}
      {/* <Grid className='  text-white absolute top-4 left-4 p-1 px-2 pb-1  '>
        <ArrowBackRoundedIcon
          fontSize=' large'
          className='text-gray-700  hover:bg-blue-500'
          onClick={() => navigate(-1)}
        />
      </Grid> */}
      <Grid item lg={12} md={12} xs={12}>
        {/* <DriverTreeView /> */}
        <Grid item lg={12} md={12} xs={12} className=' '>
          {/* profile img  */}
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            className='flex justify-center  cursor-pointer'
          >
            <Avatar
              src={driver.profile_image || default_image}
              sx={{ height: '90px', width: '90px' }}
              onClick={handleOpeningImage}
            />
          </Grid>

          {/* some info */}
          {/* online badge */}
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            className='flex justify-center max-w-full'
          >
            <Grid item lg={2} md={2}>
              <Status driver={driver} />
            </Grid>
          </Grid>

          {/* header */}
          <Grid item lg={12} md={12} xs={12} className=' text-center '>
            <h1 className=' font-serif font-extrabold text-gray-700 text-2xl'>
              {driver.full_name}
            </h1>

            <Rating
              style={{ padding: '5px' }}
              readOnly
              name='simple-controlled'
              value={driver.avg_rating}
            />
          </Grid>
          <Divider variant='middle' />
          {/* body */}
          <Grid container lg={12} md={12} className='' spacing={2}>
            {/* leftside */}
            <Grid item lg={4} md={4}>
              <List
                dense={true}
                subheader={
                  <ListSubheader
                    sx={{ backgroundColor: '#f3f4f6', fontSize: 'large' }}
                    component='div'
                    id='nested-list-subheader'
                  >
                    {t('driver_info')}
                  </ListSubheader>
                }
              >
                {/* item 1 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <Person className='text-black w-2' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('driver_id')}
                    secondary={driver.driver_id || '...'}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 1 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <Avatar className={classes.small}>
                        <PhoneInTalkIcon className='text-black w-2' />
                      </Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('mobile_number')}
                    secondary={driver.mobile_number || '...'}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 2 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <Avatar className={classes.small}>
                        <CreditCardIcon className='text-black' />
                      </Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('driver_license')}
                    secondary={driver.driver_licence || '...'}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 3 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <Avatar className={classes.small}>
                        <FingerprintIcon className='text-black' />
                      </Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('national_id')}
                    secondary={driver.national_id || '...'}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 4 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <Avatar className={classes.small}>
                        <Group className='text-black w-2' />
                      </Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('referral_number')}
                    secondary={driver.referral_number || '...'}
                  />
                </ListItem>

                <Divider variant='inset' component='li' />

                {/* item 4 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <Avatar className={classes.small}>
                        {driver.device_os === 'ios' ? (
                          <Apple className='text-white' />
                        ) : (
                          <Android className='text-green-600' />
                        )}
                      </Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('device_os')}
                    secondary={driver.device_os || '...'}
                  />
                </ListItem>
              </List>
            </Grid>
            {/* rigthside */}
            <Grid item lg={4} md={4}>
              {/* car info */}
              <List
                dense={true}
                subheader={
                  <ListSubheader
                    sx={{ backgroundColor: '#f3f4f6', fontSize: 'large' }}
                    component='div'
                    id='nested-list-subheader'
                  >
                    {t('car_info')}
                  </ListSubheader>
                }
              >
                {/* item 1 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <Avatar className={classes.small}>
                        <LocalTaxi className='text-black w-2' />
                      </Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('plate_number')}
                    secondary={driver.car_plate || '...'}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 2 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <Avatar className={classes.small}>
                        <ColorLens className='text-black' />
                      </Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('color')}
                    secondary={driver.car_color || '...'}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 3 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <Avatar className={classes.small}>
                        <Description className='text-black' />
                      </Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('car_model')}
                    secondary={driver.car_desc || '...'}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 4 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <Avatar className={classes.small}>
                        <Build className='text-black' />
                      </Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('car_status')}
                    secondary={handleCarstatus(driver.car_status_id) || '...'} //FIXME:
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item  */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <Avatar className={classes.small}>
                        <CalendarToday className='text-black' />
                      </Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('manufacture_date')}
                    secondary={driver.car_model_year || '...'} //FIXME:
                  />
                </ListItem>

                {/* item 4 */}
              </List>
            </Grid>{' '}
            <Grid item lg={4} md={4}>
              {' '}
              {/* account info */}
              <List
                dense={true}
                subheader={
                  <ListSubheader
                    sx={{ backgroundColor: '#f3f4f6', fontSize: 'large' }}
                    component='div'
                    id='nested-list-subheader'
                  >
                    {t('account_info')}
                  </ListSubheader>
                }
              >
                {/* item 1 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <Avatar className={classes.small}>
                        <LocalTaxi className='text-black' />
                      </Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('car_type')}
                    secondary={handleType(driver.car_type_id) || '...'} //FIXME:
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 2 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <Avatar className={classes.small}>
                        <MonetizationOnRounded className='text-black' />
                      </Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('fees_groups')}
                    secondary={
                      driver.fees_group ? driver.fees_group.group_name : '...'
                    }
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 2 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <Avatar className={classes.small}>
                        <DateRangeIcon className='text-black' />
                      </Avatar>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('date')}
                    secondary={formatDate(driver.created_at) || '...'}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
          {/* block buttons */}
          <Grid constainer className='flex justify-center p-2 ' spacing={2}>
            <Grid item className='p-2'>
              <EditDialog
                driver={driver}
                carTypes={carTypes}
                driverId={driver.driverId}
                isRefreshing={isRefreshing}
                setIsRefreshing={setIsRefreshing}
                action={updateDriverInfo}
                type='DriverInfo'
                title='Edit Driver Info'
              />
            </Grid>

            <Grid item className='p-2'>
              <Button
                variant='contained'
                color='primary'
                onClick={() => setTicketDialogstatus(true)}
              >
                {t('create_ticket')}
              </Button>
            </Grid>

            <Grid item className='p-2'>
              <Button
                variant='contained'
                color='primary'
                disabled={!driver.blocked}
                onClick={unBlock}
              >
                {t('unblock')}
              </Button>
            </Grid>

            <Grid item className='p-2'>
              <Button
                variant='contained'
                color='primary'
                disabled={driver.blocked}
                onClick={() => setBlockDialog(true)}
              >
                {t('block')}
              </Button>
            </Grid>
            <CreateTicketDialog {...createTicketDialogProps} />
          </Grid>
          <div className='flex space-x-5 pt-5 justify-center'></div>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default DriverMainDetails;
