import * as yup from 'yup';

export const authValidationSchema = yup.object({
	email: yup
		.string('Enter your email')
		.email('Enter a valid email')
		.required('Email is required'),
	password: yup
		.string('Enter your password')
		.min(8, 'Password should be of minimum 8 characters length')
		.required('Password is required'),
});

const phoneRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const driverFromValidationSchema = yup.object({
	full_name: yup
        .string('Enter the Name')
        .required('Name is required')
        .min(2,'To long'),
	Phone: yup
        .string('Enter the phone number')
        .required('phone number is required')
        .matches(phoneRegExp, 'Phone number is not valid'),
    driver_licence:yup
        .string('Enter the')
        .required('driver licence '),
    
});
