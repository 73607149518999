import { AccountCircle, Flag, PhoneAndroid, VisibilityOutlined } from '@mui/icons-material';
import { Button, Chip, Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../../shared/scripts/dateFormate';
import AssignDialog from './common/AssignDialog';
import ListFiler from './common/ListFilters';
import PageHeading from '../../../../components/common/PageHeading';
import {
  fetchData,
  setPage,
} from '../../../core/features/drivers-tickets-list/driversTicketslListSlice';
import Table from '../../../../components/new-components/Table';

const TicketsList = () => {
  const navigate = useNavigate();

  //assign dialog configuration
  const [isOpen, setIsOpen] = useState(false);
  const [statusId, setStatusId] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(0);
  const [selectedTicketId, setSelectedTicketId] = useState(0);
  const handleOpenAssignDialog = useCallback((ticketId) => {
    setSelectedTicketId(ticketId);
    setIsOpen(true);
  });

  const dispatch = useDispatch();

  const handleCloseAction = useCallback(async () => {
    setIsOpen(false);
  });

  const { tickets, isLoading, page } = useSelector((state) => state.driversTicketsList);

  const handleStatusId = useCallback((id) => {
    switch (id) {
      case 0:
        return <Chip size='small' label='new' />;
      case 1:
        return <Chip size='small' label='under_process' />;
      case 2:
        return <Chip size='small' label='closed' />;

      default:
        break;
    }
  });

  const handlePriority = useCallback((id) => {
    switch (id) {
      case 1:
        return (
          <Flag fontSize='small' className='text-green-600' />
          // <span className='text-xs font-serif font-semibold'>
          //   LOW <Flag fontSize='small' className='text-green-600' />
          // </span>
        );
      case 2:
        return (
          <Flag fontSize='small' className='text-yellow-200' />
          // <span className='text-xs font-serif font-semibold'>
          //   MEDIUM <Flag fontSize='small' className='text-blue-600' />
          // </span>
        );
      case 3:
        return (
          <Flag fontSize='small' className='text-orange-400' />
          // <span className='text-xs font-serif font-semibold'>
          //   HIGH <Flag fontSize='small' className='text-red-400' />
          // </span>
        );
      case 4:
        return (
          <Flag fontSize='small' className='text-red-700' />
          // <span className='text-xs font-serif font-semibold'>
          //   CRITICAL <Flag fontSize='small' className='text-red-700' />
          // </span>
        );
      default:
        return <span className='text-xs font-serif font-semibold'>.....</span>;
    }
  });
  const handlePagination = async (newPage) => {
    await dispatch(setPage(newPage));
    setIsRefreshing(isRefreshing + 1);
  };
  const cols = [
    {
      field: 'clientInfo',
      headerName: 'Driver',
      headerClassName: ' bg-gray-500 text-white ',

      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs p-1 hover:bg-blue-200  hover:cursor-pointer'
          // FIXME:
          onClick={() => navigate(`/drivers/${params.value.user_id}/identity`)}>
          <Grid item lg={8} md={8}>
            <Grid item lg={12} md={12} xs={12}>
              <AccountCircle fontSize='small' className='text-gray-500' />{' '}
              {params.value.user.full_name}
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <PhoneAndroid fontSize='small' className='text-gray-500' /> {params.value.user.mobile}
            </Grid>
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'category',
      headerName: 'category',
      headerClassName: ' bg-gray-500 text-white',
      flex: 2.5,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid container lg={12} md={12} className='text-xs pr-4'>
          <Grid item lg={12} md={12}>
            <span className=' text-xs text-gray-700 font-mono font-semibold w-full text-center'>
              {params.value.parent_category.category_name}
            </span>
          </Grid>
          <Grid item lg={12} md={12} className='pt-1'>
            <span className=' text-gray-700 text-xs  '>
              {params.value.child_category.category_name}
            </span>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'status',
      headerName: 'status',
      headerClassName: ' bg-gray-500 text-white',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid container lg={12} md={12} xs={12} spacing={2} justifyContent='center'>
          <Grid item lg={12} md={12} xs={12}>
            {handleStatusId(params.value.status_id)} {handlePriority(params.value.priority)}
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            {/* {handlePriority(params.value.priority)} */}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'support_user',
      headerName: 'support_user',
      headerClassName: ' bg-gray-500 text-white ',

      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid container lg={12} md={12} xs={12} spacing={2} className='text-xs p-1'>
          <Grid item lg={8} md={8}>
            <Grid item lg={12} md={12} xs={12}>
              <AccountCircle fontSize='small' className='text-gray-500' />{' '}
              {params.value.support_user_id ? params.value.support_user.full_name : '.....'}
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <PhoneAndroid fontSize='small' className='text-gray-500' />{' '}
              {params.value.support_user_id ? params.value.support_user.mobile : '.....'}
            </Grid>
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'dates',
      headerName: "Date's",
      headerClassName: ' bg-gray-500 text-white',
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid container lg={12} md={12} className='text-xs'>
          <Grid item lg={12} md={12} className='p-2 text-gray-700 text-center'>
            {formatDate(params.value.created_at)}
          </Grid>
          <Grid item lg={12} md={12} className='p-2 text-gray-700 text-center'>
            {formatDate(params.value.updated_at)}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'actions',
      headerName: 'actions',
      headerClassName: ' bg-gray-500 text-white text-center w-full',

      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid container lg={12} md={12} justifyContent='center'>
          <Grid item lg={12} md={12} className='p-2 text-gray-700 text-center'>
            <Button
              color='primary'
              onClick={() => navigate(`/dispatch/drivers-tickets/${params.value.ticket_id}`)}>
              <VisibilityOutlined fontSize='small' />
            </Button>
          </Grid>
          <Grid item lg={12} md={12} className='p-2 text-gray-700 text-center'>
            <AssignDialog
              handleCloseAction={handleCloseAction}
              isOpen={isOpen}
              ticketId={selectedTicketId}
              isRefreshing={isRefreshing}
              setIsRefreshing={setIsRefreshing}
              handleOpenAssignDialog={handleOpenAssignDialog}
            />
          </Grid>
        </Grid>
      ),
    },
  ];
  const rowsData = tickets.map((tictet) => {
    return {
      id: tictet.ticket_id,
      status: tictet,
      clientInfo: tictet,
      support_user: tictet,
      category: tictet,
      dates: tictet,
      actions: tictet,
    };
  });

  const tableProps = {
    isLoading,
    pagination: handlePagination,
    loaderType: 'page',
    rowsData,
    cols,
  };
  useEffect(() => {
    // fetchData();
    dispatch(fetchData());
    // console.log(tickets);
  }, [isRefreshing]);

  return (
    <Grid container lg={12} md={12} justifyContent='center' spacing={2}>
      <Grid item lg={12} md={12} className=' flex justify-center'>
        <PageHeading
          heading1={'Tickets'}
          heading2={'Drivers Tickets List'}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>
      <Grid item lg={12} md={12}>
        <ListFiler
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          statusId={statusId}
          setStatusId={setStatusId}
        />
      </Grid>
      <Grid item lg={12} md={12}>
        <Table {...tableProps} />
      </Grid>
    </Grid>
  );
};

export default TicketsList;
