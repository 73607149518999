import http from '../http';

const getDetail = async (payload) => {
  const baseURL = process.env.REACT_APP_DISPATCH_API;
  try {
    const response = await http.get(
      `${baseURL}/dashboard/acceptance_rate/weakly_acceptance_rate?from=${payload.from}&to=${payload.to}`
    );
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

export default getDetail;
