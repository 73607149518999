import http from '../http';

const deleteUserById = async (payload) => {
  const baseURL = process.env.REACT_APP_IDENTITY_API;
  try {
    const response = await http.delete(
      `${baseURL}/dashboard/users/${payload.id}`
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      throw err.response.statusText;
    } else {
      throw err;
    }
  }
};

export default deleteUserById;
