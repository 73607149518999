import http1 from '../../../services/APIs/dispatch_api';

export const carsApi = {
  fetchCarTypesData: async () => {
    try {
      const response = await http1.get('/dashboard/car_types');
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  fetchCarDetails: async ({ id, payload }) => {
    try {
      const response = await http1.put(`/dashboard/car_types/${id}`, payload);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
};
