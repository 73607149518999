import { Grid } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  fetchDispatches,
  setError,
} from '../../../Redux/store/dispatches/dispatchesListSlice';
import DispatchesTable from '../../drivers/components/common/DispatchesTable';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import ListFiler from '../components/common/Listfilters';
import PageHeading from '../../../components/common/PageHeading';
import { setAlert } from '../../../Redux/store/app/appSlice';
import { useDispatchTranslation } from '../i18n';
import { toast } from 'react-toastify';
import { createSearchParams, useSearchParams } from 'react-router-dom';

const DispatchesListPage = () => {
  const dispatch = useDispatch();
  const { dispatches } = useSelector(
    (state) => state.dispatches
  ).dispatchesList;
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = React.useState(0);
  const t = useDispatchTranslation();

  const [searchParams, setSearchParmas] = useSearchParams(
    createSearchParams({
      page: 0,
      pageSize: 20,
    })
  );

  const currnetSearchParams = useMemo(() => ({
    page: searchParams.get('page'),
    pageSize: searchParams.get('pageSize'),
  }));

  const setPage = (newPage) => {
    setSearchParmas({
      ...currnetSearchParams,
      page: newPage,
    });
    setIsRefreshing((prev) => prev + 1);
  };

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(fetchDispatches(currnetSearchParams));
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    fetchData();
  }, [isRefreshing]);

  //   if (isLoading) {
  //     return <LoadingIndicator />;
  //   }

  return (
    <Grid container justify='center' spacing={2}>
      <Grid item lg={12} md={12}>
        {' '}
        <PageHeading
          heading1={t('dispatches')}
          heading2={t('dispatches_list')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>
      <Grid item lg={12} md={12} xs={12}>
        {/* <ListFiler /> */}
        <DispatchesTable
          data={dispatches}
          loading={isLoading}
          setPage={setPage}
          page={Number(currnetSearchParams.page)}
          Ikey={'all'}
        />
      </Grid>
    </Grid>
  );
};

export default connect(null, { fetchDispatches, setError })(DispatchesListPage);
