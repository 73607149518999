import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { useDriverTranslation } from '../../i18n';

const Chart = ({ data, type }) => {
  const typeOfMauser = type;
  const t = useDriverTranslation();
  if (typeOfMauser === 'daily') {
    return (
      <Line
        datasetIdKey='id'
        data={{
          labels: data.map((t) => t.hour + 1),
          datasets: [
            {
              id: 1,
              label: t('SDG'),
              data: data.map((t) => t.total_price),
              backgroundColor: ['blue'],
            },
          ],
        }}
      />
    );
  } else {
    return (
      <Bar
        datasetIdKey='id'
        data={{
          labels: data.map((t) => `day ${t.day} `),
          datasets: [
            {
              id: 1,
              label: t('SDG'),
              data: data.map((t) => t.total_price),
              backgroundColor: ['blue'],
            },
          ],
        }}
      />
    );
  }
};

export default Chart;
