import http from '../http';

const updateCartype = async ({ id, payload }) => {
  const baseURL = process.env.REACT_APP_DISPATCH_API;
  try {
    const response = await http.put(
      `${baseURL}/dashboard/car_types/${id}`,
      payload
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      throw err.response.statusText;
    } else {
      throw err;
    }
  }
};

export default updateCartype;
