import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Typography, withStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import OrderForm from './OrderForm';

export default function OrderDialog({
  setRemoveOrderId,
  isLoading,
  setIsLoading,
}) {
  const { pointsList } = useSelector((state) => {
    return state.shareTrip.shareTripList;
  });

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (pointsList.length == 0) return handleClose();
  });
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitlee = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant='h6'>{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return (
    <div>
      <Button
        // variant="contained"
        className='w-full border-t-2 text-yellow-400'
        style={{ color: '#E6B400' }}
        onClick={handleClickOpen}
      >
        Review
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitlee
          id='form-dialog-title'
          onClose={handleClose}
        ></DialogTitlee>
        <DialogContent>
          <OrderForm
            setRemoveOrderId={setRemoveOrderId}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            open={open}
            setOpen={setOpen}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button> */}
          {/* <Button onClick={handleClose} color="primary">
            Subscribe
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
