import React from 'react';
import {
  DataGrid,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';

import { Button, CircularProgress, Grid } from '@mui/material';

import { Empty } from '../../drivers/components/common/Empty';
import { Avatar } from '@mui/material';
import default_image from '../../../assets/images/defult-pic.png';

import { ColorLens } from '@mui/icons-material';
import { LocalTaxi } from '@mui/icons-material';
import { CalendarToday } from '@mui/icons-material';
import { EventAvailable } from '@mui/icons-material';
import { Visibility } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { useSelector } from 'react-redux';
import { formatDate } from '../../drivers/utils';
import { usePendingTranslation } from './i18n';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';

function CustomPagination(loading) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid
      container
      className=' flex justify-center relative space-x-4 py-4 w-full'
      spacing={1}
    >
      <Grid item>
        <Button
          disabled={page === 0 || loading ? true : false}
          variant='contained'
          color='primary'
          onClick={() => apiRef.current.setPage(page - 1)}
        >
          {t('previous')}
        </Button>
      </Grid>

      <Grid item>
        <Button
          disabled={loading}
          variant='contained'
          color='primary'
          onClick={() => apiRef.current.setPage(page + 1)}
        >
          {t('next')}
        </Button>
      </Grid>
      <Grid item>
        {loading ? (
          <CircularProgress
            className={
              theme.direction === 'ltr' ? 'absolute right-8' : 'absolute left-8'
            }
          />
        ) : (
          <span
            className={
              '' + theme.direction === 'ltr'
                ? 'p-2 px-4 rounded-full bg-gray-300  font-bold absolute right-8'
                : 'p-2 px-4 rounded-full bg-gray-300  font-bold absolute left-8'
            }
          >
            {page + 1}
          </span>
        )}
      </Grid>
    </Grid>
  );
}

const DriversTable = ({ data, setPage, page, loading }) => {
  const t = usePendingTranslation();
  const { drivers } = useSelector(
    (state) => state.pendingDrivers
  ).pendingDrivers;

  const cols = [
    {
      field: 'DriverInfo',
      headerName: t('drivers'),
      headerClassName: 'bg-gray-100   fond-extrabold ',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs p-1'
        >
          <Grid item lg={2} md={2}>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className='flex justify-center  cursor-pointer'
            >
              <Avatar src={params.value.profile_image || default_image} />
            </Grid>
          </Grid>
          <Grid item lg={8} md={8}>
            <Grid item lg={12} md={12} xs={12}>
              {params.value.full_name}
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              {params.value.mobile_number}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'Status',
      headerName: t('status'),
      headerClassName: 'bg-gray-100   fond-extrabold ',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          justifyContent='center'
          spacing={2}
          className='text-xs font-semibold pl-2'
        >
          <Grid item lg={10} md={10}>
            {params.value && (
              <span
                className={
                  params.value.id === 1
                    ? 'text-green-600'
                    : params.value.id === 2
                    ? 'text-orange-400'
                    : 'text-red-600'
                }
              >
                {params.value.status_name}
              </span>
            )}
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'CarInfo',
      headerName: t('car_info'),
      headerClassName: 'bg-gray-100 px-2  fond-extrabold ',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid container lg={12} md={12} xs={12} spacing={2} className='text-xs'>
          <Grid item lg={6} md={6}>
            <Grid item lg={12} md={12} xs={12}>
              <LocalTaxi fontSize='small' />
              <span className='text-gray-600 leading-4 pl-2'>
                {params.value.car_desc || '.....'}
              </span>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <ColorLens fontSize='small' />
              <span className='text-gray-600 leading-4 pl-2'>
                {params.value.car_color || '.....'}
              </span>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <CalendarToday fontSize='small' />
              <span className='text-gray-600 leading-4 pl-2'>
                {params.value.car_model_year || '.....'}
              </span>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6}>
            <Grid item lg={12} md={12}>
              <p className='text-white'>.</p>
            </Grid>
            {/* just for hold the design */}
            <Grid item lg={12} md={12} xs={12}>
              <div className=' font-extrabold border-2 w-24 border-gray-800 leading-4 p-2 text-center rounded-md'>
                {params.value.car_plate || '.....'}
              </div>
            </Grid>
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'AccountInfo',
      headerName: t('date'),
      headerClassName: ' bg-gray-100   fond-extrabold ',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid container lg={12} md={12} xs={12} className='text-xs'>
          <Grid item lg={12} md={12} xs={12} className='pb-2'>
            <CalendarToday fontSize='small' />
            <span className='text-gray-600 leading-4 pl-2'>
              {formatDate(params.value.created_at)}
            </span>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <EventAvailable fontSize='small' />
            <span className='text-gray-600 leading-4 pl-2'>
              {formatDate(params.value.updated_at)}
            </span>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'Action',
      headerName: t('actions'),
      headerClassName: ' bg-gray-100   fond-extrabold',

      disableColumnMenu: true,
      // flex: 1,
      renderCell: (params) => (
        <Link
          to={`/drivers/pending-drivers/${params.value.driver_id}`}
          className='flex w-full justify-center'
        >
          <Button color='inherit'>
            <Visibility fontSize='small' />
          </Button>
        </Link>
      ),
    },
  ];
  const rowsData = drivers.map((data) => {
    return {
      id: data.driver_id,
      Status: data.pending_driver_status,
      DriverInfo: data,
      CarInfo: data,
      AccountInfo: data,
      Action: data,
    };
  });

  return (
    <div className='flex h-auto '>
      <div className=' flex-grow'>
        <DataGrid
          disableColumnFilter
          autoHeight
          density='standard'
          rowHeight={80}
          loading={loading}
          rows={rowsData}
          columns={cols}
          pageSize={1}
          page={page}
          rowCount={60}
          paginationMode='server'
          onPageChange={(newPage) => setPage(newPage)}
          components={{
            Toolbar: null,
            Pagination: () => CustomPagination(loading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: LoadingIndicator,
          }}
        />
      </div>
    </div>
  );
};

export default DriversTable;
