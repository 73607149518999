import React, { useState, useCallback } from 'react';
import { Grid, Button, CircularProgress, Avatar, Rating } from '@mui/material';
import { StarIcon, ThumbUp, Delete } from '@mui/icons-material';
import { Empty } from '../../../drivers/components/common/Empty';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import default_image from '../../../../assets/images/place-moose.png';

import CommentDialog from '../common/CommentDialog';
import DeleteDialog from '../common/DeleteDialog';
import { formatDate } from '../../../drivers/utils';
import ReviewImages from './ReviewImages';
import CustomPagination from '../../../../components/new-components/CustomPagination';
import { makeStyles } from '@mui/styles';
import { usePlacesTranslation } from '../../i18n';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

function CustomToolbar() {
  return <></>;
}

function CommentsContainer({
  data,
  isLoading,
  setPage,
  isRefreshing,
  setIsRefreshing,
  page,
}) {
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();

  const t = usePlacesTranslation();

  const cols = [
    {
      field: 'place',
      headerName: t('place'),
      headerClassName: ' bg-gray-100 text-center w-full ',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <Link to={`/places/${params.value.place_id}`}>
          <div className='text-xs w-full flex items-center justify-center px-2 cursor-pointer'>
            <Grid item lg={3} md={3}>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                className='flex justify-center'
              >
                <Avatar
                  src={params.value.place_logo || default_image}
                  variant='square'
                  className='pl-1'
                />
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} marginX={2}>
              <Grid item lg={12} md={12} xs={12}>
                {params.value.name || '...'}
              </Grid>
            </Grid>
          </div>
        </Link>
      ),
    },
    {
      field: 'user',
      headerName: t('user'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          spacing={0}
          display='flex'
          justifyContent='space-between'
          className={
            params.value.user_id
              ? 'text-xs p-1 hover:bg-blue-200  hover:cursor-pointer'
              : 'text-xs p-1'
          }
          onClick={() => {
            navigate(`/users/${params.value.user_id}`);
          }}
        >
          <Grid item lg={1.5} md={2} xs={2}>
            <Avatar
              src={params.value.url || default_image}
              className={classes.small}
            />
          </Grid>
          <Grid item lg={8} md={8} marginX={2}>
            <Grid item lg={12} md={12} xs={12}>
              {params.value.full_name}
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              {params.value.mobile}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'comment',
      headerName: t('comment'),
      headerClassName: ' bg-gray-100 text-center w-full  ',
      disableColumnMenu: true,
      flex: 2.5,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className='flex text-xs font-serif font-semibold h-full pl-2 whitespace-normal items-end '
        >
          <Grid item lg={12} md={12} xs={12} className='truncate'>
            {params.value.comment}
          </Grid>
          <Grid item lg={12} md={12} xs={12} className='slef-start'>
            <Rating value={params.value.rating} disabled />
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'likes_count',
      headerName: t('likes'),
      headerClassName: ' bg-gray-100 text-center w-full ',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className=' text-xs font-serif font-semibold pl-2'
        >
          <Grid item lg={12} md={12} xs={12} className=''>
            {/* {params.value.replys} */}
          </Grid>
          <Grid item lg={12} md={12} xs={12} className=''>
            {/* <ThumbUpIcon fontSize="small" /> */}
            <span className='p-2'> {params.value.likes_count}</span>
            <ThumbUp fontSize='small' />
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'images',
      headerName: t('images'),
      headerClassName: ' bg-gray-100 text-center w-full ',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className=' text-xs font-serif font-semibold pl-2'
        >
          <Grid item lg={8} md={8} xs={8} className='flex flex-row '>
            {params.value.place_images.length !== 0
              ? params.value.place_images
                  .filter((item, idx) => idx < 2)
                  .map((item) => {
                    return (
                      <Grid lg={8} md={8} className='pl-1 '>
                        <img
                          src={item.image}
                          alt=''
                          width='100%'
                          height='50%'
                        />
                      </Grid>
                    );
                  })
              : 'No Images'}
          </Grid>
          {params.value.place_images.length !== 0 ? (
            <Grid container lg={4} md={4} className='flex  mt-2'>
              <ReviewImages
                comment={params.value}
                open={open}
                setOpen={setOpen}
                isRefreshing={isRefreshing}
                setIsRefreshing={setIsRefreshing}
              />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      ),
    },
    {
      field: 'status',
      headerName: t('status'),
      headerClassName: ' bg-gray-100 text-center w-full ',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className=' text-xs font-serif font-semibold pl-2'
        >
          <Grid item lg={12} md={12} xs={12} className=''>
            {params.value.is_deleted ? (
              <Grid className='text-red-600'>{t('deleted')}</Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'date',
      headerName: t('date'),
      headerClassName: ' bg-gray-100 text-center w-full ',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className=' text-xs font-serif font-semibold pl-2'
        >
          <Grid item lg={12} md={12} xs={12} className=''>
            {formatDate(params.value.created_at)}
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'comment_id',
      headerName: t('actions'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 0.8,
      renderCell: (params) => (
        <Grid container lg={12} md={12}>
          <Grid container lg={6} md={6}>
            <CommentDialog
              comment={params.value}
              open={open}
              setOpen={setOpen}
              isRefreshing={isRefreshing}
              setIsRefreshing={setIsRefreshing}
            />
          </Grid>

          <Grid container lg={6} md={6}>
            {!params.value.is_deleted ? (
              <DeleteDialog
                open={open}
                setOpen={setOpen}
                id={params.value.comment_id}
                isRefreshing={isRefreshing}
                setIsRefreshing={setIsRefreshing}
              />
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      ),
    },
  ];

  const rowsData = data.map((item) => {
    return {
      place: item.place,
      id: item.comment_id,
      user: item.user,
      comment: item,
      comment_id: item,
      date: item,
      likes_count: item,
      status: item,
      images: item,
    };
  });

  return (
    <div className='flex h-auto w-full '>
      <div className=' flex-grow'>
        <DataGrid
          className='border-2'
          disableColumnFilter
          autoHeight
          rowHeight={60}
          loading={isLoading}
          rows={rowsData}
          page={page}
          columns={cols}
          pageSize={1}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => setPage(newPage)}
          CellStyle='{StaticResource DataGridBorder}'
          components={{
            Toolbar: CustomToolbar,
            Pagination: () => CustomPagination(isLoading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: LoadingIndicator,
          }}
        />
      </div>
    </div>
  );
}

export default CommentsContainer;
