// This dictionary contains 338 untranslated or inherited localization strings.
// These strings are commented out. Uncomment and edit them if you want to add your translations.
import { editorLocalization } from "survey-creator-core";

export var fiStrings = {
  // survey templates
  survey: {
    edit: "Muokkaa",
    externalHelpLink: "Katso ja opi luomaan kyselyjä",
    externalHelpLinkUrl: "https://www.youtube.com/channel/UCH2ru9okIcooNZfpIbyq4qQ?view_as=subscriber",
    dropQuestion: "Pudota kysymys Työkalupakista tähän.",
    addLogicItem: "Luo sääntö mukauttamaan kyselyn kulkua.",
    copy: "Kopioi",
    duplicate: "Monista",
    addToToolbox: "Lisää Työkalupakkiin",
    deletePanel: "Poista Paneeli",
    deleteQuestion: "Poista Kysymys",
    convertTo: "Konvertoi",
    drag: "Vedä elementtiä"
  },
  // Question types
  qt: {
    default: "Oletusarvo",
    checkbox: "Valintaruutu",
    comment: "Kommentti",
    imagepicker: "Kuvanvalitsin",
    ranking: "Sijoitus",
    image: "Kuva",
    dropdown: "Pudotusvalikko",
    // tagbox: "Tag Box",
    file: "Tiedosto",
    html: "Html",
    matrix: "Matrix (yksi valinta)",
    matrixdropdown: "Matrix (monivalinta)",
    matrixdynamic: "Matrix (dynaamiset rivit)",
    multipletext: "Useita tekstejä",
    panel: "Paneeli",
    paneldynamic: "Paneeli (dynaamiset paneelit)",
    radiogroup: "Radiopainikeryhmä",
    rating: "Luokitus",
    text: "Teksti",
    boolean: "Totuusarvomuuttuja",
    expression: "Lauseke(vain luku)",
    signaturepad: "Allekirjoitusalusta",
    // buttongroup: "Button Group"
  },
  // Strings in SurveyJS Creator
  ed: {
    defaultLocale: "Vakio ({0})",
    survey: "Kysely",
    settings: "Kyselyn asetukset",
    settingsTooltip: "Avaa kyselyn asetukset",
    // surveySettings: "Survey Settings",
    // surveySettingsTooltip: "Open survey settings",
    showPanel: "Näytä Paneeli",
    hidePanel: "Piilota Paneeli",
    // prevSelected: "Select previous",
    // nextSelected: "Select next",
    // surveyTypeName: "Survey",
    // pageTypeName: "Page",
    // panelTypeName: "Panel",
    // questionTypeName: "Question",
    // columnTypeName: "Column",
    addNewPage: "Lisää uusi sivu",
    moveRight: "Selaa oikealle",
    moveLeft: "Selaa vasemmalle",
    deletePage: "Poista sivu",
    editPage: "Muokkaa sivua",
    edit: "Muokkaa",
    newPageName: "sivu",
    newQuestionName: "kysymys",
    newPanelName: "paneeli",
    newTextItemName: "teksti",
    testSurvey: "Testaa kysely",
    // defaultV2Theme: "Default",
    // modernTheme: "Modern",
    // defaultTheme: "Default (legacy)",
    testSurveyAgain: "Testaa kysely uudestaan",
    testSurveyWidth: "Kyselyn laajuus: ",
    navigateToMsg: "Sinun piti navigoida:",
    logic: "Kysely logiikka",
    embedSurvey: "Upota kysely",
    translation: "Käännös",
    saveSurvey: "Tallenna kysely",
    saveSurveyTooltip: "Tallenna kysely",
    designer: "Kyselyn suunnittelija",
    jsonEditor: "JSON Editori",
    // jsonHideErrors: "Hide errors",
    // jsonShowErrors: "Show errors",
    undo: "Kumoa",
    redo: "Tee uudelleen",
    undoTooltip: "Kumoa viimeinen muutos",
    redoTooltip: "Tee muutos uudelleen",
    // showMoreChoices: "Show more",
    // showLessChoices: "Show less",
    copy: "Kopioi",
    cut: "Leikkaa",
    paste: "Liitä",
    copyTooltip: "Kopioi valinta leikepöydälle",
    cutTooltip: "Leikkaa valinta leikepöydälle",
    pasteTooltip: "Liitä leikepöydältä",
    options: "Vaihtoehdot",
    generateValidJSON: "Luo kelvollinen JSON",
    generateReadableJSON: "Luo luettava JSON",
    toolbox: "Työkalupakki",
    "property-grid": "Properties",
    // propertyGridFilteredTextPlaceholder: "Type to search...",
    toolboxGeneralCategory: "Yleinen",
    // toolboxChoiceCategory: "Choice Questions",
    // toolboxTextCategory: "Text Input Questions",
    // toolboxContainersCategory: "Containers",
    // toolboxMatrixCategory: "Matrix Questions",
    // toolboxMiscCategory: "Misc",
    correctJSON: "Korjaa JSON.",
    surveyResults: "Kyselyn tulos:",
    surveyResultsTable: "Taulukossa",
    surveyResultsJson: "JSON:ssa",
    resultsTitle: "Kysymyksen otsikko",
    resultsName: "Kysymyksen nimi",
    resultsValue: "Vastauksen arvo",
    resultsDisplayValue: "Näytä arvo",
    modified: "Muokattu",
    saving: "Tallentaa",
    saved: "Tallennettu",
    propertyEditorError: "Virhe:",
    saveError: "Virhe! Editorin sisältöä ei ole tallennettu.",
    // translationPropertyGridTitle: "Language Settings",
    // translationLanguages: "Languages",
    translationAddLanguage: "Valitse käännettävä kieli",
    translationShowAllStrings: "Näytä kaikki merkkijonot",
    // translationShowUsedStringsOnly: "Used Strings Only",
    translationShowAllPages: "Näytä kaikki sivut",
    translationNoStrings: "Ei merkkijonoja käännettäväksi. Vaihda suodatin.",
    translationExportToSCVButton: "Vie (CSV)",
    translationImportFromSCVButton: "Tuo (CSV)",
    translationMergeLocaleWithDefault: "Yhdistä {0} oletuskielellä",
    // translationPlaceHolder: "Translation...",
    bold: "Lihavoitu",
    italic: "Kursiivi",
    underline: "Alleviivaa",
    addNewQuestion: "Lisää kysymys",
    selectPage: "Valitse sivu...",
    // htmlPlaceHolder: "HTML content will be here.",
    // panelPlaceHolder: "Drop a question from the toolbox here.",
    // surveyPlaceHolder: "The survey is empty. Drag an element from the toolbox or click the button below.",
    // addNewTypeQuestion: "Add {0}", //{0} is localizable question type
    // chooseLogoPlaceholder: "[LOGO]",
    // auto: "auto",
    lg: {
      // addNewItem: "Add New Rule",
      // empty_tab: "Create a rule to customize the flow of the survey.",
      page_visibilityName: "Sivun näkyvyys",
      // page_enableName: "Enable (disable) page",
      panel_visibilityName: "Panelin näkyvyys",
      panel_enableName: "Paneeli käytössä/Ei käytössä",
      question_visibilityName: "Kysymyksen näkyvyys",
      question_enableName: "Kysymys käytössä/Ei käytössä",
      question_requireName: "Kysymys valinnainen vaaditaan",
      // column_visibilityName: "Show (hide) column",
      // column_enableName: "Enable (disable) column",
      // column_requireName: "Make column required",
      trigger_completeName: "Valmis kysely",
      trigger_setvalueName: "Aseta kysymyksen arvo",
      trigger_copyvalueName: "Kopioi kysymyksen arvo",
      trigger_skipName: "Ohita kysymys",
      trigger_runExpressionName: "Suorita mukautettu lauseke",
      completedHtmlOnConditionName: "Mukautettu Kiitos -sivuteksti",
      page_visibilityDescription: "Tee sivusta näkyvä, kun looginen lauseke palauttaa arvon tosi. Muuten pidä se näkymättömänä.",
      panel_visibilityDescription: "Tee paneelista näkyvä, kun looginen lauseke palauttaa arvon tosi. Muuten pidä se näkymättömänä.",
      panel_enableDescription: "Ota paneeli ja kaikki sen sisällä olevat elementit käyttöön, kun looginen lauseke palauttaa arvon tosi. Muuten pidä ne poissa käytöstä.",
      question_visibilityDescription: "Tee kysymyksestä näkyvä, kun looginen lauseke palauttaa arvon tosi. Muuten pidä se näkymättömänä.",
      question_enableDescription: "Ota kysymys käyttöön, kun looginen lauseke palauttaa arvon tosi. Muussa tapauksessa pidä se poissa käytöstä.",
      question_requireDescription: "Kysymys tulee pakolliseksi, kun looginen lauseke palauttaa arvon tosi.",
      trigger_completeDescription: "Kun looginen lauseke palauttaa arvon tosi, kysely valmistuu ja loppukäyttäjä näkee Kiitos -sivun.",
      trigger_setvalueDescription: "Kun loogisessa lausekkeessa käytettyjä kysymysarvoja muutetaan ja looginen lauseke palauttaa arvon tosi, arvo asetetaan valitulle kysymykselle.",
      trigger_copyvalueDescription: "Kun loogisessa lausekkeessa käytettyjä kysymysarvoja muutetaan ja looginen lauseke palauttaa arvon tosi, yhden valitun kysymyksen arvo kopioidaan toiseen valittuun kysymykseen.",
      trigger_skipDescription: "Kun looginen lauseke palauttaa arvon tosi, kysely siirtyy valittuun kysymykseen / kohdista valittu kysymys.",
      trigger_runExpressionDescription: "When the logic expression returns true, then the custom expression is performed. You may optionally set this expression result into the selected question",
      completedHtmlOnConditionDescription: "If the logic expression returns true, then the default text for the 'Thank you page' is changed to the given one.",
      itemExpressionText: "Kun lauseke: '{0}' palauttaa arvon tosi:", //{0} - the expression
      // itemEmptyExpressionText: "New rule",
      page_visibilityText: "Tee sivu {0} näkyväksi", //{0} page name
      panel_visibilityText: "Tee paneelista {0} näkyvä", //{0} panel name
      panel_enableText: "Ota paneeli {0} käyttöön", //{0} panel name
      question_visibilityText: "Tee kysymys {0} näkyväksi", //{0} question name
      question_enableText: "Ota kysymys {0} käyttöön", //{0} question name
      question_requireText: "Tee kysymyksestä {0} pakollinen", //{0} question name
      // column_visibilityText: "make column {0} of question {1} visible", //{0} column name, {1} question name
      // column_enableText: "make column {0} of question {1} enable", //{0} column name, {1} question name
      // column_requireText: "make column {0} of question {1} required", //{0} column name, {1} question name
      trigger_completeText: "Kyselystä tulee täytetty",
      trigger_setvalueText: "Laita arvo {1} kysymykseen {0}", //{0} question name, {1} setValue
      trigger_copyvalueText: "Kopioi kysymykseen: {0} arvo kysymyksestä {1}", //{0} and {1} question names
      trigger_skipText: "Siirry kyselyn kysymykseen {0}", //{0} question name
      trigger_runExpressionText1: "Suorita lauseke: '{0}'", //{0} the expression
      trigger_runExpressionText2: " ja aseta sen tulos kysymykseen: {0}", //{0} question name
      completedHtmlOnConditionText: "Näytä mukautettu teksti Kiitos -sivulle.",
      // showAllQuestions: "All Questions",
      // showAllActionTypes: "All Action Types",
      conditions: "Ehdot",
      actions: "Toiminnot",
      expressionEditorTitle: "Määritä olosuhteet",
      actionsEditorTitle: "Määritä toiminnot",
      deleteAction: "Poista toiminto",
      addNewAction: "Lisää uusi toiminto",
      selectedActionCaption: "Valitse lisättävä toiminto ...",
      expressionInvalid: "Looginen lauseke on tyhjä tai virheellinen. Korjaa se.",
      noActionError: "Lisää ainakin yksi toiminto.",
      actionInvalid: "Korjaa ongelmat toiminnoissa.",
      // expressionSetup: "",
      // actionsSetup: ""
    }
  },
  // Property Editors
  pe: {
    apply: "Käytä",
    ok: "OK",
    save: "Tallenna",
    // clear: "Clear",
    saveTooltip: "Tallenna",
    cancel: "Peruuta",
    // set: "Set",
    reset: "Nollaa",
    // change: "Change",
    refresh: "Päivitä",
    // close: "Close",
    delete: "Poista",
    add: "Lisää",
    addNew: "Lisää uusi",
    addItem: "Klikkaa lisätäksesi uusi kohde...",
    // removeItem: "Click to remove the item...",
    // dragItem: "Drag the item",
    addOther: "Muu",
    addSelectAll: "Valitse kaikki",
    addNone: "Ei mitään",
    removeAll: "Poista kaikki",
    edit: "Muokkaa",
    back: "Palaa tallentamatta",
    backTooltip: "Palaa tallentamatta",
    saveAndBack: "Tallenna ja palaa",
    saveAndBackTooltip: "Tallenna ja palaa",
    // doneEditing: "Done",
    editChoices: "Muokkaa valintoja",
    showChoices: "Näytä valinnat",
    move: "Siirrä",
    empty: "<tyhjä>",
    // emptyValue: "Value is empty",
    fastEntry: "Nopea pääsy",
    // fastEntryNonUniqueError: "Value '{0}' is not unique",
    // fastEntryChoicesCountError: "Please limit the number of items from {0} to {1}",
    // fastEntryPlaceholder: "You can set data in the following format:\nvalue1|text\nvalue2",
    formEntry: "Lomakkeen pääsy",
    testService: "Testaa palvelu",
    itemSelectorEmpty: "Valitse elementti",
    conditionActionEmpty: "Valitse toiminto",
    conditionSelectQuestion: "Valitse kysymys...",
    conditionSelectPage: "Valitse sivu...",
    conditionSelectPanel: "Valitse paneeli...",
    conditionValueQuestionTitle: "Syötä / valitse arvo",
    expressionHelp: "Voit käyttää aalto ​​sulkeita päästäksesi kysymysarvoihin: {question1} + {question2}, ({price}*{quantity}) * (100 - {discount}). Voit käyttää funktioita: iif(), today(), age(), min(), max(), count(), avg() ja muita.",
    aceEditorHelp: "Paina ctrl + välilyönti saadaksesi lausekkeen täydennysvihje",
    aceEditorRowTitle: "Nykyinen rivi",
    aceEditorPanelTitle: "Nykyinen paneeli",
    showMore: "Katso lisätietoja dokumentaatiosta",
    assistantTitle: "Saatavilla olevat kysymykset:",
    cellsEmptyRowsColumns: "Sarakkeita tai rivejä on oltava vähintään yksi",
    // showPreviewBeforeComplete: "Preview answers before submitting the survey",
    propertyIsEmpty: "Anna arvo",
    propertyIsNoUnique: "Anna yksilöllinen arvo",
    propertyNameIsNotUnique: "Anna yksilöllinen nimi",
    listIsEmpty: "Lisää uusi kohde",
    // "listIsEmpty@choices": "No choices have been added yet",
    // "addNew@choices": "Add a choice",
    expressionIsEmpty: "Lauseke on tyhjä",
    value: "Arvo",
    text: "Teksti",
    rowid: "Rivi ID",
    imageLink: "Kuvalinkki",
    columnEdit: "Muokkaa saraketta: {0}",
    itemEdit: "Muokkaa kohdetta: {0}",
    url: "URL",
    path: "Polku",
    valueName: "Arvon nimi",
    choicesbyurl: {
      // valueName: "Get values from the following JSON field"
    },
    titleName: "Otsikon nimi",
    // imageLinkName: "Get image URLs from the following JSON field",
    // allowEmptyResponse: "Allow empty response",
    titlePlaceholder: "Syötä otsikko tähän",
    surveyTitlePlaceholder: "Syötä kyselyn otsikko tähän",
    pageTitlePlaceholder: "Syötä sivun otsikko tähän",
    descriptionPlaceholder: "Lisää kuvaus",
    surveyDescriptionPlaceholder: "Lisää kyselyn kuvaus",
    pageDescriptionPlaceholder: "Lisää sivun kuvaus",
    showOtherItem: "On muu",
    otherText: "Muu teksti",
    showNoneItem: "Ei mitään",
    noneText: "Ei mitään tekstiä",
    showSelectAllItem: "On valinnut kaikki",
    selectAllText: "Valitse kaikki tekstit",
    choicesMin: "Automaattisesti luotujen valintojen vähimmäisarvo",
    choicesMax: "Automaattisesti luotujen valintojen enimmäisarvo",
    choicesStep: "Automaattisesti luotujen valintojen ero",
    name: "Nimi",
    title: "Otsikko",
    cellType: "Solutyyppi",
    colCount: "Sarakemäärä",
    choicesOrder: "Valitse valintojen järjestys",
    visible: "On näkyvä?",
    isRequired: "On vaadittu?",
    isAllRowRequired: "Vaadi vastaus kaikille riveille",
    requiredErrorText: "Vaadittu virheteksti",
    startWithNewLine: "Onko aloitus uudella rivillä?",
    rows: "Rivit",
    cols: "Sarakeet",
    placeholder: "Syötä paikkamerkintä",
    showPreview: "Näytä esikatselu",
    storeDataAsText: "Tallenna tiedostosisältö JSON-tulokseen tekstinä",
    maxSize: "Tiedoston enimmäiskoko tavuina",
    imageHeight: "Kuvan korkeus",
    imageWidth: "Kuvan leveys",
    rowCount: "Rivien määrä",
    columnLayout: "Sarakkeiden asettelu",
    addRowLocation: "Lisää rivipainikkeen sijainti",
    addRowText: "Lisää rivipainikkeen teksti",
    removeRowText: "Poista rivipainikkeen teksti",
    rateMin: "Vähimmäismäärä",
    rateMax: "Enimmäismäärä",
    rateStep: "Arvostele askel",
    minRateDescription: "Vähimmäismäärän kuvaus",
    maxRateDescription: "Enimmäismäärän kuvaus",
    inputType: "Syötä tyyppi",
    optionsCaption: "Vaihtoehdot kuvateksti",
    defaultValue: "Oletusarvo",
    cellsDefaultRow: "Oletussolutekstit",
    surveyEditorTitle: "Muokkaa kyselyn asetuksia",
    qEditorTitle: "Muokkaa: {0}",
    maxLength: "Enimmäispituus",
    buildExpression: "Luo",
    editExpression: "Muokkaa",
    // and: "and",
    // or: "or",
    remove: "Poista",
    addCondition: "Lisää ehto",
    // emptyLogicPopupMessage: "Select a question to start configuring conditions.",
    // if: "If",
    // then: "then",
    // setToName: "Target question",
    // fromName: "Question to copy answer from",
    // gotoName: "Question to skip to",
    // ruleIsNotSet: "Rule is incorrect",
    // includeIntoResult: "Include into survey results",
    showTitle: "Näytä/piilota otsikko",
    expandCollapseTitle: "Laajenna / tiivistä otsikkoa",
    locale: "Oletus kieli",
    simulator: "Valitse laite",
    landscapeOrientation: "Vaakasuunta",
    // portraitOrientation: "Switch to portrait orientation",
    mode: "Tila (vain muokkaus / vain luku)",
    clearInvisibleValues: "Tyhjennä näkymättömät arvot",
    cookieName: "Evästeen nimi (poistaaksesi kysely käytöstä suorita kysely kaksi kertaa paikallisesti)",
    sendResultOnPageNext: "Lähetä kyselyn tulokset seuraavalla sivulla",
    storeOthersAsComment: "Tallenna 'muut' arvo erilliseen kenttään",
    showPageTitles: "Näytä sivun otsikot",
    showPageNumbers: "Näytä sivunumerot",
    pagePrevText: "Edellinen sivu -painikkeen teksti",
    pageNextText: "Seuraava sivu -painikkeen teksti",
    completeText: "Valmis -painikkeen teksti",
    previewText: "Esikatsele -painikkeen teksti",
    editText: "Muokkaa -painikkeen teksti",
    startSurveyText: "Aloita -painikkeen teksti",
    showNavigationButtons: "Näytä navigointipainikkeet (oletusnavigointi)",
    showPrevButton: "Näytä edellinen -painike (käyttäjä voi palata edelliselle sivulle)",
    firstPageIsStarted: "Kyselyn ensimmäinen sivu on aloitussivu.",
    showCompletedPage: "Näytä valmis sivu lopussa (completeHtml)",
    goNextPageAutomatic: "Kun vastaat kaikkiin kysymyksiin, siirry seuraavalle sivulle automaattisesti",
    showProgressBar: "Näytä edistymispalkki",
    questionTitleLocation: "Kysymyksen otsikon sijainti",
    requiredText: "Kysymys vaadittu symboli (t)",
    questionStartIndex: "Kysymyksen aloitus indeksi (1, 2 or 'A', 'a')",
    showQuestionNumbers: "Näytä kysymysnumerot",
    questionTitleTemplate: "Kysymyksen otsikkomalli, oletusarvo: '{no}. {require} {title}'",
    questionErrorLocation: "Kysymyksen virheen sijainti",
    focusFirstQuestionAutomatic: "Fokusoi ensimmäiseen kysymykseen sivun vaihtuessa",
    questionsOrder: "Elementtien järjestys sivulla",
    maxTimeToFinish: "Enimmäisaika saada kysely täytettyä",
    maxTimeToFinishPage: "Enimmäisaika kyselyn sivun täyttämiseen",
    page: {
      // maxTimeToFinish: "Time limit to finish the page (in seconds)"
    },
    question: {
      // page: "Parent page"
    },
    showTimerPanel: "Näytä ajastus -paneeli",
    showTimerPanelMode: "Näytä ajastus -paneelin tila",
    renderMode: "Renderöinnin tila",
    allowAddPanel: "Salli paneelin lisääminen",
    allowRemovePanel: "Salli paneelin poistaminen",
    // noEntriesText: "Empty entries text",
    panelAddText: "Paneelin tekstin lisääminen",
    panelRemoveText: "Paneelin tekstin poistaminen",
    isSinglePage: "Näytä kaikki elementit yhdellä sivulla",
    html: "Html",
    expression: "Lauseke",
    // setValue: "Answer",
    // dataFormat: "Image format",
    // allowAddRows: "Allow adding rows",
    // allowRemoveRows: "Allow removing rows",
    // allowRowsDragAndDrop: "Allow row drag and drop",
    // responsiveImageSizeHelp: "Does not apply if you specify the exact image width or height.",
    // minImageWidth: "Minimum image width",
    // maxImageWidth: "Maximum image width",
    // minImageHeight: "Minimum image height",
    // maxImageHeight: "Maximum image height",
    minValue: "Vähimmäisarvo",
    maxValue: "Suurin arvo",
    minLength: "Vähimmäispituus",
    allowDigits: "Salli numerot",
    minCount: "Vähimmäismäärä",
    maxCount: "Enimmäismäärä",
    regex: "Regular expression",
    surveyvalidator: {
      // text: "Error message",
      // expression: "Validation expression"
    },
    // totalText: "Total row text",
    // totalType: "Total type",
    // totalExpression: "Total expression",
    // totalDisplayStyle: "Total value display style",
    // totalCurrency: "Currency",
    // totalFormat: "Formatted string",
    // logo: "Logo (URL or base64-encoded string)",
    // questionsOnPageMode: "Survey structure",
    // maxTextLength: "Maximum answer length (in characters)",
    // maxOthersLength: "Maximum comment length (in characters)",
    // autoGrowComment: "Auto-expand comment area if necessary",
    // textUpdateMode: "Update text question value",
    // focusOnFirstError: "Set focus on the first invalid answer",
    // checkErrorsMode: "Run validation",
    // navigateToUrl: "Navigate to URL",
    // navigateToUrlOnCondition: "Dynamic URL",
    // completedBeforeHtml: "Markup to show if the user already filled out this survey",
    // completedHtml: "Survey Complete page markup",
    // completedHtmlOnCondition: "Dynamic Survey Complete page markup",
    // loadingHtml: "Markup to show while survey model is loading",
    // commentText: "Comment area text",
    // autocomplete: "Autocomplete type",
    // labelTrue: "\"True\" label",
    // labelFalse: "\"False\" label",
    // allowClear: "Show the Clear button",
    // displayStyle: "Value display style",
    // format: "Formatted string",
    // maximumFractionDigits: "Maximum fractional digits",
    // minimumFractionDigits: "Minimum fractional digits",
    // useGrouping: "Display grouping separators",
    // allowMultiple: "Allow multiple files",
    // allowImagesPreview: "Preview images",
    // acceptedTypes: "Accepted file types",
    // waitForUpload: "Wait for the upload to complete",
    // needConfirmRemoveFile: "Confirm file deletion",
    // detailPanelMode: "Detail panel location",
    // minRowCount: "Minimum row count",
    // maxRowCount: "Maximum row count",
    // confirmDelete: "Confirm row deletion",
    // confirmDeleteText: "Confirmation message",
    paneldynamic: {
      // confirmDelete: "Confirm panel deletion"
    },
    // panelCount: "Initial panel count",
    // minPanelCount: "Minimum panel count",
    // maxPanelCount: "Maximum panel count",
    // panelsState: "Inner panel expand state",
    // templateDescription: "Description template",
    // templateTitle: "Title template",
    // panelPrevText: "Previous Panel button tooltip",
    // panelNextText: "Next Panel button tooltip",
    // showRangeInProgress: "Show progress bar",
    // templateTitleLocation: "Question title location",
    // panelRemoveButtonLocation: "Remove Panel button location",
    // hideIfRowsEmpty: "Hide the question if there are no rows",
    // hideColumnsIfEmpty: "Hide columns if there are no rows",
    // rateValues: "Custom rate values",
    // hideIfChoicesEmpty: "Hide the question if it contains no choices",
    // hideNumber: "Hide question number",
    // minWidth: "Minimum width (in CSS-accepted values)",
    // maxWidth: "Maximum width (in CSS-accepted values)",
    // width: "Width (in CSS-accepted values)",
    // showHeader: "Show column headers",
    // horizontalScroll: "Show horizontal scrollbar",
    // columnMinWidth: "Minimum column width (in CSS-accepted values)",
    // rowTitleWidth: "Row header width (in CSS-accepted values)",
    // valueTrue: "\"True\" value",
    // valueFalse: "\"False\" value",
    // minErrorText: "\"Value is below minimum\" error message",
    // maxErrorText: "\"Value exceeds maximum\" error message",
    // otherErrorText: "\"Empty comment\" error message",
    // keyDuplicationError: "\"Non-unique key value\" error message",
    // maxSelectedChoices: "Maximum selected choices",
    // showClearButton: "Show the Clear button",
    // showNumber: "Show panel number",
    // logoWidth: "Logo width (in CSS-accepted values)",
    // logoHeight: "Logo height (in CSS-accepted values)",
    // readOnly: "Read-only",
    // enableIf: "Editable if",
    // emptyRowsText: "\"No rows\" message",
    // size: "Input size (in characters)",
    // separateSpecialChoices: "Separate special choices (None, Other, Select All)",
    // choicesFromQuestion: "Copy choices from the following question",
    // choicesFromQuestionMode: "Which choices to copy?",
    // showCommentArea: "Show the comment area",
    // commentPlaceholder: "Comment area placeholder",
    // displayRateDescriptionsAsExtremeItems: "Display rate descriptions as extreme values",
    // rowsOrder: "Row order",
    // columnsLayout: "Column layout",
    // columnColCount: "Nested column count",
    // state: "Panel expand state",
    // correctAnswer: "Correct Answer",
    // defaultPanelValue: "Default Values",
    // cells: "Cell Texts",
    // keyName: "Key column",
    itemvalue: {
      // text: "Alt text"
    },
    logoPosition: "Logo sijainti",
    addLogo: "Lisää logo...",
    changeLogo: "Muuta logo...",
    logoPositions: {
      none: "Poista logo",
      left: "Vasen",
      right: "Oikea",
      top: "Päällä",
      bottom: "Alla"
    },
    tabs: {
      general: "Yleinen",
      fileOptions: "Vaihtoehdot",
      html: "Html Editori",
      columns: "Sarakkeet",
      rows: "Rivit",
      choices: "Valinnat",
      items: "Kohteet",
      visibleIf: "Näkyvä jos",
      enableIf: "Käytössä jos",
      requiredIf: "Vaadittu jos",
      rateValues: "Järjestä arvot",
      choicesByUrl: "Valintoja verkosta",
      matrixChoices: "Oletus valinnat",
      multipleTextItems: "Tekstinsyötöt",
      numbering: "Numerointi",
      validators: "Validattorit",
      navigation: "Navigointi",
      question: "Kysymys",
      pages: "Sivut",
      timer: "Ajastin / tietovisa",
      calculatedValues: "Lasketut arvot",
      triggers: "Triggerit",
      templateTitle: "Mallin otsikko",
      totals: "Yhteensä",
      logic: "Logiikka",
      layout: "Layout",
      data: "Data",
      validation: "Validointi",
      cells: "Solut",
      showOnCompleted: "Näytä kun valmis",
      logo: "Logo kyselyn otsikossa",
      slider: "Liukusäädin",
      // expression: "Expression",
      others: "Muut"
    },
    editProperty: "Muokkaa ominaisuutta'{0}'",
    items: "[ Kohteet: {0} ]",
    // choicesVisibleIf: "Choices are visible if",
    // choicesEnableIf: "Choices are selectable if",
    // columnsEnableIf: "Columns are visible if",
    // rowsEnableIf: "Rows are visible if",
    // indent: "Add indents",
    panel: {
      // indent: "Add outer indents"
    },
    // innerIndent: "Add inner indents",
    // defaultValueFromLastRow: "Take default values from the last row",
    // defaultValueFromLastPanel: "Take default values from the last panel",
    enterNewValue: "Anna arvo.",
    noquestions: "Kyselyssä ei ole yhtään kysymystä.",
    createtrigger: "Luo triggeri",
    titleKeyboardAdornerTip: "Paina Enter -painiketta muokataksesi",
    keyboardAdornerTip: "Voit muokata kohdetta painamalla Enter -painiketta, poistaa kohteen painamalla poistopainiketta, siirtää kohdetta painamalla Alt plus nuoli ylös tai nuoli alas",
    triggerOn: "Päällä ",
    triggerMakePagesVisible: "Tee sivuista näkyviä:",
    triggerMakeQuestionsVisible: "Tee elementit näkyviksi:",
    triggerCompleteText: "Valmista kysely, jos se on onnistunut.",
    triggerNotSet: "Triggeriä ei ole asetettu",
    triggerRunIf: "Suorita jos",
    triggerSetToName: "Muuta arvoa: ",
    triggerFromName: "Kopioi arvo kohteesta: ",
    triggerRunExpression: "Suorita tämä lauseke:",
    triggerSetValue: "kohtaan: ",
    triggerGotoName: "Siirry kysymykseen:",
    triggerIsVariable: "Älä lisää muuttujaa kyselyn tulokseen.",
    triggerRunExpressionEmpty: "Anna kelvollinen lauseke",
    // emptyExpressionPlaceHolder: "Type expression here...",
    noFile: "Tiedostoa ei ole valittuna",
    // clearIfInvisible: "Clear the value if the question becomes hidden",
    // valuePropertyName: "Value property name",
    // searchEnabled: "Enable search",
    // hideSelectedItems: "Hide selected items",
    // signatureWidth: "Signature width",
    // signatureHeight: "Signature height",
    // verticalAlign: "Vertical alignment",
    // alternateRows: "Alternate rows",
    // columnsVisibleIf: "Columns are visible if",
    // rowsVisibleIf: "Rows are visible if",
    // otherPlaceholder: "Comment area placeholder"
  },
  // Property values
  pv: {
    // true: "true",
    // false: "false",
    inherit: "inherit",
    show: "show",
    hide: "hide",
    default: "default",
    initial: "initial",
    random: "random",
    collapsed: "collapsed",
    expanded: "expanded",
    none: "none",
    asc: "ascending",
    desc: "descending",
    indeterminate: "indeterminate",
    // decimal: "decimal",
    // currency: "currency",
    // percent: "percent",
    firstExpanded: "firstExpanded",
    off: "off",
    // onpanel: "Start on each panel",
    onPanel: "onPanel",
    onSurvey: "onSurvey",
    list: "list",
    progressTop: "progressTop",
    progressBottom: "progressBottom",
    progressTopBottom: "progressTopBottom",
    horizontal: "horizontal",
    vertical: "vertical",
    top: "top",
    bottom: "bottom",
    topBottom: "top and bottom",
    both: "both",
    left: "left",
    // right: "Right",
    // color: "color",
    // date: "date",
    // datetime: "datetime",
    // "datetime-local": "datetime-local",
    // email: "email",
    // month: "month",
    // number: "number",
    // password: "password",
    // range: "range",
    // tel: "tel",
    // text: "text",
    // time: "time",
    // url: "url",
    // week: "week",
    hidden: "hidden",
    on: "on",
    onPage: "onPage",
    edit: "edit",
    display: "display",
    onComplete: "onComplete",
    onHidden: "onHidden",
    // onHiddenContainer: "When the question or its panel/page becomes hidden",
    clearInvisibleValues: {
      // none: "Never"
    },
    all: "all",
    page: "page",
    survey: "survey",
    onNextPage: "onNextPage",
    onValueChanged: "onValueChanged",
    // onValueChanging: "Before an answer is changed",
    standard: "standard",
    singlePage: "single page",
    questionPerPage: "question per page",
    noPreview: "no preview",
    showAllQuestions: "show preview with all questions",
    showAnsweredQuestions: "show preview with answered questions",
    // pages: "Completed pages",
    // questions: "Answered questions",
    // requiredQuestions: "Answered required questions",
    // correctQuestions: "Valid answers",
    // buttons: "Completed pages (button UI)",
    // underInput: "Under the input",
    // underTitle: "Under the title",
    // onBlur: "On blur",
    // onTyping: "While typing",
    // underRow: "Under the row",
    // underRowSingle: "Under the row, only one panel is visible",
    showNavigationButtons: {
      // none: "Hidden"
    },
    showProgressBar: {
      // off: "Hidden"
    },
    showTimerPanel: {
      // none: "Hidden"
    },
    showTimerPanelMode: {
      // all: "Both"
    },
    detailPanelMode: {
      // none: "Hidden"
    },
    addRowLocation: {
      // default: "Depends on matrix layout"
    },
    panelsState: {
      // default: "Users cannot expand or collapse panels",
      // collapsed: "All panels are collapsed",
      // expanded: "All panels are expanded"
    },
    widthMode: {
      // auto: "Auto",
      // static: "Static",
      // responsive: "Responsive"
    },
    imageFit: {
      // none: "None",
      // contain: "Contain",
      // cover: "Cover",
      // fill: "Fill"
    },
    contentMode: {
      // auto: "Auto",
      // image: "Image",
      // video: "Video",
      // youtube: "YouTube"
    },
    displayMode: {
      // auto: "Auto",
      // buttons: "Buttons",
      // dropdown: "Dropdown"
    }
  },
  // Operators
  op: {
    empty: "on tyhjä",
    notempty: "ei ole tyhjä",
    equal: "yhtäsuurikuin",
    notequal: "ei ole yhtäsuurikuin",
    contains: "sisältää",
    notcontains: "ei sisällä",
    anyof: "mikä tahansa",
    allof: "kaikki",
    greater: "suurempi",
    less: "vähemmän",
    greaterorequal: "suurempi tai yhtäsuurikuin",
    lessorequal: "vähemmän tai yhtäsuurikuin",
    // and: "and",
    // or: "or"
  },
  // Embed window
  ew: {
    angular: "Käytä Angular versiota",
    jquery: "Käytä jQuery versiota",
    knockout: "Käytä Knockout versiota",
    react: "Käytä React versiota",
    vue: "Käytä Vue versiota",
    bootstrap: "Bootstrap työkalulle",
    modern: "Moderni teema",
    default: "Oletus teema",
    orange: "Oranssi teema",
    darkblue: "Tummansininen teema",
    darkrose: "Tumma ruusu teema",
    stone: "Kiviteema",
    winter: "Talvi teema",
    winterstone: "Talvi-kivi-teema",
    showOnPage: "Näytä kysely sivulla",
    showInWindow: "Näytä kysely ikkunassa",
    loadFromServer: "Lataa kysely palvelimelta JSON muodossa",
    titleScript: "Skriptit ja tyylit",
    titleHtml: "HTML",
    titleJavaScript: "JavaScript"
  },
  // Preview (Survey)
  ts: {
    selectPage: "Valitse sivu testataksesi sitä:",
    showInvisibleElements: "Näytä näkymätön elementti",
    // hideInvisibleElements: "Hide invisible elements"
  },
  validators: {
    answercountvalidator: "vastausten määrä",
    emailvalidator: "sähköposti",
    expressionvalidator: "lauseke",
    numericvalidator: "lukumääräinen",
    regexvalidator: "säännöllinen lauseke",
    textvalidator: "teksti"
  },
  triggers: {
    completetrigger: "valmis kysely",
    setvaluetrigger: "aseta arvo",
    copyvaluetrigger: "kopioi arvo",
    skiptrigger: "siirry kysymykseen",
    runexpressiontrigger: "suorita lauseke",
    visibletrigger: "muuta näkyvyyttä (vanhentunut)"
  },
  pehelp: {
    // cookieName: "Cookies prevent users from filling out the same survey twice.",
    // format: "Use {0} as a placeholder for the actual value.",
    // totalText: "Visible only when at least one column has Total type or Total expression.",
    // acceptedTypes: "Refer to the [accept](https://www.w3schools.com/tags/att_input_accept.asp) attribute description for more information.",
    // columnColCount: "Applicable only to Radiogroup and Checkbox cell types.",
    // autocomplete: "Refer to the [autocomplete](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete) attribute description for more information.",
    // valueName: "If you do not set this property, the answer will be stored in a field specified by the Name property.",
    choicesbyurl: {
      // valueName: " "
    },
    // keyName: "If the specified column contains identical values, the survey throws the \"Non-unique key value\" error."
  },
  // Properties
  p: {
    title: {
      name: "otsikko",
      title: "Jätä se tyhjäksi, jos se on sama kuin 'Nimi'"
    },
    // multiSelect: "Allow multiple selection",
    // showLabel: "Show image captions",
    // value: "Value",
    visibleIf: "visibleIf",
    // attachOriginalItems: "attachOriginalItems",
    // useDisplayValuesInDynamicTexts: "useDisplayValuesInDynamicTexts",
    titleLocation: "titleLocation",
    description: "description",
    // descriptionLocation: "descriptionLocation",
    // defaultValueExpression: "defaultValueExpression",
    requiredIf: "requiredIf",
    validators: "validators",
    // bindings: "bindings",
    // renderAs: "renderAs",
    currency: "currency",
    // cellHint: "cellHint",
    // isUnique: "isUnique",
    // showInMultipleColumns: "showInMultipleColumns",
    // totalMaximumFractionDigits: "totalMaximumFractionDigits",
    // totalMinimumFractionDigits: "totalMinimumFractionDigits",
    columns: "columns",
    // detailElements: "detailElements",
    choices: "choices",
    // allowAdaptiveActions: "allowAdaptiveActions",
    defaultRowValue: "defaultRowValue",
    // detailPanelShowOnAdding: "detailPanelShowOnAdding",
    // logoFit: "logoFit",
    // pages: "pages",
    // questions: "questions",
    triggers: "triggers",
    // calculatedValues: "calculatedValues",
    // surveyId: "surveyId",
    // surveyPostId: "surveyPostId",
    // surveyShowDataSaving: "surveyShowDataSaving",
    // questionDescriptionLocation: "questionDescriptionLocation",
    // progressBarType: "progressBarType",
    // questionTitlePattern: "questionTitlePattern",
    // widthMode: "widthMode",
    // showBrandInfo: "showBrandInfo",
    choicesByUrl: "choicesByUrl",
    // choicesLazyLoadEnabled: "choicesLazyLoadEnabled",
    // choicesLazyLoadPageSize: "choicesLazyLoadPageSize",
    // inputFieldComponent: "inputFieldComponent",
    // itemComponent: "itemComponent",
    // min: "min",
    // max: "max",
    // minValueExpression: "minValueExpression",
    // maxValueExpression: "maxValueExpression",
    // step: "step",
    // dataList: "dataList",
    itemSize: "itemSize",
    // elements: "elements",
    // content: "content",
    navigationButtonsVisibility: "navigationButtonsVisibility",
    // navigationTitle: "navigationTitle",
    // navigationDescription: "navigationDescription",
    // closeOnSelect: "closeOnSelect",
    // longTap: "longTap",
    // autoGrow: "autoGrow",
    // acceptCarriageReturn: "acceptCarriageReturn",
    // displayMode: "displayMode",
    label: "label",
    // contentMode: "contentMode",
    // imageFit: "imageFit",
    // altText: "altText",
    // height: "height",
    // penColor: "penColor",
    // backgroundColor: "backgroundColor",
    // templateElements: "templateElements",
    // operator: "operator",
    // isVariable: "isVariable",
    // runExpression: "runExpression",
    // showCaption: "showCaption",
    // iconName: "iconName",
    // iconSize: "iconSize"
  }
};
// Uncomment this line on creating a translation file. You should replace "en" and enStrings with your locale ("fr", "de" and so on) and your variable.
editorLocalization.locales["fi"] = fiStrings;
