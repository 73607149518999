import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import Provider from 'react-redux/es/components/Provider';
import store from './Redux/store';
import { MainRoutes } from './Routes';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Layout from './theme/Layout';
import LoginPage from './modules/auth/pages/login-page';
import firebaseApp from './services/firebaseService/firebase';
import { getMessaging, getToken } from 'firebase/messaging';
import http from './services/APIs/identity_api';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider } from '@mui/material';

import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';
import stylisRTLPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import storage from './storage';
import { green } from '@mui/material/colors';

const theme = createTheme({
  direction: storage.lang.getDirection(),
  palette: {
    light: {
      main: '#eeeeee',
    },
    dark: {
      main: '#9e9e9e',
    },
    primary: {
      main: '#1565c0',
    },
    secondary: {
      main: green[500],
    },
  },

  components: {
    // Name of the component
    MuiInputLabel: {
      styleOverrides: {
        // Name of the slot

        root: {
          fontSize: '18px',

          width: '100%',
        },
      },
    },
  },
});

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useLayoutEffect(() => {
    let state = JSON.parse(localStorage.getItem('token'));
    setIsAuthenticated(state ? true : false);
  }, []);

  //push_notification-config
  const [firebaseToken, setFirebaseToken] = useState(null);
  //get firebase token
  const getFirebaseToken = useCallback(async () => {
    const msg = getMessaging(firebaseApp);
    getToken(msg, {
      vapidKey:
        'BIew-ohYTaHWJqkQBrxHr2gM58K3tLA2Jb_LCNUGQ_JPjrhrq0_KNyIM2CfFuqR2T16asFWywGe-gs8I1BdG0Mw',
    })
      .then((token) => handleFirebaseAuth(token))
      .catch((err) => console.log(err))
      .finally(() => console.log(msg));
  });

  const handleFirebaseAuth = useCallback(async (token) => {
    try {
      await http.post('/device_tokens', {
        device_token: token,
        token_type: 'ANDROID',
      });
    } catch (err) {
      // window.alert(err.response)
    }
  });

  useEffect(() => {
    if (isAuthenticated) {
      getFirebaseToken();
      if (firebaseToken) {
        handleFirebaseAuth();
      }
    }
  }, [isAuthenticated]);

  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, stylisRTLPlugin],
  });

  const children = (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Layout
            isAuthenticated={isAuthenticated}
            setIsAuthenticated={setIsAuthenticated}
          >
            <ToastContainer
              position='top-right'
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              limit={1}
            />
            {isAuthenticated ? (
              <MainRoutes />
            ) : (
              <LoginPage setIsAuthenticated={setIsAuthenticated} />
            )}
          </Layout>
        </MuiPickersUtilsProvider>
      </Provider>
    </ThemeProvider>
  );

  return storage.lang.getDirection() === 'ltr' ? (
    <>{children}</>
  ) : (
    <CacheProvider value={cacheRtl}>{children}</CacheProvider>
  );
}

export default App;
