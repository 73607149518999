import { Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import {
  addEmployee,
  clearErrors,
} from '../../../core/features/provider-employees/providerEmployeesSlice';
import { useEventsTranslation } from '../../../services/i18n';

export default function CreateEmployeeForm({
  setOpenModal,
  provider_id,
  setIsRefreshing,
}) {
  const { isLoading, error } = useSelector(
    (state) => state.providerEmployeesSlice
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      toast.error(error.message, {
        onClose: dispatch(clearErrors()),
      });
    }
  }, [dispatch, error]);

  const t = useEventsTranslation();

  if (isLoading) return <LoadingIndicator />;

  return (
    <div className='absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] bg-slate-100 px-5 py-3 rounded-lg w-[40%]'>
      <div className='flex items-start' onClick={() => setOpenModal(false)}>
        <Close className='bg-gray-100 cursor-pointer' />
      </div>
      <div className='text-xl font-bold text-center'>
        {t('add_new_employee')}
      </div>
      <Formik
        initialValues={{
          full_name: '',
          mobile: '+249',
          role_id: '5',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.full_name) {
            errors.full_name = t('name_is_required');
          }
          if (!values.mobile.startsWith('+') || values.mobile.length < 10) {
            errors.mobile = t('mobile_number_is_not_vaild');
          }
          return errors;
        }}
        onSubmit={async (values) => {
          await dispatch(addEmployee({ ...values, provider_id }));
          setIsRefreshing((prev) => prev + 1);
          setOpenModal(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form
            onSubmit={handleSubmit}
            className='w-full flex flex-col justify-center gap-5 p-5'
          >
            <TextField
              name='full_name'
              label={t('name')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.full_name}
              error={errors.full_name && touched.full_name ? true : false}
              helperText={
                errors.full_name && touched.full_name && errors.full_name
              }
            />
            {/* {errors.full_name && touched.full_name && errors.full_name} */}
            <TextField
              name='mobile'
              dir='ltr'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.mobile}
              error={errors.mobile && touched.mobile ? true : false}
              helperText={touched.mobile && errors.mobile}
            />
            <FormControl fullWidth>
              <InputLabel id='role-lable'>{t('role')}</InputLabel>
              <Select
                labelId='role-lable'
                id='simple-select'
                name='role_id'
                value={values.role_id}
                label={t('role')}
                onChange={handleChange}
              >
                <MenuItem value={5}>{t('employee')}</MenuItem>
                <MenuItem value={4}>{t('manager')}</MenuItem>
              </Select>
            </FormControl>
            <Grid container justifyContent={'center'} spacing={2}>
              <Grid item lg={6}>
                <Button
                  color='primary'
                  variant='contained'
                  className='w-full'
                  type='submit'
                >
                  {t('add')}
                </Button>
              </Grid>
              <Grid item lg={6}>
                <Button
                  variant='contained'
                  color='inherit'
                  className='w-full'
                  onClick={() => setOpenModal(false)}
                >
                  {t('cancle')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
}
