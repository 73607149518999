import http from '../http';

const getDriverTickets = async (payload) => {
  const baseURL = process.env.REACT_APP_TICKETS_API;
  try {
    const ticketsList = await http.get(
      `${baseURL}/tickets?service_id=dispatch&client_id=DISPATCH_ID${
        payload.user_id ? '&user_id=' + payload.user_id : ''
      }&status_id=${payload.status_id}&page_index=${payload.page_number}&page_size=${
        payload.page_size
      }`
      // : `/tickets?service_id=dispatch&client_id=DISPATCH_ID&status_id=${payload.status_id}&page_index=${payload.page_number}&page_size=${payload.page_size}`
    );
    return ticketsList.data;
  } catch (err) {
    throw err.response;
  }
};

export default getDriverTickets;
