import { Grid, IconButton } from '@material-ui/core';
import { PauseCircleFilled, PlayCircleFilled, StopScreenShareRounded } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { generateTimeLineSlots } from '../utils';

const Timeline = ({ setSelectedTime, selectedTime }) => {
  const [slots, setSlots] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [time, setTime] = useState(moment(selectedTime).format('HH:mm'));

  const handleSelectingSlot = (index) => {
    console.log(index);
    setSelectedIndex(index);
    const std = moment('2022-10-08').startOf('day');
    const newTime = moment(std).add(index * 5, 'minute');
    console.log(moment(newTime).format('YYYY-MM-DD HH:mm:ss'));
    setTime(moment(newTime).format('HH:mm'));
    setSelectedTime(moment(newTime).format('YYYY-MM-DD HH:mm:ss'));
  };

  const handlePlayingSlot = () => {
    let counter = selectedIndex;
    setIsPlaying(true);
    const playAction = setInterval(() => {
      // runs every 2 seconds
      if (counter === 287) {
        clearInterval(playAction);
        setIsPlaying(false);
        setSelectedIndex(0);
      }
      handleSelectingSlot(counter);
      counter++;
    }, 1000);
  };

  useEffect(() => {
    setSlots(generateTimeLineSlots());
  }, []);

  return (
    <>
      <div className=' w-full pt-3 overflow-x-scroll'>
        <div className={isPlaying ? 'w-max animate-marquee-infinite' : ''}>
          {slots.map((slot) => (
            <span
              key={slots.indexOf(slot)}
              className={`text-xs border-2 border-gray-500 px-2 ${
                time === slot ? 'bg-gray-400 ' : 'bg-gray-100'
              } hover:bg-gray-300 hover:cursor-pointer`}
              onClick={() => {
                handleSelectingSlot(slots.indexOf(slot));
              }}>
              {slot}
            </span>
          ))}
        </div>
      </div>
      <span className='text-xs text-gray-600 px-2 text-center font-bold'>
        {moment(selectedTime).format('YYYY-MM-DD')}
        <br />
        {moment(selectedTime).format('HH:mm')}
      </span>
      <IconButton size='small' onClick={handlePlayingSlot}>
        <PlayCircleFilled fontSize='large' />
      </IconButton>
      <IconButton size='small' disabled={!isPlaying}>
        <PauseCircleFilled fontSize='large' />
      </IconButton>
    </>
  );
};

export default Timeline;
