import moment from 'moment';
import http from '../http';

const getDailyRate = async () => {
  const baseURL = process.env.REACT_APP_DISPATCH_API;
  const currentDay = {
    from: moment().startOf('day').utc(),
    to: moment().endOf('day').utc(),
  };
  try {
    const response = await http.get(
      `${baseURL}/dashboard/acceptance_rate/hourly_acceptance_rate?from=${currentDay.from}&to=${currentDay.to}`
    );
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

export default getDailyRate;
