import { GridCloseIcon } from '@mui/x-data-grid-pro';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageHeading from '../../../components/common/PageHeading';
import { setAlert } from '../../../Redux/store/app/appSlice';
import {
  createNewPlace,
  getAmenitiesList,
} from '../../../Redux/store/places/placesListSlice';
import UploadBtn from '../components/helper_components/Upload';
import Map from '../../tracking/components/common/add_place_map/Map';
import { generateFormData, sleep } from '../utils';
import UploadBtnOne from '../components/helper_components/Upload_one';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Autocomplete,
  Button,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  Slide,
  TextField,
  Toolbar,
} from '@mui/material';
import { Done, Place, Room } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { usePlacesTranslation } from '../i18n';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
const MapDialog = ({ isOpen, setIsOpen, data, setData }) => {
  // const {isOpen, open, data,setData } = props;
  const [coordinates, setCoordinates] = useState({
    lng: data.lng,
    lat: data.lat,
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSetCoordinates = () => {
    // setData({ ...data, latitude: coordinates.lat, longitude: coordinates.lng });
    setData('latitude', coordinates.lat);
    setData('longitude', coordinates.lng);
    setIsOpen(false);
  };

  const mapProps = {
    coordinates: coordinates,
    setCoordinates: setCoordinates,
  };

  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={isOpen}
    >
      <AppBar position='static' className='relative bg-blue-900 text-white'>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <GridCloseIcon />
          </IconButton>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleSetCoordinates}
            aria-label='close'
          >
            <Done />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Map {...mapProps} />
    </Dialog>
  );
};

const ShowImages = ({ images }) => {
  let arrayOfImages = [];
  let reader = new FileReader();

  Object.keys(images).forEach((key) => {
    arrayOfImages.push(URL.createObjectURL(images[key]));
  });
  return (
    <Grid container lg={12} md={12} className='p-2'>
      {arrayOfImages.map((image) => (
        <Grid
          item
          lg={2}
          md={2}
          className='border-2 border-gray-600 max-h-10 flex overflow-hidden'
        >
          <img src={image} alt={'none'} />
        </Grid>
      ))}
    </Grid>
  );
};
const ShowLogo = ({ logo }) => {
  let arrayOfImages = [];
  let reader = new FileReader();
  const url = URL.createObjectURL(logo);

  return (
    <Grid container lg={12} md={12} className='p-2' justifyContent='center'>
      <Grid item lg={6} md={6} className='border-2 border-gray-600'>
        <img src={url} alt={'none'} />
      </Grid>
    </Grid>
  );
};

const AddPlacePage = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { amenitiesList } = useSelector((state) => state.places).places;

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(getAmenitiesList());
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  const createPlace = useCallback(async (payload, resetForm) => {
    try {
      setIsLoading(true);
      await dispatch(createNewPlace(payload));
      toast.success(t('place_created_successfully'));
      navigate('/places/');
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });
  const [formData, setFormData] = useState({
    name_en: '',
    name_ar: '',
    amenity_id: null,
    address: null,
    latitude: null,
    longitude: null,
    images: [],
    phone: null,
    website: null,
    open: null,
    place_logo: null,
  });

  //img preview

  const formik = useFormik({
    initialValues: formData,
    onSubmit: (values, { resetForm }) => {
      const payload = generateFormData(
        {
          ...values,
        },
        values.images
      );
      createPlace(payload, resetForm);
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  const t = usePlacesTranslation();

  return (
    <Grid container lg={12} md={12} justifyContent='center' spacing={2}>
      {isLoading && <LoadingIndicator />}
      <Grid item lg={12} md={12} className='px-2  pl-2 text-white relative'>
        <PageHeading heading1={t('places')} heading2={t('add_place')} backBtn />
      </Grid>

      <Grid
        item
        lg={11}
        md={11}
        className='shadow-lg shadow-gray-600  rounded-md '
      >
        <form onSubmit={formik.handleSubmit} className='w-full p-4'>
          <Grid container lg={12} md={12} spacing={2} justifyContent='center'>
            <Grid container lg={6} md={6} spacing={2} className='py-4'>
              <Grid item lg={12} md={12}>
                <TextField
                  required
                  name='name_en'
                  fullWidth
                  id='name_en'
                  variant='outlined'
                  label={t('name_en')}
                  type={'text'}
                  onChange={formik.handleChange}
                  value={formik.values.name_en}
                />
              </Grid>
              <Grid item lg={12} md={12}>
                <TextField
                  required
                  name='name_ar'
                  fullWidth
                  id='name_ar'
                  variant='outlined'
                  label={t('name_ar')}
                  type={'text'}
                  onChange={formik.handleChange}
                  value={formik.values.name_ar}
                />
              </Grid>
              <Grid item lg={12} md={12}>
                <TextField
                  name='phone'
                  fullWidth
                  id='phone'
                  variant='outlined'
                  label={t('mobile')}
                  type={'text'}
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                />
              </Grid>
              <Grid item lg={12} md={12}>
                <TextField
                  name='website'
                  fullWidth
                  id='website'
                  variant='outlined'
                  label={t('website')}
                  type={'text'}
                  onChange={formik.handleChange}
                  value={formik.values.website}
                />
              </Grid>
              <Grid item lg={12} md={12}>
                <TextField
                  name='address'
                  fullWidth
                  id='address'
                  variant='outlined'
                  label={t('address')}
                  type={'text'}
                  onChange={formik.handleChange}
                  value={formik.values.address}
                />
              </Grid>

              <Grid item lg={12} md={12}>
                <Autocomplete
                  id='amenity_id'
                  name='amenity_id'
                  fullWidth
                  options={amenitiesList}
                  getOptionLabel={(option) => option.name}
                  style={{ width: '100%' }}
                  onChange={(event, value) => {
                    formik.setFieldValue('amenity_id', value.amenity_id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={t('amenity')}
                      variant='outlined'
                      value={formik.values?.amenity_id}
                    />
                  )}
                />
              </Grid>
              <Grid item marginY={2} lg={12} md={12}>
                <Button
                  size='small'
                  variant='outlined'
                  color='primary'
                  fullWidth
                  // className={classes.button}
                  onClick={(e) => setIsOpen(true)}
                  startIcon={<Place />}
                >
                  {t('set_Location_on_a_map')}
                </Button>
              </Grid>
              <Grid item lg={12} md={12}>
                <TextField
                  required
                  name='latitude'
                  fullWidth
                  id='latitude'
                  variant='outlined'
                  label={t('latitude')}
                  type='number'
                  onChange={formik.handleChange}
                  value={formik.values.latitude}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Room />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item lg={12} md={12}>
                <TextField
                  required
                  name='longitude'
                  fullWidth
                  id='longitude'
                  variant='outlined'
                  label={t('longitude')}
                  type='number'
                  onChange={formik.handleChange}
                  value={formik.values.longitude}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Room />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            {/* img side  */}
            <Grid
              container
              lg={6}
              md={6}
              justifyContent='center'
              className='px-12'
            >
              <Grid item lg={10} md={10}>
                <p className=' text-center text-sm text-gray-600 p-2'>
                  {' '}
                  {t('place_images')}
                </p>
                {formik.values.images.length !== 0 && (
                  <ShowImages images={formik.values.images} />
                )}
                <UploadBtn
                  setValue={formik.setFieldValue}
                  onChange={formik.handleChange}
                  value={formik.values.images}
                  name={'images'}
                  title={t('place_images')}
                />
                <br />
                <p className=' text-center text-sm text-gray-600 p-2'>
                  {' '}
                  {t('place_logo')}
                </p>
                {formik.values.place_logo && (
                  <ShowLogo logo={formik.values.place_logo} />
                )}
                <UploadBtnOne
                  setValue={formik.setFieldValue}
                  onChange={formik.handleChange}
                  value={formik.values.place_logo}
                  name={'place_logo'}
                  title={t('logo')}
                />
              </Grid>
            </Grid>
            <Grid item lg={4} md={4}>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                type='submit'
              >
                {t('create')}
              </Button>
            </Grid>
          </Grid>
          <MapDialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            data={
              formik.values.latitude
                ? { lat: formik.values.latitude, lng: formik.values.longitude }
                : { lat: 15.5007, lng: 32.5599 }
            }
            setData={formik.setFieldValue}
          />
        </form>
      </Grid>
    </Grid>
  );
};

export default AddPlacePage;
