import { Grid } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import Filters from './common/Filters';
import UsersList from './common/UsersList';
import PageHeading from '../../../components/common/PageHeading';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  fetchUsersList,
  clearErrors,
} from '../../core/features/userIdentitySlice';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { useIdentityTranslation } from '../i18n';
import { createSearchParams, useSearchParams } from 'react-router-dom';

function ListContainer(props) {
  const [isRefreshing, setIsRefreshing] = useState(0);
  const [payload, setPayload] = useState({
    mobile: '',
    status: null,
    deleted: null,
  });

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams(
    createSearchParams({
      page: 0,
      pageSize: 10,
      mobile: '',
      status: null,
      deleted: null,
    })
  );

  const currentSearchParams = useMemo(() => ({
    page: searchParams.get('page'),
    pageSize: searchParams.get('pageSize'),
    mobile: searchParams.get('mobile'),
    status: searchParams.get('status'),
    deleted: searchParams.get('deleted'),
  }));

  const setPage = (newPage) => {
    setSearchParams({
      ...currentSearchParams,
      page: newPage,
    });
    setIsRefreshing((prev) => prev + 1);
  };

  const { error, isLoading } = useSelector((state) => {
    return state.userIdentitySlice;
  });

  const fetchData = async () => {
    await dispatch(fetchUsersList(currentSearchParams));
  };

  const t = useIdentityTranslation();
  useEffect(() => {
    fetchData();
  }, [isRefreshing]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
    dispatch(clearErrors());
  }, [dispatch, error]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Grid container lg={12} md={12} spacing={2}>
      <Grid item lg={12} md={12}>
        <PageHeading
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          heading1={t('identity')}
          heading2={t('users')}
          backBtn
        />
      </Grid>
      <Grid item lg={12} md={12} className=' bg-gray-100 '>
        <Filters
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          payload={payload}
          setPayload={setPayload}
          setPage={setPage}
          setSearchParams={setSearchParams}
          currentSearchParams={currentSearchParams}
        />
      </Grid>
      <Grid item lg={12} md={12}>
        <UsersList
          page={Number(currentSearchParams.page)}
          setPage={setPage}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      </Grid>
    </Grid>
  );
}

export default ListContainer;
