import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

export const defaultDriver = {
  driver_id: '',
  full_name: '',
  mobile_number: '',
  device_id: '',
  role_id: 0,
  fees_group_id: 0,
  national_id: null,
  driver_licence: '',
  profile_image: '',
  car_type_id: 1,
  car_plate: '',
  car_color: '',
  car_status_id: 0,
  car_desc: '',
  car_model_year: 2020,
  car_seats_count: 0,
  online: false,
  blocked: false,
  block_reason: null,
  supervisor_note: null,
  payment_block: false,
  referral_number: null,
  avg_rating: 0,
  rating_count: 0,
  completed_order_count: 0,
  auto_dispatch_range_km: 3,
  created_at: new Date(),
  updated_at: new Date(),
  active: false,
  available: false,
  location: null,
};

export function FormatDate(dateString) {
  if (dateString) {
    // const date = new Date(dateString);
    const date = moment(dateString, 'YYYYMMDD').fromNow();
    return date;
  }
  return null;
}
export function formatDate(dateString) {
  if (dateString) {
    // const date = new Date(dateString);
    const date = moment(dateString).format('L, h:mm:ss a');
    return date;
  }
  return null;
}

// for multi select

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};

const options = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

const test = [
  { hour: 0, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 1, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 2, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 3, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 4, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 5, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 6, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 7, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 8, total_price: 5088, paid_cash: 0, paid_balance: 88 },
  { hour: 9, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 10, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 11, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 12, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 13, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 14, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 15, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 16, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 17, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 18, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 19, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 20, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 21, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 22, total_price: 0, paid_cash: 0, paid_balance: 0 },
  { hour: 23, total_price: 0, paid_cash: 0, paid_balance: 0 },
];

const checkAuth = (err) => {
  if (err) {
    if (err.status === 401) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  }
};

export { useStyles, checkAuth, MenuProps, options, test };
