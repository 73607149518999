import http from 'axios';

export default {
	fetchAll: async ({ page, pageSize }) => {
		try {
			const response = await http.get(`/dashboard/incentives?page_number=${page}&page_size=${pageSize}`);
			return response.data;
		} catch (error) {
			return error;
		}
	},

	create: async incentive => {
		try {
			const response = await http.post('/dashboard/incentives', { ...incentive });
			return response.data;
		} catch (error) {
			return error;
		}
	},

	update: async incentive => {
		try {
			const response = await http.put(`/dashboard/incentives/${incentive.incentive_id}`, { ...incentive });
			return response.data;
		} catch (error) {
			return error;
		}
	},

	complete: async id => {
		try {
			const response = await http.put(`/dashboard/incentives/${id}/complete`);
			return response.data;
		} catch (error) {
			return error;
		}
	},

	cancel: async id => {
		try {
			const response = await http.put(`/dashboard/incentives/${id}/cancel`);
			return response.data;
		} catch (error) {
			return error;
		}
	},

	announce: async id => {
		try {
			const response = await http.put(`/dashboard/incentives/${id}/announce`);
			return response.data;
		} catch (error) {
			return error;
		}
	}
};
