import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import {
  activatePendingDriver,
  rejectPendingDriver,
} from '../../../../Redux/store/pending-drivers/pendingDriversListSlice';
import { appDirection } from '../../../../shared_utils';
import { usePendingTranslation } from '../i18n';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export function ActivateFormDialog({
  driverId,
  isRefreshing,
  setIsRefreshing,
}) {
  // const { open, close, driverId } = props;
  const [reason, setReason] = React.useState(null);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const t = usePendingTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();

  const handleActivate = async (event) => {
    try {
      await dispatch(activatePendingDriver(driverId));

      toast.success(t('msg_activate'));
    } catch (err) {
      toast.error(err.data.message);

      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      // setIsRefreshing(isRefreshing + 1);
      handleClose();
      navigate('/drivers/pending-drivers');
    }
  };

  return (
    <div dir={appDirection}>
      <Button
        variant='contained'
        color='primary'
        aria-label='outlined primary button group'
        onClick={handleClickOpen}
      >
        {t('activate')}
      </Button>
      <Dialog
        fullWidth
        maxWidth='xs'
        aria-labelledby='dialog-title'
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id='dialog-title'>{t('block_dialog')}</DialogTitle>
        <DialogContent>
          <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12}>
              <div className='text-gray font-serif p-4 capitalize bg-gray-300'>
                {t('activate_driver')} {driverId}
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            lg={12}
            md={12}
            className='flex justify-center  p-2 '
            spacing={1}
          >
            <Grid item>
              <Button
                variant='outlined'
                color='secondary'
                type='submit'
                onClick={handleActivate}
              >
                {t('activate')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant='outlined' color='inherit' onClick={handleClose}>
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
