import { createSlice } from '@reduxjs/toolkit';
import incentivesApi from './api';

const initialState = {
	incentives: [],
	isLoading: false
};

const incentivesSlice = createSlice({
	name: 'incentives',
	initialState,
	reducers: {
		setLoadingState: (state, action) => {
			state.isLoading = action.payload;
		},
		fetchIncentivesSuccess: (state, action) => {
			state.incentives = action.payload;
		},
		setError: (state, actions) => {
			state.error = actions.payload.message;
		}
	}
});

export const { fetchIncentivesSuccess, setError, setLoadingState } = incentivesSlice.actions;

export const fetchIncentives = options => async dispatch => {
	try {
		dispatch(setLoadingState(true));
		const incentives = await incentivesApi.fetchAll(options);
		dispatch(fetchIncentivesSuccess(incentives));
	} catch (error) {
		dispatch(setError(error));
	}
};

export const create = incentive => async dispatch => {
	try {
		await incentivesApi.create(incentive);
	} catch (error) {
		dispatch(setError(error));
	}
};

export const update = incentive => async dispatch => {
	try {
		await incentivesApi.update(incentive);
	} catch (error) {
		dispatch(setError(error));
	}
};

export const announce = id => async dispatch => {
	try {
		await incentivesApi.announce(id);
	} catch (error) {
		dispatch(setError(error));
	}
};

export const cancel = id => async dispatch => {
	try {
		await incentivesApi.cancel(id);
	} catch (error) {
		dispatch(setError(error));
	}
};

export const complete = id => async dispatch => {
	try {
		await incentivesApi.complete(id);
	} catch (error) {
		dispatch(setError(error));
	}
};

export default incentivesSlice.reducer;
