import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../services/API/cartypes';

const initialState = {
  isLoading: false,
  carTypes: [],
  error: null,
};

export const fetchCarTypes = createAsyncThunk(
  'carTypes/fetchCarTypes',
  async (payload) => {
    return await api.getCarTypes(payload);
  }
);

const carTypesListSlce = createSlice({
  name: 'carTypesList',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchCarTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCarTypes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.carTypes = action.payload;
    });
    builder.addCase(fetchCarTypes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export default carTypesListSlce.reducer;
