import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  fetchTicketSupportUsersList,
  fetchUsersTickets,
} from '../../../../Redux/store/tickets/ticketsListSlice';
import ListFiler from '../../../tickets/components/common/ListFilters';
import TicketsList from '../../../tickets/components/TicketsList';

const Tickets = () => {
  const dispatch = useDispatch();
  const [loadingCount, setLoadingCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(0);
  const [page, setPage] = useState(0);
  const { user } = useSelector((state) => state.users).usersList;

  const [statusId, setStatusId] = useState(0);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(
        fetchUsersTickets({
          page_number: page,
          page_size: 10,
          status_id: statusId,
          user_id: user.user_id,
        })
      );
      await dispatch(fetchTicketSupportUsersList());
    } catch (err) {
      if (err.status === 401) {
        toast.error(err.data.message);
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
      setLoadingCount(100);
    }
  });

  useEffect(() => {
    fetchData();
  }, [page, isRefreshing]);

  if (isLoading && loadingCount === 0) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-[70vh] place-content-center'>
          <div class='flex items-center gap-2 text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            loading...
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <Grid container lg={12} md={12} justifyContent='center' className='pt-2'>
      <Grid item lg={12} md={12} className=' flex justify-center pb-4'>
        <ListFiler
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          statusId={statusId}
          setStatusId={setStatusId}
        />
      </Grid>
      <Grid item lg={12} md={12}>
        <TicketsList
          setPage={setPage}
          isLoading={isLoading}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          type='users'
        />
      </Grid>
    </Grid>
  );
};

export default Tickets;
