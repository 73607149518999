import React, { useState, useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchTicketNotes } from '../../../../Redux/store/tickets/ticketsListSlice';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import CreatenoteDialog from '../common/CreateNoteDialog';
import { format } from '../../utils';
import { Empty } from '../../../drivers/components/common/Empty';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { useTicketTranslation } from '../../i18n';
import { appDirection } from '../../../../shared_utils';
import { DataGrid } from '@mui/x-data-grid';

function CustomPagination(loading) {
  return (
    <Grid className=' flex justify-center relative space-x-4 py-4 w-full'></Grid>
  );
}

const TicketNotes = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [isRefreshing, setIsRefreshing] = useState(0);

  const { notes } = useSelector((state) => state.tickets).ticketsList;
  const t = useTicketTranslation();

  const fetchData = useCallback(async () => {
    try {
      await dispatch(fetchTicketNotes(id));
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [isRefreshing]);

  const cols = [
    {
      field: 'note',
      headerName: t('note'),
      headerClassName: ' bg-gray-100  text-center w-full',
      disableColumnMenu: true,
      flex: 3,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs pl-2'
        >
          <Grid item lg={10} md={10} className='pt-4'>
            {params.value.note}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'supervisor',
      headerName: t('supervisor_name'),
      headerClassName: ' bg-gray-100  text-center w-full',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs mx-2 '
        >
          <Grid item lg={6} md={6} className='pt-4'>
            {params.value.supervisor_name}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'date',
      headerName: t('date'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className='text-xs mx-2 '
        >
          <Grid item lg={6} md={6} className='pt-4   '>
            {format(params.value.created_at)}
          </Grid>
        </Grid>
      ),
    },
  ];

  const rowsData = notes.map((data) => {
    return {
      id: data.id,
      note: data,
      supervisor: data,
      date: data,
    };
  });

  return (
    <Grid lg={12} md={12} dir={appDirection}>
      <Grid lg={12} md={12} className='my-2'>
        <CreatenoteDialog
          ticket_id={id}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      </Grid>

      <Grid className=' flex-grow'>
        <DataGrid
          className='bg-white '
          disableColumnFilter
          autoHeight
          density='compact'
          rowHeight={60}
          rows={rowsData}
          columns={cols}
          pageSize={1}
          rowCount={100}
          paginationMode='server'
          hideFooterPagination={true}
          CellStyle='{StaticResource DataGridBorder}'
          components={{
            // Pagination: () => CustomPagination(),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: LoadingIndicator,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default TicketNotes;
