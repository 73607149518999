import React, { useCallback } from 'react';
import {
  AccountCircle,
  CalendarToday,
  LocalPhone,
  MonetizationOn,
  SwapVert,
} from '@material-ui/icons';
import { formatDate } from '../../../modules/drivers/utils';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPointsList,
  fetchOrdersSuccess,
} from '../../../Redux/store/shareTrip/shareTripListSlice';

function PopupList({ selectedTrips, setSelectedTrips, trip, id }) {
  const dispatch = useDispatch();
  const { trips, pointsList } = useSelector((state) => {
    return state.shareTrip.shareTripList;
  });

  const handlePickup = async (trip, id) => {
    await dispatch(
      setPointsList({
        id: id,
        action: 'pickup',
        order_id: trip.order_id,
        full_name: trip.user.full_name,
        distance_km: trip.distance_km,
        price: trip.price,
        estimated_duration: trip.estimated_duration,
        lat: trip.pick_up_latitude,
        long: trip.pick_up_longitude,
      })
    );
    // setOpen(false);
    let newArr = selectedTrips.map((item, i) => {
      if (id === i) {
        return { ...item, showPickUp: false };
      }
      return item;
    });
    setSelectedTrips(newArr);
    // await dispatch(fetchOrdersSuccess(newArr));
  };
  return (
    <button onClick={() => handlePickup(trip, id)}>
      <Grid item className='font-base text-left'>
        <div className='pb-1'>
          <AccountCircle
            fontSize='medium'
            style={{ color: '#3C99DC' }}
            className='pr-1'
          />
          {trip.user.full_name}
        </div>
        <div className='pb-1'>
          <LocalPhone
            fontSize='medium'
            style={{ color: '#3C99DC' }}
            className='pr-1'
          />
          {trip.user.mobile}
        </div>
        <div className='pb-1'>
          <SwapVert
            fontSize='medium'
            style={{ color: '#3C99DC' }}
            className='pr-1'
          />
          {trip.distance_km}
        </div>
        <div className='pb-1'>
          <MonetizationOn
            fontSize='medium'
            style={{ color: '#3C99DC' }}
            className='pr-1'
          />
          {trip.price}
        </div>
        <div className='pb-1'>
          <CalendarToday
            fontSize='medium'
            style={{ color: '#3C99DC' }}
            className='pr-1'
          />
          {formatDate(trip.created_at)}
        </div>
      </Grid>
    </button>
  );
}

export default PopupList;
