import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@mui/styles';
import { AppBar, Tabs, Tab, Typography, Grid, Box } from '@mui/material';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import TicketMainDetails from './tabs/TicketMainDetails';
import TicketTransactions from './tabs/TicketTransactions';
import TicketChat from './tabs/TicketChat';
import AssignDialog from './common/AssignDialog';
import UpdateStatusDialog from './common/UpdateStatusDialog';
import UpdateTicketDialog from './common/UpdateTicketDialog';
import TicketNotes from './tabs/TicketNotes';

import { useDispatch, useSelector } from 'react-redux';
import { useTicketTranslation } from '../i18n';

//tabs config
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

//main component
const TicketDetails = ({ isLoading, isRefreshing, setIsRefreshing, type }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const { ticketDetails } = useSelector((state) => state.tickets).ticketsList;
  const t = useTicketTranslation();

  //assign dialog configuration
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedTicketId, setSelectedTicketId] = React.useState(0);
  const handleOpenAssignDialog = React.useCallback((ticketId) => {
    setSelectedTicketId(ticketId);

    setIsOpen(true);
  });

  const handleCloseAction = React.useCallback(async () => {
    setIsOpen(false);
  });

  //upadate Ticket Status dialog
  const [Open, setOpen] = React.useState(false);
  const [ticketId, setTicketId] = React.useState(0);
  const handleOpenUpdateStatusDialog = React.useCallback((ticketId) => {
    setTicketId(ticketId);
    setOpen(true);
  });

  //upadate Ticket dialog
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
  const handleOpenUpdateDialog = React.useCallback((ticket) => {
    setDialogIsOpen(true);
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Grid container lg={12} md={12} className='bg-gray-100 '>
      <AssignDialog
        handleCloseAction={handleCloseAction}
        isOpen={isOpen}
        ticketId={selectedTicketId}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />
      <UpdateStatusDialog
        setIsOpen={setOpen}
        isOpen={Open}
        ticketId={ticketId}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />
      <UpdateTicketDialog
        setIsOpen={setDialogIsOpen}
        isOpen={dialogIsOpen}
        ticket={ticketDetails}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />
      <Grid item lg={12} md={12}>
        <AppBar position='static' color='default' className=''>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            aria-label='full width tabs example'
            className='px-4'
            centered
          >
            <Tab label={t('Details')} {...a11yProps(0)} />
            <Tab label={t('notes')} {...a11yProps(1)} />
            <Tab label={t('Chat')} {...a11yProps(2)} />
            <Tab label={t('Transactions')} {...a11yProps(3)} />
          </Tabs>
        </AppBar>
      </Grid>

      <Grid item lg={12} md={12} xs={12}>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel
            value={value}
            index={0}
            className=' h-auto min-h-[90vh] overflow-visible'
          >
            <TicketMainDetails
              type={type}
              handleOpenAssignDialog={handleOpenAssignDialog}
              handleOpenUpdateStatusDialog={handleOpenUpdateStatusDialog}
              handleOpenUpdateDialog={handleOpenUpdateDialog}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            className=' h-auto min-h-[90vh] overflow-visible'
          >
            <TicketNotes />
          </TabPanel>
          <TabPanel value={value} index={2} className=' h-auto min-h-[90vh]'>
            <TicketChat />
          </TabPanel>

          <TabPanel value={value} index={3} className=' h-auto min-h-[90vh]'>
            <TicketTransactions />
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
  );
};

export default TicketDetails;
