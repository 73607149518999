import { Button, Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import './Map.css';
import L, { map, marker } from 'leaflet';
import polyline from '@mapbox/polyline';

import {
  getSharedOrders,
  setPointsList,
  setTrips,
} from '../../Redux/store/shareTrip/shareTripListSlice';
import PopupList from './component/PopupList';
import DropoffList from './component/DropoffList';
import TripsList from './component/TripsList';
import { current } from '@reduxjs/toolkit';
// import { usePrevious } from 'react-delta';

const Map = ({ isLoading, setIsLoading }) => {
  // const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(1000);
  const [selectedTrips, setSelectedTrips] = useState([]);
  const [options, setOptions] = useState({
    center_lat: '15.544599501055671',
    center_lng: '32.533712424337864',
    page_number: page,
    page_size: pageSize,
    status_id: 8,
  });

  const [removeOrderId, setRemoveOrderId] = useState([]);

  const dispatch = useDispatch();
  const { trips } = useSelector((state) => {
    return state.shareTrip.shareTripList;
  });

  // const prevValue = usePrevious(trips);

  const fetchSharedOrders = useCallback(async () => {
    try {
      let pendingTrips = [];

      // setIsLoading(true);
      await dispatch(getSharedOrders(options));

      trips.map((item) => {
        if (!pendingTrips.some((e) => e.order_id == item.order_id)) {
          return pendingTrips.push({
            ...item,
            showPickUp: true,
            showDropOff: true,
          });
        }
      });
      setSelectedTrips(pendingTrips);
    } catch (err) {
      console.error(err);
    } finally {
      // setIsLoading(false);
      // setIsRefreshing(isRefreshing + 1);
    }
  }, []);

  const fetchData = useCallback(async () => {
    try {
      // let pendingTrips = [];
      await dispatch(getSharedOrders(options));
    } catch (err) {
      console.error(err);
    } finally {
      // setIsLoading(false);
      // setIsRefreshing(isRefreshing + 1);
    }
  }, []);

  useEffect(() => {
    fetchSharedOrders();

    const interval = setInterval(async () => {
      // fetchUpdate();
      fetchData();
      console.log(selectedTrips);
      let pp = [...selectedTrips];
      trips.map((item) => {
        if (pp.some((e) => e.order_id == item.order_id)) {
          return pp.push({
            ...item,
            showPickUp: true,
            showDropOff: true,
          });
        }
      });
      console.log(pp);
      setSelectedTrips(pp);
    }, 5000);

    return () => clearInterval(interval);
  }, []); //isloading or isrefreshung

  const dropoff = new L.Icon({
    iconUrl: require('../../assets/svg/dropoff.png'),
    iconSize: [35, 40],
    iconAnchor: [17, 26],
  });

  const fillBlueOptions = { color: 'blue' };

  return (
    <>
      <MapContainer
        center={['15.544599501055671', '32.533712424337864']}
        zoom={11}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />

        {selectedTrips.length !== '0'
          ? selectedTrips.map((trip, i) => {
              return trip.showPickUp ? (
                <Marker
                  key={i}
                  position={[trip.pick_up_latitude, trip.pick_up_longitude]}
                >
                  <Popup closeButton={false}>
                    <PopupList
                      selectedTrips={selectedTrips}
                      setSelectedTrips={setSelectedTrips}
                      trip={trip}
                      id={i}
                    />
                  </Popup>
                </Marker>
              ) : (
                ''
              );
            })
          : ''}

        {trips.length !== '0'
          ? trips.map((trip, i) => (
              <Polyline
                key={i}
                pathOptions={fillBlueOptions}
                positions={polyline.decode(trip.route_ref) || ''}
              />
            ))
          : ''}

        {trips.length !== '0'
          ? trips.map((trip, i) => {
              return trip.drop_off_latitude !== '' ? (
                <Marker
                  key={i}
                  position={
                    [trip.drop_off_latitude, trip.drop_off_longitude] || []
                  }
                  icon={dropoff}
                >
                  <Popup closeButton={false}>
                    <DropoffList trip={trip} id={i} />
                  </Popup>
                </Marker>
              ) : (
                ''
              );
            })
          : ''}
      </MapContainer>
      <Grid item lg={5} md={5} className='relative bottom-0 left-0 bg-white  '>
        <TripsList
          selectedTrips={selectedTrips}
          setSelectedTrips={setSelectedTrips}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </Grid>
    </>
  );
};

export default Map;
