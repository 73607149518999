import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageHeading from '../../../components/common/PageHeading';
import { newOtp } from '../../../Redux/store/users/usersListSlice';
import { validateMobile } from '../utils';
import { generateOtpFromValidationSchema } from '../validations/validation';
import * as yup from 'yup';
import { useUsersTranslation } from '../i18n';
import { appDirection } from '../../../shared_utils';
import { toast } from 'react-toastify';

const GenerateOtpsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [payload, setPayload] = useState({
    service_id: 'LEMON_APP_ID',
    mobile: '',
  });
  const [showNewOtp, setShowNewOpt] = useState(false);
  const { OTP } = useSelector((state) => state.users).usersList;
  const dispatch = useDispatch();

  const generateNewOtp = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(
        newOtp({ ...payload, mobile: validateMobile(payload.mobile) })
      );
      setShowNewOpt(true);
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  const handleChange = (e, type) => {
    if (type === 'mobile') {
      setPayload({ ...payload, mobile: e.target.value });
    } else {
      setPayload({ ...payload, service_id: e.target.value });
    }
  };
  const t = useUsersTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowNewOpt(false);
    const formData = {
      mobile: payload.mobile,
    };

    const isValid = await generateOtpFromValidationSchema.isValid(formData);
    if (isValid) {
      generateNewOtp();
    } else {
      toast.error(t('msg_invalid'));
    }
  };
  return (
    <Grid
      container
      lg={12}
      md={12}
      justifyContent='center'
      spacing={2}
      className='p-6 '
    >
      <Grid item lg={12} md={12}>
        <PageHeading
          heading1={t('users')}
          heading2={t('generate_otp')}
          backBtn
        />
      </Grid>

      <Grid
        item
        lg={6}
        md={6}
        className='p-4 bg-gray-50  rounded-lg shadow-lg shadow-gray-500'
      >
        <form onSubmit={handleSubmit}>
          <Grid item lg={12} md={12} className='py-6 px-24'>
            <TextField
              label={t('service')}
              className='w-full'
              select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={payload.service_id}
              onChange={(e) =>
                setPayload({ ...payload, service_id: e.target.value })
              }
            >
              <MenuItem value='LEMON_APP_ID'>{t('lemon_client')}</MenuItem>
              <MenuItem value='DISPATCH_ID'>{t('lemon_driver')}</MenuItem>
              <MenuItem value='LEMON_TAXI_SUPPORT_ID'>{t('taxi_support')}</MenuItem>
              <MenuItem value='LEMON_FOOD_SUPPORT_ID'>{t('food_support')}</MenuItem>
              <MenuItem value='LEMON_FOOD_RESTAURANT_ID'>{t('lemon_store')}</MenuItem>
              <MenuItem value='LEMON_EVENTS_PORTAL_ID'>{t('lemon_events_portal')}</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={12} md={12} className='py-6 px-24'>
            <TextField
              id='outlined-basic'
              InputLabelProps={{ shrink: true }}
              fullWidth
              label={t('mobile')}
              type='tel'
              InputProps={{ max: 10 }}
              placeholder='09XXXXXXXX'
              value={payload.mobile}
              onChange={(e) =>
                setPayload({ ...payload, mobile: e.target.value })
              }
            />
          </Grid>
          <Grid item lg={12} md={12} className='py-6 px-24 flex justify-center'>
            <Button
              disabled={isLoading}
              type='submit'
              variant='contained'
              color='primary'
              className='min-width-sm'
              // onClick={(e) => generateNewOtp()}
            >
              {isLoading ? <CircularProgress size={15} /> : t('generate_otp')}
            </Button>
          </Grid>
        </form>{' '}
      </Grid>

      <Grid container lg={12} md={12} className=''>
        <Grid item lg={12} md={12} className='flex justify-center pt-6'>
          {showNewOtp && (
            <Grid item lg={3} md={3} sm={6} xs={9}>
              <Paper elevation={6} className=' p-2  '>
                <span className=''>
                  <h1 className='w-full pt-3 text-center uppercase text-sm font-serif font-semibold text-gray-500'>
                    {t('new_otp')}
                  </h1>

                  <h1 className='w-full pt-3 text-center uppercase text-sm font-serif font-semibold text-gray-500'></h1>
                </span>
                <p className='w-full p-5 text-center font-medium text-2xl'>
                  {OTP.code}
                  {/* { setTotal(total+count)} */}
                </p>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GenerateOtpsPage;
