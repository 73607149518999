import http from '../http';

export const getEvents = async ({ id: providerID, page }) => {
  providerID = providerID || '';
  try {
    const { data } = await http.get(
      `/dashboard/events?page_number=${page}&page_size=100&provider_id=${providerID}&is_deleted=false`
    );
    return data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
};
