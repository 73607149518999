import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useSelector } from 'react-redux';
import Details from './tabs/Details';
import PlaceImage from './tabs/PlaceImage';
import Reviews from './tabs/Reviews';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import {
  AppBar,
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import { usePlacesTranslation } from '../i18n';

//tabs config
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

//main component
const PlaceDetails = ({ isLoading, setIsLoading }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const { placeDetails } = useSelector((state) => state.places).places;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const t = usePlacesTranslation();

  return (
    <Grid container lg={12} md={12} sm={12} className='bg-gray-100 '>
      <Grid item lg={12} md={12} sm={12}>
        <AppBar position='static' color='default' className=''>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            aria-label='full width tabs example'
            className='px-4'
            centered
          >
            <Tab label={t('details')} {...a11yProps(0)} />
            <Tab label={t('images')} {...a11yProps(1)} />
            <Tab label={t('reviews')} {...a11yProps(2)} />
          </Tabs>
        </AppBar>
      </Grid>

      <Grid item lg={12} md={12} xs={12}>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel
            value={value}
            index={0}
            dir={theme.direction}
            className=' h-auto min-h-[90vh]'
          >
            <Grid container lg={12} md={12}>
              {/* <Orders /> */}
              {placeDetails ? (
                <Details placeDetails={placeDetails} isLoading={isLoading} />
              ) : (
                <LoadingIndicator />
              )}
            </Grid>
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            dir={theme.direction}
            className=' h-auto min-h-[90vh]'
          >
            <Grid container lg={12} md={12}>
              <PlaceImage />
            </Grid>
          </TabPanel>
          <TabPanel
            value={value}
            index={2}
            dir={theme.direction}
            className=' h-auto min-h-[90vh]'
          >
            <Grid container lg={12} md={12}>
              <Reviews isLoading={isLoading} setIsLoading={setIsLoading} />
            </Grid>
          </TabPanel>
          <TabPanel
            value={value}
            index={3}
            dir={theme.direction}
            className=' h-auto min-h-[90vh]'
          >
            <Grid container lg={12} md={12}></Grid>
          </TabPanel>
          <TabPanel
            value={value}
            index={4}
            dir={theme.direction}
            className=' h-auto min-h-[90vh]'
          ></TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
  );
};

export default PlaceDetails;
