import axios from 'axios';
import storage from '../../../storage';

const baseURL = process.env.REACT_APP_EVENTS_API;

const http = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'x-lemon-client-id': process.env.REACT_APP_CLIENT_ID,
    'x-lemon-client-secret': process.env.REACT_APP_CLIENT_SECRET,
  },
});

http.interceptors.request.use(function (config) {
  let token = JSON.parse(localStorage.getItem('token'));
  config.headers['Authorization'] = 'Bearer ' + token;
  config.headers.lang = storage.lang.getLang();
  return config;
});

export const requestHandler = async (req) => {
  try {
    return await req();
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
};

export const handler = async (req) => {
  try {
    const { data } = await req;
    return data;
  } catch (error) {
    if (error.response) {
      if (error.response.status == 401) {
        storage.auth.removeToken();
        window.location.reload();
      }
      throw error.response.data;
    } else {
      throw error;
    }
  }
};

export default http;
