import React, { useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';

import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import { updateGroupDetails } from '../../../../Redux/store/places/placesListSlice';
import { usePlacesTranslation } from '../../i18n';

const Form = ({ isOpen, setIsOpen, isRefreshing, setIsRefreshing }) => {
  const { groupDetails } = useSelector((state) => state.places).places;
  const dispatch = useDispatch();

  const handleUpdate = useCallback(async (values) => {
    try {
      await dispatch(updateGroupDetails(values));
      dispatch(
        setAlert({
          type: 'success',
          message: 'Group has been updated successfully',
          isOpen: true,
        })
      );

      setIsOpen(false);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
    }
  });

  const formik = useFormik({
    initialValues: groupDetails,
    onSubmit: (values, { resetForm }) => {
      handleUpdate(values);
      setIsRefreshing(isRefreshing + 1);
    },
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  const t = usePlacesTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      fullWidth={'sm'}
      maxWidth={'sm'}
    >
      <DialogTitle id='form-dialog-title' onClose={handleClose}></DialogTitle>

      <DialogContent>
        {/* general info */}
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            lg={12}
            md={12}
            spacing={2}
            style={{ padding: '20px 10px' }}
          >
            {/* header */}
            <Grid
              item
              lg={12}
              className=' text-2xl w-full  text-gray-600 font-bold font-mono  uppercase'
            >
              {t('personal_info')}
            </Grid>

            {/* body  */}

            <Grid item lg={6}>
              <TextField
                variant='outlined'
                margin='dense'
                id='name_ar'
                name='name_ar'
                label={t('name_ar')}
                fullWidth
                value={formik.values.name_ar}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                variant='outlined'
                margin='dense'
                id='name_en'
                name='name_en'
                label={t('name_en')}
                fullWidth
                value={formik.values.name_en}
                onChange={formik.handleChange}
              />
            </Grid>

            <Grid item lg={12} md={12} xs={12} className='flex justify-end'>
              <Button variant='contained' color='primary' type='submit'>
                {t('update')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
