import { combineReducers } from '@reduxjs/toolkit';
// import auth from 'app/auth/store';
// import fuse from './fuse';
import i18n from './i18nSlice';
import app from './app';
import drivers from './drivers';
import pendingDrivers from './pending-drivers';
import dispatches from './dispatches';
import cars from './cars';
import incentives from './incentives';
import inboxMessages from './inbox-messages';
import orders from './orders';
import users from './users';
import tickets from './tickets';
import places from './places';
import shareTrip from './shareTrip';
import demand_supply from './demand_supply';

const createReducer = (asyncReducers) =>
  combineReducers({
    // auth,
    // fuse,
    i18n,
    app,
    drivers,
    pendingDrivers,
    dispatches,
    cars,
    orders,
    incentives,
    inboxMessages,
    users,
    tickets,
    places,
    shareTrip,
    demand_supply,
    ...asyncReducers,
  });

export default createReducer;
