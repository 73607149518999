import {
  Autocomplete,
  Button,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Slide,
  TextField,
  Toolbar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { GridCloseIcon } from '@mui/x-data-grid-pro';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PageHeading from '../../../components/common/PageHeading';
import { setAlert } from '../../../Redux/store/app/appSlice';
import {
  createNewGroup,
  getAmenitiesList,
} from '../../../Redux/store/places/placesListSlice';
import { usePlacesTranslation } from '../i18n';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const AddGroupPage = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { amenitiesList } = useSelector((state) => state.places).places;

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(getAmenitiesList());
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  const createGroup = useCallback(async (payload) => {
    try {
      setIsLoading(true);
      await dispatch(createNewGroup(payload));
      toast.success(t('place_added_successfuly'));
    } catch (err) {
      toast.error(err.data.messag);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });
  const [formData, setFormData] = useState({
    name_en: '',
    name_ar: '',
    amenity_id: [],
    group_type: 1,
    sorted_by: 2,
  });

  const formik = useFormik({
    initialValues: formData,
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      let payload = { ...values, sorted_by: values.group_type };

      createGroup(values);

      resetForm();
    },
  });

  useEffect(() => {
    fetchData();
    // handleValuesSetup();
  }, []);

  const t = usePlacesTranslation();

  if (isLoading) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-screen place-content-center'>
          <div class='flex items-center  text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            {t('loading')}...
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <Grid container lg={12} md={12} justifyContent='center' spacing={2}>
      <Grid item lg={12} md={12} className='px-2  pl-2 text-white relative'>
        <PageHeading heading1={t('places')} heading2={t('add_group')} backBtn />
      </Grid>
      <Grid
        item
        lg={10}
        md={10}
        className='shadow-lg shadow-gray-600  rounded-md '
      >
        <form onSubmit={formik.handleSubmit} className='w-full p-24'>
          <Grid container lg={12} md={12} spacing={2} justifyContent='center'>
            <Grid item lg={12} md={12}>
              <TextField
                required
                name='name_en'
                fullWidth
                id='name_en'
                variant='outlined'
                label={t('name_en')}
                type={'text'}
                onChange={formik.handleChange}
                value={formik.values.name_en}
              />
            </Grid>
            <Grid item lg={12} md={12}>
              <TextField
                required
                name='name_ar'
                fullWidth
                id='name_ar'
                variant='outlined'
                label={t('name_ar')}
                type={'text'}
                onChange={formik.handleChange}
                value={formik.values.name_ar}
              />
            </Grid>

            <Grid item lg={12} md={12}>
              <TextField
                fullWidth
                required
                variant='outlined'
                id='group_type'
                name='group_type'
                select
                label={t('group_type')}
                onChange={formik.handleChange}
                value={formik.values.group_type}
              >
                <MenuItem value={2}>{t('manually_selected')}</MenuItem>
                <MenuItem value={1}>{t('automatically_selected')}</MenuItem>
              </TextField>
            </Grid>
            <Grid item lg={12} md={12}>
              <Autocomplete
                disabled={formik.values.group_type === 1 ? true : false}
                multiple
                id='amenity_id'
                name='amenity_id'
                options={amenitiesList}
                getOptionLabel={(option) => option.name}
                style={{ width: '100%' }}
                onChange={(event, value) => {
                  let newValue = value.map((value) => value.amenity_id);
                  console.log(newValue);
                  formik.setFieldValue('amenity_id', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('amenity')}
                    variant='outlined'
                    value={formik.values?.amenity_id}
                  />
                )}
              />
            </Grid>

            <Grid item lg={12} md={12} className='flex justify-center'>
              <Button
                size='large'
                variant='contained'
                color='primary'
                type='submit'
              >
                {t('create')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
{
}
export default AddGroupPage;
