import http from '../../../services/APIs/dispatch_api';
// { page, pageSize ,mobile, carTypeId }

export const pendingDriversApi = {
  fetchPendingDrivers: async ({ page, pageSize, mobile, carTypeId }) => {
    try {
      const queryString = `page_size=${pageSize}&page_number=${page}${
        mobile === '' || !mobile ? '' : `&mobile=${mobile}`
      }${carTypeId ? `&car_type_id=${carTypeId}` : ''}`;
      const response = await http.get(
        `/dashboard/pending_drivers?${queryString}`
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  findPendingDriver: async (id) => {
    try {
      const response = await http.get(`/dashboard/pending_drivers/${id}`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  fetchCarTypes: async () => {
    try {
      const response = await http.get('/dashboard/car_types');
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  fetchPendingDriverFiles: async (id) => {
    try {
      const response = await http.get(`/dashboard/pending_driver_files/${id}`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  activatePendingDriver: async (id) => {
    try {
      const response = await http.put(
        `/dashboard/pending_drivers/${id}/activate`,
        { driver_id: id }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  rejectPendingDriver: async ({ id, reason }) => {
    try {
      const response = await http.put(
        `/dashboard/pending_drivers/${id}/reject`,
        {
          driver_id: id,
          reject_reason: reason,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  needUpdatePendingDriver: async ({ id, reason }) => {
    try {
      const response = await http.put(
        `/dashboard/pending_drivers/${id}/need_update`,
        {
          driver_id: id,
          reject_reason: reason,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  updateDriver: async (driver) => {
    try {
      const response = await http.put(
        `/dashboard/pending_drivers/${driver.driverId}`,
        driver
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
};
