const orders_en = {
  orders: 'Orders',
  oredrs_list: 'Orders List',
  orders_details: 'Orders Details',
  filters: 'Filters',
  status: 'Status',
  all: 'All',
  car_type: 'Car type',
  apply: 'Apply',
  user_mobile: 'User Mobile',
  driver_mobile: 'Driver Mobile',
  search_with: 'Search with',
  client: 'Client',
  driver: 'Driver',
  distance: 'Distance',
  km: 'KM',
  paid_info: 'Paid info',
  SDG: 'SDG',
  dates: 'Dates',
  actions: 'Actions',
  create_order: 'Create Order For Customer',
  select_car: 'Select Car',
  open_trip: 'Open Trip',
  dropoff_location: 'Dropoff location',
  pickup_location: 'Pickup location',
  route_details: 'Route Details',
  Customer_phone: 'Customer phone number',
  lemon_go: 'Lemon Go',
  lemon_one: 'Lemon one',
  lemon_star: 'Lemon star',
  lemon_xl: 'lemon xl',
  lemon_box: 'lemon BOX',

  yalla: 'Yalla',
  cancel: 'Cancel',
  note: 'Note To driver',
  client_mobile: 'Client Mobile',
  search: 'Search',
  estimated_price: 'Estimated price',
  estimated_distance: 'Estimated distance',
  msg_created: 'Order Created Successfully',
  reset: 'Reset',
  no_place: 'There is no place',
  cancle: 'Cancle',
  AreyousureyouwanttocanceltheOrder:
    'Are you sure do you want to cancle the order?',
  confirm: 'Confirm',
  details: 'Details',
  transactions: 'Transactions',
  tracking: 'Tracking',
  Dispatch_ID: 'Dispatch ID',
  Dispatch_details: 'Dispatch Details',
  Driver_ID: 'Driver ID',
  Creation_at: 'Creation At',
  Updated_at: 'Updated At',
  Car_Type: 'Car type',
  Peak_Rate: 'Peak Rate',
  Trip_details: 'Trip Details',
  From: 'From',
  To: 'To',
  Price: 'Price',
  Open_trip_Price: 'Open trip price',
  Distance_details: 'Distance Details',
  Distance: 'Distance',
  Duration: 'Duration',
  Open_Trip_Distance: 'Open trip distance',
  Open_Trip_Duration: 'Open trip duration',
  Client_details: 'Client Details',
  Client: 'Client',
  Client_Mobile: 'Client Mobile',
  Order_Mobile: 'Order Mobile',
  Client_Note: 'Client Note',
  create_ticket: 'Create Ticket',
  Order_Transactins: 'Order Transactions',
  Created_By: 'Created By',
  Supervisor: 'Supervisor',
  Admin: 'Admin',
  System: 'System',
  Driver: 'Driver',
  Driver_Name: 'Driver Name',
  Paid_cash: 'Paid Cash',
  Paid_balance: 'Paid Balance',
  Paid_promo: 'Paid Promo',
  orders_list: 'Orders List',
  reorder_successfully: 'Reorder Successfully',
  are_you_sure_do_you_want_to_reorder: 'Are you sure do you want to reorder',
  pending: 'Pending',
  accepted: 'Accepted',
  timed_out: 'Timed out',
  canceled_by_admin: 'Canceled by admin',
  canceled: 'Canceled',
  canceled_by_user: 'Canceled by user',
  arrived: 'Arrived',
  picked: 'Picked',
  completed: 'Completed',
  pending_matching: 'Pending matching',
  matching_timeout: 'Matching timeout',
  not_show: 'Not show',
  canceled_by_driver: 'Canceled by driver',
  canceled_by_system: 'Canceled by system',
  canceled_by_user_after_accepted: 'Canceled by user after accepted',
  total: 'Total',
};

export default orders_en;
