import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
// import UserDetails from '../components/UserDetails';
import {
  fetchUserById,
  setError,
} from '../../../Redux/store/users/usersListSlice';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import UserDetailsTabs from '../components/UserDetailsTabs';
import { fetchCarTypes } from '../../../Redux/store/drivers/driversListSlice';
import PageHeading from '../../../components/common/PageHeading';
import { useUsersTranslation } from '../i18n';
import { toast } from 'react-toastify';

const UserDetailsPage = () => {
  // component state
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(0);
  const navigate = useNavigate();
  // component data
  const { id } = useParams();
  const dispatch = useDispatch();
  const t = useUsersTranslation();

  const fetchUser = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        dispatch(fetchUserById(id)),
        dispatch(fetchCarTypes()),
      ]);
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    fetchUser();
  }, [isRefreshing]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Grid container lg={12} md={12} sm={12} spacing={2} className=' h-full'>
      <Grid item lg={12} md={12} className='px-2  pl-2 text-white relative'>
        <PageHeading
          previousUrl={'users'}
          heading1={t('users')}
          heading2={t('users_list')}
          heading3={t('user_details')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} className='h-full'>
        <UserDetailsTabs
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      </Grid>
    </Grid>
  );
};

export default UserDetailsPage;
