import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Pane,
  Circle,
  Polyline,
  useMap,
} from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import './Map.css';
import PinDropIcon from '@material-ui/icons/PinDrop';
import L, { map } from 'leaflet';
import polyline from '@mapbox/polyline';
import { HorizontalSplit } from '@material-ui/icons';
import http1 from '../../../../../services/APIs/dispatch_api';
import {
  setAlert,
  getTripActualRoute,
} from '../../../../../Redux/store/app/appSlice';
import LoadingIndicator from '../../../../../components/common/LoadingIndicator';

const Map = ({
  driver_id,
  pickup_lang,
  pickup_lat,
  dropoff_lat,
  dropoff_lang,
  route_ref,
  dispatch_id,
}) => {
  const [isLocated, setIsLocated] = useState(false);
  const dispatch = useDispatch();
  const { tripActualRoute } = useSelector((state) => state.app).app;
  const [isLoading, setIsLoading] = useState(false);
  const [driverLocation, setDriverLocation] = useState({
    driver_id: '',
    accuracy: 0,
    speed: 0,
    bearing: 0,
    longitude: 0,
    latitude: 0,
    provider: '',
    updated_at: '',
  });

  const [actualRoute, setActualRoute] = useState(null);

  const fetchDriverLocation = useCallback(async () => {
    try {
      const res = await http1.get(
        `http://138.68.83.187:3000/api/v1/dashboard/drivers/location/${driver_id}`
      );

      if (res.data.location != null) {
        setIsLocated(true);
        setDriverLocation({
          driver_id: res.data.location.driver_id,
          accuracy: res.data.location.accuracy,
          speed: res.data.location.speed,
          bearing: res.data.location.bearing,
          longitude: res.data.location.longitude,
          latitude: res.data.location.latitude,
          provider: res.data.location.provider,
          updated_at: res.data.location.updated_at,
        });
      } else {
        setIsLocated(false);
      }
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
    }
  });

  useEffect(() => {
    if (driver_id) {
      fetchDriverLocation();
      const interval = setInterval(() => {
        fetchDriverLocation();
      }, 15000);

      return () => clearInterval(interval);
    }
  }, []);

  const fetchActualRoute = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(getTripActualRoute({ id: 6581580790335799296 }));
      //6581580790335799296
      // console.log();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    fetchActualRoute();
  }, []);

  useEffect(() => {
    const route = tripActualRoute.map((value) => {
      return [value.latitude, value.longitude];
    });
    setActualRoute(route);
  }, [tripActualRoute]);

  const dropoff = new L.Icon({
    iconUrl: require('../../../../../assets/svg/dropoff.png'),
    iconSize: [35, 40],
    iconAnchor: [17, 26],
  });

  const car = new L.Icon({
    iconUrl: require('../../../../../assets/svg/PngItem_1509028.png'),
    iconSize: [20, 35],
    iconAnchor: [17, 0],
  });

  const fillBlueOptions = { color: 'blue' };
  const purpleOptions = { color: 'purple' };

  if (isLoading) {
    return (
      <div>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <MapContainer
      center={[pickup_lat, pickup_lang] || []}
      zoom={11}
      scrollWheelZoom={true}
      style={{ height: '100%', width: '100%' }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {/* dispatch location */}
      <Marker
        position={[pickup_lat, pickup_lang] || []}
        // icon={car}
      >
        <Popup closeButton={false}>Pickup</Popup>
      </Marker>

      {route_ref && (
        <Polyline
          pathOptions={fillBlueOptions}
          positions={polyline.decode(route_ref)}
        />
      )}

      {driver_id && isLocated && (
        <Marker
          position={[driverLocation.latitude, driverLocation.longitude]}
          icon={car}
        />
      )}
      {dropoff_lat && (
        <Marker position={[dropoff_lat, dropoff_lang] || []} icon={dropoff}>
          <Popup closeButton={false}>Dropoff</Popup>
        </Marker>
      )}

      {actualRoute ? (
        <Polyline pathOptions={purpleOptions} positions={actualRoute} />
      ) : (
        console.log(null)
      )}
    </MapContainer>
  );
};

export default Map;
