import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import {
  getDriverBalance,
  getDriverTransactions,
  addDriverBalance,
  deductDriverBalance,
  setError,
  setLoadingState,
} from '../../../../Redux/store/drivers/driversListSlice';
// import { UpdateBalanceForm } from "../common/UpdateBalanceForm";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import BalanceTable from '../common/BalanceTable';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import UpdateBalanceForm from '../common/UpdateBalanceForm';
import { Backdrop, CircularProgress, makeStyles } from '@mui/material';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import { appDirection } from '../../../../shared_utils';
import { toast } from 'react-toastify';

function DriverBalance(props) {
  const { t } = useTranslation('drivers');
  const dispatch = useDispatch();
  const { balance, transactions } = useSelector(
    (state) => state.drivers
  ).driversList;
  const [loading, setLoading] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [isOpen, setIsOpen] = useState(false);
  const [updateType, setUpdateType] = useState('');

  // revetDialog
  const [open, setOpen] = useState(false);

  const addOptions = [
    'CASH_DEPOSIT_BALANCE',
    'PAYCHAT_DEPOSIT_BALANCE',
    'MBOK_DEPOSIT_BALANCE',
    'GIFT_BALANCE',
    'INVALID_FEES_COMPENSATION_BALANCE',
    'TRIP_COMPENSATION_BALANCE',
  ];
  const deductOptions = [
    'DEDUCTION',
    'FINE',
    'CASH_WITHDRAWAL',
    'DELIVERY_FEES',
    'DELIVERY_ORDER_AMOUNT',
  ];

  const columns = [
    { displayName: t('REFERENCE_TYPE'), propName: 'reference_type' },
    { displayName: t('AMOUNT'), propName: 'amount' },
    { displayName: t('NARRATION'), propName: 'narration' },
    { displayName: t('BALANCE_CREATED_AT'), propName: 'created_at' },
  ];

  const handleAddBalance = async (options) => {
    try {
      const { driverId } = props;
      await dispatch(addDriverBalance({ id: driverId, ...options }));
      setIsRefreshing(isRefreshing + 1);
      setLoadingCount(0);
      toast.success(t('msg_add'));
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    }

    // setDialogValue({ ...isOpen, addBalanceDialog: false });
  };

  const handleDeductBalance = async (options) => {
    try {
      const { driverId } = props;
      await dispatch(deductDriverBalance({ id: driverId, ...options }));
      setIsRefreshing(isRefreshing + 1);
      setLoadingCount(0);
      toast.success(t('msg_deduct'));
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    }

    // setDialogValue({ ...dialogs, deductBalanceDialog: false });
  };

  // api call
  const fetchEverything = useCallback(async () => {
    try {
      setLoading(true);
      const { driverId } = props;
      return await Promise.all([
        dispatch(getDriverBalance(driverId)),
        dispatch(
          getDriverTransactions({ id: driverId, pageSize: rowsPerPage, page })
        ),
      ]);
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    } finally {
      dispatch(setLoadingState(false));
      setLoading(false);
      setLoadingCount(1);
    }
  });

  useEffect(() => {
    fetchEverything();
  }, [dispatch, page, isRefreshing]);

  if (loading && loadingCount === 0) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-screen place-content-center'>
          <div class='flex items-center gap-2 text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            loading...
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <Grid
      container
      lg={12}
      md={12}
      xs={12}
      justifyContent='center'
      dir={appDirection}
    >
      {/* header  */}
      <Grid item lg={6} md={6} xs={6} className='pb-4'>
        <Card className='w-full flex justify-center'>
          <CardContent className=''>
            <span className=' font-sans text-lg font-semibold p-2 text-gray-500'>
              {t('total_balance')}:
            </span>
            <span
              className={
                balance > 0
                  ? 'font-black text-lg text-green-700 p-2 mr-4  rounded-xl'
                  : 'font-black text-lg text-red-700 p-2 mr-4  rounded-xl'
              }
            >
              {balance} {t('SDG')}
            </span>

            {/* btn's */}

            <AddCircleIcon
              color='primary'
              onClick={() => {
                setUpdateType('add');
                setIsOpen(true);
              }}
              className='cursor-pointer'
            />
            <RemoveCircleIcon
              color='error'
              onClick={() => {
                setUpdateType('deduct');
                setIsOpen(true);
              }}
              className='cursor-pointer'
            />

            <UpdateBalanceForm
              open={isOpen}
              setOpen={setIsOpen}
              updateBalnce={
                updateType === 'add' ? handleAddBalance : handleDeductBalance
              }
              title={
                updateType === 'add' ? t('add_balance') : t('deduct_balance')
              }
              options={updateType === 'add' ? addOptions : deductOptions}
            />
            {/* <UpdateBalanceForm
							open={isOpen}
							updateFunc={handleDeductBalance}
							title={"DEDUCT_BALANCE"}
						/> */}
          </CardContent>
        </Card>
      </Grid>
      {/* body  */}
      <Grid container lg={12} md={12} xs={10}>
        <Grid item lg={12} md={12} xs={12}></Grid>
        <Grid item lg={12} md={12} xs={9}>
          <BalanceTable
            data={transactions}
            setPage={setPage}
            isRefreshing={isRefreshing}
            loading={loading}
            page={page}
            setLoading={setLoading}
            setIsRefreshing={setIsRefreshing}
            open={open}
            setOpen={setOpen}
            // handleRevert={handleRevert}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default connect(null, {
  getDriverBalance,
  getDriverTransactions,
  addDriverBalance,
  deductDriverBalance,
  setError,
  setLoadingState,
})(DriverBalance);
