import { Grid } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useDispatchTranslation } from '../../modules/dispatches/i18n';
import { Empty } from '../../modules/drivers/components/common/Empty';
import LoadingIndicator from '../common/LoadingIndicator';
import { DataGrid } from '@mui/x-data-grid';
import { formatDate } from '../../modules/drivers/utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDispatchTransaction } from '../../Redux/store/dispatches/dispatchesListSlice';
import { toast } from 'react-toastify';

export default function GlobalTransactionsTableTap({ dispatch_id }) {
  const [isLoading, setIsLoading] = React.useState(false);

  const { dispatchTransactions: data } = useSelector(
    (state) => state.dispatches
  ).dispatchesList;

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(fetchDispatchTransaction({ dispatchId: dispatch_id }));
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  const t = useDispatchTranslation();

  const handleCreatedById = useCallback((id) => {
    switch (id) {
      case '0':
        return t('driver');

      case '1':
        return t('client');

      case '2':
        return t('supervisor');

      case '3':
        return t('admin');

      case '4':
        return t('system');

      default:
        return '.....';
    }
  }, []);

  const cols = [
    {
      field: 'status',
      headerName: t('status'),
      headerClassName: ' bg-gray-100 font-bold ',

      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid container lg={12} md={12} xs={12} className='text-xs p-1'>
          {' '}
          {params.value.status.status_name_en}
        </Grid>
      ),
    },
    {
      field: 'created_at',
      headerName: t('created_at'),
      headerClassName: ' bg-gray-100 font-bold ',

      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid container lg={12} md={12} xs={12} className='text-xs p-1'>
          {' '}
          {formatDate(params.value.created_at)}
        </Grid>
      ),
    },
    {
      field: 'created_by',
      headerName: t('created_by'),
      headerClassName: 'bg-gray-100 ',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid container lg={12} md={12} xs={12} className='text-xs p-1'>
          {handleCreatedById(params.value.created_by_role_id)}
        </Grid>
      ),
    },
  ];
  const rowsData = data?.map((row) => {
    return {
      id: row.id,
      status: row,
      created_at: row,
      created_by: row,
    };
  });

  if (isLoading) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-[80vh] place-content-center'>
          <div class='flex items-center gap-2 text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            loading...
          </div>
        </div>
      </Grid>
    );
  }
  return (
    <Grid
      container
      lg={12}
      md={12}
      justifyContent='center'
      spacing={2}
      className='h-full'
    >
      <Grid item lg={12} md={12} className='flex justify-center'>
        <h1 className='text-xl text-gray-600'>{t('dispatch_transactins')}</h1>
      </Grid>

      <Grid item lg={12} md={12}>
        <div className=' flex-grow '>
          <DataGrid
            className='bg-white '
            disableColumnFilter
            autoHeight
            density='compact'
            rows={rowsData}
            columns={cols}
            pageSize={1}
            rowHeight={60}
            rowCount={100}
            paginationMode='server'
            hideFooterPagination={true}
            CellStyle='{StaticResource DataGridBorder}'
            components={{
              NoRowsOverlay: Empty,
              NoResultsOverlay: Empty,
              LoadingOverlay: LoadingIndicator,
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
}
