import { createSlice } from '@reduxjs/toolkit';
import { usersApi } from './api';

const initialState = {
  users: [],
  user: {},
  ordersStatis: [],
  listPayload: {},
  transactions: [],
  OTP: null,
  balance: 0,
};

const usersListSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUsersSuccess: (state, action) => {
      state.users = action.payload;
    },
    fetchUserSuccess: (state, action) => {
      state.user = action.payload;
    },
    fetchOrdersStatisSuccess: (state, action) => {
      state.ordersStatis = action.payload;
    },

    getNewOtpSuccess: (state, action) => {
      state.OTP = action.payload;
    },

    setListPayloadSuccess: (state, actions) => {
      state.listPayload = actions.payload;
    },
    fetchUserTransactionsSuccess: (state, action) => {
      state.transactions = action.payload;
    },
    fetchUserBalanceSuccess: (state, action) => {
      state.balance = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  fetchUsersSuccess,
  fetchUserSuccess,
  fetchOrdersStatisSuccess,
  fetchUserTransactionsSuccess,
  fetchUserBalanceSuccess,
  getNewOtpSuccess,
  setError,
  setListPayloadSuccess,
} = usersListSlice.actions;

export const fetchUsers = (payload) => async (dispatch) => {
  try {
    const users = await usersApi.fetchUsers(payload);
    dispatch(fetchUsersSuccess(users));
  } catch (err) {
    throw err;
  }
};

export const fetchUserById = (payload) => async (dispatch) => {
  try {
    const users = await usersApi.fetchUser(payload);
    dispatch(fetchUserSuccess(users));
  } catch (err) {
    throw err;
  }
};

export const fetchOrdersStatis = (payload) => async (dispatch) => {
  try {
    const statistics = await usersApi.fetchOrdersStatistics(payload);
    dispatch(fetchOrdersStatisSuccess(statistics));
  } catch (err) {
    throw err;
  }
};

export const setListPayload = (payload) => async (dispatch) => {
  dispatch(setListPayloadSuccess(payload));
};

export const fetchUserBalance = (payload) => async (dispatch) => {
  try {
    const balance = await usersApi.fetchUserBalance(payload);
    dispatch(fetchUserBalanceSuccess(balance));
  } catch (err) {
    throw err;
  }
};
export const fetchUserTransactions = (payload) => async (dispatch) => {
  try {
    const transactions = await usersApi.fetchUserTransactions(payload);
    dispatch(fetchUserTransactionsSuccess(transactions));
  } catch (err) {
    throw err;
  }
};

export const creditUserBalance = (payload) => async (dispatch) => {
  try {
    await usersApi.creditBalance(payload);
  } catch (err) {
    throw err;
  }
};

export const debitUserBalance = (payload) => async (dispatch) => {
  try {
    await usersApi.debitBalance(payload);
  } catch (err) {
    throw err;
  }
};

export const newOtp = (payload) => async (dispatch) => {
  try {
    const Otp = await usersApi.getNewOtp(payload);
    dispatch(getNewOtpSuccess(Otp));
  } catch (err) {
    throw err;
  }
};

export default usersListSlice.reducer;
