import { Grid } from '@mui/material';
import React, { useState } from 'react';
import PageHeading from '../../../components/common/PageHeading';
import RepliesList from '../components/common/RepliesList';

import { usePlacesTranslation } from '../i18n';

function RepliesPage(props) {
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(0);

  const t = usePlacesTranslation();

  return (
    <Grid constainer lg={12} md={12}>
      <PageHeading
        heading1={t('place_details')}
        heading2={t('replies')}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
        backBtn
      />
      <RepliesList
        isLoading={isLoading}
        page={page}
        setPage={setPage}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
        setIsLoading={setIsLoading}
      />
    </Grid>
  );
}

export default RepliesPage;
