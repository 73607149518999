import React, { useCallback, useEffect } from 'react';

import {
  DialogTitle,
  Dialog,
  Button,
  DialogContent,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { updateTicket } from '../../../../Redux/store/tickets/ticketsListSlice';
import { useFormik } from 'formik';
import { useTicketTranslation } from '../../i18n';
import styled from '@emotion/styled';
import { appDirection } from '../../../../shared_utils';
import { toast } from 'react-toastify';

const UpdateTicketDialog = (props) => {
  const { setIsOpen, isOpen, ticket, isRefreshing, setIsRefreshing } = props;
  const dispatch = useDispatch();
  const { categoriesList, ticketDetails } = useSelector(
    (state) => state.tickets
  ).ticketsList;

  const [data, setData] = React.useState(ticket);
  const [parentId, setParentId] = React.useState(data.parent_category_id);
  const handleUpdatingTicket = useCallback(async (payload) => {
    try {
      await dispatch(
        updateTicket({
          payload: payload,
          ticketId: data.ticket_id,
        })
      );

      toast.success(t(`updated_successfully`));
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }

      toast.error(err.data.message);
    } finally {
      setIsRefreshing(isRefreshing + 1);
    }
  });
  const t = useTicketTranslation();
  const init = {
    parent_category_id: data.parent_category_id,
    child_category_id: data.child_category_id,
    description: data.description,
    note: data.note,
    priority: data.priority,
  };

  const formik = useFormik({
    initialValues: init,
    onSubmit: (values, { resetForm }) => {
      handleUpdatingTicket(values);
      resetForm();
      setIsOpen(false);
    },
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={isOpen}
      fullWidth
      dir={appDirection}
    >
      <DialogTitle id='simple-dialog-title'>{t('update_ticket')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container lg={12} md={12} spacing={2} className='pt-4'>
            <Grid item lg={12} md={12}>
              <TextField
                required
                fullWidth
                select
                id='parent_category_id'
                name='parent_category_id'
                label={t('parent_category')}
                onChange={(e) => {
                  setParentId(e.target.value);
                  formik.handleChange(e);
                }}
                value={formik.values.parent_category_id}
                variant='outlined'
              >
                {categoriesList.map(
                  (category) =>
                    category.parent_category_id === null && (
                      <MenuItem
                        value={category.category_id}
                        key={category.category_id}
                        className='px-4'
                      >
                        {category.category_name}
                      </MenuItem>
                    )
                )}
              </TextField>
            </Grid>
            {parentId && (
              <Grid item lg={12} md={12}>
                <TextField
                  required
                  fullWidth
                  select
                  id='child_category_id'
                  name='child_category_id'
                  label={t('child_category')}
                  onChange={formik.handleChange}
                  value={formik.values.child_category_id}
                  variant='outlined'
                >
                  {categoriesList.map(
                    (category) =>
                      category.parent_category_id === parentId && (
                        <MenuItem
                          value={category.category_id}
                          key={category.category_id}
                          className='px-4'
                        >
                          {category.category_name}
                        </MenuItem>
                      )
                  )}
                </TextField>
              </Grid>
            )}

            <Grid item lg={12} md={12}>
              <TextField
                required
                fullWidth
                select
                id='priority'
                name='priority'
                label={t('priority')}
                onChange={formik.handleChange}
                value={formik.values.priority}
                variant='outlined'
              >
                <MenuItem value={1} className='px-4'>
                  {t('low')}
                </MenuItem>
                <MenuItem value={2} className='px-4'>
                  {t('medium')}
                </MenuItem>
                <MenuItem value={3} className='px-4'>
                  {t('high')}
                </MenuItem>
                <MenuItem value={4} className='px-4'>
                  {t('critical')}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item lg={12} md={12}>
              <TextField
                required
                fullWidth
                id='description'
                name='description'
                label={t('description')}
                onChange={formik.handleChange}
                value={formik.values.description}
                variant='outlined'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item lg={12} md={12}>
              <TextField
                fullWidth
                id='note'
                name='note'
                label={t('note')}
                onChange={formik.handleChange}
                value={formik.values.note}
                variant='outlined'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid
              container
              lg={12}
              md={12}
              className='flex p-2 justify-center space-x-2  '
              spacing={1}
            >
              <Grid item>
                <Button variant='outlined' color='primary' type='submit'>
                  {t('edit')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='outlined'
                  color='inherit'
                  onClick={handleClose}
                >
                  {t('cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateTicketDialog;
