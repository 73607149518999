export const validateMobile = (mobileNumber) => {
  let mobile = mobileNumber;
  if (mobile[0] === '0') {
    mobile = mobile.substring(1);
  }
  return `+249${mobile}`;
};

export const validateMobileForQueryParams = (mobileNumber) => {
  let mobile = mobileNumber;
  if (mobile[0] === '0') {
    mobile = mobile.substring(1);
  }
  return `%2B249${mobile}`;
};

export const sleep = (time) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time * 1000);
  });
};

export const statisData = [
  {
    status_id: 1,
    status_name_ar: 'قيد الإنتظار',
    status_name_en: 'Pending',
  },
  {
    status_id: 2,
    status_name_ar: 'مقبول',
    status_name_en: 'Acceped',
  },
  {
    status_id: 3,
    status_name_ar: 'إنتهى وقته',
    status_name_en: 'Timed out',
  },

  {
    status_id: 5,
    status_name_ar: 'وصول السائق',
    status_name_en: 'Arrived',
  },
  {
    status_id: 6,
    status_name_ar: 'تم حمل الراكب',
    status_name_en: 'Picked',
  },
  {
    status_id: 7,
    status_name_ar: 'منتهي',
    status_name_en: 'Completed',
  },
  {
    status_id: 8,
    status_name_ar: 'جاري البحث عن راكب',
    status_name_en: 'Matching Clients',
  },
  {
    status_id: 9,
    status_name_ar: 'لم يتم العثور على راكب',
    status_name_en: 'No matches found',
  },
  {
    status_id: 13,
    status_name_ar: 'لم يظهر العميل',
    status_name_en: 'not show',
  },
  {
    status_id: 14,
    status_name_ar: 'ملغي بواسطة المشرف',
    status_name_en: 'Canceled by Support',
  },
  {
    status_id: 15,
    status_name_ar: 'ملغي بواسطة السائق',
    status_name_en: 'Canceled by Driver',
  },
  {
    status_id: 16,
    status_name_ar: ' ملغي بواسطة المستخدم قبل القبول',
    status_name_en: 'Canceled by User before accept',
  },
  {
    status_id: 17,
    status_name_ar: 'ملغي بواسطة النظام',
    status_name_en: 'Canceled by System',
  },
  {
    status_id: 18,
    status_name_ar: ' ملغي بواسطة المستخدم بعد القبول',
    status_name_en: 'Canceled by user after accept',
  },
];
