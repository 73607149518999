import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { Avatar, Chip, fabClasses, Grid } from '@mui/material';
import DoneIcon from '@material-ui/icons/Done';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import {
  fetchCarTypes,
  fetchFeesGroups,
  setError,
} from '../../../../Redux/store/drivers/driversListSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    margin: '0px 0px 10px 0px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function ListFiler() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { carTypes } = useSelector((state) => state.drivers).driversList;
  const { feesGroups } = useSelector((state) => state.drivers).driversList;

  useEffect(() => {
    async function fetchEverything() {
      // setIsLoading(true);
      try {
        // const  id = id;
        // console.log(id)
        // const mode = 'view';

        return await Promise.all([
          dispatch(fetchCarTypes()),
          dispatch(fetchFeesGroups()),
        ]);
      } catch (error) {
        setError(error);
        return error;
      } finally {
        // setIsLoading(false);
      }
    }

    fetchEverything();
  }, [dispatch]);

  // searchParams
  // const [search, setSearch] = useSearchParams({
  // 	blocked: false,
  // 	payment_block: false,
  // 	blocked_until: false,
  // 	car_type_ids:'All',
  // 	fees_group_id:'all',
  // 	mobile: "",
  // });

  //formik

  const init = {
    // mobile: search.get("mobile"),
    cartype: 0,
  };

  const formik = useFormik({
    initialValues: init,
  });

  return (
    <Grid
      container
      lg={12}
      md={12}
      justifyContent
      className='p-1 bg-gray-200 rounded-t-xl'>
      <Grid
        item
        lg={1}
        md={1}
        className='text-g text-gray-700 text-center font-bold pt-4'>
        Filters:
      </Grid>
      <Grid item lg={10} md={10} className='text-xs flex justify-center'>
        <div className='my-2 flex sm:flex-row flex-col'>
          <div className='flex flex-row mb-1 sm:mb-0 shadow-lg'>
            {/* carType menu  */}
            <div className='relative'>
              <select
                className=' h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                // onChange={handleType}
                // value={search.get("car_type")}
              >
                {/* TODO: config it to become multi select */}
                <option value='1' disabled selected>
                  Cartype
                </option>
                <option value={null}>All</option>
                {carTypes.map((cartype) => (
                  <option value={cartype.id} key={cartype.id}>
                    {cartype.car_name_en}
                  </option>
                ))}
              </select>
              <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                <svg
                  className='fill-current h-4 w-4'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'>
                  <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                </svg>
              </div>
            </div>

            {/* status menu  */}
            <div className='relative'>
              <select className=' h-full rounded-r border-t sm:rounded-r-none sm:border-r-0 border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500'>
                <option selected disabled>
                  Status
                </option>
                <option>All</option>
                <option>Active</option>
                <option>Inactive</option>
              </select>
              <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                <svg
                  className='fill-current h-4 w-4'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'>
                  <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                </svg>
              </div>
            </div>
          </div>

          {/* search field */}
          <div className='block relative'>
            <Paper
              component='form'
              //   onSubmit={(e) => {
              //     e.preventDefault();
              //     handleSubmit(formik.values);
              //   }}
              className='appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block  w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none'>
              <InputBase
                className={classes.input}
                id='mobile'
                name='mobile'
                // type='tel'
                placeholder='Driver Mobile...'
                inputProps={{ 'aria-label': 'search google maps' }}
                // value={formik.values.mobile}
                // onChange={formik.handleChange}
              />
              <IconButton
                type='submit'
                className={classes.iconButton}
                aria-label='search'>
                <SearchIcon />
              </IconButton>
              {/* </form> */}
            </Paper>
          </div>
          {/* <div className='relative pl-4 pt-1'>
            <Button color='primary' variant='contained' size='small'>
              apply
            </Button>
          </div> */}
        </div>
      </Grid>
    </Grid>
  );
}
