import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Chart from './common/Chart';

const DayChart = () => {
  const hourlyRateData = useSelector((state) => state.acceptanceRates.hourlyRateData);
  useEffect(() => console.log(hourlyRateData), [hourlyRateData]);

  return <Chart data={hourlyRateData} type={'hourly'} />;
};

export default DayChart;
