import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import api from '../../../services/API/events-providers';

const initialState = {
  isLoading: false,
  events: [],
  error: null,
  provider: {},
};

export const fetchAllEventsProviders = createAsyncThunk(
  'eventsProviders/fetchAllEventsProviders',
  async (payload) =>
    await api.getAllEventsProviders(
      payload.page_number,
      payload.page_size,
      payload.is_deleted
    )
);

export const createEventProviders = createAsyncThunk(
  'eventsProviders/createEventProviders',
  async (payload) => await api.createEventProvider(payload)
);

export const getEventProviderById = createAsyncThunk(
  'eventsProviders/getEventProviderById',
  async (payload) => await api.getProviderById(payload)
);

export const updateEventProvider = createAsyncThunk(
  'eventsProviders/updateEventProvider',
  async (payload) =>
    await api.updateEventProvider(payload.provider_id, payload.formData)
);

export const deleteEventProvider = createAsyncThunk(
  'eventsProviders/deleteEventProvider',
  async (id) => await api.deleteEventProvider(id)
);

const eventsProviders = createSlice({
  name: 'eventsProviders',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // fetch
    builder.addCase(fetchAllEventsProviders.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.events = [];
    });
    builder.addCase(fetchAllEventsProviders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.events = action.payload;
      state.error = null;
    });
    builder.addCase(fetchAllEventsProviders.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.events = [];
    });
    // create
    builder.addCase(createEventProviders.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createEventProviders.fulfilled, (state) => {
      state.isLoading = false;
      state.error = null;
      toast.success(t('Created successfully'));
    });
    builder.addCase(createEventProviders.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    // get
    builder.addCase(getEventProviderById.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.provider = {};
    });
    builder.addCase(getEventProviderById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.provider = action.payload;
      state.error = null;
    });
    builder.addCase(getEventProviderById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.provider = {};
    });
    // update
    builder.addCase(updateEventProvider.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateEventProvider.fulfilled, (state) => {
      state.isLoading = false;
      state.error = null;
      toast.success(t('Updated successfully'));
    });
    builder.addCase(updateEventProvider.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    // delete
    builder.addCase(deleteEventProvider.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteEventProvider.fulfilled, (state) => {
      state.isLoading = false;
      state.error = null;
      toast.success(t('Delete successfully'));
    });
    builder.addCase(deleteEventProvider.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const { setPage, clearErrors } = eventsProviders.actions;

export default eventsProviders.reducer;
