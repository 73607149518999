import React from 'react';
import { Provider } from 'react-redux';
import eventsStore from '../../../core/app/store';
import ChooseProvider from '../components/ChooseProvider';

export default function ChooseProviderPage() {
  return (
    <Provider store={eventsStore}>
      <ChooseProvider />
    </Provider>
  );
}
