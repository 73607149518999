import React from 'react';
import { Provider } from 'react-redux';
import identityStore from '../../core/app/store';
import ListContainer from '../components/ListContainer';

export default function UsersListPage() {
  return (
    <Provider store={identityStore}>
      <ListContainer />
    </Provider>
  );
}
