import React, { useCallback, useEffect } from 'react';
import { fetchOrders } from '../../../../Redux/store/orders/ordersListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import OrdersList from '../../../orders/components/OrdersList';

import { useParams } from 'react-router-dom';
import OrderIdFilter from '../common/OrderIdFilter';
import { toast } from 'react-toastify';

const OrdersListPage = () => {
  const { orders } = useSelector((state) => state.orders).ordersList;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadingCount, setLoadingCount] = React.useState(0);
  const [isRefreshing, setIsRefreshing] = React.useState(0);
  const [pageNum, setPageNum] = React.useState(0);
  const { id } = useParams();
  const [payload, setPayload] = React.useState({
    order_id: '',
  });

  const fetchOrdersData = useCallback(async () => {
    try {
      setIsLoading(true);

      await dispatch(
        fetchOrders({
          page_number: pageNum,
          page_size: 100,
          user_id: id,
          ...payload,
        })
      );
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
      setLoadingCount(loadingCount + 1);
    }
  });

  useEffect(() => {
    fetchOrdersData();
  }, [isRefreshing, pageNum, payload]);

  if (isLoading && loadingCount === 0) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-[70vh] place-content-center'>
          <div class='flex items-center gap-2 text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            loading...
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <Grid
      container
      lg={12}
      md={12}
      className='pt-4'
      spacing={2}
      justifyContent='center'
    >
      <Grid item lg={12} md={12}>
        <OrderIdFilter
          payload={payload}
          setPayload={setPayload}
          setIsRefreshing={setIsRefreshing}
          isRefreshing={isRefreshing}
        />
      </Grid>
      <Grid item lg={12} md={12}>
        <OrdersList
          page={pageNum}
          data={orders}
          isLoading={isLoading}
          setPage={setPageNum}
          setIsRefreshing={setIsRefreshing}
          isRefreshing={isRefreshing}
        />
      </Grid>
    </Grid>
  );
};

export default OrdersListPage;
