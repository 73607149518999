import http from '../http';

const getTicketDetails = async (ticket_id) => {
  const baseURL = process.env.REACT_APP_TICKETS_API;
  try {
    const ticket = await http.get(`${baseURL}/tickets/${ticket_id}`);
    return ticket.data;
  } catch (err) {
    throw err.response;
  }
};

export default getTicketDetails;
