import React from 'react';
import {
  DataGrid,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';

import {
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Toolbar,
} from '@mui/material';

import { Empty } from '../../drivers/components/common/Empty';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import LoadingIndicator from '../../../components/common/LoadingIndicator';

import { formatDate } from '../../drivers/utils';

import {
  AttachMoney,
  CalendarToday,
  Cancel,
  Delete,
  EventAvailable,
  Refresh,
  Room,
  Visibility,
} from '@mui/icons-material';
import { GridCloseIcon } from '@mui/x-data-grid-pro';
import Map from '../../tracking/components/common/places_List/Map';
import { makeStyles } from '@mui/styles';
import { usePlacesTranslation } from '../i18n';
import CustomPagination from '../../../components/new-components/CustomPagination';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

function CustomToolbar() {
  return <></>;
}

//mapdialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const MapDialog = (props) => {
  const classes = useStyles();
  const { onClose, selectedValue, open, lang, lat } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const mapProps = {
    lang: lang,
    lat: lat,
  };

  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <appBar className='relative bg-blue-900 text-white'>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <GridCloseIcon />
          </IconButton>
        </Toolbar>
      </appBar>
      <Map {...mapProps} />
    </Dialog>
  );
};

const GroupsList = ({ data, setPage, isLoading, page }) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  //mapdialog
  const [open, setOpen] = React.useState(false);
  const handleClose = (value) => {
    setOpen(false);
  };
  const [mapProps, setMapProps] = React.useState({});
  const handleClickOpen = (latitude, longitude) => {
    setOpen(true);
    setMapProps({
      lang: longitude,
      lat: latitude,
    });
  };

  const t = usePlacesTranslation();

  const cols = [
    {
      field: 'name_en',
      headerName: t('name_en'),
      headerClassName: ' bg-gray-100',
      cellClassName: 'text-xs',
      flex: 2,
      disableColumnMenu: true,
    },
    {
      field: 'name_ar',
      headerName: t('name_ar'),
      headerClassName: ' bg-gray-100',
      cellClassName: 'text-xs',
      flex: 2,
      disableColumnMenu: true,
    },
    {
      field: 'status',
      headerName: t('status'),
      headerClassName: ' bg-gray-100 text-center w-full ',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className=' text-xs font-serif font-semibold pl-2'
        >
          <Grid item lg={12} md={12} xs={12} className=''>
            {params.value.is_deleted ? (
              <Grid className='text-red-600'>Deleted</Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'Dates',
      headerName: t('dates'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid container lg={12} md={12} xs={12} className='text-xs'>
          <Grid item lg={12} md={12} xs={12} className='pb-2'>
            <CalendarToday fontSize='small' />
            <span className='text-gray-600 leading-4 pl-2'>
              {formatDate(params.value.created_at)}
            </span>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <EventAvailable fontSize='small' />
            <span className='text-gray-600 leading-4 pl-2'>
              {formatDate(params.value.updated_at)}
            </span>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'actions',
      headerName: t('actions'),
      headerClassName: ' bg-gray-100',
      flex: 0.5,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid container lg={12} md={12} justifyContent='center'>
          <ButtonGroup
            color='primary'
            aria-label='outlined primary button group'
          >
            {/* details  */}
            <Visibility
              className=' hover:bg-blue-200 hover:cursor-pointer'
              onClick={() => {
                navigate(`/places/groups/${params.value.group_id}`);
              }}
            />
          </ButtonGroup>
        </Grid>
      ),
    },
  ];
  const rowsData = data.map((data) => {
    return {
      id: data.group_id,
      name_en: data.name_en,
      name_ar: data.name_ar,
      status: data,
      Dates: data,
      actions: data,
    };
  });
  return (
    <div className='flex h-auto'>
      <MapDialog open={open} onClose={handleClose} {...mapProps} />

      <div className=' flex-grow'>
        <DataGrid
          className='border-2'
          disableColumnFilter
          autoHeight
          rowHeight={60}
          loading={isLoading}
          rows={rowsData}
          page={page}
          columns={cols}
          pageSize={1}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => setPage(newPage)}
          CellStyle='{StaticResource DataGridBorder}'
          components={{
            Toolbar: CustomToolbar,
            Pagination: () => CustomPagination(isLoading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: LoadingIndicator,
          }}
        />
      </div>
    </div>
  );
};

export default GroupsList;
