import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import LoadingIndicator from '../../../../../components/common/LoadingIndicator';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import {
  clearErrors,
  getEventProviderById,
} from '../../../../core/features/events-providers/eventsProvidersSlice';
import { useParams } from 'react-router-dom';
import { Email, Phone } from '@mui/icons-material';

export default function ProviderDetails({ isRefreshing }) {
  const { isLoading, error, provider } = useSelector(
    (state) => state.eventsProvidersSlice
  );

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getEventProviderById(id));
  }, [dispatch, isRefreshing]);

  useEffect(() => {
    if (error) {
      toast.error(error.message, {
        onClose: dispatch(clearErrors()),
      });
    }
  }, [error, dispatch]);

  if (isLoading) return <LoadingIndicator />;

  if (!provider.provider_id) return <></>;

  return (
    <div className='w-full flex items-center justify-center'>
      <div className='bg-gray-100 rounded-2xl p-7 my-3 flex flex-col w-[50%] items-center gap-3'>
        <img
          className='w-[200px] h-[200px] rounded-full'
          src={
            provider.provider_logo ||
            'https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI='
          }
        />
        <p className='font-bold text-2xl'>{provider.full_name}</p>
        <div className='flex items-center w-[70%] justify-between bg-white rounded-lg px-5 py-2'>
          <Email />
          <p>{provider.email}</p>
        </div>
        <div className='flex items-center w-[70%] justify-between bg-white rounded-lg px-5 py-2'>
          <Phone />
          <p dir='ltr'>{provider.mobile}</p>
        </div>
      </div>
    </div>
  );
}
