import { Grid } from '@mui/material';
import { useEffect, useLayoutEffect } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  fetchDispatchById,
  setError,
} from '../../../Redux/store/dispatches/dispatchesListSlice';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import DispatchTabs from '../components/DispatchTabs';
import PageHeading from '../../../components/common/PageHeading';
import {
  setAlert,
  getTripActualRoute,
} from '../../../Redux/store/app/appSlice';
import { useDriverTranslation } from '../../drivers/i18n';
import { useDispatchTranslation } from '../i18n';
import { appDirection } from '../../../shared_utils';
import { toast } from 'react-toastify';

const DispatchPage = () => {
  const { id } = useParams();
  const match = useMatch('/dispatches/:id');
  const [isRefreshing, setIsRefreshing] = useState(0);
  const navigate = useNavigate();
  const t = useDispatchTranslation();

  const reduxDispatch = useDispatch();

  const [isloading, setIsloading] = useState(false);

  const fetchDipatche = useCallback(async () => {
    try {
      setIsloading(true);
      await reduxDispatch(fetchDispatchById({ dispatchId: id }));
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    } finally {
      setIsloading(false);
    }
  }, []);

  useEffect(() => {
    fetchDipatche();
  }, [reduxDispatch, isRefreshing]);

  if (isloading) {
    return <LoadingIndicator />;
  }

  return (
    <Grid
      container
      lg={12}
      md={12}
      sm={12}
      spacing={2}
      className='h-full'
      dir={appDirection}
    >
      <Grid item lg={12} md={12} className='px-2  pl-2 text-white relative'>
        <PageHeading
          previousUrl={'dispatches'}
          heading1={t('dispatches')}
          heading2={t('dispatches_list')}
          heading3={t('dispatch_details')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} className='h-full'>
        <DispatchTabs
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      </Grid>
    </Grid>
  );
};

export default connect(null, { setError, fetchDispatchById })(DispatchPage);
