import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../services/API/supply-demand';

const initialState = {
  isLoading: false,
  from: '',
  to: '',
  supplyDemandData: [],
  error: null,
};

export const fetchData = createAsyncThunk('supplyDemand/fetchData', async (payload) => {
  await api.getSupplyDemandData(payload);
});

const supplyDemandSlice = createSlice({
  name: 'supplyDemand',
  initialState,
  reducers: {
    setDate: (state, action) => {
      state.from = action.payload.from;
      state.to = action.payload.to;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplyDemandData = action.payload;
    });
    builder.addCase(fetchData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const { setDate } = supplyDemandSlice.actions;
export default supplyDemandSlice.reducer;
