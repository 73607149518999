import { Grid } from '@material-ui/core';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../../core/features/supply-demand/supplyDemandSlice';
import Map from './dmand_supply_Heatmap/Map';
import Timeline from './Timeline';

const HeatMapContainer = () => {
  const [dateRange, setDateRange] = useState({
    from: moment('2022-10-08').startOf('day').add(2, 'hours').utc(), //!need to ask hassan about it
    to: moment('2022-10-08').endOf('day').add(2, 'hours').utc(),
  });
  const [selectedTime, setSelectedTime] = useState(dateRange.from);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData({ from: dateRange.from, to: dateRange.to }));
  }, []);

  return (
    <Grid container lg={12} md={12} className='relative' justifyContent='center'>
      <Map selectedTime={selectedTime} />
      <Timeline setSelectedTime={setSelectedTime} selectedTime={selectedTime} />
    </Grid>
  );
};

export default HeatMapContainer;
