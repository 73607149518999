import React, { useCallback } from 'react';
import {
  AccountCircle,
  CalendarToday,
  LocalPhone,
  MonetizationOn,
  SwapVert,
} from '@material-ui/icons';
import { formatDate } from '../../../modules/drivers/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPointsList,
  fetchOrdersSuccess,
} from '../../../Redux/store/shareTrip/shareTripListSlice';

function DropoffList({ trip, id }) {
  const dispatch = useDispatch();

  const { trips, pointsList } = useSelector((state) => {
    return state.shareTrip.shareTripList;
  });

  const handleDropoff = async (trip, id) => {
    await dispatch(
      setPointsList({
        id: id,
        action: 'dropoff',
        order_id: trip.order_id,
        full_name: trip.user.full_name,
        distance_km: 0,
        price: 0,
        estimated_duration: 0,
        lat: trip.drop_off_latitude,
        long: trip.drop_off_longitude,
      })
    );
    let newArr = trips.map((item, i) => {
      if (id === i) {
        return { ...item, drop_off_latitude: '', drop_off_longitude: '' };
      }
      return item;
    });
    await dispatch(fetchOrdersSuccess(newArr));
  };

  return (
    <button onClick={() => handleDropoff(trip, id)}>
      <div className='font-base text-left'>
        <div className='pb-1'>
          <AccountCircle
            fontSize='medium'
            style={{ color: '#3C99DC' }}
            className='pr-1'
          />
          {trip.user.full_name}
        </div>
        <div className='pb-1'>
          <LocalPhone
            fontSize='medium'
            style={{ color: '#3C99DC' }}
            className='pr-1'
          />
          {trip.user.mobile}
        </div>
        <div className='pb-1'>
          <SwapVert
            fontSize='medium'
            style={{ color: '#3C99DC' }}
            className='pr-1'
          />
          {trip.distance_km}
        </div>
        <div className='pb-1'>
          <MonetizationOn
            fontSize='medium'
            style={{ color: '#3C99DC' }}
            className='pr-1'
          />
          {trip.price}
        </div>
        <div className='pb-1'>
          <CalendarToday
            fontSize='medium'
            style={{ color: '#3C99DC' }}
            className='pr-1'
          />
          {formatDate(trip.created_at)}
        </div>
      </div>
    </button>
  );
}

export default DropoffList;
