import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingIndicator from '../../../../../../components/common/LoadingIndicator';
import api from '../../../../../services/API/eventTicketTypes';
import { useEventsTranslation } from '../../../../../services/i18n';

export default function DialogForm({ open, close, update, setIsRefreshing }) {
  const defaultData = {
    ticket_type_name_ar: '',
    ticket_type_name_en: '',
    ticket_price: null,
    tickets_qty: null,
  };

  const [data, setData] = useState(defaultData);

  useEffect(() => {
    setData(update || defaultData);
  }, [update]);

  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const t = useEventsTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (update) {
        await api.updateTicketType(data.id, data);
        toast.success(t('updated_successfully'));
      } else {
        await api.createTicketType({
          ...data,
          event_id: id,
        });
        toast.success(t('created_successfully'));
      }
      setIsRefreshing((prev) => prev + 1);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <LoadingIndicator />;

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>
        {update ? t('update_ticket_type') : t('create_new_ticket_type')}
      </DialogTitle>
      <form onSubmit={handleSubmit} className='p-5'>
        <div className='w-[500px] flex flex-col gap-2'>
          <TextField
            required
            label={t('ticket_type_name_en')}
            value={data.ticket_type_name_en}
            fullWidth
            onChange={(e) =>
              setData({ ...data, ticket_type_name_en: e.target.value })
            }
          />
          <TextField
            required
            label={t('ticket_type_name_ar')}
            value={data.ticket_type_name_ar}
            fullWidth
            onChange={(e) =>
              setData({ ...data, ticket_type_name_ar: e.target.value })
            }
          />
          <TextField
            label={t('ticket_price')}
            value={data.ticket_price}
            fullWidth
            type='number'
            onChange={(e) => setData({ ...data, ticket_price: e.target.value })}
          />
          <TextField
            label={t('tickets_qty')}
            value={data.tickets_qty}
            fullWidth
            type='number'
            onChange={(e) => setData({ ...data, tickets_qty: e.target.value })}
          />
        </div>
        <div className='flex justify-center gap-2 mt-3'>
          <Button variant='contained' color='primary' fullWidth type='submit'>
            {update ? t('update') : t('add')}
          </Button>
          <Button variant='contained' color='inherit' fullWidth onClick={close}>
            {t('cancel')}
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
