import React, { useLayoutEffect, useState } from 'react';
import { Breadcrumbs, Grid } from '@mui/material';
import {
  ArrowBack,
  East,
  NavigateBefore,
  NavigateNext,
  Refresh,
} from '@mui/icons-material';
import GET_CURRENT_LANG from '../../../../shared_utils';
import { useDispatch } from 'react-redux';
import CarsList from './common/CarsList';
import { fetchCarTypes } from '../../../core/features/cartypes-list/cartypesListSlce';
import { useCarsTranslation } from '../i18n';

const ListContainer = () => {
  const dispatch = useDispatch();
  const [isRefreshing, setIsRefreshing] = useState(0);
  const t = useCarsTranslation();

  useLayoutEffect(() => {
    dispatch(fetchCarTypes({ page_number: 0, page_size: 100 }));
  }, [isRefreshing]);

  const [type, setType] = useState('table');

  return (
    <Grid container lg={12} md={12} spacing={2} justifyContent='center'>
      <Grid item lg={12} md={12}>
        <Grid
          container
          lg={12}
          md={12}
          spacing={2}
          alignItems={'center'}
          className='pr-4 py-1'
        >
          <Grid item>
            {GET_CURRENT_LANG() === 'en' ? (
              <ArrowBack
                color='primary'
                fontSize='medium'
                className='  hover:text-blue-500 hover:cursor-pointer hover:animate-pulse mr-2'
                onClick={() => setType('table')}
              />
            ) : (
              <East
                color='primary'
                fontSize='medium'
                className='  hover:text-blue-500 hover:cursor-pointer hover:animate-pulse ml-2'
                onClick={() => setType('table')}
              />
            )}
            {setIsRefreshing && (
              <Refresh
                color='primary'
                fontSize='medium'
                className='  hover:text-blue-500 hover:cursor-pointer hover:animate-pulse '
                onClick={() => setIsRefreshing(isRefreshing + 1)}
              />
            )}
          </Grid>

          <Grid item>
            <Breadcrumbs
              separator={
                GET_CURRENT_LANG() === 'en' ? (
                  <NavigateNext fontSize='small' />
                ) : (
                  <NavigateBefore fontSize='small' />
                )
              }
              aria-label='breadcrumb'
            >
              <span className='text-xs'>{t('cars')}</span>

              <span className='text-xs hover:text-sm hover:text-blue-500 hover:cursor-pointer hover:animate-pulse'>
                {t('cars_list')}
              </span>

              {type == 'details' ? (
                <span className='text-xs font-bold text-blue-500'>
                  {t('car_details')}
                </span>
              ) : null}
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} md={12}>
        <CarsList
          type={type}
          setType={setType}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      </Grid>
    </Grid>
  );
};

export default ListContainer;
