import React, { useState } from 'react';
import {
  DataGrid,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';

import {
  Avatar,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Rating,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';

import { Empty } from '../../drivers/components/common/Empty';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { formatDate } from '../../drivers/utils';

import {
  AccountCircle,
  AttachMoney,
  CalendarToday,
  Cancel,
  Delete,
  EventAvailable,
  ListAlt,
  PhoneAndroid,
  Refresh,
  Room,
  Visibility,
} from '@mui/icons-material';
import { GridCloseIcon } from '@mui/x-data-grid-pro';
import Map from '../../tracking/components/common/places_List/Map';
import default_image from '../../../assets/images/place-moose.png';
// import { Rating } from '@material-ui/lab';
import DisplayDialog from './common/DisplayDialog';
import { makeStyles } from '@mui/styles';
import { usePlacesTranslation } from '../i18n';
import CustomPagination from '../../../components/new-components/CustomPagination';
import DeleteDialog from './common/DeleteDialog';
import DeleteModal from '../../../components/new-components/DeleteModal';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

function CustomToolbar() {
  return <></>;
}

//mapdialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const MapDialog = (props) => {
  const classes = useStyles();
  const { onClose, selectedValue, open, lang, lat } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const mapProps = {
    lang: lang,
    lat: lat,
  };

  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <appBar className='relative bg-blue-900 text-white'>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <GridCloseIcon />
          </IconButton>
        </Toolbar>
      </appBar>
      <Map {...mapProps} />
    </Dialog>
  );
};

const PlacesList = ({
  data,
  setPage,
  isLoading,
  setIsLoading,
  isRefreshing,
  setIsRefreshing,
  deleteAction,
  page,
}) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const t = usePlacesTranslation();

  const [deletePlace, setDeletePlace] = useState(false);

  //mapdialog
  const [open, setOpen] = React.useState(false);
  const handleClose = (value) => {
    setOpen(false);
  };
  const [mapProps, setMapProps] = React.useState({});
  const handleClickOpen = (latitude, longitude) => {
    setOpen(true);
    setMapProps({
      lang: longitude,
      lat: latitude,
    });
  };

  const cols = [
    {
      field: 'PlaceInfo',
      headerName: t('place_info'),
      headerClassName: ' bg-gray-100  text-center w-full',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className=' text-xs px-2'
        >
          <Grid item lg={2} md={2}>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className='flex justify-center  cursor-pointer'
            >
              <Avatar
                src={params.value ? params.value.place_logo : default_image}
                className={classes.small}
              />
            </Grid>
          </Grid>
          <Grid item lg={8} md={8}>
            <Grid item lg={12} md={12} xs={12}>
              {params.value.name || '...'}
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              {params.value.phone || '....'}
            </Grid>

            <Grid item lg={12} md={12} xs={12}>
              <Rating size='small' readOnly value={params.value.avg_rating} />
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'userInfo',
      headerName: t('user'),
      headerClassName: ' bg-gray-100  ',

      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className={
            params.value.user_id
              ? 'text-xs p-1 hover:bg-blue-200  hover:cursor-pointer'
              : 'text-xs p-1'
          }
          onClick={() => {
            if (params.value.user_id) {
              navigate(`/users/${params.value.user_id}`);
            }
          }}
        >
          <Grid item lg={8} md={8}>
            <Grid item lg={12} md={12} xs={12}>
              <AccountCircle fontSize='small' className='text-gray-500' />
              {params.value.user_id ? params.value.user.full_name : '....'}
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <PhoneAndroid fontSize='small' className='text-gray-500' />{' '}
              {params.value.user_id ? params.value.user.mobile : '....'}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'amenity',
      headerName: t('amenity'),
      headerClassName: ' bg-gray-100  text-center w-full',
      disableColumnMenu: true,
      flex: 0.8,
      renderCell: (params) => (
        <Grid container lg={12} md={12} xs={12} className=' text-base'>
          <Grid item lg={12} md={12} xs={12} className='pb-2  text-gray-600'>
            {params.value.amenity_id ? params.value.amenity.name : '.....'}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'Visibility',
      headerName: t('visibility'),
      headerClassName: ' bg-gray-100  text-center w-full ',
      disableColumnMenu: true,
      flex: 0.8,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className=' text-xs font-serif font-semibold pl-2'
        >
          <Grid item lg={12} md={12} xs={12} className=''>
            {params.value.hidden ? (
              <Grid className='text-red-600'>{t('hidden')}</Grid>
            ) : (
              <Grid className='text-green-600'>{t('visible')}</Grid>
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'status',
      headerName: t('status'),
      headerClassName: ' bg-gray-100  text-center w-full ',
      disableColumnMenu: true,
      flex: 0.8,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className=' text-xs font-serif font-semibold pl-2'
        >
          <Grid item lg={12} md={12} xs={12} className=''>
            {params.value.is_deleted ? (
              <Grid className='text-red-600'>{t('deleted')}</Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'Dates',
      headerName: t('dates'),
      headerClassName: ' bg-gray-100  text-center w-full',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid container lg={12} md={12} xs={12} className='text-xs'>
          <Grid item lg={12} md={12} xs={12} className='pb-2'>
            <CalendarToday fontSize='small' />
            <span className='text-gray-600 leading-4 px-2'>
              {formatDate(params.value.created_at)}
            </span>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <EventAvailable fontSize='small' />
            <span className='text-gray-600 leading-4 px-2'>
              {formatDate(params.value.updated_at)}
            </span>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'actions',
      headerName: t('actions'),
      headerClassName: ' bg-gray-100 ',
      flex: 0.8,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid container lg={12} md={12} justifyContent='center'>
          {/* <ButtonGroup
            color='primary'
            aria-label='outlined primary button group'
          > */}
          {/* details  */}
          <ListAlt
            color='primary'
            className=' hover:bg-blue-200 hover:cursor-pointer'
            onClick={() => {
              navigate(`/places/${params.value.place_id}`);
            }}
          />
          {/* map */}
          <Room
            className=' hover:bg-blue-200 hover:cursor-pointer'
            onClick={() =>
              handleClickOpen(params.value.latitude, params.value.longitude)
            }
          />
          {deleteAction && (
            <Delete
              color='error'
              className=' hover:bg-red-200 hover:cursor-pointer'
              onClick={() => setDeletePlace(params.value.place_id)}
            />
          )}

          <DisplayDialog
            id={params.value.place_id}
            hidden={params.value.hidden}
            setIsLoading={setIsLoading}
            isRefreshing={isRefreshing}
            setIsRefreshing={setIsRefreshing}
          />
          {/* </ButtonGroup> */}
        </Grid>
      ),
    },
  ];
  const rowsData = data.map((data, i) => {
    return {
      id: i,
      PlaceInfo: data,
      userInfo: data,
      amenity: data,
      status: data,
      Visibility: data,
      Dates: data,
      actions: data,
    };
  });
  return (
    <div className='flex h-auto'>
      <MapDialog open={open} onClose={handleClose} {...mapProps} />

      <DeleteModal
        openModal={deletePlace ? true : false}
        setOpenModal={setDeletePlace}
        message={t('delete_place_msg')}
        onConfirm={() => deleteAction(deletePlace)}
      />

      <div className=' flex-grow'>
        <DataGrid
          className='border-2'
          disableColumnFilter
          autoHeight
          rowHeight={70}
          loading={isLoading}
          page={Number(page)}
          rows={rowsData}
          columns={cols}
          pageSize={1}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => setPage(newPage)}
          CellStyle='{StaticResource DataGridBorder}'
          components={{
            Toolbar: CustomToolbar,
            Pagination: () => CustomPagination(isLoading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: LoadingIndicator,
          }}
        />
      </div>
    </div>
  );
};

export default PlacesList;
