import { createSlice } from '@reduxjs/toolkit';
import { pendingDriversApi } from './api';

const initialState = {
  drivers: [],
  files: [],
  driverImage: '',
  driver: {
    pending_driver_status: {
      id: null,
      status_name_ar: '',
      status_name_en: '',
      status_name: '',
    },
  },
  carTypes: [],
  listFilters: { mobile: '', carTypeId: null },
  isLoading: false,
};

const pendingDriversListSlice = createSlice({
  name: 'pending-drivers',
  initialState,
  reducers: {
    setLoadingState: (state, action) => {
      state.isLoading = action.payload;
    },
    fetchDriversSuccess: (state, action) => {
      state.drivers = action.payload;
    },
    fetchDriverSuccess: (state, action) => {
      state.driver = action.payload;
    },
    fetchDriverFilesSuccess: (state, action) => {
      state.files = action.payload;
    },
    fetchCarTypesSuccess: (state, action) => {
      state.carTypes = action.payload;
    },
    setFilters: (state, actions) => {
      state.listFilters = actions.payload;
    },
    setImage: (state, actions) => {
      state.driverImage = actions.payload;
    },
    setError: (state, actions) => {
      state.error = actions.payload;
    },
  },
});

export const {
  fetchDriversSuccess,
  fetchDriverSuccess,
  fetchCarTypesSuccess,
  fetchDriverFilesSuccess,
  setError,
  setImage,
  setFilters,
  setLoadingState,
} = pendingDriversListSlice.actions;

const getDriverImage = (driver) => {
  const file = driver.files.find((file) => file.file_type.id === 1);
  if (file) {
    return file.image_url;
  }
};

export const fetchDrivers = (options) => async (dispatch) => {
  // console.log(options);
  try {
    const drivers = await pendingDriversApi.fetchPendingDrivers(options);
    dispatch(fetchDriversSuccess(drivers));
  } catch (error) {
    dispatch(fetchDriversSuccess([]));
    dispatch(setError(error));
    throw error;
  }
};

export const fetchDriver = (id) => async (dispatch) => {
  try {
    const driver = await pendingDriversApi.findPendingDriver(id);
    const img = getDriverImage(driver);
    dispatch(fetchDriverSuccess(driver));
    dispatch(setImage(img));
    return driver;
  } catch (error) {
    dispatch(fetchDriverSuccess({}));
    dispatch(setError(error));
    throw error;
  }
};

export const fetchCarTypes = () => async (dispatch) => {
  try {
    const carTypes = await pendingDriversApi.fetchCarTypes();
    dispatch(fetchCarTypesSuccess(carTypes));
    return carTypes;
  } catch (error) {
    dispatch(setError(error));
    throw error;
  }
};

export const fetchDriverFiles = (id) => async (dispatch) => {
  try {
    const files = await pendingDriversApi.fetchPendingDriverFiles(id);
    dispatch(fetchDriverFilesSuccess(files));
    return files;
  } catch (error) {
    dispatch(setError(error));
    throw error;
  }
};

export const activatePendingDriver = (id) => async (dispatch) => {
  try {
    const resp = await pendingDriversApi.activatePendingDriver(id);
    return resp;
  } catch (error) {
    dispatch(setError(error));
    throw error;
  }
};

export const rejectPendingDriver = (options) => async (dispatch) => {
  try {
    const resp = await pendingDriversApi.rejectPendingDriver(options);
    return resp;
  } catch (error) {
    dispatch(setError(error));
    throw error;
  }
};

export const needUpdatePendingDriver = (options) => async (dispatch) => {
  try {
    const resp = await pendingDriversApi.needUpdatePendingDriver(options);
    return resp;
  } catch (error) {
    dispatch(setError(error));
    throw error;
  }
};

export const updateDriver = (options) => async (dispatch) => {
  try {
    const resp = await pendingDriversApi.updateDriver(options);
    return resp;
  } catch (error) {
    dispatch(setError(error));
    throw error;
  }
};

export const setListfilters = (options) => async (dispatch) => {
  await dispatch(setFilters(options));
};

export default pendingDriversListSlice.reducer;
