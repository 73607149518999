// This dictionary contains 401 untranslated or inherited localization strings.
// These strings are commented out. Uncomment and edit them if you want to add your translations.
import { editorLocalization } from "survey-creator-core";

export var ruStrings = {
  // survey templates
  survey: {
    edit: "Редактировать",
    externalHelpLink: "Научиться создавать опросы",
    externalHelpLinkUrl: "Https://www.youtube.com/channel/UCH2ru9okIcooNZfpIbyq4qQ?view_as=subscriber",
    dropQuestion: "Пожалуйста, перетяните необходимый элемент из панели слева",
    // addLogicItem: "Create a rule to customize the flow of the survey.",
    copy: "Копировать",
    // duplicate: "Duplicate",
    addToToolbox: "Добавить к инструментам",
    deletePanel: "Удалить панель",
    deleteQuestion: "Удалить вопрос",
    convertTo: "Преобразовать в",
    drag: "Перетянуть элемент"
  },
  // Question types
  qt: {
    default: "По умолчанию",
    checkbox: "Множественный выбор",
    comment: "Комментарий",
    imagepicker: "Выбор изображения",
    // ranking: "Ranking",
    // image: "Image",
    dropdown: "Выпадающий список",
    // tagbox: "Tag Box",
    file: "Загрузка файлов",
    // html: "HTML",
    matrix: "Матрица (единичный выбор)",
    matrixdropdown: "Матрица (множественный выбор)",
    matrixdynamic: "Матрица (динамические ряды)",
    multipletext: "Multiple Text",
    panel: "Панель (для группы вопросов)",
    paneldynamic: "Панель (динамические панели)",
    radiogroup: "Единичный выбор",
    rating: "Рейтинг (шкала)",
    text: "Текстовый ввод",
    boolean: "Булиевое значение",
    expression: "Выражение (только для чтения)",
    // signaturepad: "Signature Pad",
    // buttongroup: "Button Group"
  },
  // Strings in SurveyJS Creator
  ed: {
    defaultLocale: "По умолчанию ({0})",
    survey: "Опросник",
    settings: "Настройки опросника",
    // settingsTooltip: "Open settings",
    // surveySettings: "Survey Settings",
    // surveySettingsTooltip: "Open survey settings",
    // showPanel: "Show Panel",
    // hidePanel: "Hide Panel",
    // prevSelected: "Select previous",
    // nextSelected: "Select next",
    // surveyTypeName: "Survey",
    // pageTypeName: "Page",
    // panelTypeName: "Panel",
    // questionTypeName: "Question",
    // columnTypeName: "Column",
    addNewPage: "Добавить новую страницу",
    moveRight: "Прокрутить вправо",
    moveLeft: "Прокрутить влево",
    deletePage: "Удалить страницу",
    editPage: "Редактировать страницу",
    edit: "Редактировать",
    newPageName: "страница",
    newQuestionName: "вопрос",
    newPanelName: "панель",
    newTextItemName: "текст",
    testSurvey: "Тестировать опросник",
    // defaultV2Theme: "Default",
    // modernTheme: "Modern",
    // defaultTheme: "Default (legacy)",
    testSurveyAgain: "Тестировать еще раз",
    testSurveyWidth: "Ширина опросника",
    // navigateToMsg: "You had to navigate to",
    logic: "Логика опросника",
    embedSurvey: "Вставить опросник",
    translation: "Перевод",
    saveSurvey: "Сохранить опросник",
    // saveSurveyTooltip: "Save Survey",
    designer: "Обзор конструктора",
    jsonEditor: "JSON редактор",
    // jsonHideErrors: "Hide errors",
    // jsonShowErrors: "Show errors",
    undo: "Отменить",
    redo: "Повтор",
    // undoTooltip: "Undo last change",
    // redoTooltip: "Redo the change",
    // showMoreChoices: "Show more",
    // showLessChoices: "Show less",
    // copy: "Copy",
    // cut: "Cut",
    // paste: "Paste",
    // copyTooltip: "Copy selection to clipboard",
    // cutTooltip: "Cut selection to clipboard",
    // pasteTooltip: "Paste from clipboard",
    options: "Параметры",
    generateValidJSON: "Сформировать валидный JSON",
    generateReadableJSON: "Сформировать читаемый JSON",
    toolbox: "Панель инструментов",
    "property-grid": "Свойства",
    // propertyGridFilteredTextPlaceholder: "Type to search...",
    toolboxGeneralCategory: "Общие",
    // toolboxChoiceCategory: "Choice Questions",
    // toolboxTextCategory: "Text Input Questions",
    // toolboxContainersCategory: "Containers",
    // toolboxMatrixCategory: "Matrix Questions",
    // toolboxMiscCategory: "Misc",
    correctJSON: "Пожалуйста, исправьте JSON.",
    surveyResults: "Результаты обследования",
    surveyResultsTable: "Как таблица",
    surveyResultsJson: "Как JSON",
    resultsTitle: "Заголовок вопроса",
    resultsName: "Название вопроса",
    resultsValue: "Значение ответа",
    resultsDisplayValue: "Отображаемое значение",
    modified: "Модифицирован",
    saving: "Сохранение",
    saved: "Сохраненный",
    // propertyEditorError: "Error",
    saveError: "Ошибка! Содержание редактора не сохранено.",
    // translationPropertyGridTitle: "Language Settings",
    // translationLanguages: "Languages",
    translationAddLanguage: "Выберите дополнительный язык опросника",
    translationShowAllStrings: "Показывать все строки",
    // translationShowUsedStringsOnly: "Used Strings Only",
    translationShowAllPages: "Показывать все страницы",
    translationNoStrings: "Нет строк для перевода. Пожалуйста, измените фильтр.",
    translationExportToSCVButton: "Экспорт в CSV",
    translationImportFromSCVButton: "Импорт из CSV",
    translationMergeLocaleWithDefault: "Объединить {0} с локалью по умолчанию",
    translationPlaceHolder: "Перевод...",
    bold: "Жирный",
    italic: "Курсив",
    underline: "Подчеркивание",
    addNewQuestion: "Добавить вопрос",
    // selectPage: "Select page...",
    // htmlPlaceHolder: "HTML content will be here.",
    // panelPlaceHolder: "Drop a question from the toolbox here.",
    // surveyPlaceHolder: "The survey is empty. Drag an element from the toolbox or click the button below.",
    // addNewTypeQuestion: "Add {0}", //{0} is localizable question type
    // chooseLogoPlaceholder: "[LOGO]",
    // auto: "auto",
    lg: {
      addNewItem: "Добавить новое правило",
      // empty_tab: "Create a rule to customize the flow of the survey.",
      page_visibilityName: "Видимость страницы",
      // page_enableName: "Enable (disable) page",
      panel_visibilityName: "Видимость панели",
      panel_enableName: "Панель включена/выключена",
      question_visibilityName: "Видимость вопроса",
      question_enableName: "Вопрос включен/выключен",
      question_requireName: "Вопрос обязательный",
      // column_visibilityName: "Show (hide) column",
      // column_enableName: "Enable (disable) column",
      // column_requireName: "Make column required",
      trigger_completeName: "Опрос завершен",
      trigger_setvalueName: "Установить значение вопроса",
      trigger_copyvalueName: "Копировать значение вопроса",
      trigger_skipName: "Перейти к вопросу",
      trigger_runExpressionName: "Запустить пользовательское выражение",
      completedHtmlOnConditionName: "Пользовательский текст 'Страницы благодарности'",
      page_visibilityDescription: "Сделать страницу видимой, когда логическое выражение вернет 'true'. В противном случае сделать ее невидимой.",
      panel_visibilityDescription: "Сделать панель видимой, когда логическое выражение вернет 'true'. В противном случае сделать ее невидимой.",
      panel_enableDescription: "Сделать панель и все элементы внутри нее включенной, когда логическое выражение вернет 'true'. В противном случае оставить их отключенными.",
      question_visibilityDescription: "Сделать вопрос видимой, когда логическое выражение вернет 'true'. В противном случае сделать ее невидимой.",
      question_enableDescription: "Сделать вопрос включенным, когда логическое выражение вернет 'true'. В противном случае оставить его отключенными.",
      question_requireDescription: "Вопрос становится обязательным, когда логическое выражение вернет 'true'.",
      trigger_completeDescription: "Когда логическое выражение вернет 'true', то опрос становится завершенным и конечный пользователь видит 'Страницу благодарности'.",
      trigger_setvalueDescription: "Когда значения вопросов, которые используются в логическом выражении, изменяются и логическое выражение возвращает значение 'true', тогда на выбранный вопрос устанавливается значение.",
      trigger_copyvalueDescription: "Когда значения вопросов, которые используются в логическом выражении, изменяются и логическое выражение возвращает значение 'true', тогда значение одного выбранного вопроса копируется в другой выбранный вопрос.",
      trigger_skipDescription: "Когда логическое выражение вернет 'true', тогда опрос переходит к/фокусируется на выбранном вопросе.",
      trigger_runExpressionDescription: "Когда логическое выражение возвращает 'true',тогда выполняется пользовательское выражение. Вы можете при желании установить этот результат выражения в выбранный вопрос.",
      completedHtmlOnConditionDescription: "Если логическое выражение возвращает 'true', то текст по умолчанию для 'Страницы благодарности' изменяется на заданный.",
      itemExpressionText: "Когда выражение: '{0}' возвращает 'true'", //{0} - the expression
      // itemEmptyExpressionText: "New rule",
      page_visibilityText: "Сделать страницу {0} видимой", //{0} page name
      panel_visibilityText: "Сделать панель {0} видимой", //{0} panel name
      panel_enableText: "Сделать панель {0} включенной", //{0} panel name
      question_visibilityText: "Сделать вопрос {0} видимым", //{0} question name
      question_enableText: "Сделать вопрос {0} включенным", //{0} question name
      question_requireText: "Сделать вопрос {0} обязательным", //{0} question name
      // column_visibilityText: "make column {0} of question {1} visible", //{0} column name, {1} question name
      // column_enableText: "make column {0} of question {1} enable", //{0} column name, {1} question name
      // column_requireText: "make column {0} of question {1} required", //{0} column name, {1} question name
      trigger_completeText: "Опрос становится завершенным",
      trigger_setvalueText: "Установить в вопрос: {0} значение {1}", //{0} question name, {1} setValue
      trigger_copyvalueText: "Копировать в вопрос: {0} значение из вопроса {1}", //{0} and {1} question names
      trigger_skipText: "Опрос переходит к вопросу {0}", //{0} question name
      trigger_runExpressionText1: "Выполнить выражение: '{0}'", //{0} the expression
      trigger_runExpressionText2: "и установить его результат в вопрос: {0}", //{0} question name
      completedHtmlOnConditionText: "Показывать пользовательский текст для 'Страницы благодарности'.",
      // showAllQuestions: "All Questions",
      // showAllActionTypes: "All Action Types",
      // conditions: "Condition(s)",
      // actions: "Action(s)",
      expressionEditorTitle: "Настройка логики выражения",
      // actionsEditorTitle: "Define action(s)",
      // deleteAction: "Delete Action",
      // addNewAction: "Add Action",
      // selectedActionCaption: "Select action...",
      expressionInvalid: "Логическое выражение пустое или недействительное. Пожалуйста, исправьте его.",
      // noActionError: "Please, add at least one action.",
      // actionInvalid: "Please, fix problems in your action(s).",
      // expressionSetup: "",
      // actionsSetup: ""
    }
  },
  // Property Editors
  pe: {
    apply: "Применить",
    ok: "OK",
    save: "Сохранить",
    // clear: "Clear",
    // saveTooltip: "Save",
    cancel: "Отменить",
    // set: "Set",
    reset: "Сброс",
    // change: "Change",
    // refresh: "Refresh",
    close: "Закрыть",
    delete: "Удалить",
    add: "Добавить",
    addNew: "Добавить новый",
    addItem: "Нажмите, чтобы добавить элемент...",
    // removeItem: "Click to remove the item...",
    // dragItem: "Drag the item",
    addOther: "Другое",
    addSelectAll: "Выбрать все пункты",
    addNone: "Ничего",
    removeAll: "Удалить все",
    edit: "Редактировать",
    back: "Вернуться без сохранения",
    // backTooltip: "Return without saving",
    saveAndBack: "Сохранить и вернуться",
    // saveAndBackTooltip: "Save and return",
    // doneEditing: "Done",
    editChoices: "Редактировать выбор",
    // showChoices: "Show Choices",
    move: "Переместить",
    empty: "<пусто>",
    // emptyValue: "Value is empty",
    fastEntry: "Быстрый вход",
    // fastEntryNonUniqueError: "Value '{0}' is not unique",
    // fastEntryChoicesCountError: "Please limit the number of items from {0} to {1}",
    // fastEntryPlaceholder: "You can set data in the following format:\nvalue1|text\nvalue2",
    formEntry: "Форма входа",
    testService: "Тест услуги",
    itemSelectorEmpty: "Пожалуйста, выберите элемент",
    // conditionActionEmpty: "Please select the action",
    conditionSelectQuestion: "Выберите вопрос ...",
    conditionSelectPage: "Выбрать страницу ...",
    conditionSelectPanel: "Выберите панель ...",
    conditionValueQuestionTitle: "Пожалуйста, введите/выберите значение",
    expressionHelp: "Пожалуйста, введите выражение. Вы можете использовать фигурные скобки, чтобы получить доступ к значениям вопроса.: '{Вопрос1} + {Вопрос2}', '({цена}*{количество}) * (100 - {скидка}).",
    aceEditorHelp: "Нажмите Ctrl+Пробел, чтобы получить подсказки",
    aceEditorRowTitle: "Текущая строка",
    aceEditorPanelTitle: "Текущая панель",
    showMore: "Для получения более подробной информации, пожалуйста, перейдите к документации",
    assistantTitle: "Доступные вопросы",
    cellsEmptyRowsColumns: "Должен быть хотя бы один столбец или строка",
    // showPreviewBeforeComplete: "Preview answers before submitting the survey",
    propertyIsEmpty: "Пожалуйста, введите значение",
    // propertyIsNoUnique: "Please enter a unique value",
    propertyNameIsNotUnique: "Пожалуйста, введите уникальное имя",
    listIsEmpty: "Добавьте новое условие",
    // "listIsEmpty@choices": "No choices have been added yet",
    // "addNew@choices": "Add a choice",
    expressionIsEmpty: "Выражение - пустое",
    value: "Значение",
    text: "Текст",
    rowid: "ID строки",
    imageLink: "Ссылка на изображение",
    columnEdit: "Изменить столбец: {0}",
    itemEdit: "Редактировать элемент: {0}",
    url: "URL",
    path: "Путь",
    valueName: "Название пункта",
    choicesbyurl: {
      // valueName: "Get values from the following JSON field"
    },
    titleName: "Заголовок пункта",
    // imageLinkName: "Get image URLs from the following JSON field",
    // allowEmptyResponse: "Allow empty response",
    titlePlaceholder: "Введите заголовок",
    surveyTitlePlaceholder: "Введите заголовок опроса",
    pageTitlePlaceholder: "Введите заголовок страницы",
    descriptionPlaceholder: "Введите описание",
    surveyDescriptionPlaceholder: "Введите описание опроса",
    pageDescriptionPlaceholder: "Введите описание страницы",
    showOtherItem: "Есть пункт 'Другое'",
    otherText: "Текст пункта 'Другое'",
    showNoneItem: "Нет ни одного пункта",
    noneText: "Текст пункта 'Ничего'",
    showSelectAllItem: "Выбрать все пункты",
    selectAllText: "Текст пункта 'Выбрать все пункты'",
    choicesMin: "Минимальное значение для автоматически сгенерированных элементов",
    choicesMax: "Максимальное значение для автоматически сгенерированных элементов",
    choicesStep: "Разница между автоматически сгенерироваными элементами",
    name: "Название",
    title: "Заголовок",
    cellType: "Тип ячейки",
    // colCount: "Column count",
    choicesOrder: "Выбрать порядок отбора",
    visible: "Отображать?",
    isRequired: "Обязательный?",
    isAllRowRequired: "Все строки обязательны для заполнения",
    requiredErrorText: "Это поле обязательное для заполнения",
    startWithNewLine: "Начинать с новой строки?",
    rows: "Количество строк",
    // cols: "Columns",
    // placeholder: "Input area placeholder",
    showPreview: "Показать предпросмотр?",
    storeDataAsText: "Хранить содержимое файла в формате JSON как текст",
    // maxSize: "Maximum file size (in bytes)",
    // imageHeight: "Image height",
    // imageWidth: "Image width",
    rowCount: "Количество строк",
    columnLayout: "Макет столбцов",
    addRowLocation: "Добавить расположение кнопки строки",
    addRowText: "Добавить текст кнопки строки",
    removeRowText: "Удалить кнопку строки текста",
    rateMin: "Минимальный рейтинг",
    rateMax: "Максимальный рейтинг",
    rateStep: "Шаг рейтинга",
    minRateDescription: "Описание для минимального рейтинга",
    maxRateDescription: "Описание для максимального рейтинга",
    inputType: "Тип ввода",
    optionsCaption: "Параметры подписи",
    // defaultValue: "Default Answer",
    cellsDefaultRow: "Текст ячеек по умолчанию",
    surveyEditorTitle: "Настройки опросника",
    qEditorTitle: "Изменить: {0}",
    maxLength: "Максимальная длина",
    buildExpression: "Собрать",
    editExpression: "Редактировать",
    // and: "and",
    // or: "or",
    remove: "Переместить",
    addCondition: "добавить условие",
    // emptyLogicPopupMessage: "Select a question to start configuring conditions.",
    // if: "If",
    // then: "then",
    // setToName: "Target question",
    // fromName: "Question to copy answer from",
    // gotoName: "Question to skip to",
    // ruleIsNotSet: "Rule is incorrect",
    // includeIntoResult: "Include into survey results",
    showTitle: "Показать/скрыть заголовок",
    // expandCollapseTitle: "Expand/collapse title",
    locale: "Язык по умолчанию",
    simulator: "Выберите устройство",
    landscapeOrientation: "Альбомная",
    // portraitOrientation: "Switch to portrait orientation",
    mode: "Режим (редактирование/просмотр)",
    clearInvisibleValues: "Очистить невидимые значения",
    cookieName: "Имя Cookie (отключить повторное прохождение опроса локально)",
    sendResultOnPageNext: "Показать результаты опроса на странице рядом",
    storeOthersAsComment: "Хранить занчение 'Другое' в отдельном поле",
    showPageTitles: "Показывать заголовки страниц",
    showPageNumbers: "Показывать номера страниц",
    pagePrevText: "Текст в кнопке 'Предыдущая страница'",
    pageNextText: "Текст в кнопке 'Следующая страница'",
    completeText: "Текст в кнопке 'Завершить'",
    // previewText: "Preview Answers button text",
    // editText: "Edit Answer button text",
    startSurveyText: "Текст в кнопке 'Начать'",
    showNavigationButtons: "Показывать кнопки навигации (навигация по умолчанию)",
    showPrevButton: "Показывать кнопки 'Предыдущая страница' (пользователь может вернуться на предыдущую страницу)",
    firstPageIsStarted: "Первая страница опросника является стартовой страницей.",
    showCompletedPage: "Показывать страницу с текстом по завершению заполнения (HTML финальной страницы)",
    goNextPageAutomatic: "Переходить на следующую страницу автоматически при заполнении всех вопросов",
    showProgressBar: "Показывать прогресс заполнения",
    questionTitleLocation: "Расположение заголовка вопроса",
    requiredText: "Символ для обязательного вопроса",
    questionStartIndex: "Начальный индекс вопроса (1, 2 или 'A', 'а')",
    showQuestionNumbers: "Показывать номера вопросов",
    questionTitleTemplate: "Шаблон названия опроса, по умолчанию: {не} {требует} {текста}.",
    questionErrorLocation: "Размещение ошибки опроса",
    focusFirstQuestionAutomatic: "Фокусирование на первом вопросе при изменении страницы",
    questionsOrder: "Сортировка элементов на странице",
    maxTimeToFinish: "Максимальное время в секундах, чтобы заполнить опросник",
    maxTimeToFinishPage: "Максимальное время в секундах, чтобы заполнить страницу опросника",
    page: {
      // maxTimeToFinish: "Time limit to finish the page (in seconds)"
    },
    question: {
      // page: "Parent page"
    },
    showTimerPanel: "Показывать панель таймера",
    showTimerPanelMode: "Показывать режим панели таймера",
    // renderMode: "Panel display mode",
    allowAddPanel: "Разрешить добавление панели",
    allowRemovePanel: "Разрешить удаление панели",
    // noEntriesText: "Empty entries text",
    panelAddText: "Добавление текста на панели",
    panelRemoveText: "Удаление текста на панели",
    isSinglePage: "Показывать все элементы на одной странице",
    // html: "HTML markup",
    expression: "Выражение",
    // setValue: "Answer",
    // dataFormat: "Image format",
    // allowAddRows: "Allow adding rows",
    // allowRemoveRows: "Allow removing rows",
    // allowRowsDragAndDrop: "Allow row drag and drop",
    // responsiveImageSizeHelp: "Does not apply if you specify the exact image width or height.",
    // minImageWidth: "Minimum image width",
    // maxImageWidth: "Maximum image width",
    // minImageHeight: "Minimum image height",
    // maxImageHeight: "Maximum image height",
    minValue: "Минимальное значение",
    maxValue: "Максимальное значение",
    // minLength: "Minimum length (in characters)",
    allowDigits: "Разрешить цифры",
    minCount: "Минимальное количество",
    maxCount: "Максимальное количество",
    regex: "Регулярное выражение",
    surveyvalidator: {
      // text: "Error message",
      // expression: "Validation expression"
    },
    totalText: "Общий текст",
    totalType: "Общий тип",
    totalExpression: "Общее выражение",
    totalDisplayStyle: "Общий стиль отображения",
    totalCurrency: "Общая валюта",
    totalFormat: "Общий формат",
    // logo: "Logo (URL or base64-encoded string)",
    // questionsOnPageMode: "Survey structure",
    // maxTextLength: "Maximum answer length (in characters)",
    // maxOthersLength: "Maximum comment length (in characters)",
    // autoGrowComment: "Auto-expand comment area if necessary",
    // textUpdateMode: "Update text question value",
    // focusOnFirstError: "Set focus on the first invalid answer",
    // checkErrorsMode: "Run validation",
    // navigateToUrl: "Navigate to URL",
    // navigateToUrlOnCondition: "Dynamic URL",
    // completedBeforeHtml: "Markup to show if the user already filled out this survey",
    // completedHtml: "Survey Complete page markup",
    // completedHtmlOnCondition: "Dynamic Survey Complete page markup",
    // loadingHtml: "Markup to show while survey model is loading",
    // commentText: "Comment area text",
    // autocomplete: "Autocomplete type",
    // labelTrue: "\"True\" label",
    // labelFalse: "\"False\" label",
    // allowClear: "Show the Clear button",
    // displayStyle: "Value display style",
    // format: "Formatted string",
    // maximumFractionDigits: "Maximum fractional digits",
    // minimumFractionDigits: "Minimum fractional digits",
    // useGrouping: "Display grouping separators",
    // allowMultiple: "Allow multiple files",
    // allowImagesPreview: "Preview images",
    // acceptedTypes: "Accepted file types",
    // waitForUpload: "Wait for the upload to complete",
    // needConfirmRemoveFile: "Confirm file deletion",
    // detailPanelMode: "Detail panel location",
    // minRowCount: "Minimum row count",
    // maxRowCount: "Maximum row count",
    // confirmDelete: "Confirm row deletion",
    // confirmDeleteText: "Confirmation message",
    paneldynamic: {
      // confirmDelete: "Confirm panel deletion"
    },
    // panelCount: "Initial panel count",
    // minPanelCount: "Minimum panel count",
    // maxPanelCount: "Maximum panel count",
    // panelsState: "Inner panel expand state",
    // templateDescription: "Description template",
    // templateTitle: "Title template",
    // panelPrevText: "Previous Panel button tooltip",
    // panelNextText: "Next Panel button tooltip",
    // showRangeInProgress: "Show progress bar",
    // templateTitleLocation: "Question title location",
    // panelRemoveButtonLocation: "Remove Panel button location",
    // hideIfRowsEmpty: "Hide the question if there are no rows",
    // hideColumnsIfEmpty: "Hide columns if there are no rows",
    // rateValues: "Custom rate values",
    // hideIfChoicesEmpty: "Hide the question if it contains no choices",
    // hideNumber: "Hide question number",
    // minWidth: "Minimum width (in CSS-accepted values)",
    // maxWidth: "Maximum width (in CSS-accepted values)",
    // width: "Width (in CSS-accepted values)",
    // showHeader: "Show column headers",
    // horizontalScroll: "Show horizontal scrollbar",
    // columnMinWidth: "Minimum column width (in CSS-accepted values)",
    // rowTitleWidth: "Row header width (in CSS-accepted values)",
    // valueTrue: "\"True\" value",
    // valueFalse: "\"False\" value",
    // minErrorText: "\"Value is below minimum\" error message",
    // maxErrorText: "\"Value exceeds maximum\" error message",
    // otherErrorText: "\"Empty comment\" error message",
    // keyDuplicationError: "\"Non-unique key value\" error message",
    // maxSelectedChoices: "Maximum selected choices",
    // showClearButton: "Show the Clear button",
    // showNumber: "Show panel number",
    // logoWidth: "Logo width (in CSS-accepted values)",
    // logoHeight: "Logo height (in CSS-accepted values)",
    // readOnly: "Read-only",
    // enableIf: "Editable if",
    // emptyRowsText: "\"No rows\" message",
    // size: "Input size (in characters)",
    // separateSpecialChoices: "Separate special choices (None, Other, Select All)",
    // choicesFromQuestion: "Copy choices from the following question",
    // choicesFromQuestionMode: "Which choices to copy?",
    // showCommentArea: "Show the comment area",
    // commentPlaceholder: "Comment area placeholder",
    // displayRateDescriptionsAsExtremeItems: "Display rate descriptions as extreme values",
    // rowsOrder: "Row order",
    // columnsLayout: "Column layout",
    // columnColCount: "Nested column count",
    // state: "Panel expand state",
    // correctAnswer: "Correct Answer",
    // defaultPanelValue: "Default Values",
    // cells: "Cell Texts",
    // keyName: "Key column",
    itemvalue: {
      // text: "Alt text"
    },
    logoPosition: "Местоположение логотипа",
    addLogo: "Добавить логотип...",
    changeLogo: "Изменить логотип...",
    logoPositions: {
      none: "Разместить логотип",
      left: "Слева",
      right: "Справа",
      top: "Сверху",
      bottom: "Снизу"
    },
    tabs: {
      general: "Общие",
      fileOptions: "Параметры",
      // html: "HTML Editor",
      columns: "Столбцы",
      rows: "Строки",
      choices: "Выбор",
      items: "Элементы",
      visibleIf: "Отображать, если",
      enableIf: "Доступен, если",
      requiredIf: "Обязателен для заполнения, если",
      rateValues: "Оценить важность(вес) вопроса",
      choicesByUrl: "Выбор из Интернета",
      matrixChoices: "Выбор по умолчанию",
      multipleTextItems: "Ввод нескольких текстовых полей",
      // numbering: "Numbering",
      validators: "Валидаторы",
      navigation: "Навигация",
      question: "Вопрос",
      // pages: "Pages",
      timer: "Таймер/Викторина",
      calculatedValues: "Расчетные значения",
      triggers: "Триггеры",
      templateTitle: "Название шаблона",
      totals: "Итоги",
      logic: "Логика",
      layout: "Планировка",
      data: "Данные",
      validation: "Проверка",
      cells: "Ячейки",
      showOnCompleted: "Показать по завершению",
      logo: "Логотип в заголовке опросника",
      // slider: "Slider",
      // expression: "Expression",
      others: "Другие"
    },
    editProperty: "Редактировать свойство '{0}'",
    items: "[Элементы: {0}]",
    // choicesVisibleIf: "Choices are visible if",
    // choicesEnableIf: "Choices are selectable if",
    // columnsEnableIf: "Columns are visible if",
    // rowsEnableIf: "Rows are visible if",
    // indent: "Add indents",
    panel: {
      // indent: "Add outer indents"
    },
    // innerIndent: "Add inner indents",
    // defaultValueFromLastRow: "Take default values from the last row",
    // defaultValueFromLastPanel: "Take default values from the last panel",
    enterNewValue: "Пожалуйста, введите значение.",
    noquestions: "В опроснике нет ни одного вопроса",
    createtrigger: "Пожалуйста, создайте триггер",
    // titleKeyboardAdornerTip: "Press enter button to edit",
    // keyboardAdornerTip: "Press enter button to edit item, press delete button to delete item, press alt plus arrow up or arrow down to move item",
    triggerOn: "О",
    triggerMakePagesVisible: "Сделать страницы видимыми",
    triggerMakeQuestionsVisible: "Сделать элементы видимыми",
    triggerCompleteText: "Завершить опрос, если все получися.",
    triggerNotSet: "Тригер не установлен",
    triggerRunIf: "Начать, если",
    triggerSetToName: "Изменить значение",
    triggerFromName: "Скопировать значение из",
    triggerRunExpression: "Выполнить это выражение",
    triggerSetValue: "в",
    triggerGotoName: "Перейти к вопросу",
    triggerIsVariable: "Не помещать переменную в результат опросника.",
    triggerRunExpressionEmpty: "Пожалуйста, введите корректное выражение",
    // emptyExpressionPlaceHolder: "Type expression here...",
    // noFile: "No file choosen",
    // clearIfInvisible: "Clear the value if the question becomes hidden",
    // valuePropertyName: "Value property name",
    // searchEnabled: "Enable search",
    // hideSelectedItems: "Hide selected items",
    // signatureWidth: "Signature width",
    // signatureHeight: "Signature height",
    // verticalAlign: "Vertical alignment",
    // alternateRows: "Alternate rows",
    // columnsVisibleIf: "Columns are visible if",
    // rowsVisibleIf: "Rows are visible if",
    // otherPlaceholder: "Comment area placeholder"
  },
  // Property values
  pv: {
    // true: "true",
    // false: "false",
    inherit: "Наследовать",
    show: "Показать",
    hide: "Скрыть",
    default: "По умолчанию",
    initial: "Изначальный порядок",
    random: "Случайный порядок",
    collapsed: "Прерван",
    expanded: "Расширен",
    none: "Нет",
    asc: "По возрастанию",
    desc: "По убыванию",
    indeterminate: "Неопределенный",
    // decimal: "decimal",
    // currency: "currency",
    // percent: "percent",
    firstExpanded: "Расширен первый",
    off: "Выключить",
    // onpanel: "Start on each panel",
    onPanel: "На панели",
    onSurvey: "В опроснике",
    list: "Список",
    progressTop: "progressTop",
    progressBottom: "progressBottom",
    progressTopBottom: "progressTopBottom",
    horizontal: "Горизонтально",
    vertical: "Вертикально",
    top: "Сверху",
    bottom: "Снизу",
    topBottom: "Сверху и снизу",
    // both: "Both",
    left: "Слева",
    // right: "Right",
    // color: "color",
    // date: "date",
    // datetime: "datetime",
    // "datetime-local": "datetime-local",
    // email: "email",
    // month: "month",
    // number: "number",
    // password: "password",
    // range: "range",
    // tel: "tel",
    // text: "text",
    // time: "time",
    // url: "url",
    // week: "week",
    hidden: "Скрыт",
    on: "Включить",
    onPage: "На странице",
    edit: "Редактирование",
    display: "Просмотр",
    onComplete: "По завершению",
    onHidden: "По скрытию",
    // onHiddenContainer: "When the question or its panel/page becomes hidden",
    clearInvisibleValues: {
      // none: "Never"
    },
    all: "Все",
    page: "Страница",
    survey: "Опрос",
    onNextPage: "При переходе на следующую страницу",
    onValueChanged: "При изменении значения",
    // onValueChanging: "Before an answer is changed",
    // standard: "Original structure",
    // singlePage: "All questions on a single page",
    // questionPerPage: "Each question on an individual page",
    // noPreview: "No preview",
    // showAllQuestions: "Show all questions",
    // showAnsweredQuestions: "Show answered questions only",
    // pages: "Completed pages",
    // questions: "Answered questions",
    // requiredQuestions: "Answered required questions",
    // correctQuestions: "Valid answers",
    // buttons: "Completed pages (button UI)",
    // underInput: "Under the input",
    // underTitle: "Under the title",
    // onBlur: "On blur",
    // onTyping: "While typing",
    // underRow: "Under the row",
    // underRowSingle: "Under the row, only one panel is visible",
    showNavigationButtons: {
      // none: "Hidden"
    },
    showProgressBar: {
      // off: "Hidden"
    },
    showTimerPanel: {
      // none: "Hidden"
    },
    showTimerPanelMode: {
      // all: "Both"
    },
    detailPanelMode: {
      // none: "Hidden"
    },
    addRowLocation: {
      // default: "Depends on matrix layout"
    },
    panelsState: {
      // default: "Users cannot expand or collapse panels",
      // collapsed: "All panels are collapsed",
      // expanded: "All panels are expanded"
    },
    widthMode: {
      // auto: "Auto",
      // static: "Static",
      // responsive: "Responsive"
    },
    imageFit: {
      // none: "None",
      // contain: "Contain",
      // cover: "Cover",
      // fill: "Fill"
    },
    contentMode: {
      // auto: "Auto",
      // image: "Image",
      // video: "Video",
      // youtube: "YouTube"
    },
    displayMode: {
      // auto: "Auto",
      // buttons: "Buttons",
      // dropdown: "Dropdown"
    }
  },
  // Operators
  op: {
    empty: "не заполнено",
    notempty: "заполнено",
    equal: "равно",
    notequal: "не равно",
    contains: "содержит",
    notcontains: "не содержит",
    anyof: "любой из",
    allof: "все из",
    greater: "больше",
    less: "меньше",
    greaterorequal: "больше или равно",
    lessorequal: "меньше или равно",
    // and: "and",
    // or: "or"
  },
  // Embed window
  ew: {
    angular: "Используйте версию Angular",
    jquery: "Используйте версию jQuery",
    knockout: "Используйте версию Knockout",
    react: "Используйте версию React",
    vue: "Используйте версию Vue",
    bootstrap: "Для библиотеки bootstrap",
    // modern: "Modern theme",
    // default: "Default theme",
    // orange: "Orange theme",
    // darkblue: "Darkblue theme",
    // darkrose: "Darkrose theme",
    // stone: "Stone theme",
    // winter: "Winter theme",
    // winterstone: "Winter-Stone theme",
    showOnPage: "Показывать опросник на странице",
    showInWindow: "Показывать опросник в модальном окне",
    loadFromServer: "Загрузить Json опросника с сервера",
    titleScript: "Скрипты и стили",
    titleHtml: "HTML",
    titleJavaScript: "JavaScript"
  },
  // Preview (Survey)
  ts: {
    selectPage: "Выберите страницу, чтобы проверить это",
    showInvisibleElements: "Показывать скрытые элементы",
    // hideInvisibleElements: "Hide invisible elements"
  },
  validators: {
    answercountvalidator: "Количество ответов",
    // emailvalidator: "Email",
    expressionvalidator: "Выражение",
    numericvalidator: "Число",
    regexvalidator: "Регулярное выражение",
    textvalidator: "Текст"
  },
  triggers: {
    completetrigger: "Завершить опрос",
    setvaluetrigger: "Установить значение",
    copyvaluetrigger: "Скопировать значение",
    skiptrigger: "Перейти к вопросу",
    runexpressiontrigger: "Запустить выражение",
    visibletrigger: "Изменить видимость (не рекомендуется)"
  },
  pehelp: {
    // cookieName: "Cookies prevent users from filling out the same survey twice.",
    // format: "Use {0} as a placeholder for the actual value.",
    // totalText: "Visible only when at least one column has Total type or Total expression.",
    // acceptedTypes: "Refer to the [accept](https://www.w3schools.com/tags/att_input_accept.asp) attribute description for more information.",
    // columnColCount: "Applicable only to Radiogroup and Checkbox cell types.",
    // autocomplete: "Refer to the [autocomplete](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete) attribute description for more information.",
    // valueName: "If you do not set this property, the answer will be stored in a field specified by the Name property.",
    choicesbyurl: {
      // valueName: " "
    },
    // keyName: "If the specified column contains identical values, the survey throws the \"Non-unique key value\" error."
  },
  // Properties
  p: {
    title: {
      name: "Название",
      title: "Оставьте его пустым, если оно такое же, как 'Название'"
    },
    // multiSelect: "Allow multiple selection",
    // showLabel: "Show image captions",
    // value: "Value",
    visibleIf: "Отображать, если",
    // attachOriginalItems: "attachOriginalItems",
    // useDisplayValuesInDynamicTexts: "useDisplayValuesInDynamicTexts",
    titleLocation: "Расположение заголовка",
    description: "Описание",
    // descriptionLocation: "descriptionLocation",
    // defaultValueExpression: "defaultValueExpression",
    requiredIf: "Обязателен если",
    validators: "Валидаторы",
    // bindings: "bindings",
    // renderAs: "renderAs",
    currency: "Валюта",
    // cellHint: "cellHint",
    // isUnique: "isUnique",
    // showInMultipleColumns: "showInMultipleColumns",
    // totalMaximumFractionDigits: "totalMaximumFractionDigits",
    // totalMinimumFractionDigits: "totalMinimumFractionDigits",
    columns: "Столбцы",
    // detailElements: "detailElements",
    choices: "Выбор",
    // allowAdaptiveActions: "allowAdaptiveActions",
    defaultRowValue: "Значение строки по умолчанию",
    // detailPanelShowOnAdding: "detailPanelShowOnAdding",
    // logoFit: "logoFit",
    // pages: "pages",
    // questions: "questions",
    triggers: "триггеры",
    // calculatedValues: "calculatedValues",
    // surveyId: "surveyId",
    // surveyPostId: "surveyPostId",
    // surveyShowDataSaving: "surveyShowDataSaving",
    // questionDescriptionLocation: "questionDescriptionLocation",
    // progressBarType: "progressBarType",
    // questionTitlePattern: "questionTitlePattern",
    // widthMode: "widthMode",
    // showBrandInfo: "showBrandInfo",
    choicesByUrl: "Выбрать из интернета",
    // choicesLazyLoadEnabled: "choicesLazyLoadEnabled",
    // choicesLazyLoadPageSize: "choicesLazyLoadPageSize",
    // inputFieldComponent: "inputFieldComponent",
    // itemComponent: "itemComponent",
    // min: "min",
    // max: "max",
    // minValueExpression: "minValueExpression",
    // maxValueExpression: "maxValueExpression",
    // step: "step",
    // dataList: "dataList",
    itemSize: "Размер элементов",
    // elements: "elements",
    // content: "content",
    navigationButtonsVisibility: "Отображение навигационных кнопок",
    // navigationTitle: "navigationTitle",
    // navigationDescription: "navigationDescription",
    // closeOnSelect: "closeOnSelect",
    // longTap: "longTap",
    // autoGrow: "autoGrow",
    // acceptCarriageReturn: "acceptCarriageReturn",
    // displayMode: "displayMode",
    label: "Ярлык",
    // contentMode: "contentMode",
    // imageFit: "imageFit",
    // altText: "altText",
    // height: "height",
    // penColor: "penColor",
    // backgroundColor: "backgroundColor",
    // templateElements: "templateElements",
    // operator: "operator",
    // isVariable: "isVariable",
    // runExpression: "runExpression",
    // showCaption: "showCaption",
    // iconName: "iconName",
    // iconSize: "iconSize"
  }
};
//Uncomment this line on creating a translation file. You should replace "en" and enStrings with your locale ("fr", "de" and so on) and your variable.
editorLocalization.locales["ru"] = ruStrings;
