import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  clearErrors,
  deleteEmployee,
  fetchEmployees,
} from '../../../../core/features/provider-employees/providerEmployeesSlice';
import { toast } from 'react-toastify';
import { Add, Delete } from '@mui/icons-material';
import Table from '../../../../../components/new-components/Table';
import { Person, PhoneAndroid, StarOutline } from '@mui/icons-material';
import { Button, Modal } from '@mui/material';
import DeleteModal from '../../../../../components/new-components/DeleteModal';
import { Grid } from '@mui/material';
import CreateEmployeeForm from '../CreateEmployeeForm';
import { useEventsTranslation } from '../../../../services/i18n';

export default function ProviderEmpolyees({ isRefreshing, setIsRefreshing }) {
  const { isLoading, error, employees } = useSelector(
    (state) => state.providerEmployeesSlice
  );

  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deletedId, setDeletedId] = useState(0);
  const [page, setPage] = useState(0);
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchEmployees({ id, page_number: page }));
  }, [dispatch, isRefreshing]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
      dispatch(clearErrors());
    }
    return () => {};
  }, [dispatch, error]);

  const t = useEventsTranslation();

  const cols = [
    {
      field: 'full_name',
      headerName: t('name'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <Grid container>
          <Grid item ml={2}>
            {params.value.user.full_name}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'mobile',
      headerName: t('mobile'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <Grid container>
          <Grid item ml={2} className='flex items-center'>
            <PhoneAndroid /> <p dir='ltr'>{params.value.user.mobile}</p>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'role_id',
      headerName: t('role'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <Grid container>
          <Grid item ml={2}>
            {params.value.role_id === 4 ? <StarOutline /> : <Person />}
            {params.value.role_id === 5 ? t('employee') : t('manager')}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'btn',
      headerName: t('actions'),
      headerClassName: 'bg-gray-100 text-center w-full h-full',
      disableColumnMenu: true,
      flex: 0.5,
      renderCell: (params) => (
        <div className='flex justify-center items-center ml-2 w-full gap-2'>
          <button
            onClick={() => {
              setDeletedId(params.value.user.user_id);
              setConfirmModal(true);
            }}
          >
            <Delete color='error' />
          </button>
        </div>
      ),
    },
  ];

  const rowsData = employees.map((employee, i) => ({
    id: i,
    full_name: employee,
    mobile: employee,
    role_id: employee,
    btn: employee,
  }));

  const handlePagination = (newPage) => {
    setPage(newPage);
    setIsRefreshing((prev) => prev + 1);
  };

  const tableProps = {
    isLoading,
    pagination: (newPage) => handlePagination(newPage),
    loaderType: 'page',
    rowsData,
    cols,
    page,
  };

  return (
    <div className='flex flex-col'>
      <DeleteModal
        setOpenModal={setConfirmModal}
        openModal={confirmModal}
        onConfirm={async () => {
          await dispatch(deleteEmployee({ userId: deletedId, providerId: id }));
          setIsRefreshing((prev) => prev + 1);
        }}
        // message={'Are you sure do you want to delete this employee?'}
        message={t('delete_employee_msg')}
      />
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <CreateEmployeeForm
          setOpenModal={setOpenModal}
          setIsRefreshing={setIsRefreshing}
          provider_id={id}
        />
      </Modal>
      <div className='mb-3'>
        <Button
          variant='contained'
          color='primary'
          className='flex items-center w-40 '
          onClick={() => {
            setOpenModal(true);
          }}
          endIcon={<Add />}
        >
          {t('add_new')}
        </Button>
      </div>
      <Table {...tableProps} />
    </div>
  );
}
