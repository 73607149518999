import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../services/API/drivers-tickects';

const initialState = {
  isLoading: false,
  ticketDetails: {},
  ticketTransactions: [],
  ticketMessages: [],
  supportUsersList: [],
  categoriesList: [],
  error: null,
};

export const fetchData = createAsyncThunk(
  'driversTicketsDetails/fetchData(Details+supportUsers+categories)',
  async (payload) => {
    const details = await api.getTicketDetails(payload);
    const supportUsers = await api.getSupportUsers();
    const categories = await api.getTicketcategories();
    return { details, supportUsers, categories };
  }
);

export const fetchTicketMessages = createAsyncThunk(
  'driversTicketsDetails/fetchTicketMessages',
  async (payload) => {
    await api.getTicketMessages();
  }
);
export const fetchTicketTransactions = createAsyncThunk(
  'driversTicketsDetails/fetchTicketTransactions',
  async (payload) => {
    await api.getTicketTransactions();
  }
);
export const assignSupportUser = createAsyncThunk(
  'driversTicketsDetails/assignSupportUser',
  async (payload) => {
    await api.assignSupportUser();
  }
);
export const createTicket = createAsyncThunk(
  //?implementation in driver list
  'driversTicketsDetails/createTicket',
  async (payload) => {
    await api.createTicket();
  }
);
export const sendMessage = createAsyncThunk(
  'driversTicketsDetails/sendMessage',
  async (payload) => {
    await api.sendMessage();
  }
);
export const updateTicketDetails = createAsyncThunk(
  'driversTicketsDetails/updateTicketDetails',
  async (payload) => {
    await api.updateTicketDetails();
  }
);
export const updateTicketStatus = createAsyncThunk(
  'driversTicketsDetails/updateTicketStatus',
  async (payload) => {
    await api.updateTicketStatus();
  }
);

const driversTicketsDetailslSlice = createSlice({
  name: 'driversTicketsDetails',
  initialState,
  extraReducers: (builder) => {
    //data
    builder.addCase(fetchData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ticketDetails = action.payload.details;
      state.supportUsers = action.payload.supportUsers;
      state.categoriesList = action.payload.categories;
    });
    builder.addCase(fetchData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    //assign
    builder.addCase(assignSupportUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(assignSupportUser.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(assignSupportUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    //transactions
    builder.addCase(fetchTicketTransactions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTicketTransactions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ticketTransactions = action.payload;
    });
    builder.addCase(fetchTicketTransactions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    //messages
    builder.addCase(fetchTicketMessages.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTicketMessages.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ticketMessages = action.payload;
    });
    builder.addCase(fetchTicketMessages.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    //update details
    builder.addCase(updateTicketDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateTicketDetails.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateTicketDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    //update status
    builder.addCase(updateTicketStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateTicketStatus.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateTicketStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    //sendMessage
    builder.addCase(sendMessage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sendMessage.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(sendMessage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export default driversTicketsDetailslSlice.reducer;
