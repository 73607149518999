import { useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';
import HeatMapContainer from '../components/HeatMapContainer';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getHeatMapData } from '../../../Redux/store/demand_supply/demandSupplySlice';
import { setAlert } from '../../../Redux/store/app/appSlice';
import { useEffect } from 'react';
import { generateTimeLineSlots } from '../utils';

const HeatMapPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    from: moment('2022-10-08').startOf('day').add(2, 'hours').utc(), //!need to ask hassan about it
    to: moment('2022-10-08').endOf('day').add(2, 'hours').utc(),
  });

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(getHeatMapData({ ...dateRange }));
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    }
  });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid container lg={12} md={12}>
      <Grid item lg={12} md={12}>
        <HeatMapContainer dateRange={dateRange} setDateRange={setDateRange} />
      </Grid>
    </Grid>
  );
};

export default HeatMapPage;
