import { MapContainer, TileLayer, Popup, Circle } from 'react-leaflet';
import { useSelector } from 'react-redux';
import './Map.css';
import { handleGenerateFakeData } from '../../utils';

const Map = ({ selectedTime }) => {
  const { supplyDemandData } = useSelector((state) => state.supplyDemand);
  return (
    <MapContainer
      className='container'
      center={[15.586001978047445, 32.53120422363282] || []}
      zoom={12}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      let count = 0
      {supplyDemandData &&
        supplyDemandData.map(
          (data) =>
            data.from_time === selectedTime && (
              <Circle
                key={data.to_time}
                center={[data.latitude, data.longitude]}
                fillOpacity={1}
                opacity={1}
                pathOptions={{
                  color: handleGenerateFakeData(
                    data.supply_demand_ratio,
                    'ratio'
                  ),
                  fillColor: handleGenerateFakeData(
                    data.supply_demand_ratio,
                    'ratio'
                  ),
                }}
                radius={handleGenerateFakeData(
                  data.supply_demand_difference,
                  'diff'
                )}
              >
                <Popup closeButton={false}>
                  before :{data.supply_demand_difference * 7}
                  <br />
                  {data.from_time}
                </Popup>
              </Circle>
            )
        )}
    </MapContainer>
  );
};

export default Map;
