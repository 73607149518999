import { Grid } from '@material-ui/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Pane,
  Circle,
  Polyline,
  useMap,
  useMapEvents,
  useMapEvent,
} from 'react-leaflet';
import { useSelector } from 'react-redux';
import './Map.css';
import PinDropIcon from '@material-ui/icons/PinDrop';
import L, { map } from 'leaflet';
import polyline from '@mapbox/polyline';
import { HorizontalSplit } from '@material-ui/icons';
import http1 from '../../../../../services/APIs/dispatch_api';

const MapEvents = ({ setCoordinates, coordinates, stage }) => {
  useMapEvents({
    click(e) {
      const newCoordinates = e.latlng;
      if (stage === 'pickup') {
        setCoordinates({ ...coordinates, pickup: newCoordinates });
      } else if (stage === 'check') {
        setCoordinates({ ...coordinates, dropoff: newCoordinates });
      }
    },
  });
  return false;
};

function ChangeView({ center }) {
  const map = useMap();
  map.setView(center);
  // map.fitBounds([50.505, -29.09]);
  return null;
}

const dropoff = new L.Icon({
  iconUrl: require('../../../../../assets/svg/dropoff.png'),
  iconSize: [35, 40],
  iconAnchor: [17, 26],
});

const Map = ({ coordinates, setCoordinates, stage }) => {
  const animateRef = useRef(false);
  // const [coordinates, setCoordinates] = useState({
  //   lat: 15.5007,
  //   lng: 32.5599,
  // });

  return (
    <MapContainer center={coordinates.center} zoom={12} scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {/* pickup */}
      <ChangeView
        center={stage === 'pickup' ? coordinates.pickup : coordinates.dropoff}
      />

      <Marker position={coordinates.pickup}>
        <Popup closeButton={false}>Pickup</Popup>
      </Marker>
      <MapEvents
        setCoordinates={setCoordinates}
        coordinates={coordinates}
        stage={stage}
      />

      {/* dropoff */}
      {stage === 'check' ||
      stage === 'checked' ||
      stage === 'check with dropoff' ||
      stage === 'start trip' ? (
        <Marker position={coordinates.dropoff} icon={dropoff}>
          <Popup closeButton={false}>Dropoff</Popup>
        </Marker>
      ) : null}
    </MapContainer>
  );
};

export default Map;
