import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import { getPaginationState } from '../../../components/common/paginationState';

import {
  fetchDrivers,
  fetchCarTypes,
  fetchFeesGroups,
  setError,
  setLoadingState,
} from '../../../Redux/store/drivers/driversListSlice';
import DriversTable from '../components/common/DriversList';
import ListFilter from '../components/common/ListFilter';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import PageHeading from '../../../components/common/PageHeading';
import { setAlert } from '../../../Redux/store/app/appSlice';
import { useDriverTranslation } from '../i18n';
import { toast } from 'react-toastify';

function DriversList(props) {
  const dispatch = useDispatch();
  const { drivers, error } = useSelector((state) => state.drivers).driversList;
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(0);
  const t = useDriverTranslation();
  const [search, setSearch] = useState({
    blocked: false,
    payment_block: false,
    blocked_until: false,
    car_type_ids: '',
    fees_group_id: 0,
    mobile: '',
  });

  const [searchParams, setSearchParmas] = useSearchParams(
    createSearchParams({
      page_number: 0,
      page_size: 20,
      blocked: '',
      payment_block: '',
      blocked_until: '',
      car_type_ids: '',
      fees_group_id: 0,
      mobile: '',
    })
  );

  const currnetSearchParams = useMemo(() => ({
    ...search,
    page_number: Number(searchParams.get('page_number')),
    page_size: searchParams.get('page_size'),
  }));

  const setPage = (newPage) => {
    setSearchParmas({
      ...currnetSearchParams,
      page_number: newPage,
    });
    setIsRefreshing((prev) => prev + 1);
  };

  const onApply = () => {
    setSearchParmas({
      ...currnetSearchParams,
      ...search,
      page_number: 0,
    });
    setIsRefreshing((prev) => prev + 1);
  };

  const fetchEverything = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        dispatch(fetchDrivers(currnetSearchParams)),
        dispatch(fetchCarTypes()),
        dispatch(fetchFeesGroups()),
      ]);
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    fetchEverything();
  }, [isRefreshing]);

  // useEffect(() => checkAuth(error), []);

  return (
    <Grid container justifyContent='center' spacing={2}>
      <Grid item lg={12} md={12}>
        <PageHeading
          heading1={t('drivers')}
          heading2={t('drivers_list')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>

      <Grid item lg={12} md={12} xs={12} className='bg-gray-100 '>
        <ListFilter
          onApply={onApply}
          search={search}
          setSearch={setSearch}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      </Grid>
      <Grid item lg={12} md={12} xs={12}>
        <DriversTable
          data={drivers}
          page={Number(currnetSearchParams.page_number)}
          setPage={setPage}
          loading={isLoading}
        />
      </Grid>
    </Grid>
  );
}
export default DriversList;
