import { Grid } from '@mui/material';
import React from 'react';
import { Provider } from 'react-redux';
import eventsStore from '../../../core/app/store';
import CreateEventForm from '../components/CreateEventForm';

export default function AddEventPage() {
  return (
    <Provider store={eventsStore}>
      <CreateEventForm />
    </Provider>
  );
}
