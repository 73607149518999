import { getEvents } from './getEvents';
import { updateEvent } from './updateEvent';
import { deleteEvent } from './deleteEvent';
import { createEvent } from './createEvent';
import { getEventDetails } from './getEventDetails';
import { handler } from '../http';
import http from '../http';

export default {
  getEvents,
  updateEvent,
  deleteEvent,
  createEvent,
  getEventDetails,
  hideEvent: (id) => handler(http.put('/dashboard/events/hide/' + id)),
  cancelEvent: (id) => handler(http.put('/dashboard/events/cancel/' + id)),
  startBookingEvent: (id) =>
    handler(http.put('/dashboard/events/start_booking/' + id)),
  endBookingEvent: (id) =>
    handler(http.put('/dashboard/events/end_booking/' + id)),
  getAllEvents: (params) =>
    handler(
      http.get('/dashboard/events', {
        params: { ...params, is_deleted: false },
      })
    ),
};
