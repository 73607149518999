import { CalendarMonth, Place } from '@mui/icons-material';
import { Avatar, Chip } from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';

export const eventsStatuses = [
  { name: 'new', color: 'primary' },
  { name: 'canceled', color: 'error' },
  { name: 'hidden', color: 'warning' },
  { name: 'start_booking', color: 'success' },
  { name: 'end_booking', color: 'info' },
];

export const filterMobile = (mobile) => {
  if (!mobile) return '';
  mobile = mobile[0] === '0' ? mobile.slice(1) : mobile;
  return `+249${mobile}`;
};

export const filterDate = (date) => {
  return date ? moment(date).utc() : '';
};

export const eventsCommonCols = (t) => [
  {
    field: 'name',
    headerName: t('name'),
    headerClassName: ' bg-gray-100 text-center w-full',
    disableColumnMenu: true,
    flex: 1.5,
    renderCell: (params) => (
      <div className='flex flex-col ml-2'>
        <p className=''>{params.value.event_name}</p>
      </div>
    ),
  },
  {
    field: 'provider',
    headerName: t('event_provider'),
    headerClassName: 'bg-gray-100 text-center w-full',
    disableColumnMenu: true,
    flex: 1.5,
    renderCell: (params) => (
      <Link to={'/events/providers/' + params.value.provider_id}>
        <div className='flex w-full items-center hover:bg-blue-200'>
          <div className='ml-2'>
            <Avatar
              alt={params.value.full_name_en}
              src={params.value.provider_logo}
            />
          </div>
          <div className='flex flex-col ml-3'>
            <p className=''>{params.value.full_name}</p>
          </div>
        </div>
      </Link>
    ),
  },
  {
    field: 'address',
    headerName: t('address'),
    headerClassName: ' bg-gray-100 text-center w-full',
    disableColumnMenu: true,
    flex: 1.5,
    renderCell: (params) => (
      <div className='flex flex-col mx-2  w-full'>
        <p className='truncate'>
          <Place /> {params.value.event_address}
        </p>
      </div>
    ),
  },
  {
    field: 'date',
    headerName: t('event_date'),
    headerClassName: ' bg-gray-100 text-center w-full',
    disableColumnMenu: true,
    flex: 1.5,
    renderCell: (params) => (
      <div className='flex flex-col ml-2'>
        <p className=''>
          <CalendarMonth /> {t('start_at')} :{' '}
          {params.value.event_start_date.slice(0, 10)}
        </p>
        <p className=''>
          <CalendarMonth /> {t('end_at')} :{' '}
          {params.value.event_end_date.slice(0, 10)}
        </p>
      </div>
    ),
  },
  {
    field: 'bookingDate',
    headerName: t('booking_date'),
    headerClassName: ' bg-gray-100 text-center w-full',
    disableColumnMenu: true,
    flex: 1.5,
    renderCell: (params) => (
      <div className='flex flex-col ml-2'>
        <p className=''>
          <CalendarMonth /> {t('start_at')} :{' '}
          {moment(params.value.start_booking_at).format('YYYY-MM-DD')}
        </p>
        <p className=''>
          <CalendarMonth /> {t('end_at')} :{' '}
          {moment(params.value.end_booking_at).format('YYYY-MM-DD')}
        </p>
      </div>
    ),
  },
  {
    field: 'status',
    headerName: t('status'),
    headerClassName: ' bg-gray-100 text-center w-full',
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params) => (
      <Chip
        sx={{
          fontSize: '12px',
          p: 1,
          pb: 2,
        }}
        color={eventsStatuses[params.value.event_status_id - 1].color}
        variant='outlined'
        label={t(eventsStatuses[params.value.event_status_id - 1].name)}
      />
    ),
  },
];
