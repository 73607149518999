import React from 'react';
import { Button, Grid } from '@mui/material';

import TextField from '@mui/material/TextField';

import { useFormik } from 'formik';
import { driverFromValidationSchema } from '../../validations/validation';
import { useSelector } from 'react-redux';
import { useDriverTranslation } from '../../i18n';
import { MenuItem } from '@mui/material';
import { appDirection } from '../../../../shared_utils';

const Form = (props) => {
  const { driver, carTypes, feesGroups } = useSelector(
    (state) => state.drivers
  ).driversList;

  const handleClose = props.close;
  const updateDriverInfo = props.updateDriverInfo;
  const t = useDriverTranslation();
  // const header = props.header

  //formik
  const formik = useFormik({
    initialValues: driver,
    validationSchema: driverFromValidationSchema,
  });

  const handleSubmit = (values) => {
    // updateDriverInfo({full_name });
    updateDriverInfo({ ...values, ...{ fees_group: null } });
    handleClose();
  };

  return (
    <>
      {/* general info */}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(formik.values);
        }}
        style={{ width: '100%' }}
      >
        <Grid container lg={12} md={12} spacing={2} dir={appDirection}>
          {/* header */}
          <Grid item lg={12} className=' w-full  text-gray-600 font-bold'>
            {t('driver_info')}
          </Grid>

          {/* body  */}

          <Grid item lg={6}>
            <TextField
              variant='outlined'
              margin='dense'
              id='full_name'
              name='full_name'
              label={t('name')}
              fullWidth
              value={formik.values.full_name}
              onChange={formik.handleChange}
              error={
                formik.touched.full_name && Boolean(formik.errors.full_name)
              }
              helperText={formik.touched.full_name && formik.errors.full_name}
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              variant='outlined'
              margin='dense'
              id='mobile_number'
              name='mobile_number'
              label={t('mobile_number')}
              type='phone'
              fullWidth
              value={formik.values.mobile_number}
              onChange={formik.handleChange}
              error={
                formik.touched.mobile_number &&
                Boolean(formik.errors.mobile_number)
              }
              helperText={
                formik.touched.mobile_number && formik.errors.mobile_number
              }
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              variant='outlined'
              margin='dense'
              id='driver_licence'
              name='driver_licence'
              label={t('driver_license')}
              type='text'
              fullWidth
              // defaultValue={driver.driver_licence}
              value={formik.values.driver_licence}
              onChange={formik.handleChange}
              error={
                formik.touched.driver_licence &&
                Boolean(formik.errors.driver_licence)
              }
              helperText={
                formik.touched.driver_licence && formik.errors.driver_licence
              }
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              variant='outlined'
              margin='dense'
              id='national_id'
              name='national_id'
              label={t('national_id')}
              type='text'
              fullWidth
              // defaultValue={driver.national_id}
              value={formik.values.national_id}
              onChange={formik.handleChange}
              error={
                formik.touched.national_id && Boolean(formik.errors.national_id)
              }
              helperText={
                formik.touched.national_id && formik.errors.national_id
              }
            />
          </Grid>
          <Grid item lg={12} md={12}>
            <TextField
              select
              fullWidth
              margin='dense'
              variant='outlined'
              id='fees_group_id'
              name='fees_group_id'
              value={formik.values.fees_group_id}
              onChange={formik.handleChange}
              label={t('fees_groups')}
            >
              {feesGroups.map((Group) => (
                <MenuItem key={Group.id} value={Group.id}>
                  {Group.group_name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        {/* car info */}
        <Grid container lg={12} md={12} spacing={2} style={{ padding: '10px' }}>
          {/* header */}
          <Grid item lg={12} className=' w-full  text-gray-600 font-bold'>
            {t('car_info')}
          </Grid>

          <Grid item lg={6}>
            <TextField
              variant='outlined'
              margin='dense'
              id='car_plate'
              label={t('plate_number')}
              type='text'
              fullWidth
              // defaultValue={driver.car_plate}
              value={formik.values.car_plate}
              onChange={formik.handleChange}
              error={
                formik.touched.car_plate && Boolean(formik.errors.car_plate)
              }
              helperText={formik.touched.car_plate && formik.errors.car_plate}
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              variant='outlined'
              margin='dense'
              id='car_color'
              label={t('color')}
              type='text'
              fullWidth
              // defaultValue={driver.car_color}
              value={formik.values.car_color}
              onChange={formik.handleChange}
              error={
                formik.touched.car_color && Boolean(formik.errors.car_color)
              }
              helperText={formik.touched.car_color && formik.errors.car_color}
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              variant='outlined'
              margin='dense'
              id='car_desc'
              label={t('car_model')}
              type='text'
              fullWidth
              // defaultValue={driver.car_desc}
              value={formik.values.car_desc}
              onChange={formik.handleChange}
              error={formik.touched.car_desc && Boolean(formik.errors.car_desc)}
              helperText={formik.touched.car_desc && formik.errors.car_desc}
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              variant='outlined'
              margin='dense'
              id='car_model_year'
              label={t('manufacture_date')}
              type='text'
              fullWidth
              // defaultValue={driver.car_model_year}
              value={formik.values.car_model_year}
              onChange={formik.handleChange}
              error={
                formik.touched.car_model_year &&
                Boolean(formik.errors.car_model_year)
              }
              helperText={
                formik.touched.car_model_year && formik.errors.car_model_year
              }
            />
          </Grid>

          <Grid item lg={6}>
            <TextField
              select
              fullWidth
              margin='dense'
              variant='outlined'
              id='car_type_id'
              name='car_type_id'
              value={formik.values.car_type_id}
              onChange={formik.handleChange}
              error={
                formik.touched.car_type_id && Boolean(formik.errors.car_type_id)
              }
              helperText={
                formik.touched.car_type_id && formik.errors.car_type_id
              }
              label={t('car_type')}
            >
              {carTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.car_name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={6} md={6}>
            <TextField
              select
              fullWidth
              margin='dense'
              variant='outlined'
              id='car_status_id'
              name='car_status_id'
              value={formik.values.car_status_id}
              onChange={formik.handleChange}
              label={t('car_status')}
            >
              <MenuItem value={1}>{t('new')}</MenuItem>
              <MenuItem value={2}>{t('very_good')}</MenuItem>
              <MenuItem value={3}>{t('good')}</MenuItem>
            </TextField>
          </Grid>
          <Grid
            container
            lg={12}
            md={12}
            className='flex p-2 justify-center space-x-2  '
            spacing={1}
          >
            <Grid item>
              <Button
                variant='outlined'
                color='primary'
                type='submit'
                // onClick={updateDriverInfo}
              >
                {t('submit')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='outlined'
                color='inherit'
                onClick={() => handleClose()}
              >
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default Form;
