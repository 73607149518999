import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteComment } from '../../../../Redux/store/places/placesListSlice';

import { setAlert } from '../../../../Redux/store/app/appSlice';
import { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { Delete } from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { usePlacesTranslation } from '../../i18n';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  cancel: {
    backgroundColor: '#575757',
    color: 'white',
    border: '2px',
    paddingLeft: '8px',
    paddingRight: '8px',
    '&:hover': {
      backgroundColor: '#383838',
      color: 'white',
      boxShadow: 'none',
    },
  },

  text: {
    fontSize: '18px',
    color: '#151515',
  },
  delete: {
    // '&:active': {},
    backgroundColor: '#FF392E',
    color: 'white',
    border: '2px',
    paddingLeft: '8px',
    paddingRight: '8px',
    '&:hover': {
      backgroundColor: '#9A0000',
      color: 'white',
      boxShadow: 'none',
    },
  },
  dialog: {},
}));

export default function DeleteDialog({ id, isRefreshing, setIsRefreshing }) {
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = useCallback(async () => {
    try {
      await dispatch(deleteComment({ id: id }));
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsRefreshing(isRefreshing + 1);
      setOpen(false);
    }
  });

  const t = usePlacesTranslation();

  return (
    <div>
      <ButtonGroup
        aria-label='outlined primary button group'
        onClick={handleClickOpen}
      >
        <Delete
          fontSize='small'
          color='error'
          className=' hover:bg-blue-200 hover:cursor-pointer'
        />
      </ButtonGroup>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle
          id='alert-dialog-title'
          style={{ color: '#FF392E', fontWeight: 'bold' }}
        >
          {t('delete')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            className={classes.text}
          >
            {t('delete_message')}!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('cancel')}</Button>
          <Button onClick={handleDelete} autoFocus color='error'>
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
