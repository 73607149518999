const events_en = {
  name: 'Name',
  mobile: 'Mobile',
  email: 'Email',
  actions: 'Actions',
  delete_provider_msg: 'Are you sure you want to delete this provider?',
  events: 'Events',
  events_providers: 'Events providers',
  add: 'Add',
  providers: 'Providers',
  about_provider: 'About provider',
  provider_events: `Provider's events`,
  provider_employees: `Provider's employees`,
  role: 'Role',
  employee: 'Empolyee',
  manager: 'Manager',
  delete_employee_msg: 'Are you sure you want to delete this emoloyee?',
  add_new: 'Add',
  date: 'Date',
  description: 'Description',
  add_new_employee: 'Add New Employee',
  name_is_required: 'Name is required',
  mobile_number_is_not_vaild: 'Mobile number is not vaild',
  cancle: 'Cancle',
  please_fill_all_the_places: 'Please fill all the places',
  edit_event: 'Edit Event',
  add_new_event: 'Add New Event',
  event_name_ar: 'Event name ar',
  event_name_en: 'Event name en',
  event_address_ar: 'Event address ar',
  event_address_en: 'Event address en',
  event_desc_en: 'Event desc en',
  event_desc_ar: 'Event desc ar',
  event_start_date: 'Event start date',
  event_end_date: 'Event end date',
  start_booking_at: 'Start booking at',
  end_booking_at: 'End booking at',
  select_images: 'Select images',
  edit_location: 'Edit location',
  select_location: 'Select location',
  edit_event_provider: 'Edit Event Provider',
  add_new_event_provider: 'Add New Event Provider',
  full_name_ar: 'Full name ar',
  full_name_en: 'Full name en',
  provider_logo: 'Provider logo',
  address: 'Address',
  all: 'All',
  deleted: 'Deleted',
  not_deleted: 'Not deleted',
  status: 'Status',
  apply: 'Apply',
  edit: 'Edit',
  delete_event_msg: 'Are you sure do you want to delete this event',
  all_tickets_qty: 'All tickets quantity',
  max_tickets_qty_per_user: 'Max tickets per user',
  enable_survey: 'Enable survey',
  survey: 'Survey',
  form: 'Form',
  add_event: 'Add Event',
  choose_provider_to_continue: 'Choose Provider To Continue',
  choose: 'Choose',
  sold_tickets_qty: 'Sold tickets quantity',
  remaining_tickets_qty: 'Remaining tickets quantity',
  new: 'New',
  canceled: 'Canceled',
  hidden: 'Hidden',
  start_booking: 'Start Booking',
  end_booking: 'End Booking',
  event_details: 'Event Details',
  show_location: 'Show Location',
  about_event: 'About Event',
  event_provider: 'Event Provider',
  event_address: 'Event Address',
  require_booking_time: 'Require Booking Time',
  event_note: 'Event Note',
  event_updated_successfuly: 'Event updated successfully',
  event_created_successfuly: 'Event created successfully',
  min_tickets_qty_per_booking: 'Min tickets quantity per booking',
  lemon_percentage: 'Lemon Percentage',
  event_images: 'Event Images',
  event_note: 'Event Note',
  latitude: 'Latitude',
  longitude: 'Longitude',
  ticket_name: 'Ticket name',
  ticket_price: 'Ticket price',
  tickets_qty: 'عدد التذاكر',
  cancel: 'Cancel',
  enabled: 'Enabled',
  disabled: 'Disabled',
  update_ticket_type: 'Update Ticket Type',
  create_new_ticket_type: 'Create New Ticket Type',
  ticket_type_name_en: 'Ticket type name en',
  ticket_type_name_ar: 'Ticket type name ar',
  tickets_qty: 'Tickets quantity',
  are_you_sure_you_want_to_delete: 'Are you sure you want to delete',
  disable_msg: 'Are you sure you want to disable this ticket',
  enable_msg: 'Are you sure you want to enable this ticket',
  deleted_successfully: 'Deleted Successfully',
  done_successfully: 'Done Successfully',
  event_tickets: 'Event Tickets',
  updated_successfully: 'Updated Successfully',
  created_successfully: 'Created Successfully',
  show_images: 'Show Images',
  start_booking_msg: 'Are you sure you want to start booking to this event?',
  cancel_event_msg: 'Are you sure you want to cancel this event?',
  hide_event_msg: 'Are you sure you want to hide this event?',
  hide: 'Hide',
  starting_booking: 'Start booking',
  ending_booking: 'End booking',
  end_booking_msg: 'Are you sure you want to end this event?',
  events_list: 'Events List',
  event_status: 'Event Status',
  ticket_number: 'Ticket Number',
  user_name: 'User Name',
  user_mobile: 'User Mobile',
  ticket_type: 'Ticket Type',
  booking_time: 'Booking Time',
  created_at: 'Created At',
  view_qr: 'View QR',
  confirmd: 'Confirmd',
  consumed: 'Consumed',
  events_tickets: 'Events Tickets',
  from: 'From',
  to: 'To',
  event_id: 'Event ID',
  purchased_from_app: 'From App',
  event_tickets_types: 'Event Tickets Types',
  add_event_to_place: 'Add event to a place',
  choose_event_to_continue: 'Choose event to link it to the place',
  event_date: 'Event Date',
  start_at: 'Start At',
  end_at: 'End At',
  booking_date: 'Booking Date',
  user_info: 'User Info',
};

export default events_en;
