import React from 'react';
import {
  DataGrid,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';
import { formatDate } from '../../utils';
import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { Empty } from './Empty';
import { useSelector } from 'react-redux';
import { Rating } from '@material-ui/lab';
import default_image from '../../../../assets/images/defult-pic.png';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Link, useNavigate } from 'react-router-dom';

import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useDriverTranslation } from '../../i18n';
import { useTranslation } from 'react-i18next';
import { appDirection } from '../../../../shared_utils';
import { useDispatchTranslation } from '../../../dispatches/i18n';

// avatar style
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

function CustomPagination(loading) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid
      container
      className=' flex justify-center relative space-x-4 py-4 w-full'
      spacing={1}
    >
      <Grid item>
        <Button
          disabled={page === 0 || loading ? true : false}
          variant='contained'
          color='primary'
          onClick={() => apiRef.current.setPage(page - 1)}
        >
          {t('previous')}
        </Button>
      </Grid>

      <Grid item>
        <Button
          disabled={loading}
          variant='contained'
          color='primary'
          onClick={() => apiRef.current.setPage(page + 1)}
        >
          {t('next')}
        </Button>
      </Grid>
      <Grid item>
        {loading ? (
          <CircularProgress
            className={
              theme.direction === 'ltr' ? 'absolute right-8' : 'absolute left-8'
            }
          />
        ) : (
          <span
            className={
              '' + theme.direction === 'ltr'
                ? 'p-2 px-4 rounded-full bg-gray-300  font-bold absolute right-8'
                : 'p-2 px-4 rounded-full bg-gray-300  font-bold absolute left-8'
            }
          >
            {page + 1}
          </span>
        )}
      </Grid>
    </Grid>
  );
}

function CustomToolbar() {
  return <></>;
}

const DispatchesTable = ({ data, setPage, loading, Ikey, page }) => {
  const classes = useStyles();
  const { driver, carTypes } = useSelector(
    (state) => state.drivers
  ).driversList;
  const { dispatches } = useSelector(
    (state) => state.dispatches
  ).dispatchesList;
  //dialog
  const [open, setOpen] = React.useState(false);
  const t = useDispatchTranslation();

  const [selectedTransaction, setSelectedTransaction] = React.useState({});

  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/dispatches/${id}`);
  };

  const handleDriver = (data) => {
    if (data.driver === null) {
      return {};
    } else {
      return data.driver;
    }
  };

  const cols = [
    {
      field: 'DriverInfo',
      headerName: t('driver_info'),
      headerClassName: ' bg-gray-100  ',

      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Link to={`/drivers/${params.value?.identity_id}/identity`}>
          <Grid
            container
            lg={12}
            md={12}
            xs={12}
            spacing={2}
            className={
              Ikey === 'all' && params.value.driver_id
                ? 'text-xs p-2 text-[#1769aa] cursor-pointer'
                : 'text-xs p-3'
            }
            // onClick={() => {
            //   if (params.value.driver_id && Ikey === 'all') {
            //     navigate(`/drivers/${params.value.identity_id}/identity`);
            //   }
            // }}
          >
            <Grid item lg={2} md={2}>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                className='flex justify-center'
              >
                <Avatar
                  src={params.value.profile_image || default_image}
                  className={classes.small}
                />
              </Grid>
            </Grid>
            <Grid item lg={8} md={8}>
              <Grid item lg={12} md={12} xs={12}>
                {params.value.full_name}
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                {params.value.mobile_number}
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Rating size='small' readOnly value={params.value.avg_rating} />
              </Grid>
            </Grid>
          </Grid>
        </Link>
      ),
    },

    {
      field: 'ClientInfo',
      headerName: t('client_info'),
      headerClassName: ' bg-gray-100  ',

      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Link to={`/users/${params.value.client_id}`}>
          <Grid
            container
            lg={12}
            md={12}
            xs={12}
            spacing={2}
            className='text-xs p-1 text-[#1769aa] cursor-pointer'
            // onClick={() => navigate(`/users/${params.value.client_id}`)}
          >
            <Grid item lg={8} md={8}>
              <Grid item lg={12} md={12} xs={12}>
                <AccountCircleIcon fontSize='small' />{' '}
                {params.value.client_name}
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <PhoneAndroidIcon fontSize='small' />{' '}
                {params.value.client_mobile}
              </Grid>
            </Grid>
          </Grid>
        </Link>
      ),
    },

    {
      field: 'DispatchInfo',
      headerName: t('dispatch_status'),
      headerClassName: ' bg-gray-100 ',
      disableColumnMenu: true,
      width: 130,
      renderCell: (params) => (
        <Grid container lg={12} md={12} xs={12}>
          <Grid item lg={12} md={12} xs={12}>
            <Grid item lg={2} md={2} className='text-xs'>
              {params.value.status.status_name_en}
            </Grid>
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'carType',
      headerName: t('car_type'),
      headerClassName: ' bg-gray-100 ',
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid container lg={12} md={12}>
          <span className=' text-xs text-gray-700 font-mono font-semibold w-full text-center'>
            {params.value.car_type.car_name_en}
          </span>
        </Grid>
      ),
    },
    {
      field: 'Distance',
      headerName: t('distance'),
      headerClassName: ' bg-gray-100 ',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid container lg={12} md={12} className='text-xs'>
          <span className='p-2 text-gray-700'>
            {params.value.distance_km}
            <span className='font-bold text-xs'> KM</span>{' '}
          </span>
        </Grid>
      ),
    },
    {
      field: 'PaidInfo',
      headerName: t('paid_info'),
      headerClassName: ' bg-gray-100 ',
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid
          container
          justifyContent='center'
          lg={12}
          md={12}
          xs={12}
          className='text-xs'
        >
          <Grid item lg={12} md={12} xs={12} className=' rounded-xl '>
            {params.value.total_price}{' '}
            <Typography
              variant='caption'
              className=' font-extrabold text-gray-700'
            >
              {t('SDG')}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <LocalAtmIcon fontSize='small' className=' text-gray-500' />{' '}
            {params.value.paid_cash}{' '}
            <Typography
              variant='caption'
              className=' font-extrabold text-gray-700'
            >
              {t('SDG')}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <AccountBalanceWalletIcon
              fontSize='small'
              className='text-gray-500'
            />{' '}
            {params.value.paid_balance}{' '}
            <Typography
              variant='caption'
              className=' font-extrabold text-gray-700'
            >
              {t('SDG')}
            </Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'dates',
      headerName: t('dates_info'),
      headerClassName: ' bg-gray-100 ',
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Grid container lg={12} md={12} className='text-xs'>
          <Grid item lg={12} md={12} className='p-2 text-gray-700 text-center'>
            {formatDate(params.value.created_at)}
          </Grid>
          <Grid item lg={12} md={12} className='p-2 text-gray-700 text-center'>
            {formatDate(params.value.updated_at)}
          </Grid>
        </Grid>
      ),
    },
    {
      //TODO:
      field: 'action',
      headerName: t('view'),
      headerClassName: ' bg-gray-100 ',
      // flex: 1,
      width: 50,
      renderCell: (params) => (
        <Link to={`/dispatches/${params.value.dispatch_id}`}>
          <span className='w-full flex justify-center'>
            <VisibilityOutlinedIcon
              fontSize='small'
              className=' cursor-pointer text-blue-700'
              // onClick={() => handleClick(params.value.dispatch_id)}
            />
          </span>
        </Link>
      ),
    },
  ];

  const rowsData = dispatches.map((data) => {
    return {
      id: data.dispatch_id,
      DriverInfo: handleDriver(data),
      ClientInfo: data,
      DispatchInfo: data,
      carType: data,
      Distance: data,
      PaidInfo: data,
      dates: data,
      action: data,
    };
  });

  return (
    <div className='flex h-auto' dir={appDirection}>
      <div className=' flex-grow'>
        <DataGrid
          className='bg-white'
          disableColumnFilter
          autoHeight
          rowHeight={70}
          loading={loading}
          rows={rowsData}
          columns={cols}
          page={page}
          pageSize={1}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => setPage(newPage)}
          CellStyle='{StaticResource DataGridBorder}'
          components={{
            Toolbar: CustomToolbar,
            Pagination: () => CustomPagination(loading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: Ikey === 'all' ? LoadingIndicator : LinearProgress,
          }}
        />
      </div>
      {/* <ReteveDialog
        open={open}
        setOpen={setOpen}
        transaction={selectedTransaction}
      /> */}
    </div>
  );
};

export default DispatchesTable;
