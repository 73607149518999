import React from 'react';
import { Provider } from 'react-redux';
import eventsStore from '../../../core/app/store';

import UpdateEvent from '../components/UpdateEvents';

export default function UpdateEventPage() {
  return (
    <Provider store={eventsStore}>
      <UpdateEvent />
    </Provider>
  );
}
