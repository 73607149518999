import React, { useCallback, useEffect, useState } from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import './Map.css';
import L from 'leaflet';
import polyline from '@mapbox/polyline';
import http1 from '../../../../../services/APIs/dispatch_api';
import { getTripActualRoute } from '../../../../../Redux/store/app/appSlice';

const Map = () => {
  const { dispatch } = useSelector((state) => state.dispatches).dispatchesList;
  const { tripActualRoute } = useSelector((state) => state.app).app;

  const [isLocated, setIsLocated] = useState(false);
  const [driverLocation, setDriverLocation] = useState({
    driver_id: '',
    accuracy: 0,
    speed: 0,
    bearing: 0,
    longitude: 0,
    latitude: 0,
    provider: '',
    updated_at: '',
  });

  const [actualRoute, setActualRoute] = useState(null);

  const fillGreenOptions = { color: '#007200' };
  const limeOptions = { color: 'lime' };
  const redOptions = { color: '#d90429' };

  const dropoff = new L.Icon({
    iconUrl: require('../../../../../assets/svg/dropoff.png'),
    iconSize: [35, 40],
    iconAnchor: [17, 26],
  });
  const car = new L.Icon({
    iconUrl: require('../../../../../assets/svg/PngItem_1509028.png'),
    iconSize: [20, 35],
    iconAnchor: [17, 0],
  });

  const fetchDriverLocation = useCallback(async () => {
    try {
      const res = await http1.get(
        `/dashboard/drivers/location/${dispatch.driver_id}`
      );

      if (res.data.location != null) {
        setIsLocated(true);
        setDriverLocation({
          driver_id: res.data.location.driver_id,
          accuracy: res.data.location.accuracy,
          speed: res.data.location.speed,
          bearing: res.data.location.bearing,
          longitude: res.data.location.longitude,
          latitude: res.data.location.latitude,
          provider: res.data.location.provider,
          updated_at: res.data.location.updated_at,
        });
      } else {
        setIsLocated(false);
      }
    } catch (err) {
      console.error(err);
    }
  });

  const reduxDispatch = useDispatch();

  const fetchActualRoute = useCallback(async () => {
    try {
      await reduxDispatch(getTripActualRoute({ id: dispatch.dispatch_id }));
      //6581580790335799296
    } catch (err) {
      console.error(err);
    }
  });

  useEffect(() => {
    if (dispatch.driver_id) {
      fetchDriverLocation();
      const interval = setInterval(() => {
        fetchDriverLocation();
      }, 15000);

      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    fetchActualRoute();
  }, []);

  useEffect(() => {
    const route = tripActualRoute.map((value) => {
      return [value.latitude, value.longitude];
    });
    setActualRoute(route);
  }, [tripActualRoute]);

  return (
    <MapContainer
      center={[dispatch.pick_up_latitude, dispatch.pick_up_longitude] || []}
      zoom={11}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {/* pickup */}
      <Marker
        position={[dispatch.pick_up_latitude, dispatch.pick_up_longitude] || []}
      >
        <Popup closeButton={false}>Pickup</Popup>
      </Marker>
      {dispatch.route_ref && (
        <Polyline
          dashArray='5 10'
          pathOptions={fillGreenOptions}
          positions={polyline.decode(dispatch.route_ref)}
        />
      )}
      {/* driver marker  */}
      {dispatch.driver_id && isLocated && (
        <Marker
          position={[driverLocation.latitude, driverLocation.longitude]}
          icon={car}
        />
      )}
      {/* dropoff */}
      <Marker
        position={
          [dispatch.drop_off_latitude, dispatch.drop_off_longitude] || []
        }
        icon={dropoff}
      >
        <Popup closeButton={false}>Dropoff</Popup>
      </Marker>

      {actualRoute ? (
        <Polyline pathOptions={redOptions} positions={actualRoute} />
      ) : (
        console.log(null)
      )}
    </MapContainer>
  );
};

export default Map;
