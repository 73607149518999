const driver_ar = {
  next: 'التالي',
  previous: 'السابق',
  low: 'منخفض',
  medium: 'متوسط',
  high: 'مرتفع',
  critical: 'حرج',
  date: 'التاريخ',
  dates: 'التاريخ',

  driver_info: 'معلومات السائق',
  account_info: 'معلومات الحساب',
  car_info: 'معلومات السيارة',
  Drivers_status: 'الحالة',
  all: 'الكل',
  blocked: 'محظور',
  blocked_by_payment: ' محظور من الدفع',
  temporary_blocked: 'محظور مؤقتا',
  car_type: 'نوع السيارة',
  fees_groups: 'مجموعة الرسوم',
  mobile: 'رقم الهاتف',
  user_mobile: 'رقم هاتف العميل',
  drivers: 'السائقين',
  drivers_list: 'قائمة السائقين',
  driver_details: 'التفاصيل',
  details: 'التفاصيل',
  dispatches: 'الديسباتش',
  balance: 'الرصيد',
  files: 'ملفات',
  statistics: 'إحصائيات',
  performance: 'أداء',
  tickets: 'تذاكر',
  notes: 'مذكرة',
  orders: 'طلبات',

  driver_id: 'رقم هوية السائق',
  driver_info: 'معلومات السائق',
  mobile_number: 'رقم الهاتف',
  driver_license: 'رخصة السائق',
  national_id: 'الرقم الوطني',
  referral_number: ' رقم الإحالة',
  device_os: 'نظام التشغيل',
  plate_number: 'رقم اللوحة',
  color: 'اللون',
  car_model: 'موديل السيارة',
  car_status: 'الحالة',
  manufacture_date: 'تاريخ التصنيع',
  vehicle_type: 'نوع السيارة',
  update_info: 'تحديث المعلومات',
  send_request: 'تحديث السبب',
  account_info: 'معلومات الحساب',
  reason: 'الأسباب',
  activate: 'تنشيط',
  reject: 'حظر',
  details: 'التفاصيل',
  files: 'الملفات',
  name: 'الإسم',
  submit: 'تطبيق',
  apply: 'تطبيق',
  cancel: 'إلغاء',
  update_reason: 'تعديل السبب',
  block_dialog: 'تنشيط حساب السائق',
  activate_driver: 'تنشيط حساب السائق',
  new: 'جديد',
  very_good: 'جيد جدا',
  good: 'جيد',
  parent_category: 'التصنيف الرئيسي',
  child_category: ' التصنيف الفرعي',
  priority: 'أفضليه',
  description: 'تفاصيل',
  cancel: 'إلغاء',
  update_ticket: 'تعديل التذكرة',
  update_status: 'تعديل الحاله',
  notify_user: 'إخطار المستخدم',
  add_note: 'أضف ملاحظة',
  create: 'إنشاء',
  note: 'مذكرة',
  create_ticket: 'إنشاء تذكرة',
  new_ticket: 'تذكرة جديدة',
  supervisor_note: 'مذكرة المشرف',
  temporary_block: 'حظر مؤقت',
  block: 'حظر',
  unblock: 'إلغاء الحظر',
  block_dialog: 'تنشيط حساب السائق',
  unblock_date: 'تاريخ الإلغاء',
  reference_type: 'نوع المرجع ',
  // reference_type: '',
  new_otp: ' otp جديد',
  amount: 'المبلغ',
  narration: 'وصف',
  created_at: 'تم الإنشاء',
  total_balance: 'الرصيد الكلي',
  transactions: 'التحويلات',
  SDG: 'جنيه',
  update: 'تحديث',
  add_balance: 'اضافة رصيد',
  deduct_balance: 'خصم رصيد',
  status: 'الحاله',
  revert: 'عكس ',
  avatar_image: 'الصورة الشخصية',
  id_image: 'صورة الهوية',
  car_inside_image: 'صور السيارة من الداخل',
  car_outside_image: 'صور السيارة من الخارج',
  driver_license_image: 'صور الرخصة',
  car_search_certificate_image: 'صور شهادة البحث',
  emergency_transport_license_image: 'صور ترخيص النقل في حالات الطوارئ',
  total: 'إجمالي',
  from: 'من',
  to: 'الى',
  client_info: 'معلومات العميل',
  dispatch_status: 'حالة الديسباش',
  distance: 'المسافه',
  paid_info: 'معلومات الدفع',
  SDG: 'جنيه',
  dates_info: 'بيانات التاريخ',
  action: 'عملية',
  note: 'مذكرة',
  supervisor_name: 'اسم الشرف',
  daily: 'يوميا',
  weekly: 'إسبوعيا',
  acceptance_rate: 'معدل القبول',
  active_time: 'نشط',
  rating: 'تقييم',
  guarantees: 'ضمانات',
  bonus: 'حافز',
  ignored_dispatches: 'متجاهل',
  canceled_dispatches: 'ملغي',
  completed_dispatches: 'مكتمل',
  view: 'عرض',
  transaction_ID: 'معرف',
  role: 'عرض',
  msg: ' إرجاع الرصيد',
  online: 'أونلاين',
  active: 'نشط',
  available: 'متاح',
  msg_success: 'تم التعديل',
  msg_unblock: 'تم إلغاء الحظر',
  msg_block: 'تم الحظر',
  msg_note: 'تمت إضافة التذكرة',
  msg_add: 'تمت إضافة للرصيد بنجاح',
  msg_deduct: 'تم الخصم من الرصيد بنجاح',
  // CAR_INFO: 'السيارة',
  // CAR_SEATS_COUNT: 'عدد مقاعد السيارة',
  // CAR_MODEL_YEAR: 'موديل العربة',
  // CAR_DESC: 'وصف العربة',
  // CREATED_AT: 'تاريخ التسجيل',
  // PERSONAL_INFO_TAB: 'البيانات الشخصية',
  // DISPATCHES_TAB: 'الدسباتش',
  // DRIVER_BALANCE_TAB: 'رصيد السائق',
  // PERFORMANCE_TAB: 'الأداء',
  // DRIVER_FILES_TAB: 'ملفات السائق',
  // DRIVER_LICENCE: 'رخصة السائق',
  // NATIONAL_ID: 'الوثيقة الوطنية',
  // DEVICE_ID: 'معرف الجهاز',
  // COMPLETED_ORDER_COUNT: 'عدد الطلبات المكتملة',
  // BLOCKED_SWITCH: 'محظور',
  // ONLINE_SWITCH: 'متصل',
  // ACTIVE_SWITCH: 'مفعل',
  // BLOCK_BTN: 'حظر',
  // UN_BLOCK_BTN: 'الغاء الحظر',
  // BACK_BTN: 'رجوع',
  // TOTAL_PRICE: 'السعر الكلي',
  // DISTANCE: 'المسافة بالكيلو',
  // STATUS: 'الحالة',
  // AMOUNT: 'الكمية',
  // NARRATION: 'توضيح',
  // REFERENCE_CODE: 'رقم العملية',
  // DONE: 'تم',
  // REFERENCE_TYPE: 'البند',
  // BALANCE_CREATED_AT: 'تاريخ الانشاء',
  // TOTAL_BALANCE: 'المبلغ الكلي',
  // ADD_BALANCE: 'اضافة رصيد',
  // DEDUCT_BALANCE: 'خصم رصيد',
  // ACCEPTANCE_RATE: 'معدل القبول',
  // VIEWED_DISPATCHES: 'الدسباتش التي شوهدت',
  // ACCEPTED_DISPATCHES: 'الدسباتش المقبولة',
  // COMPLETED_DISPATCHES: 'الدسباتش المكتملة',
  // COMPLETED_DISPATCHES_AMOUNT: 'كمية الدسباتش المكتملة',
  // CANCELED_DISPATCHES: 'الدسباتش الملغية',
  // BONUSES_AMOUNT: 'الكمية الزائدة',
  // TOTAL_BONUSES_AMOUNT: 'مجموع الكمية الزائدة',
  // GUARANTEED_AMOUNT: 'الكمية المطلوبة',
  // TOTAL_GUARANTEED_AMOUNT: 'مجموع الكمية المطلوبة',
  // TOTAL_EARNINGS: 'مجموع التحصيل',
  // FROM: 'من',
  // TO: 'إلى',
  // GET_PERFORMANCE: 'احصل على الأداء',
  // GET_WEEKLY_PERFORMANCE: 'احصل على الأداء الأسبوعي',
  // GET_HOURLY_PERFORMANCE: 'احصل على الأداء بالساعة',
  // BASIC_PERFORMANCE: 'الأداء الأساسي',
  // WEEKLY_PERFORMANCE: 'الأداء الاسبوعي',
  // HOURLY_PERFORMANCE: 'الأداء بالساعة',
  // PAID_CASH: 'المبلغ المدفوع',
  // PAID_BALANCE: 'الرصيد المدفوع',
  // HOUR: 'الساعة',
  // DAY: 'اليوم',
  // NO_FILES: 'لا ملفات',
  // UPDATE_DRIVER: 'تعديل البيانات',
  // UPDATE_CAR_INFO: 'تعديل بيانات العربة',
  // REASON: 'السبب',
  // BASIC_INFO: 'المعلومات الأساسية',
  // CLIENT_INFO: 'معلومات العميل',
  // PRICE_INFO: 'معلومات الدفع',
};

export default driver_ar;
