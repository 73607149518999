const dispatch_ar = {
  dispatches: 'ديسباتش',
  dispatches_list: 'قائمة الديسباتش',
  dispatch_details: 'تفاصيل الديسباتش ',
  dispatch_drivers: 'سائقو الديسباتش',
  details: 'التفاصيل',
  transactions: 'إجراءات',
  tracking: 'تتبع',
  manual_dispatch: 'كتيب',
  completed: 'اكتمل',
  picked: 'تم التحرك',
  arrived: 'السائق ينتظر',
  ending_date: 'زمن النهاية',
  starting_date: 'زمن البداية',
  driver_note: 'ملاحظة السائق',
  client_mobile: 'رقم هاتف العميل',
  client: 'العميل',
  peak_rate: 'معدل الذروة',
  distance: 'المسافة',
  order_price: 'سعر الطلب',
  SDG: 'جنيه',
  paid_balance: 'المدفوع كرصيد',
  paid_cash: 'المبلغ كاش',
  delivery_price: 'سعر التوصيل',
  price: 'السعر',
  driver: 'السائق',
  to: 'الى',
  from: 'من',
  pending: 'معلق',
  accepted: 'مقبول',
  timeout: 'لا يوجد سائق',
  canceled: 'ملغي',
  canceled_client: 'تم الإلغاء عن طريق العميل',
  canceled_driver: 'تم الإلغاء عن طريق السائق',
  canceled_support: 'تم الإلغاء عن طريق الدعم الفني',
  on_going: 'جاري',
  not_show: 'غير ظاهر',
  status_unknown: 'الحالة غير معروف',
  dates: 'التاريخ',
  driver_info: 'معلومات السائق',
  account_info: 'معلومات الحساب',
  client_info: 'معلومات العميل',
  dispatch_status: 'حالة الديسباتش',
  distance: 'المسافه',
  paid_info: 'معلومات الدفع',
  SDG: 'جنيه',
  dates_info: ' التاريخ',
  car_type: 'نوع السيارة',

  supervisor: 'المشرف',
  admin: 'المضيف',
  system: 'النظام',
  status: 'الحالة',
  created_at: 'تاريخ الإنشاء',
  created_by: 'انشئ بواسطة',
  confirm: 'تأكيد',
  cancel: 'إلغاء',
  status_change: 'تغيير الحالة',
  status_msg: 'هل ترغب في تغيير الحالة الى',
  updated_at: 'تاريخ التحديث',
  location: 'الموقع',
  driver_id: 'رقم هوية السائق',
  action: 'الحاله',
  view: 'عرض',
  KM: 'كلم',
  eligible: 'مؤهل',
  dispatched: 'تم الإرسال',
  received: ' تم الإستلام',
  viewed: 'تم رؤيتها',
  dispatch_transactins: 'إجراءات الديسباتش',
};

export default dispatch_ar;
