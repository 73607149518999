import React from 'react';
import { Grid } from '@mui/material';
import PageHeading from '../../../components/common/PageHeading';
import { useState, useEffect } from 'react';
import CarsList from '../components/common/CarsList';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCarTypes } from '../../../Redux/store/cars/carsListSlice';
import { setAlert } from '../../../Redux/store/app/appSlice';
import { useCarsTranslation } from '../../../dispatch/views/cartypes/i18n';

const CarsTypesPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const t = useCarsTranslation();

  const [isRefreshing, setIsRefreshing] = useState(0);

  const { carTypes } = useSelector((state) => state.cars).carsList;
  // console.table( carTypes)

  const dispatch = useDispatch();

  const fetchCarTypesData = async () => {
    try {
      setIsLoading(true);
      await dispatch(fetchCarTypes());
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCarTypesData();
  }, [isRefreshing]);

  return (
    <Grid container lg={12} md={12} spacing={2} justifyContent='center'>
      <Grid item lg={12} md={12}>
        <PageHeading
          heading1={t('cars')}
          heading2={t('cars_list')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>
      <Grid item lg={12} md={12}>
        {/* <ListFiler
          setIsRefreshing={setIsRefreshing}
          isRefreshing={isRefreshing}
        /> */}
        <CarsList data={carTypes} setPage={setPage} isLoading={isLoading} />
      </Grid>
    </Grid>
  );
};

export default CarsTypesPage;
