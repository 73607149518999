import http from '../http';

export const getAllEventsProviders = async (pageNum, pageSize, isDeleted) => {
  try {
    const { data } = await http.get(
      `/dashboard/events_providers?page_number=${pageNum}&page_size=${pageSize}&is_deleted=${isDeleted}`
    );

    return data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
};
