import React, { useState, useCallback, useEffect } from 'react';
import { Grid, TextField, Button, Input, makeStyles, Divider } from '@material-ui/core';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../../Redux/store/app/appSlice';
// import { putCommentInfo } from '../../../../Redux/store/places/placesListSlice';
import { useRef } from 'react';
import {
  createNewTrip,
  removeItemSuccess,
  fetchOrdersSuccess,
} from '../../../Redux/store/shareTrip/shareTripListSlice';
import LoadingIndicator from '../../../components/common/LoadingIndicator';

const useStyles = makeStyles({
  label: {
    fontStyle: 'italic',
    fontWeight: '600',
    fontSize: '16px',
  },
  orderId: {
    color: '#E6B400',
    marginTop: '2px',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  distance: {
    color: 'red',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  button: {
    color: '#398AD7',
    fontFamily: 'italic',
    fontSize: '16px',
  },
});

function OrderForm({ setRemoveOrderId, isLoading, setIsLoading, open, setOpen }) {
  const dispatch = useDispatch();

  const classes = useStyles();

  // const [isLoading, setIsLoading] = useState(false);

  const { trips, pointsList } = useSelector((state) => {
    return state.shareTrip.shareTripList;
  });

  const [sumPrices, setSumedPrices] = useState(0);
  const [sumDistances, setSumedDistances] = useState(0);
  const [sumMinutes, setSumedMinutes] = useState(0);
  const [test, setTest] = useState({
    orders: [
      { action: 'pickup', order_id: '6983174037232943104' },
      { action: 'pickup', order_id: '6982692531397984256' },
      { action: 'dropoff', order_id: '6983174037232943104' },
      { action: 'dropoff', order_id: '6982692531397984256' },
    ],
  });
  // const [test, setTest] = useState(false);

  // const isInitialMount = useRef(true);

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      const newArray = pointsList.map(
        ({ full_name, id, distance_km, price, estimated_duration, lat, long, ...rest }) => rest
      );
      const arr = { orders: [] };
      for (let i = 0; i < newArray.length; i++) {
        arr.orders.push(newArray[i]);
      }
      await dispatch(createNewTrip(arr));
      await dispatch(removeItemSuccess([]));
      setRemoveOrderId(test);
      setOpen(false);
    } catch (err) {
      console.error(err);
    } finally {
      // setIsRefreshing(isRefreshing + 1);
      setIsLoading(false);
    }
  });

  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //   } else {
  //     updatePlaceFrom();
  //     // console.log(options);
  //   }
  // }, [test]);

  // console.log(pointsList);
  // console.log(trips);

  useEffect(() => {
    const priceResult = pointsList.reduce(
      (total, currentValue) => (total = total + currentValue.price),
      0
    );
    const distResult = pointsList.reduce(
      (total, currentValue) => (total = total + currentValue.distance_km),
      0
    );
    const durationResult = pointsList.reduce(
      (total, currentValue) => (total = total + currentValue.estimated_duration),
      0
    );
    setSumedPrices(priceResult);
    setSumedDistances(distResult);
    setSumedMinutes(durationResult);
  }, [sumPrices, sumDistances, sumMinutes]);

  const formik = useFormik({
    initialValues: pointsList,
  });

  return (
    <Grid container lg={12} md={12}>
      {/* <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(formik.values);
        }}
      > */}
      <Grid container lg={12} md={12} spacing={2}>
        <Grid container className='border-b-2 border-gray-600' lg={12} md={12}>
          <Grid item lg={6} md={6}>
            <div className=' inline pr-2 text-base font-semibold italic'>Total Price</div>
            <div className='inline text-gray-400'>{sumPrices} SDG</div>
          </Grid>

          <Grid item lg={6} md={6}>
            <div className='inline pr-2 text-base font-semibold italic'>Total Distance</div>
            <div className='inline text-gray-400'>{sumDistances} Km</div>
          </Grid>

          <Grid item lg={6} md={6}>
            <div className='inline pr-2 text-base font-semibold italic'>Estimated Time</div>
            <div className='inline text-gray-400'>{sumMinutes} min</div>
          </Grid>

          <Grid item lg={6} md={6}>
            <label className='pr-3 text-base font-semibold italic'>Prices Adjustment</label>

            <input
              type='checkbox'
              disabled
              label='Prices Adjustment'
              id='priceAdjustment'
              name='priceAdjustment'
              variant='standard'
              onChange={formik.handleChange}
              value={formik.values.comment}
            />
          </Grid>
        </Grid>
        <Grid container lg={12} md={12}>
          {pointsList.length !== 0
            ? pointsList.map((order) => {
                if (order.action == 'pickup')
                  return (
                    <Grid container className='mt-2 pb-2' lg={12} md={12}>
                      <Grid item className={classes.orderId} lg={6} md={6}>
                        #{order.order_id}
                      </Grid>
                      <Grid item className={classes.distance} lg={6} md={6}>
                        {/* {order.distance_km} SDG */}
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <label className='pr-2 text-base font-semibold italic'>Distance</label>

                        <TextField
                          disabled
                          // label='Distance'
                          id='Distance'
                          name='Distance'
                          variant='standard'
                          onChange={formik.handleChange}
                          value={order.distance_km}
                        />
                      </Grid>

                      <Grid item lg={6} md={6}>
                        <label className='px-2 text-base font-semibold italic'>Price</label>

                        <TextField
                          disabled
                          // label='Price'
                          id='Price'
                          name='Price'
                          variant='standard'
                          onChange={formik.handleChange}
                          value={order.price}
                        />
                      </Grid>
                    </Grid>
                  );
              })
            : ''}
        </Grid>
        {/* </Grid> */}
        <Grid item lg={12} md={12} className=' flex justify-center py-5'>
          <Button
            variant='contained'
            className={classes.button}
            type='submit'
            onClick={() => handleSubmit()}>
            Create Trip
          </Button>
        </Grid>
      </Grid>
      {/* </form> */}
    </Grid>
  );
}

export default OrderForm;
