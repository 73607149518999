const common_en = {
  update_msg: 'Are you sure you want to update?',
  dispatching_time: 'Dispatching Time',
  time_value_in_sec: 'Time value in sec',
  DISPATCH_DELAY: 'Dispatch Delay',
  DISPATCH_LOCK_DURATION_IN_SEC: 'Dispatch lock duration in sec',
  MANUAL_DISPATCH_DELAY: 'Manual dispatch delay',
  MANUAL_DISPATCH_LOCK_DURATION_IN_SEC: 'Manual dispatch lock duration in sec',
  FOOD_DISPATCH_DELAY: 'Food dipatch delay',
  LAST_DELAY: 'Last delay',
  action: 'Action',
};

export default common_en;
