import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageHeading from '../../../components/common/PageHeading';
import {
  fetchUsers,
  setError,
} from '../../../Redux/store/users/usersListSlice';
import ListFiler from '../components/common/ListFilters';
import UsersList from '../components/UsersList';
import { useUsersTranslation } from '../i18n';

const UsersListPage = () => {
  // component state
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(0);

  // component data
  const { users, listPayload } = useSelector((state) => state.users).usersList;
  const t = useUsersTranslation();
  //redux
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams(
    createSearchParams({
      page_number: 0,
      page_size: 10,
    })
  );

  const currentSearchParams = useMemo(
    () => ({
      page_number: searchParams.get('page_number'),
      page_size: searchParams.get('page_size'),
      ...listPayload,
    }),
    [searchParams, listPayload]
  );

  const setPage = (newPage) => {
    setSearchParams({
      ...currentSearchParams,
      page_number: newPage,
    });
    setIsRefreshing((prev) => prev + 1);
  };

  const fetchUsersData = async () => {
    try {
      setIsLoading(true);
      await dispatch(fetchUsers(currentSearchParams));
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, [isRefreshing]);

  return (
    <Grid container lg={12} md={12} spacing={2} justifyContent='center'>
      <Grid item lg={12} md={12}>
        <PageHeading
          heading1={t('users')}
          heading2={t('users_list')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>

      <Grid item lg={12} md={12} className=' bg-gray-100 '>
        <ListFiler
          setIsRefreshing={setIsRefreshing}
          isRefreshing={isRefreshing}
        />
      </Grid>
      <Grid item lg={12} md={12}>
        <UsersList
          data={users}
          page={Number(currentSearchParams.page_number)}
          setPage={setPage}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
};

export default UsersListPage;
