import React from 'react';
import { AccessAlarm } from '@mui/icons-material';
import { Done, Star } from '@mui/icons-material';
import { Rating } from '@mui/material';
import { useDriverTranslation } from '../../i18n';
import { appDirection } from '../../../../shared_utils';

const PerformanceCards = ({ performance }) => {
  const t = useDriverTranslation();
  return (
    <div class='mt-4' dir={appDirection}>
      <div class='flex flex-wrap -mx-6'>
        <div className='w-full px-6 sm:w-1/2 xl:w-1/3'>
          <div className='flex items-center px-5 py-4 shadow-xl rounded-md bg-white'>
            <div className='p-3 rounded-full bg-[#1565c0] bg-opacity-75'>
              <Done className='text-white' />
            </div>

            <div className='mx-5'>
              <h4 className='text-2xl font-semibold text-gray-700'>
                {performance.acceptance_rate || '---'}%
              </h4>
              <div className='text-gray-500'>{t('acceptance_rate')}</div>
            </div>
          </div>
        </div>
        <div className='w-full px-6 sm:w-1/2 xl:w-1/3'>
          <div className='flex items-center px-5 py-4 shadow-xl rounded-md bg-white'>
            <div className='p-3 rounded-full bg-[#1565c0] bg-opacity-75'>
              <AccessAlarm className='text-white ' />
            </div>

            <div className='mx-5'>
              <h4 className='text-2xl font-semibold text-gray-700'>
                {performance.duration_formatted}
              </h4>
              <div className='text-gray-500'>{t('active_time')}</div>
            </div>
          </div>
        </div>
        <div className='w-full px-6 sm:w-1/2 xl:w-1/3'>
          <div className='flex items-center px-5 py-4 shadow-xl rounded-md bg-white'>
            <div className='p-3 rounded-full bg-[#1565c0] bg-opacity-75'>
              <Star className='text-white' />
            </div>

            <div className='mx-5'>
              <Rating size='large' readOnly value={performance.avg_rating} />

              <div className='text-gray-500'>{t('rating')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceCards;
