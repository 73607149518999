import axios from 'axios';
import storage from '../../storage';

const http = axios.create({
  baseURL: process.env.REACT_APP_IDENTITY_API,
  headers: {
    'Content-Type': 'application/json',
    'x-lemon-client-id': process.env.REACT_APP_CLIENT_ID,
    'x-lemon-client-secret': process.env.REACT_APP_CLIENT_SECRET,
    Authorization: process.env.REACT_APP_AUTH_ACCESS_TOKEN,
  },
});

http.interceptors.request.use((req) => {
  req.headers.lang = storage.lang.getLang();
  return req;
});

export default http;
