import React from "react";
import { Provider } from "react-redux";
import eventsStore from "../../../core/app/store";
import EventsProvidersList from "../components/events-providers-list";

export default function GetProvidersPage() {
  return (
    <Provider store={eventsStore}>
      <EventsProvidersList />
    </Provider>
  );
}
