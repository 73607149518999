const auth = {
  getToken: () => {
    return JSON.parse(localStorage.getItem('token'));
  },
  setToken: (token) => {
    localStorage.setItem('token', JSON.stringify(token));
  },
  removeToken: () => {
    localStorage.removeItem('token');
  },
};

export default auth;
