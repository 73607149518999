import React from 'react';
import { Route } from 'react-router-dom';
import LemonSharePage from './pages/Lemon-share-page';

export function ShareTripRoutes() {
  return (
    <Route path='/shareTrip'>
      <Route path='/shareTrip/' element={<LemonSharePage />} />
    </Route>
  );
}
