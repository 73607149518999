import React, { useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import default_image from '../../../../assets/images/defult-pic.png';
import { Button, Grid, ListSubheader, Tooltip } from '@mui/material';

import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
} from '@mui/material';
import { formatDate } from '../../../drivers/utils';
import EditDialog from '../common/FormDialog';
import {
  LocalTaxi,
  Fingerprint,
  PhoneInTalk,
  CreditCard,
  DateRange,
} from '@mui/icons-material';

import ImageDialog from '../common/ImageDialog';
import { useDispatch, useSelector } from 'react-redux';

import { RejectFormDialog } from '../common/RejectFormDialog';
import { ActivateFormDialog } from '../common/ActivateFormDialog';
import { NeedUpdateDialog } from '../common/NeedUpdateDialog';
import {
  AccountBalance,
  Android,
  Apple,
  Build,
  CalendarToday,
  ColorLens,
  Description,
  Group,
  MonetizationOn,
  Person,
} from '@mui/icons-material';
import { appDirection } from '../../../../shared_utils';
import { usePendingTranslation } from '../i18n';
import { useTheme } from '@mui/styles';

// avatar style
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: 'red',
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    color: 'red',
  },
}));

function DriverMainDetails({ isRefreshing, setIsRefreshing }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { driver, carTypes, driverImage } = useSelector(
    (state) => state.pendingDrivers
  ).pendingDrivers;

  const theme = useTheme();

  const t = usePendingTranslation();

  //cartype
  const handleType = (carTypeId) => {
    const res = carTypes.map((carType) => {
      if (carType.id === carTypeId) {
        return carType.car_name;
      }
    });
    return res;
  };

  // dialog settings
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [rejectDialog, setRejectDialog] = React.useState(false);
  const [activateDialog, setActivateDialog] = React.useState(false);
  const [needUpadteDialog, setNeedUpadteDialog] = React.useState(false);
  const sleep = (time) => {
    return new Promise((resolve) => {
      setTimeout(resolve, time * 1000);
    });
  };

  // img dialog settings
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpeningImage = () => {
    if (driverImage) {
      setIsOpen(true);
    }
  };
  const [isLoading, setIsLoading] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const getDriverImage = () => {
    const file = driver.files.find((file) => file.file_type.id === 1);
    return file.image_url;
  };

  const driverId = driver.driver_id;

  const navigate = useNavigate();

  const handleCarstatus = (id) => {
    switch (id) {
      case 1:
        return 'new';
      case 2:
        return 'Very Good';
      case 3:
        return 'Good';

      default:
        break;
    }
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  if (isLoading) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-screen place-content-center'>
          <div class='flex items-center  text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            loading...
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <Grid
      container
      dir={appDirection}
      lg={12}
      md={12}
      xs={12}
      className='relative'
    >
      <ImageDialog open={isOpen} setOpen={setIsOpen} image_url={driverImage} />

      {/* edit btn */}

      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        // className=' border-2 p-5   rounded-xl w-[24vw] '
      >
        {/* <DriverTreeView /> */}
        <Grid item lg={12} md={12} xs={12} className=' '>
          {/* profile img  */}
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            className='flex justify-center  cursor-pointer'
          >
            <Avatar
              // src={
              //   'https://s3.eu-central-1.amazonaws.com/lemon-dispatch-dev/drivers/6919430200807653376/d1e5472d-684d-4f12-949f-3b46df65a206-thumb1.jpeg'
              // }
              src={driverImage || default_image}
              sx={{ height: '90px', width: '90px' }}
              onClick={handleOpeningImage}
            />
          </Grid>

          {/* some info */}

          {/* header */}
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            className=' text-center relative pb-2 pt-2'
          >
            <h1 className=' font-serif font-extrabold text-gray-700 text-2xl pb-2'>
              {driver.full_name}
            </h1>

            {/* <Grid item lg={12} md={12} className='py-6 '> */}
            <div className='flex justify-center'>
              <span className='p-2 px-6 rounded-2xl font-serif font-semibold bg-gray-300'>
                {driver.pending_driver_status &&
                  driver.pending_driver_status.status_name}
              </span>
            </div>
            {driver.reject_reason && (
              <div className={'flex justify-start rounded-sm '}>
                {t('reason')}: {driver.reject_reason}
              </div>
            )}
            {/* </Grid> */}
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            className='flex justify-center pb-1 relative'
          ></Grid>
          <Divider variant='middle' />
          {/* body */}

          <Grid container lg={12} md={12} className='p-4' spacing={2}>
            {/* leftside */}
            <Grid item lg={4} md={4}>
              <List
                dense={true}
                subheader={
                  <ListSubheader
                    sx={{ backgroundColor: '#f3f4f6', fontSize: 'large' }}
                    component='div'
                    id='nested-list-subheader'
                  >
                    {t('driver_info')}
                  </ListSubheader>
                }
              >
                {/* item 1 */}
                <ListItem>
                  <ListItemAvatar>
                    <Person className='text-black w-2' />
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('driver_id')}
                    secondary={driver.driver_id || '...'}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 1 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <PhoneInTalk className='text-black w-2' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('mobile_number')}
                    secondary={driver.mobile_number || '...'}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 2 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <CreditCard className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('driver_license')}
                    secondary={driver.driver_licence || '...'}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 3 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <Fingerprint className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('national_id')}
                    secondary={driver.national_id || '...'}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 4 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <Group className='text-black w-2' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('referral_number')}
                    secondary={driver.referral_number || '...'}
                  />
                </ListItem>

                <Divider variant='inset' component='li' />

                {/* item 4 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      {driver.device_os === 'ios' ? (
                        <Apple className='text-white' />
                      ) : (
                        <Android className='text-green-600' />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('device_os')}
                    secondary={driver.device_os || '...'}
                  />
                </ListItem>
              </List>
            </Grid>
            {/* rigthside */}
            <Grid item lg={4} md={4}>
              {/* car info */}
              <List
                dense={true}
                subheader={
                  <ListSubheader
                    sx={{ backgroundColor: '#f3f4f6', fontSize: 'large' }}
                    component='div'
                    id='nested-list-subheader'
                  >
                    {t('car_info')}
                  </ListSubheader>
                }
              >
                {/* item 1 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <LocalTaxi className='text-black w-2' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('plate_number')}
                    secondary={driver.car_plate || '...'}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 2 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <ColorLens className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('color')}
                    secondary={driver.car_color || '...'}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 3 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <Description className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('car_model')}
                    secondary={driver.car_desc || '...'}
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item  */}
                {/* item 4 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <Build className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('car_status')}
                    secondary={handleCarstatus(driver.car_status_id) || '...'} //FIXME:
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 4 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <CalendarToday className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('manufacture_date')}
                    secondary={driver.car_model_year || '...'} //FIXME:
                  />
                </ListItem>

                {/* item 4 */}
              </List>
            </Grid>{' '}
            <Grid item lg={4} md={4}>
              {' '}
              {/* account info */}
              <List
                dense={true}
                subheader={
                  <ListSubheader
                    sx={{ backgroundColor: '#f3f4f6', fontSize: 'large' }}
                    component='div'
                    id='nested-list-subheader'
                  >
                    {t('account_info')}
                  </ListSubheader>
                }
              >
                {/* item 1 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <LocalTaxi className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('car_type')}
                    secondary={handleType(driver.car_type_id) || '...'} //FIXME:
                  />
                </ListItem>
                <Divider variant='inset' component='li' />
                {/* item 2 */}
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ height: '30px', width: '30px' }}>
                      <DateRange className='text-black' />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('date')}
                    secondary={formatDate(driver.created_at) || '...'}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>

          {/* block buttons */}

          <Grid constainer className='flex justify-center p-2 ' spacing={2}>
            <Grid item className='p-2'>
              <EditDialog
                driver={driver}
                carTypes={carTypes}
                driverId={driverId}
                isRefreshing={isRefreshing}
                setIsRefreshing={setIsRefreshing}
              />
            </Grid>

            <Grid item className='p-2'>
              <NeedUpdateDialog
                open={needUpadteDialog}
                driverId={driverId}
                isRefreshing={isRefreshing}
                setIsRefreshing={setIsRefreshing}
              />
            </Grid>

            <Grid item className='p-2'>
              <ActivateFormDialog
                open={activateDialog}
                driverId={driverId}
                isRefreshing={isRefreshing}
                setIsRefreshing={setIsRefreshing}
              />
            </Grid>

            <Grid item className='p-2'>
              <RejectFormDialog
                driver={driver}
                driverId={driverId}
                isRefreshing={isRefreshing}
                setIsRefreshing={setIsRefreshing}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default DriverMainDetails;
