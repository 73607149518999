import React, { useEffect, createRef, useCallback } from 'react';
import { makeStyles } from '@mui/styles';

import { Grid, InputAdornment, TextField, Button } from '@mui/material';

import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { MenuItem } from '@mui/material';
import {
  fetchCarTypes,
  fetchFeesGroups,
  setError,
} from '../../../../Redux/store/drivers/driversListSlice';
import { useDriverTranslation } from '../../i18n';
import { useTheme } from '@mui/styles';
import { async } from 'rxjs';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    margin: '0px 0px 10px 0px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function ListFiler({
  search,
  setSearch,
  isRefreshing,
  setIsRefreshing,
  onApply,
}) {
  const classNamees = useStyles();
  const dispatch = useDispatch();
  const { carTypes } = useSelector((state) => state.drivers).driversList;
  const { feesGroups } = useSelector((state) => state.drivers).driversList;

  //formik
  const theme = useTheme();
  const inputRef = createRef();
  useEffect(() => {
    if (inputRef) {
      inputRef.current.dir = 'auto';
    }
  }, []);

  const t = useDriverTranslation();

  const handleClick = (e) => {
    const type = e.target.value;
    if (type === 'Block') {
      setSearch({
        ...search,
        blocked: true,
        payment_block: false,
        blocked_until: false,
      });
    } else if (type === 'All') {
      setSearch({
        ...search,
        blocked: false,
        payment_block: false,
        blocked_until: false,
      });
    } else if (type === 'Payment') {
      setSearch({
        ...search,
        blocked: false,
        blocked_until: false,
        payment_block: true,
      });
    } else {
      setSearch({
        ...search,
        blocked: false,
        payment_block: false,
        blocked_until: true,
      });
    }
  };

  return (
    <Grid
      container
      lg={12}
      md={12}
      justifyContent
      className=' flex justify-start p-1 '
    >
      <Grid container lg={11} md={11} className='' spacing={2}>
        <Grid item lg={2} md={2} sm={2} xs={2} sx={{ mt: -1 }}>
          <TextField
            InputLabelProps={{ shrink: true }}
            className='bg-white'
            fullWidth
            label={t('Drivers_status')}
            onChange={handleClick}
            margin='dense'
            variant='outlined'
            size='small'
            select
          >
            <MenuItem value='All'>{t('all')}</MenuItem>
            <MenuItem value='Block'>{t('blocked')}</MenuItem>
            <MenuItem value='Payment'>{t('blocked_by_payment')}</MenuItem>
            <MenuItem value='Temporary'>{t('temporary_blocked')}</MenuItem>
          </TextField>
        </Grid>

        {/* carType menu  */}
        <Grid item lg={2} md={2} sm={2} xs={2} sx={{ mt: -1 }}>
          <TextField
            InputLabelProps={{ shrink: true }}
            className='bg-white'
            fullWidth
            onChange={(e) =>
              setSearch({ ...search, car_type_ids: `${e.target.value}` })
            }
            label={t('car_type')}
            margin='dense'
            variant='outlined'
            size='small'
            value={search.car_type}
            select
          >
            <MenuItem value={null}>All</MenuItem>
            {carTypes.map((cartype) => (
              <MenuItem value={cartype.id} key={cartype.id}>
                {cartype.car_name_en}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* fees groups menu  */}
        <Grid item lg={2} md={2} sm={2} xs={2} sx={{ mt: -1 }}>
          <TextField
            InputLabelProps={{ shrink: true }}
            className='bg-white'
            fullWidth
            onChange={(e) =>
              setSearch({ ...search, fees_group_id: e.target.value })
            }
            label={t('fees_groups')}
            margin='dense'
            variant='outlined'
            size='small'
            value={search.feesGroups}
            select
          >
            {feesGroups.map((Group) => (
              <MenuItem key={Group.id} value={Group.id}>
                {Group.group_name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* search field */}

        <Grid item lg={2} md={2} sm={2} xs={2} sx={{ mt: -1 }}>
          <TextField
            inputRef={inputRef}
            className=' bg-white'
            margin='dense'
            variant='outlined'
            value={search.mobile}
            onChange={(e) =>
              setSearch({
                ...search,
                mobile: e.target.value,
              })
            }
            id='mobile'
            label={t('mobile')}
            type='text'
            InputLabelProps={{ shrink: true }}
            size='small'
            InputProps={
              theme.direction == 'ltr'
                ? {
                    startAdornment: (
                      <InputAdornment position='end'>+249</InputAdornment>
                    ),
                  }
                : {
                    endAdornment: (
                      <InputAdornment position='start'>249+</InputAdornment>
                    ),
                  }
            }
          />
        </Grid>
      </Grid>

      <Grid
        item
        lg={1}
        md={1}
        sm={1}
        xs={1}
        className='flex justify-center h-full '
      >
        <Button
          variant='contained'
          className='h-full p-8'
          color='primary'
          onClick={() => onApply()}
        >
          {t('apply')}
        </Button>
      </Grid>
    </Grid>
  );
}
