import http from '../../../services/APIs/dispatch_api';

export const appApi = {
  getDailyRateData: async (payload) => {
    try {
      const response = await http.get(
        `/dashboard/acceptance_rate/hourly_acceptance_rate?from=${payload.from}&to=${payload.to}`
      );
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },
  getWeeklyRateData: async (payload) => {
    try {
      const response = await http.get(
        `/dashboard/acceptance_rate/daily_acceptance_rate?from=${payload.from}&to=${payload.to}`
      );
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },
  getMonthlyRateData: async (payload) => {
    try {
      const response = await http.get(
        `/dashboard/acceptance_rate/weakly_acceptance_rate?from=${payload.from}&to=${payload.to}`
      );
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },
  getRateDetails: async (payload) => {
    try {
      const response = await http.get(
        `/dashboard/acceptance_rate/acceptance_rate_details?from=${payload.from}&to=${payload.to}`
      );
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },

   fetchActualRoute: async({id}) => {
    try {
      const response = await http.get(`/dispatch_tracking_locations?dispatch_id=${id}`)
      //6976811824456925184
      return response.data;
    } catch (error) {
      throw error.response;
  }
}
};
