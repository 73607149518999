import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteImage,
  setRefreshingPlace,
} from '../../../../Redux/store/places/placesListSlice';

import { setAlert } from '../../../../Redux/store/app/appSlice';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { usePlacesTranslation } from '../../i18n';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

export default function ImageDialog({
  id,
  image_id,
  isRefreshing,
  setIsRefreshing,
}) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = useCallback(async () => {
    try {
      await dispatch(deleteImage({ id: id, imagesIds: [image_id] }));
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      handleClose();
      setIsRefreshing(isRefreshing + 1);
    }
  });

  const t = usePlacesTranslation();

  return (
    <div>
      <Button onClick={handleClickOpen} color='error' variant='contained'>
        <Delete fontSize='small' />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle color='error' id='alert-dialog-title'>
          {t('warning')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {t('delete_img_msg')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('cancel')}</Button>
          <Button color='error' onClick={handleDelete} autoFocus>
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
