import { createSlice } from '@reduxjs/toolkit';
import { dispatchesApi } from './api';

const initialState = {
  dispatches: [],
  autoDispatches: [],
  dispatchTransactions: [],
  dispatch: {},
  isLoading: false,
};

const dispatchesListSlice = createSlice({
  name: 'dispatches',
  initialState,
  reducers: {
    setLoadingState: (state, action) => {
      state.isLoading = action.payload;
    },
    fetchDispatchesSuccess: (state, action) => {
      state.dispatches = action.payload;
    },
    fetchDispatchSuccess: (state, action) => {
      state.dispatch = action.payload;
    },
    fetchAutoDispatchesSuccess: (state, action) => {
      state.autoDispatches = action.payload;
    },
    fetchDispatcheTransactionsSuccess: (state, action) => {
      state.dispatchTransactions = action.payload;
    },
    setError: (state, actions) => {
      state.error = actions.payload;
    },
    
  },
});

export const {
  fetchDispatchesSuccess,
  fetchDispatchSuccess,
  fetchAutoDispatchesSuccess,
  fetchDispatcheTransactionsSuccess,
  setError,
  setLoadingState,
} = dispatchesListSlice.actions;

export const fetchDispatches = (options) => async (dispatch) => {
  try {
    dispatch(setLoadingState(true));
    const dispatches = await dispatchesApi.fetchAll(options);
    dispatch(fetchDispatchesSuccess(dispatches));
  } catch (error) {
    dispatch(setError(error));
    throw error;
  }
};

export const fetchDispatchById = (options) => async (dispatchFunc) => {
  try {
    dispatchFunc(setLoadingState(true));
    const dispatch = await dispatchesApi.fetchDispatchById(options);
    dispatchFunc(fetchDispatchSuccess(dispatch));
  } catch (error) {
    dispatchFunc(setError(error));
    throw error;
  }
};

export const fetchAutoDispatch = (options) => async (dispatchFunc) => {
  try {
    const dispatches = await dispatchesApi.fetchAutoDispatch(options);
    dispatchFunc(fetchAutoDispatchesSuccess(dispatches));
  } catch (error) {
    dispatchFunc(setError(error));
    throw error;
  }
};

export const fetchDispatchTransaction = (options) => async (dispatchFunc) => {
  try {
    const transactions = await dispatchesApi.fetchDispatchTransaction(options);
    dispatchFunc(fetchDispatcheTransactionsSuccess(transactions));
  } catch (error) {
    dispatchFunc(setError(error));
    throw error;
  }
};

export const fetchDriverDispatches = (options) => async (dispatch) => {
  try {
    dispatch(setLoadingState(true));
    const dispatches = await dispatchesApi.fetchDriverDispatches(options);
    dispatch(fetchDispatchesSuccess(dispatches));
  } catch (error) {
    dispatch(setError(error));
    throw error;
  }
};

export const updateStatus = (options) => async (dispatch) => {
  try {
    const resp = await dispatchesApi.updateStatus(options);
    return resp;
  } catch (error) {
    dispatch(setError(error));
    throw error;
  }
};

export const assignDispatch = (options) => async (dispatch) => {
  try {
    const resp = await dispatchesApi.assignDispatch(options);
    return resp;
  } catch (error) {
    dispatch(setError(error));
    throw error;
  }
};



export default dispatchesListSlice.reducer;
