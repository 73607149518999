import React, { useCallback, useEffect, createRef } from 'react';
import { makeStyles } from '@mui/styles';

import { Grid } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField, InputAdornment } from '@mui/material';

import { setListPayload } from '../../../../Redux/store/users/usersListSlice';
import { useUsersTranslation } from '../../i18n';
import { useTheme } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    margin: '0px 0px 10px 0px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function ListFiler({ setIsRefreshing, isRefreshing }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useUsersTranslation();
  const { listPayload } = useSelector((state) => state.users).usersList;
  const theme = useTheme();
  const inputRef = createRef();
  useEffect(() => {
    if (inputRef) {
      inputRef.current.dir = 'auto';
    }
  }, []);

  const [mobile, setMobile] = React.useState(listPayload.mobile);

  const handleSubmit = useCallback((e) => {
    if (mobile === '') {
      dispatch(setListPayload({ mobile: undefined }));
    } else {
      dispatch(setListPayload({ mobile: mobile }));
    }

    setIsRefreshing(isRefreshing + 1);
  });

  const handleClearField = useCallback((e) => {
    setMobile('');
  });

  return (
    <Grid
      container
      lg={12}
      md={12}
      justifyContent
      className='p-1 flex justify-between'
    >
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <TextField
          InputLabelProps={{ shrink: true }}
          inputRef={inputRef}
          className=' bg-white'
          variant='outlined'
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          id='driver_phone'
          label={t('phone')}
          type='text'
          size='small'
          fullWidth
          InputProps={
            theme.direction == 'ltr'
              ? {
                  startAdornment: (
                    <InputAdornment position='end'>+249</InputAdornment>
                  ),
                }
              : {
                  endAdornment: (
                    <InputAdornment position='start'>249+</InputAdornment>
                  ),
                }
          }
        />
      </Grid>
      <Grid
        item
        lg={1}
        md={1}
        sm={1}
        xs={1}
        className='flex justify-center h-full '
      >
        <Button
          variant='contained'
          className='h-full p-8'
          color='primary'
          type='submit'
          onClick={handleSubmit}
        >
          {t('apply')}
        </Button>
      </Grid>
    </Grid>
  );
}
