import { Route } from 'react-router-dom';
import DriverDetailsPage from './pages/driver-details-page';
import DriversListPage from './pages/drivers-list-page';

export const PendingDriversRoutes = () => {
  return (
    <Route path='/drivers/pending-drivers' key={33}>
      <Route
        path='/drivers/pending-drivers/'
        element={<DriversListPage />}
        key={'pending-drivers-list'}
      />
      <Route
        path='/drivers/pending-drivers/:id'
        element={<DriverDetailsPage />}
        key={'pending-driver-details'}
      />
    </Route>
  );
};
