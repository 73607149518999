import http from '../http';

const getTicketTransactions = async (ticket_id) => {
  const baseURL = process.env.REACT_APP_TICKETS_API;
  try {
    const transactions = await http.get(`${baseURL}/ticket_transactions/${ticket_id}`);
    return transactions.data;
  } catch (err) {
    throw err.response;
  }
};

export default getTicketTransactions;
