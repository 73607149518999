import React from 'react';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatchTranslation } from '../../i18n';

export default function AlertDialog({
  open,
  setOpen,
  handleUpdateStatus,
  id,
  status,
}) {
  //   const [open, setOpen] = React.useState(false);

  const handleCofiramation = () => {
    handleUpdateStatus(id);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const t = useDispatchTranslation();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle id='alert-dialog-title'>{t('status_change')} </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {t('status_msg')} {status} !
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            className='flex justify-center pt-5 pb-4 '
            spacing={2}
          >
            <Grid item>
              <Button
                variant='outlined'
                onClick={handleCofiramation}
                color='primary'
                autoFocus
              >
                {t('confirm')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant='outlined' onClick={handleClose} color='inherit'>
                {t('cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
