import { Edit } from '@mui/icons-material';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import PageHeading from '../../../components/common/PageHeading';
import api from '../../services/API/dispatchingTime';
import DeleteModal from '../../../components/new-components/DeleteModal';

export default function DispatchingTimesPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreching, setIsRefreching] = useState(false);
  const [dispatchingTimes, setDispatchingTimes] = useState([]);

  const [selected, setSelected] = useState(null);
  const [val, setVal] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await api.getDispatchingTimes();
      setDispatchingTimes(data);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [dispatchingTimes]);

  const updateData = useCallback(
    async (key, time) => {
      setIsLoading(true);
      try {
        await api.updateDispatchingTimes({ key, value: time });
        toast.success(t('Updated successfully'));
        setIsRefreching((prev) => prev + 1);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [val, selected]
  );

  useEffect(() => {
    fetchData();
  }, [isRefreching]);

  // this will updating after get useDispatchTranslations
  const { t } = useTranslation();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Grid container>
      <Grid item lg={12}>
        <PageHeading
          backBtn
          heading1={t('Dispatch')}
          heading2={t('System Config')}
          isRefreshing={isRefreching}
          setIsRefreshing={setIsRefreching}
        />
      </Grid>
      <DeleteModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        onConfirm={() => {
          updateData(selected, val);
          setSelected(null);
        }}
        message={t('update_msg')}
      />
      <Grid item className='flex items-center justify-center' lg={12}>
        <Grid item marginTop={6} lg={10} justifyContent='center'>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead className='bg-gray-100'>
                <TableRow>
                  <TableCell>{t('dispatching_time')}</TableCell>
                  <TableCell align='center'>{t('time_value_in_sec')}</TableCell>
                  <TableCell align='center'>{t('action')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dispatchingTimes.map((dispatchingTime, i) => (
                  <TableRow key={i}>
                    <TableCell>{t(dispatchingTime.key)}</TableCell>
                    <TableCell align='center'>
                      {dispatchingTime.key === selected ? (
                        <TextField
                          size='small'
                          value={val !== null ? val : dispatchingTime.value}
                          type='number'
                          className='text-center w-[150px]'
                          onChange={(e) => setVal(e.target.value)}
                        />
                      ) : (
                        <span>{dispatchingTime.value}</span>
                      )}
                    </TableCell>
                    <TableCell align='center'>
                      {dispatchingTime.key === selected ? (
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={() => setOpenModal(true)}
                        >
                          {t('edit')}
                        </Button>
                      ) : (
                        <Edit
                          className='cursor-pointer w-full'
                          onClick={() => setSelected(dispatchingTime.key)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
}
