import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PageHeading from '../../../../components/common/PageHeading';
import ProviderDetails from './tabs/ProviderDetails';
import ProviderEvents from './tabs/ProviderEvents';
import ProviderEmpolyees from './tabs/ProviderEmployees';
import { useEventsTranslation } from '../../../services/i18n';
import {
  createSearchParams,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useEffect } from 'react';
import eventsDashboardAPI from '../../../services/API/events-dashboard';
import { toast } from 'react-toastify';

export default function ProviderTabs() {
  const [value, setValue] = useSearchParams(
    createSearchParams({
      tabs: '1',
    })
  );

  const [isRefreshing, setIsRefreshing] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue({
      tabs: newValue,
    });
  };

  const t = useEventsTranslation();

  return (
    <div className='mt-2'>
      <PageHeading
        backBtn
        heading1={t('events')}
        heading2={t('providers')}
        isRefreshing={isRefreshing}
        setIsRefreshing={setIsRefreshing}
      />
      <Box
        sx={{
          width: '100%',
          typography: 'body1',
        }}
      >
        <TabContext value={value.get('tabs')}>
          <Box
            className='bg-gray-100'
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <TabList
              onChange={handleChange}
              aria-label='lab API tabs example'
              centered
            >
              <Tab label={t('about_provider')} value='1' />
              <Tab label={t('provider_events')} value='2' />
              <Tab label={t('provider_employees')} value='3' />
            </TabList>
          </Box>
          <TabPanel value='1'>
            <ProviderDetails isRefreshing={isRefreshing} />
          </TabPanel>
          <TabPanel value='2'>
            <ProviderEvents
              isRefreshing={isRefreshing}
              setIsRefreshing={setIsRefreshing}
            />
          </TabPanel>
          <TabPanel value='3'>
            <ProviderEmpolyees
              isRefreshing={isRefreshing}
              setIsRefreshing={setIsRefreshing}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
