import { Route } from 'react-router-dom';
import UsersTicketDetailsPage from './pages/users/ticket-details-page';
import UsersTicketsListPage from './pages/users/tickets-list-page';
import DriversTicketDetailsPage from './pages/drivers/ticket-details-page';
import DriversTicketsListPage from './pages/drivers/tickets-list-page';

export function ticketsRoutes() {
  return [
    <Route path='/tickets' key={22}>
      <Route path='/tickets/users'>
        <Route
          path='/tickets/users/'
          element={<UsersTicketsListPage />}
          key={'tickets_list_page'}
        />
        <Route
          path='/tickets/users/:id'
          element={<UsersTicketDetailsPage />}
          key={'ticket_details_page'}
        />
      </Route>
      <Route path='/tickets/drivers'>
        <Route
          path='/tickets/drivers/'
          element={<DriversTicketsListPage />}
          key={'tickets_list_page'}
        />
        <Route
          path='/tickets/drivers/:id'
          element={<DriversTicketDetailsPage />}
          key={'ticket_details_page'}
        />
      </Route>
    </Route>,
  ];
}
