import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  fetchUsersTickets,
  fetchTicketSupportUsersList,
} from '../../../../Redux/store/tickets/ticketsListSlice';
import TicketsList from '../../components/TicketsList';
import PageHeading from '../../../../components/common/PageHeading';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import Filters from '../../components/common/Filters';
import { useTicketTranslation } from '../../i18n';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const TicketsListPage = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefreshing, setIsRefreshing] = React.useState(0);
  const t = useTicketTranslation();
  const [payload, setPayload] = React.useState({
    status_id: '',
    ticket_id: '',
    mobile: '',
    from: '',
    to: '',
  });

  const [searchParams, setSearchParams] = useSearchParams(
    createSearchParams({
      page_number: 0,
      page_size: 10,
      status_id: '',
    })
  );

  const currentSearchParams = useMemo(
    () => ({
      ...payload,
      page_number: searchParams.get('page_number'),
      page_size: searchParams.get('page_size'),
      status_id: searchParams.get('status_id'),
    }),
    [searchParams]
  );

  const setPage = (newPage) => {
    setSearchParams({
      ...currentSearchParams,
      page_number: newPage,
    });
    setIsRefreshing((prev) => prev + 1);
  };

  const onApply = () => {
    setSearchParams({
      ...currentSearchParams,
      ...payload,
      page_number: 0,
    });
    setIsRefreshing((prev) => prev + 1);
  };

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        dispatch(fetchUsersTickets(currentSearchParams)),
        dispatch(fetchTicketSupportUsersList()),
      ]);
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    fetchData();
  }, [isRefreshing]);

  return (
    <Grid
      container
      lg={12}
      md={12}
      justifyContent='center'
      className='mx-1'
      spacing={2}
    >
      <Grid item lg={12} md={12} className=' flex justify-center'>
        <PageHeading
          heading1={t('Tickets')}
          heading2={t('users_tickets_list')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>

      <Grid item lg={12} md={12} className=' bg-gray-100 '>
        <Filters
          setPayload={setPayload}
          payload={payload}
          isRefrashing={isRefreshing}
          setIsRefrashing={setIsRefreshing}
          onApply={onApply}
        />
      </Grid>
      <Grid item lg={12} md={12}>
        <TicketsList
          setPage={setPage}
          page={Number(currentSearchParams.page_number)}
          isLoading={isLoading}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          type='users'
        />
      </Grid>
    </Grid>
  );
};

export default TicketsListPage;
