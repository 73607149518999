import http, { requestHandler } from '../http';

const api = {
  addEmployee: async (employee) => {
    try {
      await http.post(`/dashboard/events_providers_employees`, employee);
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  },
  getEmployees: (id, page_number) =>
    requestHandler(async () => {
      const { data } = await http.get(
        `/dashboard/events_providers_employees?provider_id=${id}&page_number=${page_number}&page_size=10`
      );
      return data;
    }),
  deleteEmployee: async (userId, providerId) => {
    try {
      await http.delete(
        `/dashboard/events_providers_employees/${userId}/${providerId}`
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  },
};

export default api;
