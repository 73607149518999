import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  clearErrors,
  deleteEvent,
  fetchEvents,
} from '../../../../core/features/events/eventsSlice';
import { toast } from 'react-toastify';
import { Add, Delete, Edit, Info, Place } from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';
import { CalendarMonth, Visibility } from '@mui/icons-material';
import { Button, Chip, LinearProgress, Modal } from '@mui/material';
import DeleteModal from '../../../../../components/new-components/DeleteModal';
import { useEventsTranslation } from '../../../../services/i18n';
import { eventsCommonCols, eventsStatuses } from '../../../utils';
import CustomPagination from '../../../../../components/new-components/CustomPagination';
import { Empty } from '../../../../../components/new-components/Empty';

export default function ProviderEvents({ isRefreshing, setIsRefreshing }) {
  const { isLoading, error, events } = useSelector(
    (state) => state.eventsSlice
  );

  const dispatch = useDispatch();
  const [confirmModal, setConfirmModal] = useState(false);
  const [deletedId, setDeletedId] = useState(0);
  const [page, setPage] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchEvents({ id, page }));
  }, [dispatch, isRefreshing, page]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
      dispatch(clearErrors());
    }
  }, [dispatch, error]);

  const t = useEventsTranslation();

  const cols = [
    ...eventsCommonCols(t).filter((col) => col.field !== 'provider'),
    {
      field: 'btn',
      headerName: t('actions'),
      headerClassName: 'bg-gray-100 text-center w-full h-full',
      disableColumnMenu: true,
      flex: 0.8,
      renderCell: (params) => (
        <div className='flex items-center ml-2 w-full gap-2'>
          <Link to={`/events/${params.value.event_id}`}>
            <Visibility color='primary' />
          </Link>
          <Link to={`/events/update/${params.value.event_id}`}>
            <Edit className='text-gray-800' />
          </Link>
          <button
            onClick={() => {
              setConfirmModal(true);
              setDeletedId(params.value.event_id);
            }}
          >
            <Delete color='error' />
          </button>
        </div>
      ),
    },
  ];

  const rowsData = events.map((event) => ({
    id: event.event_id,
    name: event,
    provider: event.event_provider,
    address: event,
    date: event,
    bookingDate: event,
    description: event,
    status: event,
    btn: event,
  }));

  return (
    <div className='flex flex-col'>
      <DeleteModal
        setOpenModal={setConfirmModal}
        openModal={confirmModal}
        onConfirm={async () => {
          await dispatch(deleteEvent(deletedId));
          setIsRefreshing((prev) => prev + 1);
        }}
        // message={'Are you sure do you want to delete this event?'}
        message={t('delete_event_msg')}
      />
      <div className='mb-3'>
        <Button
          variant='contained'
          color='primary'
          className='flex items-center w-40 '
          onClick={() => {
            navigate('/events/add/' + id);
          }}
          endIcon={<Add />}
        >
          {t('add_new')}
        </Button>
      </div>
      <DataGrid
        className='border-2'
        disableColumnFilter
        autoHeight
        rowHeight={60}
        page={page}
        loading={isLoading}
        rows={rowsData}
        columns={cols}
        pageSize={10}
        rowCount={100}
        paginationMode='server'
        onPageChange={(newPage) => setPage(newPage)}
        CellStyle='{StaticResource DataGridBorder}'
        components={{
          Toolbar: () => <></>,
          Pagination: () => CustomPagination(isLoading),
          NoRowsOverlay: Empty,
          NoResultsOverlay: Empty,
          LoadingOverlay: LinearProgress,
        }}
      />
    </div>
  );
}
