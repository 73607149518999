import React, { useState, useCallback, useEffect } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import { putCommentInfo } from '../../../../Redux/store/places/placesListSlice';
import { useRef } from 'react';
import { usePlacesTranslation } from '../../i18n';

function CommentsForm({
  comment,
  setOpen,
  isRefreshing,
  setIsRefreshing,
  setLoadingCount,
}) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState({
    id: comment.comment_id,
    payload: comment,
  });

  const [test, setTest] = useState(false);

  const isInitialMount = useRef(true);

  const updatePlaceFrom = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(putCommentInfo(options));
      setOpen(false);
      setIsRefreshing(isRefreshing + 1);
      // setLoadingCount(0);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    }
  });

  const t = usePlacesTranslation();

  const handleSubmit = (values) => {
    setOptions({ ...options, payload: values });
    setTest(true);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      updatePlaceFrom();
    }
  }, [test]);

  const formik = useFormik({
    initialValues: comment,
  });
  return (
    <Grid container lg={12} md={12}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(formik.values);
        }}
        style={{ width: '100%' }}
      >
        <Grid
          container
          lg={12}
          md={12}
          spacing={2}
          justifyContent='center'
          alignItems='center'
          marginTop={1}
        >
          <Grid item lg={12} md={12}>
            <TextField
              fullWidth
              label={t('comment')}
              id='comment'
              name='comment'
              variant='outlined'
              multiline
              rows={10}
              onChange={formik.handleChange}
              value={formik.values.comment}
            />
          </Grid>

          <Grid item lg={12} md={12} className='flex justify-center py-5'>
            <Button color='primary' variant='contained' type='submit'>
              {t('submit')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

export default CommentsForm;
