import http from '../http';

const getTicketCategories = async () => {
  const baseURL = process.env.REACT_APP_TICKETS_API;
  try {
    const categoriesList = await http.get(
      `${baseURL}/ticket_categories?service_id=dispatch&client_id=DISPATCH_ID`
    );
    return categoriesList.data;
  } catch (err) {
    throw err.response;
  }
};

export default getTicketCategories;
