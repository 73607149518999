import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Clear } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { rejectReport } from '../../../../Redux/store/places/placesListSlice';
import { makeStyles } from '@material-ui/core/styles';

import { setAlert } from '../../../../Redux/store/app/appSlice';
import { useCallback } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cancel: {
    backgroundColor: '#575757',
    color: 'white',
    border: '2px',
    paddingLeft: '8px',
    paddingRight: '8px',
    '&:hover': {
      backgroundColor: '#383838',
      color: 'white',
      boxShadow: 'none',
    },
  },

  text: {
    fontSize: '18px',
    color: '#151515',
  },
  delete: {
    // '&:active': {},
    backgroundColor: '#FF392E',
    color: 'white',
    border: '2px',
    paddingLeft: '8px',
    paddingRight: '8px',
    '&:hover': {
      backgroundColor: '#9A0000',
      color: 'white',
      boxShadow: 'none',
    },
  },
  dialog: {},
}));

export default function RejectDialog({ id, setIsLoading, isRefreshing, setIsRefreshing }) {
  const [open, setOpen] = React.useState(false);
  const [note, setNote] = React.useState('');

  const [options, setOptions] = React.useState({
    id: id,
    support_note: '',
  });

  const classes = useStyles();

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setNote(e.target.value);
    setOptions({
      ...options,
      support_note: e.target.value,
    });
  };

  const handleDelete = useCallback(async () => {
    try {
      await dispatch(rejectReport({ ...options }));
      setIsLoading(true);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsRefreshing(isRefreshing + 1);
      setOpen(false);
    }
  });

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <Clear style={{ color: '#BB0000' }} />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        // className={classes.Dialog}
        fullWidth
        maxWidth='sm'>
        <DialogTitle id='alert-dialog-title' style={{ color: '#FF392E', fontWeight: 'bold' }}>
          WARNING
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description' className={classes.text}>
            <Grid item className='pb-2' lg={12} md={12}>
              Are you sure you want to reject this report !
            </Grid>
            <Grid item lg={12} md={12}>
              <TextField
                fullWidth
                label='Note'
                id='note'
                name='note'
                variant='outlined'
                multiline
                rows={2}
                onChange={handleChange}
                value={note}
              />
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.cancel}>
            cancel
          </Button>
          <Button onClick={handleDelete} variant='contained' className={classes.delete} autoFocus>
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
