const users_ar = {
  users: 'المستخدمين',
  users_list: 'قائمة المستخدمين',
  user_info: 'معلومات المستخدم',
  actions: 'العمليات',
  phone: 'رقم الهاتف',
  user_details: 'تفاصيل المستخدم',
  details: 'تفاصيل',
  orders: 'طلبات',
  statistics: 'إحصائيات',
  balance: 'رصيد',
  tickets: 'تذاكر',
  ID: 'رقم الهوية',
  full_name: 'الإسم',
  email: 'الإيميل',
  created_at: 'تاريخ الإنشاء',
  updated_at: 'تاريخ التحديث ',
  create_ticket: 'إنشاء تذكرة',
  parent_category: 'التصنيف الرئيسي',
  child_category: 'التتصنيف الفرعي',
  priority: 'أفضليه',
  description: 'تفاصيل',
  cancel: 'إلغاء',
  low: 'منخفض',
  medium: 'متوسط',
  high: 'مرتفع',
  critical: 'حرج',
  note: 'ملاحظة',
  new_ticket: 'تذكرة جديدة',
  submit: 'تطبيق',
  apply: 'تطبيق',

  order_id: 'ID السائق',
  from: 'من',
  to: 'الى',
  car_type: 'نوع السيارة',
  all: 'الكل',
  Total: 'الكلي',
  reference_type: 'نوع المرجع ',
  // reference_type: '',
  new_otp: ' otp جديد',
  amount: 'المبلغ',
  narration: 'وصف',
  created_at: 'تم الإنشاء',
  total_balance: 'الرصيد الكلي',
  transactions: 'التحويلات',
  SDG: 'جنيه',
  update: 'تحديث',
  add_balance: 'اضافة رصيد',
  deduct_balance: 'خصم رصيد',
  status: 'الحاله',
  new: 'جديد',
  under_process: 'قيد المعالجة',
  closed: 'مغلق',
  generate_otp: 'توليد رقم الدخول الموحد',
  service: 'خدمة',
  lemon_client: 'تطبيق العميل',
  lemon_driver: 'تطبيق السائق',
  taxi_support: 'الدعم الفني تاكسي',
  food_support: 'الدعم الفني فوود',
  lemon_store: ' متاجر ليمون',
  lemon_events_portal: 'فعاليات ليمون (المزود)',
  mobile: 'رقم الهاتف',
  msg_invalid: 'رقم الهاتف غير صالح',
  msg_add: 'تمت إضافة للرصيد بنجاح',
  msg_deduct: 'تم الخصم من الرصيد بنجاح',
};
export default users_ar;
