import http, { baseURL } from './http';

const api = {
  getDispatchingTimes: () =>
    requestHandler(async () => {
      const { data } = await http.get(
        baseURL + '/dashboard/dispatcher/system_configs'
      );
      return data;
    }),
  updateDispatchingTimes: (payload) =>
    requestHandler(async () => {
      await http.post(
        baseURL + '/dashboard/dispatcher/system_configs',
        payload
      );
    }),
};

async function requestHandler(req) {
  try {
    return await req();
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
}

export default api;
