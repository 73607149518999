import moment from 'moment';
import http from '../http';

const getWeeklyRate = async () => {
  const baseURL = process.env.REACT_APP_DISPATCH_API;
  const currentWeek = {
    from: moment().startOf('week').utc(),
    to: moment().endOf('week').utc(),
  };
  try {
    const response = await http.get(
      `${baseURL}/dashboard/acceptance_rate/daily_acceptance_rate?from=${currentWeek.from}&to=${currentWeek.to}`
    );
    return response.data;
  } catch (err) {
    throw err.response;
  }
};

export default getWeeklyRate;
