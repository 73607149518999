import moment from 'moment';
import http, { handler } from './http';

const api = {
  getAllTickets: (params) =>
    handler(http.get('/dashboard/tickets', { params })),
  getTicketTransactions: (id) =>
    handler(http.get('/dashboard/tickets/ticket_transactions?ticket_id=' + id)),
};

export default api;
