import http from '../http';

const getUsers = async (payload) => {
  const baseURL = process.env.REACT_APP_IDENTITY_API;

  try {
    const response = await http.get(
      `${baseURL}/dashboard/users?deleted=${
        payload.deleted === '0' ? '' : payload.deleted
      }&blocked=${payload.status === '0' ? '' : payload.status}&mobile=%2B249${
        payload.mobile
      }&page_number=${payload.page}&page_size=${payload.pageSize}`
    );
    return response.data;
  } catch (err) {
    if (err.response.status == 401) {
      // localStorage.removeItem('token');
      // window.location.reload();
      throw err.response.statusText;
    } else if (err.response) {
      throw err.response.statusText;
    } else {
      throw err;
    }
  }
};

export default getUsers;
