import React from 'react';
import { Provider } from 'react-redux';
import identityStore from '../../core/app/store';
import DetailsList from '../components/DetailsList';

export default function UserDetailsPage(props) {
  return (
    <Provider store={identityStore}>
      <DetailsList />
    </Provider>
  );
}
