import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import * as React from 'react';
import { usePlacesTranslation } from '../../i18n';

const options = ['all', 'pending', 'approved', 'rejected'];

export default function StatusFilter({
  payload,
  setPayload,
  setIsRefreshing,
  isRefreshing,
  onApply,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);

    {
      index == 0
        ? setPayload({
            ...payload,
            status_id: '',
          })
        : setPayload({
            ...payload,
            status_id: index,
          });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const t = usePlacesTranslation();

  return (
    <Grid
      container
      lg={12}
      md={12}
      justifyContent={'space-between'}
      alignItems='center'
      className='p-1 bg-gray-100 my-2'
    >
      <Grid item lg={10} md={10} className=' text-xs flex '>
        <div className='p-0 mx-3 flex sm:flex-row flex-col'>
          <div className=' block relative'>
            <List
              component='nav'
              aria-label='Device settings'
              // className=' text-center border-blue-600 rounded sm:rounded-l-none border border-b block  w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none'
              className='border border-gray-400  bg-white text-gray-600 rounded-md'
              // sx={{ m: -2 }}
            >
              <ListItem
                button
                id='lock-button'
                aria-haspopup='listbox'
                aria-controls='lock-menu'
                aria-label={'Status'}
                aria-expanded={open ? 'true' : undefined}
                className=' '
                sx={{ width: 200, m: -1 }}
                onClick={handleClickListItem}
              >
                <ListItemText
                  className=' text-center font-semibold  '
                  primary={t('status')}
                  // secondary={options[selectedIndex]}
                />
              </ListItem>
            </List>
            <Menu
              id='lock-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'lock-button',
                role: 'listbox',
              }}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={option}
                  selected={index === selectedIndex}
                  className=''
                  sx={{ width: 300 }}
                  onClick={(event) => handleMenuItemClick(event, index)}
                >
                  {t(option)}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      </Grid>
      <Button
        variant='contained'
        onClick={() => onApply()}
        color='primary'
        className='h-[93%]'
      >
        {t('apply')}
      </Button>
    </Grid>
  );
}
