import { createSlice } from '@reduxjs/toolkit';
import {shareTripApi} from './api'

const initialState = {
trips:[

],
pointsList:[]
}

const shareTripListSlice = createSlice({
 name: 'trips',
  initialState,
  reducers: {
  fetchOrdersSuccess : (state, action) => {
      state.trips = action.payload;
    },
  setTrips : (state, action) => {
      state.trips.push(action.payload);
    },
  setPointsList : (state, action) => {
      state.pointsList.push(action.payload) 
    },
  removeItemSuccess : (state, action) => {
      state.pointsList =  action.payload 
  }
  }
})

export const {
 fetchOrdersSuccess,
 setTrips,
 setPointsList, 
  removeItemSuccess
} = shareTripListSlice.actions;

export const getSharedOrders = (payload) => async (dispatch) => {
  try {
     const response = await shareTripApi.fetchSharedOrders(payload);
    dispatch(fetchOrdersSuccess(response));
  } catch (err) {
    throw err;
  }
};
export const fetchCheckedPrice = (payload) => async () => {
  try {
    await shareTripApi.checkPrice(payload);
  } catch (err) {
    throw err;
  }
};

export const createNewTrip = (payload) => async () => {
  try {
    await shareTripApi.createSharedTrips(payload);
  } catch (err) {
    throw err;
  }
};

export default shareTripListSlice.reducer;
