import http from "../http";

export const createEventProvider = async (formData) => {
  try {
    const response = await http.post(`/dashboard/events_providers`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
};
