import React, { useCallback, useEffect, useState, Component } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { connect, useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import default_image from '../../../../assets/images/defult-pic.png';
import ImageDialog from '../common/ImageDialog';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import { fetchDriverFiles } from '../../../../Redux/store/pending-drivers/pendingDriversListSlice';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { usePendingTranslation } from '../i18n';
import { useTheme } from '@mui/styles';
import { toast } from 'react-toastify';

function DriverFiles(props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { files } = useSelector((state) => state.pendingDrivers).pendingDrivers;
  const { driverId } = props;
  const t = usePendingTranslation();
  //Dialog
  const [img, setImg] = useState(default_image);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenImage = (img_url) => {
    setImg(img_url);
    setIsOpen(true);
  };

  const fetchEverything = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all([dispatch(fetchDriverFiles(driverId))]);
    } catch (err) {
      if (err.status === 401) {
        toast.error(err.data.message);
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });
  useEffect(() => {
    fetchEverything();
  }, [dispatch]);

  const theme = useTheme();

  if (isLoading) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div className='grid min-h-screen place-content-center'>
          <div className='flex items-center  text-xl text-gray-600'>
            <span className='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            loading...
          </div>
        </div>
      </Grid>
    );
  }

  if (!files || files.length === 0) {
    return (
      <Grid container justify='center'>
        <Typography variant='h6' gutterBottom>
          {t('no_files')}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid container lg={12} md={12} spacing={2}>
      {/* dialog */}
      <ImageDialog open={isOpen} setOpen={setIsOpen} image_url={img} />

      <Grid
        item
        lg={6}
        md={6}
        className={theme.direction === 'ltr' ? 'border-l-4 ' : 'border-r-4 '}
      >
        <Grid className='p-4'>
          <h1>{t('avatar_image')}</h1>
        </Grid>

        <ImageList sx={{ width: '100%' }} cols={3}>
          {files.map((file) => {
            if (file.type_id === 1) {
              return (
                <ImageListItem key={file.id}>
                  <img
                    src={file.image_url || default_image}
                    alt={file.file_type.name}
                    // classNameName=' w-36 h-24 hover:cursor-pointer'
                    onClick={() => {
                      handleOpenImage(file.image_url);
                    }}
                  />
                </ImageListItem>
              );
            }
          })}
        </ImageList>
      </Grid>

      <Grid
        item
        lg={6}
        md={6}
        className={theme.direction === 'ltr' ? 'border-l-4 ' : 'border-r-4 '}
      >
        <Grid className='p-4'>
          <h1>{t('id_image')}</h1>
        </Grid>

        <ImageList sx={{ width: '100%' }} cols={3}>
          {files.map((file) => {
            if (file.type_id === 2) {
              return (
                <ImageListItem key={file.id}>
                  <img
                    src={file.image_url || default_image}
                    alt={file.file_type.name}
                    // classNameName=' w-36 h-24 hover:cursor-pointer'
                    onClick={() => {
                      handleOpenImage(file.image_url);
                    }}
                  />
                </ImageListItem>
              );
            }
          })}
        </ImageList>
      </Grid>

      <Grid
        item
        lg={6}
        md={6}
        className={theme.direction === 'ltr' ? 'border-l-4 ' : 'border-r-4 '}
      >
        <Grid className='p-4'>
          <h1>{t('car_inside_image')}</h1>
        </Grid>
        <ImageList sx={{ width: '100%' }} cols={3}>
          {files.map((file) => {
            if (file.type_id === 3) {
              return (
                <ImageListItem key={file.id}>
                  <img
                    src={file.image_url || default_image}
                    alt={file.file_type.name}
                    // classNameName=' w-36 h-24 hover:cursor-pointer'
                    onClick={() => {
                      handleOpenImage(file.image_url);
                    }}
                  />
                </ImageListItem>
              );
            }
          })}
        </ImageList>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        className={theme.direction === 'ltr' ? 'border-l-4 ' : 'border-r-4 '}
      >
        <Grid className='p-4'>
          <h1>{t('car_outside_image')}</h1>
        </Grid>
        <ImageList sx={{ width: '100%' }} cols={3}>
          {files.map((file) => {
            if (file.type_id === 4) {
              return (
                <ImageListItem key={file.id}>
                  <img
                    src={file.image_url || default_image}
                    alt={file.file_type.name}
                    // classNameName=' w-36 h-24 hover:cursor-pointer'
                    onClick={() => {
                      handleOpenImage(file.image_url);
                    }}
                  />
                </ImageListItem>
              );
            }
          })}
        </ImageList>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        className={theme.direction === 'ltr' ? 'border-l-4 ' : 'border-r-4 '}
      >
        <Grid className='p-4'>
          <h1>{t('driver_license_image')}</h1>
        </Grid>
        <ImageList sx={{ width: '100%' }} cols={3}>
          {files.map((file) => {
            if (file.type_id === 5) {
              return (
                <ImageListItem key={file.id}>
                  <img
                    src={file.image_url || default_image}
                    alt={file.file_type.name}
                    // classNameName=' w-36 h-24 hover:cursor-pointer'
                    onClick={() => {
                      handleOpenImage(file.image_url);
                    }}
                  />
                </ImageListItem>
              );
            }
          })}
        </ImageList>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        className={theme.direction === 'ltr' ? 'border-l-4 ' : 'border-r-4 '}
      >
        <Grid className='p-4'>
          <h1>{t('car_search_certificate_image')}</h1>
        </Grid>
        <ImageList sx={{ width: '100%' }} cols={3}>
          {files.map((file) => {
            if (file.type_id === 6) {
              return (
                <ImageListItem key={file.id}>
                  <img
                    src={file.image_url || default_image}
                    alt={file.file_type.name}
                    // classNameName=' w-36 h-24 hover:cursor-pointer'
                    onClick={() => {
                      handleOpenImage(file.image_url);
                    }}
                  />
                </ImageListItem>
              );
            }
          })}
        </ImageList>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        className={theme.direction === 'ltr' ? 'border-l-4 ' : 'border-r-4 '}
      >
        <Grid className='p-4'>
          <h1>{t('emergency_transport_license_image')}</h1>
        </Grid>
        <ImageList sx={{ width: '100%' }} cols={3}>
          {files.map((file) => {
            if (file.type_id === 7) {
              return (
                <ImageListItem key={file.id}>
                  <img
                    src={file.image_url || default_image}
                    alt={file.file_type.name}
                    // classNameName=' w-36 h-24 hover:cursor-pointer'
                    onClick={() => {
                      handleOpenImage(file.image_url);
                    }}
                  />
                </ImageListItem>
              );
            }
          })}
        </ImageList>
      </Grid>
    </Grid>
  );
}

export default DriverFiles;
