import React, { useState } from 'react';
import Nav2 from './layout compnents/Nav2';
import Nav from './layout compnents/Nav';
import SideBar from './layout compnents/SideBar';
import Alerts from './layout compnents/Alert';

const Layout = (props) => {
  const { isAuthenticated, setIsAuthenticated } = props;
  const [state, setState] = useState(true);

  return (
    <div className='relative h-[100vh]'>
      {/* alerts */}
      <div className=' absolute z-30 top-10 w-full flex justify-center'>
        <Alerts />
      </div>

      {/* NavBar container  */}
      <div className=' sticky top-0 z-20 '>
        <Nav
          setState={setState}
          state={state}
          setIsAuthenticated={setIsAuthenticated}
          isAuthenticated={isAuthenticated}
        />
      </div>
      {/* sidebar */}
      <div className='flex'>
        {isAuthenticated && state && (
          <div className='h-auto min-h-[95vh] w-[230px] text-black bg-gray-100'>
            <SideBar />
          </div>
        )}
        {/* main  */}
        <div
          className={
            isAuthenticated && state
              ? 'relative p-2 basis-11/12 overflow-hidden'
              : 'relative w-full overflow-hidden'
          }
        >
          {/* main content */}
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
