const driver_en = {
  dates: 'Dates',
  driver_info: 'Driver info',
  account_info: 'Account info',
  car_info: 'Car info',
  Drivers_status: 'Drivers status',
  all: 'All',
  blocked: 'Blocked',
  blocked_by_payment: 'Blocked by payment',
  temporary_blocked: 'Temporary blocked',
  car_type: ' Car type',
  fees_groups: 'Fees groups ',
  mobile: 'Mobile',
  drivers: 'Drivers',
  drivers_list: 'Drivers List ',
  driver_details: 'Driver Details',

  driver_id: 'Driver ID',
  driver_info: 'Driver Info ',
  mobile_number: ' Mobile number',
  driver_license: 'Driver license ',
  national_id: 'National ID',
  referral_number: ' Referral number',
  device_os: 'Device OS ',
  plate_number: 'Plate number',
  color: 'Color',
  description: 'Description',
  car_status: 'Car status',
  car_model: 'Car model',
  manufacture_date: 'Manufacture date ',
  vehicle_type: 'Vehicle type ',
  update_info: 'Update Info ',
  account_info: ' Account Info',
  send_request: ' Send request',
  reason: 'Reason',

  create_ticket: 'Create ticket',
  new_ticket: 'New Ticket ',
  supervisor_note: 'Supervisor note',
  temporary_block: 'Temporary block',
  all: 'All',
  blocked: 'Blocked',
  block: 'Block',
  block_dialog: 'Block Form',
  reference_type: ' Reference type ',
  // // reference_type: '',
  new_otp: 'New OTP',
  amount: 'Amount',
  narration: 'Narration',
  created_at: 'Created at',
  total_balance: 'Total Balance ',
  add_note: 'Add Note',
  add_balance: ' Add Balance',
  deduct_balance: 'Deduct Balance',
  date: 'Date',
  // status: 'الحاله',
  revert: 'Revert ',
  activate_driver: 'Activate driver  ',
  no_files: 'No Files',
  reject_driver: ' Reject Driver ',
  avatar_image: 'Avatar image ',
  id_image: 'ID image ',
  car_inside_image: 'Car inside image',
  car_outside_image: 'Car outside image ',
  driver_license_image: 'Driver license image',
  car_search_certificate_image: 'Car search certificate image',
  emergency_transport_license_image: 'Emergency transport license image',
  update_reason: 'Update Reason ',
  user_mobile: 'User mobile',

  // total: 'إجمالي',
  // from: 'من',
  // to: 'الى',
  client_info: 'Client info',
  dispatch_status: 'Dispatch status',
  distance: 'Distance',
  paid_info: 'Paid info',
  // SDG: 'جنيه',
  dates_info: 'Dates Info ',
  // action: 'عملية',
  name: 'Name',
  note: 'Note',
  supervisor_name: 'Supervisor name ',
  daily: 'Daily',
  weekly: 'weekly',
  acceptance_rate: 'Acceptance rate ',
  active_time: 'active time',
  rating: 'Rating',
  guarantees: 'Guarantees',
  bonus: 'Bonus',
  ignored_dispatches: 'Ignored dispatches',
  canceled_dispatches: 'Canceled dispatches',
  completed_dispatches: 'Completed dispatches',
  view: 'View',
  transaction_ID: 'Transaction ID',
  role: 'Role',
  msg: 'Revert Balance',
  online: 'Online',
  active: 'Active',
  available: 'Available',
  msg_success: 'updated details successfully',
  msg_unblock: 'Unblocked successfully',
  msg_block: 'Blocked successfully',
  msg_note: 'Note added successfully',
};

export default driver_en;
