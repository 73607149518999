import http3 from '../../../services/APIs/taxi_api';

export const shareTripApi = {
  fetchSharedOrders: async (payload) => {
    try {
      const response = await http3.post('/dashboard/orders', payload);
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  checkPrice: async (payload) => {
    try {
      const response = await http3.post('/dashboard/trips/check_price', payload);
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  createSharedTrips: async (payload) => {
    try {
      const response = await http3.post('/dashboard/trips', payload);
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

 }