import {
  AccountCircleOutlined,
  Add,
  AssignmentInd,
  BarChart,
  CollectionsBookmark,
  ContactPhone,
  Equalizer,
  FiberPin,
  ListAlt,
  Person,
  Place,
  Room,
  TrendingUp,
  LocalTaxi,
  MapTwoTone,
  GroupWork,
  Delete,
  RateReview,
  Report,
  PersonPin,
} from '@material-ui/icons';
import {
  BookOnline,
  Event,
  LockClock,
  Map,
  TimeToLeave,
  TimeToLeaveOutlined,
} from '@mui/icons-material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const menuItems = [
  {
    title: 'lemon dispatch',
    items: [
      {
        name: 'Acceptance Rate',
        Icon: () => <TrendingUp />,
        url: '/dispatch/acceptance_rate',
      },
      {
        name: 'Dispatches',
        Icon: () => <ContactPhone />,
        url: '/dispatches',
      },
      {
        name: 'Drivers',
        Icon: () => <AccountCircleOutlined />,
        url: '/drivers',
      },
      {
        name: 'Pending Drivers',
        Icon: () => <AssignmentInd />,
        url: '/drivers/pending-drivers',
      },

      {
        name: 'CarTypes',
        Icon: () => <LocalTaxi />,
        url: '/dispatch/cartypes/',
      },
      // {
      //   name: 'Supply Demand',
      //   Icon: () => <Map />,
      //   url: '/dispatch/supply_demand/',
      // },
      {
        name: 'System Config',
        Icon: () => <LockClock />,
        url: '/dispatch/system_config',
      },
      {
        name: 'Tracking drivers',
        Icon: () => <MapTwoTone />,
        url: '/dispatch/tracking/',
      },
    ],
  },
  {
    title: 'Lemon Taxi',
    items: [
      {
        name: 'New Order',
        Icon: () => <Add />,
        url: '/orders/create-order',
      },
      {
        name: 'Shared Trips',
        Icon: () => <LocalTaxi />,
        url: '/shareTrip',
      },
      {
        name: 'Orders',
        Icon: () => <ListAlt />,
        url: '/orders',
      },
      {
        name: 'Users',
        Icon: () => <Person />,
        url: '/users',
      },
      {
        name: 'Drivers Tickets',
        Icon: () => (
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M10 6H7C6.46957 6 5.96086 6.21071 5.58579 6.58579C5.21071 6.96086 5 7.46957 5 8V17C5 17.5304 5.21071 18.0391 5.58579 18.4142C5.96086 18.7893 6.46957 19 7 19H16C16.5304 19 17.0391 18.7893 17.4142 18.4142C17.7893 18.0391 18 17.5304 18 17V14'
              stroke='currentColor'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M17 10C18.6569 10 20 8.65685 20 7C20 5.34314 18.6569 4 17 4C15.3431 4 14 5.34314 14 7C14 8.65685 15.3431 10 17 10Z'
              stroke='currentColor'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        ),
        url: '/tickets/drivers',
      },
      {
        name: 'Users Tickets',
        Icon: () => (
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M10 6H7C6.46957 6 5.96086 6.21071 5.58579 6.58579C5.21071 6.96086 5 7.46957 5 8V17C5 17.5304 5.21071 18.0391 5.58579 18.4142C5.96086 18.7893 6.46957 19 7 19H16C16.5304 19 17.0391 18.7893 17.4142 18.4142C17.7893 18.0391 18 17.5304 18 17V14'
              stroke='currentColor'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M17 10C18.6569 10 20 8.65685 20 7C20 5.34314 18.6569 4 17 4C15.3431 4 14 5.34314 14 7C14 8.65685 15.3431 10 17 10Z'
              stroke='currentColor'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        ),
        url: '/tickets/users',
      },
      {
        name: 'Orders Statistics',
        Icon: () => <Equalizer />,
        url: '/orders/statistics',
      },
      {
        name: "Generate OTP's",
        Icon: () => <FiberPin />,
        url: '/users/otps-generate/',
      },
    ],
  },
  {
    title: 'Lemon Places',
    items: [
      {
        name: 'Tracking Map',
        Icon: () => <MapTwoTone />,
        url: '/places/tracking-map',
      },
      {
        name: 'New Place',
        Icon: () => <Add />,
        url: '/places/add-place/',
      },
      {
        name: 'Places',
        Icon: () => <Place />,
        url: '/places/',
      },
      {
        name: 'Reviews',
        Icon: () => <RateReview />,
        url: '/places/reviews/',
      },
      {
        name: 'Reports',
        Icon: () => <Report />,
        url: '/places/reports/',
      },
      {
        name: 'New Group',
        Icon: () => <Add />,
        url: '/places/add/groups/',
      },
      {
        name: 'Groups',
        Icon: () => <GroupWork />,
        url: '/places/groups/',
      },
    ],
  },
  {
    title: 'Lemon events',
    items: [
      {
        name: 'Events Providers',
        Icon: () => <PersonPin />,
        url: '/events/providers',
      },
      {
        name: 'Add New Event',
        Icon: () => <Add />,
        url: '/events/add',
      },
      {
        name: 'Events',
        Icon: () => <Event />,
        url: '/events/',
      },
      {
        name: 'Events Tickets',
        Icon: () => <BookOnline />,
        url: '/events/tickets',
      },
    ],
  },
  {
    title: 'Lemon food',
    items: [],
  },
  {
    title: 'Lemon pay',
    items: [],
  },
  {
    title: 'Lemon identity',
    items: [{ name: 'Users', Icon: () => <Person />, url: '/identity/users' }],
  },
];

export default function SideBar() {
  const { t } = useTranslation();

  let menuArray = new Array(menuItems.length).fill(false);
  const [menu, setMenu] = useState(menuArray);
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  const setMenuValue = (props) => {
    let newArr = [...menu];
    newArr[props] = !newArr[props];
    setMenu(newArr);
  };

  return (
    <div>
      <div className='rounded-r bg-white xl:hidden flex justify-between w-full  items-center '>
        <div aria-label='toggler' className='flex justify-center items-center'>
          <button
            aria-label='open'
            id='open'
            onClick={() => setShow(true)}
            className={`${
              show ? 'hidden' : ''
            } focus:outline-none focus:ring-2`}
          >
            <svg
              width={24}
              height={24}
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M4 6H20'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M4 12H20'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M4 18H20'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </button>
          <button
            aria-label='close'
            id='close'
            onClick={() => setShow(false)}
            className={`${
              show ? '' : 'hidden'
            } focus:outline-none focus:ring-2`}
          >
            <svg
              width={24}
              height={24}
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M18 6L6 18'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M6 6L18 18'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        id='Main'
        className={`${
          show ? 'translate-x-0' : '-translate-x-full'
        } xl:rounded-r transform  xl:translate-x-0  ease-in-out transition duration-500 flex justify-start items-start h-full  w-full sm:w-64 flex-col`}
      >
        {menuItems.map((item, i) => (
          <div
            key={i}
            className='flex flex-col justify-start items-center  w-full  '
          >
            <button
              onClick={() => setMenuValue(i)}
              className='focus:outline-none focus:text-gray-900 font-bold   flex justify-evenly items-center w-full py-5'
            >
              <p className='text-sm leading-5  uppercase'>{t(item.title)}</p>
              <svg
                id='icon1'
                className={`${
                  menu[i] ? '' : 'rotate-180'
                } transform duration-100`}
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M18 15L12 9L6 15'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </button>
            <div
              id='menu1'
              className={`${
                menu[i] ? 'flex' : 'hidden'
              } justify-start  flex-col w-full md:w-auto items-start pb-1 `}
            >
              {item.items.map(({ url, name, Icon }) => (
                <button
                  key={url}
                  onClick={() => navigate(url)}
                  className='flex justify-start items-center mx-6 hover:text-white focus:bg-gray-700 focus:text-white hover:bg-gray-700 rounded px-3 py-2  w-full md:w-52'
                >
                  <Icon />
                  <p className='text-sm leading-4 mx-3'>{t(name)}</p>
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
