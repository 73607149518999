import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { HashRouter as Router } from 'react-router-dom';
import history from './@history';

import './i18n/index';
import storage from './storage';

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

document
  .getElementsByTagName('body')[0]
  .setAttribute('dir', storage.lang.getDirection());
