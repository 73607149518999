import { Grid } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import {
  deleteGroupPlace,
  getGroupPlaces,
} from '../../../../Redux/store/places/placesListSlice';
import { usePlacesTranslation } from '../../i18n';
import PlacesList from '../PlacesList';

const GroupPlaces = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(0);
  const [page, setPage] = useState(0);
  const { groupPlaces, groupDetails } = useSelector(
    (state) => state.places
  ).places;
  const dispatch = useDispatch();

  const t = usePlacesTranslation();

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(
        getGroupPlaces({
          group_id: groupDetails.group_id,
          page: page,
          pageSize: 10,
        })
      );
    } catch (err) {
      toast.error(err.data?.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  const deletePlace = useCallback(async (place_id) => {
    const id = groupPlaces.find(
      (place) => place.place.place_id === place_id
    ).group_place_id;
    try {
      setIsLoading(true);
      await dispatch(deleteGroupPlace({ place_id: id }));
      toast.success(t('place_was_successfully_deleted'));
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
      setIsRefreshing(isRefreshing + 1);
    }
  });

  useEffect(() => {
    fetchData();
  }, [isRefreshing, page]);

  return (
    <Grid container lg={12} md={12} justifyContent='center'>
      <Grid item lg={12} md={12} className='bg-white'>
        <PlacesList
          data={groupPlaces
            .map((place) => place.place)
            .filter((item) => item !== null)}
          setPage={setPage}
          page={page}
          isLoading={isLoading}
          isRefreshing={isRefreshing}
          setIsLoading={setIsLoading}
          setIsRefreshing={setIsRefreshing}
          deleteAction={deletePlace}
        />
      </Grid>
    </Grid>
  );
};

export default GroupPlaces;
