import getDriversTickets from './getDriversTickets';
import assignSupportUser from './assignSupportUser';
import createTicket from './createTicket';
import getSupportUsers from './getSupportUsers';
import getTicketCategories from './getTicketCategories';
import getTicketDetails from './getTicketDetails';
import getTicketMessages from './getTicketMessages';
import getTicketTransactions from './getTicketTransactions';
import sendMessage from './sendMessage';
import updateTicketDetails from './updateTicketDetails';
import updateTicketStatus from './updateTicketStatus';

export default {
  getDriversTickets,
  getSupportUsers,
  getTicketCategories,
  getTicketDetails,
  createTicket,
  updateTicketDetails,
  updateTicketStatus,
  assignSupportUser,
  getTicketMessages,
  getTicketTransactions,
  sendMessage,
};
