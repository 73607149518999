import moment from 'moment';
import React, { useCallback, useRef, useState } from 'react';
import { Bar, getElementsAtEvent } from 'react-chartjs-2';
import { useDispatch } from 'react-redux';
import {
  fetchRateDetails,
  setAlert,
} from '../../../../Redux/store/app/appSlice';
import DetailsDailog from './DetailsDailog';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const RateChart = ({ data, type }) => {
  const chartRef = useRef();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const getDetails = useCallback(async (payload) => {
    try {
      await dispatch(fetchRateDetails(payload));
      setIsOpen(true);
    } catch (err) {
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
    }
  });
  const handleClick = (e) => {
    const element = getElementsAtEvent(chartRef.current, e);
    const index = element[0].index;
    const seleted = data[index];
    getDetails({ from: seleted.from, to: seleted.to });
  };
  let count = 0;

  return (
    <>
      <Bar
        ref={chartRef}
        className='max-h-96 bg-gray-100'
        datasetIdKey='id'
        data={{
          labels: data.map((t) => {
            switch (type) {
              case 'hourly':
                return moment(t.from).format('h a');
              case 'daily':
                return moment(t.from).format('ddd');
              case 'weekly':
                count = count + 1;
                return `Week ${count}`;
              // return `Week ${moment(t.from).isoWeek()}`;

              default:
                return null;
            }
          }),
          datasets: [
            {
              id: 1,
              label: '%',
              data: data.map((data) => data.acceptance_rate),
              backgroundColor: ['blue'],
            },
          ],
        }}
        options={{
          layout: {
            padding: {
              top: 5,
              left: 15,
              right: 15,
              bottom: 15,
            },
          },
        }}
        onClick={handleClick}
      />
      <DetailsDailog open={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default RateChart;
