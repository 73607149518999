import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Pane,
  Circle,
  Polyline,
  useMap,
} from 'react-leaflet';
import { useSelector } from 'react-redux';
import './Map.css';
import PinDropIcon from '@material-ui/icons/PinDrop';
import L, { map } from 'leaflet';

const Map = ({ lang, lat }) => {
  return (
    <MapContainer
      center={[lat, lang] || []}
      zoom={11}
      scrollWheelZoom={true}
      // style={{ height: '100%' }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {/* dispatch location */}
      <Marker
        position={[lat, lang] || []}
        // icon={car}
      >
        <Popup closeButton={false}>Pickup</Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
