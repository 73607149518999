import React, { useState, useCallback } from 'react';
import { Grid, Button, CircularProgress, Avatar, Rating } from '@mui/material';

import { Empty } from '../../../drivers/components/common/Empty';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import default_image from '../../../../assets/images/place-moose.png';
import SimpleBackdrop from '../../../../components/common/LoadingIndicator';

import RejectDialog from '../common/RejectDialog';
import ApproveDialog from '../common/ApproveDialog';
import { makeStyles } from '@mui/styles';
import { usePlacesTranslation } from '../../i18n';
import CustomPagination from '../../../../components/new-components/CustomPagination';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

function ReportsContainer({
  data,
  isLoading,
  setIsLoading,
  setPage,
  isRefreshing,
  setIsRefreshing,
  page,
}) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const t = usePlacesTranslation();

  const classes = useStyles();
  const cols = [
    {
      field: 'place',
      headerName: t('place'),
      headerClassName: ' bg-gray-100 text-center w-full ',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Link to={'/places/' + params.value.place_id}>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            display={'flex'}
            justifyContent='space-between'
            className='text-xs px-2'
          >
            <Grid item lg={2} md={2}>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                className='flex justify-center  cursor-pointer'
              >
                <Avatar
                  src={params.value.place_logo || default_image}
                  className={classes.small}
                />
              </Grid>
            </Grid>
            <Grid item lg={8} md={8}>
              <Grid item lg={12} md={12} xs={12}>
                {params.value.place ? params.value.place.name : '...'}
              </Grid>
            </Grid>
          </Grid>
        </Link>
      ),
    },
    {
      field: 'user',
      headerName: t('user'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          spacing={2}
          className={
            params.value.user_id
              ? 'text-xs font-medium p-1 hover:bg-blue-200  hover:cursor-pointer'
              : 'text-xs p-1'
          }
          onClick={() => {
            navigate(`/users/${params.value.user_id}`);
          }}
        >
          <Grid item lg={8} md={8}>
            <Grid item lg={12} md={12} xs={12}>
              {params.value.full_name}
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              {params.value.mobile}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'status_id',
      headerName: t('status'),
      headerClassName: ' bg-gray-100 text-center w-full ',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className=' text-sm font-semibold px-2'
        >
          <Grid item lg={12} md={12} xs={12} className=''>
            {params.value.status_id === 1 ? (
              <div className='text-blue-700 uppercase'>{t('pending')}</div>
            ) : params.value.status_id === 2 ? (
              <div className='text-green-800 uppercase'>{t('approved')}</div>
            ) : (
              <div className='text-red-800 uppercase'>{t('rejected')}</div>
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'comment',
      headerName: t('comment'),
      headerClassName: ' bg-gray-100 text-center w-full  ',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className='flex text-xs font-serif font-semibold h-full pl-2 whitespace-normal items-end '
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            className='line-clamp-3 overflow-y-scroll'
          >
            {params.value.comment}
          </Grid>
          <Grid item lg={12} md={12} xs={12} className='slef-start'>
            {/* <Rating value={params.value.rating} disabled /> */}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'message',
      headerName: t('message'),
      headerClassName: ' bg-gray-100 text-center w-full ',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className='flex text-xs font-serif font-semibold h-full pl-2 whitespace-normal items-end '
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            className='line-clamp-3 overflow-y-scroll'
          >
            {params.value.message}
          </Grid>
          <Grid item lg={12} md={12} xs={12} className='slef-start'>
            {/* <Rating value={params.value.rating} disabled /> */}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'note',
      headerName: t('note'),
      headerClassName: ' bg-gray-100 text-center w-full ',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Grid
          container
          lg={12}
          md={12}
          xs={12}
          className=' text-xs font-serif font-semibold px-2'
        >
          <Grid item lg={12} md={12} xs={12} className=''>
            {params.value.support_note}
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'action',
      headerName: t('actions'),
      headerClassName: ' bg-gray-100 text-center w-full',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.value.status_id === 1 ? (
            <>
              <ApproveDialog
                type={'approve'}
                id={params.value.id}
                open={open}
                setOpen={setOpen}
                isRefreshing={isRefreshing}
                setIsRefreshing={setIsRefreshing}
                setIsLoading={setIsLoading}
              />
              {/* <Grid item className='pr-1'> */}
              <ApproveDialog
                type={'reject'}
                id={params.value.id}
                open={open}
                setOpen={setOpen}
                isRefreshing={isRefreshing}
                setIsRefreshing={setIsRefreshing}
                setIsLoading={setIsLoading}
              />
              {/* </Grid> */}
            </>
          ) : (
            <div className='text-center w-full'>...</div>
          )}
        </>
      ),
    },
  ];

  const rowsData = data.map((item) => {
    return {
      id: item.id,
      place: item,
      user: item.user,
      status_id: item,
      comment: item.place_comment,
      message: item,
      note: item,
      action: item,
    };
  });

  return (
    <div className='flex h-auto w-full '>
      <div className=' flex-grow'>
        <DataGrid
          disableColumnFilter
          autoHeight
          density='standard'
          rowHeight={60}
          loading={isLoading}
          rows={rowsData}
          columns={cols}
          page={page}
          pageSize={1}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => setPage(newPage)}
          components={{
            Toolbar: null,
            Pagination: () => CustomPagination(isLoading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: SimpleBackdrop,
          }}
        />
      </div>
    </div>
  );
}

export default ReportsContainer;
