import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { dialog } from '../../../../core/features/acceptance-rate/acceptanceRateSlice';

export default function DetailsDailog() {
  const { rateDetails, isDailogOpen } = useSelector((state) => state.acceptanceRates);
  const dispatch = useDispatch();
  const handleClose = async () => {
    // setIsOpen(false);
    await dispatch(dialog());
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby='simple-dialog-title' open={isDailogOpen}>
      <DialogTitle id='simple-dialog-title'>Details</DialogTitle>
      <Grid container lg={12} md={12} className='p-6'>
        <Grid item lg={12} md={12} className='p-2'>
          <span className='text-xl text-gray-700 font-serif font-semibold capitalize'>
            acceptance rate:
          </span>
          <span className=' text-base text-gray-800 font-serif p-2 bg-gray-300 rounded-lg ml-2 '>
            {rateDetails.acceptance_rate}
          </span>{' '}
          %
        </Grid>
        <Grid item lg={12} md={12} className='p-2'>
          <span className='text-xl text-gray-700 font-serif font-semibold capitalize'>
            accepted:
          </span>
          <span className=' text-base text-gray-800 font-serif p-2 bg-gray-300 rounded-lg ml-2 '>
            {rateDetails.total_accepted}
          </span>
        </Grid>
        <Grid item lg={12} md={12} className='p-2'>
          <span className='text-xl text-gray-700 font-serif font-semibold capitalize'>
            rejected:
          </span>
          <span className=' text-base text-gray-800 font-serif p-2 bg-gray-300 rounded-lg ml-2 '>
            {rateDetails.total_rejected}
          </span>
        </Grid>
        <Grid item lg={12} md={12} className='p-2'>
          <span className='text-xl text-gray-700 font-serif font-semibold capitalize'>
            timeout:
          </span>
          <span className=' text-base text-gray-800 font-serif p-2 bg-gray-300 rounded-lg ml-2 '>
            {rateDetails.total_timeout}
          </span>
        </Grid>
        <Grid item lg={12} md={12} className='p-2'>
          <span className='text-xl text-gray-700 font-serif font-semibold capitalize'>
            dispatched:
          </span>
          <span className=' text-base text-gray-800 font-serif p-2 bg-gray-300 rounded-lg ml-2 '>
            {rateDetails.total_dispatched}
          </span>
        </Grid>
      </Grid>
    </Dialog>
  );
}

DetailsDailog.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
