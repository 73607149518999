import React from 'react';

import { useDispatch } from 'react-redux';
import {
  hidePlace,
  showPlace,
} from '../../../../Redux/store/places/placesListSlice';
import { useCallback } from 'react';
import { Grid, Button } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { usePlacesTranslation } from '../../i18n';
import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cancel: {
    backgroundColor: '#575757',
    color: 'white',
    border: '2px',
    paddingLeft: '8px',
    paddingRight: '8px',
    '&:hover': {
      backgroundColor: '#383838',
      color: 'white',
      boxShadow: 'none',
    },
  },

  text: {
    fontSize: '18px',
    color: '#151515',
  },
  delete: {
    backgroundColor: '#006DB5',
    color: 'white',
    border: '2px',
    paddingLeft: '8px',
    paddingRight: '8px',
    '&:hover': {
      backgroundColor: '#00559A',
      color: 'white',
      boxShadow: 'none',
    },
  },
  dialog: {},
}));

export default function DisplayDialog(props) {
  const { id, hidden, setIsLoading, isRefreshing, setIsRefreshing } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleVisibilty = useCallback(async () => {
    try {
      setIsLoading(true);
      if (hidden) {
        await dispatch(showPlace({ id: id }));
      } else {
        await dispatch(hidePlace({ id: id }));
      }
      toast.success(t('done_successfully'));
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsRefreshing(isRefreshing + 1);
      setOpen(false);
      setIsLoading(false);
    }
  });

  const t = usePlacesTranslation();

  return (
    <div>
      <div onClick={handleClickOpen}>
        {hidden ? (
          <Visibility
            color='secondary'
            className=' hover:bg-blue-200 hover:cursor-pointer'
          />
        ) : (
          <VisibilityOff
            color='error'
            className=' hover:bg-blue-200 hover:cursor-pointer'
          />
        )}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        // className={classes.Dialog}
        fullWidth
        maxWidth='sm'
      >
        {' '}
        <DialogTitle
          id='alert-dialog-title'
          style={{ color: '#FF392E', fontWeight: 'bold' }}
        >
          {t('warning')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='alert-dialog-description'
            className={classes.text}
          >
            {hidden ? (
              <Grid item className='pb-2' lg={12} md={12}>
                {/* Are you sure you want to show this place ! */}
                {t('dialog_show_msg')}
              </Grid>
            ) : (
              <Grid item className='pb-2' lg={12} md={12}>
                {/* Are you sure you want to hide this place ! */}
                {t('dialog_hide_msg')}
              </Grid>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {t('cancel')}
          </Button>
          <Button onClick={handleVisibilty} autoFocus color='error'>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
