import { Route } from 'react-router-dom';
// import DriversTicketDetailsPage from './pages/ticket-details-page';
import TicketsListPage from './pages/tickets-list-page';

export function ticketsRoutes() {
  return (
    <Route path='/dispatch/drivers-tickets' key={22}>
      <Route
        path='/dispatch/drivers-tickets/'
        element={<TicketsListPage />}
        key={'tickets_list_page'}
      />
      {/* <Route
        path='/dispatch/drivers-tickets/:id'
        element={<DriversTicketDetailsPage />}
        key={'ticket_details_page'}
      /> */}
    </Route>
  );
}
