import React, { useEffect, useCallback } from 'react';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
} from '@mui/material';
import { Person } from '@mui/icons-material';

import { useSelector, useDispatch } from 'react-redux';
import {
  assignSupportUser,
  fetchTicketSupportUsersList,
} from '../../../../Redux/store/tickets/ticketsListSlice';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import { useTicketTranslation } from '../../i18n';
import { toast } from 'react-toastify';

const AssignDialog = (props) => {
  const { handleCloseAction, isOpen, ticketId, isRefreshing, setIsRefreshing } =
    props;
  const { supportUsersList } = useSelector(
    (state) => state.tickets
  ).ticketsList;

  const dispatch = useDispatch();
  const t = useTicketTranslation();

  const handleAssignSupportUser = useCallback(async (userId) => {
    try {
      await dispatch(
        assignSupportUser({ support_user_id: userId, id: ticketId })
      );

      toast.success(t('msg_asign'));
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsRefreshing(isRefreshing + 1);
    }
  });

  const handleListItemClick = (userId) => {
    handleAssignSupportUser(userId);
    handleCloseAction();
  };

  const fetchSupport = async () => {
    try {
      await dispatch(fetchTicketSupportUsersList());
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      dispatch(
        setAlert({
          type: 'error',
          message: err.data.message,
          isOpen: true,
        })
      );
    }
  };

  useEffect(() => {
    fetchSupport();
  }, [isOpen]);

  return (
    <Dialog
      onClose={handleCloseAction}
      aria-labelledby='simple-dialog-title'
      open={isOpen}
      fullWidth
    >
      <DialogTitle id='simple-dialog-title'>
        {t('assign_support_user')}
      </DialogTitle>
      <List>
        {supportUsersList.map((support_user) => (
          <ListItem
            button
            onClick={() => handleListItemClick(support_user.user_id)}
            key={support_user.user_id}
          >
            <ListItemAvatar>
              <Avatar>
                <Person />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={support_user.full_name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default AssignDialog;
