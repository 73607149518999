import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import {
  needUpdatePendingDriver,
  rejectPendingDriver,
} from '../../../../Redux/store/pending-drivers/pendingDriversListSlice';
import { usePendingTranslation } from '../i18n';
import { appDirection } from '../../../../shared_utils';
import { toast } from 'react-toastify';

export function NeedUpdateDialog({ driverId, isRefreshing, setIsRefreshing }) {
  // const { driverId } = props;
  const [reason, setReason] = React.useState(null);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const t = usePendingTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSending = async () => {
    try {
      await dispatch(
        needUpdatePendingDriver({
          id: driverId,
          reason: reason,
        })
      );

      toast.success(t('msg_update'));
    } catch (err) {
      toast.error(err.data.message);

      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsRefreshing(isRefreshing + 1);
      handleClose();
    }
  };

  return (
    <div dir={appDirection}>
      <Button
        variant='contained'
        color='primary'
        aria-label='outlined primary button group'
        onClick={handleClickOpen}
      >
        {t('update_reason')}
      </Button>
      <Dialog aria-labelledby='dialog-title' open={open} onClose={handleClose}>
        <DialogTitle id='form-dialog-title' onClose={handleClose}>
          {/* {t('add_note')} */}
          {t('update_reason')}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            lg={12}
            md={12}
            justifyContent='center'
            // style={{ width: '100%' }}
            className='pt-4'
            spacing={2}
          >
            <Grid item lg={12} md={12} xs={12}>
              <TextField
                onChange={(e) => setReason(e.target.value)}
                label={t('reason')}
                value={reason}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid
              container
              lg={12}
              md={12}
              className='flex p-2 justify-center  '
              spacing={1}
            >
              <Grid item>
                <Button
                  variant='outlined'
                  color='primary'
                  type='submit'
                  onClick={handleSending}
                >
                  {t('submit')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='outlined'
                  color='inherit'
                  onClick={() => handleClose()}
                >
                  {t('cancel')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
