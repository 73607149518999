import { Button, Grid, MenuItem, Paper, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchOrdersStatis,
  setError,
} from '../../../../Redux/store/users/usersListSlice';
import StatisticsCards from '../common/StatisticsCards';
import { statisData } from '../../utils';
import TotalStatisticsCard from '../common/TotalStatisticsCard';
import { useUsersTranslation } from '../../i18n';
import { appDirection } from '../../../../shared_utils';
import { useTheme } from '@mui/styles';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const Statistics = ({ dir }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false); //component state
  const [isRefreshing, setIsRefreshing] = useState(false); //component state

  const [payload, setPayload] = useState({
    from: moment().format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    car_type_id: null,
  });
  // const { ordersStatis } = useSelector(state => state.users).usersList;

  const { carTypes } = useSelector((state) => state.drivers).driversList; //data
  const dispatch = useDispatch();
  const t = useUsersTranslation();
  const theme = useTheme;
  console.log(dir);

  const fetchStatistics = useCallback(async () => {
    try {
      setIsLoading(true);

      await dispatch(fetchOrdersStatis({ user_id: id, ...payload }));
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    fetchStatistics();
  }, [isRefreshing]);

  const handleDateRangeChange = (type, value) => {
    if (type === 'from') {
      setPayload({ ...payload, from: moment(value).format('YYYY-MM-DD') });
    } else {
      setPayload({ ...payload, to: moment(value).format('YYYY-MM-DD') });
    }
    // setIsRefreshing(isRefreshing+1); //
  };

  const handleCarTypeChange = (value) => {
    setPayload({ ...payload, car_type_id: value });
    // setIsRefreshing(isRefreshing+1);
  };

  if (isLoading) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-[70vh] place-content-center'>
          <div class='flex items-center gap-2 text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            loading...
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <Grid container lg={12} md={12} dir={appDirection}>
      <Grid container lg={12} md={12} className=' flex justify-end p-1 '>
        <Grid container lg={11} md={11} className='' spacing={2}>
          <Grid item lg={2} md={2} sm={2} xs={2} sx={{ mt: -1 }}>
            <TextField
              className='bg-white'
              variant='outlined'
              id='from'
              label={t('from')}
              margin='dense'
              type='date'
              size='small'
              fullWidth
              value={payload.from}
              onChange={(e) => handleDateRangeChange('from', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={6} xs={2} sx={{ mt: -1 }}>
            <TextField
              className='bg-white'
              variant='outlined'
              id='to'
              label={t('to')}
              type='date'
              margin='dense'
              size='small'
              fullWidth
              value={payload.to}
              onChange={(e) => handleDateRangeChange('to', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={6} xs={2} sx={{ mt: -1 }}>
            <TextField
              className='bg-white'
              variant='outlined'
              id='cartype'
              label={t('car_type')}
              margin='dense'
              size='small'
              fullWidth
              select
              value={payload.car_type_id}
              onChange={(e) => handleCarTypeChange(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value={null}>{t('all')}</MenuItem>
              {carTypes.map((cartype) => (
                <MenuItem value={cartype.id} key={cartype.id}>
                  {cartype.car_name_en}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid
          item
          lg={1}
          md={1}
          sm={1}
          xs={1}
          className='flex justify-center h-full '
        >
          <Button
            variant='contained'
            className='h-full p-8'
            color='primary'
            type='submit'
            onClick={() => setIsRefreshing(isRefreshing + 1)}
          >
            {t('apply')}
          </Button>
        </Grid>
      </Grid>
      {/* body  */}
      <Grid
        container
        lg={12}
        md={12}
        spacing={2}
        justifyContent='space-around'
        className='pt-5'
      >
        {/* total card */}
        <TotalStatisticsCard />

        {/* Cards  */}
        {statisData.map((data) => {
          // setTotal(total+data.total)
          return (
            <StatisticsCards
              name={dir == 'ltr' ? data.status_name_en : data.status_name_ar}
              status_id={data.status_id}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Statistics;
