import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_ar from './common_ar';
import orders_ar from '../modules/orders/i18n/orders_ar';
import orders_en from '../modules/orders/i18n/orders_en';
import ticket_en from '../modules/tickets/i18n/ticket_en';
import ticket_ar from '../modules/tickets/i18n/ticket_ar';
import places_ar from '../modules/places/i18n/places_ar';
import storage from '../storage';
import pending_driver_ar from '../modules/pending_drivers/components/i18n/pending_driver_ar';
import pending_driver_en from '../modules/pending_drivers/components/i18n/pending_driver_en';
import places_en from '../modules/places/i18n/places_en';
import events_en from '../events/services/i18n/events_en';
import events_ar from '../events/services/i18n/events_ar';
import common_en from './common_en';
import identity_en from '../identity/views/i18n/identity_en';
import identity_ar from '../identity/views/i18n/identity_ar';
import users_en from '../modules/users/i18n/users_en';
import users_ar from '../modules/users/i18n/users_ar';
import car_ar from '../dispatch/views/cartypes/i18n/car_ar';
import car_en from '../dispatch/views/cartypes/i18n/car_en';
import rate_en from '../dispatch/views/acceptance-rate/components/i18n/rate_en';
import rate_ar from '../dispatch/views/acceptance-rate/components/i18n/rate_ar';
import driver_en from '../modules/drivers/i18n/driver_en';
import driver_ar from '../modules/drivers/i18n/driver_ar';
import dispatch_ar from '../modules/dispatches/i18n/dispatch_ar';
import dispatch_en from '../modules/dispatches/i18n/dispatch_en';

const resources = {
  en: {
    common: common_en,
    orders: orders_en,
    tickets: ticket_en,
    pendingDriver: pending_driver_en,
    places: places_en,
    events: events_en,
    identity: identity_en,
    users: users_en,
    cars: car_en,
    rate: rate_en,
    drivers: driver_en,
    dispatch: dispatch_en,
  },
  ar: {
    common: common_ar,
    orders: orders_ar,
    places: places_ar,
    tickets: ticket_ar,
    pendingDriver: pending_driver_ar,
    events: events_ar,
    identity: identity_ar,
    users: users_ar,
    cars: car_ar,
    rate: rate_ar,
    drivers: driver_ar,
    dispatch: dispatch_ar,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: storage.lang.getLang(),

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },

  defaultNS: 'common',
});

export default i18n;
