import {
  AppBar,
  Button,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Toolbar,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import DispatchDriversCard from '../../modules/dispatches/components/common/DispatchDriversCard';
import { Empty } from '../../modules/drivers/components/common/Empty';
import { Pagination } from '@mui/material';
import Map from '../../modules/tracking/components/common/auto_dispatch_tracking/Map';
import { GridCloseIcon } from '@mui/x-data-grid-pro';
import { fetchAutoDispatch } from '../../Redux/store/dispatches/dispatchesListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

//mapdialog
const useStyles = makeStyles((theme) => ({
  appBar: {
    // position: 'absolute bottom-0 left-0',
    // paddingTop: '',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
//mapdialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
//mapdialog
function MapDialog(props) {
  // const { data } = useSelector(
  //   (state) => state.dispatches
  // ).dispatchesList;
  const classes = useStyles();
  const { onClose, selectedValue, open, dispatch } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  useEffect(() => {
    console.log(dispatch);
  }, []);

  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <GridCloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* <div className='pt-40'> */}
      <Map
        lang={dispatch.driver_location_longitude}
        lat={dispatch.driver_location_latitude}
      />
      {/* </div> */}
    </Dialog>
  );
}

export default function GlobalDispatchDriversTab({ dispatch_id }) {
  const [open, setOpen] = React.useState(false);

  const { autoDispatches: data } = useSelector(
    (state) => state.dispatches
  ).dispatchesList;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(fetchAutoDispatch({ dispatchId: dispatch_id }));
    } catch (err) {
      toast.error(err.data.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [dispatchesPerPage, setDispatchesPerPage] = useState(3);

  // pagination logic
  const indexOfLastdispatch = currentPage * dispatchesPerPage;
  const indexOfFirstdispatch = indexOfLastdispatch - dispatchesPerPage;
  const currentdispatches = data.slice(
    indexOfFirstdispatch,
    indexOfLastdispatch
  );

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  if (isLoading) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-[80vh] place-content-center'>
          <div class='flex items-center gap-2 text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            loading...
          </div>
        </div>
      </Grid>
    );
  }
  if (data.length === 0) {
    return <Empty />;
  }

  return (
    <Grid
      container
      lg={12}
      md={12}
      justifyContent='center'
      alignContent='center'
      className='relative h-full'
    >
      {currentdispatches.map((dispatch) => (
        <Grid item lg={10} md={10} className='p-5'>
          <DispatchDriversCard
            dispatch={dispatch}
            handleClickOpen={handleClickOpen}
          />
          <MapDialog open={open} onClose={handleClose} dispatch={dispatch} />
        </Grid>
      ))}

      <Grid item lg={12} md={12} className='pt-10 flex justify-evenly'>
        <Pagination
          count={Math.ceil(data.length / dispatchesPerPage)}
          color='primary'
          page={currentPage}
          onChange={handleChange}
        />
      </Grid>
      {/* //mapdialog */}
    </Grid>
  );
}
