import React, { useCallback, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import LoadingIndicator from '../../../components/common/LoadingIndicator';

import DriverDetailsTabs from '../components/DriverDetailsTabs';

import {
  fetchDriver,
  blockDriver,
  unBlockDriver,
  fetchCarTypes,
  setError,
  updateDriver,
} from '../../../Redux/store/drivers/driversListSlice';
import { checkAuth } from '../utils';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import PageHeading from '../../../components/common/PageHeading';

import { toast } from 'react-toastify';
import { useDriverTranslation } from '../i18n';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Driver(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { driver, carTypes, error } = useSelector(
    (state) => state.drivers
  ).driversList;
  const t = useDriverTranslation();

  const { id, type } = useParams();
  const match = useMatch('/drivers/:id');

  const [isLoading, setIsLoading] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [blockDialog, setBlockDialog] = React.useState(false);
  const [isRefreshing, setIsRefreshing] = React.useState(0);

  const updateDriverInfo = async (driverInfo) => {
    try {
      setIsLoading(true);
      const driverId = driver.driver_id;
      await dispatch(updateDriver({ driverId, ...driverInfo }));
      setIsRefreshing(isRefreshing + 1);

      toast.success(t('msg_success'));
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEverything = useCallback(async () => {
    setIsLoading(true);
    try {
      // const  id = id;
      // console.log(id)
      // const mode = 'view';

      return await Promise.all([
        dispatch(fetchCarTypes()),
        dispatch(fetchDriver({ id: id, typeOfId: type })),

        // mode,
      ]);
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    } finally {
      setIsLoading(false);
    }
  });
  useEffect(() => {
    fetchEverything();
  }, [dispatch, match, isRefreshing]);

  async function unBlock() {
    try {
      await dispatch(unBlockDriver(driver.driver_id));

      toast.success(t('msg_unblock'));
      setIsRefreshing(isRefreshing + 1);
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    }
  }

  async function block(payload) {
    // console.log(payload);
    try {
      const id = driver.driver_id;
      const reqPayload = { driver_id: id, ...payload };
      // console.log(reqPayload);
      await dispatch(blockDriver(reqPayload));

      toast.success(t('msg_block'));
      setIsRefreshing(isRefreshing + 1);
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    }
  }
  useEffect(() => checkAuth(error), [isRefreshing]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Grid container lg={12} md={12} spacing={2}>
      <Grid item lg={12} md={12} className='px-2  pl-2 text-white relative'>
        <PageHeading
          previousUrl={'drivers'}
          heading1={t('drivers')}
          heading2={t('drivers_list')}
          heading3={t('driver_details')}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          backBtn
        />
      </Grid>

      {/* main view */}
      <Grid item lg={12} md={12} xs={12}>
        <DriverDetailsTabs
          updateDriver={updateDriverInfo}
          driver={driver}
          carTypes={carTypes}
          block={block}
          unBlock={unBlock}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      </Grid>
    </Grid>
    // </Grid>
  );
}
export default connect(null, {
  fetchDriver,
  blockDriver,
  unBlockDriver,
  fetchCarTypes,
  setError,
  updateDriver,
})(Driver);
