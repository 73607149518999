import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Grid } from '@mui/material';
import { usePlacesTranslation } from '../../i18n';

const options = ['all', 'deleted', 'not_deleted'];

export default function DeleteFilter({
  payload,
  setPayload,
  setIsRefreshing,
  isRefreshing,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    if (index == 0) {
      setPayload({
        ...payload,
        isDeleted: '',
      });
    } else if (index == 1) {
      setPayload({
        ...payload,
        isDeleted: true,
      });
    } else {
      setPayload({
        ...payload,
        isDeleted: false,
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const t = usePlacesTranslation();

  return (
    <Grid container lg={12} md={12} justifyContent>
      <Grid item lg={12} md={12} className=' text-xs '>
        <div className='p-0 flex sm:flex-row flex-col'>
          <div className=' block relative'>
            <List
              component='nav'
              aria-label='Device settings'
              // className=' text-center border-blue-600 rounded sm:rounded-l-none border border-b block  w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none'
              className='border border-gray-400 rounded-md bg-white'
              // sx={{ m: -2 }}
            >
              <ListItem
                button
                id='lock-button'
                aria-haspopup='listbox'
                aria-controls='lock-menu'
                aria-label='Status'
                aria-expanded={open ? 'true' : undefined}
                className=' '
                sx={{ width: 200, m: -1 }}
                onClick={handleClickListItem}
              >
                <ListItemText
                  className=' text-center font-semibold text-blue-700 '
                  primary={t('status')}
                />
              </ListItem>
            </List>
            <Menu
              id='lock-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'lock-button',
                role: 'listbox',
              }}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={option}
                  selected={index === selectedIndex}
                  className=''
                  sx={{ width: 200 }}
                  onClick={(event) => handleMenuItemClick(event, index)}
                >
                  {t(option)}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
