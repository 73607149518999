const ticket_ar = {
  drivers: 'السائقين',
  driver: 'السائق',
  users: 'المستخدمين',
  user: 'المستخدم',
  order_id: 'رقم الطلب',
  ticket_id: 'رقم التذكرة',
  category: 'التصنيفات',
  status: 'الحالة',
  support_user: 'الدعم الفني',
  dates: 'التواريخ',
  date: 'تاريخ',
  actions: 'العمليات',
  new: 'جديد',
  under_process: 'قيد المعالجة',
  closed: 'مغلق',
  phone: 'رقم الهاتف',
  from: 'من',
  to: 'الى',
  drivers_tickets_list: 'قائمة تذاكر السائقين',
  Tickets: 'التذاكر',
  assign_support_user: 'تعيين الدعم الفني',
  tickets_details: 'تفاصيل التذكرة',
  Details: 'التفاصيل',
  notes: 'الملاحظات',
  Chat: 'دردشه',
  Transactions: 'إجراءات',
  low: 'منخفض',
  medium: 'متوسط',
  high: 'مرتفع',
  critical: 'حرج',
  created_at: 'تم الإنشاء',
  description: 'وصف',
  edit: 'تعديل',
  assign: 'تعيين',
  change_status: 'تغيير الحالة',
  support_note: 'مذكرة الدعم الفني',
  supervisor_name: 'اسم المشرف',
  note: 'مذكرة',
  parent_category: 'التصنيف الرئيسي',
  child_category: ' التصنيف الفرعي',
  priority: 'أفضليه',
  description: 'تفاصيل',
  cancel: 'إلغاء',
  update_ticket: 'تعديل التذكرة',
  update_status: 'تعديل الحاله',
  notify_user: 'إخطار المستخدم',
  add_note: 'أضف ملاحظة',
  create: 'إنشاء',
  ticket_transactins: 'إجراءات التذكرة',
  action: 'اجراء',
  creation_date: 'تايرخ الإنشاء',
  created_by: 'انشأ بواسطة ',
  created: ' تم الإنشاء',
  assigned: 'تم التعيين',
  reopened: 'إعادة فتح',
  apply: 'تطبيق',
  text_msg: 'رساله نصيه  ...',
  users_tickets_list: 'قائمة تذاكر المستخدمين',
  updated_successfully: 'تم التحديث بنجاح',
  msg_note: 'تمت إضافة الملاحظة',
  created_success: 'تم إنشاؤه بنجاح',
  msg_asign: 'تم التعيين',
  new_ticket: 'تذكرة جديدة',
  submit: 'تطبيق',
};

export default ticket_ar;
