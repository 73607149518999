import React, { useCallback } from 'react';

import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Grid,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import { createTicket } from '../../../../Redux/store/tickets/ticketsListSlice';
import { useFormik } from 'formik';

import { setAlert } from '../../../../Redux/store/app/appSlice';
import { useTicketTranslation } from '../../i18n';
import { toast } from 'react-toastify';
import { useUsersTranslation } from '../../../users/i18n';

const CreateTicketDialog = (props) => {
  const {
    setIsOpen,
    isOpen,
    client_id,
    service_id,
    userId,
    referenceId,
    isRefreshing,
    setIsRefreshing,
  } = props;

  const t = useTicketTranslation();
  // console.log(ticketId);
  const dispatch = useDispatch();
  const { categoriesList } = useSelector((state) => state.tickets).ticketsList;
  const [parentId, setParentId] = React.useState(null);
  const handleCreatingTicket = useCallback(async (payload) => {
    try {
      await dispatch(
        createTicket({
          ...payload,
          client_id: client_id,
          service_id: service_id,
          user_id: userId,
          reference_id: referenceId,
        })
      );
      toast.success(t('created'));
    } catch (err) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
      toast.error(err.data.message);
    } finally {
      setIsRefreshing(isRefreshing + 1);
    }
  });

  const init = {
    parent_category_id: null,
    child_category_id: null,
    description: null,
    note: null,
    priority: null,
  };

  const formik = useFormik({
    initialValues: init,
    onSubmit: (values, { resetForm }) => {
      // console.log(values);
      handleCreatingTicket(values);
      resetForm();
      setIsOpen(false);
    },
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={isOpen}
      fullWidth
    >
      <DialogTitle id='simple-dialog-title'>{t('new_ticket')}</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container lg={12} md={12} spacing={2} className='pt-4'>
            <Grid item lg={12} md={12}>
              <TextField
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                select
                id='parent_category_id'
                name='parent_category_id'
                label={t('parent_category')}
                onChange={(e) => {
                  setParentId(e.target.value);
                  formik.handleChange(e);
                }}
                value={formik.values.parent_category_id}
                variant='outlined'
              >
                {categoriesList.map(
                  (category) =>
                    category.parent_category_id === null && (
                      <MenuItem
                        value={category.category_id}
                        key={category.category_id}
                      >
                        {category.category_name}
                      </MenuItem>
                    )
                )}
              </TextField>
            </Grid>
            {parentId && (
              <Grid item lg={12} md={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth
                  select
                  id='child_category_id'
                  name='child_category_id'
                  label={t('child_category')}
                  onChange={formik.handleChange}
                  value={formik.values.child_category_id}
                  variant='outlined'
                >
                  {categoriesList.map(
                    (category) =>
                      category.parent_category_id === parentId && (
                        <MenuItem
                          value={category.category_id}
                          key={category.category_id}
                        >
                          {category.category_name}
                        </MenuItem>
                      )
                  )}
                </TextField>
              </Grid>
            )}

            <Grid item lg={12} md={12}>
              <TextField
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                select
                id='priority'
                name='priority'
                label={t('priority')}
                onChange={formik.handleChange}
                value={formik.values.priority}
                variant='outlined'
              >
                <MenuItem value={1}>{t('low')}</MenuItem>
                <MenuItem value={2}>{t('medium')}</MenuItem>
                <MenuItem value={3}>{t('high')}</MenuItem>
                <MenuItem value={4}>{t('critical')}</MenuItem>
              </TextField>
            </Grid>
            <Grid item lg={12} md={12}>
              <TextField
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                id='description'
                name='description'
                label={t('description')}
                onChange={formik.handleChange}
                value={formik.values.description}
                variant='outlined'
              />
            </Grid>
            <Grid item lg={12} md={12}>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                id='note'
                name='note'
                label={t('note')}
                onChange={formik.handleChange}
                value={formik.values.note}
                variant='outlined'
              />
            </Grid>
            <Grid
              container
              lg={12}
              md={12}
              className='flex p-2 justify-center space-x-2  '
              spacing={1}
            >
              <Grid item>
                <Button variant='outlined' color='primary' type='submit'>
                  {t('submit')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='outlined'
                  color='inherit'
                  onClick={handleClose}
                >
                  {t('cancel')}
                </Button>
              </Grid>
            </Grid>

            {/* <Grid
              item
              lg={12}
              md={12}
              className='flex p-2 justify-center gap-2'
            >
              <Button variant='contained' color='primary' type='submit'>
                {t('create')}
              </Button>
              <Button variant='outlined' color='error' onClick={handleClose}>
                {t('cancel')}
              </Button>
            </Grid> */}
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateTicketDialog;
