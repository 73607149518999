import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
import React from 'react';
import { useEventsTranslation } from '../../services/i18n';

export default function TicketsFilter({
  filterPayload,
  setFilterPayload,
  onApplyFilters,
}) {
  const t = useEventsTranslation();

  return (
    <Box className='bg-gray-100 h-[77px] my-4 p-3 rounded-md flex items-center justify-between'>
      <Box className='flex items-center h-full gap-3'>
        <Box>
          <p className='text-gray-600'>{t('status')}</p>
          <TextField
            size='small'
            select
            className='w-[200px]'
            value={filterPayload.status_id}
            onChange={(e) =>
              setFilterPayload({ ...filterPayload, status_id: e.target.value })
            }
          >
            <MenuItem value={''}>{t('all')}</MenuItem>
            <MenuItem value={1}>{t('confirmd')}</MenuItem>
            <MenuItem value={2}>{t('consumed')}</MenuItem>
          </TextField>
        </Box>
        <Box>
          <p className='text-gray-600'>{t('user_mobile')}</p>
          <TextField
            dir='ltr'
            size='small'
            className='h-full'
            value={filterPayload.mobile}
            onChange={(e) =>
              setFilterPayload({ ...filterPayload, mobile: e.target.value })
            }
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>+249</InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <p className='text-gray-600'>{t('from')}</p>
          <TextField
            onChange={(e) =>
              setFilterPayload({ ...filterPayload, from_date: e.target.value })
            }
            type='datetime-local'
            inputProps={{ step: '1' }}
            variant='outlined'
            size='small'
            required
            className='w-full'
            value={filterPayload.from_date}
          />
        </Box>
        <Box>
          <p className='text-gray-600'>{t('to')}</p>
          <TextField
            onChange={(e) =>
              setFilterPayload({ ...filterPayload, to_date: e.target.value })
            }
            type='datetime-local'
            size='small'
            inputProps={{ step: '1' }}
            variant='outlined'
            required
            className='w-full h-full'
            value={filterPayload.to_date}
          />
        </Box>
      </Box>
      <Button
        variant='contained'
        className='h-full'
        color='primary'
        onClick={onApplyFilters}
      >
        {t('apply')}
      </Button>
    </Box>
  );
}
