import http from '../../../services/APIs/taxi_api';

export const usersApi = {
  fetchUsers: async (payload) => {
    try {
      const response = await http.post('/dashboard/users', payload);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },

  fetchUser: async (id) => {
    try {
      const response = await http.get(`/users/${id}`);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },

  fetchUserBalance: async (id) => {
    try {
      const response = await http.get(`/dashboard/balances/${id}`);
      return response.data.balance;
    } catch (err) {
      throw err.response;
    }
  },
  creditBalance: async (payload) => {
    try {
      const response = await http.post('/dashboard/balances/add', payload);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },

  debitBalance: async (payload) => {
    try {
      const response = await http.post('/dashboard/balances/sub', payload);
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },

  fetchUserTransactions: async (payload) => {
    //
    try {
      const response = await http.get(
        `/dashboard/balance_transactions?user_id=${payload.user_id}&page_size=${payload.page_size}&page_number=${payload.page_number}`
      );
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },

  fetchOrdersStatistics: async (payload) => {
    try {
      const response = await http.get(
        `/dashboard/stats/orders?from=${payload.from}&to=${
          payload.to
        }&user_id=${payload.user_id}${
          payload.car_type_id ? `&car_type_id=${payload.car_type_id}` : ''
        }`
      );
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },

  getNewOtp: async (payload) => {
    //
    try {
      const response = await http.get(
        `/dashboard/support_passcode?mobile_number=${payload.mobile}&target_client_id=${payload.service_id}`
      );
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },
};
