import { handleStateStore } from '../../../modules/auth/uitls';

import http from '../../../services/APIs/dispatch_api';
import http1 from '../../../services/APIs/taxi_api';

export const driversApi = {
  fetchDrivers: async (payload) => {
    try {
      const response = await http.post('/dashboard/drivers_list', payload);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  // fetchPendingDrivers: async (payload) => {
  //   try {
  //     const response = await http.post(
  //       `/dashboard/pending_drivers?page_size=${payload.pageSize}&page_number=${payload.page}`
  //     );
  //     return response.data;
  //   } catch (error) {
  //     throw error.response;
  //   }
  // },
  findDriver: async (payload) => {
    try {
      const response = await http.get(
        payload.typeOfId === 'identity'
          ? `/dashboard/drivers/identity/${payload.id}`
          : `/dashboard/drivers/${payload.id}`
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  fetchCarTypes: async () => {
    try {
      const response = await http.get('/dashboard/car_types');
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  updateDriver: async (driver) => {
    try {
      const response = await http.put(
        `/dashboard/drivers/${driver.driverId}`,
        driver
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  block: async (payload) => {
    try {
      console.log(payload);
      const response = await http.post('/dashboard/drivers/block', payload);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  unBlock: async (id) => {
    try {
      const response = await http.post('/dashboard/drivers/unblock', {
        driver_id: id,
        supervisor_note: 'الغاء الحظر',
        supervisor_name: 'Ahmed',
        created_by: '6277809219596976118',
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  getDriverTransactions: async ({ id, pageSize, page }) => {
    try {
      const response = await http.get(
        `/dashboard/driver_balances/${id}/transactions?page_size=${pageSize}&page_number=${page}`
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  getDriverTotalBalance: async (id) => {
    try {
      const response = await http.get(`/dashboard/driver_balances/${id}`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  deductDriverBalance: async ({ id, amount, narration, reference_type }) => {
    try {
      const response = await http.post('/dashboard/driver_balances/deduct', {
        driver_id: id,
        amount,
        narration,
        reference_type,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  addDriverBalance: async ({ id, amount, narration, reference_type }) => {
    try {
      const response = await http.post('/dashboard/driver_balances/add', {
        driver_id: id,
        amount,
        narration,
        reference_type,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  revertTransactions: async (id) => {
    try {
      const response = await http.post('/dashboard/driver_balances/revert', {
        id: id,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  fetchDriverPerformance: async ({ id, from, to }) => {
    try {
      const response = await http.get(
        `/dashboard/driver_performance/basic?driver_id=${id}&from=${from}&to=${to}`
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  fetchDriverWeeklyPerformance: async ({ id, from, to }) => {
    try {
      const response = await http.get(
        `/dashboard/driver_performance/weekly_earnings?driver_id=${id}&from=${from}&to=${to}`
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  fetchDriverHourlyPerformance: async ({ id, from, to }) => {
    try {
      const response = await http.get(
        `/dashboard/driver_performance/hourly_earnings?driver_id=${id}&from=${from}&to=${to}`
      );
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  fetchDriverFiles: async (id) => {
    try {
      const response = await http.get(`/dashboard/driver_files/${id}`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  // TODO:

  fetchNotes: async (id) => {
    try {
      const response = await http.get(`/dashboard/supervisor_notes/${id}`);
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  addNote: async ({ driver_id, note, supervisor_name }) => {
    try {
      const response = await http.post('/dashboard/supervisor_notes', {
        driver_id,
        note,
        supervisor_name,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  fetchFeesGroups: async () => {
    try {
      const response = await http.get('/dashboard/fee_groups');
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  fetchOrdersStatistics: async (payload) => {
    try {
      const response = await http1.get(
        `/dashboard/stats/orders?from=${payload.from}&to=${
          payload.to
        }&driver_id=${payload.driver_id}${
          payload.car_type_id ? `&car_type_id=${payload.car_type_id}` : ''
        }`
      );
      return response.data;
    } catch (err) {
      throw err.response;
    }
  },
};
