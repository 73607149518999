import getDetails from './getDetails';
import getUsers from './getUsers';
import getUserBlocked from './getUserBlocked';
import getUserUnblocked from './getUserUnblocked';
import deleteUserById from './deleteUser';

export default {
  getUsers,
  getDetails,
  getUserBlocked,
  getUserUnblocked,
  deleteUserById,
};
