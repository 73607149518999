import { Dialog } from "@material-ui/core";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import React from "react";

const ImageDialog = (props) => {
  const classes = useStyles();
  const { open,setOpen, image_url } = props;

  const handleClose = () => {
    setOpen(false);
  };

  
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className="overflow-hidden"
    >
      <img
        src={image_url}
        alt={"null"}
        className=" w-full h-[60vh] "
        loading="lazy"
      />
    </Dialog>
  );
};

export default ImageDialog;
