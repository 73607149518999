// This dictionary contains 338 untranslated or inherited localization strings.
// These strings are commented out. Uncomment and edit them if you want to add your translations.
import { editorLocalization } from "survey-creator-core";

export var czStrings = {
  // survey templates
  survey: {
    edit: "Upravit",
    externalHelpLink: "Podívejte se a naučte se vytvářet průzkumy",
    externalHelpLinkUrl: "https://www.youtube.com/channel/UCH2ru9okIcooNZfpIbyq4qQ?view_as=subscriber",
    dropQuestion: "Sem napište otázku ze sady nástrojů.",
    addLogicItem: "Vytvořte pravidlo pro přizpůsobení průběhu průzkumu.",
    copy: "Kopírovat",
    duplicate: "Duplikovat",
    addToToolbox: "Přidat do sady nástrojů",
    deletePanel: "Odstranit panel",
    deleteQuestion: "Odstranit otázku",
    convertTo: "Převést na",
    drag: "Přetáhnout prvek"
  },
  // Question types
  qt: {
    default: "Výchozí",
    checkbox: "Zaškrtávací pole",
    comment: "Komentář",
    imagepicker: "Nástroj pro výběr obrázku",
    ranking: "Pořadí",
    image: "Obrázek",
    dropdown: "Rozbalovací nabídka",
    // tagbox: "Tag Box",
    file: "Soubor",
    html: "HTML",
    matrix: "Matrix (jedna volba)",
    matrixdropdown: "Matrix (výběr z více možností)",
    matrixdynamic: "Matice (dynamické řádky)",
    multipletext: "Více textů",
    panel: "Panel",
    paneldynamic: "Panel (dynamické panely)",
    radiogroup: "Radiogroup",
    rating: "Hodnocení",
    text: "Jeden vstup",
    boolean: "Boolean",
    expression: "Výraz (pouze pro čtení)",
    signaturepad: "Podpisová podložka",
    // buttongroup: "Button Group"
  },
  // Strings in SurveyJS Creator
  ed: {
    defaultLocale: "Výchozí ({0})",
    survey: "Průzkum",
    settings: "Nastavení průzkumu",
    settingsTooltip: "Otevřít nastavení průzkumu",
    // surveySettings: "Survey Settings",
    // surveySettingsTooltip: "Open survey settings",
    showPanel: "Zobrazit panel",
    hidePanel: "Skrýt panel",
    // prevSelected: "Select previous",
    // nextSelected: "Select next",
    // surveyTypeName: "Survey",
    // pageTypeName: "Page",
    // panelTypeName: "Panel",
    // questionTypeName: "Question",
    // columnTypeName: "Column",
    addNewPage: "Přidat novou stránku",
    moveRight: "Přejděte doprava",
    moveLeft: "Přejděte doleva",
    deletePage: "Smazat stránku",
    editPage: "Upravit stránku",
    edit: "Upravit",
    newPageName: "stránka",
    newQuestionName: "otázka",
    newPanelName: "panel",
    newTextItemName: "text",
    testSurvey: "Otestovat průzkum",
    // defaultV2Theme: "Default",
    // modernTheme: "Modern",
    // defaultTheme: "Default (legacy)",
    testSurveyAgain: "Znovu otestovat průzkum",
    testSurveyWidth: "Šířka průzkumu: ",
    navigateToMsg: "Museli jste přejít na:",
    logic: "Logika průzkumu",
    embedSurvey: "Vložit průzkum",
    translation: "Překlad",
    saveSurvey: "Uložit průzkum",
    saveSurveyTooltip: "Uložit průzkum",
    designer: "Návrhář průzkumů",
    jsonEditor: "Editor JSON",
    // jsonHideErrors: "Hide errors",
    // jsonShowErrors: "Show errors",
    undo: "Zrušit",
    redo: "Opětovně vrátit",
    undoTooltip: "Vrátit poslední změnu",
    redoTooltip: "Znovu provést změnu",
    // showMoreChoices: "Show more",
    // showLessChoices: "Show less",
    copy: "Kopírovat",
    cut: "Vyjmout",
    paste: "Vložit",
    copyTooltip: "Zkopírovat výběr do schránky",
    cutTooltip: "Vyjmout výběr do schránky",
    pasteTooltip: "Vložit ze schránky",
    options: "Možnosti",
    generateValidJSON: "Generovat platný JSON",
    generateReadableJSON: "Generovat čitelný JSON",
    toolbox: "Sada nástrojů",
    "property-grid": "Vlastnosti",
    // propertyGridFilteredTextPlaceholder: "Type to search...",
    toolboxGeneralCategory: "Obecné",
    // toolboxChoiceCategory: "Choice Questions",
    // toolboxTextCategory: "Text Input Questions",
    // toolboxContainersCategory: "Containers",
    // toolboxMatrixCategory: "Matrix Questions",
    // toolboxMiscCategory: "Misc",
    correctJSON: "Opravte JSON.",
    surveyResults: "Výsledek průzkumu: ",
    surveyResultsTable: "Jako tabulka",
    surveyResultsJson: "Jako JSON",
    resultsTitle: "Nadpis otázky",
    resultsName: "Název otázky",
    resultsValue: "Hodnota odpovědi",
    resultsDisplayValue: "Hodnota zobrazení",
    modified: "Upraveno",
    saving: "Ukládání",
    saved: "Uloženo",
    propertyEditorError: "Chyba:",
    saveError: "Chyba! Obsah editoru není uložen.",
    // translationPropertyGridTitle: "Language Settings",
    // translationLanguages: "Languages",
    translationAddLanguage: "Vyberte jazyk pro překlad",
    translationShowAllStrings: "Zobrazit všechny řetězce",
    // translationShowUsedStringsOnly: "Used Strings Only",
    translationShowAllPages: "Zobrazit všechny stránky",
    translationNoStrings: "Žádné řetězce k překladu. Změňte filtr.",
    translationExportToSCVButton: "Export do CSV",
    translationImportFromSCVButton: "Import z CSV",
    translationMergeLocaleWithDefault: "Sloučit {0} s výchozím lokálem",
    // translationPlaceHolder: "Translation...",
    bold: "Tučně",
    italic: "Kurzíva",
    underline: "Podtržení",
    addNewQuestion: "Přidat otázku",
    selectPage: "Vyberte stránku...",
    // htmlPlaceHolder: "HTML content will be here.",
    // panelPlaceHolder: "Drop a question from the toolbox here.",
    // surveyPlaceHolder: "The survey is empty. Drag an element from the toolbox or click the button below.",
    // addNewTypeQuestion: "Add {0}", //{0} is localizable question type
    // chooseLogoPlaceholder: "[LOGO]",
    // auto: "auto",
    lg: {
      addNewItem: "Přidat nové pravidlo",
      // empty_tab: "Create a rule to customize the flow of the survey.",
      page_visibilityName: "Viditelnost stránky",
      // page_enableName: "Enable (disable) page",
      panel_visibilityName: "Viditelnost panelu",
      panel_enableName: "Zapnutí/vypnutí panelu",
      question_visibilityName: "Viditelnost otázky",
      question_enableName: "Zapnutí/vypnutí otázky",
      question_requireName: "Otázka nepovinná vyžadována",
      // column_visibilityName: "Show (hide) column",
      // column_enableName: "Enable (disable) column",
      // column_requireName: "Make column required",
      trigger_completeName: "Dokončení průzkumu",
      trigger_setvalueName: "Nastavení hodnoty otázky",
      trigger_copyvalueName: "Kopírovat hodnotu otázky",
      trigger_skipName: "Přeskočit na otázku",
      trigger_runExpressionName: "Spuštění vlastního výrazu",
      completedHtmlOnConditionName: "Vlastní text stránky s poděkováním",
      page_visibilityDescription: "Zobrazení stránky, když se logický výraz vrátí jako true. V opačném případě zůstane neviditelná.",
      panel_visibilityDescription: "Zobrazení panel, když se logický výraz vrátí jako true. V opačném případě zůstane neviditelný.",
      panel_enableDescription: "Panel a všechny prvky v něm se aktivují, když logický výraz vrátí hodnotu true. V opačném případě je nechte vypnuté.",
      question_visibilityDescription: "Zobrazit otázku, když logický výraz vrátí hodnotu true. V opačném případě zůstane neviditelná.",
      question_enableDescription: "Povolit otázku, když logický výraz vrátí hodnotu true. V opačném případě zůstane neviditelná.",
      question_requireDescription: "Otázka se stává povinnou, když logický výraz vrátí hodnotu true.",
      trigger_completeDescription: "Když logický výraz vrátí hodnotu true, průzkum je dokončen a koncovému uživateli se zobrazí stránka s poděkováním.",
      trigger_setvalueDescription: "Pokud se změní hodnoty otázek, které jsou použity v logickém výrazu, a logický výraz vrátí hodnotu true, nastaví se hodnota na vybranou otázku.",
      trigger_copyvalueDescription: "Pokud se změní hodnoty otázek, které jsou použity v logickém výrazu, a logický výraz vrátí hodnotu true, pak se hodnota jedné vybrané otázky zkopíruje do jiné vybrané otázky.",
      trigger_skipDescription: "Pokud logický výraz vrátí hodnotu true, průzkum přejde na vybranou otázku/zaměří se na ni.",
      trigger_runExpressionDescription: "Pokud logický výraz vrátí hodnotu true, provede se vlastní výraz. Výsledek tohoto výrazu můžete volitelně nastavit do vybrané otázky.",
      completedHtmlOnConditionDescription: "Pokud logický výraz vrátí hodnotu true, změní se výchozí text stránky s poděkováním na zadaný text.",
      itemExpressionText: "Pokud výraz „{0}“ vrátí hodnotu true:", //{0} - the expression
      // itemEmptyExpressionText: "New rule",
      page_visibilityText: "Zobrazit stránku {0}", //{0} page name
      panel_visibilityText: "Zobrazit panel {0}", //{0} panel name
      panel_enableText: "Povolit panel {0}", //{0} panel name
      question_visibilityText: "Zobrazit otázku {0}", //{0} question name
      question_enableText: "Povolit otázku {0}", //{0} question name
      question_requireText: "Otázka {0} je povinná", //{0} question name
      // column_visibilityText: "make column {0} of question {1} visible", //{0} column name, {1} question name
      // column_enableText: "make column {0} of question {1} enable", //{0} column name, {1} question name
      // column_requireText: "make column {0} of question {1} required", //{0} column name, {1} question name
      trigger_completeText: "Průzkum se stává dokončeným",
      trigger_setvalueText: "Zpochybnit: {0} hodnota {1}", //{0} question name, {1} setValue
      trigger_copyvalueText: "Kopírovat do otázky: {0} hodnota z otázky {1}", //{0} and {1} question names
      trigger_skipText: "Průzkum přeskočí na otázku {0}", //{0} question name
      trigger_runExpressionText1: "Spustit výraz: „{0}“", //{0} the expression
      trigger_runExpressionText2: " a zpochybnit jeho výsledek: {0}", //{0} question name
      completedHtmlOnConditionText: "Zobrazit vlastní text pro stránku s poděkováním.",
      // showAllQuestions: "All Questions",
      // showAllActionTypes: "All Action Types",
      conditions: "Podmínky",
      actions: "Akce",
      expressionEditorTitle: "Definovat podmínky",
      actionsEditorTitle: "Definovat akce",
      deleteAction: "Smazat akci",
      addNewAction: "Přidat novou akci",
      selectedActionCaption: "Vyberte akci, kterou chcete přidat...",
      expressionInvalid: "Logický výraz je prázdný nebo neplatný. Opravte jej.",
      noActionError: "Přidejte alespoň jednu akci.",
      actionInvalid: "Opravte prosím problémy v akcích.",
      // expressionSetup: "",
      // actionsSetup: ""
    }
  },
  // Property Editors
  pe: {
    apply: "Použít",
    ok: "OK",
    save: "Uložit",
    // clear: "Clear",
    saveTooltip: "Uložit",
    cancel: "Zrušit",
    // set: "Set",
    reset: "Resetovat",
    // change: "Change",
    refresh: "Obnovit",
    // close: "Close",
    delete: "Smazat",
    add: "Přidat",
    addNew: "Přidat novou",
    addItem: "Kliknutím přidáte položku...",
    // removeItem: "Click to remove the item...",
    // dragItem: "Drag the item",
    addOther: "Ostatní",
    addSelectAll: "Vybrat vše",
    addNone: "Žádné",
    removeAll: "Odstranit vše",
    edit: "Upravit",
    back: "Návrat bez uložení",
    backTooltip: "Návrat bez uložení",
    saveAndBack: "Uložit a vrátit",
    saveAndBackTooltip: "Uložit a vrátit",
    // doneEditing: "Done",
    editChoices: "Upravit volby",
    showChoices: "Zobrazit volby",
    move: "Přesunout",
    // empty: "<empty>",
    // emptyValue: "Value is empty",
    fastEntry: "Rychlý vstup",
    // fastEntryNonUniqueError: "Value '{0}' is not unique",
    // fastEntryChoicesCountError: "Please limit the number of items from {0} to {1}",
    // fastEntryPlaceholder: "You can set data in the following format:\nvalue1|text\nvalue2",
    formEntry: "Vstupní formulář",
    testService: "Testování služby",
    itemSelectorEmpty: "Vyberte prvek",
    conditionActionEmpty: "Vyberte akci",
    conditionSelectQuestion: "Vyberte otázku...",
    conditionSelectPage: "Vyberte stránku...",
    conditionSelectPanel: "Vyberte panel...",
    conditionValueQuestionTitle: "Zadejte/vyberte hodnotu",
    expressionHelp: "Pro přístup k hodnotám otázek můžete použít složené závorky: {otázka1} + {otázka2}, ({cena}*{množství}) * (100 - {sleva}). Můžete použít funkce jako: iif(), today(), age(), min(), max(), count(), avg() a další.",
    aceEditorHelp: "Stisknutím kláves ctrl+mezerník získáte nápovědu k dokončení výrazu",
    aceEditorRowTitle: "Aktuální řádek",
    aceEditorPanelTitle: "Aktuální panel",
    showMore: "Další podrobnosti naleznete v dokumentaci",
    assistantTitle: "Dostupné otázky:",
    cellsEmptyRowsColumns: "Měl by existovat alespoň jeden sloupec nebo řádek.",
    // showPreviewBeforeComplete: "Preview answers before submitting the survey",
    propertyIsEmpty: "Zadejte hodnotu",
    propertyIsNoUnique: "Zadejte jedinečnou hodnotu",
    propertyNameIsNotUnique: "Zadejte jedinečný název",
    listIsEmpty: "Přidat novou položku",
    // "listIsEmpty@choices": "No choices have been added yet",
    // "addNew@choices": "Add a choice",
    expressionIsEmpty: "Výraz je prázdný",
    value: "Hodnota",
    text: "Text",
    rowid: "ID řady",
    imageLink: "Odkaz na obrázek",
    columnEdit: "Upravit sloupec: {0}",
    itemEdit: "Upravit položku: {0}",
    url: "URL",
    path: "Cesta",
    valueName: "Název hodnoty",
    choicesbyurl: {
      // valueName: "Get values from the following JSON field"
    },
    titleName: "Název titulu",
    // imageLinkName: "Get image URLs from the following JSON field",
    allowEmptyResponse: "Povolit prázdnou odpověď",
    titlePlaceholder: "Sem zadejte název",
    surveyTitlePlaceholder: "Sem zadejte název průzkumu",
    pageTitlePlaceholder: "Sem zadejte název stránky",
    descriptionPlaceholder: "Zadejte popis",
    surveyDescriptionPlaceholder: "Zadejte popis průzkumu",
    pageDescriptionPlaceholder: "Zadejte popis stránky",
    showOtherItem: "Má jinou položku",
    otherText: "Další text položky",
    showNoneItem: "Nemá žádnou položku",
    noneText: "Žádný text položky",
    showSelectAllItem: "Vybral/a všechny položky",
    selectAllText: "Vyberte veškerý text položky",
    choicesMin: "Minimální hodnota pro automaticky generované položky",
    choicesMax: "Maximální hodnota pro automaticky generované položky",
    choicesStep: "Rozdíl mezi automaticky generovanými položkami",
    name: "Název",
    title: "Titul",
    cellType: "Typ buňky",
    colCount: "Počet sloupců",
    choicesOrder: "Vyberte pořadí voleb",
    visible: "Je viditelný?",
    isRequired: "Je vyžadovaný?",
    isAllRowRequired: "Požadovat odpověď pro všechny řádky",
    requiredErrorText: "Požadovaný text chyby",
    startWithNewLine: "Začátek s novým řádkem?",
    rows: "Počet řádků",
    cols: "Počet sloupců",
    placeholder: "Vstupní zástupný symbol",
    showPreview: "Zobrazit oblast náhledu",
    storeDataAsText: "Uložit obsah souboru ve výsledku JSON jako text",
    maxSize: "Maximální velikost souboru v bajtech",
    imageHeight: "Výška obrázku",
    imageWidth: "Šířka obrázku",
    rowCount: "Počet řádků",
    columnLayout: "Rozložení sloupců",
    addRowLocation: "Přidat umístění tlačítka řádku",
    addRowText: "Přidat text tlačítka řádku",
    removeRowText: "Odebrat text tlačítka řádku",
    rateMin: "Minimální frekvence",
    rateMax: "Maximální frekvence",
    rateStep: "Krok frekvence",
    minRateDescription: "Popis minimální frekvence",
    maxRateDescription: "Popis maximální sazby",
    inputType: "Typ vstupu",
    optionsCaption: "Popisek možností",
    defaultValue: "Výchozí hodnota",
    cellsDefaultRow: "Výchozí texty buněk",
    surveyEditorTitle: "Upravit nastavení průzkumu",
    qEditorTitle: "Upravit: {0}",
    maxLength: "Maximální délka",
    buildExpression: "Vytvořit",
    editExpression: "Upravit",
    // and: "and",
    // or: "or",
    remove: "Odebrat",
    addCondition: "Přidat podmínku",
    // emptyLogicPopupMessage: "Select a question to start configuring conditions.",
    // if: "If",
    // then: "then",
    // setToName: "Target question",
    // fromName: "Question to copy answer from",
    // gotoName: "Question to skip to",
    // ruleIsNotSet: "Rule is incorrect",
    // includeIntoResult: "Include into survey results",
    showTitle: "Zobrazit/skrýt název",
    expandCollapseTitle: "Rozbalit/sbalit název",
    locale: "Výchozí jazyk",
    simulator: "Vybrat zařízení",
    landscapeOrientation: "Na šířku",
    // portraitOrientation: "Switch to portrait orientation",
    mode: "Režim (pouze pro úpravy/čtení)",
    clearInvisibleValues: "Vymazat neviditelné hodnoty",
    cookieName: "Název souboru cookie (pro zakázání dvojího lokálního spuštění průzkumu)",
    sendResultOnPageNext: "Odeslání výsledků průzkumu na další straně",
    storeOthersAsComment: "Uložení hodnoty „others“ do samostatného pole",
    showPageTitles: "Zobrazit názvy stránek",
    showPageNumbers: "Zobrazit čísla stránek",
    pagePrevText: "Text tlačítka předchozí stránky",
    pageNextText: "Text tlačítka další stránky",
    completeText: "Úplný text tlačítka",
    previewText: "Text tlačítka náhledu",
    editText: "Text tlačítka úpravy",
    startSurveyText: "Text tlačítka zahájení",
    showNavigationButtons: "Zobrazit navigační tlačítka (výchozí navigace)",
    showPrevButton: "Zobrazit předchozí tlačítko (uživatel se může vrátit na předchozí stránku)",
    firstPageIsStarted: "První stránka průzkumu je úvodní stránka.",
    showCompletedPage: "Zobrazení dokončené stránky na konci (completedHtml)",
    goNextPageAutomatic: "Po zodpovězení všech otázek automaticky přejít na další stránku",
    showProgressBar: "Zobrazit ukazatel průběhu",
    questionTitleLocation: "Umístění názvu otázky",
    requiredText: "Požadované symboly otázky",
    questionStartIndex: "Index začátku otázky (1, 2 nebo A, a)",
    showQuestionNumbers: "Zobrazit čísla otázek",
    questionTitleTemplate: "Šablona názvu otázky, výchozí je: „{no}. {require} {title}“",
    questionErrorLocation: "Umístění chyby v otázce",
    focusFirstQuestionAutomatic: "Zaměřte se na první otázku týkající se změny stránky",
    questionsOrder: "Pořadí prvků na stránce",
    maxTimeToFinish: "Maximální doba pro dokončení průzkumu",
    maxTimeToFinishPage: "Maximální doba pro dokončení stránky v průzkumu",
    page: {
      // maxTimeToFinish: "Time limit to finish the page (in seconds)"
    },
    question: {
      // page: "Parent page"
    },
    showTimerPanel: "Zobrazit panel časovače",
    showTimerPanelMode: "Zobrazit režim panelu časovače",
    renderMode: "Režim vykreslování",
    allowAddPanel: "Povolit přidání panelu",
    allowRemovePanel: "Povolit odstranění panelu",
    // noEntriesText: "Empty entries text",
    panelAddText: "Přidávání textu na panel",
    panelRemoveText: "Odstraňování textu panelu",
    isSinglePage: "Zobrazit všechny prvky na jedné stránce",
    html: "HTML",
    expression: "Výraz",
    // setValue: "Answer",
    // dataFormat: "Image format",
    // allowAddRows: "Allow adding rows",
    // allowRemoveRows: "Allow removing rows",
    // allowRowsDragAndDrop: "Allow row drag and drop",
    // responsiveImageSizeHelp: "Does not apply if you specify the exact image width or height.",
    // minImageWidth: "Minimum image width",
    // maxImageWidth: "Maximum image width",
    // minImageHeight: "Minimum image height",
    // maxImageHeight: "Maximum image height",
    minValue: "Minimální hodnota",
    maxValue: "Maximální hodnota",
    minLength: "Minimální délka",
    allowDigits: "Povolit číslice",
    minCount: "Minimální počet",
    maxCount: "Maximální počet",
    regex: "Pravidelný výraz",
    surveyvalidator: {
      // text: "Error message",
      // expression: "Validation expression"
    },
    totalText: "Celkový text",
    totalType: "Celkový typ",
    totalExpression: "Celkový výraz",
    totalDisplayStyle: "Celkový styl zobrazení",
    totalCurrency: "Celková měna",
    totalFormat: "Celkový formát",
    // logo: "Logo (URL or base64-encoded string)",
    // questionsOnPageMode: "Survey structure",
    // maxTextLength: "Maximum answer length (in characters)",
    // maxOthersLength: "Maximum comment length (in characters)",
    // autoGrowComment: "Auto-expand comment area if necessary",
    // textUpdateMode: "Update text question value",
    // focusOnFirstError: "Set focus on the first invalid answer",
    // checkErrorsMode: "Run validation",
    // navigateToUrl: "Navigate to URL",
    // navigateToUrlOnCondition: "Dynamic URL",
    // completedBeforeHtml: "Markup to show if the user already filled out this survey",
    // completedHtml: "Survey Complete page markup",
    // completedHtmlOnCondition: "Dynamic Survey Complete page markup",
    // loadingHtml: "Markup to show while survey model is loading",
    // commentText: "Comment area text",
    // autocomplete: "Autocomplete type",
    // labelTrue: "\"True\" label",
    // labelFalse: "\"False\" label",
    allowClear: "Zobrazit popisek možností",
    // displayStyle: "Value display style",
    // format: "Formatted string",
    // maximumFractionDigits: "Maximum fractional digits",
    // minimumFractionDigits: "Minimum fractional digits",
    // useGrouping: "Display grouping separators",
    // allowMultiple: "Allow multiple files",
    // allowImagesPreview: "Preview images",
    // acceptedTypes: "Accepted file types",
    // waitForUpload: "Wait for the upload to complete",
    // needConfirmRemoveFile: "Confirm file deletion",
    // detailPanelMode: "Detail panel location",
    // minRowCount: "Minimum row count",
    // maxRowCount: "Maximum row count",
    // confirmDelete: "Confirm row deletion",
    // confirmDeleteText: "Confirmation message",
    paneldynamic: {
      // confirmDelete: "Confirm panel deletion"
    },
    // panelCount: "Initial panel count",
    // minPanelCount: "Minimum panel count",
    // maxPanelCount: "Maximum panel count",
    // panelsState: "Inner panel expand state",
    // templateDescription: "Description template",
    // templateTitle: "Title template",
    // panelPrevText: "Previous Panel button tooltip",
    // panelNextText: "Next Panel button tooltip",
    // showRangeInProgress: "Show progress bar",
    // templateTitleLocation: "Question title location",
    // panelRemoveButtonLocation: "Remove Panel button location",
    // hideIfRowsEmpty: "Hide the question if there are no rows",
    // hideColumnsIfEmpty: "Hide columns if there are no rows",
    // rateValues: "Custom rate values",
    // hideIfChoicesEmpty: "Hide the question if it contains no choices",
    // hideNumber: "Hide question number",
    // minWidth: "Minimum width (in CSS-accepted values)",
    // maxWidth: "Maximum width (in CSS-accepted values)",
    // width: "Width (in CSS-accepted values)",
    // showHeader: "Show column headers",
    // horizontalScroll: "Show horizontal scrollbar",
    // columnMinWidth: "Minimum column width (in CSS-accepted values)",
    // rowTitleWidth: "Row header width (in CSS-accepted values)",
    // valueTrue: "\"True\" value",
    // valueFalse: "\"False\" value",
    // minErrorText: "\"Value is below minimum\" error message",
    // maxErrorText: "\"Value exceeds maximum\" error message",
    // otherErrorText: "\"Empty comment\" error message",
    // keyDuplicationError: "\"Non-unique key value\" error message",
    // maxSelectedChoices: "Maximum selected choices",
    // showClearButton: "Show the Clear button",
    // showNumber: "Show panel number",
    // logoWidth: "Logo width (in CSS-accepted values)",
    // logoHeight: "Logo height (in CSS-accepted values)",
    // readOnly: "Read-only",
    // enableIf: "Editable if",
    // emptyRowsText: "\"No rows\" message",
    // size: "Input size (in characters)",
    // separateSpecialChoices: "Separate special choices (None, Other, Select All)",
    // choicesFromQuestion: "Copy choices from the following question",
    // choicesFromQuestionMode: "Which choices to copy?",
    // showCommentArea: "Show the comment area",
    // commentPlaceholder: "Comment area placeholder",
    // displayRateDescriptionsAsExtremeItems: "Display rate descriptions as extreme values",
    // rowsOrder: "Row order",
    // columnsLayout: "Column layout",
    // columnColCount: "Nested column count",
    // state: "Panel expand state",
    // correctAnswer: "Correct Answer",
    // defaultPanelValue: "Default Values",
    // cells: "Cell Texts",
    // keyName: "Key column",
    itemvalue: {
      // text: "Alt text"
    },
    logoPosition: "Pozice loga",
    addLogo: "Přidat logo...",
    changeLogo: "Změnit logo...",
    logoPositions: {
      none: "Odstranit logo",
      left: "Vlevo",
      right: "Vpravo",
      top: "Nahoře",
      bottom: "Dole"
    },
    tabs: {
      general: "Obecné",
      fileOptions: "Možnosti",
      html: "Editor HTML",
      columns: "Sloupce",
      rows: "Řádky",
      choices: "Volby",
      items: "Položky",
      visibleIf: "Viditelné, pokud",
      enableIf: "Povolené, pokud",
      requiredIf: "Vyžadované, pokud",
      rateValues: "Hodnoty sazeb",
      choicesByUrl: "Volby z webu",
      matrixChoices: "Výchozí volby",
      multipleTextItems: "Textové vstupy",
      numbering: "Číslování",
      validators: "Validátory",
      navigation: "Navigace",
      question: "Otázka",
      pages: "Stránky",
      timer: "Časovač/kvíz",
      calculatedValues: "Vypočítané hodnoty",
      triggers: "Spouštěče",
      templateTitle: "Název šablony",
      totals: "Součty",
      logic: "Logika",
      layout: "Rozložení",
      data: "Data",
      validation: "Ověřování",
      cells: "Buňky",
      showOnCompleted: "Zobrazit na stránce Dokončeno",
      logo: "Logo v názvu průzkumu",
      slider: "Posuvník",
      // expression: "Expression",
      others: "Ostatní"
    },
    editProperty: "Upravit vlastnost „{0}“",
    items: "[ Položky: {0} ]",
    // choicesVisibleIf: "Choices are visible if",
    // choicesEnableIf: "Choices are selectable if",
    // columnsEnableIf: "Columns are visible if",
    // rowsEnableIf: "Rows are visible if",
    // indent: "Add indents",
    panel: {
      // indent: "Add outer indents"
    },
    // innerIndent: "Add inner indents",
    // defaultValueFromLastRow: "Take default values from the last row",
    // defaultValueFromLastPanel: "Take default values from the last panel",
    enterNewValue: "Zadejte hodnotu.",
    noquestions: "V průzkumu není žádná otázka.",
    createtrigger: "Vytvořte spouštěč",
    titleKeyboardAdornerTip: "Zadejte tlačítko k úpravě",
    keyboardAdornerTip: "Stisknutím klávesy enter upravíte položku, stisknutím klávesy delete položku odstraníte, stisknutím klávesy alt plus šipky nahoru nebo šipky dolů položku přesunete.",
    triggerOn: "Zapnout ",
    triggerMakePagesVisible: "Zobrazit stránky:",
    triggerMakeQuestionsVisible: "Zobrazit prvky:",
    triggerCompleteText: "Vyplňte průzkum, pokud se vám to podaří.",
    triggerNotSet: "Spouštěč není nastaven",
    triggerRunIf: "Spustit, pokud",
    triggerSetToName: "Změnit hodnotu: ",
    triggerFromName: "Zkopírovat hodnotu z: ",
    triggerRunExpression: "Spustit tento výraz:",
    triggerSetValue: "do: ",
    triggerGotoName: "Přejít na otázku:",
    triggerIsVariable: "Nevkládejte proměnnou do výsledku průzkumu.",
    triggerRunExpressionEmpty: "Zadejte platný výraz",
    // emptyExpressionPlaceHolder: "Type expression here...",
    noFile: "Nebyl vybrán žádný soubor",
    // clearIfInvisible: "Clear the value if the question becomes hidden",
    // valuePropertyName: "Value property name",
    // searchEnabled: "Enable search",
    // hideSelectedItems: "Hide selected items",
    // signatureWidth: "Signature width",
    // signatureHeight: "Signature height",
    // verticalAlign: "Vertical alignment",
    // alternateRows: "Alternate rows",
    // columnsVisibleIf: "Columns are visible if",
    // rowsVisibleIf: "Rows are visible if",
    // otherPlaceholder: "Comment area placeholder"
  },
  // Property values
  pv: {
    // true: "true",
    // false: "false",
    inherit: "zdědit",
    show: "zobrazit",
    hide: "skrýt",
    default: "výchozí",
    initial: "úvodní",
    random: "náhodný",
    collapsed: "sbalený",
    expanded: "rozbalený",
    none: "žádný",
    asc: "vzestupný",
    desc: "sestupný",
    indeterminate: "neurčitý",
    // decimal: "decimal",
    // currency: "currency",
    // percent: "percent",
    firstExpanded: "firstExpanded",
    off: "vypnout",
    // onpanel: "Start on each panel",
    onPanel: "onPanel",
    onSurvey: "onSurvey",
    list: "seznam",
    progressTop: "progressTop",
    progressBottom: "progressBottom",
    progressTopBottom: "progressTopBottom",
    horizontal: "horizontální",
    vertical: "vertikální",
    top: "nahoře",
    bottom: "dole",
    topBottom: "nahoře a dole",
    both: "obě",
    left: "vlevo",
    // right: "Right",
    // color: "color",
    // date: "date",
    // datetime: "datetime",
    // "datetime-local": "datetime-local",
    // email: "email",
    // month: "month",
    // number: "number",
    // password: "password",
    // range: "range",
    // tel: "tel",
    // text: "text",
    // time: "time",
    // url: "url",
    // week: "week",
    hidden: "skrytý",
    on: "zapnout",
    onPage: "onPage",
    edit: "upravit",
    display: "zobrazit",
    onComplete: "onComplete",
    onHidden: "onHidden",
    // onHiddenContainer: "When the question or its panel/page becomes hidden",
    clearInvisibleValues: {
      // none: "Never"
    },
    all: "vše",
    page: "stránka",
    survey: "průzkum",
    onNextPage: "onNextPage",
    onValueChanged: "onValueChanged",
    // onValueChanging: "Before an answer is changed",
    standard: "standardní",
    singlePage: "jedna stránka",
    questionPerPage: "otázka na stránku",
    noPreview: "bez náhledu",
    showAllQuestions: "zobrazit náhled se všemi otázkami",
    showAnsweredQuestions: "zobrazit náhled se zodpovězenými otázkami",
    pages: "stránky",
    questions: "otázky",
    requiredQuestions: "povinné otázky",
    correctQuestions: "správné otázky",
    buttons: "tlačítka",
    underInput: "pod vstupem",
    underTitle: "pod nadpisem",
    // onBlur: "On blur",
    // onTyping: "While typing",
    // underRow: "Under the row",
    // underRowSingle: "Under the row, only one panel is visible",
    showNavigationButtons: {
      // none: "Hidden"
    },
    showProgressBar: {
      // off: "Hidden"
    },
    showTimerPanel: {
      // none: "Hidden"
    },
    showTimerPanelMode: {
      // all: "Both"
    },
    detailPanelMode: {
      // none: "Hidden"
    },
    addRowLocation: {
      // default: "Depends on matrix layout"
    },
    panelsState: {
      // default: "Users cannot expand or collapse panels",
      // collapsed: "All panels are collapsed",
      // expanded: "All panels are expanded"
    },
    widthMode: {
      // auto: "Auto",
      // static: "Static",
      // responsive: "Responsive"
    },
    imageFit: {
      // none: "None",
      // contain: "Contain",
      // cover: "Cover",
      // fill: "Fill"
    },
    contentMode: {
      // auto: "Auto",
      // image: "Image",
      // video: "Video",
      // youtube: "YouTube"
    },
    displayMode: {
      // auto: "Auto",
      // buttons: "Buttons",
      // dropdown: "Dropdown"
    }
  },
  // Operators
  op: {
    empty: "je prázdný",
    notempty: "není prázdný",
    equal: "rovná se",
    notequal: "nerovná se",
    contains: "obsahuje",
    notcontains: "neobsahuje",
    anyof: "žádný z",
    allof: "všechny",
    greater: "větší",
    less: "nižší",
    greaterorequal: "větší nebo se rovná",
    lessorequal: "nižší nebo se rovná",
    // and: "and",
    // or: "or"
  },
  // Embed window
  ew: {
    angular: "Použít verzi Angular",
    jquery: "Použít verzi jQuery",
    knockout: "Použít verzi Knockout",
    react: "Použít verzi React",
    vue: "Použít verzi Vue",
    bootstrap: "Pro framework bootstrap",
    modern: "Moderní motiv",
    default: "Výchozí motiv",
    orange: "Oranžový motiv",
    darkblue: "Tmavěmodrý motiv",
    darkrose: "Tmavěrůžový motiv",
    stone: "Kamenný motiv",
    winter: "Zimní motiv",
    winterstone: "Téma zima-kámen",
    showOnPage: "Zobrazit průzkum na stránce",
    showInWindow: "Zobrazit průzkum v okně",
    loadFromServer: "Načíst průzkum JSON ze serveru",
    titleScript: "Skripty a styly",
    titleHtml: "HTML",
    titleJavaScript: "JavaScript"
  },
  // Preview (Survey)
  ts: {
    selectPage: "Vyberte stránku a otestujte ji:",
    showInvisibleElements: "Zobrazit neviditelné prvky",
    // hideInvisibleElements: "Hide invisible elements"
  },
  validators: {
    answercountvalidator: "počet odpovědí",
    emailvalidator: "e-mail",
    expressionvalidator: "výraz",
    numericvalidator: "číselné",
    regexvalidator: "regex",
    textvalidator: "text"
  },
  triggers: {
    completetrigger: "dokončit průzkum",
    setvaluetrigger: "nastavit hodnotu",
    copyvaluetrigger: "zkopírovat hodnotu",
    skiptrigger: "přeskočit na otázku",
    runexpressiontrigger: "spustit výraz",
    visibletrigger: "změnit viditelnost (zastaralé)"
  },
  pehelp: {
    // cookieName: "Cookies prevent users from filling out the same survey twice.",
    // format: "Use {0} as a placeholder for the actual value.",
    // totalText: "Visible only when at least one column has Total type or Total expression.",
    // acceptedTypes: "Refer to the [accept](https://www.w3schools.com/tags/att_input_accept.asp) attribute description for more information.",
    // columnColCount: "Applicable only to Radiogroup and Checkbox cell types.",
    // autocomplete: "Refer to the [autocomplete](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete) attribute description for more information.",
    // valueName: "If you do not set this property, the answer will be stored in a field specified by the Name property.",
    choicesbyurl: {
      // valueName: " "
    },
    // keyName: "If the specified column contains identical values, the survey throws the \"Non-unique key value\" error."
  },
  // Properties
  p: {
    title: {
      // name: "title",
      title: "Nechte pole prázdné, pokud je stejné jako „Název”"
    },
    // multiSelect: "Allow multiple selection",
    // showLabel: "Show image captions",
    // value: "Value",
    // visibleIf: "visibleIf",
    // attachOriginalItems: "attachOriginalItems",
    // useDisplayValuesInDynamicTexts: "useDisplayValuesInDynamicTexts",
    // titleLocation: "titleLocation",
    // description: "description",
    // descriptionLocation: "descriptionLocation",
    // defaultValueExpression: "defaultValueExpression",
    // requiredIf: "requiredIf",
    // validators: "validators",
    // bindings: "bindings",
    // renderAs: "renderAs",
    // currency: "currency",
    // cellHint: "cellHint",
    // isUnique: "isUnique",
    // showInMultipleColumns: "showInMultipleColumns",
    // totalMaximumFractionDigits: "totalMaximumFractionDigits",
    // totalMinimumFractionDigits: "totalMinimumFractionDigits",
    // columns: "columns",
    // detailElements: "detailElements",
    // choices: "choices",
    // allowAdaptiveActions: "allowAdaptiveActions",
    // defaultRowValue: "defaultRowValue",
    // detailPanelShowOnAdding: "detailPanelShowOnAdding",
    // logoFit: "logoFit",
    // pages: "pages",
    // questions: "questions",
    // triggers: "triggers",
    // calculatedValues: "calculatedValues",
    // surveyId: "surveyId",
    // surveyPostId: "surveyPostId",
    // surveyShowDataSaving: "surveyShowDataSaving",
    // questionDescriptionLocation: "questionDescriptionLocation",
    // progressBarType: "progressBarType",
    // questionTitlePattern: "questionTitlePattern",
    // widthMode: "widthMode",
    // showBrandInfo: "showBrandInfo",
    // choicesByUrl: "choicesByUrl",
    // choicesLazyLoadEnabled: "choicesLazyLoadEnabled",
    // choicesLazyLoadPageSize: "choicesLazyLoadPageSize",
    // inputFieldComponent: "inputFieldComponent",
    // itemComponent: "itemComponent",
    // min: "min",
    // max: "max",
    // minValueExpression: "minValueExpression",
    // maxValueExpression: "maxValueExpression",
    // step: "step",
    // dataList: "dataList",
    // itemSize: "itemSize",
    // elements: "elements",
    // content: "content",
    // navigationButtonsVisibility: "navigationButtonsVisibility",
    // navigationTitle: "navigationTitle",
    // navigationDescription: "navigationDescription",
    // closeOnSelect: "closeOnSelect",
    // longTap: "longTap",
    // autoGrow: "autoGrow",
    // acceptCarriageReturn: "acceptCarriageReturn",
    // displayMode: "displayMode",
    // label: "label",
    // contentMode: "contentMode",
    // imageFit: "imageFit",
    // altText: "altText",
    // height: "height",
    // penColor: "penColor",
    // backgroundColor: "backgroundColor",
    // templateElements: "templateElements",
    // operator: "operator",
    // isVariable: "isVariable",
    // runExpression: "runExpression",
    // showCaption: "showCaption",
    // iconName: "iconName",
    // iconSize: "iconSize"
  }
};

editorLocalization.locales["cs"] = czStrings;
