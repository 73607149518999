import {
  Avatar,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import {
  createSearchParams,
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import PageHeading from '../../../components/common/PageHeading';
import CustomPagination from '../../../components/new-components/CustomPagination';
import { Empty } from '../../../components/new-components/Empty';
import api from '../../services/API/events-dashboard';
import { useEventsTranslation } from '../../services/i18n';
import { eventsCommonCols, eventsStatuses } from '../utils';
import { DataGrid } from '@mui/x-data-grid';
import {
  CalendarMonth,
  Delete,
  Edit,
  Place,
  Visibility,
} from '@mui/icons-material';
import DeleteModal from '../../../components/new-components/DeleteModal';
import { placesApi } from '../../../Redux/store/places/api';
import moment from 'moment';

export default function EventsListPage() {
  const [searchParams, setSearchParams] = useSearchParams(
    createSearchParams({
      page_number: 0,
      page_size: 20,
      event_status_id: '',
    })
  );

  const { place_id } = useParams();
  const navigate = useNavigate();

  const [filterPayload, setFilterPayload] = useState({
    event_status_id: '',
  });

  const [deletedId, setDeletedId] = useState();
  const [confirmModal, setConfirmModal] = useState(false);

  const currentSearchParams = useMemo(
    () => ({
      page_number: searchParams.get('page_number'),
      page_size: searchParams.get('page_size'),
      event_status_id: searchParams.get('event_status_id'),
    }),
    [searchParams]
  );

  const setPage = (newPage) => {
    setSearchParams({
      ...currentSearchParams,
      page_number: newPage,
    });
    setIsRefreshing((prev) => prev + 1);
  };

  const [isRefreshing, setIsRefreshing] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await api.getAllEvents(currentSearchParams);
      setData(res);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAction = async () => {
    setIsLoading(true);
    try {
      await api.deleteEvent(deletedId);
      toast.success(t('deleted_successfully'));
      setIsRefreshing((prev) => prev + 1);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isRefreshing]);

  const handleApply = () => {
    setSearchParams({
      ...currentSearchParams,
      ...filterPayload,
      page_number: 0,
    });
    setIsRefreshing((prev) => prev + 1);
  };

  const handleChooseEvent = async (event_id, event_provider) => {
    try {
      await placesApi.updatePlaceInfo({
        id: place_id,
        payload: {
          event_id,
          reference_data: JSON.stringify(event_provider),
        },
      });
      toast.success(t('done_successfully'));
      navigate('/places/' + place_id);
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  const t = useEventsTranslation();

  const cols = [
    ...eventsCommonCols(t),
    {
      field: 'btn',
      headerName: t('actions'),
      headerClassName: 'bg-gray-100 text-center w-full h-full',
      disableColumnMenu: true,
      flex: 0.8,
      renderCell: (params) =>
        place_id ? (
          <div className='flex items-center justify-center w-full'>
            <Button
              variant='contained'
              onClick={() =>
                handleChooseEvent(
                  params.value.event_id,
                  params.value.event_provider
                )
              }
            >
              {t('choose')}
            </Button>
          </div>
        ) : (
          <div className='flex items-center ml-2 w-full gap-2'>
            <Link to={`/events/${params.value.event_id}`}>
              <Visibility color='primary' />
            </Link>
            <Link to={`/events/update/${params.value.event_id}`}>
              <Edit className='text-gray-800' />
            </Link>
            <button
              onClick={() => {
                setConfirmModal(true);
                setDeletedId(params.value.event_id);
              }}
            >
              <Delete color='error' />
            </button>
          </div>
        ),
    },
  ];

  const rowsData = data.map((event) => ({
    id: event.event_id,
    name: event,
    provider: event.event_provider,
    address: event,
    date: event,
    bookingDate: event,
    description: event,
    status: event,
    btn: event,
  }));

  return (
    <Box>
      <Box>
        <PageHeading
          heading1={t('events')}
          heading2={t('events_list')}
          backBtn
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
        />
      </Box>
      {place_id && (
        <p className='text-3xl font-bold text-center mt-2 mb-5'>
          {t('choose_event_to_continue')}
        </p>
      )}
      <Box className='flex items-center bg-gray-100 rounded-md p-2 my-3 justify-between'>
        <Box className='w-[200px] bg-white'>
          <FormControl fullWidth>
            <InputLabel id='filter-input'>{t('event_status')}</InputLabel>
            <Select
              labelId='filter-input'
              id='select'
              value={filterPayload.event_status_id}
              label={t('event_status')}
              onChange={(e) =>
                setFilterPayload({
                  ...filterPayload,
                  event_status_id: e.target.value,
                })
              }
            >
              <MenuItem value={''}>{t('all')}</MenuItem>
              {eventsStatuses.map((item, i) => (
                <MenuItem key={i} value={i + 1}>
                  {t(item.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Button variant='contained' color='primary' onClick={handleApply}>
          {t('apply')}
        </Button>
      </Box>
      <Box>
        <DataGrid
          className='border-2'
          disableColumnFilter
          autoHeight
          rowHeight={60}
          page={Number(currentSearchParams.page_number)}
          loading={isLoading}
          rows={rowsData}
          columns={cols}
          pageSize={10}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => setPage(newPage)}
          CellStyle='{StaticResource DataGridBorder}'
          components={{
            Toolbar: () => <></>,
            Pagination: () => CustomPagination(isLoading),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay: LinearProgress,
          }}
        />
      </Box>
      <DeleteModal
        openModal={confirmModal}
        setOpenModal={setConfirmModal}
        message={t('are_you_sure_you_want_to_delete')}
        onConfirm={deleteAction}
      />
    </Box>
  );
}
