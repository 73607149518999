import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Empty } from './Empty';
import LoadingIndicator from '../common/LoadingIndicator';
import { LinearProgress } from '@mui/material';
import CustomPagination from './CustomPagination';

function CustomToolbar() {
  return <></>;
}

const Table = ({ isLoading, pagination, loaderType, rowsData, cols, page }) => {
  return (
    <div className='flex h-auto'>
      <div className=' flex-grow'>
        <DataGrid
          className='border-2'
          disableColumnFilter
          autoHeight
          rowHeight={70}
          loading={isLoading}
          rows={rowsData}
          columns={cols}
          page={page}
          pageSize={1}
          rowCount={100}
          paginationMode='server'
          onPageChange={(newPage) => pagination(newPage)}
          CellStyle='{StaticResource DataGridBorder}'
          components={{
            Toolbar: CustomToolbar,
            Pagination: () => (pagination ? CustomPagination(isLoading) : null),
            NoRowsOverlay: Empty,
            NoResultsOverlay: Empty,
            LoadingOverlay:
              loaderType === 'page' ? LoadingIndicator : LinearProgress,
          }}
        />
      </div>
    </div>
  );
};

export default Table;
