const identity_en = {
  unblock_message: 'Are you sure you want to unblock this user !',
  block_message: 'Are you sure you want to block this user !',
  delete_message: 'Are you sure you want to delete this user !',
  deletion_status: 'Deletion status',
  block_status: 'Block status',
  identity: 'Identity',
  users: 'Users',
  apply: 'Apply',
  deleted: 'Deleted',
  not_deleted: ' Not Deleted',
  all: 'All',
  blocked: 'Blocked',
  block: 'Block',
  warning: 'Warning',
  not_blocked: 'Not Blocked ',
  status: 'Status',
  mobile: 'Mobile ',
  actions: 'Action',
  name: 'Name',
  full_name: 'Full name',
  email: 'Email',
  gender: 'Gender',
  block_reason: 'Block reason',
  deleted_at: 'Deleted at',
  details: 'Details',
  previous: 'Previous',
  next: 'Next',
};
export default identity_en;
