import { Avatar, Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import default_image from '../../../../assets/images/defult-pic.png';
import { fetchUsersTicketcategories } from '../../../../Redux/store/tickets/ticketsListSlice';
import { appDirection } from '../../../../shared_utils';
import { formatDate } from '../../../drivers/utils';
import CreateTicketDialog from '../../../tickets/components/common/CreateTicketDialog';
import { useUsersTranslation } from '../../i18n';

// avatar style
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const UserDetails = ({ isRefreshing, setIsRefreshing }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const { user } = useSelector((state) => state.users).usersList;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useUsersTranslation();
  //create ticket  dialog
  const [isOpen, setIsOpen] = React.useState(false);

  const dialogProps = {
    setIsOpen: setIsOpen,
    isOpen: isOpen,
    userId: user.user_id,
    client_id: 'LEMON_APP_ID',
    service_id: 'lemon_taxi',
    referenceId: null,
    isRefreshing: isRefreshing,
    setIsRefreshing: setIsRefreshing,
  };

  const getCatogries = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(fetchUsersTicketcategories());
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    getCatogries();
  }, []);

  if (isLoading) {
    return (
      <Grid container lg={12} md={12} justifyContent='center'>
        <div class='grid min-h-screen place-content-center'>
          <div class='flex items-center  text-xl text-gray-600'>
            <span class='h-12 w-12 block rounded-full border-4 border-t-blue-300 animate-spin'></span>
            loading...
          </div>
        </div>
      </Grid>
    );
  }

  return (
    <Grid
      container
      lg={12}
      md={12}
      xs={12}
      justifyContent='center'
      className='relative h-full'
      dir={appDirection}
    >
      {/* back btn */}

      <Grid
        item
        lg={9}
        md={9}
        xs={9}
        className=' border-2  pt-16  pb-6 text-center bg-gradient-to-br from-gray-200 to-gray-100  rounded-xl w-[24vw] '
      >
        {/* header  */}
        <Grid item lg={12} md={12} className='pb-4 flex justify-center'>
          {/* status */}

          <Avatar
            src={user.url || default_image}
            sx={{ height: '90px', width: '90px' }}
          />
        </Grid>
        {/* body  */}
        {/* item  */}

        {/* item  */}
        <Grid item lg={12} md={12} className='p-3 flex justify-evenly '>
          <Grid item lg={1} md={1} className='flex justify-start'>
            <span className=' text-sm font-semibold '>{t('ID')}:</span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white  '>
            <span className=' text-sm  font-serif w-full '>
              {user.user_id || '....'}
            </span>
          </Grid>
        </Grid>
        {/* item  */}
        <Grid item lg={12} md={12} className='p-3 flex justify-evenly'>
          <Grid item lg={1} md={1} className='flex justify-start'>
            <span className=' text-sm font-semibold '>{t('full_name')}:</span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className=' text-sm font-serif w-full '>
              {user.full_name || '....'}
            </span>
          </Grid>
        </Grid>

        {/* item  */}
        <Grid item lg={12} md={12} className='p-3 flex justify-evenly'>
          <Grid item lg={1} md={1} className='flex justify-start'>
            <span className=' text-sm font-semibold '>{t('phone')}:</span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className='text-sm  font-serif w-full'>
              {user.mobile || '....'}
            </span>
          </Grid>
        </Grid>

        {/* item  */}
        <Grid item lg={12} md={12} className='p-3 flex justify-evenly'>
          <Grid item lg={1} md={1} className='flex justify-start'>
            <span className=' text-sm font-semibold '>{t('email')}:</span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className='text-sm  font-serif w-full'>
              {user.email || '....'}
            </span>
          </Grid>
        </Grid>
        {/* item  */}

        {/* item  */}
        <Grid item lg={12} md={12} className='p-3 flex justify-evenly'>
          <Grid item lg={1} md={1} className='flex justify-start'>
            {' '}
            <span className=' text-sm font-semibold '>{t('created_at')}:</span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className='text-sm  font-serif w-full'>
              {formatDate(user.created_at) || '....'}
            </span>
          </Grid>
        </Grid>
        {/* item  */}
        <Grid item lg={12} md={12} className='p-3 flex justify-evenly'>
          <Grid item lg={1} md={1} className='flex justify-start'>
            <span className=' text-sm font-semibold '>{t('updated_at')}:</span>
          </Grid>

          <Grid item lg={8} md={8} sm={8} className='p-1 bg-white '>
            <span className='text-sm font-serif w-full'>
              {formatDate(user.updated_at) || '....'}
            </span>
          </Grid>
        </Grid>
        {/* manual btn  */}
        <Grid item lg={12} md={12} sm={12} className='px-2 pt-12'>
          <Button
            variant='contained'
            color='primary'
            onClick={() => setIsOpen(true)}
          >
            {t('create_ticket')}
          </Button>
          <CreateTicketDialog {...dialogProps} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserDetails;
