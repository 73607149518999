import { Grid, Paper } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const StatisticCard = ({ name, status_id }) => {
  const { ordersStatis } = useSelector((state) => state.orders).ordersList;

  const handleSetValue = () => {
    let value = null;
    ordersStatis.map((data) => {
      if (data.status_id === status_id) {
        value = data.total;
      }
    });

    if (value === null) {
      return 0;
    } else {
      return value;
    }
  };

  return (
    <Grid item lg={3} md={3} sm={6} xs={9}>
      <Paper elevation={6} className=' p-2  '>
        <span className=''>
          <h1 className='w-full pt-3 text-center uppercase text-xs font-serif font-semibold text-gray-500'>
            {name}
          </h1>
        </span>
        <p className='w-full p-5 text-center font-medium text-2xl'>
          {handleSetValue()}
          {/* { setTotal(total+count)} */}
        </p>
      </Paper>
    </Grid>
  );
};

export default StatisticCard;
