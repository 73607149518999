import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Chart from './common/Chart';

const DayChart = () => {
  const { dailyRateData } = useSelector((state) => state.app).app;

  return <Chart data={dailyRateData.acceptance_rates} type={'hourly'} />;
};

export default DayChart;
