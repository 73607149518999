import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import api from '../../../services/API/provider-employees';

const initialState = {
  isLoading: false,
  employees: [],
  error: null,
  isSuccess: null,
};

const sliceName = 'providerEmployees';

export const fetchEmployees = createAsyncThunk(
  sliceName + '/fetchEmployees',
  async (payload) => await api.getEmployees(payload.id, payload.page_number)
);

export const addEmployee = createAsyncThunk(
  sliceName + '/addEmployee',
  async (payload) => await api.addEmployee(payload)
);

export const deleteEmployee = createAsyncThunk(
  sliceName + '/deleteEmployee',
  async (payload) =>
    await api.deleteEmployee(payload.userId, payload.providerId)
);

const providerEmployees = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
    clearSuccess: (state) => {
      state.isSuccess = null;
    },
  },
  extraReducers: (builder) => {
    // fetch
    builder.addCase(fetchEmployees.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.employees = [];
    });
    builder.addCase(fetchEmployees.fulfilled, (state, action) => {
      state.isLoading = false;
      state.employees = action.payload;
      state.error = null;
    });
    builder.addCase(fetchEmployees.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.employees = [];
    });
    // create
    builder.addCase(addEmployee.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(addEmployee.fulfilled, (state) => {
      state.isLoading = false;
      state.error = null;
      toast.success(t('Created successfully'));
    });
    builder.addCase(addEmployee.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    // delete
    builder.addCase(deleteEmployee.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteEmployee.fulfilled, (state) => {
      state.isLoading = false;
      state.error = null;
      toast.success(t('Delete successfully'));
    });
    builder.addCase(deleteEmployee.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const { clearErrors, clearSuccess } = providerEmployees.actions;

export default providerEmployees.reducer;
