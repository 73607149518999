import React, { useState, useCallback } from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  Slide,
  TextField,
  Toolbar,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../../../Redux/store/app/appSlice';
import {
  updatePlaceDetails,
  setRefreshingPlace,
} from '../../../../Redux/store/places/placesListSlice';
import { useEffect } from 'react';
import UploadBtnOne from '../helper_components/Upload_one';
import { useRef } from 'react';
import { generateFormData } from '../../utils';

import Map from '../../../tracking/components/common/add_place_map/Map';
import { GridCloseIcon } from '@mui/x-data-grid-pro';
import { Done, Place, Room } from '@mui/icons-material';
import { usePlacesTranslation } from '../../i18n';
import { toast } from 'react-toastify';

// import FormDialog from "./FormDialog";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const MapDialog = ({ isOpen, setIsOpen, data, setData }) => {
  // const {isOpen, open, data,setData } = props;
  const [coordinates, setCoordinates] = useState({
    lng: data.lng,
    lat: data.lat,
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSetCoordinates = () => {
    // setData({ ...data, latitude: coordinates.lat, longitude: coordinates.lng });
    setData('latitude', coordinates.lat);
    setData('longitude', coordinates.lng);
    setIsOpen(false);
  };

  const mapProps = {
    coordinates: coordinates,
    setCoordinates: setCoordinates,
  };

  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={isOpen}
    >
      <appBar className='relative bg-blue-900 text-white'>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <GridCloseIcon />
          </IconButton>
          <IconButton
            edge='start'
            color='inherit'
            onClick={handleSetCoordinates}
            aria-label='close'
          >
            <Done />
          </IconButton>
        </Toolbar>
      </appBar>
      <Map {...mapProps} />
    </Dialog>
  );
};

function PlaceForm({ setOpen, open }) {
  const dispatch = useDispatch();
  const [autoCompleteValues, setAutoCompleteValues] = useState([]);

  const { IsRefreshingPlace } = useSelector((state) => state.places).places;

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { placeDetails } = useSelector((state) => state.places).places;

  const { amenitiesList } = useSelector((state) => state.places).places;

  const handleValuesSetup = () => {
    let newValues = amenitiesList.map((amenity) => {
      return { title: amenity.name, value: amenity.amenity_id };
    });
    newValues = newValues.concat({ title: 'none', value: null });
    setAutoCompleteValues(newValues);
  };

  useEffect(() => {
    handleValuesSetup();
  }, []);

  const [test, setTest] = useState(false);

  const isInitialMount = useRef(true);

  const [options, setOptions] = useState({
    id: placeDetails.place_id,
    payload: placeDetails,
  });

  const updatePlaceFrom = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(updatePlaceDetails(options));
      setOpen(false);
      await dispatch(setRefreshingPlace(IsRefreshingPlace + 1));
    } catch (err) {
      toast.error(err.data.message);
      if (err.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    } finally {
      setIsLoading(false);
    }
  });

  const handleSubmit = (values) => {
    const formData = generateFormData(values);
    setOptions({ ...options, payload: formData });
    setTest(true);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      updatePlaceFrom();
    }
  }, [test]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: placeDetails,
  });

  const t = usePlacesTranslation();

  return (
    <Grid container lg={12} md={12}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(formik.values);
        }}
      >
        <Grid
          container
          lg={12}
          md={12}
          spacing={2}
          justifyContent='center'
          alignItems='center'
        >
          <Grid
            container
            lg={12}
            md={12}
            spacing={2}
            className='flex justify-center  rounded'
          >
            <Grid item lg={12} md={12} marginTop={4}>
              <span className='text-lg font-semibold text-slate-600 '>
                {t('edit_form')}
              </span>
            </Grid>
            <Grid item lg={12} md={12}>
              {/* <Grid item className='' lg={12} md={12}>
              <label >Name</label>
              </Grid> */}
              <TextField
                fullWidth
                label={t('name_en')}
                id='name_en'
                name='name_en'
                variant='outlined'
                margin='dense'
                value={formik.values.name_en}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item lg={12} md={12}>
              <Grid item lg={12} md={12}>
                <TextField
                  fullWidth
                  label={t('name_ar')}
                  id='name_ar'
                  name='name_ar'
                  variant='outlined'
                  margin='dense'
                  value={formik.values.name_ar}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
            <Grid item lg={6} md={6}>
              <Autocomplete
                id='amenity_id'
                name='amenity_id'
                fullWidth
                options={autoCompleteValues}
                getOptionLabel={(option) => option.title}
                style={{ width: '100%' }}
                // value={formik.values.amenity_id}

                onChange={(event, value) => {
                  formik.setFieldValue('amenity_id', value.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('amenity')}
                    variant='outlined'
                    value={formik.values.amenity_id}
                  />
                )}
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <TextField
                fullWidth
                label={t('address')}
                id='address'
                name='address'
                variant='outlined'
                // margin="dense"
                value={formik.values.address}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <TextField
                fullWidth
                label={t('phone')}
                id='phone'
                name='phone'
                variant='outlined'
                // margin="dense"
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <TextField
                fullWidth
                label={t('website')}
                id='website'
                name='website'
                variant='outlined'
                // margin="dense"
                value={formik.values.website}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item lg={6} md={6}>
              <Button
                size='small'
                variant='outlined'
                color='primary'
                fullWidth
                // className={classes.button}
                onClick={(e) => setIsOpen(true)}
                startIcon={<Place />}
              >
                {t('set_location_on_a_map')}
              </Button>
            </Grid>
            <Grid item lg={12} md={12}>
              <TextField
                required
                name='latitude'
                fullWidth
                id='latitude'
                variant='outlined'
                label={t('latitude')}
                type='number'
                onChange={formik.handleChange}
                value={formik.values.latitude}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Room />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item lg={12} md={12}>
              <TextField
                required
                name='longitude'
                fullWidth
                id='longitude'
                variant='outlined'
                label={t('longitude')}
                type='number'
                onChange={formik.handleChange}
                value={formik.values.longitude}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Room />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid lg={12} md={12} className='flex justify-center p-5 '>
              <UploadBtnOne
                setValue={formik.setFieldValue}
                onChange={formik.handleChange}
                value={formik.values.place_logo}
                name={'place_logo'}
                title={t('logo')}
              />
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} className='flex justify-center p-5 '>
            <Button color='primary' variant='contained' type='submit'>
              {t('submit')}
            </Button>
          </Grid>
          <MapDialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            data={
              formik.values.latitude
                ? { lat: formik.values.latitude, lng: formik.values.longitude }
                : { lat: 15.5007, lng: 32.5599 }
            }
            setData={formik.setFieldValue}
          />
        </Grid>
      </form>
    </Grid>
  );
}

export default PlaceForm;
