import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../services/API/acceptance-rate';

const initialState = {
  isLoading: false,
  isDailogOpen: false,
  hourlyRateData: [],
  dailyRateData: [],
  weeklyRateData: [],
  rateDetails: {},
  error: null,
};
// Generates pending, fulfilled and rejected action types
export const fetchRateData = createAsyncThunk('acceptance_rate/fetchData', async () => {
  const hourly = await api.getDailyRate();
  const daily = await api.getWeeklyRate();
  const weekly = await api.getMonthlyRate();
  return { daily, weekly, hourly };
});

export const fetchRateDetails = createAsyncThunk(
  'acceptance_rate/fetchRateDetails',
  async (payload) => {
    await api.getDetail(payload);
  }
);

const acceptanceRateSlice = createSlice({
  name: 'acceptance_rate',
  initialState,
  reducer: {
    dialog: (state) => {
      state.isDailogOpen = !state.isDailogOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRateData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRateData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hourlyRateData = action.payload.hourly.acceptance_rates;
      state.dailyRateData = action.payload.daily.acceptance_rates;
      state.weeklyRateData = action.payload.weekly.acceptance_rates;
    });
    builder.addCase(fetchRateData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    builder.addCase(fetchRateDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRateDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.rateDetails = action.payload;
      state.isDailogOpen = true;
    });
    builder.addCase(fetchRateDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const { dialog } = acceptanceRateSlice.actions;
export default acceptanceRateSlice.reducer;
