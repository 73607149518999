import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { TextField, Button, MenuItem } from '@mui/material';
import { useUsersTranslation } from '../../../users/i18n';
import { appDirection } from '../../../../shared_utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    margin: '0px 0px 10px 0px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function ListFiler({
  setIsRefreshing,
  isRefreshing,
  statusId,
  setStatusId,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useUsersTranslation();

  return (
    <Grid
      container
      lg={12}
      md={12}
      justifyContent
      // className='p-1 bg-gray-200 rounded-t-xl'
      dir={appDirection}
    >
      <Grid container lg={12} md={12} className=' flex justify-end p-1'>
        <Grid container lg={11} md={11} className=''>
          <Grid item lg={2} md={2} sm={2} xs={2} sx={{ mt: -1 }}>
            <TextField
              className='bg-white'
              fullWidth
              select
              id='outlined-select-currency'
              InputLabelProps={{ shrink: true }}
              label={t('status')}
              value={statusId}
              onChange={(e) => setStatusId(e.target.value)}
              margin='dense'
              variant='outlined'
              size='small'
            >
              <MenuItem value={0}>{t('new')}</MenuItem>
              <MenuItem value={1}>{t('under_process')}</MenuItem>
              <MenuItem value={2}>{t('closed')}</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid
          item
          lg={1}
          md={1}
          sm={1}
          xs={1}
          className='flex justify-center h-full '
        >
          <Button
            variant='contained'
            className='h-full p-8'
            color='primary'
            type='submit'
            onClick={() => {
              setIsRefreshing(isRefreshing + 1);
            }}
          >
            {t('apply')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
