// This dictionary contains 318 untranslated or inherited localization strings.
// These strings are commented out. Uncomment and edit them if you want to add your translations.
import { editorLocalization, defaultStrings } from "survey-creator-core";

export var nlStrings = {
  // survey templates
  survey: {
    edit: "Bewerk",
    externalHelpLink: "Bekijk en leer hoe u enquêtes maakt",
    externalHelpLinkUrl: "https://www.youtube.com/channel/UCH2ru9okIcooNZfpIbyq4qQ?view_as=subscriber",
    dropQuestion: "Stel hier een vraag vanuit de Toolbox.",
    addLogicItem: "Maak een regel om de stroom van de enquête aan te passen.",
    copy: "Kopiëren",
    // duplicate: "Duplicate",
    addToToolbox: "Toevoegen aan toolbox",
    deletePanel: "Paneel verwijderen",
    deleteQuestion: "Vraag verwijderen",
    convertTo: "Omzetten naar",
    drag: "Sleep element"
  },
  // Question types
  qt: {
    default: "Standaard",
    checkbox: "Selectievakje",
    comment: "Commentaar",
    imagepicker: "Afbeelingsvraag",
    // ranking: "Ranking",
    image: "Afbeelding",
    dropdown: "Keuzelijst",
    // tagbox: "Tag Box",
    file: "Bestandsupload",
    html: "Html",
    matrix: "Matrix (enkele keuze)",
    matrixdropdown: "Matrix (meerkeuze)",
    matrixdynamic: "Matrix (dynamische rijen)",
    multipletext: "Meervoudige tekstvak",
    panel: "Paneel",
    paneldynamic: "Paneel (dynamische panelen)",
    radiogroup: "Meerkeuzevraag",
    rating: "Beoordeling",
    text: "Tekstvak",
    boolean: "Boolean",
    expression: "Berekening",
    signaturepad: "Handtekening",
    // buttongroup: "Button Group"
  },
  // Strings in SurveyJS Creator
  ed: {
    defaultLocale: "Standaard ({0})",
    survey: "Enquête",
    settings: "Enquête-instellingen",
    settingsTooltip: "Open enquête-instellingen",
    // surveySettings: "Survey Settings",
    // surveySettingsTooltip: "Open survey settings",
    showPanel: "Toon paneel",
    hidePanel: "Verberg paneel",
    // prevSelected: "Select previous",
    // nextSelected: "Select next",
    // surveyTypeName: "Survey",
    // pageTypeName: "Page",
    // panelTypeName: "Panel",
    // questionTypeName: "Question",
    // columnTypeName: "Column",
    addNewPage: "Pagina toevoegen",
    moveRight: "Scroll naar rechts",
    moveLeft: "Scroll naar links",
    deletePage: "Pagina verwijderen",
    editPage: "Pagina aanpassen",
    edit: "Bewerk",
    newPageName: "Pagina",
    newQuestionName: "Vraag",
    newPanelName: "Paneel",
    newTextItemName: "Tekst",
    testSurvey: "Test de enquête",
    // defaultV2Theme: "Default",
    // modernTheme: "Modern",
    // defaultTheme: "Default (legacy)",
    testSurveyAgain: "Test de enquête opnieuw",
    testSurveyWidth: "Onderzoeksbreedte: ",
    navigateToMsg: "Je moest navigeren naar:",
    logic: "Onderzoekslogica",
    embedSurvey: "Enquête insluiten",
    translation: "Vertaling",
    saveSurvey: "Enquête opslaan",
    saveSurveyTooltip: "Enquête opslaan",
    designer: "Enquête-ontwerper",
    jsonEditor: "JSON-editor",
    // jsonHideErrors: "Hide errors",
    // jsonShowErrors: "Show errors",
    undo: "Ongedaan maken",
    redo: "Opnieuw uitvoeren",
    undoTooltip: "Maak de laatste wijziging ongedaan",
    redoTooltip: "Voer de wijziging opnieuw uit",
    // showMoreChoices: "Show more",
    // showLessChoices: "Show less",
    copy: "Kopiëren",
    cut: "Besnoeiing",
    paste: "Plakken",
    copyTooltip: "Kopieer selectie naar klembord",
    cutTooltip: "Knip de selectie naar het klembord",
    pasteTooltip: "Plakken vanaf klembord",
    options: "Opties",
    generateValidJSON: "Genereer geldige JSON",
    generateReadableJSON: "Genereer leesbare JSON",
    toolbox: "Gereedschap",
    "property-grid": "Eigenschappen",
    // propertyGridFilteredTextPlaceholder: "Type to search...",
    toolboxGeneralCategory: "Algemeen",
    // toolboxChoiceCategory: "Choice Questions",
    // toolboxTextCategory: "Text Input Questions",
    // toolboxContainersCategory: "Containers",
    // toolboxMatrixCategory: "Matrix Questions",
    // toolboxMiscCategory: "Misc",
    correctJSON: "Corrigeer JSON.",
    surveyResults: "Enquêteresultaat: ",
    surveyResultsTable: "Als tafel",
    surveyResultsJson: "Als JSON",
    resultsTitle: "Vraag titel",
    resultsName: "Vraag naam",
    resultsValue: "Antwoordwaarde",
    resultsDisplayValue: "Waarde weergeven",
    modified: "Gewijzigd",
    saving: "Besparing",
    saved: "Opgeslagen",
    propertyEditorError: "Fout:",
    saveError: "Fout! De inhoud van de editor wordt niet opgeslagen.",
    // translationPropertyGridTitle: "Language Settings",
    // translationLanguages: "Languages",
    translationAddLanguage: "Selecteer de taal om te vertalen",
    translationShowAllStrings: "Toon alle snaren",
    // translationShowUsedStringsOnly: "Used Strings Only",
    translationShowAllPages: "Toon alle paginas",
    translationNoStrings: "Geen verplichtingen om te vertalen. Vervang alstublieft het filter.",
    translationExportToSCVButton: "Exporteren naar CSV",
    translationImportFromSCVButton: "Importeren vanuit CSV",
    translationMergeLocaleWithDefault: "Voeg {0} samen met de standaardlandinstelling",
    // translationPlaceHolder: "Translation...",
    bold: "Vetgedrukt",
    italic: "Cursief",
    underline: "Onderstrepen",
    addNewQuestion: "Vraag toevoegen",
    selectPage: "Selecteer pagina...",
    // htmlPlaceHolder: "HTML content will be here.",
    // panelPlaceHolder: "Drop a question from the toolbox here.",
    // surveyPlaceHolder: "The survey is empty. Drag an element from the toolbox or click the button below.",
    // addNewTypeQuestion: "Add {0}", //{0} is localizable question type
    // chooseLogoPlaceholder: "[LOGO]",
    // auto: "auto",
    lg: {
      // addNewItem: "Add New Rule",
      // empty_tab: "Create a rule to customize the flow of the survey.",
      page_visibilityName: "Pagina zichtbaarheid",
      // page_enableName: "Enable (disable) page",
      panel_visibilityName: "Paneel zichtbaarheid",
      panel_enableName: "Paneel inschakelen/uitschakelen",
      question_visibilityName: "Vraag zichtbaarheid",
      question_enableName: "Vraag inschakelen/uitschakelen",
      question_requireName: "Vraag optioneel vereist",
      // column_visibilityName: "Show (hide) column",
      // column_enableName: "Enable (disable) column",
      // column_requireName: "Make column required",
      trigger_completeName: "Volledige enquête",
      trigger_setvalueName: "Stel vraagwaarde in",
      trigger_copyvalueName: "Kopieer de vraagwaarde",
      trigger_skipName: "Ga naar de vraag",
      trigger_runExpressionName: "Voer een aangepaste expressie uit",
      completedHtmlOnConditionName: "Aangepaste 'Bedanktpagina'-tekst",
      page_visibilityDescription: "Maak de pagina zichtbaar wanneer de logische uitdrukking true retourneert. Houd het anders onzichtbaar.",
      panel_visibilityDescription: "Maak het paneel zichtbaar wanneer de logische uitdrukking true retourneert. Houd het anders onzichtbaar.",
      panel_enableDescription: "Zorg ervoor dat het paneel en alle elementen erin worden ingeschakeld wanneer de logische uitdrukking true retourneert. Houd ze anders uitgeschakeld.",
      question_visibilityDescription: "Maak de vraag zichtbaar wanneer de logische uitdrukking true retourneert. Houd het anders onzichtbaar.",
      question_enableDescription: "Schakel de vraag in wanneer de logische uitdrukking true retourneert. Houd het anders uitgeschakeld.",
      question_requireDescription: "Vraag wordt vereist wanneer de logische uitdrukking true retourneert.",
      trigger_completeDescription: "Wanneer de logische uitdrukking true retourneert, wordt de enquête voltooid en ziet een eindgebruiker de 'Bedankpagina'.",
      trigger_setvalueDescription: "Wanneer vraagwaarden, die worden gebruikt in de logische uitdrukking, worden gewijzigd en de logische uitdrukking true retourneert, wordt de waarde ingesteld op de geselecteerde vraag.",
      trigger_copyvalueDescription: "Wanneer vraagwaarden, die worden gebruikt in de logische uitdrukking, worden gewijzigd en de logische uitdrukking true retourneert, wordt de waarde van een geselecteerde vraag gekopieerd naar een andere geselecteerde vraag.",
      trigger_skipDescription: "Als de logische uitdrukking true retourneert, gaat de enquête naar/focust de geselecteerde vraag.",
      trigger_runExpressionDescription: "Wanneer de logische expressie true retourneert, wordt de aangepaste expressie uitgevoerd. U kunt dit expressieresultaat optioneel instellen voor de geselecteerde vraag",
      completedHtmlOnConditionDescription: "Als de logische uitdrukking true retourneert, wordt de standaardtekst voor de 'Bedankpagina' gewijzigd in de opgegeven tekst.",
      itemExpressionText: "Wanneer expressie: '{0}' true retourneert:", //{0} - the expression
      // itemEmptyExpressionText: "New rule",
      page_visibilityText: "Maak pagina {0} zichtbaar", //{0} page name
      panel_visibilityText: "Maak paneel {0} zichtbaar", //{0} panel name
      panel_enableText: "Schakel paneel {0} in", //{0} panel name
      question_visibilityText: "Maak vraag {0} zichtbaar", //{0} question name
      question_enableText: "Schakel vraag {0} in", //{0} question name
      question_requireText: "Stel vraag {0} verplicht", //{0} question name
      // column_visibilityText: "make column {0} of question {1} visible", //{0} column name, {1} question name
      // column_enableText: "make column {0} of question {1} enable", //{0} column name, {1} question name
      // column_requireText: "make column {0} of question {1} required", //{0} column name, {1} question name
      trigger_completeText: "Enquête wordt voltooid",
      trigger_setvalueText: "Stel de vraag: {0} waarde {1}", //{0} question name, {1} setValue
      trigger_copyvalueText: "Kopieer naar vraag: {0} waarde uit vraag {1}", //{0} and {1} question names
      trigger_skipText: "Enquête ga verder met de vraag {0}", //{0} question name
      trigger_runExpressionText1: "Uitdrukking uitvoeren: '{0}'", //{0} the expression
      trigger_runExpressionText2: " en stel het resultaat in vraag: {0}", //{0} question name
      completedHtmlOnConditionText: "Toon aangepaste tekst voor de 'Bedankpagina'.",
      // showAllQuestions: "All Questions",
      // showAllActionTypes: "All Action Types",
      conditions: "Voorwaard(en)",
      actions: "Actie(s)",
      expressionEditorTitle: "Definieer conditie(s)",
      actionsEditorTitle: "Definieer actie(s)",
      deleteAction: "Actie verwijderen",
      addNewAction: "Voeg nieuwe actie toe",
      selectedActionCaption: "Selecteer een actie om toe te voegen...",
      expressionInvalid: "De logische uitdrukking is leeg of ongeldig. Verbeter dit alstublieft.",
      noActionError: "Voeg ten minste één actie toe.",
      actionInvalid: "Los problemen in uw actie(s) op.",
      // expressionSetup: "",
      // actionsSetup: ""
    }
  },
  // Property Editors
  pe: {
    apply: "Van toepassing zijn",
    ok: "OK",
    save: "Opslaan",
    // clear: "Clear",
    saveTooltip: "Opslaan",
    cancel: "Annuleren",
    // set: "Set",
    reset: "Resetten",
    // change: "Change",
    refresh: "Opslaan",
    // close: "Close",
    delete: "Verwijderen",
    add: "Toevoegen",
    addNew: "Nieuwe toevoegen",
    addItem: "Klik om een item toe te voegen...",
    // removeItem: "Click to remove the item...",
    // dragItem: "Drag the item",
    addOther: "Andere",
    addSelectAll: "Selecteer alles",
    addNone: "Geen",
    removeAll: "Verwijder alles",
    edit: "Bewerk",
    back: "Keer terug zonder op te slaan",
    backTooltip: "Keer terug zonder op te slaan",
    saveAndBack: "Bewaar en keer terug",
    saveAndBackTooltip: "Bewaar en keer terug",
    // doneEditing: "Done",
    editChoices: "Keuzes bewerken",
    showChoices: "Toon keuzes",
    move: "Bewegen",
    empty: "<leeg>",
    // emptyValue: "Value is empty",
    fastEntry: "Snelle toegang",
    // fastEntryNonUniqueError: "Value '{0}' is not unique",
    // fastEntryChoicesCountError: "Please limit the number of items from {0} to {1}",
    // fastEntryPlaceholder: "You can set data in the following format:\nvalue1|text\nvalue2",
    formEntry: "Formulierinvoer",
    testService: "Test de service",
    itemSelectorEmpty: "Selecteer het element",
    conditionActionEmpty: "Selecteer de actie",
    conditionSelectQuestion: "Selecteer vraag...",
    conditionSelectPage: "Selecteer pagina...",
    conditionSelectPanel: "Selecteer paneel...",
    conditionValueQuestionTitle: "Voer/selecteer de waarde",
    expressionHelp: "U kunt accolades gebruiken om toegang te krijgen tot de vraagwaarden: {vraag1} + {vraag2}, ({prijs} * {aantal}) * (100 - {korting}). U kunt functies gebruiken als: iif(), today(), age(), min(), max(), count(), avg() en anderen.",
    aceEditorHelp: "Druk op Ctrl+spatiebalk om een hint voor het voltooien van een uitdrukking te krijgen",
    aceEditorRowTitle: "Huidige rij",
    aceEditorPanelTitle: "Huidig paneel",
    showMore: "Raadpleeg de documentatie voor meer details",
    assistantTitle: "Beschikbare vragen:",
    cellsEmptyRowsColumns: "Er moet minimaal één kolom of rij zijn",
    showPreviewBeforeComplete: "Voorbeeldweergave voor afronden",
    propertyIsEmpty: "Voer alstublieft een waarde in",
    // propertyIsNoUnique: "Please enter a unique value",
    propertyNameIsNotUnique: "Voer een unieke naam in",
    listIsEmpty: "Voeg een nieuw item toe",
    // "listIsEmpty@choices": "No choices have been added yet",
    // "addNew@choices": "Add a choice",
    expressionIsEmpty: "Expressie is leeg",
    value: "Waarde",
    text: "Tekst",
    rowid: "Rij-ID",
    imageLink: "Afbeeldingslink",
    columnEdit: "Kolom bewerken: {0}",
    itemEdit: "Bewerk item: {0}",
    url: "URL",
    path: "Pad",
    valueName: "Waardenaam",
    choicesbyurl: {
      // valueName: "Get values from the following JSON field"
    },
    titleName: "Titel",
    // imageLinkName: "Get image URLs from the following JSON field",
    allowEmptyResponse: "Leeg respons toestaan",
    titlePlaceholder: "Voer hier de titel in",
    surveyTitlePlaceholder: "Voer de enquêtetitel hier in",
    pageTitlePlaceholder: "Voer hier de paginatitel in",
    descriptionPlaceholder: "Voer een omschrijving in",
    surveyDescriptionPlaceholder: "Voer een onderzoeksbeschrijving in",
    pageDescriptionPlaceholder: "Voer een paginabeschrijving in",
    showOtherItem: "Heeft de 'Anders:'-optie",
    otherText: "Titel 'Anders:'-optie",
    showNoneItem: "Heeft de 'Geen'-optie",
    noneText: "Titel 'Geen'-optie",
    showSelectAllItem: "Heeft de 'Alles selecteren'-optie",
    selectAllText: "Titel 'Alles selecteren'-optie",
    choicesMin: "Minimumwaarde voor automatisch gegenereerde items",
    choicesMax: "Maximale waarde voor automatisch gegenereerde items",
    choicesStep: "Het verschil tussen automatisch gegenereerde items",
    name: "Naam",
    title: "Titel",
    cellType: "Celtype",
    colCount: "Kolom tellen",
    choicesOrder: "Volgorde opties",
    visible: "Is zichtbaar?",
    isRequired: "Is verplicht?",
    isAllRowRequired: "Antwoord vereisen voor alle rijen",
    requiredErrorText: "Tekst bij niet-ingevulde verplichte vraag",
    startWithNewLine: "Beginnen met een nieuwe regel?",
    rows: "Aantal rijen",
    cols: "Kolom tellen",
    placeholder: "Hulptekst",
    showPreview: "Toon voorbeeldgebied",
    storeDataAsText: "Bewaar bestandsinhoud in JSON-resultaat als tekst",
    maxSize: "Maximale bestandsgrootte in bytes",
    imageHeight: "Afbeelding hoogte",
    imageWidth: "Afbeelding breedte",
    rowCount: "Aantal rijen",
    columnLayout: "Kolommen layout",
    addRowLocation: "Voeg de locatie van de rijknop toe",
    addRowText: "Voeg tekst van de rijknop toe",
    removeRowText: "Verwijder de tekst van de rijknop",
    rateMin: "Minimumtarief",
    rateMax: "Maximaal tarief",
    rateStep: "Beoordeel stap",
    minRateDescription: "Beschrijving minimumtarief",
    maxRateDescription: "Maximale tariefomschrijving",
    inputType: "Invoertype",
    optionsCaption: "Bijschrift opties",
    defaultValue: "Standaardwaarde",
    cellsDefaultRow: "Standaard celteksten",
    surveyEditorTitle: "Bewerk enquête-instellingen",
    qEditorTitle: "Bewerken: {0}",
    maxLength: "Maximale lengte",
    buildExpression: "Bouwen",
    editExpression: "Bewerk",
    // and: "and",
    // or: "or",
    remove: "Verwijderen",
    addCondition: "Voorwaarde toevoegen",
    // emptyLogicPopupMessage: "Select a question to start configuring conditions.",
    // if: "If",
    // then: "then",
    // setToName: "Target question",
    // fromName: "Question to copy answer from",
    // gotoName: "Question to skip to",
    // ruleIsNotSet: "Rule is incorrect",
    // includeIntoResult: "Include into survey results",
    showTitle: "Titel weergeven/verbergen",
    expandCollapseTitle: "Titel uitvouwen/samenvouwen",
    locale: "Standaardtaal",
    simulator: "Kies apparaat",
    landscapeOrientation: "Landschap",
    // portraitOrientation: "Switch to portrait orientation",
    mode: "Modus (bewerken/alleen lezen)",
    clearInvisibleValues: "Wis onzichtbare waarden",
    cookieName: "Cookienaam (zodat enquête slechts éénmalig wordt ingevuld)",
    sendResultOnPageNext: "Antwoorden opslaan bij pagina-overgang",
    storeOthersAsComment: "Sla de waarde van 'anderen' op in een apart veld",
    showPageTitles: "Toon paginatitels",
    showPageNumbers: "Toon paginanummers",
    pagePrevText: "Knoptitel 'Vorige pagina'",
    pageNextText: "Knoptitel 'Volgende pagina'",
    completeText: "Knoptitel 'Afronden'",
    previewText: "Knoptitel 'Voorbeeldweergave'",
    editText: "Knoptitel 'Bewerken'",
    startSurveyText: "Knoptitel 'Starten'",
    showNavigationButtons: "Navigatieknoppen weergeven (standaardnavigatie)",
    showPrevButton: "Toon knop 'Vorige pagina' (gebruiker kan terugkeren)",
    firstPageIsStarted: "De eerste pagina in de enquête is een startpagina",
    showCompletedPage: "Toon bij afronden deze HTML-code",
    goNextPageAutomatic: "Na alle vragen automatisch naar volgende pagina gaan",
    showProgressBar: "Toon voortgangsbalk",
    questionTitleLocation: "Plek vraagtitel",
    requiredText: "Symbool(en) verplichte vraag",
    questionStartIndex: "Eerste vraag (1, 2 or 'A', 'a')",
    showQuestionNumbers: "Toon vraagnummers",
    questionTitleTemplate: "Vraagtitelsjabloon, standaard is: '{no}. {vereisen} {titel}'",
    questionErrorLocation: "Plek vraagfoutmelding",
    focusFirstQuestionAutomatic: "Op volgende pagina focus op de eerste vraag zetten",
    questionsOrder: "Volgorde elementen op pagina",
    maxTimeToFinish: "Maximale tijd om de enquête te voltooien",
    maxTimeToFinishPage: "Maximale tijd om een pagina in de enquête te voltooien",
    page: {
      // maxTimeToFinish: "Time limit to finish the page (in seconds)"
    },
    question: {
      // page: "Parent page"
    },
    showTimerPanel: "Toon timerpaneel",
    showTimerPanelMode: "Modus timerpaneel",
    renderMode: "Render-modus",
    allowAddPanel: "Sta het toevoegen van een paneel toe",
    allowRemovePanel: "Laat het verwijderen van het paneel toe",
    // noEntriesText: "Empty entries text",
    panelAddText: "Paneeltekst toevoegen",
    panelRemoveText: "Paneeltekst verwijderen",
    isSinglePage: "Toon alle elementen op één pagina",
    html: "Html",
    expression: "Uitdrukking",
    // setValue: "Answer",
    // dataFormat: "Image format",
    // allowAddRows: "Allow adding rows",
    // allowRemoveRows: "Allow removing rows",
    // allowRowsDragAndDrop: "Allow row drag and drop",
    // responsiveImageSizeHelp: "Does not apply if you specify the exact image width or height.",
    // minImageWidth: "Minimum image width",
    // maxImageWidth: "Maximum image width",
    // minImageHeight: "Minimum image height",
    // maxImageHeight: "Maximum image height",
    minValue: "Minimale waarde",
    maxValue: "Maximale waarde",
    minLength: "Minimale lengte",
    allowDigits: "Cijfers toestaan",
    minCount: "Minimum aantal",
    maxCount: "Maximale telling",
    regex: "Reguliere expressie",
    surveyvalidator: {
      // text: "Error message",
      // expression: "Validation expression"
    },
    totalText: "Totale tekst",
    totalType: "Totaal type",
    totalExpression: "Totale expressie",
    totalDisplayStyle: "Totale weergavestijl",
    totalCurrency: "Totale valuta",
    totalFormat: "Totaal formaat",
    // logo: "Logo (URL or base64-encoded string)",
    // questionsOnPageMode: "Survey structure",
    maxTextLength: "Maximale tekstlengte",
    maxOthersLength: "Maximale tekstlengte optie 'Anders:'",
    // autoGrowComment: "Auto-expand comment area if necessary",
    textUpdateMode: "Modus tekstvernieuwing",
    focusOnFirstError: "Focus op eerste fout zetten",
    // checkErrorsMode: "Run validation",
    // navigateToUrl: "Navigate to URL",
    // navigateToUrlOnCondition: "Dynamic URL",
    // completedBeforeHtml: "Markup to show if the user already filled out this survey",
    // completedHtml: "Survey Complete page markup",
    // completedHtmlOnCondition: "Dynamic Survey Complete page markup",
    // loadingHtml: "Markup to show while survey model is loading",
    // commentText: "Comment area text",
    // autocomplete: "Autocomplete type",
    // labelTrue: "\"True\" label",
    // labelFalse: "\"False\" label",
    // allowClear: "Show the Clear button",
    // displayStyle: "Value display style",
    // format: "Formatted string",
    // maximumFractionDigits: "Maximum fractional digits",
    // minimumFractionDigits: "Minimum fractional digits",
    // useGrouping: "Display grouping separators",
    // allowMultiple: "Allow multiple files",
    // allowImagesPreview: "Preview images",
    // acceptedTypes: "Accepted file types",
    // waitForUpload: "Wait for the upload to complete",
    // needConfirmRemoveFile: "Confirm file deletion",
    // detailPanelMode: "Detail panel location",
    // minRowCount: "Minimum row count",
    // maxRowCount: "Maximum row count",
    // confirmDelete: "Confirm row deletion",
    // confirmDeleteText: "Confirmation message",
    paneldynamic: {
      // confirmDelete: "Confirm panel deletion"
    },
    // panelCount: "Initial panel count",
    // minPanelCount: "Minimum panel count",
    // maxPanelCount: "Maximum panel count",
    // panelsState: "Inner panel expand state",
    // templateDescription: "Description template",
    // templateTitle: "Title template",
    // panelPrevText: "Previous Panel button tooltip",
    // panelNextText: "Next Panel button tooltip",
    // showRangeInProgress: "Show progress bar",
    // templateTitleLocation: "Question title location",
    // panelRemoveButtonLocation: "Remove Panel button location",
    // hideIfRowsEmpty: "Hide the question if there are no rows",
    // hideColumnsIfEmpty: "Hide columns if there are no rows",
    // rateValues: "Custom rate values",
    // hideIfChoicesEmpty: "Hide the question if it contains no choices",
    // hideNumber: "Hide question number",
    // minWidth: "Minimum width (in CSS-accepted values)",
    // maxWidth: "Maximum width (in CSS-accepted values)",
    // width: "Width (in CSS-accepted values)",
    // showHeader: "Show column headers",
    // horizontalScroll: "Show horizontal scrollbar",
    // columnMinWidth: "Minimum column width (in CSS-accepted values)",
    // rowTitleWidth: "Row header width (in CSS-accepted values)",
    // valueTrue: "\"True\" value",
    // valueFalse: "\"False\" value",
    // minErrorText: "\"Value is below minimum\" error message",
    // maxErrorText: "\"Value exceeds maximum\" error message",
    // otherErrorText: "\"Empty comment\" error message",
    // keyDuplicationError: "\"Non-unique key value\" error message",
    // maxSelectedChoices: "Maximum selected choices",
    // showClearButton: "Show the Clear button",
    // showNumber: "Show panel number",
    logoWidth: "Breedte logo",
    logoHeight: "Hoogte logo",
    // readOnly: "Read-only",
    // enableIf: "Editable if",
    // emptyRowsText: "\"No rows\" message",
    // size: "Input size (in characters)",
    // separateSpecialChoices: "Separate special choices (None, Other, Select All)",
    // choicesFromQuestion: "Copy choices from the following question",
    // choicesFromQuestionMode: "Which choices to copy?",
    // showCommentArea: "Show the comment area",
    // commentPlaceholder: "Comment area placeholder",
    // displayRateDescriptionsAsExtremeItems: "Display rate descriptions as extreme values",
    // rowsOrder: "Row order",
    // columnsLayout: "Column layout",
    // columnColCount: "Nested column count",
    // state: "Panel expand state",
    // correctAnswer: "Correct Answer",
    // defaultPanelValue: "Default Values",
    // cells: "Cell Texts",
    // keyName: "Key column",
    itemvalue: {
      // text: "Alt text"
    },
    logoPosition: "Positie logo",
    addLogo: "Logo toevoegen...",
    changeLogo: "Logo wijzigen...",
    logoPositions: {
      none: "Logo verwijderen",
      left: "Links",
      right: "Rechtsaf",
      top: "Bovenop",
      bottom: "Onder aan"
    },
    tabs: {
      general: "Algemeen",
      fileOptions: "Opties",
      html: "Html-editor",
      columns: "Kolommen",
      rows: "Rijen",
      choices: "Keuzes",
      items: "Artikelen",
      visibleIf: "Zichtbaar als",
      enableIf: "Schakel If in",
      requiredIf: "Vereist als",
      rateValues: "Beoordeel waarden",
      choicesByUrl: "Keuzes van internet",
      matrixChoices: "Standaardkeuzes",
      multipleTextItems: "Tekstinvoer",
      numbering: "Nummering",
      validators: "Validators",
      navigation: "Navigatie",
      question: "Vraag",
      pages: "Pagina's",
      timer: "Timer/Quiz",
      calculatedValues: "Berekende waardes",
      triggers: "Triggers",
      templateTitle: "Sjabloontitel",
      totals: "Totalen",
      logic: "Logica",
      layout: "Indeling",
      data: "Gegevens",
      validation: "Validatie",
      cells: "Cellen",
      showOnCompleted: "Weergeven bij afronden",
      logo: "Logo in enquêtetitel",
      slider: "Schuifregelaar",
      // expression: "Expression",
      others: "Anderen"
    },
    editProperty: "Eigenschap '{0}' bewerken",
    items: "Items",
    // choicesVisibleIf: "Choices are visible if",
    // choicesEnableIf: "Choices are selectable if",
    // columnsEnableIf: "Columns are visible if",
    // rowsEnableIf: "Rows are visible if",
    // indent: "Add indents",
    panel: {
      // indent: "Add outer indents"
    },
    // innerIndent: "Add inner indents",
    // defaultValueFromLastRow: "Take default values from the last row",
    // defaultValueFromLastPanel: "Take default values from the last panel",
    enterNewValue: "Voer de waarde in.",
    noquestions: "Er is geen enkele vraag in de enquête.",
    createtrigger: "Maak een trigger",
    titleKeyboardAdornerTip: "Druk op de enter-knop om te bewerken",
    keyboardAdornerTip: "Druk op de enter-knop om het item te bewerken, druk op de delete-knop om het item te verwijderen, druk op alt plus pijl omhoog of pijl omlaag om item te verplaatsen",
    triggerOn: "Aan ",
    triggerMakePagesVisible: "Maak pagina's zichtbaar:",
    triggerMakeQuestionsVisible: "Maak elementen zichtbaar:",
    triggerCompleteText: "Vul de enquête in als dit lukt.",
    triggerNotSet: "De trigger is niet ingesteld",
    triggerRunIf: "Voer als",
    triggerSetToName: "Verander waarde van: ",
    triggerFromName: "Waarde kopiëren van: ",
    triggerRunExpression: "Voer deze expressie uit:",
    triggerSetValue: "naar: ",
    triggerGotoName: "Ga naar de vraag:",
    triggerIsVariable: "Zet de variabele niet in het onderzoeksresultaat.",
    triggerRunExpressionEmpty: "Voer een geldige uitdrukking in",
    // emptyExpressionPlaceHolder: "Type expression here...",
    noFile: "Geen bestand gekozen",
    // clearIfInvisible: "Clear the value if the question becomes hidden",
    // valuePropertyName: "Value property name",
    // searchEnabled: "Enable search",
    // hideSelectedItems: "Hide selected items",
    // signatureWidth: "Signature width",
    // signatureHeight: "Signature height",
    // verticalAlign: "Vertical alignment",
    // alternateRows: "Alternate rows",
    // columnsVisibleIf: "Columns are visible if",
    // rowsVisibleIf: "Rows are visible if",
    // otherPlaceholder: "Comment area placeholder"
  },
  // Property values
  pv: {
    // true: "true",
    // false: "false",
    inherit: "Erven",
    show: "Tonen",
    hide: "Zich verstoppen",
    default: "Standaard",
    initial: "Standaard",
    random: "Willekeurig",
    collapsed: "Ingestort",
    expanded: "Uitgebreid",
    none: "Geen",
    asc: "Oplopend",
    desc: "Aflopend",
    indeterminate: "Onbepaald",
    // decimal: "decimal",
    // currency: "currency",
    // percent: "percent",
    firstExpanded: "Eerste uitgevouwen",
    off: "Uit",
    // onpanel: "Start on each panel",
    onPanel: "Op paneel",
    onSurvey: "Op enquête",
    list: "Lijst",
    progressTop: "Voortgang top",
    progressBottom: "Voortgang bodem",
    progressTopBottom: "Voortgang top bodem",
    horizontal: "Horizontaal",
    vertical: "Verticaal",
    top: "Top",
    bottom: "Beneden",
    topBottom: "Top en beneden",
    both: "Beide",
    left: "Links",
    right: "Rechts",
    // color: "color",
    // date: "date",
    // datetime: "datetime",
    // "datetime-local": "datetime-local",
    // email: "email",
    // month: "month",
    // number: "number",
    // password: "password",
    // range: "range",
    // tel: "tel",
    // text: "text",
    // time: "time",
    // url: "url",
    // week: "week",
    hidden: "Verborgen",
    on: "Aan",
    onPage: "Op pagina",
    edit: "Bewerk",
    display: "Scherm",
    onComplete: "Bij afronden",
    onHidden: "Bij verbergen",
    // onHiddenContainer: "When the question or its panel/page becomes hidden",
    clearInvisibleValues: {
      // none: "Never"
    },
    all: "Alle",
    page: "Pagina",
    survey: "Enquête",
    onNextPage: "Bij pagina-overgang",
    onValueChanged: "Bij waardeverandering",
    // onValueChanging: "Before an answer is changed",
    standard: "Standaard",
    singlePage: "Enkele pagina",
    questionPerPage: "Vraag per pagina",
    noPreview: "Geen preview",
    showAllQuestions: "Toon voorbeeld met alle vragen",
    showAnsweredQuestions: "Toon voorbeeld met beantwoorde vragen",
    // pages: "Completed pages",
    // questions: "Answered questions",
    // requiredQuestions: "Answered required questions",
    // correctQuestions: "Valid answers",
    // buttons: "Completed pages (button UI)",
    underInput: "Onder het invoerveld",
    underTitle: "Onder de titel",
    onBlur: "Bij verliezen focus",
    onTyping: "Tijdens het typen",
    // underRow: "Under the row",
    // underRowSingle: "Under the row, only one panel is visible",
    showNavigationButtons: {
      // none: "Hidden"
    },
    showProgressBar: {
      // off: "Hidden"
    },
    showTimerPanel: {
      // none: "Hidden"
    },
    showTimerPanelMode: {
      // all: "Both"
    },
    detailPanelMode: {
      // none: "Hidden"
    },
    addRowLocation: {
      // default: "Depends on matrix layout"
    },
    panelsState: {
      // default: "Users cannot expand or collapse panels",
      // collapsed: "All panels are collapsed",
      // expanded: "All panels are expanded"
    },
    widthMode: {
      // auto: "Auto",
      // static: "Static",
      // responsive: "Responsive"
    },
    imageFit: {
      // none: "None",
      // contain: "Contain",
      // cover: "Cover",
      // fill: "Fill"
    },
    contentMode: {
      // auto: "Auto",
      // image: "Image",
      // video: "Video",
      // youtube: "YouTube"
    },
    displayMode: {
      // auto: "Auto",
      // buttons: "Buttons",
      // dropdown: "Dropdown"
    }
  },
  // Operators
  op: {
    empty: "is leeg",
    notempty: "is niet leeg",
    equal: "gelijk is",
    notequal: "niet gelijk",
    contains: "bevat",
    notcontains: "bevat niet",
    anyof: "een van",
    allof: "alles van",
    greater: "groter",
    less: "minder",
    greaterorequal: "groter of gelijk aan",
    lessorequal: "minder of gelijk aan",
    // and: "and",
    // or: "or"
  },
  // Embed window
  ew: {
    angular: "Gebruik de Angular-versie",
    jquery: "Gebruik de jQuery-versie",
    knockout: "Gebruik de Knockout-versie",
    react: "Gebruik de React-versie",
    vue: "Gebruik de Vue-versie",
    bootstrap: "Voor bootstrap-framework",
    modern: "Modern thema",
    default: "Standaard thema",
    orange: "Oranje thema",
    darkblue: "Donkerblauw thema",
    darkrose: "Donkereroos thema",
    stone: "Stenen thema",
    winter: "Winter thema",
    winterstone: "Wintersteen thema",
    showOnPage: "Toon enquête op een pagina",
    showInWindow: "Toon enquête in een venster",
    loadFromServer: "Laad Survey JSON vanaf de server",
    titleScript: "Scripts en stijlen",
    titleHtml: "HTML",
    titleJavaScript: "JavaScript"
  },
  // Preview (Survey)
  ts: {
    selectPage: "Selecteer de pagina om deze te testen:",
    showInvisibleElements: "Toon onzichtbare elementen",
    // hideInvisibleElements: "Hide invisible elements"
  },
  validators: {
    answercountvalidator: "Aantal antwoorden",
    emailvalidator: "E-mail",
    expressionvalidator: "Uitdrukking",
    numericvalidator: "Numeriek",
    regexvalidator: "Regex",
    textvalidator: "Tekst"
  },
  triggers: {
    completetrigger: "Volledige enquête",
    setvaluetrigger: "Ingestelde waarde",
    copyvaluetrigger: "Kopie waarde",
    skiptrigger: "Ga naar de vraag",
    runexpressiontrigger: "Run expressie",
    visibletrigger: "Zichtbaarheid wijzigen (verouderd)"
  },
  pehelp: {
    // cookieName: "Cookies prevent users from filling out the same survey twice.",
    // format: "Use {0} as a placeholder for the actual value.",
    // totalText: "Visible only when at least one column has Total type or Total expression.",
    // acceptedTypes: "Refer to the [accept](https://www.w3schools.com/tags/att_input_accept.asp) attribute description for more information.",
    // columnColCount: "Applicable only to Radiogroup and Checkbox cell types.",
    // autocomplete: "Refer to the [autocomplete](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete) attribute description for more information.",
    // valueName: "If you do not set this property, the answer will be stored in a field specified by the Name property.",
    choicesbyurl: {
      // valueName: " "
    },
    // keyName: "If the specified column contains identical values, the survey throws the \"Non-unique key value\" error."
  },
  // Properties
  p: {
    title: {
      name: "Titel",
      title: "Laat het leeg als het hetzelfde is als 'Naam'"
    },
    // multiSelect: "Allow multiple selection",
    // showLabel: "Show image captions",
    // value: "Value",
    visibleIf: "Zichtbaar als",
    // attachOriginalItems: "attachOriginalItems",
    useDisplayValuesInDynamicTexts: "Waardes in titel gebruiken",
    titleLocation: "Plek titel",
    description: "Omschrijving",
    descriptionLocation: "Plek omschrijving",
    // defaultValueExpression: "defaultValueExpression",
    requiredIf: "Vereist als",
    validators: "Validators",
    // bindings: "bindings",
    // renderAs: "renderAs",
    currency: "Valuta",
    // cellHint: "cellHint",
    // isUnique: "isUnique",
    // showInMultipleColumns: "showInMultipleColumns",
    // totalMaximumFractionDigits: "totalMaximumFractionDigits",
    // totalMinimumFractionDigits: "totalMinimumFractionDigits",
    columns: "Kolommen",
    // detailElements: "detailElements",
    choices: "Keuzes",
    // allowAdaptiveActions: "allowAdaptiveActions",
    defaultRowValue: "Standaard rijwaarde",
    // detailPanelShowOnAdding: "detailPanelShowOnAdding",
    // logoFit: "logoFit",
    // pages: "pages",
    // questions: "questions",
    triggers: "Triggers",
    // calculatedValues: "calculatedValues",
    // surveyId: "surveyId",
    // surveyPostId: "surveyPostId",
    // surveyShowDataSaving: "surveyShowDataSaving",
    questionDescriptionLocation: "Plek vraagomschrijving",
    progressBarType: "Type voortgangsbalk",
    // questionTitlePattern: "questionTitlePattern",
    // widthMode: "widthMode",
    // showBrandInfo: "showBrandInfo",
    choicesByUrl: "Keuzes per url",
    // choicesLazyLoadEnabled: "choicesLazyLoadEnabled",
    // choicesLazyLoadPageSize: "choicesLazyLoadPageSize",
    // inputFieldComponent: "inputFieldComponent",
    // itemComponent: "itemComponent",
    // min: "min",
    // max: "max",
    // minValueExpression: "minValueExpression",
    // maxValueExpression: "maxValueExpression",
    // step: "step",
    // dataList: "dataList",
    itemSize: "Item grootte",
    // elements: "elements",
    // content: "content",
    navigationButtonsVisibility: "Navigatie Knoppen zichtbaarheid",
    // navigationTitle: "navigationTitle",
    // navigationDescription: "navigationDescription",
    // closeOnSelect: "closeOnSelect",
    // longTap: "longTap",
    // autoGrow: "autoGrow",
    // acceptCarriageReturn: "acceptCarriageReturn",
    // displayMode: "displayMode",
    label: "Etiket",
    // contentMode: "contentMode",
    // imageFit: "imageFit",
    // altText: "altText",
    // height: "height",
    // penColor: "penColor",
    // backgroundColor: "backgroundColor",
    // templateElements: "templateElements",
    // operator: "operator",
    // isVariable: "isVariable",
    // runExpression: "runExpression",
    // showCaption: "showCaption",
    // iconName: "iconName",
    // iconSize: "iconSize"
  }
};
//Uncomment this line on creating a translation file. You should replace "en" and enStrings with your locale ("fr", "de" and so on) and your variable.
editorLocalization.locales["nl"] = nlStrings;
