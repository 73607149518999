import { Close, Edit } from '@mui/icons-material';
import {
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { useState } from 'react';
import { usePlacesTranslation } from '../../i18n';
import CommentsForm from './CommentsForm';

export default function CommentDialog({
  comment,
  isRefreshing,
  setIsRefreshing,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const t = usePlacesTranslation();

  const DialogTitlee = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <DialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant='h6'>{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  });
  return (
    <div>
      <ButtonGroup onClick={handleClickOpen}>
        <Edit
          fontSize='small'
          className=' hover:bg-blue-200 hover:cursor-pointer'
        />
      </ButtonGroup>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth='sm'
      >
        <DialogTitlee
          id='form-dialog-title'
          onClose={handleClose}
        ></DialogTitlee>
        <DialogContent style={{ width: '100%' }}>
          <CommentsForm
            comment={comment}
            open={open}
            setOpen={setOpen}
            isRefreshing={isRefreshing}
            setIsRefreshing={setIsRefreshing}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
