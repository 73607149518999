import { Grid } from '@material-ui/core';
import { Provider } from 'react-redux';
import dispatchStore from '../../../core/app/store';
import HeatMapContainer from '../components/HeatMapContainer';

const HeatMapPage = () => {
  return (
    <Provider store={dispatchStore}>
      <Grid container lg={12} md={12}>
        <Grid item lg={12} md={12}>
          <HeatMapContainer />
        </Grid>
      </Grid>
    </Provider>
  );
};

export default HeatMapPage;
